import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { format, addMonths } from 'date-fns';
import CalendarView from './view';
import useStore from '../../hooks/useStore';
import { timeUtils } from '../../common/utils/timeUtil';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const Calendar = observer(
  ({
    currentDate,
    onChangePage,
    setCurrentDate,
    sportsType,
    closeDrawer,
    onClickToday,
    calendarOpenButtonRef
  }) => {
    const calendarRef = useRef();
    const { scoreStore } = useStore();
    const { requestGetCalender, calendarGameCount, gameCount } = scoreStore;
    const dayList = ['일', '월', '화', '수', '목', '금', '토'];

    const [calendarArr, setCalendarArr] = useState(
      timeUtils.calendarBinary(currentDate)
    );
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const [today] = useState(timeUtils.todayYmd());

    useEffect(() => {
      const date = new Date(currentDate);

      setCalendarArr(timeUtils.calendarBinary(currentDate));
      setSelectedDate(currentDate);
      requestGetCalender(sportsType, getMonth(date) + 1, getYear(date));
    }, [currentDate]);

    const nextMonth = () => {
      const date = addMonths(new Date(selectedDate), 1);
      const ymd = format(date, 'yyyy-MM-dd');

      setCalendarArr(timeUtils.calendarBinary(ymd));
      setSelectedDate(date);
      requestGetCalender(sportsType, getMonth(date) + 1, getYear(date));
    };

    const prevMonth = () => {
      const date = addMonths(new Date(selectedDate), -1);
      const ymd = format(date, 'yyyy-MM-dd');

      setCalendarArr(timeUtils.calendarBinary(ymd));
      setSelectedDate(date);
      requestGetCalender(sportsType, getMonth(date) + 1, getYear(date));
    };

    const changeDate = (date) => {
      setCurrentDate(date);
      onChangePage(date);
      setSelectedDate(date);

      closeDrawer();
    };

    const onClick = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        if (
          calendarOpenButtonRef.current &&
          !calendarOpenButtonRef.current.contains(event.target)
        ) {
          closeDrawer();
        }
      }
    };

    useEffect(() => {
      document.addEventListener('click', onClick, true);
      return () => {
        document.removeEventListener('click', onClick, true);
        enableBodyScroll(calendarRef.current);
      };
    }, []);

    useLayoutEffect(() => {
      disableBodyScroll(calendarRef.current);
    }, [calendarRef]);

    return (
      <div className="calendar_layer_wrap" ref={calendarRef}>
        <div className="summary">
          <a className="btn_close" onClick={closeDrawer}>
            닫기
          </a>
          <button className="btn btn_prev" onClick={() => prevMonth()}>
            이전
          </button>
          <div className="year_month">
            {format(new Date(selectedDate), 'yyyy.MM')}
          </div>
          <button className="btn btn_next" onClick={() => nextMonth()}>
            다음
          </button>

          <button className="btn_today" onClick={onClickToday}>
            오늘
          </button>
        </div>

        <div className="info">
          {dayList.map((day, index) => {
            return (
              <div
                key={index}
                className={clsx(
                  'day',
                  index === 0 && 'sun',
                  index === 6 && 'sat'
                )}
              >
                <span>{day}</span>
              </div>
            );
          })}
        </div>
        <div className="date_list"></div>
        {calendarArr.map((week, index) => {
          return (
            <div key={`week-row-${index}`} className="week">
              {week.map((day, idx) => (
                <CalendarView
                  key={`week-day-${day}-${idx}`}
                  day={day}
                  gameCount={gameCount}
                  selectedDate={selectedDate}
                  today={today}
                  changeDate={changeDate}
                />
              ))}
            </div>
          );
        })}
      </div>
    );
  }
);

export default Calendar;
