export const getChatHostname = () => {
    const hostname = window.location.hostname
    let chatHostName = '';
    let chatHostNameWithProtocol = '';

    switch (hostname) {
        case 'm.named.com':
            chatHostName = "named.com"
            chatHostNameWithProtocol = "https://named.com/"
            break;
        case 'm-stage.named.com':
            chatHostName = "named.com"
            chatHostNameWithProtocol = "https://named.com/"
            break;
        case 'm.nmd.co':
            chatHostName = "nmd.co"
            chatHostNameWithProtocol = "https://nmd.co/"
            break;
        case 'm-stage.nmd.co':
            chatHostName = "nmd.co"
            chatHostNameWithProtocol = "https://nmd.co/"
            break;
        case 'dev-m.named.com':
            chatHostName = "dev.named.com"
            chatHostNameWithProtocol = "http://dev.named.com/"
            break;
        case 'localhost':
            chatHostName = "dev.named.com"
            chatHostNameWithProtocol = "http://dev.named.com/"
            break;
        default:
            chatHostName = "named.com"
            chatHostNameWithProtocol = "https://named.com/"
            break;
    }

    return {
        chatHostName, chatHostNameWithProtocol
    };
}