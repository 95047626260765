import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import './chargeRequest.css';
import WalletAddress from "./wallet";
import Cookie from "../../../common/utils/cookie";
import {Url} from "../../../common/constants/environment";
import {MypageContext, useMypageStore} from "../context";

const CashChargeRequest = observer(({history}) => {
  const minimumAmount = 10000;
  const COUNTDOWN_SECONDS = process.env.REACT_APP_API === "production" ? 3600 : 3000;

  const [currentStep, setCurrentStep] = useState(1);
  const [prevClass, setPrevClass] = useState(false);
  const [stepsClasses, setStepClasses] = useState([
    {"step": 1, "classes": "active"},
    {"step": 2, "classes": ""},
    {"step": 3, "classes": ""},
    {"step": 4, "classes": ""},
  ]);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState("BANK_TRANSFER");
  const [timeLeft, setTimeLeft] = useState(COUNTDOWN_SECONDS);
  const [isRunning, setIsRunning] = useState(false);

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [txID, setTxID] = useState("");
  const [amountError, setAmountError] = useState({isError: false, message: ""});
  const [txIDError, setTxIDError] = useState({isError: false, message: ""});

  const { mypageStore } = useMypageStore(MypageContext);

  const paymentMethods = [
    {name: "USDT 테더 TRC 20", type: "BANK_TRANSFER", icon: "/images/payment/usdt-icon.png"}
  ];

  const nextStep = async () => {
    if (currentStep === 2) {
      let validAmount = checkAmount(amount);
      if (!validAmount) return false;
      setIsRunning(true);
    }
    if (currentStep === 3) {
      let validTxID = checkTxID(txID);
      if (!validTxID) return false;

      const submit = await handleSubmit()
      if (!submit) {
        return false;
      }
      setIsRunning(false);
      setTimeLeft(COUNTDOWN_SECONDS);
    }
    if (currentStep === 4) {
      history.push('/customercenter/qna/write');
    }

    setCurrentStep((prevStep) => prevStep + 1);
    const newStepClasses = stepsClasses.map((item, index) => {
      if (item.step === currentStep) {
        return {"step": item.step, "classes": "previous out"};
      } else if (item.step === currentStep + 1) {
        return {"step": item.step, "classes": "active in"};
      } else {
        return {"step": item.step, "classes": ""};
      }
    })
    setStepClasses(newStepClasses);
    setPrevClass(true);
  };

  const prevStep = () => {
        scrollToElement()
    if (currentStep === 1 || currentStep === 4) {
      return history.push('/mypage/cash?tab=cash_charge_list')
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
      const newStepClasses = stepsClasses.map((item, index) => {
        if (item.step === currentStep) {
          return {"step": item.step, "classes": "previous prev-out"};
        } else if (item.step === currentStep - 1) {
          return {"step": item.step, "classes": "active prev-in"};
        } else {
          return {"step": item.step, "classes": ""};
        }
      });
      setStepClasses(newStepClasses);
      setPrevClass(true);
    }
  }

  const scrollToElement = () => {
    const element = document.querySelector('.step.active');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

    const getStep = (step) => {
      return stepsClasses.filter(item => item.step === step)[0].classes;
    }

    const handleAmountChange = (e) => {
      const value = Math.max(0, parseInt(e.target.value.replace(/[^0-9]/g, '')));
      if (value > 9223372036854775807) {
        return;
      }
      checkAmount(value);
      setAmount(value);
    };

    const checkAmount = (amount) => {
      if (!amount) {
        setAmountError({isError: true, message: "최소 입금 금액은 1 만원 입니다."});
        return false;
      } else if (amount < minimumAmount) {
        setAmountError({isError: true, message: `최소 입금 금액은 1 만원 입니다.`});
        return false;
      }
      setAmountError({isError: false, message: ``});
      return true;
    };

    const checkTxID = (txID) => {
      if (!txID) {
        setTxIDError({isError: true, message: "TxID를 입력해 주세요."});
        return false;
      }
      setTxIDError({isError: false, message: ``});
      return true;
    };

    const numberFormat = (number) => {
      if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return 0;
    };

    const handleSubmit = async () => {
//    e.preventDefault();

      if (!checkAmount(amount)) return;
      if ((!checkTxID(txID))) return;

      const payload = new FormData();
      payload.append('amount', amount);
      payload.append('pay_method', paymentMethodSelected);
      payload.append('tx_id', txID);

      setLoading(true);
      try {
        const response = await fetch(`${Url.named}payment/usdt/request-charge`, {
          method: 'POST',
          headers: {
            'named-session': Cookie.getCookie('session_id'),
          },
          body: payload,
        });

        const result = await response.json();

        if (result.success) {
          const startDt = '';
          const endDt = '';
          const page = 1;
          mypageStore.actionCashChargeList({ startDt, endDt, page });
          return true;
        } else {
          alert('신청 중 오류가 발생했습니다. 다시 시도해 주세요');
          return false;
        }
      } catch (error) {
        alert('신청 중 오류가 발생했습니다. 다시 시도해 주세요');
        return false;
      } finally {
        setLoading(false);
      }
    };

    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const mainButtonText = () => {
      let text;
      switch (currentStep) {
        case 1:
          text = "충전하기";
          break;
        case 2:
        case 3:
          text = "확인";
          break;
        case 4:
          text = "문의하기";
          break;
        default:
          text = "";
          break;
      }
      return text;
    }

    const secondButtonText = () => {
      let text;
      switch (currentStep) {
        case 1:
          text = "창닫기";
          break;
        case 2:
        case 3:
          text = "뒤로";
          break;
        case 4:
          text = "창닫기";
          break;
        default:
          text = "";
          break;
      }
      return text;
    }

    useEffect(() => {
      if (prevClass) {
        const timer = setTimeout(() => {
          const newStepClasses = stepsClasses.map((item, index) => {
            return {"step": item.step, "classes": item.classes.replace(/previous /g, '')}
          })
          setStepClasses(newStepClasses);
          setPrevClass(false);
        }, 500);

        return () => clearTimeout(timer);
      }
    }, [prevClass]);

    useEffect(() => {
      let timer;

      if (isRunning) {
        timer = setInterval(() => {
          setTimeLeft(prevTime => {
            if (prevTime <= 0) {
              clearInterval(timer);
              alert("TxID 입력 시간이 초과되었습니다.  다시 시도해 주세요.");
              window.location.reload();
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      }

      return () => clearInterval(timer);
    }, [isRunning]);


    return (
      <div id={"charge-container-outer"}>
        <div className="charge-container">
          <div className={"method_area"}>
            {/*STEP 1*/}
            <div className={`step ${getStep(1)}`}>
              <div className="amt_area">
                <h2 className="tit_area">충전방법선택</h2>
                <div id="amtlist"></div>
              </div>
              <div className="select-container">
                {
                  paymentMethods.map((method, index) => (
                    <button key={index} className={`link ${method.type === paymentMethodSelected ? "active" : null}`}>
                      <img src={method.icon} alt={method.name} className="option-icon"/>
                      {method.name}
                    </button>
                  ))
                }
              </div>
            </div>

            {/*STEP 2*/}
            <div className={`step ${getStep(2)}`}>
              <div className="form-group form-with-unit">
                <div className={"input-label"}>
                  <div className={"title-label"}>
                    <label>입금금액</label> <span className={"red-text"}>*</span>
                  </div>
                  <label className={`error-label ${amountError.isError ? "" : "hide"}`}>{amountError.message}</label>
                </div>
                <div className={"input-with-unit"}>
                  <input
                    className={"box-input"}
                    type="text"
                    value={numberFormat(amount)}
                    onChange={handleAmountChange}
                    //                      onBlur={handleAmountBlur}
                    required
                  />
                </div>
                <p className={"input-note amount-result"}>{` = ${numberFormat(amount)} 캐쉬`}</p>
              </div>
              <div className="readme">
                <ul>
                  <li className="ico_indent">입금하실 금액을 정확하게 입력하셨는지 다시 한번 확인해 주시기 바랍니다.</li>
                  <li className="ico_indent">최소 1 만원 이상 충전이 가능합니다.</li>
                  <li className="ico_indent">입금하신 금액은 취소나 환불이 불가합니다.</li>
                </ul>
              </div>
            </div>

            {/*STEP 3*/}
            <div className={`step ${getStep(3)}`}>
              <div className="tutorial-area">
                <h2>
                  <b>
                    입금할 USDT 금액은 어떻게 계산하나요 ?
                  </b>
                </h2>
                <p>
                  아래 구글 링크를 눌러서 현재 시세에 맞춰 입금 해주셔야 합니다.
                </p>
                <a href="https://www.google.com/finance/quote/KRW-USD?hl=ko"
                   target="_blank">https://www.google.com/finance/quote/KRW-USD?hl=ko</a>
                <p>계산 방법 : ( 현재 환율 ) x ( 입금하실 금액 ) = 입금하실 USDT 개수</p>
              </div>

              <div className="deposit-info-area">
                <div className="qr-section">
                  <img src="/images/payment/usdt-deposit-qr.png" alt="usdt-deposit-qr" width="100%"/>
                </div>
                <div className="wallet-area">
                  <WalletAddress/>
                </div>
              </div>
              <div className="count_down-area">
                <div id="timer" className={timeLeft <= 10 ? "danger" : ""}>{formatTime(timeLeft)}</div>
              </div>
              <div className="form-group">
                <div className={"input-label"}>
                  <div className={"title-label"}>
                    <label>TxID <span className={"red-text"}>*</span></label>
                  </div>
                  <label className={`error-label ${txIDError.isError ? "" : "hide"}`}>{txIDError.message}</label>
                </div>
                <div className={"input-without-unit"}>
                  <input
                    className={"box-input"}
                    type="text"
                    value={txID}
                    onChange={(e) => {
                      setTxID(e.target.value);
                      checkTxID(e.target.value);
                    }}
                  />
                </div>
                <p className={"input-note gray-text"}>( 입금 완료 후 TxID 확인 후 기입 )</p>
              </div>
              <div className="readme">
                <ul>
                  <li className="ico_indent">정확한 TxID 를 입력해 주셔야 입금 확인이 가능합니다.</li>
                  <li className="ico_indent">정확하지 않은 USDT 금액을 송금하셨을 경우, 정상적인 충전 처리가 이루어지지 않을 수 있으니 상단의 계산 방법을 반드시 확인하시고
                    정확하게 입금해 주시기 바랍니다.
                  </li>
                  <li className="ico_indent">실수로 입금을 잘못 하셨을 경우 문의하기를 통해 문의 부탁드립니다.</li>
                  <li className="ico_indent">명시된 시간 (1 시간 ) 안에 입금 완료를 하지 못하셨을 경우, 창을 닫고 캐쉬 충전 처음 단계부터 다시 시작해 주시기 바랍니다
                  </li>
                </ul>
              </div>

            </div>

            {/*STEP 4*/}
            <div className={`step ${getStep(4)}`}>
              <div className="amt_area">
                <h2 className="tit_area">캐쉬 충전 신청이 완료 되었습니다</h2>
                <div id="amtlist"></div>
              </div>
              <div className="payment-order tutorial-area" id="payment-order">
                <p>
                  <strong>TxID:</strong>
                  <span id="txID-result">{txID}</span>
                </p>
                <p>
                  <strong>입금 금액:</strong>
                  <span id="deposit-amount" className="deposit-amount">{numberFormat(amount)}</span> 원
                </p>
              </div>
              <div className="readme">
                <ul>
                  <li className="ico_indent">입금을 완료하신 후에는 문의하기 게시판을 통해 입금 완료 사실을 알려주시면 더욱 빠른 처리가 가능합니다.</li>
                  <li className="ico_indent">정확하지 않은 정보를 기입하셨거나, 입금 금액이 잘못되었을 경우 문의하기를 통해 문의 부탁드립니다.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="button-group">
            <button
              type="button"
              onClick={nextStep}
              className={`submit-button ${loading ? 'loading-btn' : ''}`}
              disabled={loading}
            >
              {mainButtonText()}
            </button>
            <button
              type="button"
              className={`cancel-button ${loading ? 'loading-btn' : ''}`}
              onClick={prevStep}
              disabled={loading}
            >
              {secondButtonText()}
            </button>
          </div>
        </div>
      </div>
    );
  }
)
  ;

  export default CashChargeRequest;
