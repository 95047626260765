import React, { useEffect, useMemo, useState } from 'react';
import { List, MoreButton, Card } from './styled';
import holdemService from '../../../../../common/services/game/holdem';
import moment from 'moment';
import clsx from 'clsx';

const combo = {
  HIGH_CARDS: '하이카드',
  ONE_PAIR: '원페어',
  TWO_PAIRS: '투페어',
  TREE_OF_A_KIND: '트리플',
  STRAIGHT: '스트레이트',
  FLUSH: '플러시',
  FULL_HOUSE: '풀하우스',
  QUADS: '포카드',
  STRAIGHT_FLUSH: '스트레이트 플러시',
  ROYAL_FLUSH: '로얄 플러시'
};

const HoldemResult = ({}) => {
  const row = 10;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ list: [], count: 0 });
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    getHoldemResult().then((res) => {
      setList(res.list.slice(0, row * page));
      setTotalPage(Math.ceil(res.count / row) || 1);
      setLoading(false);
    });
  }, []);

  const getHoldemResult = async () => {
    const { code, data } = await holdemService.getHoldemResult();
    if (code === 200) {
      setData(data);
      return data;
    } else {
      return { list: [], count: 0 };
    }
  };

  const onClickMore = () => {
    if (page === totalPage) {
      return;
    }
    setList(data.list.slice(0, row * (page + 1)));
    setPage(page + 1);
  };

  const getResultCombo = (data) => {
    if (!data.win_p) {
      return '';
    } else {
      if (data.win_p === 'tie') {
        let result = '';
        for (let i = 1; i <= 6; i++) {
          if (!!data[`p${i}_result`]) {
            result += data[`p${i}_result`] + ',';
          }
        }
        return result;
      } else {
        return data[`${data.win_p}_result`];
      }
    }
  };

  const getStartTime = (time) => {
    if (!time) return '';
    return moment(time).format('HH:mm');
  };

  const getPlayerDom = (data) => {
    const players = [];
    let playerCards = [];
    let card = null;

    for (let i = 1; i <= 6; i++) {
      for (let j = 1; j <= 2; j++) {
        card = data[`p${i}_card${j}`];
        if (!!card) {
          playerCards.push(
            <Card
              key={`${i}${j}`}
              className={clsx(
                `c${card}`,
                getResultCombo(data).includes(card) && 'combo'
              )}
            />
          );
        }
      }

      players.push(
        <div key={i} className={'cards'}>
          {playerCards}
        </div>
      );

      playerCards = [];
    }

    return players;
  };

  const getDealerDom = (data) => {
    return (
      <div className={'cards'}>
        <Card
          className={clsx(
            `c${data.flop_card1}`,
            getResultCombo(data).includes(data.flop_card1) && 'combo'
          )}
        />
        <Card
          className={clsx(
            `c${data.flop_card2}`,
            getResultCombo(data).includes(data.flop_card2) && 'combo'
          )}
        />
        <Card
          className={clsx(
            `c${data.flop_card3}`,
            getResultCombo(data).includes(data.flop_card3) && 'combo'
          )}
        />
        <Card
          className={clsx(
            `c${data.turn_card}`,
            getResultCombo(data).includes(data.turn_card) && 'combo'
          )}
        />
        <Card
          className={clsx(
            `c${data.river_card}`,
            getResultCombo(data).includes(data.river_card) && 'combo'
          )}
        />
      </div>
    );
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <List>
        {list.map((item, key) => {
          const { start_time, win_combo } = item;
          return (
            <li key={key}>
              <div>
                <div>
                  <span className={'time'}>{getStartTime(start_time)}</span>
                  결과
                  <em className={'result'}>{combo[win_combo]}</em>
                </div>
                {getDealerDom(item)}
              </div>
              <div>{getPlayerDom(item)}</div>
            </li>
          );
        })}
      </List>
      <MoreButton
        onClick={onClickMore}
      >{`더보기 ( ${page} / ${totalPage} )`}</MoreButton>
    </>
  );
};

export default HoldemResult;
