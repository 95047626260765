import React from 'react';
import { Switch } from 'react-router-dom';
import MypageProvider from './context';
import Info from './info';
import BlackList from './black_list';
import BlackListAdd from './blacklist_add';
import CertResult from './cert_result';
import NickChange from './nick_change';
import TodayWordChange from './today_word_change';
import ProfileImageChange from './profile_image_change';
import BackgroundImageChange from './background_image_change';
import PasswordChange from './password_change';
import EmailAuth from './email_auth';
import AuthResult from './auth_result';
import Exp from './exp';
import Point from './point';
import Tp from './tp';
import Medal from './medal';
import LoginLog from './login_log';
import Pw2 from './pw2';
import Pw2Set from './pw2_set';
import Pw2SetEmail from './pw2_set_email';
import Pw2SetResult from './pw2_set_result';
import IpAdd from './ip_add';
import IpDelete from './ip_delete';
import Market from './market';
import Gifticon from './gifticon';
import Memo from './memo';
import ItemGift from './item_gift';
import Item from './item';
import Cash from './cash';
import Trade from './trade';
import Leave from './leave';
import CashReceipt from './cash_receipt';
import TradeWrite from './trade_write';
import TradeDetail from './trade_detail';
import Subscribe from './subscribe';
import useModalStore from '../../hooks/useModalStore';
import RouteGuard from '../../components/routeGuard';
import Seo from '../../components/seo';
import MedalExchange from './medal/exchange';
import MedalExchangeHistory from './medal/exchangeHistory';
import CashChargeRequest from './cash/chargeRequest';

// This flag to enable or disable the gift icon in menu
export const enableGiftIcon = false;

const Mypage = ({ history }) => {
  const modalStore = useModalStore();

  return (
    <>
      <Seo title={'마이페이지'} />
      <MypageProvider modalStore={modalStore} history={history}>
        <Switch>
          <RouteGuard exact path='/mypage/info' component={Info} />
          <RouteGuard exact path='/mypage/memo' component={Memo} />
          <RouteGuard exact path='/mypage/blacklist' component={BlackList} />
          <RouteGuard exact path='/mypage/subscribe' component={Subscribe} />
          <RouteGuard exact path='/mypage/blacklist/add' component={BlackListAdd} />
          <RouteGuard exact path='/mypage/cert_result' component={CertResult} />
          <RouteGuard exact path='/mypage/nick_change' component={NickChange} />
          <RouteGuard exact path='/mypage/today_word_change' component={TodayWordChange} />
          <RouteGuard exact path='/mypage/profile_image_change' component={ProfileImageChange} />
          <RouteGuard exact path='/mypage/background_image_change' component={BackgroundImageChange} />
          <RouteGuard exact path='/mypage/password' component={PasswordChange} />
          <RouteGuard exact path='/mypage/email_auth' component={EmailAuth} />
          <RouteGuard exact path='/mypage/auth_result/:type' component={AuthResult} />
          <RouteGuard exact path='/mypage/cash' component={Cash} />
          <RouteGuard exact path='/mypage/cash/request-charge' component={CashChargeRequest} />
          <RouteGuard exact path='/mypage/exp' component={Exp} />
          <RouteGuard exact path='/mypage/point' component={Point} />
          <RouteGuard exact path='/mypage/medal' component={Medal} />
          <RouteGuard exact path='/mypage/medal_exchange' component={MedalExchange} />
          <RouteGuard exact path='/mypage/medal_exchange_history' component={MedalExchangeHistory} />
          <RouteGuard exact path='/mypage/loginlog' component={LoginLog} />
          <RouteGuard exact path='/mypage/pw2' component={Pw2} />
          <RouteGuard exact path='/mypage/pw2_set' component={Pw2Set} />
          <RouteGuard exact path='/mypage/pw2_set_email' component={Pw2SetEmail} />
          <RouteGuard exact path='/mypage/pw2_set_result' component={Pw2SetResult} />
          <RouteGuard exact path='/mypage/ip_add' component={IpAdd} />
          <RouteGuard exact path='/mypage/ip_delete' component={IpDelete} />
          <RouteGuard exact path='/mypage/market' component={Market} />
          {enableGiftIcon ? <RouteGuard exact path='/mypage/gifticon' component={Gifticon} /> : null}
          {enableGiftIcon ? <RouteGuard exact path='/mypage/gifticon/:type' component={Gifticon} /> : null}
          <RouteGuard exact path='/mypage/item_gift/:type' component={ItemGift} />
          <RouteGuard exact path='/mypage/item' component={Item} />
          <RouteGuard exact path='/mypage/trade' component={Trade} />
          <RouteGuard exact path='/mypage/trade/write/:type' component={TradeWrite} />
          <RouteGuard exact path='/mypage/trade/:type' component={TradeDetail} />
          <RouteGuard exact path='/mypage/leave' component={Leave} />
          <RouteGuard exact path='/mypage/cash-receipt' component={CashReceipt} />
        </Switch>
      </MypageProvider>
    </>
  );
};

export default Mypage;
