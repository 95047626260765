import { RegexUtil } from './regexUtil';

const currencyData = [
  { type: 'cash', value: 'c' },
  { type: 'point', value: 'p' },
  { type: 'experience', value: 'e' },
  { type: 'ea', value: '개' }
];

/**
 * @description 포멧 유틸
 * @export
 * @class RegexUtil
 */
export class FormatUtil {
  /**
   * @description 원화타입 리턴
   * @static
   * @param {*} type
   * @param {*} value
   * @returns
   */
  static currencyType(type, value) {
    return `${RegexUtil.makeComma(value)}${
      currencyData.filter((item) => item.type === type)[0].value
    }`;
  }

  /**
   * @description K, M 단위 리턴
   * @static
   * @param {*} value
   * @returns
   */
  static numberUnitConvert(value) {
    if (value === 0) {
      return 0;
    } else if (value > 1000000000) {
      return `${RegexUtil.makeComma((value / 1000000000).toFixed(1))}G`;
    } else if (value > 1000000) {
      return `${RegexUtil.makeComma((value / 1000000).toFixed(1))}M`;
    } else if (value > 1000) {
      return `${RegexUtil.makeComma((value / 1000).toFixed(1))}K`;
    } else {
      return `${RegexUtil.makeComma(value)}`;
    }
  }
}
