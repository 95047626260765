import { extendObservable, observable, computed, action } from 'mobx';
import autobind from 'autobind-decorator';

@autobind
class LolPlayerModel {
  @observable
  location = 'home';

  @observable
  champion = null;

  constructor(data, location) {
    extendObservable(this, data);

    this.location = location;
  }

  @computed
  get name() {
    const { player } = this;

    return player.nickname;
  }

  @computed
  get champ() {
    const { champion } = this;

    let defaultChampion = {
      cid: '0',
      img_path: '/sports/lol/champ/League-of-Legends-Game-Logo.jpg',
      name_en_full: '',
      name_full: '선택중',
      position: ''
    };

    if (!!champion) {
      for (let [key, value] of Object.entries(champion)) {
        if (!!champion[key]) {
          defaultChampion[key] = value;
        }
      }
    }

    return {
      name: defaultChampion.name_full.split(',')[0],
      imgPath: defaultChampion.img_path
    };
  }

  @computed
  get kda() {
    const { kill, death, assist } = this;

    return `${kill}/${death}/${assist}`;
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  updatePlayer(data) {
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  }
}

export default LolPlayerModel;
