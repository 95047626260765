import React from 'react';

const MypageCashHtml = () => {
  return (
    <>

      <div className="snb_wrap">
        <ul>
          <li><a className="item" href="javascript:;">충전내역</a></li>
          <li><a className="item selected" href="javascript:;">사용내역</a></li>
        </ul>
      </div>

      {/* history_wrap */}
      <div className="history_wrap cash">
        <div className="summary">
          <dl className="amass">
            <dt>보유 캐시</dt>
            <dd>3,400,000</dd>
          </dl>
          <dl className="today">
            <dt>오늘 충전 캐시</dt>
            <dd>5,400</dd>
          </dl>
        </div>
        <div className="list_area">
          <div className="tip_area">
            <p className="tx">※ 최대 3개월 이내의 내역만 확인 가능합니다.</p>
            <button type="button" className="btn_charge">충전하기</button>
          </div>
          <div className="list">
            <div className="item">
              <span className="info">
                <em className="tx">
                  골드 박스
                  <span className="count">(2개)</span>
                  <span className="type">구매</span>
                </em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
              <span className="num">-2,000</span>
            </div>
            <div className="item">
              <span className="info">
                <em className="tx">
                  랜덤 포인트 쪽지
                  <span className="count">(2개)</span>
                  <span className="type">선물</span>
                </em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
              <span className="num">-500</span>
            </div>
            <div className="item">
              <span className="info">
                랜덤 포인트 쪽지
                <span className="type">구매</span>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
              <span className="num">-500</span>
            </div>
          </div>
        </div>
      </div>
      {/* // history_wrap */}

    </>
  );
};

export default MypageCashHtml;
