import styled, { css } from 'styled-components';

export const List = styled.ul`
  background-color: #fff;
  padding: 0 20px;
  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    box-sizing: border-box;
    padding: 0 5px;
    :not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }
  }
`;

export const ItemInfo = styled.div`
  display: flex;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
  > div {
    display: flex;
    line-height: 13px;
    em {
      font-size: 14px;
      font-weight: 600;
      :after {
        display: inline-block;
        content: '・';
        width: 18px;
        text-align: center;
        color: #c7c7c7;
      }
    }
    span {
      font-size: 12px;
      color: #999;
    }
    strong {
      font-size: 12px;
      color: #ed2026;
    }
  }
  > p {
    font-size: 12px;
    line-height: 12px;
    color: #666;
    margin-top: 8px;
  }
`;

export const Active = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 20px;
  height: 18px;
  span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #aaa;
  }
`;

export const ButtonLayer = styled.div`
  position: absolute;
  width: 100px;
  border: 1px solid #999;
  background-color: #fff;
  right: 0;
  ${({is_bottom}) =>
          !is_bottom
                  ? css`top:50px;`
                  : css`bottom:50px;`
};
  z-index: 1;
    

  button {
    width: 100%;
    height: 37px;
    box-sizing: border-box;
    :first-child {
      color: #ed2026;
    }
    :nth-child(2) {
      border-top: 1px solid #e5e5e5;
    }
  }
`;

export const UsingItem = styled.li`
  height: 54px !important;
  em {
    font-size: 14px;
    font-weight: 600;
    color: #222;
  }
  span {
    font-size: 13px;
    ${(props) =>
      props.isNone &&
      css`
        color: #999;
      `}
  }
`;
