import styled from 'styled-components';

export const List = styled.ul`
  background-color: #fff;
  padding: 0 20px;
  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    box-sizing: border-box;
    padding: 0 5px;
    border-bottom: 1px solid #e5e5e5;
    > span {
      font-size: 12px;
      color: #999;
    }
    > div {
      display: flex;
      flex-direction: column;
      em {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 9px;
      }
      span {
        font-size: 10px;
        color: #999;
      }
    }
  }
`;
