import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import SoccerFinalButton from '../../soccer/component/finalButton';
import Calendar from '../../../../components/calendar';
import { timeUtils } from '../../../../common/utils/timeUtil';
import useScoreViewType from '../../../../hooks/useScoreViewType';
import Search from './search';

const ScoreContainer = observer(({ type }) => {
  const history = useHistory();
  const location = useLocation();
  const calendarOpenButtonRef = useRef();
  const { viewType, changeViewType } = useScoreViewType();
  const [isOpen, toggleOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(timeUtils.todayYmd());
  const [openSearch, setOpenSearch] = useState(false);
  const sportsType = useMemo(() => {
    if (type === 'hockey') {
      return 'icehockey';
    } else {
      return type;
    }
  }, [type]);

  useEffect(() => {
    setCurrentDate(timeUtils.todayYmd());
  }, [type]);

  useEffect(() => {
    const { search } = location;

    if (search.length === 0) {
      setCurrentDate(timeUtils.todayYmd());
    }
  }, [location]);

  useEffect(() => {
    const { search } = location;

    if (search.length > 0) {
      const date = search.split('=')[1];

      setCurrentDate(date);
    }
  }, []);

  const onClickToday = () => {
    onChangeCalendarPage(timeUtils.todayYmd());
    toggleOpen(false);
  };

  const onClickPrev = () => {
    const prev = timeUtils.datePrevOrNext(currentDate, 'prev');
    onChangeCalendarPage(prev);
  };

  const onClickNext = () => {
    const next = timeUtils.datePrevOrNext(currentDate, 'next');
    onChangeCalendarPage(next);
  };

  const onChangeCalendarPage = (date) => {
    history.push({
      pathname: `/score/${type}`,
      search: `?date=${date}`
    });

    setCurrentDate(date);
  };

  const onClickCalendarButton = () => {
    toggleOpen(!isOpen);
  };

  const onClickViewOption = () => {
    let changeViewTypeText = 'DEFAULT_VIEW';

    if (viewType === 'DEFAULT_VIEW') {
      changeViewTypeText = 'VERTICAL_VIEW';
    }

    changeViewType(changeViewTypeText);
  };

  return (
    <>
      {isOpen && (
        <Calendar
          onClickToday={onClickToday}
          currentDate={currentDate}
          onChangePage={onChangeCalendarPage}
          setCurrentDate={setCurrentDate}
          sportsType={sportsType}
          closeDrawer={() => toggleOpen(false)}
          calendarOpenButtonRef={calendarOpenButtonRef}
        />
      )}

      {/* score_summary */}
      <div className="score_summary">
        {type === 'soccer' ? (
          <button
            type={'button'}
            className={'btn_search'}
            onClick={() => setOpenSearch(!openSearch)}
          />
        ) : (
          <button
            type="button"
            className="btn_calendar"
            ref={calendarOpenButtonRef}
            onClick={onClickCalendarButton}
          >
            캘린더
          </button>
        )}
        {type === 'soccer' && (
          <Search currentDate={currentDate} open={openSearch} />
        )}
        <SoccerFinalButton sportsType={sportsType} date={currentDate} />
        <div className="date_area">
          <a className="btn_prev" onClick={onClickPrev}>
            이전 날짜
          </a>
          <strong className="date">
            {timeUtils.dateFormatCalendarYmd(currentDate)}
          </strong>
          <a className="btn_next" onClick={onClickNext}>
            다음 날짜
          </a>
        </div>
        <button
          type="button"
          className={clsx(
            'btn_view_option',
            viewType === 'VERTICAL_VIEW' && 'vertical_view'
          )}
          onClick={onClickViewOption}
        >
          보기 옵션 변경
        </button>
      </div>
      {/* // score_summary */}
    </>
  );
});

export default ScoreContainer;
