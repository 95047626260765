

export const payTypesHelper = (pay_type) => {
    let is_medal = false;
    let is_gold = false;
    let is_point = false;
    if(pay_type) {
        const pay_types = pay_type.split(',');
        pay_types.forEach((el)=> {
            // eslint-disable-next-line default-case
            switch (el) {
                case "MEDAL":
                    is_medal = true;
                    break;
                case "GOLD":
                    is_gold = true;
                    break;
                case "POINT":
                    is_point = true;
                    break;
            }
        })
    }
    return {
        is_gold,
        is_point,
        is_medal
    };
}

export const descriptionsHelper = (desc)=>{
    if(desc) return desc.split("<br>");
    else return null;
}