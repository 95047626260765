import React, { useEffect } from 'react';
import BaseballView from '../../baseball/view';
import BasketballView from '../../basketball/view';
import FootballView from '../../football/view';
import HockeyView from '../../hockey/view';
import LolView from '../../lol/view';
import SoccerView from '../../soccer/view';
import StarcraftView from '../../starcraft/view';
import VolleyballView from '../../volleyball/view';
import useStore from "../../../../hooks/useStore";
import Banner from "../../../../components/banner";

const withBanner = (WrappedComponent) => {
  return (props) => {
    const { bannerStore } = useStore();

    useEffect(() => {
      if (
        !bannerStore.activeMajorViewId ||
        bannerStore.activeMajorViewId === "placeholder"
      ) {
        if (Number.isInteger(props.item.id)) {
          bannerStore.setActiveMajorView(props.item.id);
        }
      }
    }, [bannerStore, bannerStore.activeMajorViewId, props.item.id]);
    return (
      <>
        <WrappedComponent {...props} />
        {bannerStore.activeMajorViewId === props.item.id ? (
          <Banner
            //            sportsBanner={bannerStore.sportsBanner}
            marginTop="10px"
            marginBottom="10px"
          />
        ) : null}
      </>
    );
  };
};

const ScoreTypeView = withBanner(({ sportsType, item, setSelectedGame, isSelected, fromHome = false }) => {
  if (sportsType === 'soccer' || sportsType === 'SOCCER') {
    return (
      <SoccerView
        item={item}
        setSelectedGame={setSelectedGame}
        isSelected={isSelected}
        fromHome={fromHome}
      />
    );
  } else if (sportsType === 'baseball' || sportsType === 'BASEBALL') {
    return (
      <BaseballView
        item={item}
        setSelectedGame={setSelectedGame}
        isSelected={isSelected}
        fromHome={fromHome}
      />
    );
  } else if (sportsType === 'basketball' || sportsType === 'BASKETBALL') {
    return (
      <BasketballView
        item={item}
        setSelectedGame={setSelectedGame}
        isSelected={isSelected}
        fromHome={fromHome}
      />
    );
  } else if (sportsType === 'volleyball' || sportsType === 'VOLLEYBALL') {
    return (
      <VolleyballView
        item={item}
        setSelectedGame={setSelectedGame}
        isSelected={isSelected}
        fromHome={fromHome}
      />
    );
  } else if (
    sportsType === 'hockey' ||
    sportsType === 'icehockey' ||
    sportsType === 'ICEHOCKEY'
  ) {
    return (
      <HockeyView
        item={item}
        setSelectedGame={setSelectedGame}
        isSelected={isSelected}
        fromHome={fromHome}
      />
    );
  } else if (sportsType === 'football' || sportsType === 'FOOTBALL') {
    return (
      <FootballView
        item={item}
        setSelectedGame={setSelectedGame}
        isSelected={isSelected}
        fromHome={fromHome}
      />
    );
  } else if (sportsType === 'lol' || sportsType === 'LOL') {
    return (
      <LolView
        item={item}
        setSelectedGame={setSelectedGame}
        isSelected={isSelected}
        fromHome={fromHome}
      />
    );
  } else if (sportsType === 'starcraft' || sportsType === 'STARCRAFT') {
    return (
      <StarcraftView
        item={item}
        setSelectedGame={setSelectedGame}
        isSelected={isSelected}
        fromHome={fromHome}
      />
    );
  } else {
    return null;
  }
});

export default ScoreTypeView;
