import React from 'react';
import { Redirect } from 'react-router-dom';
import { Menu } from "../../common/constants/menu";

const Main = () => {
  // let url = '/minigame/nball/powerball5';

  // if(Menu['minigame'][0].key === 'rball'){
  //   url = "/minigame/rball/space8";
  // }

  const url = '/home';

  return <Redirect to={url} />;
};

export default Main;
