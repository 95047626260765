import React from 'react';

const GamePickHtml = () => {
  return (
    <>
      <div className="minigame_wrap">

        <div className="game_view">
          중계화면 영역
        </div>
        <div className="snb_wrap">
          <ul>
            <li><a className="item" href="javascript:;">응원</a></li>
            <li><a className="item" href="javascript:;">방채팅</a></li>
            <li><a className="item" href="javascript:;">결과</a></li>
            <li><a className="item" href="javascript:;">분석</a></li>
            <li><a className="item selected" href="javascript:;">게임픽</a></li>
          </ul>
        </div>

        {/* game_stats_area */}
        <div className="game_stats_area">

          {/* option_tab */}
          <div className="option_tab">
            <ul>
              <li><a className="selected">픽분포도</a></li>
              <li><a>픽등록</a></li>
            </ul>
          </div>
          {/* // option_tab */}

          {/* option_area */}
          <div className="option_area">
            <div className="date_area">
              <a className="btn_prev" href="javascript:;">이전 날짜</a>
              <strong className="date">2021.01.07 - 212</strong>
              <a className="btn_next" href="javascript:;">다음 날짜</a>
            </div>
            <div className="option_type">
              <select>
                <option value="0" selected="selected">전체</option>
                <option value="1">파워볼 홀짝</option>
                <option value="2">파워볼 언오버</option>
                <option value="3">파워볼 구간</option>
                <option value="4">파워볼 홀짝+언오버</option>
                <option value="5">일반볼합 홀짝</option>
                <option value="6">일반볼합 언오버</option>
                <option value="7">일반볼합 대중소</option>
                <option value="8">일반볼합 홀짝+언오버</option>
                <option value="9">일반볼합 홀짝+대중소</option>
              </select>
            </div>
          </div>
          {/* // option_area */}

          {/* date_detail_wrap */}
          <div className="date_detail_wrap">

            {/* data_summary */}
            <div className="data_summary">
              <h3 className="tit">전체 픽 분포도</h3>
              <div className="chart">
                <dl className="bar ODD">
                  <dt>파워 홀</dt>
                  <dd><span className="num">116</span><span className="per">(47.15%)</span></dd>
                </dl>
                <dl className="bar EVEN on">
                  <dt>파워 짝</dt>
                  <dd><span className="num">130</span><span className="per">(52.85%)</span></dd>
                </dl>
              </div>
              <div className="chart">
                <dl className="bar UNDER">
                  <dt>파워 언더</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar OVER">
                  <dt>파워 오버</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
              </div>
              <div className="chart column4">
                <dl className="bar A">
                  <dt>파워 구간A</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar B on">
                  <dt>파워 구간B</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar C">
                  <dt>파워 구간C</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar D">
                  <dt>파워 구간D</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
              </div>
              <div className="chart column4 division">
                <dl className="bar ODD">
                  <dt>파워 홀언</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar ODD">
                  <dt>파워 홀오</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
                <dl className="bar EVEN on">
                  <dt>파워 짝언</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar EVEN">
                  <dt>파워 짝오</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
              </div>
              <div className="chart">
                <dl className="bar ODD">
                  <dt>일반 홀</dt>
                  <dd><span className="num">116</span><span className="per">(47.15%)</span></dd>
                </dl>
                <dl className="bar EVEN on">
                  <dt>일반 짝</dt>
                  <dd><span className="num">130</span><span className="per">(52.85%)</span></dd>
                </dl>
              </div>
              <div className="chart">
                <dl className="bar UNDER on">
                  <dt>일반 언더</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar OVER">
                  <dt>일반 오버</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
              </div>
              <div className="chart">
                <dl className="bar BIG">
                  <dt>일반 대</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
                <dl className="bar MIDDLE">
                  <dt>일반 중</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar SMALL on">
                  <dt>일반 소</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
              </div>
              <div className="chart column4">
                <dl className="bar ODD">
                  <dt>일반 홀언</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar ODD">
                  <dt>일반 홀오</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
                <dl className="bar EVEN on">
                  <dt>일반 짝언</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar EVEN">
                  <dt>일반 짝오</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
              </div>
              <div className="chart column6">
                <dl className="bar ODDBIG">
                  <dt>홀+대</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
                <dl className="bar ODDMIDDLE">
                  <dt>홀+중</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar ODDSMALL on">
                  <dt>홀+소</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar EVENBIG">
                  <dt>짝+대</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar EVENMIDDLE">
                  <dt>짝+중</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar EVENSMALL">
                  <dt>짝+소</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
              </div>
            </div>
            {/* // data_summary */}

            {/* pick_list_wrap */}
            <div className="pick_list_wrap">
              <h3 className="tit">유저의 픽</h3>
              <div className="pick_list">
                <div className="item">
                  <dl className="pick">
                    <dt className="ic EVEN">짝</dt>
                    <dd className="type">파워볼</dd>
                  </dl>
                  <div className="talk">어디로 갈지 망설이지 말고 저를 믿으셔야 합니다.</div>
                  <div className="nick">개미지옥</div>
                </div>
                <div className="item">
                  <dl className="pick">
                    <dt className="ic ODD">홀</dt>
                    <dd className="type">파워볼</dd>
                  </dl>
                  <div className="talk">어디로 갈지 망설이지 말고 저를 믿으셔야 합니다.</div>
                  <div className="nick">개미지옥</div>
                </div>
                <div className="item">
                  <dl className="pick">
                    <dt className="ic SMALL">소</dt>
                    <dd className="type">일반볼</dd>
                  </dl>
                  <div className="talk">어디로 갈지 망설이지 말고 저를 믿으셔야 합니다.</div>
                  <div className="nick">개미지옥</div>
                </div>
              </div>
            </div>
            {/* // pick_list_wrap */}

          </div>
          {/* // date_detail_wrap */}


          <br/><br/><br/><br/><br/><br/><br/>

          {/* pick_register_wrap */}
          <div className="pick_register_wrap">

            {/* pick_select */}
            <div className="pick_select">
              <h3 className="tit">픽 선택</h3>
              <div className="item">
                <button type="button" className="ODD selected">
                  <span className="tx">파워 홀</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="EVEN">
                  <span className="tx">파워 짝</span>
                  <span className="per">48.48%</span>
                </button>
              </div>
              <div className="item">
                <button type="button" className="UNDER">
                  <span className="tx">파워 언더</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="OVER">
                  <span className="tx">파워 오버</span>
                  <span className="per">48.48%</span>
                </button>
              </div>
              <div className="item">
                <button type="button" className="A">
                  <span className="tx">파워 구간A</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="B">
                  <span className="tx">파워 구간B</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="C">
                  <span className="tx">파워 구간C</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="D">
                  <span className="tx">파워 구간D</span>
                  <span className="per">48.48%</span>
                </button>
              </div>
              <div className="item division">
                <button type="button" className="ODDUNDER">
                  <span className="tx">파워 홀+언</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="ODDOVER">
                  <span className="tx">파워 홀+오</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="EVENUNDER">
                  <span className="tx">파워 짝+언</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="EVENOVER">
                  <span className="tx">파워 짝+오</span>
                  <span className="per">48.48%</span>
                </button>
              </div>
              <div className="item">
                <button type="button" className="ODD">
                  <span className="tx">일반 홀</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="EVEN">
                  <span className="tx">일반 짝</span>
                  <span className="per">48.48%</span>
                </button>
              </div>
              <div className="item">
                <button type="button" className="UNDER">
                  <span className="tx">일반 언더</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="OVER">
                  <span className="tx">일반 오버</span>
                  <span className="per">48.48%</span>
                </button>
              </div>
              <div className="item">
                <button type="button" className="LARGE">
                  <span className="tx">일반 대</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="MEDIUM">
                  <span className="tx">일반 중</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="SMALL">
                  <span className="tx">일반 소</span>
                  <span className="per">48.48%</span>
                </button>
              </div>
              <div className="item">
                <button type="button" className="ODDUNDER">
                  <span className="tx">일반 홀+언</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="ODDOVER">
                  <span className="tx">일반 홀+오</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="EVENUNDER">
                  <span className="tx">일반 짝+언</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="EVENOVER">
                  <span className="tx">일반 짝+오</span>
                  <span className="per">48.48%</span>
                </button>
              </div>
              <div className="item">
                <button type="button" className="ODDLARGE">
                  <span className="tx">홀대</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="ODDMEDIUM">
                  <span className="tx">홀중</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="ODDSMALL">
                  <span className="tx">홀소</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="EVENLARGE">
                  <span className="tx">짝대</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="EVENMEDIUM">
                  <span className="tx">짝중</span>
                  <span className="per">48.48%</span>
                </button>
                <button type="button" className="EVENSMALL">
                  <span className="tx">짝소</span>
                  <span className="per">48.48%</span>
                </button>
              </div>
            </div>
            {/* // pick_select */}

            {/* pick_message */}
            <div className="pick_message">
              <h3 className="tit">픽 메시지</h3>
              <div className="message_type">
                <select>
                  <option value="0" selected="selected">전체</option>
                  <option value="1">파워볼 홀짝</option>
                  <option value="2">파워볼 언오버</option>
                  <option value="3">파워볼 구간</option>
                  <option value="4">파워볼 홀짝+언오버</option>
                  <option value="5">일반볼합 홀짝</option>
                  <option value="6">일반볼합 언오버</option>
                  <option value="7">일반볼합 대중소</option>
                  <option value="8">일반볼합 홀짝+언오버</option>
                  <option value="9">일반볼합 홀짝+대중소</option>
                </select>
              </div>
              <button type="submit" className="btn_submit">픽등록하기</button>
            </div>
            {/* // pick_message */}

          </div>
          {/* // pick_register_wrap */}


        </div>
        {/* // game_stats_area */}

      </div>

    </>
  );
};

export default GamePickHtml;
