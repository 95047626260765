import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

const CertResult = observer(({ result }) => {
  useEffect(() => {
  }, [result])

  return (
    <>
      <div>result</div>
    </>
  );
});

export default CertResult;
