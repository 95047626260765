import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CertResultComponent } from '../../../components/cert';
// import style from './style.module.scss';

const CertResult = observer(({ location }) => {
  const queryString = new URLSearchParams(location.search);
  const [result, setResult] = useState('');

  useEffect(() => {
    setResult(queryString.get('certify_token'));
  }, [queryString]);

  return (
    <>
      <CertResultComponent result={result} />
    </>
  );
});

export default CertResult;
