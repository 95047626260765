import autobind from 'autobind-decorator';
import { action, computed, extendObservable } from 'mobx';
import CommunityCommentModel from '../comment';
import { Url } from '../../../../../common/constants/environment';
import { timeUtils } from '../../../../../common/utils/timeUtil';

@autobind
class CommunityDetailModel {
  constructor(data) {
    let deep = { ...data };

    if (!!deep.comment && deep.comment.length > 0) {
      const copyComment = [...deep.comment];

      const setCommentModel = this.setCommentModel(copyComment, []);

      deep.comment = setCommentModel;
    } else {
      deep.comment = [];
    }

    extendObservable(this, deep);
  }

  /**********************************************************
   * function
   **********************************************************/
  setCommentModel(comment, list) {
    comment.forEach((item) => {
      list.push(new CommunityCommentModel(item, false));

      if (!!item.comment) {
        item.comment.forEach((itemRe) => {
          list.push(new CommunityCommentModel(itemRe, true));

          if (!!itemRe.comment) {
            itemRe.comment.forEach((itemReRe) => {
              list.push(
                new CommunityCommentModel(itemReRe, true, itemRe.mb_nick)
              );
            });
          }
        });
      }
    });

    return list;
  }

  /**********************************************************
   * computed
   **********************************************************/

  @computed
  get date() {
    let date = this.wr_datetime;

    if (!date.includes('T')) {
      date = date.replace(' ', 'T');
    }

    return timeUtils.yyyyMMddHHmm(date);
  }

  @computed
  get user() {
    const { member_info } = this;

    if (!!member_info) {
      const copy = { ...member_info };
      let profileImg = '/images/thumb_user_def.png';

      if (!!member_info.profile_img) {
        profileImg = `${Url.thumb}normal/crop/112x112${member_info.profile_img}`;
      }

      copy.profile_img = profileImg;

      return copy;
    } else {
      return {
        level: '0',
        profile_img: '/images/thumb_user_def.png',
        today_word: '',
        gender: null,
        level_title: '',
        nick_color: null
      };
    }
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  commentReGenerator(comments) {
    this.comment = this.setCommentModel(comments, []);
  }
}

export default CommunityDetailModel;
