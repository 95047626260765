import autobind from 'autobind-decorator';
import { action, observable, runInAction } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { BannerService } from '../services';
import { Url } from '../constants/environment';
import { preloadMedia } from '../../common/cache/mediaCache';

@autobind
class BannersStore {
  @action setActiveMajorView(id) {
    runInAction(() => {
      this.activeMajorViewId = id;
    });
  }

  @action clearActiveMajorView() {
    runInAction(() => {
      this.activeMajorViewId = null;
      this.isBannerLoaded = false;
    });
  }

  @action setSportType(sportType) {
    runInAction(() => {
      this.sportType = sportType;
    });
  }

  @observable
  activeMajorViewId = null;

  @observable
  isBannerLoaded = false;

  @observable
  code = 200;

  @observable
  message = '';

  @observable
  data = {};

  @observable
  mobileBanner = [];

  @observable
  mobilePopup = [];

  @observable
  sportsMobileBannerLeft = [];

  @observable
  sportsMobileBannerRight = [];

  @observable
  sportType = null;

  @action
  setBannerLoaded = () => {
    runInAction(() => {
      this.isBannerLoaded = true;
    });
  };

  @autobind
  @asyncAction
  *getList() {
    const res = yield BannerService.getList();
    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
        //        this.mobileBanner = res.data.mobileBanner;
        this.popup = res.data.popup;
        let host = window.location.hostname;
        //        console.log(this.sportType)
        if (this.sportType) {
          //          console.log(banners)
          this.mobileBanner = res.data.mobileBanner.filter(
            (item) => item != null && item.sport_type.includes(this.sportType)
          );
          if (host === Url.hostNamedWeb) {
            this.mobileBanner = this.mobileBanner.filter((item) => item.display === 'Y');
          }
          if (host === `stage.${Url.hostNamedWeb}`) {
            this.mobileBanner = this.mobileBanner.filter((item) => item.display_stage === 'Y');
          }
          //          this.mobileBanner = res.data.mobileBanner;
          this.isBannerLoaded = true;
        } else {
          // this.mobileBanner = res.data.mobileBanner;
          this.mobileBanner = [];
          this.isBannerLoaded = false;
        }

        this.sportsMobileBannerLeft = res.data?.sportsMobileBannerLeft || [];
        this.sportsMobileBannerRight = res.data?.sportsMobileBannerRight || [];

        //Only show banner that has display flag equal to Y in its environment
        if (host === 'm.named.com' || host === 'm.nmd.co') {
          this.sportsMobileBannerLeft = this.sportsMobileBannerLeft.filter((item) => item.display === 'Y');
          this.sportsMobileBannerRight = this.sportsMobileBannerRight.filter((item) => item.display === 'Y');
        }
        if (host === 'm-stage.named.com' || host === 'm-stage.nmd.co') {
          this.sportsMobileBannerLeft = this.sportsMobileBannerLeft.filter((item) => item.display_stage === 'Y');
          this.sportsMobileBannerRight = this.sportsMobileBannerRight.filter((item) => item.display_stage === 'Y');
        }

        preloadMedia(this.sportsMobileBannerLeft?.map((item) => item.imgPath));
        preloadMedia(this.sportsMobileBannerRight?.map((item) => item.imgPath));
      });
    } else {
      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
        this.mobileBanner = {};
      });
    }
    return res.code === 200;
  }

  @autobind
  @asyncAction
  *getListPopups() {
    const res = yield BannerService.getList();
    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
        this.mobilePopup = res.data.mobilePopup;
        this.isBannerLoaded = true;
      });
    } else {
      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
    return res.code === 200;
  }
}

//const bannerStore = new BannersStore();
export default BannersStore;
