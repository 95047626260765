import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import useUsersStore from '../../../hooks/useUsersStore';
import useModalStore from '../../../hooks/useModalStore';

const NickChange = observer(({ history, onClose, category = '' }) => {
  const usersStore = useUsersStore();
  const modalStore = useModalStore();
  const { mypageStore } = useMypageStore(MypageContext);
  const [nick, setNick] = useState('');

  const onClickNickChange = () => {
    if (!mypageStore.isNickCheck) {
      modalStore.openAlertModal({
        message: `닉네임 중복 체크 후 변경 가능합니다.`
      });
      return;
    }

    if (usersStore.data.certify.is_phone_certify) {
      updateNick();
    } else {
      usersStore.modalStore.openConfirmModal({
        message: `닉네임 변경 아이템을 사용 하시겠습니까?`,
        okCallback: () => {
          updateNick();
        }
      });
    }
  };

  const updateNick = () => {
    const pathname = history.location.pathname.replace('/mypage/', '');
    mypageStore.actionNickChange({ nick, pathname }, () => {
      usersStore.getInfo();
      mypageStore.clearNickCheck();
      if (pathname === 'item') {
        mypageStore.actionMyItemList({
          category: category === 'all' ? '' : category
        });
      }
      onClose();
    });
  };

  const onClickCancel = () => {
    onClose();
  };

  const onClickNickCheck = () => {
    mypageStore.actionNickCheck({ nick });
  };

  const goMarket = () => {
    history.push('/mypage/market');
  };

  return (
    <>
      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">닉네임 변경</h2>
          <button className={'btn_close'} onClick={onClickCancel} />
        </div>
        <div className="info_change_area">
          <div className="inner">
            {usersStore.data.certify.is_phone_certify ? (
              <p className="tx">
                닉네임 변경권(<strong>본인인증 완료</strong>)
              </p>
            ) : (
              <p className="tx">
                닉네임 변경권{' '}
                <strong>
                  {usersStore.data.item.change_nick
                    ? usersStore.data.item.change_nick
                    : 0}
                  개
                </strong>{' '}
                보유중
              </p>
            )}
            <div className="item">
              <input
                type="text"
                className="inputbox"
                autoComplete="off"
                tabIndex="1"
                title="닉네임"
                placeholder="닉네임을 입력해주세요."
                onChange={(e) => {
                  setNick(e.target.value);
                }}
              />
              <button type="button" onClick={onClickNickCheck}>
                중복체크
              </button>
            </div>
            <p className="error">
              닉네임은 공백없이 한글, 영문, 숫자만 입력 가능합니다.
            </p>
            {/*
            <p class="error">사용 중인 닉네임입니다. 다른 닉네임을 사용해 주세요.</p>
            */}
          </div>
          <div className="btn_area">
            <button type="submit" className="btn" onClick={onClickCancel}>
              취소
            </button>
            <button
              type="submit"
              className="btn btn_submit"
              onClick={onClickNickChange}
            >
              변경하기
            </button>
          </div>
        </div>
        <div className="tip">
          <p>
            변경권이 없으신가요?{' '}
            <a className="lnk" href="javascript:;" onClick={goMarket}>
              닉네임 변경권 구매하기
            </a>
          </p>
          <p className="c_999">
            닉네임을 변경하지 않고 페이지를 닫으실 경우 해당 아이템은 사용되지
            않습니다.
          </p>
          <p className="c_999">
            한글, 영문, 숫자를 혼용하여 사용할 수 있습니다.
            (한글2자,영문/숫자4자 이상, 8자 이하)
          </p>
          <p className="c_999">
            욕설이나 미풍양속에 어긋나는 닉네임 사용으로 신고 접수 시 ID이용이
            제한될 수 있습니다.
          </p>
        </div>
      </div>
    </>
  );
});

export default NickChange;
