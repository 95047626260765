import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { List, Summary } from "./styled"
import { ImageUtil } from "../../../../common/utils/imageUtil"
import { RegexUtil } from "../../../../common/utils/regexUtil"
import ItemModal from "./itemModal"
import { PopupModal } from "../../../../components/modal/popup/popupModal"
import ItemGift from "../../item_gift"
import { descriptionsHelper, payTypesHelper } from "./utils/utils"
import {getColorPayType} from "./itemModal/helpers";

const MypageMarketList = observer(({ items = [], history }) => {
	const [isOpen, setOpen] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [isOpenBuy, setOpenBuy] = useState(false)
	const [giftComponent, setGiftComponent] = useState(null)

	const onClickItem = (item) => {
		setOpen(true)
		setSelectedItem(item)
	}

	const onClickGift = (count, payType) => {
		setOpenBuy(true)
		setGiftComponent(
			<ItemGift
				history={history}
				onClose={() => setOpenBuy(false)}
				count={count}
				item={selectedItem}
				payType={payType}
			/>
		)
	}

	return (
		<>
			<List>
				{items.map((item, key) => {
					return <Item key={key} item={item} onClickItem={onClickItem} />
				})}
			</List>
			{isOpen && (
				<ItemModal
					isOpen={isOpen}
					onClose={() => setOpen(false)}
					item={selectedItem}
					onClickGift={onClickGift}
				/>
			)}
			<PopupModal isOpen={isOpenBuy} onClose={() => setOpenBuy(false)}>
				{giftComponent}
			</PopupModal>
		</>
	)
})

const Item = ({ item, onClickItem }) => {
	const {
		bonus_exp,
		cash,
		desc,
		disp_order,
		display_cash,
		display_medal,
		display_point,
		giftable,
		id,
		image,
		mark,
		medal,
		name,
		pay_type,
		point,
		tp,
	} = item

	const descriptions = descriptionsHelper(desc)
	const payTypeResult = payTypesHelper(pay_type)

	return (
		<li onClick={() => onClickItem(item)}>
			<img src={ImageUtil.imageUrl(image, 0, 50)} />
			<Summary>
				<div>
					<span className={"name"}>{name}</span>
					{bonus_exp > 0 && (
						<span className={"exp"}>
							{`+ ${RegexUtil.makeComma(bonus_exp)} EXP`}
						</span>
					)}
				</div>
				<div>
					<p>
						{descriptions &&
							descriptions.map((el, i) => (
								<React.Fragment key={i}>
									<span>{el}</span>
									<br />
								</React.Fragment>
							))}
					</p>
				</div>
				<div>
					{payTypeResult.is_gold && (
						<div className={"price-box"}>
							{cash !== display_cash && display_cash !== "0" && (
								<span className={"display-price"}>
									{`${RegexUtil.makeComma(display_cash)} 캐시`}
								</span>
							)}
							<span className={"cash price"}>{`${RegexUtil.makeComma(
								cash
							)} 캐시`}</span>
						</div>
					)}
					{payTypeResult.is_medal && (
						<div className={"price-box"}>
							{medal !== display_medal && display_medal !== "0" && (
								<span className={"display-price"}>
									{`${RegexUtil.makeComma(display_medal)} 건빵`}
								</span>
							)}
							<span className={"medal price"}>{`${RegexUtil.makeComma(
								medal
							)} 건빵`}</span>
						</div>
					)}

					{payTypeResult.is_point && (
						<div className={"price-box"}>
							{point !== display_point && display_point !== "0" && (
								<span className={"display-price"}>
									{`${RegexUtil.makeComma(display_point)} 포인트`}
								</span>
							)}
							<span className={"point price"}>{`${RegexUtil.makeComma(
								point
							)} 포인트`}</span>
						</div>
					)}
				</div>
			</Summary>
		</li>
	)
}

export { MypageMarketList }