import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { NavMenu } from '../components';
import { PowerLadderResult } from '../components/powerLadder/result';
import PowerLadderAnalysis from '../components/powerLadder/analysis';
import { PowerLadderPick } from '../components/powerLadder/pick';
import { RoomChatList } from '../components/roomChat';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import {Url} from "../../../common/constants/environment";
import useStore from "../../../hooks/useStore";
import {timeUtils} from "../../../common/utils/timeUtil";
import BetSection from "../betSection";

const PowerLadder = observer(({ history }) => {
  const { pathname } = useLocation();
  const { clockStore } = useStore();

  const brandName = pathname?.split('/')[2];
  const gameName = pathname?.split('/')[3];

  const gameViewRef = useRef(null);
  const gameViewIframeRef = useRef(null);

  const query = new URLSearchParams(history.location.search);
  const tab = query.get('tab') || 'result';
  const [isSmallView, toggleSmallView] = useState(false);

  const [currentRound, setCurrentRound] = useState(0);
  const [tabSelect, setTabSelect] = useState();

  useEffect(() => {
    const width = document.getElementsByTagName('body')[0].offsetWidth;
    const frameHeight = parseInt((width * 390) / 300);
    const frameScale = (width / 300).toFixed(3);

    gameViewRef.current.style.height = `${frameHeight}px`;
    gameViewIframeRef.current.style.transform = `scale(${frameScale})`;
  }, []);

  useEffect(() => {
    toggleSmallView(false);
  }, [tab]);

  useEffect(() => {
    if (gameName === "powerladder5") {
      setCurrentRound(clockStore.getPower5MinRound());
    } else if (gameName === "powerladder3") {
      setCurrentRound(clockStore.getPower3MinRound());
    } else {
      setCurrentRound(clockStore.getRedBallRound5min());
    }
  }, [gameName]);

  useEffect(() => {
    history.push(`${pathname}?tab=analysis${getTabQueryParams(tabSelect)}`)
  }, [pathname, tabSelect]);

  window.addEventListener('message', (e) => {
      if (e.data.changeTab) {
        setTabSelect(e.data.changeTab.analysisType)
        window.scrollTo({
          top: 500,
          behavior: 'smooth'
        });
      }
  })

  const optionsMap = {
    daily: [
      { value: "oneDay", name: "하루씩보기" },
      { value: "period", name: "기간으로보기" }
    ],
    round: [
      { value: "single", name: "단일회차" },
      { value: "latest", name: "최근회차" }
    ],
    pattern: [
      { value: "odd_even", name: "결과" },
      { value: "start_point", name: "출발" },
      { value: "line_count", name: "줄수" },
      { value: "combination", name: "출발+줄수" }
    ]
  };

  const getTabQueryParams = (analysisType) => {
    switch (analysisType) {
      case 'daily':
        return '&type=daily&option=oneDay';
      case 'round':
        return `&type=round&option=single&start_dt=${timeUtils.addDay(new Date(), -6).value}&round=${currentRound}`;
      case 'pattern':
        return `&type=pattern&option=${optionsMap.pattern[0].value}`;
      default:
        return '';
    }
  }

  const component = useMemo(() => {
    switch (tab) {
      case 'room_chat':
        return <RoomChatList roomType={'lottery'} />;
      case 'analysis':
        return (
          <PowerLadderAnalysis
            brandName={brandName}
            gameName={gameName}
          />
        );
      case 'pick':
        return (
          <PowerLadderPick
            brandName={brandName}
            gameName={gameName}
          />
        );
      default:
        return (
          <PowerLadderResult
            brandName={brandName}
            gameName={
              gameName
            }
          />
        );
    }
  }, [tab, isSmallView]);

  return (
    <>
      <div className="minigame_wrap">
        <div
          ref={gameViewRef}
          className={clsx('game_view', 'powerladder', isSmallView && 'small')}
        >
          <iframe
            ref={gameViewIframeRef}
            scrolling="no"
            frameBorder="0"
            src={`${Url.namedWeb}minigame/${brandName}/${gameName}/view.php?view_mode=m`}
          />
          {/*<Link to={'/minigame'} className="btn_prev">*/}
          {/*  이전으로*/}
          {/*</Link>*/}
        </div>
        <BetSection gameType={gameName}/>
        <NavMenu />
        {component}
      </div>

      {/* NEW ADS #1 구글 검열로 인해 비활성화*/}
      {/*<BannerGA*/}
      {/*    containerStyle={{*/}
      {/*      display: 'block',*/}
      {/*      width: 450,*/}
      {/*      height: 50,*/}
      {/*      marginTop: 16,*/}
      {/*    }}*/}
      {/*    gaSlot={'5003058707'}*/}
      {/*    width={450}*/}
      {/*    height={50}*/}
      {/*/>*/}
    </>
  );
});

export default PowerLadder;
