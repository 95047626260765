import React, { useEffect, useMemo, useState, useRef } from "react";
import { observer } from "mobx-react";
import { NavMenu } from "../components/navMenu";
import { PowerballResult } from "../components/powerball/result";
import { PowerballAnalysis } from "../components/powerball/analysis";
import { PowerballPick } from "../components/powerball/pick";
import { RoomChatList } from "../components/roomChat";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { DhPowerballPick } from "../components/powerball/pick/DhPick";
import {Url} from "../../../common/constants/environment";
import BannerGA from "../../../components/banner/BannerGA";
import {timeUtils} from "../../../common/utils/timeUtil";
import useStore from "../../../hooks/useStore";
import BetSection from "../betSection";

const Powerball = observer(({ history }) => {
  const { pathname } = useLocation();
  const { clockStore } = useStore();
  const brandName = pathname?.split("/")[2];
  const gameName = pathname?.split("/")[3];

  const gameViewRef = useRef(null);
  const gameViewIframeRef = useRef(null);

  const query = new URLSearchParams(history.location.search);
  const tab = query.get("tab") || "result";
  const [isSmallView, toggleSmallView] = useState(false);

  const [currentRound, setCurrentRound] = useState(0);
  const [tabSelect, setTabSelect] = useState();

  useEffect(() => {
    toggleSmallView(false);
  }, [tab]);

  useEffect(() => {
    if (gameName === "powerball5") {
      setCurrentRound(clockStore.getPower5MinRound());
    } else if (gameName === "powerball3") {
      setCurrentRound(clockStore.getPower3MinRound());
    } else {
      setCurrentRound(clockStore.getRedBallRound5min());
    }
  }, [gameName]);

  useEffect(() => {
    history.push(`${pathname}?tab=analysis${getTabQueryParams(tabSelect)}`)
  }, [pathname, tabSelect]);

  window.addEventListener('message', (e) => {
      if (e.data.changeTab) {
        setTabSelect(e.data.changeTab.analysisType)
        window.scrollTo({
          top: 500,
          behavior: 'smooth'
        });
      }
  })

  const isDhLottery = useMemo(() => {
    return brandName === "dhlottery";
  }, [brandName]);

  const optionsMap = useMemo(() => {
    return {
      daily: [
        { value: "oneDay", name: "하루씩보기" },
        { value: "period", name: "기간으로보기" }
      ],
      round: [
        { value: "single", name: "단일회차" },
        { value: "latest", name: "최근회차" }
      ],
      pattern:
        !isDhLottery ? [
            { value: "powerball_odd_even", name: "파워볼 홀짝" },
            { value: "powerball_under_over", name: "파워볼 언오버" },
            { value: "powerball_section", name: "파워볼 구간" },
            { value: "powerball_odd_even_under_over", name: "파워볼 홀짝+언오버" },
            { value: "sum_odd_even", name: "일반볼합 홀짝" },
            { value: "sum_under_over", name: "일반볼합 언오버" },
            { value: "sum_size", name: "일반볼합 대중소" },
            { value: "sum_odd_even_under_over", name: "일반볼합 홀짝+언오버" },
            { value: "sum_odd_even_size", name: "일반볼합 홀짝+대중소" }
          ] :
          [
            { value: "sum_odd_even", name: "일반볼합 홀짝" },
            { value: "sum_size", name: "일반볼합 대중소" },
            { value: "powerball_under_over", name: "파워볼 언오버" }
          ]
    };

  }, [isDhLottery]);

  const getTabQueryParams = (analysisType) => {
    switch (analysisType) {
      case 'daily':
        return '&type=daily&option=oneDay';
      case 'round':
        return `&type=round&option=single&start_dt=${timeUtils.addDay(new Date(), -6).value}&round=${currentRound}`;
      case 'pattern':
        return `&type=pattern&option=${optionsMap.pattern[0].value}`;
      default:
        return '';
    }
  }

  useEffect(() => {
    const width = document.getElementsByTagName("body")[0].offsetWidth;
    const frameHeight = parseInt((width * 390) / 300);
    const frameScale = (width / 300).toFixed(3);

    gameViewRef.current.style.height = `${frameHeight}px`;
    gameViewIframeRef.current.style.transform = `scale(${frameScale})`;
  }, []);

  const component = useMemo(() => {
    switch (tab) {
      case "room_chat":
        return <RoomChatList roomType={"lottery"} />;
      case "analysis":
        return (
          <PowerballAnalysis
            brandName={brandName}
            gameName={gameName}
          />
        );
      case "pick":
        if (brandName === "dhlottery") {
          return <DhPowerballPick brandName={brandName}
                                gameName={gameName} />;
        } else {
          return <PowerballPick brandName={brandName}
                                gameName={gameName} />;
        }

      default:
        return (
          <PowerballResult
            brandName={brandName}
            gameName={
              gameName
            }
          />
        );
    }
  }, [tab, isSmallView, gameName]);

  return (
      <>
        <div className="minigame_wrap">
          <div
              ref={gameViewRef}
              className={clsx("game_view", "powerball", isSmallView && "small")}
          >
            <iframe
                ref={gameViewIframeRef}
                scrolling="no"
                frameBorder="0"
                src={Url.namedWeb + `/minigame/${brandName}/${gameName}/view.php?view_mode=m`}
            />
            {/*<Link to={'/game'} className="btn_prev">*/}
            {/*  이전으로*/}
            {/*</Link>*/}
          </div>
          <BetSection gameType={gameName}/>
          <NavMenu/>
          {component}
        </div>

        {/* NEW ADS #1 구글 검열로 인해 비활성화*/}
        {/*<BannerGA*/}
        {/*    containerStyle={{*/}
        {/*      display: 'block',*/}
        {/*      width: 450,*/}
        {/*      height: 50,*/}
        {/*      marginTop: 16,*/}
        {/*    }}*/}
        {/*    gaSlot={'5003058707'}*/}
        {/*    width={450}*/}
        {/*    height={50}*/}
        {/*/>*/}
      </>
  );
});

export default Powerball;
