import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtoCalculator from '.';
import ProtoMyPick from '../protoMyPick';
import ProtoMyPickDetail from '../protoMyPick/detail/protoMyPickDetail';
import ProtoMyPickPaper from '../protoMyPick/paper/protoMyPickPaper';

const ProtoCalcRoutes = () => {
  return (
    <Switch>
      <Route exact path="/score/proto/calculator" component={ProtoCalculator} />
      <Route
        exact
        path="/score/proto/calculator/my-pick"
        component={ProtoMyPick}
      />
      <Route
        exact
        path="/score/proto/calculator/my-pick/detail/:id"
        component={ProtoMyPickDetail}
      />
      <Route
        exact
        path="/score/proto/calculator/my-pick/paper/:id"
        component={ProtoMyPickPaper}
      />
    </Switch>
  );
};

export default ProtoCalcRoutes;
