import React, { useEffect, useState } from 'react';
import style from './style.module.scss';

const MypageLoginLog = ({ items = [] }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (items.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [])

  return (
      <div className="list">
      {
        items.map((item, i) =>  <div className="item" key={i}>
          <span className={item.result === 'Y' ? 'state' : 'state fail'}>{item.result === 'Y' ? '성공' : '실패'}</span>
          <span className="info">
                <em className="tx"> {item.ip} : {item.device === 'P' ? '데스크탑' : '모바일'}</em>
                <em className="date">{item.reg_dt}</em>
          </span>
        </div>)
      }
    </div>
  );
};

export {
  MypageLoginLog
};
