import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {is_pick_ranking} from '../../util';
import {Url} from "../../../../common/constants/environment";

const NavMenu = ({}) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const isHoldem = location.pathname.includes('holdem');

  const isRanking = is_pick_ranking();

  const tab = query.get('tab') || (isRanking? 'pick_rank': 'result');
//  const isRanking = path[2] === 'rball';

  // remove it once pick ranking developed for space8
//  if(path[3] ==='space8') isRanking = false;

  return (
    <div className="snb_wrap">
      <ul>
        {isRanking &&
            <li>
              <Link
                  to={`${location.pathname}?tab=pick_rank`}
                  className={clsx('item', tab === 'pick_rank' && 'selected')}
              >
                <div style={{justifyContent: "center", alignItems: "center"}}>
                  <img src={Url.imageNamed + "portal/ic_new.png"} style={{marginRight: "2px"}}/>
                  랭킹
                </div>
              </Link>
            </li>
        }
        <li>
          <Link
              to={`${location.pathname}?tab=result`}
              className={clsx('item', tab === 'result' && 'selected')}
          >
            결과
          </Link>
        </li>
        <li>
          <Link
              to={`${location.pathname}?tab=room_chat`}
              className={clsx('item', tab === 'room_chat' && 'selected')}
          >
            테마채팅
          </Link>
        </li>
        {isHoldem ? (
            <li>
              <Link
                  to={`${location.pathname}?tab=guide`}
                  className={clsx('item', tab === 'guide' && 'selected')}
              >
                설명
              </Link>
            </li>
        ) : (
            <>
              <li>
                <Link
                    to={`${location.pathname}?tab=analysis`}
                    className={clsx('item', tab === 'analysis' && 'selected')}
                >
                  분석
                </Link>
              </li>
              <li>
                <Link
                    to={`${location.pathname}?tab=pick`}
                    className={clsx('item', tab === 'pick' && 'selected')}
                >
                  게임픽
                </Link>
              </li>
            </>
        )}
      </ul>
    </div>
  );
};

export {NavMenu};
