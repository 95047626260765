import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import useCommonStore from '../../../hooks/useCommonStore';

const CertIpin = observer(({ resultUrl }) => {
  const commonStore = useCommonStore();
  const [encData, setEncData] = useState('');

  const cert = async () => {
    commonStore.getCertIpinPage({ encData });
  };

  const init = () => {
    commonStore.getCertIpinEncData({ resultUrl }).then((res) => {
      if (res.code === 200) {
        setEncData(res.data.enc_data);
      } else {
        console.log(res.message);
      }
    });
  };

  useEffect(() => {
    if (!resultUrl) {
      return;
    }

    init();
  }, [resultUrl]);

  return (
    <button className="btn btn_ipin" onClick={cert}>
      아이핀 인증
    </button>
  );
});

export default CertIpin;
