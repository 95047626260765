import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { AlertModal } from './alert';
import { ConfirmModal } from './confirm';
import useModalStore from '../../hooks/useModalStore';

const Modals = {
  AlertModal,
  ConfirmModal
};

export const ModalComponent = observer(() => {
  const modalStore = useModalStore();
  const Modal = useMemo(() => {
    return !!modalStore.type ? Modals[modalStore.type] : 'div';
  }, [modalStore.type]);

  return <Modal option={modalStore.option} />;
});
