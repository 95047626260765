// mediaCache.js - Shared module for both image and video caching
export const cachedImages = new Map();
export const cachedVideos = new Map(); // New map for video players
export const loadedMedia = new Set();

/**
 * Add an image to the cache
 * @param {string} src - Original image URL
 * @param {string} objectURL - Blob URL for the cached image
 */
export const cacheImage = (src, objectURL) => {
  cachedImages.set(src, objectURL);
  loadedMedia.add(src);
};

/**
 * Get a cached image if available
 * @param {string} src - Original image URL
 * @returns {string|null} - Cached blob URL or null
 */
export const getCachedImage = (src) => {
  return cachedImages.get(src) || null;
};

/**
 * Check if an image exists in the cache
 * @param {string} src - Original image URL
 * @returns {boolean}
 */
export const hasCachedImage = (src) => {
  return cachedImages.has(src);
};

/**
 * Add a video player reference to the cache
 * @param {string} url - Video URL
 * @param {Object} playerData - Video player data (duration, loaded status, etc.)
 */
export const cacheVideoData = (url, playerData) => {
  cachedVideos.set(url, playerData);
  loadedMedia.add(url);
};

/**
 * Get cached video data if available
 * @param {string} url - Video URL
 * @returns {Object|null} - Cached video data or null
 */
export const getCachedVideoData = (url) => {
  return cachedVideos.get(url) || null;
};

/**
 * Check if video data exists in the cache
 * @param {string} url - Video URL
 * @returns {boolean}
 */
export const hasCachedVideoData = (url) => {
  return cachedVideos.has(url);
};

/**
 * Preloads images and videos to ensure they are ready when needed.
 * Now integrates with both image and video cache systems.
 * @param {Array<string>} mediaList - Array of media URLs (images/videos)
 * @returns {Promise} A Promise that resolves when all media is preloaded
 */
export const preloadMedia = (mediaList) => {
  return new Promise((resolve) => {
    if (!mediaList || mediaList.length === 0) {
      resolve();
      return;
    }

    let loadedCount = 0;
    const totalMedia = mediaList.length;

    const checkAllLoaded = () => {
      loadedCount++;
      if (loadedCount === totalMedia) {
        resolve(); // All media loaded
      }
    };

    mediaList.forEach((url) => {
      // Skip already loaded media
      if (loadedMedia.has(url) || hasCachedImage(url) || hasCachedVideoData(url)) {
        checkAllLoaded();
        return;
      }

      // Mark as being loaded
      loadedMedia.add(url);

      if (/\.(jpeg|jpg|png|gif|webp|svg)$/i.test(url)) {
        // For images, use our caching system
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const objectURL = URL.createObjectURL(blob);
            cacheImage(url, objectURL);
            checkAllLoaded();
          })
          .catch(() => {
            checkAllLoaded(); // Even on error, we mark as processed
          });
      } else if (/\.(mp4|webm|ogg|mov|avi|mkv)$/i.test(url)) {
        const video = document.createElement('video');
        video.src = url;
        video.preload = 'auto';
        video.oncanplaythrough = checkAllLoaded;
        video.onerror = checkAllLoaded;
        cacheVideoData(url, { player: video, loaded: false });
      } else {
        checkAllLoaded();
      }
    });
  });
};

/**
 * Clear the entire media cache
 */
export const clearMediaCache = () => {
  // Revoke all object URLs to prevent memory leaks
  cachedImages.forEach((objectURL) => {
    try {
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error('Error revoking object URL:', error);
    }
  });

  cachedImages.clear();
  cachedVideos.clear();
  // We don't clear loadedMedia as it might be used for tracking purposes
};
