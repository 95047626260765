import styled, { css } from 'styled-components';

export const List = styled.ul`
  background-color: #fff;
  width: 100%;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 90px;
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom: 1px solid #e5e5e5;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      background-color: #f8f8f8;
    `}
`;

export const Summary = styled.div`
  margin-left: 19px;
  width: calc(100% - 70px);
  > div {
    line-height: 13px;
    .name {
      font-size: 14px;
      font-weight: 600;
      :after {
        display: inline-block;
        content: '・';
        width: 18px;
        text-align: center;
        color: #c7c7c7;
      }
    }
    .point {
      font-size: 12px;
      color: #1287eb;
    }
    > p {
      font-size: 11px;
      line-height: 11px;
      color: #999;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 8px;
    }
  }
`;
