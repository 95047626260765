import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import {RegexUtil} from "../../../../common/utils/regexUtil";

const MypageTpList = ({ items = [] }) => {
  const [isLoading, setIsLoading] = useState(false);

    const replaceContent = (content) => {
      if (content === undefined) {
        return content
      }
      let contentArray = content.split('-');

      switch (contentArray[0]) {
        case 'XBT':
          content = '비트멕스(XBTUSD) 모의투자 ';

          if (contentArray[1] === 'LONG') {
            content += '<span class="long">상승</span> ';
          } else if (contentArray[1] === 'SHORT'){
            content += '<span class="short">하락</span> ';
          }

          if (contentArray[2] === 'TRADE') {
            content += '';
          } else if (contentArray[2] === 'SETTLE') {
            content += '실현';
          }
          break;

        case 'GIFT':
          content = '<b>' + contentArray[2] + '</b>님에게 선물';

          if (contentArray[1] === 'RECV') {
            content += ' 받음';
          }
          break;
      }

      return content;
    }



  useEffect(() => {
    if (items.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [])

  return (
      <div className="list">
      { items.map((item, i) => <div className="item" key={i}>
        <span className="info">
        <em className="tx"><div dangerouslySetInnerHTML={{__html:replaceContent(item.content)}}/></em>
          <em className="date">{item.reg_dt}</em>
        </span>
        <span className={item.point < 0 ? 'num minus' : 'num'}>{item.point > 0 ?? '+'}{item.point !== undefined ? RegexUtil.makeComma(item.point) : 0}</span>
        </div>)
      }
    </div>
  );
};

export {
  MypageTpList
};
