import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../../../context';
import { Active, ButtonLayer, ItemInfo, List, Summary, UsingItem } from './styled';
import { ImageUtil } from '../../../../../common/utils/imageUtil';
import useModalStore from '../../../../../hooks/useModalStore';
import { PopupModal } from '../../../../../components/modal/popup/popupModal';
import ProfileImg from '../../../profile_image_change';
import NickChange from '../../../nick_change';
import TodayWordChange from '../../../today_word_change';
import BackgroundImageChange from '../../../background_image_change';
import ItemGift from '../../../item_gift';
import NoneContent from '../../../../../components/noneContent';

const DynamicComponents = {
  profileImageChange: ProfileImg,
  nickChange: NickChange,
  todayWordChange: TodayWordChange,
  backgroundImageChange: BackgroundImageChange,
  itemGift: ItemGift,
};

const MypageMyItem = observer(({ history, category }) => {
  const wrapRef = useRef(null);
  const { mypageStore } = useMypageStore(MypageContext);
  const modalStore = useModalStore();
  const [isOpenModal, setOpenModal] = useState(['', false]);
  const [viewItemButton, setViewItemButton] = useState('');
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    mypageStore.actionMyItemList({
      category: category === 'all' || category === 'using' ? '' : category,
    });
  }, [category]);

  const onClickGift = (item) => {
    setSelectedItem(item);
    setOpenModal(['itemGift', true]);
  };

  const onClickUse = (item) => {
    modalStore.openConfirmModal({
      message: `아이템을 사용하시겠습니까?`,
      okCallback: () => {
        switch (item.code) {
          case 'TODAY_WORD':
            setOpenModal(['todayWordChange', true]);
            break;
          case 'INIT_PROFILE':
            setOpenModal(['profileImageChange', true]);
            break;
          case 'INIT_NICK':
            setOpenModal(['nickChange', true]);
            break;
          case 'INIT_BACKGROUND':
            setOpenModal(['backgroundImageChange', true]);
            break;
          default:
            mypageStore.actionItemUse({ item }).then((res) => {
              if (res.code === 200) {
                mypageStore.actionMyItemList({
                  category: category === 'all' || category === 'using' ? '' : category,
                });
              }
            });
        }
      },
    });
  };

  const openPopup = () => {
    const Component = DynamicComponents[isOpenModal[0]];

    if (!!Component) {
      return (
        <Component
          history={history}
          onClose={() => {
            setOpenModal(['', false]);

            //Fetch item list again
            mypageStore.actionMyItemList({
              category: category === 'all' || category === 'using' ? '' : category,
            });
          }}
          category={category}
          count={1}
          maxCount={selectedItem.count ?? 1}
          maxGiftCount={selectedItem.gift_count ?? 1}
          item={selectedItem}
        />
      );
    } else {
      return null;
    }
  };

  const { active_item, total_cnt } = mypageStore.data;
  const {
    free_memo,
    chat,
    chat_item_count,
    chat_color_time,
    chat_color_item_count,
    chat_body_color_time,
    chat_body_color_item_count,
    chat_body_color,
    chat_color,
    double_good,
    double_good_item_count,
  } = active_item || {};

  if (category !== 'using' && total_cnt < 1) {
    return <NoneContent title={'아이템'} />;
  }

  const fullScreenHeigh = window.innerHeight;
  const heightLimit = mypageStore.data.list.length * 70 + 48 + 43 + 40; //
  const is_over_height = fullScreenHeigh - heightLimit - 70;

  const colorNameChange = (color) => {
    let name;
    switch (color) {
      case 'green':
        name = '[초록]';
        break;
      case 'purple':
        name = '[보라]';
        break;
      case 'red':
        name = '[빨강]';
        break;
      case 'blue':
        name = '[파랑]';
        break;
      case 'pink':
        name = '[분홍]';
        break;
      case 'yellow':
        name = '[노랑]';
        break;
      default:
        name = '';
        break;
    }

    return name;
  };

  return (
    <div>
      <PopupModal isOpen={isOpenModal[1]} onClose={() => setOpenModal(['', false])}>
        {openPopup()}
      </PopupModal>
      <List ref={wrapRef}>
        {category === 'using' ? (
          <>
            <UsingItem isNone={!(chat || chat_item_count)}>
              <em>채팅</em>
              <span>{chat ? chat : chat_item_count ? `${chat_item_count}개` : '보유아이템 없음'}</span>
            </UsingItem>
            <UsingItem>
              <em>무료쪽지</em>
              <span>{free_memo}건</span>
            </UsingItem>
            <UsingItem isNone={!(double_good || double_good_item_count)}>
              <em>꿀엄지</em>
              <span>
                {double_good ? double_good : double_good_item_count ? `${double_good_item_count}개` : '보유아이템 없음'}
              </span>
            </UsingItem>
            <UsingItem isNone={!(chat_color_time || chat_color_item_count)}>
              <em>채팅 닉네임 색상 {colorNameChange(chat_color)}</em>
              <span>
                {chat_color_time
                  ? chat_color_time
                  : chat_color_item_count
                  ? `${chat_color_item_count}개`
                  : '보유아이템 없음'}
              </span>
            </UsingItem>
            <UsingItem isNone={!(chat_body_color_time || chat_body_color_item_count)}>
              <em>채팅 본문 색상 {colorNameChange(chat_body_color)}</em>
              <span>
                {chat_body_color_time
                  ? chat_body_color_time
                  : chat_body_color_item_count
                  ? `${chat_body_color_item_count}개`
                  : '보유아이템 없음'}
              </span>
            </UsingItem>
          </>
        ) : (
          mypageStore.data.list.map((item, key) => {
            let is_bottom = false;
            if (is_over_height < 0 && key === mypageStore.data.list.length - 1) {
              is_bottom = true;
            }

            return (
              <Item
                key={key}
                item={item}
                onClickGift={onClickGift}
                onClickUse={onClickUse}
                wrapRef={wrapRef}
                viewItemButton={viewItemButton}
                setViewItemButton={setViewItemButton}
                is_bottom={is_bottom}
              />
            );
          })
        )}
      </List>
    </div>
  );
});

const Item = ({ item, onClickGift, onClickUse, viewItemButton, setViewItemButton, is_bottom }) => {
  return (
    <li onClick={() => setViewItemButton('')}>
      <ItemInfo>
        <img src={ImageUtil.imageUrl(item.image, 0, 50)} />
        <Summary>
          <div>
            <em>{item.name}</em>
            <span>{item.count}개</span>
          </div>
          <p>
            {item.gift_count > 0 ? '선물가능' : '선물불가'}
            {item.expire_date && `, 보관기간: ${item.expire_date}`}
          </p>
        </Summary>
      </ItemInfo>
      <Active
        onClick={(e) => {
          e.stopPropagation();
          setViewItemButton(item.no);
        }}
      >
        <span />
        <span />
        <span />
      </Active>
      {viewItemButton === item.no && (
        <ButtonLayer is_bottom={is_bottom}>
          <button onClick={() => onClickUse(item)}>사용하기</button>
          {item.gift_count > 0 && <button onClick={() => onClickGift(item)}>선물하기</button>}
        </ButtonLayer>
      )}
    </li>
  );
};

export { MypageMyItem };
