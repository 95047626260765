import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CertEmailComponent } from '../../../components/cert';
import useCommonStore from '../../../hooks/useCommonStore';
import useUsersStore from '../../../hooks/useUsersStore';

const EmailAuth = observer(({ history, location }) => {
  const commonStore = useCommonStore();
  const usersStore = useUsersStore();
  const resultPath = 'mypage/email_auth';
  const [isToken, setIsToken] = useState(false);
  const queryString = new URLSearchParams(location.search);
  const certifyToken = queryString.get('certify_token') || null;

  useEffect(() => {
    if (certifyToken !== null) {
      commonStore
        .actionMyCertifyEmailToken({ token: certifyToken })
        .then((tokenRes) => {
          history.replace('/mypage/info');
        });
      setIsToken(true);
    }
  }, []);

  const onClickInfo = () => {
    history.push('/mypage/info');
  };

  return (
    <>
      {!isToken && (
        <CertEmailComponent
          email={usersStore.data.email}
          resultPath={resultPath}
          readOnly={true}
        />
      )}
    </>
  );
});

export default EmailAuth;
