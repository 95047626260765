import React from 'react';
import autobind from 'autobind-decorator';
import { action, observable } from 'mobx';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import Cookie from '../../../../common/utils/cookie';
import { ScoreService } from '../../../../common/services';

@autobind
class AbstractScoreCommon {
  localstorage = new useLocalStorage();

  @observable
  selectedGames = [];
  @observable
  login = false;
  constructor() {
    this.selectedGames = this.localstorage.getItem('selectedGames') || [];
    const check = Cookie.getCookie('session_id');
    this.login = !!check;
  }

  @action
  async setSelectedGame(isSelected, id) {
    const { addKeyData, removeKeyData } = this.localstorage;

    if (isSelected) {
      removeKeyData('selectedGames', [id]);

      if (this.login) {
        await ScoreService.deleteInterestGames([id]);
      }
    } else {
      const interestNav = document.getElementById('interestNav');

      if (interestNav) {
        interestNav.classList.add('active');
        setTimeout(() => {
          interestNav.classList.remove('active');
        }, 200);
      }

      addKeyData('selectedGames', [id]);
      if (this.login) {
        await ScoreService.setInterestGames([id]);
      }
    }

    this.selectedGames = this.localstorage.getItem('selectedGames') || [];
  }

  @action
  async getFavoriteGames() {
    const { setItem } = this.localstorage;
    let selectedGames = this.localstorage.getItem('selectedGames') || [];
    let userInterestGames = [];

    if (this.login) {
      const response = await ScoreService.getInterestGames(
        this.login,
        this.selectedGames
      );
      if(response){
        Object.values(response).map((typeValue) => {
          typeValue.map((match) => {
            userInterestGames.push(match.id || match.gidx);
          });
        });
      }
    }

    const mergeData = selectedGames.concat(userInterestGames);
    const newSet = new Set(mergeData);
    selectedGames = [...newSet];

    if (!!selectedGames) {
      this.selectedGames = selectedGames;
      setItem('selectedGames', JSON.stringify(selectedGames));
    } else {
      this.selectedGames = [];
      setItem('selectedGames', []);
    }
  }
}

export default AbstractScoreCommon;
