import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Seo from '../../../components/seo';
import './exchange.css';
import {MypageContext, useMypageStore} from "../context";
import { useLocation, withRouter } from "react-router-dom";
import Pagination from "../../../components/pagination";

const MedalExchangeHistory = observer(({ history }) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const { mypageStore } = useMypageStore(MypageContext);

    const page = parseInt(query.get('page')) || 1;

    const statusText = {
      ready: '신청중',
      progress: '처리중',
      reject: '거절',
      complete: '완료'
    }

    useEffect(() => {
        mypageStore.actionMedalExchangeHistoryList({ page });
    }, [page]);

    const numberFormat = (number) => {
      if (number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return 0;
  };

  return (
    <>
      <Seo title={'마이페이지 캐쉬'} />
      <div className="exchange-form">
            <h1 className="title">건빵 환전 내역</h1>
            <button className="btn_back" onClick={() => history.push('/mypage/medal')}>
              닫기
            </button>

            <table className={"medal_history_table"}>
                <thead>
                  <tr>
                    <th>신청 일시</th>
                    <th>신청 수량 (개)</th>
                    <th>수령액</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    mypageStore.data.medal_history_list.list &&
                    mypageStore.data.medal_history_list.count &&
                    mypageStore.data.medal_history_list.count > 0  ?
                    mypageStore.data.medal_history_list.list.map((item, index) => (
                    <tr key={index}>
                      <td>{item.create_datetime}</td>
                      <td>{numberFormat(item.amount)}</td>
                      <td>{numberFormat(item.receipt_price)}</td>
                      <td className={"flex-column"}>
                        <span className={`exchange-status ${item.status}`}>{
                          statusText[item.status] ? statusText[item.status] : ''
                        }</span>
                      </td>
                    </tr>
                  )) :
                    <tr>
                      <td colSpan={4} className={"empty-row"}>내역이 없습니다.</td>
                    </tr>
                  }

                </tbody>
              </table>

            {
              mypageStore.data.medal_history_list.count ? (
                <Pagination
                  page={page}
                  totalCount={mypageStore.data.medal_history_list.count}
                  tab={''}
                  rowCount={10}
                />
              ) : null}


            <div className="readme-wrapper">
                <div className="readme">
                    <p>환전 신청 전에 꼭 읽어주세요!</p>
                    <ul>
                        {/*<li className="ico_indent">본인 신분증 사본과 통장 사본 이미지 파일을 첨부하셔야 환전이 가능합니다.</li>*/}
                        {/*<li className="ico_indent">본인 이름과 예금주의 이름이 일치해야지만 환전 가능합니다.</li>*/}
                        <li className="ico_indent">환전 신청한 금액은 신청일로부터 영업일 5일 이내 입금 처리됩니다. (휴일인 경우 익일 영업일에 일괄 처리)</li>
                        <li className="ico_indent">건빵이 100개 이상일 때부터 현금 환전이 가능합니다. (건빵1개 100원)</li>
                        {/*<li className="ico_indent">환전 신청한 금액은 액수에 관계없이 소득세 3%, 주민세0.3%를 제외한 금액이 입금됩니다.</li>*/}
                        <li className="ico_indent">불법적인 용도나, 법적인 문제가 발생할 경우, 비정상적인 경우, 네임드 약관에 위배가되는 경우 환전 승인이 거절될수 있습니다.</li>
                        <li className="ico_indent">최근 90일간 환전 처리결과 내역을 확인하실 수 있으며, 90일 이후 내역은 모두 소멸됩니다.</li>
                    </ul>
                </div>
            </div>

        </div>
    </>
  );
});

export default withRouter(MedalExchangeHistory);