import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { MypageFriendsList, MypageFriendsRecommend } from '../components';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

const Subscribe = observer(({ history }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [tab, setTab] = useState(query.get('tab') || 'list');

  const Component = useMemo(() => {
    switch (tab) {
      case 'list':
        return MypageFriendsList;
      case 'recommend':
        return MypageFriendsRecommend;
      default:
        return MypageFriendsList;
    }
  }, [tab]);

  return (
    <>
      <div className='snb_wrap'>
        <ul>
          <li>
            <Link
              to={`${location.pathname}?tab=list`}
              className={clsx('item', tab === 'list' && 'selected')}
              onClick={() => setTab('list')}
            >
              내가 친구한 내역
            </Link>
          </li>
          <li>
            <Link
              to={`${location.pathname}?tab=recommend`}
              className={clsx('item', tab === 'recommend' && 'selected')}
              onClick={() => setTab('recommend')}
            >
              나를 친구한 내역
            </Link>
          </li>
        </ul>
      </div>
      <Component tab={tab} />
    </>
  );
});

export default Subscribe;
