import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useLocation, Link } from 'react-router-dom';

const Pagination = ({
  page,
  totalCount,
  tab = '',
  category = '',
  rowCount = 30
}) => {
  const location = useLocation();
  const pageSize = rowCount;
  const viewPageCount = 5;
  const [totalPageCount, setTotalPageCount] = useState(1);

  useEffect(() => {
    setTotalPageCount(Math.ceil(totalCount / pageSize) || 1);
  }, [totalCount]);

  if (totalCount <= 0) {
    return null;
  }

  const baseLink = `${location.pathname}?${tab ? `tab=${tab}&` : tab}${
    category ? `category=${category}&` : category
  }`;

  const startNum =
    page - 2 < 1
      ? 1
      : page + 2 > totalPageCount
      ? totalPageCount - viewPageCount + 1
      : page - 2;

  const lastNum =
    page + 2 > totalPageCount
      ? totalPageCount
      : page - 2 < 1
      ? viewPageCount
      : page + 2;

  const pagination = [];
  if (totalCount > 0) {
    for (let num = startNum; num <= lastNum; num++) {
      if (num > totalPageCount) break;
      if (num <= 0) continue;
      pagination.push(
        <Link
          key={num}
          to={`${baseLink}page=${num}`}
          className={clsx('num', page === num && 'on')}
        >
          <em>{num}</em>
        </Link>
      );
    }
  }

  return (
    <div className="pagination">
      <Link
        className="btn btn_prev"
        to={page <= 1 ? `${baseLink}page=1` : `${baseLink}page=${page - 1}`}
      >
        이전 페이지
      </Link>
      <div className="number">{pagination}</div>
      <Link
        className="btn btn_next"
        to={
          page >= totalPageCount
            ? `${baseLink}page=${totalPageCount}`
            : `${baseLink}page=${page + 1}`
        }
      >
        다음 페이지
      </Link>
    </div>
  );
};

export default Pagination;
