import styled, { css } from 'styled-components';

export const Background = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 199;
  background-color: rgba(0, 0, 0, 0.5);
  ${(props) =>
    !props.isOpen &&
    css`
      visibility: hidden;
    `}
`;

export const Wrap = styled.div`
  background-color: #fff;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  width: 70%;
  max-width: 100%;
  padding-top: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow-y: auto;
  > img {
    margin: 25px 0 18px;
  }
  .name {
    font-size: 18px;
    line-height: 17px;
    font-weight: 600;
  }
  .exp {
    font-size: 12px;
    color: #1aa718;
    line-height: 12px;
    margin-top: 12px;
  }
  .desc {
    font-size: 11px;
    color: #999;
    line-height: 11px;
    margin-top: 11px;
    text-align: center;
    padding: 0 10px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 18px;
  height: 18px;
  top: 20px;
  right: 20px;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 18px;
    background: #333;
  }
  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`;

export const OptionBox = styled.div`
  display: flex;
  margin: 30px 0;
  width: 100%;
`;
export const Option = styled.div`
  display: flex;
  padding: 15px;
  gap: 8px;
  border-top: #ececec solid 1px;
  width: 100%;
  align-items: center;
`;

export const Count = styled.div`
  min-width: 50px;
  height: 28px;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-weight: 600;
    font-size: 16px;
  }
`;

export const Price = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  :before {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    color: #fff;
    margin-right: 5px;
    margin-top: 2px;
  }
  ${({ color, checked }) =>
    checked
      ? color === 'GOLD'
        ? css`
            color: #e74c3c;
          `
        : color === 'POINT'
        ? css`
            color: #1287eb;
          `
        : css`
            color: #fba019;
          `
      : css`
          color: black;
        `}
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  border-top: #ececec solid 1px;
  button {
    flex: 1;
    font-size: 14px;
    height: 50px;
    font-weight: bold;
    &.buy {
      background-color: #e74c3c;
      color: #fff;
    }
    &.gift {
      color: black;
      border-right: #ececec solid 1px;
    }
  }
`;

export const PayTypeWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 8px;
`;

export const PayTypeItem = styled.div`
  border-top: #ececec solid 1px;
  height: 35px;
  display: flex;
  gap: 3px;
  font-size: 13px;
  padding: 8px;
  align-items: center;
  font-weight: bold;

  ${({ active }) =>
    active
      ? `
    opacity: 1;
    cursor:pointer;
    `
      : `opacity: 0.5;
        cursor:default;
      `}
`;

export const PayTypeImg = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-cover: cover;
  ${({ checked, color }) =>
    checked
      ? `background-image: url('/images/market/radio-${color}.png');`
      : `background-image: url('/images/market/radio-grey.png');`}
`;

export const ItemCount = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-left: auto;
  margin-right: 5px;
`;

export const ButtonQuantity = styled.div`
  width: 12px;
  height: 12px;
  &.POINT.minus {
    background: url('/images/market/minus-POINT.png') no-repeat;
    background-size: 12px 12px;
  }
  &.GOLD.minus {
    background: url('/images/market/minus-GOLD.png') no-repeat;
    background-size: 12px 12px;
  }
  &.MEDAL.minus {
    background: url('/images/market/minus-MEDAL.png') no-repeat;
    background-size: 12px 12px;
  }
  &.POINT.plus {
    background: url('/images/market/plus-POINT.png') no-repeat;
    background-size: 12px 12px;
  }
  &.GOLD.plus {
    background: url('/images/market/plus-GOLD.png') no-repeat;
    background-size: 12px 12px;
  }
  &.MEDAL.plus {
    background: url('/images/market/plus-MEDAL.png') no-repeat;
    background-size: 12px 12px;
  }
  ${({ disabled, color }) => disabled && `opacity: 0.3`}
`;
