/**
 * @description 스코어 유틸
 * @export
 * @class scoreUtil
 */

import {
  _SPORTS_GAME_TYPE,
  _E_SPORTS_GAME_STATUS
} from '../constants/sportsConstants';
import { toJS } from 'mobx';

// import { _SORTED_ODDSS } from '../public/Constants';
// import { RegexUtil } from './regexUtil';

export class ScoreUtil {
  /**
   * @description
   * @static
   * @param {*} oddsArr
   * @param {*} handiArr
   * @param {*} uoArr
   * @returns
   */
  static getScoreOdds({ oddsArr, handiArr, uoArr }) {
    const isOdds = oddsArr.length > 0;
    const isHandiOption = handiArr.length > 0;
    const isUnderOverOption = uoArr.length > 0;

    const win = isOdds
      ? oddsArr.filter((item) => item.type === 'WIN')[0]
      : null;
    const loss = isOdds
      ? oddsArr.filter((item) => item.type === 'LOSS')[0]
      : null;
    const handiOption = isHandiOption ? handiArr[0].optionValue : null;
    const underOverOption = isUnderOverOption ? uoArr[0].optionValue : null;

    let home = null;
    let away = null;

    if (!!win) {
      home = isNaN(win.odds) ? win.odds : win.odds.toFixed(2);
    }

    if (!!loss) {
      away = isNaN(loss.odds) ? loss.odds : loss.odds.toFixed(2);
    }

    return {
      home,
      awayOption: handiOption,
      away,
      homeOption: underOverOption
    };
  }

  /**
   *
   *
   * @static
   * @param {*} list
   * @returns
   * @memberof ScoreUtil
   */
  static sortSportsList(list, sportsType) {
    const _STATUS = {
      IN_PROGRESS: 1,
      BREAK_TIME: 1,
      PAUSE: 1,
      READY: 2,
      FINAL: 3,
      CANCEL: 3,
      DELAY: 3,
      POSTPONED: 3,
      SUSPENDED: 3,
      CUT: 3,
      PENDING: 3
    };

    const hotMatchFlag = sportsType === 'major' ? 'majorHot' : 'hot';
    return list.sort((a, b) => {
      const aDate = new Date(a.startDatetime);
      const bDate = new Date(b.startDatetime);

      if (a[hotMatchFlag] && b[hotMatchFlag]) {
        if (_STATUS[a.gameStatus] > _STATUS[b.gameStatus]) return 1;
        if (_STATUS[a.gameStatus] < _STATUS[b.gameStatus]) return -1;

        if (aDate.getMonth() > bDate.getMonth()) return 1;
        if (aDate.getDate() > bDate.getDate()) return 1;
        if (aDate.getHours() > bDate.getHours()) return 1;
        if (aDate.getMinutes() > bDate.getMinutes()) return 1;

        if (aDate.getMonth() < bDate.getMonth()) return -1;
        if (aDate.getDate() < bDate.getDate()) return -1;
        if (aDate.getHours() < bDate.getHours()) return -1;
        if (aDate.getMinutes() < bDate.getMinutes()) return -1;
      } else {
        if (a[hotMatchFlag] && _STATUS[a.gameStatus] !== 3) return -1;

        if (
          _STATUS[a.gameStatus] > _STATUS[b.gameStatus] ||
          (b[hotMatchFlag] && _STATUS[b.gameStatus] !== 3)
        )
          return 1;
        if (_STATUS[a.gameStatus] < _STATUS[b.gameStatus]) return -1;

        if (aDate.getMonth() > bDate.getMonth()) return 1;
        if (aDate.getDate() > bDate.getDate()) return 1;
        if (aDate.getHours() > bDate.getHours()) return 1;
        if (aDate.getMinutes() > bDate.getMinutes()) return 1;

        if (aDate.getMonth() < bDate.getMonth() && !b[hotMatchFlag]) return -1;
        if (aDate.getDate() < bDate.getDate() && !b[hotMatchFlag]) return -1;
        if (aDate.getHours() < bDate.getHours() && !b[hotMatchFlag]) return -1;
        if (aDate.getMinutes() < bDate.getMinutes() && !b[hotMatchFlag])
          return -1;
      }
    });
  }

  /**
   * @description e-sports 게임 sort
   * @static
   * @param {*} list
   * @returns
   */
  static eSportsSortList(list) {
    const _STATUS = {
      IN_PROGRESS: 1,
      BREAK_TIME: 1,
      PAUSE: 1,
      READY: 2,
      FINAL: 3,
      CANCEL: 3,
      DELAY: 3,
      POSTPONED: 3,
      SUSPENDED: 3,
      CUT: 3,
      PENDING: 3
    };

    return list.sort((a, b) => {
      const aDate = new Date(`
      ${a.gdate}T${a.gtime}`);
      const bDate = new Date(`
      ${b.gdate}T${b.gtime}`);

      if (
        _STATUS[_E_SPORTS_GAME_STATUS[a.gameStatus]] >
        _STATUS[_E_SPORTS_GAME_STATUS[b.gameStatus]]
      )
        return 1;
      if (
        _STATUS[_E_SPORTS_GAME_STATUS[a.gameStatus]] <
        _STATUS[_E_SPORTS_GAME_STATUS[b.gameStatus]]
      )
        return -1;

      if (aDate.getMonth() > bDate.getMonth()) return 1;
      if (aDate.getMonth() < bDate.getMonth()) return -1;
      if (aDate.getDate() > bDate.getDate()) return 1;
      if (aDate.getDate() < bDate.getDate()) return -1;
      if (aDate.getHours() > bDate.getHours()) return 1;
      if (aDate.getHours() < bDate.getHours()) return -1;
      if (aDate.getMinutes() > bDate.getMinutes()) return 1;
      if (aDate.getMinutes() < bDate.getMinutes()) return -1;
    });
  }

  /**
   * @description e-sports 게임 sort
   * @static
   * @param {*} list
   * @returns
   */
  static eSportsStarSortList(list) {
    const _STATUS = {
      IN_PROGRESS: 1,
      BREAK_TIME: 1,
      PAUSE: 1,
      READY: 2,
      FINAL: 3,
      CANCEL: 3,
      DELAY: 3,
      POSTPONED: 3,
      SUSPENDED: 3,
      CUT: 3,
      PENDING: 3
    };

    return list.sort((a, b) => {
      const aDate = new Date(`
      ${a.gdate}T${a.gtime}`);
      const bDate = new Date(`
      ${b.gdate}T${b.gtime}`);

      if (
        _STATUS[_E_SPORTS_GAME_STATUS[a.gstatus]] >
        _STATUS[_E_SPORTS_GAME_STATUS[b.gstatus]]
      )
        return 1;
      if (
        _STATUS[_E_SPORTS_GAME_STATUS[a.gstatus]] <
        _STATUS[_E_SPORTS_GAME_STATUS[b.gstatus]]
      )
        return -1;

      if (aDate.getMonth() > bDate.getMonth()) return 1;
      if (aDate.getMonth() < bDate.getMonth()) return -1;
      if (aDate.getDate() > bDate.getDate()) return 1;
      if (aDate.getDate() < bDate.getDate()) return -1;
      if (aDate.getHours() > bDate.getHours()) return 1;
      if (aDate.getHours() < bDate.getHours()) return -1;
      if (aDate.getMinutes() > bDate.getMinutes()) return 1;
      if (aDate.getMinutes() < bDate.getMinutes()) return -1;
    });
  }

  /**
   * @description 축구 리스트 정렬
   * @static
   * @param {*} list
   * @returns
   */
  static sortSoccerList(list, selectedGames) {
    const _STATUS = {
      IN_PROGRESS: 1,
      BREAK_TIME: 1,
      PAUSE: 1,
      READY: 2,
      FINAL: 3,
      CANCEL: 3,
      DELAY: 3,
      POSTPONED: 3,
      SUSPENDED: 3,
      CUT: 3,
      PENDING: 3
    };

    return list.sort((a, b) => {
      const aDate = new Date(a.startDatetime);
      const bDate = new Date(b.startDatetime);
      const isSelectedA = selectedGames.some((id) => a.id === id.key);
      const isSelectedB = selectedGames.some((id) => b.id === id.key);

      if (isSelectedA && !isSelectedB) return -1;
      if (!isSelectedA && isSelectedB) return 1;

      if (_STATUS[a.gameStatus] > _STATUS[b.gameStatus]) return 1;
      if (_STATUS[a.gameStatus] < _STATUS[b.gameStatus]) return -1;

      if (aDate.getMonth() > bDate.getMonth()) return 1;
      if (aDate.getMonth() < bDate.getMonth()) return -1;
      if (aDate.getDate() > bDate.getDate()) return 1;
      if (aDate.getDate() < bDate.getDate()) return -1;
      if (aDate.getHours() > bDate.getHours()) return 1;
      if (aDate.getHours() < bDate.getHours()) return -1;
      if (aDate.getMinutes() > bDate.getMinutes()) return 1;
      if (aDate.getMinutes() < bDate.getMinutes()) return -1;
    });
  }

  /**
   * @description 프로토 승부식 리스트 솔팅
   * @static
   * @param {*} list
   * @returns
   */
  static sortProtoWinList(list) {
    const _STATUS = {
      IN_PROGRESS: 1,
      BREAK_TIME: 1,
      PAUSE: 1,
      READY: 2,
      FINAL: 3,
      CANCEL: 3,
      DELAY: 3,
      POSTPONED: 3,
      SUSPENDED: 3,
      CUT: 3,
      PENDING: 3
    };

    return list.sort((a, b) => {
      const aNum = +a.number;
      const bNum = +b.number;

      let aStatus = a.gameStatus;
      let bStatus = b.gameStatus;

      if (!!a[_SPORTS_GAME_TYPE[a.sportsType]]) {
        aStatus = a[_SPORTS_GAME_TYPE[a.sportsType]].gameStatus;
      }

      if (!!b[_SPORTS_GAME_TYPE[b.sportsType]]) {
        bStatus = b[_SPORTS_GAME_TYPE[b.sportsType]].gameStatus;
      }

      if (_STATUS[aStatus] > _STATUS[bStatus]) return 1;
      if (_STATUS[aStatus] < _STATUS[bStatus]) return -1;

      if (aNum > bNum) return 1;
      if (aNum < bNum) return -1;
    });
  }

  /**
   * @description 홈, 어웨이 점수, 이름
   * @static
   * @param {*} game
   * @param {*} status
   * @returns {home, away}
   */
  static getTeamScore(game, status, sportsType = null, period = null) {
    const home = {
      id: 0,
      name: '',
      score: 0,
      imgPath: ''
    };
    const away = {
      id: 0,
      name: '',
      score: 0,
      imgPath: ''
    };
    const check =
      status === 'FINAL' ||
      status === 'IN_PROGRESS' ||
      status === 'BREAK_TIME' ||
      status === 'PAUSE';
    let leagueName = '';

    function getScoreSum(team, period, sportsType) {
      if (!!team.periodData) {
        if (team.periodData.length > 0) {
          let filterdPeroid = team.periodData;

          if (sportsType === 'SOCCER' || sportsType === 'ICEHOCKEY') {
            if (period === 5) {
              filterdPeroid = filterdPeroid.filter((item) => item.period < 5);
            }
          }
          return filterdPeroid.reduce((a, b) => {
            return a + b.score;
          }, 0);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }

    if (game) {
      if (game.hasOwnProperty('league')) {
        leagueName = game.league.shortName;
      }

      if (game.teams) {
        home.id = game.teams.home.id;
        home.name = game.teams.home.name;
        home.score = getScoreSum(game.teams.home, game.period, game.sportsType);

        home.imgPath = game.teams.home.imgPath;
        away.id = game.teams.away.id;
        away.name = game.teams.away.name;
        away.score = getScoreSum(game.teams.away, game.period, game.sportsType);

        away.imgPath = game.teams.away.imgPath;
      }
    }

    if (!!sportsType && sportsType === 'VOLLEYBALL') {
      let volleyballHomeSet = 0;
      let volleyballAwaySet = 0;

      if (
        game.teams.home.periodData.length > 0 &&
        game.teams.away.periodData.length > 0
      ) {
        if (status === 'IN_PROGRESS') {
          for (let i = 0; i < period - 1; i++) {
            if (
              game.teams.home.periodData[i].score >
              game.teams.away.periodData[i].score
            ) {
              volleyballHomeSet++;
            } else {
              volleyballAwaySet++;
            }
          }
        } else {
          for (let i = 0; i < period; i++) {
            if (
              game.teams.home.periodData[i].score >
              game.teams.away.periodData[i].score
            ) {
              volleyballHomeSet++;
            } else {
              volleyballAwaySet++;
            }
          }
        }
      }

      home.score = volleyballHomeSet;
      away.score = volleyballAwaySet;
    }

    if (status === 'READY') {
      home.score = '';
      away.score = '';
    }

    return { home, away, leagueName };
  }

  /**
   * @description 경기 리스트 카운트
   * @static
   * @param {*} list
   * @returns
   */
  static getSportsStatusCount(list) {
    return list.reduce(
      (acc, item) => {
        let key = 'ready';

        if (item.gameStatus === 'READY') {
          key = 'ready';
        } else if (
          item.gameStatus === 'IN_PROGRESS' ||
          item.gameStatus === 'BREAK_TIME' ||
          item.gameStatus === 'PAUSE'
        ) {
          key = 'inProgress';
        } else if (
          item.gameStatus === 'FINAL' ||
          item.gameStatus === 'CUT' ||
          item.gameStatus === 'POSTPONED' ||
          item.gameStatus === 'PENDING' ||
          item.gameStatus === 'CANCEL' ||
          item.gameStatus === 'DELAY' ||
          item.gameStatus === 'SUSPENDED'
        ) {
          key = 'final';
        }

        return { ...acc, [key]: (acc[key] || 0) + 1 };
      },
      {
        ready: 0,
        inProgress: 0,
        final: 0
      }
    );
  }

  /**
   * @description 스코어 배당 정보  home,draw,away분류후 린턴
   * @static
   * @param {*} oddss, sortedOddss
   * @param {*} sortedOddss
   * @returns sortedOddss
   */
  static getScoreOddssData(oddss, sortedOddss) {
    for (let data of oddss) {
      sortedOddss.optionValue = data.optionValue;
      if (
        data.type === 'WIN' ||
        data.type === 'HANDICAP_WIN' ||
        data.type === 'UNDER'
      ) {
        sortedOddss.win.hit = data.result === 'HIT' ? true : false;
        sortedOddss.win.id = data.id;
        sortedOddss.win.odds = data.odds.toFixed(2);
        if (!!data.preOdds) {
          if (data.odds > data.preOdds) {
            sortedOddss.win.oddsChange = 'up';
          } else {
            sortedOddss.win.oddsChange = 'down';
          }
        }
      } else if (data.type === 'DRAW' || data.type === 'HANDICAP_DRAW') {
        sortedOddss.draw.hit = data.result === 'HIT' ? true : false;
        sortedOddss.draw.id = data.id;
        sortedOddss.draw.odds = data.odds.toFixed(2);
        if (!!data.preOdds) {
          if (data.odds > data.preOdds) {
            sortedOddss.draw.oddsChange = 'up';
          } else {
            sortedOddss.draw.oddsChange = 'down';
          }
        }
      } else if (
        data.type === 'LOSS' ||
        data.type === 'HANDICAP_LOSS' ||
        data.type === 'OVER'
      ) {
        sortedOddss.loss.hit = data.result === 'HIT' ? true : false;
        sortedOddss.loss.id = data.id;
        sortedOddss.loss.odds = data.odds.toFixed(2);
        if (!!data.preOdds) {
          if (data.odds > data.preOdds) {
            sortedOddss.loss.oddsChange = 'up';
          } else {
            sortedOddss.loss.oddsChange = 'down';
          }
        }
      }
    }

    return sortedOddss;
  }

  /**
   * @description 팀 스코어합 계산
   * @static
   * @param {*} scores
   * @returns total
   */
  static getTeamTotalScore(periodData) {
    let total = 0;
    periodData.forEach((data) => {
      total += data.score;
    });

    return total;
  }

  /**
   *
   * @static
   * @param {*} special
   * @param {*} item
   * @returns
   */
  static getBaseballSpecaillHSB(special, item) {
    let home = { h: false, s: false, b: false };
    let away = { h: false, s: false, b: false };

    if (!!special) {
      if (!!special.firstHomerun && special.firstHomerun.period === item) {
        if (special.firstHomerun.location === 'AWAY') {
          away.h = true;
        } else {
          home.h = true;
        }
      }

      if (!!special.firstStrikeOut && special.firstStrikeOut.period === item) {
        if (special.firstStrikeOut.location === 'AWAY') {
          away.s = true;
        } else {
          home.s = true;
        }
      }

      if (
        !!special.firstBaseOnBall &&
        special.firstBaseOnBall.period === item
      ) {
        if (special.firstBaseOnBall.location === 'AWAY') {
          away.b = true;
        } else {
          home.b = true;
        }
      }
    }

    return { away, home };
  }

  static getOddsTypeWinnerCount(game, homeId) {
    const { odds } = game;
    let result = {
      handicap: {
        win: [],
        loss: []
      },
      unover: {
        win: [],
        loss: []
      }
    };

    let location = { home: 'win', away: 'loss' };

    if (game.home.id !== homeId) {
      location = { home: 'loss', away: 'win' };
    }

    if (odds.domesticHandicapOdds.length > 0) {
      result.handicap[location.home] = odds.domesticHandicapOdds.filter(
        (item) => item.type === 'HANDICAP_WIN' && item.result === 'HIT'
      );
      result.handicap[location.away] = odds.domesticHandicapOdds.filter(
        (item) => item.type === 'HANDICAP_LOSS' && item.result === 'HIT'
      );
    }

    if (odds.domesticUnderOverOdds.length > 0) {
      result.unover.win = odds.domesticUnderOverOdds.filter(
        (item) => item.type === 'UNDER' && item.result === 'HIT'
      );
      result.unover.loss = odds.domesticUnderOverOdds.filter(
        (item) => item.type === 'OVER' && item.result === 'HIT'
      );
    }

    return result;
  }

  /**
   *
   * @static
   * @param {*} game
   * @returns
   */
  static getSummaryScore(game, homeId) {
    const home = {
      score: 0
    };
    const away = {
      score: 0
    };

    function getScoreSum(team) {
      if (!!team.periodData) {
        if (team.periodData.length > 0) {
          let filterdPeroid = team.periodData;

          return filterdPeroid.reduce((a, b) => {
            return a + b.score;
          }, 0);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }

    if (game) {
      home.score = getScoreSum(game.home.id === homeId ? game.home : game.away);
      away.score = getScoreSum(game.home.id === homeId ? game.away : game.home);
    }

    return { home, away };
  }

  static getSportName(type) {
    const sportsMap = {
      soccer: '축구',
      baseball: '야구',
      basketball: '농구',
      volleyball: '배구',
      hockey: '아이스하키',
      football: '미식축구',
      lol: 'LOL',
      star: '스타',
      starcraft: '스타'
    };
    return sportsMap[type];
  }
}
