import React, { createContext, useContext, useState } from 'react';
import MypageStore from '../store'

export const MypageContext = createContext(null);

const MypageProvider = ({ children, modalStore, history }) => {
  const [mypageStore] = useState(() => new MypageStore(modalStore, history));

  return (
    <MypageContext.Provider value={{mypageStore}}>
      {children}
    </MypageContext.Provider>
  );
};

export default MypageProvider;

export const useMypageStore = () => {
  return useContext(MypageContext);
}; 