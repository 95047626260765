import formatDistance from 'date-fns/formatDistance';
import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import getDate from 'date-fns/getDate';
import getDay from 'date-fns/getDay';
import differenceInSeconds from 'date-fns/differenceInMilliseconds';
import ko from 'date-fns/locale/ko';
import addDays from 'date-fns/addDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInDays from 'date-fns/differenceInDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfMonth from 'date-fns/endOfMonth';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

/**
 * @description 시간 계산 유틸
 * @export
 * @class timeUtils
 */
export class timeUtils {
  /**
   * @description 시간 비교
   * @static
   * @param {*} registerTime
   */
  static timeComparison(registerTime) {
    let time = registerTime;

    if (typeof registerTime !== 'string') {
      time = new Date(
        registerTime.year,
        registerTime.monthValue,
        registerTime.dayOfMonth,
        registerTime.hour,
        registerTime.minute,
        registerTime.second,
        registerTime.nano
      );
    }

    const comparisonMinute = formatDistance(
      new Date(time + '+09:00'),
      new Date(),
      {
        locale: ko
      }
    );

    return comparisonMinute.replace(/약 /, '');
  }

  /**
   * @description 시간 비교 boolean값
   * @static
   * @param {*} leftDate
   * @param {*} rightDate
   * @returns
   */
  static timeComparisonDateToDate(leftDate, rightDate) {
    return Math.sign(
      differenceInSeconds(
        new Date(leftDate + '+09:00'),
        new Date(rightDate + '+09:00')
      )
    ) > 0
      ? true
      : false;
  }

  /**
   *
   *
   * @static
   * @param {*} leftDate
   * @param {*} rightDate
   * @returns
   * @memberof timeUtils
   */
  static checkDifferenceToday(leftDate, rightDate) {
    return differenceInDays(new Date(leftDate), new Date(rightDate));
  }

  /**
   * @description 명예의 전당 시간 포맷
   * @static
   * @param {*} date
   * @returns yyyy.MM.dd hh:mm:ss
   */
  static yyyyMMddHHmm(date) {
    return format(new Date(date + '+09:00'), 'yyyy.MM.dd HH:mm');
  }

  /**
   * @description 년도
   * @static
   * @param {*} date
   * @returns yyyy
   */
  static timeFormatYear(date) {
    return date ? format(new Date(date + '+09:00'), 'yyyy') : '';
  }

  /**
   * @description 월 일
   * @static
   * @param {*} date
   * @returns MM.dd
   */
  static timeFormatMonthDay(date) {
    return date ? format(new Date(date + '+09:00'), 'MM.dd') : '';
  }

  /**
   *
   * @static
   * @param {*} time
   * @returns MM.dd (EEEEE) HH:mm
   */
  static matchGameForamt(time) {
    return format(new Date(time + '+09:00'), 'MM.dd (EEEEE) HH:mm', {
      locale: ko
    });
  }

  /**
   * @description 시:분
   * @static
   * @param {*} data
   * @returns 'HH:mm'
   */
  static timeFormatHourMinute(date) {
    return date ? format(new Date(date + '+09:00'), 'HH:mm') : '';
  }

  /**
   * @description ex) 2019-08-02 14:00 로 출력
   * @static
   * @param {*} data
   * @returns 'yyyy-MM-dd hh:mm'
   */
  static scoreScheduleTimeFormat(date, isNew) {
    if (isNew) {
      return date ? format(new Date(), 'yyyy-MM-dd HH:mm') : '';
    } else {
      return date ? format(new Date(date + '+09:00'), 'yyyy-MM-dd HH:mm') : '';
    }
  }

  /**
   * @description 0000.00.00 00:00
   * @static
   * @param {*} date
   * @returns
   */
  static matchTimeFormat(date) {
    return date ? format(new Date(date + '+09:00'), 'yyyy.MM.dd HH:mm') : '';
  }

  /**
   * @description ex) 08-02 14:00 로 출력
   * @static
   * @param {*} data
   * @returns 'yyyy-MM-dd hh:mm'
   */
  static MmDdHhMm(date) {
    return date ? format(new Date(date), 'MM-dd HH:mm') : '';
  }

  /**
   * @description 현재 날짜
   * @static
   * @returns yyyy-MM-dd
   */
  static todayYmd() {
    return format(new Date(), 'yyyy-MM-dd');
  }

  /**
   * @description 날짜 포맷
   * @static
   * @returns yyyy-MM-dd
   */
  static dateFormatYmd(date) {
    return format(new Date(date), 'yyyy-MM-dd');
  }

  /**
   * @description 날짜 포맷
   * @static
   * @returns yyyy-MM-dd
   */
  static dateFormatCalendarYmd(date) {
    return format(new Date(date), 'yyyy.MM.dd');
  }

  /**
   * @description 어제, 내일 날짜
   * @static
   * @param {*} date
   * @param {*} type
   * @returns
   */
  static datePrevOrNext(date, type) {
    return format(
      addDays(new Date(date), type === 'next' ? 1 : -1),
      'yyyy-MM-dd'
    );
  }

  /**
   * @description
   * @static
   * @param {*} date
   * @param {*} add
   * @returns
   */
  static addDay(date, add) {
    return {
      key: format(addDays(date, add), 'MM월 dd일'),
      value: format(addDays(date, add), 'yyyy-MM-dd')
    };
  }

  /**
   * @description 스포츠 페이지네이션 타임 포멧
   * @static
   * @param {*} date
   * @returns y
   */
  static sportsDateFormat(date) {
    return format(new Date(date), 'yyyy년 MM월 dd일 (EEEEEE)', {
      locale: ko
    });
  }

  /**
   * @description 스포츠 페이지네이션 타임 포멧
   * @static
   * @param {*} date
   * @returns y
   */
  static sportsDateFormatIos(date) {
    return format(new Date(date + '+09:00'), 'yyyy년 MM월 dd일 (EEEEEE)', {
      locale: ko
    });
  }

  /**
   * @description 페이지네이션 타임포멧 변경
   * @static
   * @param {*} date
   * @returns 2020.01.01 (월)
   */
  static sportsPaginationFormat(date) {
    return format(new Date(date), 'yyyy.MM.dd (EEEEEE)', { locale: ko });
  }

  /**
   * @description 오늘과 날짜 비교
   * @static
   * @param {*} today
   * @param {*} checkDate
   * @returns -1, 0, 1 .... some numbers
   */
  static getDifferenceDay(checkDate) {
    return differenceInCalendarDays(new Date(checkDate), new Date());
  }

  /**
   * @description 날짜 비교
   * @static
   * @param {*} leftDate
   * @param {*} rightDate
   * @returns -1, 0, 1 .... some numbers
   */
  static getDifferenceDayLeftRight(leftDate, rightDate) {
    return differenceInCalendarDays(new Date(leftDate), new Date(rightDate));
  }

  /**
   * @description 선택 월 달력 주 단위 2차원 배열 반환
   * @static
   * @param {*} currentDate // ex) "2020-03"
   * @returns [1,2,3,4,5,6,7][8,9,10,11,12,13,14]...
   */
  static getCalendarArr(currentDate) {
    let initArr = [];
    let initDate = new Date(currentDate);
    let initYear = getYear(initDate);
    let initMonth = getMonth(initDate);
    let initDateCount = 1;
    let initDateIndex = 0;
    let initWeekIndex = 0;
    let startInputLowIndex = getDay(new Date(`${initYear}-${initMonth + 1}-1`));
    let isLoop = true;

    let checkedMonth =
      initMonth + 1 < 10 ? '0' + (initMonth + 1) : initMonth + 1;

    while (isLoop) {
      // debugger;
      if (initDateIndex >= startInputLowIndex) {
        if (initDateCount > 28) {
          if (
            !!getDate(
              new Date(`${initYear}-${initMonth + 1}-${initDateCount}`)
            ) &&
            getDate(
              new Date(`${initYear}-${initMonth + 1}-${initDateCount}`)
            ) !== 1
          ) {
            if (!!initArr[initWeekIndex]) {
              if (initArr[initWeekIndex].length < 7) {
                initArr[initWeekIndex] = [
                  ...initArr[initWeekIndex],
                  {
                    date: `${initYear}-${checkedMonth}-${
                      initDateCount < 10 ? '0' + initDateCount : initDateCount
                    }`,
                    num: initDateCount
                  }
                ];
              } else {
                initArr[++initWeekIndex] = [
                  {
                    date: `${initYear}-${checkedMonth}-${
                      initDateCount < 10 ? '0' + initDateCount : initDateCount
                    }`,
                    num: initDateCount
                  }
                ];
              }
            } else {
              initArr[initWeekIndex] = [
                {
                  date: `${initYear}-${checkedMonth}-${
                    initDateCount < 10 ? '0' + initDateCount : initDateCount
                  }`,
                  num: initDateCount
                }
              ];
            }
          } else {
            if (initArr[initWeekIndex].length < 7) {
              initArr[initWeekIndex] = [
                ...initArr[initWeekIndex],
                ...new Array(7 - initArr[initWeekIndex].length).fill({
                  date: '',
                  num: ''
                })
              ];
            } else {
              isLoop = false;
            }
          }
        } else {
          if (!!initArr[initWeekIndex]) {
            if (initArr[initWeekIndex].length < 7) {
              initArr[initWeekIndex] = [
                ...initArr[initWeekIndex],
                {
                  date: `${initYear}-${checkedMonth}-${
                    initDateCount < 10 ? '0' + initDateCount : initDateCount
                  }`,
                  num: initDateCount
                }
              ];
            } else {
              initArr[++initWeekIndex] = [
                {
                  date: `${initYear}-${checkedMonth}-${
                    initDateCount < 10 ? '0' + initDateCount : initDateCount
                  }`,
                  num: initDateCount
                }
              ];
            }
          } else {
            initArr[initWeekIndex] = [
              {
                date: `${initYear}-${checkedMonth}-${
                  initDateCount < 10 ? '0' + initDateCount : initDateCount
                }`,
                num: initDateCount
              }
            ];
          }
        }
        initDateCount++;
      } else {
        if (!!initArr[initWeekIndex]) {
          if (initArr[initWeekIndex].length < 7) {
            initArr[initWeekIndex] = [
              ...initArr[initWeekIndex],
              {
                date: '',
                num: ''
              }
            ];
          } else {
            initArr[++initWeekIndex] = [
              {
                date: '',
                num: ''
              }
            ];
          }
        } else {
          initArr[initWeekIndex] = [
            {
              date: '',
              num: ''
            }
          ];
        }
      }
      initDateIndex++;
    }

    return initArr;
  }

  /**
   * @description 특정 월 토요일, 일요일 날짜 배열 리턴
   * @static
   * @param {*} month
   * @returns {sat: [4,11,18,25], sun: [5,12,19,26]}
   */
  static getSatAndSun(year, month) {
    const date = new Date();
    const y = !!year ? year : date.getFullYear();
    const m = !!month ? month : date.getMonth() + 1;
    const pred = new Date(y, m, 0).getDate();
    console.log('pred',pred)

    let nowDate;
    let satArray = [];
    let sunArray = [];

    for (let i = 1; i <= pred; i++) {
      try {
        // if (i < 10) {
        //   if (m < 10) {
        //     nowDate = new Date(y + '-0' + m + '-0' + i);
        //   } else {
        //     nowDate = new Date(y + '-' + m + '-0' + i);
        //   }
        // } else {
        //   nowDate = new Date(y + '-' + m + '-' + i);
        // }
        nowDate = new Date(y, (m - 1), i);
        // console.log(m)
        // console.log('nowDate',nowDate)
        // console.log('getDay',nowDate.getDay)

        if (nowDate.getDay() === 6) {
          satArray = [...satArray, i];
        }

        if (nowDate.getDay() === 0) {
          sunArray = [...sunArray, i];
        }
      } catch (e) {
        console.log(e)
        return;
      }
    }

    return {
      sat: satArray,
      sun: sunArray
    };
  }

  /**
   *
   * @static
   * @param {*} time
   * @returns
   */
  static getTimeRemaining(time) {
    let distance = new Date(time + '+09:00').getTime() - new Date().getTime();
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    minutes = minutes < 10 && minutes >= 0 ? '0' + minutes : minutes;
    seconds = seconds < 10 && seconds >= 0 ? '0' + seconds : seconds;

    return { days, hours, minutes, seconds, distance };
  }

  /**
   * @description 2차원 배열 달력 주단위로 배열만듬
   * @static
   * @param {*} currentDate
   * @returns [[주], [], []]
   */
  static calendarBinary(currentDate) {
    const date = new Date(currentDate);
    const year = getYear(date);
    const month = getMonth(date);
    const startDate = startOfWeek(new Date(year, month, 1), {
      weekStartsOn: 0
    });
    const endDate = endOfMonth(new Date(year, month));
    const weekLength = eachWeekOfInterval({
      start: startDate,
      end: endDate
    }).length;

    let calendar = [];

    while (calendar.length < weekLength) {
      if (calendar.length === 0) {
        const addSevenDays = addDays(startDate, 6);
        calendar.push(this.daysArr(startDate, addSevenDays));
      } else {
        const start = addDays(calendar[calendar.length - 1][6], 1);
        calendar.push(this.daysArr(start, addDays(start, 6)));
      }
    }

    return calendar;
  }

  /**
   * @desciption 1차원 배열 달력 1일부터 월말까지
   * @static
   * @param {*} currentDate
   * @returns [일,일,일]
   */
  static calendar(currentDate) {
    const date = new Date(currentDate);
    const year = getYear(date);
    const month = getMonth(date);
    const endDate = endOfMonth(new Date(year, month));
    const result = this.daysArr(new Date(year, month, 1), endDate);

    return result;
  }

  /**
   *
   * @static
   * @param {*} start
   * @param {*} end
   * @returns
   */
  static daysArr(start, end) {
    return eachDayOfInterval({
      start: start,
      end: end
    });
  }

  /**
   *
   * @static
   * @param {*} time
   * @returns
   */
  static getTimestamp(date) {
    const timestamp = new Date(date).getTime();

    return timestamp;
  }
}
