import React from 'react';

const CommunityReport = ({
  isOpen,
  closeReport,
  category,
  relTable,
  mbId,
  wrId,
  items = []
}) => {
  if (!isOpen) {
    return null;
  }

  let data = {
    type: null,
    content: null
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let params = {
      category: category,
      type: data.type,
      content: data.content,
      relId: wrId,
      relTable: relTable,
      targetId: mbId
    };

    if (!data.type) {
      alert('신고 유형을 선택하세요');
      return;
    }

    if (!data.content) {
      alert('신고 내용을 입력하세요');
      return;
    }

    await items.actionReport(params);
    closeReport();
  };

  const handleRadio = async (e) => {
    data.type = e.target.value;
  };

  const handleContent = async (e) => {
    data.content = e.target.value;
  };

  return (
    <div className="ly_report">
      <div className="inner">
        <h5 className="tit">신고하기</h5>
        <ul className="list">
          <li>
            <input
              type="radio"
              id="chk_report1"
              name="chk_report"
              className="radio blind"
              value={'욕설/비방'}
              onChange={handleRadio}
              autoComplete="off"
            />
            <label htmlFor="chk_report1" className="radio_label">
              욕설/비방글
            </label>
          </li>
          <li>
            <input
              type="radio"
              id="chk_report2"
              name="chk_report"
              className="radio blind"
              value={'홍보/상업성'}
              onChange={handleRadio}
              autoComplete="off"
            />
            <label htmlFor="chk_report2" className="radio_label">
              홍보/상업성 게시글
            </label>
          </li>
          <li>
            <input
              type="radio"
              id="chk_report3"
              name="chk_report"
              className="radio blind"
              value={'기타'}
              onChange={handleRadio}
              autoComplete="off"
            />
            <label htmlFor="chk_report3" className="radio_label">
              기타
            </label>
          </li>
        </ul>
        <div className="report_cont">
          <textarea
            placeholder="신고내용을 작성해주세요."
            onChange={handleContent}
          ></textarea>
        </div>
        <div className="btn_area">
          <button type="button" className="btn_cancel" onClick={closeReport}>
            취소
          </button>
          <button type="button" className="btn_submit" onClick={onSubmit}>
            신고
          </button>
        </div>
        <button type="button" className="btn_close" onClick={closeReport}>
          닫기
        </button>
      </div>
    </div>
  );
};

export default CommunityReport;
