import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { timeUtils } from '../../../../common/utils/timeUtil';
import useStore from '../../../../hooks/useStore';

const todayDate = timeUtils.todayYmd();

const periodMap = {
  period: [2, 4, 7, 15, 30, 0],
  single: [7, 30, 60, 180, 365, 0]
};

const AnalysisOption = ({ options = [], patternTypeList }) => {
  const { clockStore } = useStore();

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type') || 'daily';
  const option = query.get('option') || 'oneDay';
  const startDate = query.get('start_dt') || todayDate;
  const endDate = query.get('end_dt') || todayDate;
  const round = parseInt(query.get('round')) || 100;
  const count = parseInt(query.get('count')) || 10;

  const baseLink = `${location.pathname}?tab=analysis`;

  const [currentRound, setCurrentRound] = useState(0);

  useEffect(() => {
    clockStore.getPowerRound().then((res) => {
      setCurrentRound(res.currentRound);
    });
  }, []);

  useEffect(() => {
    if (option === 'single') {
      clockStore.getPowerRound().then((res) => {
        setCurrentRound(res.currentRound);
      });
    }
  }, [option]);

  const onChangeOption = (event) => {
    let optionQueryString = '';
    switch (event.target.value) {
      case 'period':
        optionQueryString += `&start_dt=${
          timeUtils.addDay(new Date(), -1).value
        }`;
        break;
      case 'single':
        optionQueryString += `&start_dt=${
          timeUtils.addDay(new Date(), -6).value
        }&round=${currentRound}`;
        break;
      case 'latest':
        optionQueryString += `&round=100`;
        break;
    }

    history.push(
      `${baseLink}&type=${type}&option=${event.target.value}${optionQueryString}`
    );
  };

  const onClickPeriod = (day) => {
    if (day) {
      history.push(
        `${baseLink}&type=${type}&option=${option}&start_dt=${
          timeUtils.addDay(new Date(), -day + 1).value
        }${query.get('round') ? `&round=${round}` : ''}`
      );
    } else {
      history.push(
        `${baseLink}&type=${type}&option=${option}&start_dt=2013-01-02${
          query.get('round') ? `&round=${round}` : ''
        }`
      );
    }
  };

  const onClickRound = (round) => {
    history.push(`${baseLink}&type=${type}&option=${option}&round=${round}`);
  };

  return (
    <>
      <div className="option_tab">
        <ul>
          <li>
            <Link
              to={`${baseLink}&type=daily&option=oneDay`}
              className={clsx('item', type === 'daily' && 'selected')}
            >
              일별분석
            </Link>
          </li>
          <li>
            <Link
              to={`${baseLink}&type=round&option=single&start_dt=${
                timeUtils.addDay(new Date(), -6).value
              }&round=${currentRound}`}
              className={clsx('item', type === 'round' && 'selected')}
            >
              회차분석
            </Link>
          </li>
          <li>
            <Link
              to={`${baseLink}&type=pattern&option=${patternTypeList[0].value}`}
              className={clsx('item', type === 'pattern' && 'selected')}
            >
              패턴분석
            </Link>
          </li>
        </ul>
      </div>
      <div className="option_area">
        {option === 'oneDay' && (
          <div className="date_area">
            <Link
              className="btn_prev"
              to={`${baseLink}&type=daily&option=oneDay&start_dt=${timeUtils.datePrevOrNext(
                startDate,
                'prev'
              )}`}
            >
              이전 날짜
            </Link>
            <strong className="date">
              {timeUtils.dateFormatCalendarYmd(startDate)}
            </strong>
            <Link
              className="btn_next"
              to={
                startDate !== todayDate
                  ? `${baseLink}&type=daily&option=oneDay&start_dt=${timeUtils.datePrevOrNext(
                      startDate,
                      'next'
                    )}`
                  : `${baseLink}&type=daily&option=oneDay&start_dt=${startDate}`
              }
            >
              다음 날짜
            </Link>
          </div>
        )}
        {option === 'period' && (
          <div className="date_area">
            <strong className="date period">
              {`${timeUtils.dateFormatCalendarYmd(
                startDate
              )} ~ ${timeUtils.dateFormatCalendarYmd(endDate)}`}
            </strong>
          </div>
        )}
        {option === 'single' && (
          <div className="date_area">
            <Link
              className="btn_prev"
              to={`${baseLink}&type=round&option=single&start_dt=${startDate}&round=${
                  round > 1 ? round - 1 : round
              }`}
            >
              이전 회차
            </Link>
            <strong className="date">{round}</strong>
            <Link
              className="btn_next"
              to={`${baseLink}&type=round&option=single&start_dt=${startDate}&round=${
                round + 1
              }`}
            >
              다음 회차
            </Link>
          </div>
        )}
        {option === 'latest' && (
          <div className="date_area">
            <strong className="date period">{round}</strong>
          </div>
        )}
        {type === 'pattern' && (
          <div className="date_area">
            <Link
              className="btn_prev"
              to={`${baseLink}&type=pattern&option=${option}&count=${
                count > 7 ? count - 1 : count
              }`}
            />
            <strong className="date">{count}</strong>
            <Link
              className="btn_next"
              to={`${baseLink}&type=pattern&option=${option}&count=${
                count < 20 ? count + 1 : count
              }`}
            />
          </div>
        )}

        <div className="option_type">
          <select onChange={onChangeOption} value={option}>
            {options.map((item, key) => {
              return (
                <option key={key} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>

        {(option === 'period' || option === 'single') && (
          <div className="option_list">
            <ul>
              {periodMap[option].map((value, key) => {
                return (
                  <li key={key}>
                    <button
                      type="button"
                      className={clsx(
                        value
                          ? timeUtils.getDifferenceDayLeftRight(
                              todayDate,
                              startDate
                            ) +
                              1 ===
                              value && 'selected'
                          : timeUtils.getDifferenceDayLeftRight(
                              todayDate,
                              startDate
                            ) > 365 && 'selected'
                      )}
                      onClick={() => onClickPeriod(value)}
                    >
                      {value ? `${value}일` : '전체'}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {option === 'latest' && (
          <div className="option_list">
            <ul>
              <li>
                <button
                  type={'button'}
                  className={clsx(round === 100 && 'selected')}
                  onClick={() => onClickRound(100)}
                >
                  100
                </button>
                <button
                  type={'button'}
                  className={clsx(round === 200 && 'selected')}
                  onClick={() => onClickRound(200)}
                >
                  200
                </button>
                <button
                  type={'button'}
                  className={clsx(round === 300 && 'selected')}
                  onClick={() => onClickRound(300)}
                >
                  300
                </button>
                <button
                  type={'button'}
                  className={clsx(round === 400 && 'selected')}
                  onClick={() => onClickRound(400)}
                >
                  400
                </button>
                <button
                  type={'button'}
                  className={clsx(round === 500 && 'selected')}
                  onClick={() => onClickRound(500)}
                >
                  500
                </button>
                <button
                  type={'button'}
                  className={clsx(round === 1000 && 'selected')}
                  onClick={() => onClickRound(1000)}
                >
                  1000
                </button>
                <button
                  type={'button'}
                  className={clsx(round === 1500 && 'selected')}
                  onClick={() => onClickRound(1500)}
                >
                  1500
                </button>
                <button
                  type={'button'}
                  className={clsx(round === 2000 && 'selected')}
                  onClick={() => onClickRound(2000)}
                >
                  2000
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default AnalysisOption;
