import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../../../context';
import {
  Header,
  Help,
  Button,
  List,
  ListItem,
  UserInfo,
  Summary
} from './styled';
import Pagination from '../../../../../components/pagination';
import GuidanceModal from './guidanceModal';
import { Url } from '../../../../../common/constants/environment';
import useCommonStore from '../../../../../hooks/useCommonStore';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import NoneContent from '../../../../../components/noneContent';

const MypageFriendsBlack = observer(({ history }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;

  const commonStore = useCommonStore();
  const { mypageStore } = useMypageStore(MypageContext);

  const [isOpenGuidanceModal, setOpenGuidanceModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    mypageStore.actionBlackList({ page }).then((res) => {
      if (res) setLoading(false);
    });
    mypageStore.actionLevelList();
  }, [page]);

  const onClickLimitChange = () => {
    commonStore.modalStore.openConfirmModal({
      message:
        mypageStore.data.next_point +
        'GP 를 이용해 블랙리스트 등록제한\n10개를 추가하시겠습니까?',
      okCallback: () => {
        mypageStore.actionLimitChange();
      }
    });
  };

  const onClickDelete = (deleteMbId) => {
    commonStore.modalStore.openConfirmModal({
      message: `정말 삭제하시겠습니까?`,
      okCallback: () => {
        commonStore
          .actionBlackDelete({ deleteMbId: deleteMbId })
          .then((res) => res && mypageStore.actionBlackList({ page }));
      }
    });
  };

  const totalCount = mypageStore.data.total_cnt;

  return (
    <div>
      <GuidanceModal
        isOpen={isOpenGuidanceModal}
        onClose={() => setOpenGuidanceModal(false)}
        levelList={mypageStore.levelList}
      />
      <Header>
        <div>
          {`내 블랙 리스트 ${mypageStore.data.total_cnt} / ${mypageStore.data.max_black_count}명`}
          <Help onClick={() => setOpenGuidanceModal(!isOpenGuidanceModal)}>
            ?
          </Help>
        </div>
        <Button onClick={onClickLimitChange}>인원 확장</Button>
      </Header>
      {totalCount < 1 && !loading ? (
        <NoneContent title={'블랙 리스트'} />
      ) : (
        !loading && (
          <>
            <List>
              {mypageStore.data.list.map((item, key) => {
                return (
                  <Item key={key} item={item} onClickDelete={onClickDelete} />
                );
              })}
            </List>
            <Pagination page={page} totalCount={totalCount} rowCount={10} />
          </>
        )
      )}
    </div>
  );
});

const Item = ({ item, onClickDelete }) => {
  const profileImg = item['profile_image_path']
    ? `${Url.thumb}normal/crop/112x112${item['profile_image_path'].replace(
        'data/',
        ''
      )}`
    : '/images/thumb_user_def.png';

  return (
    <ListItem>
      <UserInfo
        levelImgUrl={`/images/level/lv${item.friend_profile.level}.svg`}
      >
        <span className={clsx('thumb', `lv${item.friend_profile.level}`)}>
          <img
            src={profileImg}
            onError={(event) => {
              event.target.src = '/images/thumb_user_def.png';
            }}
          />
          <svg
            className="border"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 36 36"
            enableBackground="new 0 0 36 36"
            xmlSpace="preserve"
          >
            <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
          </svg>
        </span>
        <Summary>
          <div>
            <em>{item.mb_nick}</em>
            {item.is_online === 1 ? (
              <strong>접속중</strong>
            ) : (
              <span>{item.mb_ahead_days}</span>
            )}
          </div>
          {item.today_word && <p>{item.today_word}</p>}
        </Summary>
      </UserInfo>
      <Button onClick={() => onClickDelete(item.mb_id)}>해제</Button>
    </ListItem>
  );
};

export { MypageFriendsBlack };
