import React from 'react';
import {Url} from "../../common/constants/environment";

const ScoreDetailHtml = () => {
  return (
    <>

      {/* score_detail_wrap */ }
      <div className="score_detail_wrap"> {/* 클래스 상태 : small_view, ready, close */}

        <div className="summary">
          <button className="btn_prev">이전으로</button>
          <h2 className="tit">2020 ASL S10</h2>
          <button className="btn_oc">크게보기/작게보기</button>
        </div>

        {/* score_detail_board */ }
        <div className="score_detail_board starcraft"> {/* 클래스 종목 : soccer, basketball ---- 종목별 이름 */}

          {/* summary_score */ }
          <div className="summary_score">
            <div className="team left">
              <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/starcraft/player/729_1603782633.png"}/>
                <span className="name">바로 셀로로</span>
                <span className="player">디에고 카스틸로</span>
            </div>
            <div className="info">
              <div className="score">
                <strong className="left on">
                  <em className="num">0</em>
                </strong>
                <strong className="right">
                  <em className="num">0</em>
                </strong>
              </div>
              <div className="state"><span>3세트 30:20</span></div>
              <div className="time">10.22 09:00</div>
              <div className="stadium">푸동 스타디움</div>
            </div>
            <div className="team right"><img className="logo" src={`${Url.thumb}normal/resize/0x55/sports/lol/team/222_1586423078.png`}/>
              <div className="name">바로 셀로나</div>
              <span className="player">디에고 카스틸로</span>
            </div>
          </div>
          {/* // summary_score */ }

        </div>
        {/* // score_detail_board */ }

        {/* snb_wrap */ }
        <div className="snb_wrap">
          <ul>
            <li><a className="item" href="javascript:;">응원</a></li>
            <li><a className="item selected" href="javascript:;">중계</a></li>
          </ul>
        </div>
        {/* // snb_wrap */ }


      </div>
      {/* // score_detail_wrap */ }

    </>
  );
};

export default ScoreDetailHtml;
