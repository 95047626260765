import Api from '../api';
import { Url } from '../../constants/environment';

class AttendanceService extends Api {
  constructor(props) {
    super(props);
  }

  /**
   * @description 최신
   * @returns
   */
  async getMyAttendance() {
    const res = await this.commonAjax(
      'get',
      Url.named,
      `users/attendanceList`,
    );

    return res;
  }

  /**
   * @description 최신
   * @returns
   */
  async checkInMyAttendance(greet) {
    const res = await this.commonAjax(
      'get',
      Url.named,
      `users/attendance`,
      {
        greet,
      },
    );

    return res;
  }

    /**
   * @description 최신
   * @returns
   */
    async getAttendanceGreetingList(payload) {
      const res = await this.commonAjax(
        'get',
        Url.named,
        `users/attendanceGreetingList`, payload
      );
  
      return res;
    }
}

export default new AttendanceService();
