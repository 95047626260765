import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { List, ListItem, Summary } from './styled';
import { ImageUtil } from '../../../../common/utils/imageUtil';
import { RegexUtil } from '../../../../common/utils/regexUtil';
import { PopupModal } from '../../../../components/modal/popup/popupModal';
import TradeDetail from '../../trade_detail';

const MypageTrade = observer(({ history, items, tab }) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setOpen] = useState(false);

  const onClickItem = (item) => {
    setOpen(true);
    setSelectedItem(item);
  };

  return (
    <>
      <List>
        {items.map((item, key) => {
          return <Item key={key} item={item} onClickItem={onClickItem} />;
        })}
      </List>
      <PopupModal isOpen={isOpen} onClose={() => setOpen(false)}>
        <TradeDetail
          history={history}
          item={selectedItem}
          tab={tab}
          onClose={() => setOpen(false)}
        />
      </PopupModal>
    </>
  );
});

const Item = ({ item, onClickItem }) => {
  return (
    <>
      <ListItem
        disabled={item.status === 'COMPLETE'}
        onClick={() => {
          if (item.status === 'COMPLETE') return;
          onClickItem(item);
        }}
      >
        <img src={ImageUtil.imageUrl(item.image, 0, 50)} />
        <Summary>
          <div>
            <span className={'name'}>{item.item_name}</span>
            <span className={'point'}>
              {item.point && `${RegexUtil.makeComma(item.point)} P`}
            </span>
          </div>
          <div>
            <p>{item.title}</p>
          </div>
        </Summary>
      </ListItem>
    </>
  );
};

export { MypageTrade };
