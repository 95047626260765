import styled, { css } from 'styled-components';

export const Background = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 199;
  background-color: rgba(0, 0, 0, 0.5);
  ${(props) =>
    !props.isOpen &&
    css`
      visibility: hidden;
    `}
`;

export const Wrap = styled.div`
  background-color: #fff;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  overflow: hidden;
  width: 100%;
  max-width: 334px;
  max-height: 100%;
  height: calc(100% - 100px);
  > div {
    :nth-child(2) {
      height: calc(100% - 58px);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  border-bottom: 1px solid #e4e4e4;
  box-sizing: border-box;
  padding: 0 20px;
  h2 {
    font-size: 14px;
  }
`;

export const CloseButton = styled.button`
  position: relative;
  width: 18px;
  height: 18px;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 18px;
    background: #333;
  }
  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`;

export const TypeWrap = styled.div`
  display: flex;
`;

export const TypeButton = styled.button`
  width: 50%;
  height: 45px;
  box-sizing: border-box;
  color: #999;
  background-color: #f1f2f4;
  border-bottom: 1px solid #e4e4e4;
  font-size: 14px;
  font-weight: bold;
  &.type-score {
    border-right: 1px solid #e4e4e4;
  }
  &.type-reward {
    border-left: 1px solid #e4e4e4;
  }
  ${(props) =>
    props.selected &&
    css`
      border-color: #fff !important;
      background-color: #fff;
      color: #333;
    `}
`;

export const GuideList = styled.ul`
  padding: 25px 20px;
  height: calc(100% - 45px);
  box-sizing: border-box;
  overflow-y: auto;
  li {
    display: flex;
    flex-direction: column;
    :not(:last-child) {
      margin-bottom: 12px;
    }
    em {
      font-size: 12px;
      color: #999;
      margin-bottom: 2px;
    }
    p {
      font-size: 13px;
      color: #222;
    }
  }
`;
