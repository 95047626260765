import React, { useState, useEffect } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Url } from '../../../../common/constants/environment';

class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // xhr.open('POST', 'http://localhost:5000/upload', true);
    xhr.open('POST', `${Url.named}community/board/upload-file`, true);
    xhr.responseType = 'json';
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      resolve({
        default: response.url
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file) {
    const data = new FormData();

    data.append('file', file);

    this.xhr.send(data);
  }
}

const Editor = (props) => {
  const { content, setContent, mode } = props;
  const [editor, setEditor] = useState();
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (!!editor) {
      if (mode === 'modify' && !!content && isInit === false) {
        editor.setData(content);

        setIsInit(true);
      }

      editor.model.document.on('change:data', () => {
        setContent(editor.getData());
      });
    }
  }, [mode, isInit, setContent, editor, content]);

  useEffect(() => {
    ClassicEditor.create(document.getElementById('editor'), {
      placeholder: !!props.placeholder
        ? props.placeholder
        : '내용을 입력하세요.',
      mediaEmbed: {
        previewsInData: true
      }
    })
      .then((editor) => {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
          return new UploadAdapter(loader);
        };

        setEditor(editor);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <div id="editor"></div>;
};

export { Editor };
