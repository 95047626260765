import { Url } from '../constants/environment';
/**
 * @description 이미디를 제어하는 유틸
 * @export
 * @class ImageUtil
 */

export class ImageUtil {
  /**
   * @description 이미지 path를 가져와서 full url로 바꿔준다
   * @static
   * @param {*} path
   * @returns
   */
  static imageUrl(path, width, height) {
    return `${Url.thumb}normal/resize/${width}x${height}${path}`;
  }
}
