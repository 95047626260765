import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: relative;
`;

export const HeaderBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #3498db;
`;

export const LogoContainer = styled.div`
  img {
    height: 100%;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  background: #fff;
  border-radius: 24px;
  padding: 4px;
  height: 30px;
  position: relative;
`;

export const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 12px;
`;

export const SearchInput = styled.input`
  flex: 1;
  height: 100%;
  padding: 12px;
  border: none;
  background-color: transparent;
  font-size: 13px;
`;

export const ClearButton = styled.button`
  height: 100%;
  width: 36px;
  padding: 6px;

  img {
    height: 100%;
  }

  /* Prevent highlight color */
  outline: none;
  background: transparent;

  /* Prevent focus outline */
  &:focus {
    outline: none;
  }

  /* Optional: Prevent tapping highlight on mobile */
  -webkit-tap-highlight-color: transparent;
`;

export const CancelButton = styled.span`
  color: white;
  font-size: 12px;
  margin-left: 16px;
  text-align: center;
  white-space: nowrap;
`;

export const SearchButton = styled.button`
  height: 100%;
  width: 44px;
  padding: 12px;

  img {
    height: 100%;
  }
`;

export const SuggestionsList = styled.ul`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  list-style: none;
  margin: 0;
  max-height: 40vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);

  /* Add a subtle scroll indicator */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  pointer-events: none;
`;

export const SuggestionContent = styled.span`
  display: flex;
  background-repeat: no-repeat;
  padding: 4px 12px;

  .highlight {
    color: #ee0000;
    background-color: transparent;
    padding: 0;
  }
`;

export const HighlightedTextStyle = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: bottom;
`;
