import React, { useEffect, useMemo } from "react";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import SpeedLadder from './speedLadder';
import Daridari from './daridari';
import Ladder from './ladder';
// import SnailRacing from './snailRacing';
// import PowerKenoLadder from './powerKenoLadder';
import Holdem from "./holdem";
import Powerball from "./powerball";
import Runningball from "./runningball";
import PowerLadder from "./powerLadder";
import Seo from "../../components/seo";
import clsx from "clsx";
import ModalController from '../../components/mainModal/ModalController'


const subNavList = {
  rball: [
    {
      label: '스페이스8 (3분)',
      href: '/minigame/rball/space8',
    },
    {
      label: '스피드6 (5분)',
      href: '/minigame/rball/runningball5_6',
    },
    {
      label: '스피드4 (5분)',
      href: '/minigame/rball/runningball5_4',
    },
    {
      label: '메이즈2 (5분)',
      href: '/minigame/rball/runningball3',
    },
  ],
  nball: [
    {
      label: 'N파워볼(5분)',
      href: '/minigame/nball/powerball5',
    },
    {
      label: 'N파워볼(3분)',
      href: '/minigame/nball/powerball3',
    },
    {
      label: 'N파워사다리(5분)',
      href: '/minigame/nball/powerladder5',
    },
    {
      label: 'N파워사다리(3분)',
      href: '/minigame/nball/powerladder3',
    },
  ],
  redball: [
    {
      label: '레드파워볼',
      href: '/minigame/redball/powerball',
    },
    {
      label: '레드파워사다리',
      href: '/minigame/redball/powerladder',
    },
  ],
  ladder: [
    {
      label: '사다리(5분)',
      href: '/minigame/ladder/ladder',
    },
    {
      label: '다리다리(3분)',
      href: '/minigame/ladder/daridari',
    },
    {
      label: '스피드사다리(1분)',
      href: '/minigame/ladder/speedladder',
    },
  ],
  holdem: [],
};

const Game = ({ history }) => {
  const location = useLocation();
  const brandName = location.pathname.split("/")[2];
  const gameName = location.pathname.split("/")[3];

  useEffect(() => {
    return () => {
      if (!!window.sessionStorage.getItem("lastMessage")) {
        window.sessionStorage.removeItem("lastMessage");
      }
    };
  }, []);


  const { subNavComponents, activeTitle } = useMemo(() => {

    let subNavComponents = [];
    let activeTitle = "";
    if (brandName) {
      subNavList[brandName].map((subNav, key) => {
        const isSelected = subNav.href.includes(`${brandName}/${gameName}`);
        const newGameNames = ['space8', 'ladder'];
        if (isSelected) {
          activeTitle = subNav.label;
        }
        subNavComponents.push(
          <li key={key}>
            <Link
              to={subNav.href}
              className={clsx("item", isSelected && "selected")}
              style={{position:'relative'}}
            >
              {newGameNames.some(item => subNav.href.includes(item)) && <div className={'blinking big_new_icon'} />}
              {subNav.label}
            </Link>
          </li>
        );
      });
    }
    return {
      subNavComponents,
      activeTitle
    };
  }, [brandName, gameName]);


  return (
    <>
      <ModalController />
      <Seo title={activeTitle} />

      {subNavComponents.length > 0 && (
        <div className='snb_wrap'>
          <ul>{subNavComponents}</ul>
        </div>
      )}
      <div id='game_wrap'>
        <Switch>
          <Route
            path={[
              '/minigame/rball/runningball3',
              '/minigame/rball/runningball5_4',
              '/minigame/rball/runningball5_6',
              '/minigame/rball/space8',
            ]}
            component={Runningball}
          />
          <Route
            path={['/minigame/redball/powerball', '/minigame/nball/powerball5', '/minigame/nball/powerball3']}
            component={Powerball}
          />
          <Route
            path={['/minigame/redball/powerladder', '/minigame/nball/powerladder5', '/minigame/nball/powerladder3']}
            component={PowerLadder}
          />
          {/*<Route path="/game/kenoladder" component={KenoLadder} />*/}
          {/*<Route path="/game/powerkenoladder" component={PowerKenoLadder} />*/}
          <Route path='/minigame/holdem' component={Holdem} />
          <Route path='/minigame/ladder/speedladder' component={SpeedLadder} />
          <Route path='/minigame/ladder/daridari' component={Daridari} />
          <Route path='/minigame/ladder/ladder' component={Ladder} />
          {/*<Route path="/game/snailracing" component={SnailRacing} />*/}

          <Route path={'/minigame/nball'} render={() => <Redirect to={'/minigame/nball/powerball5'} />} />
          <Route path={'/minigame/rball'} render={() => <Redirect to={'/minigame/rball/space8'} />} />
          <Route path={'/minigame/redball'} render={() => <Redirect to={'/minigame/redball/powerball'} />} />
          <Route path={'/minigame/ladder'} render={() => <Redirect to={'/minigame/ladder/ladder'} />} />
          <Route path={'/'} render={() => <Redirect to={'/minigame/rball/space8'} />} />
        </Switch>
      </div>
    </>
  );
};

export default Game;
