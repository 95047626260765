import React, { memo, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const ModalRenderer = memo(({ children, onClose }) => {
  return (
    <div className="layer_popup_fixed">
      {children}
    </div>
  );
});

export const PopupModal = memo(({ isOpen, onClose, children }) => {
  const [mountNode, setMountNode] = useState(undefined);

  // This effect will not be ran in the server environment
  useEffect(() => setMountNode(document.body));

  if (isOpen && mountNode) {
    return ReactDOM.createPortal(
      <ModalRenderer onClose={onClose}>{children}</ModalRenderer>,
      document.body
    );
  } else {
    return null;
  }
});
