import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

const Ball2RoundTable = ({ list = [], title = '회차별 통계' }) => {
  const size = 10;
  const [page, setPage] = useState(1);
  const totalPage = useMemo(() => {
    if (list.length === 0) return 1;
    return Math.ceil(list.length / size);
  }, [list.length]);

  return (
    <div className="data_list_wrap">
      <h3 className="tit">{title}</h3>
      <div className="data_list">
        <div className="table_float">
          <table className="list" border="1">
            <caption>결과리스트</caption>
            <colgroup>
              <col width="74px" />
            </colgroup>
            <thead>
              <tr>
                <th>회차</th>
              </tr>
            </thead>
            <tbody>
              {list.slice(0, size * page).map((item, key) => {
                var className = '';
                if((key+1) % 2 == 0) {
                  className = 'col-even';
                } else {
                  className = 'col-odd';
                }
                return (
                  <tr key={key} className={className}>
                    <td>{`${item.date_round} (${item.round})`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="table_data powerball">
          <table className="list" border="1">
            <caption>결과리스트</caption>
            <colgroup>
              <col width="49px" />
              <col width="49px" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="3">1등</th>
              </tr>
              <tr>
                <th>결과</th>
                <th>홀짝</th>
              </tr>
            </thead>
            <tbody>
              {list.slice(0, size * page).map((item, key) => {
                var className = '';
                if((key+1) % 2 == 0) {
                  className = 'col-even';
                } else {
                  className = 'col-odd';
                }
                return (
                  <tr key={key} className={className}>
                    <td>{item.ball_1}</td>
                    <td>
                      {item.odd_even ?
                        <span className={clsx('ic', item.odd_even)}>
                          {item.odd_even === 'ODD' ? '홀' : '짝'}
                        </span>
                          :
                        <span className={clsx('ic', item.runningball_odd_even)}>
                          {item.runningball_odd_even === 'ODD' ? '홀' : '짝'}
                        </span>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <button
        className="btn_more"
        style={page === totalPage ? { display: 'none' } : {}}
        onClick={() => {
          if (page >= totalPage) return;
          setPage(page + 1);
        }}
      >
        더보기 ( {page} / {totalPage} )
      </button>
    </div>
  );
};

export default Ball2RoundTable;
