import { extendObservable, computed, action, toJS } from 'mobx';
import autobind from 'autobind-decorator';
import LolSetModel from './set';
import LolBanModel from './ban';
import LolPlayerModel from './player';
import { sortPlayers } from '../util';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import { _E_SPORTS_GAME_STATUS } from '../../../../../common/constants/sportsConstants';

@autobind
class LolModel {
  constructor(data) {
    extendObservable(this, data);

    this.sets = data.sets.map((set) => {
      let setDeep = { ...set };
      return new LolSetModel(setDeep);
    });
  }

  @computed
  get activeSet() {
    const { sets } = this;
    if (sets.length > 0) {
      let activeSet = sets.find((set) => {
        return _E_SPORTS_GAME_STATUS[set.sstatus] === 'IN_PROGRESS';
      });

      if (activeSet !== undefined) {
        return activeSet;
      } else {
        return sets[sets.length - 1];
      }
    } else {
      return null;
    }
  }

  @computed
  get startDatetime() {
    const { gdate } = this;

    return new Date(gdate + 'T00:00:00');
  }

  @computed
  get getDate() {
    const { gdate, gtime } = this;

    return `${gdate}T${gtime}`;
  }

  @computed
  get getMatchGameTime() {
    return timeUtils.matchGameForamt(this.getDate);
  }

  @computed
  get getStartDateTime() {
    const { gtime } = this;
    const date = gtime.split(':');

    return [date[0], date[1]].join(':');
  }

  @computed
  get getTeamData() {
    const { home, away, homeScore, awayScore } = this;

    return {
      home: {
        name: home.name_en,
        score: homeScore,
        imgPath: home.img_path
      },
      away: {
        name: away.name_en,
        score: awayScore,
        imgPath: away.img_path
      }
    };
  }

  @computed
  get getTeamStat() {
    const { home, away } = this;

    let stat = {
      home: {
        rank: 0,
        draw: 0,
        gameCount: 0,
        lose: 0,
        win: 0,
        winPer: 0
      },
      away: {
        rank: 0,
        draw: 0,
        gameCount: 0,
        lose: 0,
        win: 0,
        winPer: 0
      }
    };

    if (home.stat.game_count > 0) {
      stat.home.winPer = Math.round(
        (home.stat.win / home.stat.game_count) * 100
      );
    }

    if (away.stat.game_count > 0) {
      stat.away.winPer = Math.round(
        (away.stat.win / away.stat.game_count) * 100
      );
    }

    stat = {
      ...stat,
      home: {
        ...stat.home,
        ...home.stat,
        rank: home.rank,
        winPerText: `${stat.home.winPer}%`
      },
      away: {
        ...stat.away,
        ...away.stat,
        rank: away.rank,
        winPerText: `${stat.away.winPer}%`
      }
    };

    return stat;
  }

  @computed
  get getTabs() {
    const { sets } = this;
    let tabs = [
      { key: 1, value: '1세트', disabled: true },
      { key: 2, value: '2세트', disabled: true },
      { key: 3, value: '3세트', disabled: true },
      { key: 4, value: '4세트', disabled: true },
      { key: 5, value: '5세트', disabled: true }
    ];
    const sLength = sets.length;

    if (sLength > 0) {
      tabs = tabs.map((tab) => {
        const checkSet = sets.some((set) => set.snum === tab.key);

        if (checkSet) {
          tab.disabled = false;
        }

        return tab;
      });
    }

    return tabs;
  }

  @computed
  get statusText() {
    const { gameStatus } = this;
    const eSportsGameStatus = _E_SPORTS_GAME_STATUS[gameStatus];

    if (eSportsGameStatus === 'CANCEL') {
      return '취소';
    } else if (eSportsGameStatus === 'READY') {
      return '대기';
    } else if (eSportsGameStatus === 'FINAL') {
      return '종료';
    } else if (eSportsGameStatus === 'IN_PROGRESS') {
      if (this.activeSet !== null) {
        if (_E_SPORTS_GAME_STATUS[this.activeSet.sstatus] === 'IN_PROGRESS') {
          const { snum, displayTime } = this.activeSet;
          return `${snum}세트 ${displayTime}`;
        }
      }
      return '경기중';
    }
  }

  @computed
  get winnerTeam() {
    const { getTeamData } = this;
    const { home, away } = getTeamData;

    const homeScore = home.score;
    const awayScore = away.score;
    let team = 'draw';

    if (homeScore > awayScore) {
      team = 'home';
    } else if (homeScore < awayScore) {
      team = 'away';
    } else if (homeScore === awayScore) {
      team = 'draw';
    }

    return team;
  }

  @computed
  get betbleButtonVisible() {
    const { betbleUri, getTracker } = this;

    return !!betbleUri && getTracker.status !== 'final';
  }

  @computed
  get getTracker() {
    const { gameStatus } = this;

    if (_E_SPORTS_GAME_STATUS[gameStatus] === 'IN_PROGRESS') {
      return { status: 'progress', class: '' };
    } else if (
      _E_SPORTS_GAME_STATUS[gameStatus] === 'FINAL' ||
      _E_SPORTS_GAME_STATUS[gameStatus] === 'CANCEL'
    ) {
      return { status: 'final', class: 'close' };
    } else {
      // READY
      return { status: 'ready', class: 'wait' };
    }
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  updateGame(data) {
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  }

  @action
  updatePeriod(data) {
    const setIdx = this.sets.findIndex((item) => item.snum === data.snum);

    if (setIdx < 0) {
      let deep = [...this.sets];

      let setDeep = { ...data };

      deep.push(new LolSetModel(setDeep));

      this.sets = deep;
    } else {
      this.sets[setIdx].updateSetDetail(data);
    }
  }
}

export default LolModel;
