import React from 'react';

const GamePickHtml = () => {
  return (
    <>
      <div className="minigame_wrap">
        {/* minigame_area */}
        <div className="minigame_area">
          {/* hd */}
          <div className="hd">
            <h2 className="tit">
              <a href="javascript:;">파워볼</a>
            </h2>
          </div>
          {/* // hd */}

          {/* bd */}
          <div className="bd">
            {/* minigame_list */}
            <div className="minigame_list">
              {/* section */}
              <div className="section">
                <div className="top">
                  <strong className="tit ic minigame">미니게임</strong>
                  <button type="button" className="btn_oc">
                    열기/닫기
                  </button>
                </div>
                <div className="list">
                  <div className="item">
                    <a href="javascript:;">
                      <span className="time">4:55</span>
                      <div className="inner">
                        <div className="result_area">
                          <div className="row">
                            <span className="count">사다리 201회차</span>
                            <div className="result">
                              <span className="ic RIGHT">우</span>
                              <span className="ic LINE4">4</span>
                              <span className="ic EVEN">짝</span>
                            </div>
                          </div>
                          <div className="row">
                            <span className="count">사다리 201회차</span>
                            <div className="result">
                              <span className="ic LEFT">좌</span>
                              <span className="ic LINE3">3</span>
                              <span className="ic ODD">홀</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="item">
                    <a href="javascript:;">
                      <span className="time">4:55</span>
                      <div className="inner">
                        <div className="result_area">
                          <div className="row">
                            <span className="count">달팽이 201회차</span>
                            <div className="result">
                              <span className="ic UNDER">언더</span>
                              <span className="ic EVEN">짝</span>
                              <span className="ic SNAIL2">2</span>
                            </div>
                          </div>
                          <div className="row">
                            <span className="count">사다리 201회차</span>
                            <div className="result">
                              <span className="ic LEFT">좌</span>
                              <span className="ic LINE3">3</span>
                              <span className="ic ODD">홀</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              {/* // section */}

              {/* section */}
              <div className="section">
                <div className="top">
                  <strong className="tit ic lottery">파워키노</strong>
                  <button type="button" className="btn_oc">
                    열기/닫기
                  </button>
                </div>
                <div className="list">
                  <div className="item">
                    <a href="javascript:;">
                      <span className="time">4:55</span>
                      <div className="inner">
                        <div className="result_area">
                          <div className="row">
                            <span className="count">파워볼 201회차</span>
                            <div className="result">
                              <span className="ic EVEN">짝</span>
                              <span className="ic OVER">오버</span>
                              <span className="ic EVEN">짝</span>
                              <span className="ic MEDIUM">중</span>
                              <span className="ic UNDER">언더</span>
                            </div>
                          </div>
                          <div className="row">
                            <span className="count">사다리 201회차</span>
                            <div className="result">
                              <span className="ic EVEN">짝</span>
                              <span className="ic OVER">오버</span>
                              <span className="ic EVEN">짝</span>
                              <span className="ic MEDIUM">중</span>
                              <span className="ic UNDER">언더</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="item">
                    <a href="javascript:;">
                      <span className="time">4:55</span>
                      <div className="inner">
                        <div className="result_area">
                          <div className="row">
                            <span className="count">스피드키노 201회차</span>
                            <div className="result">
                              <span className="ic ODD">홀</span>
                              <span className="ic UNDER">언더</span>
                              <span className="ic SUM">781</span>
                            </div>
                          </div>
                          <div className="row">
                            <span className="count">사다리 201회차</span>
                            <div className="result">
                              <span className="ic LEFT">좌</span>
                              <span className="ic LINE3">3</span>
                              <span className="ic ODD">홀</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="item">
                    <a href="javascript:;">
                      <span className="time">4:55</span>
                      <div className="inner">
                        <div className="result_area">
                          <div className="row">
                            <span className="count">
                              파워키노사다리 201회차
                            </span>
                            <div className="result">
                              <span className="ic RIGHT">우</span>
                              <span className="ic LINE4">4</span>
                              <span className="ic EVEN">짝</span>
                              <span className="ic SPEEDKENO">키노</span>
                            </div>
                          </div>
                          <div className="row">
                            <span className="count">사다리 201회차</span>
                            <div className="result">
                              <span className="ic RIGHT">우</span>
                              <span className="ic LINE4">4</span>
                              <span className="ic EVEN">짝</span>
                              <span className="ic POWERBALL">파워</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              {/* // section */}
            </div>
            {/* // minigame_list */}
          </div>
          {/* // bd */}
        </div>
        {/* // minigame_area */}
      </div>
    </>
  );
};

export default GamePickHtml;
