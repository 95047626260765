import React from 'react';
import PeriodBarChart from '../../../periodBarChart';

const space8CountWinMinMaxList = [
    { name: '좌', resultKey: 'home_count_win' },
    { name: '무', resultKey: 'draw_count_win' },
    { name: '우', resultKey: 'away_count_win' }
];
const space8SumWinMinMaxList = [
    { name: '좌', resultKey: 'home_sum_win' },
    { name: '무', resultKey: 'draw_sum_win' },
    { name: '우', resultKey: 'away_sum_win' }
];
const space8SumHomeBallMinMaxList = [
    { name: '홀', resultKey: 'sum_home_ball_odd' },
    { name: '0', resultKey: 'sum_home_ball_zero' },
    { name: '짝', resultKey: 'sum_home_ball_even' }
];
const space8SumAwayBallMinMaxList = [
    { name: '홀', resultKey: 'sum_away_ball_odd' },
    { name: '0', resultKey: 'sum_away_ball_zero' },
    { name: '짝', resultKey: 'sum_away_ball_even' }
];
const space8HomeBall1OddEvenMinMaxList = [
    { name: '홀', resultKey: 'home_ball1_odd' },
    { name: '0', resultKey: 'home_ball1_oe_zero' },
    { name: '짝', resultKey: 'home_ball1_even' }
];
const space8AwayBall1OddEvenMinMaxList = [
    { name: '홀', resultKey: 'away_ball1_odd' },
    { name: '0', resultKey: 'away_ball1_oe_zero' },
    { name: '짝', resultKey: 'away_ball1_even' }
];
const space8HomeBall1OverUnderMinMaxList = [
    { name: '언', resultKey: 'home_ball1_under' },
    { name: '0', resultKey: 'home_ball1_uo_zero' },
    { name: '오', resultKey: 'home_ball1_over' }
];
const space8AwayBall1OverUnderMinMaxList = [
    { name: '언', resultKey: 'away_ball1_under' },
    { name: '0', resultKey: 'away_ball1_uo_zero' },
    { name: '오', resultKey: 'away_ball1_over' }
];

const sumMinMaxList = [
    { name: '홀', resultKey: 'sum_odd' },
    { name: '짝', resultKey: 'sum_even' },
    { name: '언더', resultKey: 'sum_under' },
    { name: '오버', resultKey: 'sum_over' },
];

const Space8PeriodBarChart = ({ data }) => {
    if (Object.keys(data).length <= 3) {
        return null;
    }
    return (
        <div className="data_minmax_wrap">
            <h3 className="tit">기간내 최소/최다 출현 통계</h3>
            <div className="minmax_list">
                <div className="reference">
                    <span className="tx_min">최소출현</span>
                    <span className="tx_max">최대출현</span>
                </div>
                <div className="tit_sub">볼 갯수 승무패</div>
                <PeriodBarChart data={data} mapList={space8CountWinMinMaxList}/>
                <div className="tit_sub">볼 번호 합산 승무패</div>
                <PeriodBarChart data={data} mapList={space8SumWinMinMaxList}/>
                <div className="tit_sub">좌측 번호 합 홀짝</div>
                <PeriodBarChart data={data} mapList={space8SumHomeBallMinMaxList}/>
                <div className="tit_sub">우측 번호 합 홀짝</div>
                <PeriodBarChart data={data} mapList={space8SumAwayBallMinMaxList}/>
                <div className="tit_sub">좌측 1등볼 홀짝</div>
                <PeriodBarChart data={data} mapList={space8HomeBall1OddEvenMinMaxList}/>
                <div className="tit_sub">우측 1등볼 홀짝</div>
                <PeriodBarChart data={data} mapList={space8AwayBall1OddEvenMinMaxList}/>
                <div className="tit_sub">좌측 1등볼 언오버</div>
                <PeriodBarChart data={data} mapList={space8HomeBall1OverUnderMinMaxList}/>
                <div className="tit_sub">우측 1등볼 언오버</div>
                <PeriodBarChart data={data} mapList={space8AwayBall1OverUnderMinMaxList}/>


                {data.max_sum_odd_count && // 3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 로직 필요없음
                    (<>
                        <div className="tit_sub">123등 합</div>
                        <PeriodBarChart data={data} mapList={sumMinMaxList}/> </>)
                }
            </div>
        </div>
    );
};

export default Space8PeriodBarChart;
