import {
  extendObservable,
  computed,
  action,
  observable,
  runInAction
} from 'mobx';
import autobind from 'autobind-decorator';
import AbstractScoreAudioPrompt from '../../../store/audio';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import { ScoreUtil } from '../../../../../common/utils/scoreUtil';

@autobind
class BaseballModel extends AbstractScoreAudioPrompt {
  @observable
  goalEvent = {
    location: '',
    isActive: false
  };

  constructor(data) {
    super();
    extendObservable(this, data);
  }

  @computed
  get getStartDateTime() {
    return timeUtils.timeFormatHourMinute(this.startDatetime);
  }

  @computed
  get getMatchGameTime() {
    return timeUtils.matchGameForamt(this.startDatetime);
  }

  @computed
  get getTeamData() {
    return ScoreUtil.getTeamScore(
      this,
      this.gameStatus,
      this.sportsType,
      this.period
    );
  }

  @computed
  get getBaseballPlayer() {
    const result = {
      home: { player: '', isAttack: false, isHaveData: false },
      away: { player: '', isAttack: false, isHaveData: false }
    };

    const { gameStatus, teams } = this;
    const { home, away } = teams;
    const typeData = this.getTypeData;

    if (gameStatus === 'READY') {
      if (!!home.startPitcher) {
        if (!!home.startPitcher.name) {
          result.home.player = home.startPitcher.name;
          result.home.isHaveData = true;
        }
      }
      if (!!away.startPitcher) {
        if (!!away.startPitcher.name) {
          result.away.player = away.startPitcher.name;
          result.away.isHaveData = true;
        }
      }
    } else if (gameStatus === 'IN_PROGRESS') {
      if (typeData.inningDivision === 'TOP') {
        if (!!typeData.currentBatter) {
          if (!!typeData.currentBatter.name) {
            result.away.player = typeData.currentBatter.name;
            result.away.isAttack = true;
            result.away.isHaveData = true;
          }
        }
        if (!!typeData.currentPitcher) {
          if (!!typeData.currentPitcher.name) {
            result.home.player = typeData.currentPitcher.name;
            result.home.isHaveData = true;
          }
        }
      } else if (typeData.inningDivision === 'BOTTOM') {
        if (!!typeData.currentBatter) {
          if (!!typeData.currentBatter.name) {
            result.home.player = typeData.currentBatter.name;
            result.home.isAttack = true;
            result.home.isHaveData = true;
          }
        }
        if (!!typeData.currentPitcher) {
          if (!!typeData.currentPitcher.name) {
            result.away.player = typeData.currentPitcher.name;
            result.away.isHaveData = true;
          }
        }
      }
    }

    return result;
  }

  @computed
  get getTypeData() {
    return {
      strike: this.strike,
      out: this.out,
      ball: this.ball,
      firstBaseOccupied: this.firstBaseOccupied,
      secondBaseOccupied: this.secondBaseOccupied,
      thirdBaseOccupied: this.thirdBaseOccupied,
      currentPitcher: this.currentPitcher,
      currentBatter: this.currentBatter,
      inningDivision: this.inningDivision
    };
  }

  @computed
  get getTracker() {
    const gameStatus = this.gameStatus;

    if (
      gameStatus === 'IN_PROGRESS' ||
      gameStatus === 'BREAK_TIME' ||
      gameStatus === 'PAUSE'
    ) {
      return { status: 'progress', class: '' };
    } else if (
      gameStatus === 'FINAL' ||
      gameStatus === 'CUT' ||
      gameStatus === 'POSTPONED' ||
      gameStatus === 'PENDING' ||
      gameStatus === 'CANCEL' ||
      gameStatus === 'DELAY' ||
      gameStatus === 'SUSPENDED'
    ) {
      return { status: 'final', class: 'close' };
    } else {
      // READY
      return { status: 'ready', class: 'wait' };
    }
  }

  @computed
  get availableBroadcast() {
    const { broadcast, teams } = this;
    let initBroadCast = {
      score: {},
      playText: '',
      locationType: null,
      eventType: '',
      displayTime: '00:00',
      teamName: ''
    };

    if (!!broadcast) {
      initBroadCast.score = !!broadcast.score
        ? broadcast.score
        : initBroadCast.score;
      initBroadCast.playText = !!broadcast.playText
        ? broadcast.playText
        : initBroadCast.playText;
      initBroadCast.locationType = !!broadcast.locationType
        ? broadcast.locationType
        : initBroadCast.locationType;
      initBroadCast.eventType = !!broadcast.eventType
        ? broadcast.eventType
        : initBroadCast.eventType;
      initBroadCast.displayTime = !!broadcast.displayTime
        ? broadcast.displayTime
        : initBroadCast.displayTime;
      if (!!initBroadCast.locationType) {
        if (initBroadCast.locationType === 'HOME') {
          initBroadCast.teamName = teams.home.name;
        } else if (initBroadCast.locationType === 'AWAY') {
          initBroadCast.teamName = teams.away.name;
        }
      }
    }

    return initBroadCast;
  }

  @computed
  get winnerTeam() {
    const { teams } = this;
    const homeScore = this.homeTotalScore;
    const awayScore = this.awayTotalScore;
    let team = 'draw';
    let teamData = null;

    if (homeScore > awayScore) {
      team = 'home';
      teamData = teams.home;
    } else if (homeScore < awayScore) {
      team = 'away';
      teamData = teams.away;
    } else if (homeScore === awayScore) {
      team = 'draw';
      teamData = {
        imgPath: '',
        name: ''
      };
    }

    return {
      activeScore: team,
      team: teamData,
      difference: Math.abs(homeScore - awayScore)
    };
  }

  @computed
  get homeTotalScore() {
    let total = 0;

    this.teams.home.periodData.forEach((data) => {
      total += data.score;
    });

    return total;
  }

  @computed
  get awayTotalScore() {
    let total = 0;

    this.teams.away.periodData.forEach((data) => {
      total += data.score;
    });

    return total;
  }

  @computed
  get oddsData() {
    const { odds } = this;
    let result = {
      domestic: {
        home: null,
        homeOption: null,
        away: null,
        awayOption: null
      },
      international: {
        home: null,
        homeOption: null,
        away: null,
        awayOption: null
      }
    };

    result.domestic = ScoreUtil.getScoreOdds({
      oddsArr: odds.domesticWinLoseOdds,
      handiArr: odds.domesticHandicapOdds,
      uoArr: odds.domesticUnderOverOdds
    });

    result.international = ScoreUtil.getScoreOdds({
      oddsArr: odds.internationalWinLoseOdds,
      handiArr: odds.internationalHandicapOdds,
      uoArr: odds.internationalUnderOverOdds
    });

    return result;
  }

  @computed
  get sortedHomeScores() {
    const { teams } = this;
    const { home } = teams;

    return home.periodData.sort((a, b) => a.period - b.period);
  }

  @computed
  get sortedAwayScores() {
    const { teams } = this;
    const { away } = teams;

    return away.periodData.sort((a, b) => a.period - b.period);
  }

  @computed
  get statusText() {
    const { gameStatus, inningDivision, period } = this;
    let statusText = '';

    if (gameStatus === 'CANCEL') {
      statusText = '취소';
    } else if (gameStatus === 'DELAY' || gameStatus === 'POSTPONED') {
      statusText = '연기';
    } else if (gameStatus === 'BREAK_TIME') {
      statusText = 'HT';
    } else if (gameStatus === 'PAUSE') {
      statusText = '중지';
    } else if (gameStatus === 'PENDING' || gameStatus === 'CUT') {
      statusText = '중단';
    } else if (gameStatus === 'READY') {
      statusText = '대기중';
    } else if (gameStatus === 'FINAL') {
      statusText = '종료';
    } else if (gameStatus === 'IN_PROGRESS') {
      // 야구
      if (inningDivision === 'TOP') {
        statusText = `${period}회초`;
      } else {
        statusText = `${period}회말`;
      }
    }

    return statusText;
  }

  @computed
  get betbleButtonVisible() {
    const { betbleUri, getTracker } = this;

    return !!betbleUri && getTracker.status !== 'final';
  }

  @computed
  get matchTrackerVisible() {
    const { betbleUri, getTracker } = this;

    return !!betbleUri && getTracker.status === 'progress';
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  updateGame(data) {
    for (const [key, value] of Object.entries(data)) {
      // console.log('변경시작 ! = ', key);
      if (key === 'teams') {
        this.teams = {
          ...this.teams,
          ...value,
          home: {
            ...this.teams.home,
            ...value.home
          },
          away: {
            ...this.teams.away,
            ...value.away
          }
        };
      } else {
        this[key] = value;
      }
      // console.log(`${key}: ${value}`);
    }
  }

  @action
  updatePeriodData(data, isFavoriteActivePrompt, isFavorite) {
    // console.log('페리오드 업데이트 = ', data);
    const { home, away } = this.getTeamData;
    const total = home.score + away.score;

    const next = {
      home: data.home.reduce((acc, cur) => acc + cur.score, 0),
      away: data.away.reduce((acc, cur) => acc + cur.score, 0)
    };
    const nextTotal = next.home + next.away;

    if (nextTotal > total) {
      let location = 'home';

      if (next.home > home.score) {
        location = 'home';
      } else if (next.away > away.score) {
        location = 'away';
      }

      this.setGoalEvent(location);
    }

    // if (nextTotal !== total) {
    //   const { league, statusText, id } = this;

    //   let location = 'home';

    //   if (next.home !== home.score) {
    //     location = 'home';
    //   } else if (next.away !== away.score) {
    //     location = 'away';
    //   }

    //   if (isFavoriteActivePrompt) {
    //     if (isFavorite) {
    //       this.addPrompt(
    //         id,
    //         { name: home.name, score: next.home },
    //         { name: away.name, score: next.away },
    //         location,
    //         league,
    //         statusText
    //       );
    //     }
    //   } else {
    //     this.addPrompt(
    //       id,
    //       { name: home.name, score: next.home },
    //       { name: away.name, score: next.away },
    //       location,
    //       league,
    //       statusText
    //     );
    //   }
    // }

    this.teams = {
      ...this.teams,
      home: {
        ...this.teams.home,
        periodData: data.home
      },
      away: {
        ...this.teams.away,
        periodData: data.away
      }
    };
  }

  @action
  setGoalEvent(location) {
    if (!this.goalEvent.isActive) {
      runInAction(() => {
        this.goalEvent.isActive = true;
        this.goalEvent.location = location;

        setTimeout(() => {
          runInAction(() => {
            this.goalEvent = {
              location: '',
              isActive: false
            };
          });
        }, 5000);
      });
    }
  }

  @action
  updateSpecial(data) {
    // console.log('스페셜 업데이트 = ', data);
    this.special = data;
  }

  @action
  updateLastBroadcast(data) {
    // console.log('브로드캐스트 업데이트 = ', data);
    this.broadcast = data;
  }

  @action
  updateBatStatus(data) {
    // console.log('배팅 스테이터스 업데이트 = ', data);
    this.strike = data.strike;
    this.out = data.out;
    this.ball = data.ball;
  }

  @action
  updateFieldStatus(data) {
    // console.log('필드 스테이터스 업데이트 = ', data);
    this.currentBatter = data.currentBatter;
    this.currentPitcher = data.currentPitcher;
    this.firstBaseOccupied = data.firstBaseOccupied;
    this.firstBaseOccupiedBatter = data.firstBaseOccupiedBatter;
    this.nextBatters = data.nextBatters;
    this.secondBaseOccupied = data.secondBaseOccupied;
    this.secondBaseOccupiedBatter = data.secondBaseOccupiedBatter;
    this.thirdBaseOccupied = data.thirdBaseOccupied;
    this.thirdBaseOccupiedBatter = data.thirdBaseOccupiedBatter;
    // this.firstBaseOccupiedBatter = data.firstBaseOccupiedBatter;
    // this.secondBaseOccupiedBatter = data.secondBaseOccupiedBatter;
    // this.thirdBaseOccupiedBatter = data.thirdBaseOccupiedBatter;

    // this.nextBatters = data.nextBatters;
  }

  @action
  updateHeb(data) {
    // console.log('heb 업데이트 = ', data);
    this.teams = {
      ...this.teams,
      home: {
        ...this.teams.home,
        hitCount: data.home.hitCount,
        errorCount: data.home.errorCount,
        baseOnBallCount: data.home.baseOnBallCount
      },
      away: {
        ...this.teams.away,
        hitCount: data.away.hitCount,
        errorCount: data.away.errorCount,
        baseOnBallCount: data.away.baseOnBallCount
      }
    };
  }
}

export default BaseballModel;
