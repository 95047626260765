import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const BarChart = ({ count = {} }) => {
  return (
    <>
      <div className="data_summary">
        <h3 className="tit">파워볼 통계</h3>

        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'UNDER',
              parseInt(count.powerball_under_count) >
                parseInt(count.powerball_over_count) && 'on'
            )}
          >
            <dt>언더</dt>
            <dd>
              <span className="num">{count.powerball_under_count}</span>
              <span className="per">({count.powerball_under_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'OVER',
              parseInt(count.powerball_under_count) <
                parseInt(count.powerball_over_count) && 'on'
            )}
          >
            <dt>오버</dt>
            <dd>
              <span className="num">{count.powerball_over_count}</span>
              <span className="per">({count.powerball_over_percent}%)</span>
            </dd>
          </dl>
        </div>
      </div>

      <div className="data_summary">
        <h3 className="tit">일반볼 통계</h3>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'ODD',
              parseInt(count.sum_odd_count) > parseInt(count.sum_even_count) &&
                'on'
            )}
          >
            <dt>홀</dt>
            <dd>
              <span className="num">{count.sum_odd_count}</span>
              <span className="per">({count.sum_odd_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              parseInt(count.sum_odd_count) < parseInt(count.sum_even_count) &&
                'on'
            )}
          >
            <dt>짝</dt>
            <dd>
              <span className="num">{count.sum_even_count}</span>
              <span className="per">({count.sum_even_percent}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'BIG',
              MathUtil.onlyMaxNumber([
                count.sum_large_count,
                count.sum_medium_count,
                count.sum_small_count
              ]) === parseInt(count.sum_large_count) && 'on'
            )}
          >
            <dt>대</dt>
            <dd>
              <span className="num">{count.sum_large_count}</span>
              <span className="per">({count.sum_large_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'MIDDLE',
              MathUtil.onlyMaxNumber([
                count.sum_large_count,
                count.sum_medium_count,
                count.sum_small_count
              ]) === parseInt(count.sum_medium_count) && 'on'
            )}
          >
            <dt>중</dt>
            <dd>
              <span className="num">{count.sum_medium_count}</span>
              <span className="per">({count.sum_medium_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'SMALL',
              MathUtil.onlyMaxNumber([
                count.sum_large_count,
                count.sum_medium_count,
                count.sum_small_count
              ]) === parseInt(count.sum_small_count) && 'on'
            )}
          >
            <dt>소</dt>
            <dd>
              <span className="num">{count.sum_small_count}</span>
              <span className="per">({count.sum_small_percent}%)</span>
            </dd>
          </dl>
        </div>
      </div>
    </>
  );
};

export default BarChart;
