import React, { useEffect, useState } from 'react'
import MainModal from './MainModal'


const ModalController = () => {

    const [modalVisible, setModalVisible] = useState(true)

    // const openModal = () => {
    //     setModalVisible(true)
    // }
    const closeModal = () => {
        setModalVisible(false)
    }

    return (
        <div>
            <>
                {modalVisible && (
                    <MainModal visible={modalVisible} closable={true} maskClosable={true} onClose={closeModal}></MainModal>
                )}
            </>
        </div>
    )
}

export default ModalController