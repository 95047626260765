import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 26px 20px 0;
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  button {
    flex: 1;
    font-size: 14px;
    color: #fff;
    height: 44px;
    background-color: #8e8e8e;
  }
  button[type='submit'] {
    background-color: #ed2026;
    margin-left: 5px;
  }
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  span {
    font-size: 12px;
    color: #999;
    line-height: 12px;
    margin-bottom: 10px;
  }
  input {
    border: 1px solid #e4e4e4;
    height: 44px;
    font-size: 14px;
    padding: 0 16px;
    ::placeholder {
      color: #999;
    }
  }
  &.price {
    input {
      color: #1287eb;
      padding-left: 45px;
      ::placeholder {
        color: #1287eb;
      }
    }
    :before {
      content: 'P';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #1287eb;
      color: #fff;
      font-weight: bold;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 13px;
      left: 16px;
    }
  }
  select {
    height: 44px;
    padding: 0 16px;
    border: 1px solid #e4e4e4;
    background: url('/images/select-arrow.svg') no-repeat 95% 50%;
    background-size: auto 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;
