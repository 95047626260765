import React from 'react';
import { useObserver } from 'mobx-react-lite';
import useCommonStore from '../useCommonStore';

const useCheckLogin = () => {
  const commonStore = useCommonStore();

  return {
    isCheckLogin: useObserver(() => commonStore.loginCheck)
  };
};

export default useCheckLogin;
