import React from 'react';
import PeriodBarChart from '../../../periodBarChart';

const powerballMinMaxList = [
  { name: '홀', resultKey: 'powerball_odd' },
  { name: '짝', resultKey: 'powerball_even' },
  { name: '언더', resultKey: 'powerball_under' },
  { name: '오버', resultKey: 'powerball_over' },
  { name: '구간 A', resultKey: 'powerball_a_section' },
  { name: '구간 B', resultKey: 'powerball_b_section' },
  { name: '구간 C', resultKey: 'powerball_c_section' },
  { name: '구간 D', resultKey: 'powerball_d_section' },
  { name: '홀언', resultKey: 'powerball_odd_under' },
  { name: '홀오', resultKey: 'powerball_odd_over' },
  { name: '짝언', resultKey: 'powerball_even_under' },
  { name: '짝오', resultKey: 'powerball_even_over' }
];

const sumMinMaxList = [
  { name: '홀', resultKey: 'sum_odd' },
  { name: '짝', resultKey: 'sum_even' },
  { name: '언더', resultKey: 'sum_under' },
  { name: '오버', resultKey: 'sum_over' },
  { name: '대', resultKey: 'sum_large' },
  { name: '중', resultKey: 'sum_medium' },
  { name: '소', resultKey: 'sum_small' },
  { name: '홀언', resultKey: 'sum_odd_under' },
  { name: '홀오', resultKey: 'sum_odd_over' },
  { name: '짝언', resultKey: 'sum_even_under' },
  { name: '짝오', resultKey: 'sum_even_over' },
  { name: '홀대', resultKey: 'sum_odd_large' },
  { name: '홀중', resultKey: 'sum_odd_medium' },
  { name: '홀소', resultKey: 'sum_odd_small' },
  { name: '짝대', resultKey: 'sum_even_large' },
  { name: '짝중', resultKey: 'sum_even_medium' },
  { name: '짝소', resultKey: 'sum_even_small' }
];

const MinMaxWithinPeriod = ({ data }) => {
  if (Object.keys(data).length <= 3) {
    return null;
  }

  return (
    <div className="data_minmax_wrap">
      <h3 className="tit">기간내 최소/최다 출현 통계</h3>
      <div className="minmax_list">
        <div className="reference">
          <span className="tx_min">최소출현</span>
          <span className="tx_max">최대출현</span>
        </div>
        <div className="tit_sub">파워볼</div>
        <PeriodBarChart data={data} mapList={powerballMinMaxList} />
        <div className="tit_sub">일반볼 합</div>
        <PeriodBarChart data={data} mapList={sumMinMaxList} />
      </div>
    </div>
  );
};

export default MinMaxWithinPeriod;
