import {
  extendObservable,
  computed,
  action,
  observable,
  runInAction
} from 'mobx';
import autobind from 'autobind-decorator';
import AbstractScoreAudioPrompt from '../../../store/audio';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import { ScoreUtil } from '../../../../../common/utils/scoreUtil';

@autobind
class HockeyModel extends AbstractScoreAudioPrompt {
  @observable
  goalEvent = {
    location: '',
    isActive: false
  };

  constructor(data) {
    super();
    extendObservable(this, data);
  }

  @computed
  get periodArr() {
    let periods = [
      { key: '1p', value: 1 },
      { key: '2p', value: 2 },
      { key: '3p', value: 3 },
      { key: '연장', value: 4 },
      { key: '슛아웃', value: 5 }
    ];

    return periods;
  }

  @computed
  get getStartDateTime() {
    return timeUtils.timeFormatHourMinute(this.startDatetime);
  }

  @computed
  get getMatchGameTime() {
    return timeUtils.matchGameForamt(this.startDatetime);
  }

  @computed
  get getTeamData() {
    const { period } = this;

    let teams = ScoreUtil.getTeamScore(
      this,
      this.gameStatus,
      this.sportsType,
      this.period
    );

    if (period === 5) {
      const homeSoScore = getScoreSum(this.teams.home);
      const awaySoScore = getScoreSum(this.teams.away);

      if (homeSoScore > awaySoScore) {
        teams.home.score = teams.home.score + 1;
      } else if (awaySoScore > homeSoScore) {
        teams.away.score = teams.away.score + 1;
      }
    }

    function getScoreSum(team) {
      if (!!team.periodData) {
        if (team.periodData.length > 0) {
          return team.periodData
            .filter((item) => item.period === 5)
            .reduce((a, b) => {
              return a + b.score;
            }, 0);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }

    return teams;
  }

  @computed
  get getTypeData() {
    return {};
  }

  @computed
  get getTracker() {
    const gameStatus = this.gameStatus;

    if (
      gameStatus === 'IN_PROGRESS' ||
      gameStatus === 'BREAK_TIME' ||
      gameStatus === 'PAUSE'
    ) {
      return { status: 'progress', class: '' };
    } else if (
      gameStatus === 'FINAL' ||
      gameStatus === 'CUT' ||
      gameStatus === 'POSTPONED' ||
      gameStatus === 'PENDING' ||
      gameStatus === 'CANCEL' ||
      gameStatus === 'DELAY' ||
      gameStatus === 'SUSPENDED'
    ) {
      return { status: 'final', class: 'close' };
    } else {
      // READY
      return { status: 'ready', class: 'wait' };
    }
  }

  @computed
  get availableBroadcast() {
    const { broadcast, teams } = this;
    let initBroadCast = {
      score: {},
      playText: '',
      locationType: null,
      eventType: '',
      displayTime: '00:00',
      teamName: ''
    };

    if (!!broadcast) {
      initBroadCast.score = !!broadcast.score
        ? broadcast.score
        : initBroadCast.score;
      initBroadCast.playText = !!broadcast.playText
        ? broadcast.playText
        : initBroadCast.playText;
      initBroadCast.locationType = !!broadcast.locationType
        ? broadcast.locationType
        : initBroadCast.locationType;
      initBroadCast.eventType = !!broadcast.eventType
        ? broadcast.eventType
        : initBroadCast.eventType;
      initBroadCast.displayTime = !!broadcast.displayTime
        ? broadcast.displayTime
        : initBroadCast.displayTime;
      if (!!initBroadCast.locationType) {
        if (initBroadCast.locationType === 'HOME') {
          initBroadCast.teamName = teams.home.name;
        } else if (initBroadCast.locationType === 'AWAY') {
          initBroadCast.teamName = teams.away.name;
        }
      }
    }

    return initBroadCast;
  }

  @computed
  get winnerTeam() {
    const { teams, period, getTeamData } = this;
    const homeScore = getTeamData.home.score;
    const awayScore = getTeamData.away.score;
    let team = 'draw';
    let teamData = null;

    if (homeScore > awayScore) {
      team = 'home';
      teamData = teams.home;
    } else if (homeScore < awayScore) {
      team = 'away';
      teamData = teams.away;
    } else if (homeScore === awayScore) {
      team = 'draw';
      teamData = {
        imgPath: '',
        name: ''
      };
    }

    return {
      activeScore: team,
      team: teamData,
      difference: Math.abs(homeScore - awayScore),
      isShootout: period === 5
    };
  }

  @computed
  get homeTotalScore() {
    let total = 0;

    this.teams.home.periodData.forEach((data) => {
      total += data.score;
    });

    return total;
  }

  @computed
  get awayTotalScore() {
    let total = 0;

    this.teams.away.periodData.forEach((data) => {
      total += data.score;
    });

    return total;
  }

  @computed
  get summaryScore() {
    const { homeTotalScore, awayTotalScore } = this;

    return homeTotalScore + awayTotalScore;
  }

  @computed
  get oddsData() {
    const { odds } = this;
    let result = {
      domestic: {
        home: null,
        homeOption: null,
        away: null,
        awayOption: null
      },
      international: {
        home: null,
        homeOption: null,
        away: null,
        awayOption: null
      }
    };

    result.domestic = ScoreUtil.getScoreOdds({
      oddsArr: odds.domesticWinLoseOdds,
      handiArr: odds.domesticHandicapOdds,
      uoArr: odds.domesticUnderOverOdds
    });

    result.international = ScoreUtil.getScoreOdds({
      oddsArr: odds.internationalWinLoseOdds,
      handiArr: odds.internationalHandicapOdds,
      uoArr: odds.internationalUnderOverOdds
    });

    return result;
  }

  @computed
  get sortedHomeScores() {
    const { teams } = this;
    const { home } = teams;

    return home.periodData.sort((a, b) => a.period - b.period);
  }

  @computed
  get sortedAwayScores() {
    const { teams } = this;
    const { away } = teams;

    return away.periodData.sort((a, b) => a.period - b.period);
  }

  @computed
  get statusText() {
    const { gameStatus, period } = this;
    let statusText = '';

    if (gameStatus === 'CANCEL') {
      statusText = '취소';
    } else if (gameStatus === 'DELAY' || gameStatus === 'POSTPONED') {
      statusText = '연기';
    } else if (gameStatus === 'BREAK_TIME') {
      statusText = 'BT';
    } else if (gameStatus === 'PAUSE') {
      statusText = '중지';
    } else if (gameStatus === 'PENDING' || gameStatus === 'CUT') {
      statusText = '중단';
    } else if (gameStatus === 'READY') {
      statusText = '대기중';
    } else if (gameStatus === 'FINAL') {
      statusText = '종료';
    } else if (gameStatus === 'IN_PROGRESS') {
      if (period === 4) {
        statusText = '연장';
      } else if (period === 5) {
        statusText = '슛아웃';
      } else {
        statusText = `${period}P`;
      }
    }

    return statusText;
  }

  @computed
  get betbleButtonVisible() {
    const { betbleUri, getTracker } = this;

    return !!betbleUri && getTracker.status !== 'final';
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  updateGame(data) {
    for (const [key, value] of Object.entries(data)) {
      if (key === 'teams') {
        this.teams = {
          ...this.teams,
          ...value,
          home: {
            ...this.teams.home,
            ...value.home
          },
          away: {
            ...this.teams.away,
            ...value.away
          }
        };
      } else {
        this[key] = value;
      }
      // console.log(`${key}: ${value}`);
    }
  }

  @action
  updatePeriodData(data, isFavoriteActivePrompt, isFavorite) {
    // console.log('페리오드 업데이트 = ', data);
    const { home, away } = this.getTeamData;
    const total = home.score + away.score;

    const next = {
      home: data.home.reduce((acc, cur) => acc + cur.score, 0),
      away: data.away.reduce((acc, cur) => acc + cur.score, 0)
    };
    const nextTotal = next.home + next.away;

    if (nextTotal > total) {
      let location = 'home';

      if (next.home > home.score) {
        location = 'home';
      } else if (next.away > away.score) {
        location = 'away';
      }

      this.setGoalEvent(location);
    }

    if (nextTotal !== +total) {
      const { league, statusText, id } = this;

      let location = 'home';

      if (next.home !== home.score) {
        location = 'home';
      } else if (next.away !== away.score) {
        location = 'away';
      }

      // if (isFavoriteActivePrompt) {
      //   if (isFavorite) {
      //     this.addPrompt(
      //       id,
      //       { name: home.name, score: next.home },
      //       { name: away.name, score: next.away },
      //       location,
      //       league,
      //       statusText
      //     );
      //   }
      // } else {
      //   this.addPrompt(
      //     id,
      //     { name: home.name, score: next.home },
      //     { name: away.name, score: next.away },
      //     location,
      //     league,
      //     statusText
      //   );
      // }
    }

    this.teams = {
      ...this.teams,
      home: {
        ...this.teams.home,
        periodData: data.home
      },
      away: {
        ...this.teams.away,
        periodData: data.away
      }
    };
  }

  @action
  setGoalEvent(location) {
    if (!this.goalEvent.isActive) {
      runInAction(() => {
        this.goalEvent.isActive = true;
        this.goalEvent.location = location;

        setTimeout(() => {
          runInAction(() => {
            this.goalEvent = {
              location: '',
              isActive: false
            };
          });
        }, 5000);
      });
    }
  }

  @action
  updateSpecial(data) {
    this.special = data;
  }

  @action
  updateLastBroadcast(data) {
    this.broadcast = data;
  }
}

export default HockeyModel;
