import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { List, Summary, ImageStyle } from "./styled"
import { ImageUtil } from "../../../../common/utils/imageUtil"
import { RegexUtil } from "../../../../common/utils/regexUtil"
import ItemModal from "./itemModal"

const MypageGifticonList = observer(({ items = [], history }) => {
	const [isOpen, setOpen] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})

	const onClickItem = (item) => {
		setOpen(true)
		setSelectedItem(item)
	}

	return (
		<>
			<List>
				{items.map((item, key) => {
					return <Item key={key} item={item} onClickItem={onClickItem} />
				})}
			</List>
			<ItemModal
				isOpen={isOpen}
				onClose={() => setOpen(false)}
				item={selectedItem}
			/>
		</>
	)
})

const Item = ({ item, onClickItem }) => {
	const {
		product_image,
		brand_name,
		product_name,
		price,
		is_available
	} = item

	const disabled = is_available !== "1"

	return (
		<li
			disabled
			onClick={() => !disabled && onClickItem(item)}
			style={{ position: "relative", cursor: disabled ? "not-allowed" : "pointer", opacity: disabled ? 0.5 : 1 }}
		>
			<ImageStyle src={ImageUtil.imageUrl(product_image, 0, 50)} />
			<Summary>
				<div>
					<span className={"name"}>{brand_name}</span>
				</div>
				<div>
					<span className={"product_name"}>{product_name}</span>
				</div>
				<div className={"price-box"}>
					<span className={"medal price"}>{`${RegexUtil.makeComma(
						parseInt(price, 10)
					)} 건빵`}</span>
				</div>
			</Summary>
		</li>
	)
}

export { MypageGifticonList }