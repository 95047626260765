import React, { useEffect, useMemo, useRef, useState } from "react";
import Seo from '../../../components/seo';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { NavMenu } from '../components/navMenu';
import { RoomChatList } from '../components/roomChat';
import HoldemResult from '../components/holdem/result';
import HoldemGuide from '../components/holdem/guide';
import BannerGA from "../../../components/banner/BannerGA";
import {Url} from "../../../common/constants/environment";

const Holdem = ({ history }) => {
  const gameViewRef = useRef(null);
  const gameViewIframeRef = useRef(null);

  const query = new URLSearchParams(history.location.search);
  const tab = query.get('tab') || 'result';
  const [isSmallView, toggleSmallView] = useState(false);

  useEffect(() => {
    toggleSmallView(false);
  }, [tab]);

  const component = useMemo(() => {
    switch (tab) {
      case 'room_chat':
        return <RoomChatList roomType={'lottery'} />;
      case 'guide':
        return <HoldemGuide />;
      default:
        return <HoldemResult />;
    }
  }, [tab, isSmallView]);

  useEffect(() => {
    const width = document.getElementsByTagName('body')[0].offsetWidth;
    const frameHeight = parseInt((width * 553) / 900);
    const frameScale = (width / 900).toFixed(3);

    gameViewRef.current.style.height = `${frameHeight}px`;
    gameViewIframeRef.current.style.transform = `scale(${frameScale})`;
  }, []);

  return (
    <>
      <Seo title={'ODDS홀덤'} />
      <div className={clsx('minigame_wrap', 'holdem')}>
        <div ref={gameViewRef} className={clsx('game_view', isSmallView && 'small')}>
          <iframe
            ref={gameViewIframeRef}
            scrolling="no"
            frameBorder="0"
            src={`${window.location.protocol}//${Url.hostNamedWeb}/minigame/holdem/mobile`}
          />
        </div>
        <NavMenu />
        {component}
      </div>

        {/* NEW ADS #1 구글 검열로 인해 비활성화*/}
        {/*<BannerGA*/}
        {/*    containerStyle={{*/}
        {/*      display: 'block',*/}
        {/*      width: 450,*/}
        {/*      height: 50,*/}
        {/*      marginTop: 16,*/}
        {/*    }}*/}
        {/*    gaSlot={'5003058707'}*/}
        {/*    width={450}*/}
        {/*    height={50}*/}
        {/*/>*/}


    </>
  );
};

export default Holdem;
