import React from 'react';
import { Switch, Route } from 'react-router';
import FooterHtml from './footer';
import AcountHtml from './acount';

import MypageDetailHtml from './mypage_detail';
import MypageInfoHtml from './mypage_info';
import MypageExpHtml from './mypage_exp';
import MypagePointHtml from './mypage_point';
import MypageGpHtml from './mypage_gp';
import MypageTpHtml from './mypage_tp';
import MypageCashHtml from './mypage_cash';
import MypageCashUseHtml from './mypage_cash_use';
import MypageCashUseReceiptHtml from './mypage_cash_receipt';
import MypageLoginLogHtml from './mypage_login_log';
import MypageLeaveHtml from './mypage_leave';

import BoardListHtml from './board_list';
import BoardPhotoHtml from './board_photo';
import BoardViewHtml from './board_view';
import BoardWriteHtml from './board_write';
import BoardReportHtml from './board_report';
import ScoreListHtml from './score_list';
import ScoreDetailSoccerHtml from './score_detail_soccer';
import ScoreDetailBaseballHtml from './score_detail_baseball';
import ScoreDetailBasketballHtml from './score_detail_basketball';
import ScoreDetailVolleyballHtml from './score_detail_volleyball';
import ScoreDetailLolHtml from './score_detail_lol';
import ScoreDetailStarcraftHtml from './score_detail_starcraft';
import ScoreDetailOddsHtml from './score_detail_odds';

import ScoreCastSoccerHtml from './score_cast_soccer';
import ScoreCastBaseballHtml from './score_cast_baseball';
import ScoreCastBasketballHtml from './score_cast_basketball';
import ScoreCastVolleyballHtml from './score_cast_volleyball';
import ScoreCastIcehockeyHtml from './score_cast_icehockey';
import ScoreCastFootballHtml from './score_cast_football';
import ScoreCastStarcraftHtml from './score_cast_starcraft';
import ScoreCastLolHtml from './score_cast_lol';

import ScoreCheerHtml from './score_cheer';

import ProtoHtml from './proto';
import ProtoCalculatorHtml from './proto_calculator';
import ProtoHistoryHtml from './proto_history';
import ProtoHistoryDetailHtml from './proto_history_detail';
import ProtoPaperHtml from './proto_paper';

import GameHtml from './game';
import GameStatsHtml from './game_stats';
import GamePickHtml from './game_pick';
import GameRoomChatHtml from './game_roomchat';



import ModalTest from './modal_test';
import SubNav from '../../components/subNav';


const Design = ({ sportsType }) => {
  return (
      <div id="content">
        <SubNav type="score" />
        <div className="__wrap">
          <Switch>

            {/*미니게임 분석*/}
            <Route path="/_html/game" component={GameHtml} />
            <Route path="/_html/game_stats" component={GameStatsHtml} />
            <Route path="/_html/game_pick" component={GamePickHtml} />
            <Route path="/_html/game_roomchat" component={GameRoomChatHtml} />

            {/**********************************************************/}
            {/**********************************************************/}

            {/*풋더*/}
            <Route path="/_html/footer" component={FooterHtml} />

            {/**********************************************************/}
            {/**********************************************************/}

            {/*계정 관련*/}
            <Route path="/_html/account" component={AcountHtml} />

            {/*마이페이지 - 내정보*/}
            <Route path="/_html/mypage_info" component={MypageInfoHtml} />

            {/*마이페이지 - 상세 - 내정보*/}
            <Route path="/_html/mypage_detail" component={MypageDetailHtml} />

            {/*마이페이지 - 경험치,포인트,GP,TP,캐시 내역*/}
            <Route path="/_html/mypage_exp" component={MypageExpHtml} />
            <Route path="/_html/mypage_point" component={MypagePointHtml} />
            <Route path="/_html/mypage_gp" component={MypageGpHtml} />
            <Route path="/_html/mypage_tp" component={MypageTpHtml} />
            <Route path="/_html/mypage_cash" component={MypageCashHtml} />
            <Route path="/_html/mypage_cash_use" component={MypageCashUseHtml} />
            <Route path="/_html/mypage_cash_receipt" component={MypageCashUseReceiptHtml} />
            <Route path="/_html/mypage_login_log" component={MypageLoginLogHtml} />
            <Route path="/_html/mypage_leave" component={MypageLeaveHtml} />

            {/**********************************************************/}
            {/**********************************************************/}



            {/*리스트 - 게시판*/}
            <Route path="/_html/board_list" component={BoardListHtml} />

            {/*포토 - 게시판*/}
            <Route path="/_html/board_photo" component={BoardPhotoHtml} />

            {/*뷰 - 게시판*/}
            <Route path="/_html/board_view" component={BoardViewHtml} />

            {/*글쓰기 - 게시판*/}
            <Route path="/_html/board_write" component={BoardWriteHtml} />

            {/*신고 - 게시판*/}
            <Route path="/_html/board_report" component={BoardReportHtml} />


            {/**********************************************************/}
            {/**********************************************************/}


            {/*리스트 - 스코어*/}
            <Route path="/_html/score_list" component={ScoreListHtml} />

            {/*상세 - 스코어*/}
            <Route path="/_html/score_detail_soccer" component={ScoreDetailSoccerHtml} />
            <Route path="/_html/score_detail_baseball" component={ScoreDetailBaseballHtml} />
            <Route path="/_html/score_detail_basketball" component={ScoreDetailBasketballHtml} />
            <Route path="/_html/score_detail_volleyball" component={ScoreDetailVolleyballHtml} />
            <Route path="/_html/score_detail_lol" component={ScoreDetailLolHtml} />
            <Route path="/_html/score_detail_starcraft" component={ScoreDetailStarcraftHtml} />

            {/*배당 공통 - 스코어*/}
            <Route path="/_html/score_detail_odds" component={ScoreDetailOddsHtml} />

            {/*중계 - 스코어*/}
            <Route path="/_html/score_cast_soccer" component={ScoreCastSoccerHtml} />
            <Route path="/_html/score_cast_baseball" component={ScoreCastBaseballHtml} />
            <Route path="/_html/score_cast_basketball" component={ScoreCastBasketballHtml} />
            <Route path="/_html/score_cast_volleyball" component={ScoreCastVolleyballHtml} />
            <Route path="/_html/score_cast_icehockey" component={ScoreCastIcehockeyHtml} />
            <Route path="/_html/score_cast_football" component={ScoreCastFootballHtml} />
            <Route path="/_html/score_cast_starcraft" component={ScoreCastStarcraftHtml} />
            <Route path="/_html/score_cast_lol" component={ScoreCastLolHtml} />

            {/*응원 - 스코어*/}
            <Route path="/_html/score_cheer" component={ScoreCheerHtml} />


            {/*리스트 - 프로토*/}
            <Route path="/_html/proto" component={ProtoHtml} />
            {/*계산기 - 프로토*/}
            <Route path="/_html/proto_calculator" component={ProtoCalculatorHtml} />
            {/*저장내역 - 프로토*/}
            <Route path="/_html/proto_history" component={ProtoHistoryHtml} />
            {/*저장내역 상세 - 프로토*/}
            <Route path="/_html/proto_history_detail" component={ProtoHistoryDetailHtml} />
            {/*용지 - 프로토*/}
            <Route path="/_html/proto_paper" component={ProtoPaperHtml} />

            {/* 모달 테스트 */}
            <Route path="/_html/modal_test" component={ModalTest} />

          </Switch>
        </div>
      </div>
  );
};

export default Design;
