import React from 'react';
import clsx from 'clsx';

const valueNameMap = {
  ODD: '홀',
  EVEN: '짝',
  LEFT: '좌',
  RIGHT: '우',
  THREE: '3',
  FOUR: '4',
  L3E: '좌3',
  L4O: '좌4',
  R3O: '우3',
  R4E: '우4',
  UNDER: '언더',
  OVER: '오버',
  EVENUNDER: '짝언',
  EVENOVER: '짝오',
  ODDUNDER: '홀언',
  ODDOVER: '홀오',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  LARGE: '대',
  MEDIUM: '중',
  SMALL: '소',
  EVENLARGE: '짝대',
  EVENMEDIUM: '짝중',
  EVENSMALL: '짝소',
  ODDLARGE: '홀대',
  ODDMEDIUM: '홀중',
  ODDSMALL: '홀소',
  snail1: '1번',
  snail2: '2번',
  snail3: '3번',
  snail4: '4번',
  HOME: '좌',
  DRAW: '무',
  AWAY: '우',
  UNDERSPACE8: '언',
  OVERSPACE8: '오'
};

const CountPattern = ({ title = '', searchPattern, searchPatternRound }) => {
  if (!searchPattern) {
    return null;
  }

  const searchPatternArray = searchPattern.split(',');
  const searchPatternRoundArray = searchPatternRound.split(',');

  return (
    <div className="data_pattern_search_wrap">
      <h3 className="tit">{title}</h3>
      <div className="pattern_search">
        {searchPatternArray.map((item, i) => {
          return (
            <dl className="item" key={i}>
              <dt className={clsx('ic', item.toUpperCase())}>
                {valueNameMap[item.trim()]}
              </dt>
              <dd className="times">
                {searchPatternRoundArray[i].split('|')[1]}
              </dd>
            </dl>
          );
        })}
      </div>
    </div>
  );
};

export default CountPattern;
