import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { observer } from "mobx-react";
import useStore from "../../hooks/useStore";
import { Url } from "../../common/constants/environment";

// 모달창 생성 블로그 원문
//https://sycdev.tistory.com/19#--%--%ED%-C%-D%EC%--%--%EC%B-%BD%--%EB%A-%-C%EB%--%A-%EA%B-%B-

const ModalWrapper = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
`

const ModalOverlay = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
`

const ModalInner = styled.div`
    box-sizing: border-box;
    position: relative;
    // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
    // background-color: #fff;
    // border-radius: 10px;
    width: 360px;
    max-width: 480px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 40px 20px;
`

const ModalInner2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ImgStyle = styled.div`
  position: relative;
  width: 300px;
  height: 390px;
  overflow: hidden;
`;

const ImageSlide = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => props.translateX}%);
  z-index: 0;
`;

const CloseStyle = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #282828;
    width: 270px;
    padding: 15px;
    border-radius: 0 0 15px 15px;
    color: #ffffff;
`

const Close = styled.span`
    cursor: pointer;
`
const Button = styled.div`
    cursor: pointer;
    color : #282828;
    display: none;
`
const ImgButtons = styled.div`
    position: absolute;
    display: flex;
    z-index: 1;
    padding: 10px;
    justify-content: space-between;
    width: 280px;
`

function tdy() {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    return "" + year + month + day;
}

const MainModal = observer(({ className, onClose, maskClosable, closable, visible }) => {
    const { bannerStore } = useStore();
    const [popups, setPopups] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    let host = window.location.hostname;

    useEffect(() => {
        bannerStore.getListPopups();
        if (bannerStore.isBannerLoaded) {
            if (host === `m.${Url.hostNamedWeb}`) {
                setPopups(bannerStore.mobilePopup?.filter(item => item.display === "Y") || []);
            } else if (host === `m-stage.${Url.hostNamedWeb}`) {
                setPopups(bannerStore.mobilePopup?.filter(item => item.display_stage === "Y") || []);
            } else {
                setPopups(bannerStore.mobilePopup || []);
            }
            setCurrentIndex(0);
        }
    }, [bannerStore, bannerStore.isBannerLoaded]);

    //자동슬라이드 기능
    useEffect(() => {
        let interval;
        if (visible) {
            interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex === popups.length - 1 ? 0 : prevIndex + 1));
            }, 2500);
        }
        return () => clearInterval(interval);
    }, [visible, popups.length]);

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e)
        }
    }

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? popups.length - 1 : prevIndex - 1));
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === popups.length - 1 ? 0 : prevIndex + 1));
    };

    // 이전방문 날짜
    const VISITED_BEFORE_DATE = localStorage.getItem('VisitCookie')
    // 현재 날짜
    const VISITED_NOW_DATE = tdy();


    useEffect(() => {
        // 팝업 오늘 하루닫기 체크
        if (VISITED_BEFORE_DATE !== null) {
            // 날짜가 같을경우 비노출
            if (VISITED_BEFORE_DATE === VISITED_NOW_DATE) {
                onClose(false)
            }
            // 날짜가 다를경우 노출
            else {
                localStorage.removeItem('VisitCookie')
                onClose(true)
            }
        }
    }, [])

    // 하루동안 팝업 닫기
    const Dayclose = (e) => {
        if (onClose) {
            onClose(e)
            localStorage.setItem('VisitCookie', tdy())
        }
    }

    const close = (e) => {
        if (onClose) {
            onClose(e)
        }
    }

    return (
        <>
            {popups.length > 0 && (
                <div>
                    <ModalOverlay visible={visible} />
                    <ModalWrapper
                        className={className}
                        onClick={maskClosable ? onMaskClick : null}
                        tabIndex="-1"
                        visible={visible}
                    >
                        <ModalInner tabIndex="0" className="modal-inner">
                            <ModalInner2>
                                <ImgStyle>
                                    <ImgButtons>
                                        <Button onClick={prevSlide}>Prev</Button>
                                        <Button onClick={nextSlide}>Next</Button>
                                    </ImgButtons>
                                    {popups.map((popup, index) => (
                                        <a href={popup.href}>
                                            <ImageSlide
                                                key={index}
                                                src={popup.imgPath}
                                                translateX={index === currentIndex ? 0 : index < currentIndex ? -100 : 100}
                                            />
                                        </a>
                                    ))}
                                </ImgStyle>
                                {closable && (
                                    <CloseStyle>
                                        <Close className="modal-close" onClick={Dayclose}>
                                            오늘 하루 닫기
                                        </Close>
                                        <Close className="modal-close" onClick={close}>
                                            닫기
                                        </Close>
                                    </CloseStyle>
                                )}
                            </ModalInner2>
                        </ModalInner>
                    </ModalWrapper>
                </div>
            )}
        </>
    );
});

MainModal.propTypes = {
    visible: PropTypes.bool,
}

export default React.memo(MainModal)