import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MakeDomImage } from 'react-make-dom-image';
import ProtoMyPickStore from '../store';
import ProtoMyPickPaperMark from './mark/protoMyPickPaperMark';
import clsx from 'clsx';

const ProtoMyPickPaper = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data, getOddsCalculatorById } = ProtoMyPickStore();
  const domRef = useRef();

  useEffect(() => {
    getOddsCalculatorById(id);
  }, []);

  return (
    <div className="layer_proto_paper">
      <div className="option">
        <button
          type="button"
          className="btn_close"
          onClick={() => history.goBack()}
        >
          닫기
        </button>
        <button type="button" className="btn_share">
          공유
        </button>
        <MakeDomImage
          domRef={domRef}
          exportName={`프로토 ${data.detail.displayName}`}
          trigger={
            <button type="button" className="btn_download">
              다운받기
            </button>
          }
        />
      </div>
      <div className="paper" ref={domRef}>
        {data.detail.polls.length > 0 &&
          data.detail.polls.map((item) => {
            return <ProtoMyPickPaperMark item={item} key={item.id} />;
          })}

        <div className="game_count">
          <span className={clsx(`n${data.detail.polls.length}`)}>
            {data.detail.polls.length}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProtoMyPickPaper;
