import styled from 'styled-components';

export const List = styled.ul`
  height: 100%;
  width: 100%;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 11px 20px;
`;

export const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-left: 20px;
  text-indent: -9999px;
  &:after {
    position: absolute;
    content: '';
    right: 9px;
    top: 10px;
    width: 11px;
    height: 7px;
    background: url(/images/sp_layout.png) no-repeat -225px -190px;
    background-size: 350px 350px;
  }
  &.open {
    &:after {
      background-position: -225px -200px;
    }
  }
`;

export const DetailContents = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100vw;
  overflow-x: scroll;
  padding: 25px 20px;
  background-color: #fafafa;
  border-bottom: 1px solid #e5e5e5;
  & > div {
    width: 100%;
  }
  & img {
    width: 100%;
  }
`;
