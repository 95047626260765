import { observer } from 'mobx-react';
import { useLocalStore } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CommunityService } from '../../../common/services';
import Pagination from '../../../components/pagination';
import useModalStore from '../../../hooks/useModalStore';
import CommunityModel from '../../community/store/model';
import { List, ListItem, Button, DetailContents } from './styled';
import { Url } from '../../../common/constants/environment';
import Seo from '../../../components/seo';

const CustomerCenterFaq = observer(() => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;
  const modalStore = useModalStore();
  const store = useLocalStore(() => ({
    list: [],
    loading: false,
    totalCount: 0,
    async getNotice(page) {
      store.loading = true;

      const res = await CommunityService.list(
        'faq_m',
        page,
        '',
        '',
        '',
        'mobile'
      );

      if (res.code !== 200) {
        modalStore.openAlertModal({
          message: res.message
        });
      } else {
        store.code = res.code;
        store.totalCount = res.data.total_cnt;
        store.list = res.data.list.map((item) => new CommunityModel(item));
        window.scrollTo(0, 0);
      }
    }
  }));

  useEffect(() => {
    store.getNotice(page);
  }, [page]);

  return (
    <>
      <Seo title={'고객센터 도움말'} />
      <div className="board_wrap">
        <div className="board_list">
          <List>
            {!store.isLoading &&
              store.list.length > 0 &&
              store.list.map((item) => <Row item={item} key={item.wr_id} />)}
          </List>
          <Pagination page={page} totalCount={store.totalCount} />
        </div>
      </div>
    </>
  );
});

const Row = ({ item }) => {
  const { wr_subject, wr_content } = item;
  const [isOpenDetail, toggleOpenDetail] = useState(false);

  const thumbImgPathReplace = () => {
    if (!!wr_content) {
      return wr_content.replace('http://data.named.com/data', Url.dataThumbUrl);
    } else {
      return '';
    }
  };

  const onClickListItem = () => {
    toggleOpenDetail(!isOpenDetail);
  };

  return (
    <>
      <ListItem onClick={onClickListItem}>
        <p className="subject">{wr_subject}</p>
        <Button type="button" className={isOpenDetail ? 'open' : 'close'}>
          열기/닫기
        </Button>
      </ListItem>
      {isOpenDetail && (
        <DetailContents>
          <div
            dangerouslySetInnerHTML={{
              __html: thumbImgPathReplace()
            }}
          />
        </DetailContents>
      )}
    </>
  );
};

export default CustomerCenterFaq;
