import React from 'react';
import { Wrap, MajorSportTitle } from './styled';
import { observer } from 'mobx-react-lite';
import { HomeHeader } from './home_header';

import PrerenderedLoadable from '../../components/prerenderLoadable';

const MajorComponent = PrerenderedLoadable(() => import('../score/major'));
const SportAnalysisComponent = PrerenderedLoadable(() => import('./sport_analysis'));
const CommunityBoardComponent = PrerenderedLoadable(() => import('./community_board'));

const Home = observer(() => {
  return (
    <Wrap>
      <HomeHeader />
      <CommunityBoardComponent />
      <SportAnalysisComponent />
      <MajorSportTitle>오늘의 주요경기</MajorSportTitle>
      <MajorComponent fromHome />
    </Wrap>
  );
});

export default Home;
