import React, { useState } from 'react';
import 'lazysizes';
import clsx from 'clsx';
import homeDefault from 'images/sports/common/logo_home.png';
import awayDefault from 'images/sports/common/logo_away.png';
import { Url } from '../../common/constants/environment';

const SportsTeamLogo = ({
  imgSize,
  imgUrl,
  isLazy = true,
  className,
  location
}) => {
  const [isLoad, setIsLoad] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [fadeImage, setFadeImage] = useState(false);

  let imgSrc;
  if (imgUrl && imgUrl.startsWith("/"))
    imgSrc = `${Url.thumb}normal/resize/0x${imgSize}${imgUrl}`;
  else if (imgUrl && !imgUrl.startsWith("/"))
    imgSrc = `https://assets.b365api.com/images/team/m/${imgUrl}.png`;

  const onErrorImage = async (e) => {
    await setIsError(true);
  };

  const onLoadedImage = async (e) => {
    // await setFadeImage(true);

    setTimeout(async () => {
      await setIsLoad(true);
    }, 300);
  };

  if (isLazy) {
    return (
      <>
        {!isLoad && (
          <img
            src={location === 'home' ? homeDefault : awayDefault}
            className={clsx(className)}
            height={`${imgSize}`}
          />
        )}
        {!isError && (
          <img
            onLoad={(e) => onLoadedImage(e)}
            onError={(e) => onErrorImage(e)}
            data-src={`${Url.thumb}/0x${imgSize}${imgUrl}`}
            data-srcSet={`${Url.thumb}/0x${imgSize}${imgUrl} ${imgSize}w,
          ${Url.thumb}/0x${imgSize * 2}${imgUrl} ${imgSize * 2}w,
          ${Url.thumb}/0x${imgSize * 3}${imgUrl} ${imgSize * 3}w,
          `}
            srcSet={`${Url.thumb}/0x${imgSize}${imgUrl} ${imgSize}w,
          ${Url.thumb}/0x${imgSize * 2}${imgUrl} ${imgSize * 2}w,
          ${Url.thumb}/0x${imgSize * 3}${imgUrl} ${imgSize * 3}w,
          `}
            className={clsx(className)}
            height={`${imgSize}`}
          />
        )}
      </>
    );
  } else {
    if (imgUrl === null) {
      return (
        <img
          src={location === 'home' ? homeDefault : awayDefault}
          className={clsx(className)}
          height={`${imgSize}`}
        />
      );
    } else {
      return (
        !isError && (
          <img
            onLoad={(e) => onLoadedImage(e)}
            // srcSet={`${
            //   Url.thumb
            // }normal/resize/0x${imgSize}${imgUrl} ${imgSize}w,
            //   ${Url.thumb}normal/resize/0x${imgSize * 2}${imgUrl} ${
            //   imgSize * 2
            // }w,
            //   ${Url.thumb}normal/resize/0x${imgSize * 3}${imgUrl} ${
            //   imgSize * 3
            // }w,
            //   `}
            src={imgSrc}
            className={clsx(className)}
            height={`${imgSize}`}
          />
        )
      );
    }
  }
};

export default SportsTeamLogo;
