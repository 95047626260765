import {
  extendObservable,
  computed,
  action,
  createAtom,
  observable,
  runInAction
} from 'mobx';
import autobind from 'autobind-decorator';
import AbstractScoreAudioPrompt from '../../../store/audio';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import { ScoreUtil } from '../../../../../common/utils/scoreUtil';

@autobind
class SoccerModel extends AbstractScoreAudioPrompt {
  @observable
  progressTime;
  intervalHandler = null;
  soccerElapsedMin;

  @observable
  goalEvent = {
    location: '',
    isActive: false
  };

  constructor(data) {
    super();
    extendObservable(this, data);

    if (data.gameStatus === 'IN_PROGRESS' && !!data.realStartDateTime) {
      this.progressTime = createAtom(
        'ProgressTime',
        () => this.startTicking(),
        () => this.stopTicking()
      );
    }
  }

  getSoccerTime() {
    if (this.progressTime.reportObserved()) {
      return this.soccerElapsedMin;
    } else {
      return '';
    }
  }

  startTicking() {
    this.tick();
    this.intervalHandler = setInterval(() => this.tick(), 60000);
  }

  stopTicking() {
    clearInterval(this.intervalHandler);
    this.intervalHandler = null;
  }

  tick() {
    const { realStartDateTime, period } = this;

    let gap =
      new Date().getTime() - new Date(realStartDateTime + '+09:00').getTime();
    let elapsedSec = gap / 1000;
    let elapsedMin = gap / 1000 / 60;
    let floorMin = Math.floor(elapsedMin);

    this.progressTime.reportChanged();

    if (period === 1) {
      if (floorMin > 45) {
        this.soccerElapsedMin = 45;
      } else {
        this.soccerElapsedMin = floorMin;
      }
    } else if (period === 2) {
      if (floorMin + 45 > 90) {
        this.soccerElapsedMin = 90;
      } else {
        this.soccerElapsedMin = floorMin + 45;
      }
    } else if (period === 3) {
      if (floorMin > 105) {
        this.soccerElapsedMin = 15;
      } else {
        this.soccerElapsedMin = floorMin - 90;
      }
    } else if (period === 4) {
      if (floorMin > 120) {
        this.soccerElapsedMin = 15;
      } else {
        this.soccerElapsedMin = floorMin - 105;
      }
    } else {
      this.soccerElapsedMin = '';
    }
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get getTracker() {
    const gameStatus = this.gameStatus;

    if (
      gameStatus === 'IN_PROGRESS' ||
      gameStatus === 'BREAK_TIME' ||
      gameStatus === 'PAUSE'
    ) {
      return { status: 'progress', class: '' };
    } else if (
      gameStatus === 'FINAL' ||
      gameStatus === 'CUT' ||
      gameStatus === 'POSTPONED' ||
      gameStatus === 'PENDING' ||
      gameStatus === 'CANCEL' ||
      gameStatus === 'DELAY' ||
      gameStatus === 'SUSPENDED'
    ) {
      return { status: 'final', class: 'close' };
    } else {
      // READY
      return { status: 'ready', class: 'wait' };
    }
  }

  @computed
  get getMMDD() {
    const { startDatetime } = this;

    return timeUtils.timeFormatMonthDay(startDatetime).replace('.', '/');
  }

  @computed
  get getStartDateTime() {
    return timeUtils.timeFormatHourMinute(this.startDatetime);
  }

  @computed
  get getMatchGameTime() {
    return timeUtils.matchGameForamt(this.startDatetime);
  }

  @computed
  get getProgressTime() {
    const { gameStatus, realStartDateTime, period } = this;

    if (gameStatus === 'IN_PROGRESS' && !!realStartDateTime) {
      return {
        soccerTime: this.getSoccerTime(),
        isActiveTime: period > 2 ? false : true
      };
    } else {
      return { soccerTime: '', isActiveTime: false };
    }
  }

  @computed
  get checkTimeLineButton() {
    const { gameStatus } = this;
    let check = true;

    if (
      gameStatus === 'READY' ||
      gameStatus === 'CANCEL' ||
      gameStatus === 'DELAY' ||
      gameStatus === 'POSTPONED' ||
      gameStatus === 'PENDING'
    ) {
      check = false;
    }

    return check;
  }

  @computed
  get getTeamData() {
    const { period } = this;

    let teams = ScoreUtil.getTeamScore(
      this,
      this.gameStatus,
      this.sportsType,
      this.period
    );

    teams.home['ranking'] = !!this.teams.home.seasonTeamRanking
      ? `${this.teams.home.seasonTeamRanking}위`
      : '';
    teams.away['ranking'] = !!this.teams.away.seasonTeamRanking
      ? `${this.teams.away.seasonTeamRanking}위`
      : '';

    teams['isPk'] = false;

    if (period === 5) {
      teams['isPk'] = true;

      teams.home['pkScore'] = getScoreSum(this.teams.home);
      teams.away['pkScore'] = getScoreSum(this.teams.away);
    }

    function getScoreSum(team) {
      if (!!team.periodData) {
        if (team.periodData.length > 0) {
          return team.periodData
            .filter((item) => item.period === 5)
            .reduce((a, b) => {
              return a + b.score;
            }, 0);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }

    return teams;
  }

  @computed
  get winnerTeam() {
    const { teams, period } = this;
    const homeScore = this.homeTotalScore;
    const awayScore = this.awayTotalScore;
    let team = 'draw';
    let teamData = null;

    if (homeScore > awayScore) {
      team = 'home';
      teamData = teams.home;
    } else if (homeScore < awayScore) {
      team = 'away';
      teamData = teams.away;
    } else if (homeScore === awayScore) {
      team = 'draw';
      teamData = {
        imgPath: '',
        name: ''
      };
    }

    return {
      activeScore: team,
      team: teamData,
      difference: Math.abs(homeScore - awayScore),
      isPk: period === 5
    };
  }

  @computed
  get sortedHomeScores() {
    const { teams } = this;
    const { home } = teams;

    return home.periodData.sort((a, b) => a.period - b.period);
  }

  @computed
  get sortedAwayScores() {
    const { teams } = this;
    const { away } = teams;

    return away.periodData.sort((a, b) => a.period - b.period);
  }

  @computed
  get homeTotalScore() {
    let total = 0;

    this.teams.home.periodData.forEach((data) => {
      total += data.score;
    });

    return total;
  }

  @computed
  get awayTotalScore() {
    let total = 0;

    this.teams.away.periodData.forEach((data) => {
      total += data.score;
    });

    return total;
  }

  @computed
  get halfScore() {
    const { gameStatus, teams } = this;
    let result = {
      half_home: '',
      half_away: '',
      half_active: '',
      isHalfScoreActive: false
    };

    if (teams.home.periodData.length > 0) {
      if (
        gameStatus === 'IN_PROGRESS' ||
        gameStatus === 'BREAK_TIME' ||
        gameStatus === 'PAUSE' ||
        gameStatus === 'FINAL'
      ) {
        result.half_home = teams.home.periodData[0].score;
        result.half_away = teams.away.periodData[0].score;
        result.isHalfScoreActive = true;

        if (result.half_home > result.half_away) {
          result.half_active = 'home';
        } else if (result.half_home < result.half_away) {
          result.half_active = 'away';
        } else if (result.half_home === result.half_away) {
          result.half_active = '';
        }
      }
    }

    return result;
  }

  @computed
  get oddsData() {
    const { odds } = this;
    let result = {
      domestic: {
        home: null,
        homeOption: null,
        away: null,
        awayOption: null
      },
      international: {
        home: null,
        homeOption: null,
        away: null,
        awayOption: null
      }
    };

    result.domestic = ScoreUtil.getScoreOdds({
      oddsArr: odds.domesticWinLoseOdds,
      handiArr: odds.domesticHandicapOdds,
      uoArr: odds.domesticUnderOverOdds
    });

    result.international = ScoreUtil.getScoreOdds({
      oddsArr: odds.internationalWinLoseOdds,
      handiArr: odds.internationalHandicapOdds,
      uoArr: odds.internationalUnderOverOdds
    });

    return result;
  }

  @computed
  get statusText() {
    const { gameStatus, period } = this;

    // 주요경기용 statusText
    if (gameStatus === 'CANCEL') {
      return '취소';
    } else if (gameStatus === 'DELAY' || gameStatus === 'POSTPONED') {
      return '연기';
    } else if (gameStatus === 'BREAK_TIME') {
      return 'HT';
    } else if (gameStatus === 'PAUSE') {
      return '중지';
    } else if (gameStatus === 'PENDING' || gameStatus === 'CUT') {
      return '중단';
    } else if (gameStatus === 'READY') {
      return '대기';
    } else if (gameStatus === 'FINAL') {
      return '종료';
    } else if (gameStatus === 'IN_PROGRESS') {
      if (period === 1) {
        return '전반';
      } else if (period === 2) {
        return '후반';
      } else if (period === 3 || period === 4) {
        // return `연장 ${period === 3 ? '(전)' : '(후)'}`;
        return `연장`;
      } else if (period === 5) {
        return '승부차기';
      }
    }
  }

  @computed
  get statusData() {
    const { gameStatus, period, startDatetime } = this;
    let statusText = '';
    let statusClass = 'ready';
    let date = timeUtils.timeFormatHourMinute(startDatetime);

    if (gameStatus === 'CANCEL') {
      statusText = '취소';
      statusClass = 'cancel';
    } else if (gameStatus === 'DELAY' || gameStatus === 'POSTPONED') {
      statusText = '연기';
      statusClass = 'cancel';
    } else if (gameStatus === 'BREAK_TIME') {
      statusText = 'HT';
      statusClass = 'half';
    } else if (gameStatus === 'PAUSE') {
      statusText = '중지';
      statusClass = 'cancel';
    } else if (gameStatus === 'PENDING' || gameStatus === 'CUT') {
      statusText = '중단';
      statusClass = 'cancel';
    } else if (gameStatus === 'READY') {
      statusText = '대기';
      statusClass = 'ready';
    } else if (gameStatus === 'FINAL') {
      statusText = '종료';
      statusClass = 'ft';
    } else if (gameStatus === 'IN_PROGRESS') {
      statusClass = '';
      if (period === 1) {
        statusText = '전반';
      } else if (period === 2) {
        statusText = '후반';
      } else if (period === 3 || period === 4) {
        // statusText = `연장 ${period === 3 ? '(전)' : '(후)'}`;
        statusText = `연장`;
      } else if (period === 5) {
        statusText = '승부차기';
      }
    }

    return {
      statusText,
      statusClass,
      date
    };
  }

  @computed
  get availableBroadcast() {
    const { broadcast, teams } = this;
    let initBroadCast = {
      score: {},
      playText: '',
      locationType: null,
      eventType: '',
      displayTime: '00:00',
      teamName: ''
    };

    if (!!broadcast) {
      initBroadCast.score = !!broadcast.score
        ? broadcast.score
        : initBroadCast.score;
      initBroadCast.playText = !!broadcast.playText
        ? broadcast.playText
        : initBroadCast.playText;
      initBroadCast.locationType = !!broadcast.locationType
        ? broadcast.locationType
        : initBroadCast.locationType;
      initBroadCast.eventType = !!broadcast.eventType
        ? broadcast.eventType
        : initBroadCast.eventType;
      initBroadCast.displayTime = !!broadcast.displayTime
        ? broadcast.displayTime
        : initBroadCast.displayTime;
      if (!!initBroadCast.locationType) {
        if (initBroadCast.locationType === 'HOME') {
          initBroadCast.teamName = teams.home.name;
        } else if (initBroadCast.locationType === 'AWAY') {
          initBroadCast.teamName = teams.away.name;
        }
      }
    }

    return initBroadCast;
  }

  @computed
  get timeLineTimerWidth() {
    const { getProgressTime, gameStatus, period, getFinishTime } = this;

    if (
      gameStatus === 'READY' ||
      gameStatus === 'CANCEL' ||
      gameStatus === 'DELAY' ||
      gameStatus === 'POSTPONED' ||
      gameStatus === 'PENDING' ||
      gameStatus === 'FINAL'
    ) {
      return 0;
    } else {
      const { soccerTime } = getProgressTime;
      let finishTime =
        getFinishTime.time + getFinishTime.extraTime.reduce((a, c) => a + c, 0);
      let realTime = 0;

      if (gameStatus === 'BREAK_TIME') {
        realTime = 50;
      } else {
        realTime = (soccerTime / finishTime) * 100 - 1;
      }

      return realTime;
    }
  }

  @computed
  get getFinishTime() {
    let result = 0;
    const { period, broadcasts, checkExtraTime } = this;
    const lastBroadCast = broadcasts
      .slice()
      .filter((item) => item.locationType !== 'TOTAL')
      .pop();
    const finishTimeArr = [45, 90, 120];

    let extraTime = [];

    for (let i = 0; i < period; i++) {
      if (period < 5) {
        let idx = period === 4 ? 3 : i + 1;

        extraTime.push(
          getLastPeriodEventTime(broadcasts, idx, finishTimeArr[period ? 3 : i])
        );
      }
    }

    function getLastPeriodEventTime(list, period, periodFinishTime) {
      return list
        .filter((item) => item.period === period)
        .reduce((acc, cur) => {
          const time = cur.displayTime.split(':');
          const realTime = +time[0] * 60 + +time[1];

          if (cur.locationType !== 'TOTAL') {
            if (realTime > periodFinishTime) {
              acc = realTime - periodFinishTime;
            }
          }

          return acc;
        }, 0);
    }

    if (!!lastBroadCast) {
      const time = lastBroadCast.displayTime.split(':');
      const realTime = +time[0] * 60 + +time[1];

      if (period === 2 && realTime > 90) {
        result = 90 + (realTime - 90);
      } else if (period > 2) {
        if (checkExtraTime) {
          result = 127;
        } else {
          result = 90;
        }
        // if (period === 3) {
        //   // if (gameStatus === 'FINAL') {
        //   // 120분으로 설정시 아이콘 크기때문에 화면 밀림
        //   result = 127;
        //   // } else {
        //   //   result = 105;
        //   // }
        // }
        // else if (period >= 4) {
        //   result = 127;
        // }
      } else {
        result = 90;
      }
    } else {
      result = 90;
    }

    return { time: result, extraTime };
  }

  @computed
  get checkExtraTime() {
    const { period, teams } = this;
    const checkExtraTime =
      teams.home.periodData.filter((item) => item.period === 3).length > 0;
    let result = false;

    if (period === 5) {
      if (checkExtraTime) {
        result = true;
      }
    }

    if (period === 3 || period === 4) {
      result = true;
    }

    return result;
  }

  @computed
  get betbleButtonVisible() {
    const { betbleUri, getTracker } = this;
    return !!betbleUri && getTracker.status !== 'final';
  }

  @computed
  get matchTrackerVisible() {
    const { betbleUri, getTracker } = this;
    return !!betbleUri && getTracker.status === 'progress';
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  updateGame(data) {
    if (typeof this.progressTime === 'undefined') {
      if (data.gameStatus === 'IN_PROGRESS' && !!data.realStartDateTime) {
        this.progressTime = createAtom(
          'ProgressTime',
          () => this.startTicking(),
          () => this.stopTicking()
        );
      }
    }

    for (const [key, value] of Object.entries(data)) {
      // console.log('변경시작 ! = ', key);
      if (key === 'teams') {
        this.teams = {
          ...this.teams,
          ...value,
          home: {
            ...this.teams.home,
            ...value.home
          },
          away: {
            ...this.teams.away,
            ...value.away
          }
        };
      } else {
        this[key] = value;
      }
      // console.log(`${key}: ${value}`);
    }
  }

  @action
  updateBroadcasts(data) {
    this.broadcasts = data;
  }

  @action
  updatePeriodData(data, isFavoriteActivePrompt, isFavorite) {
    // console.log('페리오드 업데이트 = ', data);
    const { home, away } = this.getTeamData;
    const total = home.score + away.score;

    const next = {
      home: data.home.reduce((acc, cur) => acc + cur.score, 0),
      away: data.away.reduce((acc, cur) => acc + cur.score, 0)
    };
    const nextTotal = next.home + next.away;

    if (nextTotal > total) {
      let location = 'home';

      if (next.home > home.score) {
        location = 'home';
      } else if (next.away > away.score) {
        location = 'away';
      }

      this.setGoalEvent(location);
    }

    if (nextTotal !== total) {
      if (isFavoriteActivePrompt) {
        if (isFavorite) {
          this.playAudio(this.id);
        }
      } else {
        this.playAudio(this.id);
      }
    }

    this.teams = {
      ...this.teams,
      home: {
        ...this.teams.home,
        periodData: data.home
      },
      away: {
        ...this.teams.away,
        periodData: data.away
      }
    };
  }

  @action
  setGoalEvent(location) {
    if (!this.goalEvent.isActive) {
      runInAction(() => {
        this.goalEvent.isActive = true;
        this.goalEvent.location = location;

        setTimeout(() => {
          runInAction(() => {
            this.goalEvent = {
              location: '',
              isActive: false
            };
          });
        }, 60000);
      });
    }
  }
}

export default SoccerModel;
