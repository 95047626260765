import React from 'react';
import PeriodBarChart from '../../../periodBarChart';

const minMaxList = [
  { name: '좌', resultKey: 'start_left' },
  { name: '우', resultKey: 'start_right' },
  { name: '3', resultKey: 'line_three' },
  { name: '4', resultKey: 'line_four' },
  { name: '홀', resultKey: 'odd' },
  { name: '짝', resultKey: 'even' },
  { name: '좌4홀', resultKey: 'l4o' },
  { name: '좌3짝', resultKey: 'l3e' },
  { name: '우3홀', resultKey: 'r3o' },
  { name: '우4짝', resultKey: 'r4e' }
];

const MinMaxWithinPeriod = ({ data }) => {
  if (Object.keys(data).length <= 3) {
    return null;
  }

  return (
    <div className="data_minmax_wrap">
      <h3 className="tit">기간내 최소/최다 출현 통계</h3>
      <div className="minmax_list">
        <div className="reference">
          <span className="tx_min">최소출현</span>
          <span className="tx_max">최대출현</span>
        </div>
        <div className="tit_sub"></div>
        <PeriodBarChart data={data} mapList={minMaxList} />
      </div>
    </div>
  );
};

export default MinMaxWithinPeriod;
