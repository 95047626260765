import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const Space8PatternBarChart = ({ percent, gameName }) => {
    if (Object.keys(percent).length === 0) {
        return null;
    }

    const {
        count_win,
        sum_win,
        sum_home_ball_odd_even,
        sum_away_ball_odd_even,
        home_ball1_odd_even,
        away_ball1_odd_even,
        home_ball1_under_over,
        away_ball1_under_over,
    } = percent;

    return (
        <>
            <div className="data_summary distance">
                <h3 className="tit new_tit">볼 갯수 승무패</h3>
                <div className="chart">
                    <dl
                        className={clsx(
                            'bar new_bar',
                            'HOME',
                            parseInt(count_win.home_cnt) > parseInt(count_win.away_cnt)
                            && parseInt(count_win.home_cnt) > parseInt(count_win.draw_cnt)
                            && 'on'
                        )}
                    >
                        <dt>좌</dt>
                        <dd>
                            <span className="num">{count_win.home_cnt}</span>
                            <span className="per">({count_win.home_ratio}%)</span>
                        </dd>
                    </dl>
                    <dl
                        className={clsx(
                            'bar new_bar',
                            'DRAW',
                            parseInt(count_win.draw_cnt) > parseInt(count_win.home_cnt)
                            && parseInt(count_win.draw_cnt) > parseInt(count_win.away_cnt)
                            && 'on'
                        )}
                    >
                        <dt>무</dt>
                        <dd>
                            <span className="num">{count_win.draw_cnt}</span>
                            <span className="per">({count_win.draw_ratio}%)</span>
                        </dd>
                    </dl>
                    <dl
                        className={clsx(
                            'bar new_bar',
                            'AWAY',
                            parseInt(count_win.away_cnt) > parseInt(count_win.home_cnt)
                            && parseInt(count_win.away_cnt) > parseInt(count_win.draw_cnt)
                            && 'on'
                        )}
                    >
                        <dt>우</dt>
                        <dd>
                            <span className="num">{count_win.away_cnt}</span>
                            <span className="per">({count_win.away_cnt}%)</span>
                        </dd>
                    </dl>
                </div>
            </div>
            {sum_win && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">볼 번호 합산 승무패</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'HOME',
                                parseInt(sum_win.home_cnt) > parseInt(sum_win.away_cnt)
                                && parseInt(sum_win.home_cnt) > parseInt(sum_win.draw_cnt)
                                && 'on'
                            )}
                        >
                            <dt>좌</dt>
                            <dd>
                                <span className="num">{sum_win.home_cnt}</span>
                                <span className="per">({sum_win.home_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(sum_win.draw_cnt) > parseInt(sum_win.home_cnt)
                                && parseInt(sum_win.draw_cnt) > parseInt(sum_win.away_cnt)
                                && 'on'
                            )}
                        >
                            <dt>무</dt>
                            <dd>
                                <span className="num">{sum_win.draw_cnt}</span>
                                <span className="per">({sum_win.draw_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'AWAY',
                                parseInt(sum_win.away_cnt) > parseInt(sum_win.home_cnt)
                                && parseInt(sum_win.away_cnt) > parseInt(sum_win.draw_cnt)
                                && 'on'
                            )}
                        >
                            <dt>우</dt>
                            <dd>
                                <span className="num">{sum_win.away_cnt}</span>
                                <span className="per">({sum_win.away_ratio}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {sum_home_ball_odd_even && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">좌측 번호 합 홀짝</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'ODD',
                                parseInt(sum_home_ball_odd_even.odd_cnt) > parseInt(sum_home_ball_odd_even.even_cnt)
                                && parseInt(sum_home_ball_odd_even.odd_cnt) > parseInt(sum_home_ball_odd_even.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>홀</dt>
                            <dd>
                                <span className="num">{sum_home_ball_odd_even.odd_cnt}</span>
                                <span className="per">({sum_home_ball_odd_even.odd_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(sum_home_ball_odd_even.zero_cnt) > parseInt(sum_home_ball_odd_even.odd_cnt)
                                && parseInt(sum_home_ball_odd_even.zero_cnt) > parseInt(sum_home_ball_odd_even.even_cnt)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{sum_home_ball_odd_even.zero_cnt}</span>
                                <span className="per">({sum_home_ball_odd_even.zero_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'EVEN',
                                parseInt(sum_home_ball_odd_even.even_cnt) > parseInt(sum_home_ball_odd_even.odd_cnt)
                                && parseInt(sum_home_ball_odd_even.even_cnt) > parseInt(sum_home_ball_odd_even.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>짝</dt>
                            <dd>
                                <span className="num">{sum_home_ball_odd_even.even_cnt}</span>
                                <span className="per">({sum_home_ball_odd_even.even_ratio}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {sum_away_ball_odd_even && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">우측 번호 합 홀짝</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'ODD',
                                parseInt(sum_away_ball_odd_even.odd_cnt) > parseInt(sum_away_ball_odd_even.even_cnt)
                                && parseInt(sum_away_ball_odd_even.odd_cnt) > parseInt(sum_away_ball_odd_even.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>홀</dt>
                            <dd>
                                <span className="num">{sum_away_ball_odd_even.odd_cnt}</span>
                                <span className="per">({sum_away_ball_odd_even.odd_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(sum_away_ball_odd_even.zero_cnt) > parseInt(sum_away_ball_odd_even.odd_cnt)
                                && parseInt(sum_away_ball_odd_even.zero_cnt) > parseInt(sum_away_ball_odd_even.even_cnt)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{sum_away_ball_odd_even.zero_cnt}</span>
                                <span className="per">({sum_away_ball_odd_even.zero_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'EVEN',
                                parseInt(sum_away_ball_odd_even.even_cnt) > parseInt(sum_away_ball_odd_even.odd_cnt)
                                && parseInt(sum_away_ball_odd_even.even_cnt) > parseInt(sum_away_ball_odd_even.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>짝</dt>
                            <dd>
                                <span className="num">{sum_away_ball_odd_even.even_cnt}</span>
                                <span className="per">({sum_away_ball_odd_even.even_ratio}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {home_ball1_odd_even && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">좌측 1등볼 홀짝</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'ODD',
                                parseInt(home_ball1_odd_even.odd_cnt) > parseInt(home_ball1_odd_even.even_cnt)
                                && parseInt(home_ball1_odd_even.odd_cnt) > parseInt(home_ball1_odd_even.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>홀</dt>
                            <dd>
                                <span className="num">{home_ball1_odd_even.odd_cnt}</span>
                                <span className="per">({home_ball1_odd_even.odd_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(home_ball1_odd_even.zero_cnt) > parseInt(home_ball1_odd_even.odd_cnt)
                                && parseInt(home_ball1_odd_even.zero_cnt) > parseInt(home_ball1_odd_even.even_cnt)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{home_ball1_odd_even.zero_cnt}</span>
                                <span className="per">({home_ball1_odd_even.zero_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'EVEN',
                                parseInt(home_ball1_odd_even.even_cnt) > parseInt(home_ball1_odd_even.odd_cnt)
                                && parseInt(home_ball1_odd_even.even_cnt) > parseInt(home_ball1_odd_even.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>짝</dt>
                            <dd>
                                <span className="num">{home_ball1_odd_even.even_cnt}</span>
                                <span className="per">({home_ball1_odd_even.even_ratio}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {away_ball1_odd_even && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">우측 1등볼 홀짝</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'ODD',
                                parseInt(away_ball1_odd_even.odd_cnt) > parseInt(away_ball1_odd_even.even_cnt)
                                && parseInt(away_ball1_odd_even.odd_cnt) > parseInt(away_ball1_odd_even.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>홀</dt>
                            <dd>
                                <span className="num">{away_ball1_odd_even.odd_cnt}</span>
                                <span className="per">({away_ball1_odd_even.odd_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(away_ball1_odd_even.zero_cnt) > parseInt(away_ball1_odd_even.odd_cnt)
                                && parseInt(away_ball1_odd_even.zero_cnt) > parseInt(away_ball1_odd_even.even_cnt)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{away_ball1_odd_even.zero_cnt}</span>
                                <span className="per">({away_ball1_odd_even.zero_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'EVEN',
                                parseInt(away_ball1_odd_even.even_cnt) > parseInt(away_ball1_odd_even.odd_cnt)
                                && parseInt(away_ball1_odd_even.even_cnt) > parseInt(away_ball1_odd_even.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>짝</dt>
                            <dd>
                                <span className="num">{away_ball1_odd_even.even_cnt}</span>
                                <span className="per">({away_ball1_odd_even.even_ratio}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {home_ball1_under_over && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">좌측 1등볼 언오버</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'UNDER',
                                parseInt(home_ball1_under_over.under_cnt) > parseInt(home_ball1_under_over.over_cnt)
                                && parseInt(home_ball1_under_over.under_cnt) > parseInt(home_ball1_under_over.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>언</dt>
                            <dd>
                                <span className="num">{home_ball1_under_over.under_cnt}</span>
                                <span className="per">({home_ball1_under_over.under_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(home_ball1_under_over.zero_cnt) > parseInt(home_ball1_under_over.under_cnt)
                                && parseInt(home_ball1_under_over.zero_cnt) > parseInt(home_ball1_under_over.over_cnt)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{home_ball1_under_over.zero_cnt}</span>
                                <span className="per">({home_ball1_under_over.zero_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'OVER',
                                parseInt(home_ball1_under_over.over_cnt) > parseInt(home_ball1_under_over.under_cnt)
                                && parseInt(home_ball1_under_over.over_cnt) > parseInt(home_ball1_under_over.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>오</dt>
                            <dd>
                            <span className="num">{home_ball1_under_over.over_cnt}</span>
                                <span className="per">({home_ball1_under_over.over_ratio}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {away_ball1_under_over && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">우측 1등볼 언오버</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'UNDER',
                                parseInt(away_ball1_under_over.under_cnt) > parseInt(away_ball1_under_over.over_cnt)
                                && parseInt(away_ball1_under_over.under_cnt) > parseInt(away_ball1_under_over.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>언</dt>
                            <dd>
                                <span className="num">{away_ball1_under_over.under_cnt}</span>
                                <span className="per">({away_ball1_under_over.under_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(away_ball1_under_over.zero_cnt) > parseInt(away_ball1_under_over.under_cnt)
                                && parseInt(away_ball1_under_over.zero_cnt) > parseInt(away_ball1_under_over.over_cnt)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{away_ball1_under_over.zero_cnt}</span>
                                <span className="per">({away_ball1_under_over.zero_ratio}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'OVER',
                                parseInt(away_ball1_under_over.over_cnt) > parseInt(away_ball1_under_over.under_cnt)
                                && parseInt(away_ball1_under_over.over_cnt) > parseInt(away_ball1_under_over.zero_cnt)
                                && 'on'
                            )}
                        >
                            <dt>오</dt>
                            <dd>
                                <span className="num">{away_ball1_under_over.over_cnt}</span>
                                <span className="per">({away_ball1_under_over.over_ratio}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
        </>

    );
};

export default Space8PatternBarChart;
