import React, { useState, useEffect, useMemo } from 'react';
// import style from './style.module.scss';
import { observer } from 'mobx-react';
import { MypageCashChargeList, MypageCashUseList} from '../components';
import { useLocation, Link } from 'react-router-dom';
import clsx from "clsx";
import {MypageContext, useMypageStore} from "../context";
import {RegexUtil} from "../../../common/utils/regexUtil";
import Pagination from "../../../components/pagination";
import useUsersStore from "../../../hooks/useUsersStore";

const CashReceipt = observer(({ history }) => {
  const usersStore= useUsersStore();
  const { mypageStore } = useMypageStore(MypageContext);

  let data = {
    pay_id : history.location.state.pay_id,
    mb_id : usersStore.data.mb_id,
    type : null,
    id_number : null
  }

  useEffect(() => {

  }, );

  const goBack = () => {
    history.goBack();
  }

  const onClickRequestCashBill = () => {

    if (!data.type) {
      alert('신청 유형을 선택하세요');
      return;
    }

    if (!data.id_number) {
      alert('신청 번호를 입력하세요');
      return;
    }
    mypageStore.actionRequestCashBill(data, history.goBack);
  }

  const handleRadio = async (e) => {
    data.type = e.target.value;
  }

  const handleContent = async (e) => {
    data.id_number = e.target.value;
  }


  return (
    <>
      <div className="pay_receipt_wrap">
        <div className="nav"><h2 className="tit">현금영수증 신청</h2>
          <button className="btn_prev" onClick={goBack}>이전으로</button>
        </div>
        <div className="summary">
          <p>번호 입력시 - 없이 숫자만 입력해 주세요.</p>
          <p>신청하신 현금 영수증은 국세청에 전산 처리 후 익일부터 출력이 가능합니다.</p>
        </div>
        <div className="form_area">
          <div className="type_option">
            <input
                type="radio"
                id="bill_type1"
                name="bill_type"
                value={"consumer"}
                onChange={handleRadio}
            />
            <label htmlFor="bill_type1">소비자 소득 공제용</label>
            <input
                type="radio"
                id="bill_type2"
                name="bill_type"
                value={"corp"}
                onChange={handleRadio}
            />
            <label htmlFor="bill_type2">사업자 지출 증빙용</label>
          </div>
          <input type="text" className="inputbox" autoComplete="off" tabIndex="1" onChange={handleContent} placeholder="휴대전화 번호 입력" />
          <div className="btn_area">
            <button type="button" className="btn" onClick={goBack}s>취소</button>
            <button type="submit" className="btn btn_submit" onClick={onClickRequestCashBill}>신청하기</button>
          </div>
        </div>
      </div>
    </>
  );
});

export default CashReceipt;
