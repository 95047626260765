import styled from 'styled-components';

export const PasswordInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;

  .inputbox {
    padding-right: 40px;
    font-size: 1rem;
  }
`;

export const PasswordToggleButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
    height: 20px;
    display: block;
  }
`;