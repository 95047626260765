import React from 'react';
import {Url} from "../../common/constants/environment";

const MypageDetailHtml = () => {
  return (
    <>

      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">이메일 인증</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="find_area">
          <div className="result">
            <p className="tx">아래 가입한 아이디(이메일)로 인증 메일을 발송 합니다.</p>
            <p className="email">named@named.com</p>
          </div>
          <div className="btn_area">
            <button type="button" className="btn">취소</button>
            <button type="submit" className="btn btn_submit">확인</button>
          </div>
        </div>
      </div>

      <br/><hr/><br/>

      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">이메일 인증</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="sleeper_area">
          <div className="email_auth"><p><strong>named@named.com</strong>(으)로 인증 메일을 발송 하였습니다.</p>
            <p>메일을 10분 이내에 확인한 후 메일 내용의 링크를 클릭하면 인증을 완료하실 수 있습니다.</p></div>
          <div className="btn_area">
            <a className="btn btn_submit" href={Url.namedWeb} target="_blank">메일 확인하러 가기</a>
          </div>
        </div>
        <div className="tip">
          <p>메일을 받지 못하셨나요? <a className="lnk" href="javascript:;">인증메일 재전송</a></p>
        </div>
      </div>

      <br/><hr/><br/>

      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">비밀번호 변경</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="password_reset_area">
          <div className="inner">
            <div className="item">
              <input type="password" className="inputbox" autoComplete="off" tabIndex="1" title="비밀번호" placeholder="현재 비밀번호를 입력하세요."/>
            </div>
            <div className="item">
              <input type="password" className="inputbox" autoComplete="off" tabIndex="2" title="비밀번호" placeholder="새 비밀번호를 입력하세요."/>
                <p className="error">비밀번호는 최소4자리, 최대20자리 까지 입력 가능합니다.</p>
            </div>
            <div className="item">
              <input type="password" className="inputbox" autoComplete="off" tabIndex="3" title="비밀번호" placeholder="새 비밀번호를 한번 더 입력하세요."/>
                <p className="error">비밀번호가 일치하지 않습니다.</p>
            </div>
          </div>
          <div className="btn_area">
            <button type="submit" className="btn">취소</button>
            <button type="submit" className="btn btn_submit">변경하기</button>
          </div>
          <div className="tip">
            <p>안전한 비밀번호로 내정보를 보호하세요.</p>
            <p>다른 아이디/사이트에서 사용한 적 없는 비밀번호</p>
            <p>이전에 사용한 적 없는 비밀번호가 안전합니다.</p>
          </div>
        </div>
      </div>

      <br/><hr/><br/>

      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">닉네임 변경</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="info_change_area">
          <div className="inner">
            <p className="tx">닉네임 변경권 <strong>5개</strong> 보유중</p>
            <div className="item">
              <input type="text" className="inputbox" autoComplete="off" tabIndex="1" title="이메일" placeholder="닉네임을 입력해주세요."/>
                <button type="button">중복체크</button>
            </div>
            <p className="error">닉네임은 공백없이 한글, 영문, 숫자만 입력 가능합니다.</p>
            {/*
            <p class="error">사용 중인 닉네임입니다. 다른 닉네임을 사용해 주세요.</p>
            */}
          </div>
          <div className="btn_area">
            <button type="submit" className="btn">취소</button>
            <button type="submit" className="btn btn_submit">변경하기</button>
          </div>
        </div>
        <div className="tip">
          <p>변경권이 없으신가요? <a className="lnk" href="javascript:;">닉네임 변경권 구매하기</a></p>
          <p className="c_999">닉네임을 변경하지 않고 페이지를 닫으실 경우 해당 아이템은 사용되지 않습니다.</p>
          <p className="c_999">한글, 영문, 숫자를 혼용하여 사용할 수 있습니다. (한글2자,영문/숫자4자 이상, 8자 이하)</p>
          <p className="c_999">욕설이나 미풍양속에 어긋나는 닉네임 사용으로 신고 접수 시 ID이용이 제한될 수 있습니다.</p>
        </div>
      </div>

      <br/><hr/><br/>

      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">오늘의 한마디</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="info_change_area">
          <div className="inner">
            <p className="tx">오늘의 한마디 변경권 <strong>5개</strong> 보유중</p>
            <div className="item">
              <input type="text" className="inputbox" autoComplete="off" tabIndex="1" title="오늘의 한마디를 입력해주세요" placeholder="오늘의 한마디를 입력해주세요."/>
            </div>
            <p className="error">오늘의 한마디는 한글,숫자,영문,띄어쓰기( ),마침표(.),쉼표(,)만 가능합니다.</p>
          </div>
          <div className="btn_area">
            <button type="submit" className="btn">취소</button>
            <button type="submit" className="btn btn_submit">변경하기</button>
          </div>
        </div>
        <div className="tip">
          <p>변경권이 없으신가요? <a className="lnk" href="javascript:;">오늘의 한마디 구매하기</a></p>
          <p className="c_999">사이트 규정에 어긋나는 용도로 사용시 제재의 대상이 됩니다.</p>
          <p className="c_999">한글 20글자, 영문 40글자 까지 입력가능합니다.(한글, 숫자, 영문, 띄어쓰기, 마침표, 쉼표만 가능)</p>
        </div>
      </div>

      <br/><hr/><br/>

      {/*<div className="account_info_wrap">*/}
      {/*  <div className="nav">*/}
      {/*    <h2 className="tit">2차 비밀번호 설정</h2>*/}
      {/*    <button className="btn_prev">이전으로</button>*/}
      {/*  </div>*/}
      {/*  <div className="sleeper_area">*/}
      {/*    <div className="inner">*/}
      {/*      <dl className="info">*/}
      {/*        <dt>2차 비밀번호란?</dt>*/}
      {/*        <dd>*/}
      {/*          <p>회원님의 계정을 더욱 안전하게 보호하기 위한 수단이며 포인트 선물하기시 사용 됩니다.</p>*/}
      {/*          <p>2차 비밀번호 설정을 위해서는 아래의 인증 방법중 한가지를 통해 본인 인증 절차가 필요합니다.</p>*/}
      {/*        </dd>*/}
      {/*      </dl>*/}
      {/*      <div className="certification">*/}
      {/*        <a className="btn btn_phone" href="javascript:;">휴대폰 인증</a>*/}
      {/*        <a className="btn btn_email" href="javascript:;">이메일 인증</a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <br/><hr/><br/>

      {/*<div className="account_info_wrap">*/}
      {/*  <div className="nav">*/}
      {/*    <h2 className="tit">2차 비밀번호 입력</h2>*/}
      {/*    <button className="btn_prev">이전으로</button>*/}
      {/*  </div>*/}
      {/*  <div className="sleeper_area">*/}
      {/*    <div className="inner">*/}
      {/*      <div className="item">*/}
      {/*        <input type="text" className="inputbox" autoComplete="off" tabIndex="1" title="비밀번호 4자리를 입력해주세요." placeholder="비밀번호 4자리를 입력해주세요."/>*/}
      {/*      </div>*/}
      {/*      <p className="error">오늘의 한마디는 한글,숫자,영문,띄어쓰기( ),마침표(.),쉼표(,)만 가능합니다.</p>*/}
      {/*    </div>*/}
      {/*    <div className="btn_area">*/}
      {/*      <button type="submit" className="btn">취소</button>*/}
      {/*      <button type="submit" className="btn btn_submit">설정하기</button>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="tip">*/}
      {/*    <p>2차 비밀번호는 회원님의 계정을 더욱 안전하게 보호하기 위한 수단입니다.</p>*/}
      {/*    <p className="c_999">5회이사 입력이 틀릴경우 더이상 입력이 불가하며, 재설정을 통해 재입력이 가능합니다.</p>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <br/><hr/><br/>

      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">프로필 이미지 변경</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="img_change_area">
          <div className="inner">
            <a className="profile_img" href="javascript:;">
              <span className="thumb">
                <img src="/images/thumb_user_def.png"/>
                <svg
                    className="border"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 36 36"
                    enable-background="new 0 0 36 36"
                    xmlSpace="preserve"
                >
                  <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                  </svg>
              </span>
            </a>
            <button type="button" className="btn_upload profile">이미지 올리기</button>
            <p className="tx">프로필 이미지 변경권 <strong>5개</strong> 보유중</p>
          </div>
          <div className="btn_area">
            <button type="button" className="btn">취소</button>
            <button type="submit" className="btn btn_submit">변경하기</button>
          </div>
        </div>
        <div className="tip">
          <p>변경권이 없으신가요? <a className="lnk" href="javascript:;">프로필 이미지 변경권 구매하기</a></p>
          <p className="c_999">선정적, 사회적으로 이슈가 되는 이미지 등록 시 별도의 통보없이 삭제될 수 있습니다.</p>
        </div>
      </div>

      <br/><hr/><br/>

      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">프로필 배경 이미지 변경</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="img_change_area">
          <div className="inner">
            <a className="background_img" href="javascript:;">
              <span className="thumb">
                <img src="/images/thumb_user_def.png"/>
                <svg
                    className="border"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 36 36"
                    enable-background="new 0 0 36 36"
                    xmlSpace="preserve"
                >
                <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                </svg>
              </span>
            </a>
            <button type="button" className="btn_upload background">이미지 올리기</button>
            <p className="tx">프로필 배경 이미지 변경권 <strong>5개</strong> 보유중</p>
          </div>
          <div className="btn_area">
            <button type="button" className="btn">취소</button>
            <button type="submit" className="btn btn_submit">변경하기</button>
          </div>
        </div>
        <div className="tip">
          <p>변경권이 없으신가요? <a className="lnk" href="javascript:;">프로필 배경 이미지 변경권 구매하기</a></p>
          <p className="c_999">선정적, 사회적으로 이슈가 되는 이미지 등록 시 별도의 통보없이 삭제될 수 있습니다.</p>
          <p className="c_999">모바일 최적화 권장 이미지 사이즈는 720px x 440px 입니다.</p>
        </div>
      </div>

    </>
  );
};

export default MypageDetailHtml;
