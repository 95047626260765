import Api from '../api';
import {Url} from '../../constants/environment';

class BetService extends Api {
    constructor() {
        super();
    }

    /**
     * @description 이메일 인증번호 발송
     * @returns
     */
    async loginBet(gameType) {

        const form = new FormData();
        form.append('game_code', gameType);

        return await this.commonAjax('post', Url.named, `bet/login`, form);
    }


}

export default new BetService();