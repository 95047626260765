import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { NavMenu } from '../components';
import { LadderResult } from '../components/ladder/result';
import LadderAnalysis from '../components/ladder/analysis';
import { LadderPick } from '../components/ladder/pick';
import { RoomChatList } from '../components/roomChat';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import Seo from '../../../components/seo';
import { Url } from '../../../common/constants/environment';
import BetSection from '../betSection';

const Ladder = observer(({ history }) => {
  const { pathname } = useLocation();
  const query = new URLSearchParams(history.location.search);
  const tab = query.get('tab') || 'result';
  const [isSmallView, toggleSmallView] = useState(false);

  const gameName = pathname?.split('/')[3];

  useEffect(() => {
    toggleSmallView(false);
  }, [tab]);

  const component = useMemo(() => {
    switch (tab) {
      case 'room_chat':
        return <RoomChatList roomType={'lottery'} />;
      case 'analysis':
        return <LadderAnalysis />;
      case 'pick':
        return <LadderPick />;
      default:
        return <LadderResult />;
    }
  }, [tab, isSmallView]);

  return (
    <>
      <Seo title={'사다리'} />
      <div className='minigame_wrap'>
        <div className={clsx('game_view', 'ladder')}>
          <iframe scrolling='no' frameBorder='0' src={Url.namedWeb + 'minigame/ladder/ladder/view.php?view_mode=m'} />
        </div>
        <BetSection gameType={gameName} />
        <NavMenu />
        {component}
      </div>

      {/* NEW ADS #1 구글 검열로 인해 비활성화*/}
      {/*<BannerGA*/}
      {/*    containerStyle={{*/}
      {/*      display: 'block',*/}
      {/*      width: 450,*/}
      {/*      height: 50,*/}
      {/*      marginTop: 16,*/}
      {/*    }}*/}
      {/*    gaSlot={'5003058707'}*/}
      {/*    width={450}*/}
      {/*    height={50}*/}
      {/*/>*/}
    </>
  );
});

export default Ladder;
