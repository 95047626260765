import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Blind = ({ page, category, setBlindView }) => {
  return (
    <Wrap>
      <Content>
        <Warning>
          이 게시물은 회원님들의 신고로 인해
          <br />
          열람이 제한되어 있습니다.
          <p>누적 신고 건수 5회 이상</p>
        </Warning>
        <Confirm>
          <p>게시물을 열람하시겠습니까?</p>
          <Buttons>
            <button onClick={() => setBlindView(false)}>열람하기</button>
            <Link
              className="category"
              to={`/community/${category}?page=${page}`}
            >
              이전페이지
            </Link>
          </Buttons>
        </Confirm>
      </Content>
    </Wrap>
  );
};

const Wrap = styled.div`
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 270px;
  border: solid 2px #929ba3;
`;

const Warning = styled.div`
  font-size: 15px;
  line-height: 20px;
  padding: 32px 0 25px;
  text-align: center;
  > p {
    margin-top: 15px;
    color: #ed2026;
  }
  border-bottom: 1px solid #e4e4e4;
`;

const Confirm = styled.div`
  font-size: 15px;
  line-height: 20px;
  padding: 24px 0 32px;
  text-align: center;
`;

const Buttons = styled.div`
  height: 40px;
  display: flex;
  padding: 25px 25px 0;
  > button,
  > a {
    flex: 1;
    height: 100%;
    color: #fff;
    background-color: #ed2026;
    font-size: 15px;
    font-weight: normal;
  }
  > a {
    margin-left: 10px;
    background-color: #8e8e8e;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default Blind;
