import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import AutoSuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { Wrap, Label } from './styled';
import useSportsStore from '../../../../../hooks/useSportsStore';
import { StringUtil } from '../../../../../common/utils/stringUtil';

const Search = observer(({ open, currentDate }) => {
  const store = useSportsStore('soccer');
  const {
    suggestList,
    list,
    loading,
    setSearchValue,
    clearSearchValue,
    searchValue
  } = store;

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isClearActive, setClearActive] = useState(false);
  const [maxHeight, setMaxHeight] = useState('30vh');

  useEffect(() => {
    if (!loading) {
      if (list.length > 0) {
        store.setSuggestList(currentDate);
      }
    }
  }, [list, loading, currentDate]);

  useEffect(() => {
    if (!searchValue.hasOwnProperty('id')) {
      if (value.length > 0) {
        setClearActive(false);
        setValue('');
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (navigator.userAgent.includes('Android')) {
      setMaxHeight('50vh');
    }
    return () => {
      setClearActive(false);
      clearSearchValue();
    };
  }, []);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChangeInput = (newValue) => {
    if (newValue.length === 0) {
      setClearActive(false);
      clearSearchValue();
    }
    setValue(newValue);
  };

  const getSuggestions = (value) => {
    const result = suggestList
      .map((section) => {
        return {
          title: section.title,
          list: section.list.filter((item) => {
            const name = StringUtil.searchTextLowerProcess(value);
            const checkName = StringUtil.searchTextLowerProcess(item.name);
            return checkName.includes(name);
          })
        };
      })
      .filter((section) => {
        return section.list.length > 0;
      });

    return result;
  };

  return (
    <Wrap open={open} maxHeight={maxHeight}>
      <AutoSuggest
        multiSection={true}
        suggestions={suggestions}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, data) => {
          const { suggestion } = data;
          setClearActive(true);
          setSearchValue(suggestion);
        }}
        getSuggestionValue={(suggestion) => suggestion.name}
        getSectionSuggestions={getSectionSuggestions}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        inputProps={{
          placeholder: '팀이나 리그를 검색해 주세요.',
          value: value,
          onChange: (_, { newValue, method }) => {
            onChangeInput(newValue);
          }
        }}
        renderInputComponent={(inputProps) => (
          <RenderInputComponent
            inputProps={{ ...inputProps }}
            isClearActive={isClearActive}
            clearSearchValue={clearSearchValue}
          />
        )}
      />
    </Wrap>
  );
});

const RenderInputComponent = ({
  inputProps,
  isClearActive,
  clearSearchValue
}) => {
  return (
    <Label>
      <input {...inputProps} />
      {isClearActive && (
        <button type="button" className="btn_reset" onClick={clearSearchValue}>
          <span className="ic">리셋</span>
        </button>
      )}
    </Label>
  );
};

function renderSectionTitle(section) {
  return <span>{section.title}</span>;
}

function getSectionSuggestions(section) {
  return section.list;
}

function renderSuggestion(suggestion, { query }) {
  const suggestionText = suggestion.name;
  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  return (
    <span className={'suggestion-content'}>
      <span className="name">
        {parts.map((part, index) => {
          const className = part.highlight ? 'highlight' : null;

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </span>
    </span>
  );
}

export default Search;
