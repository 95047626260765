let videoWindow = null; // Store the reference to the opened window
let lastUrl = null; // Store the last URL that was opened

const getStreamBrowser = (link) => {
  const sourceCheck = link.endsWith("m3u8");

  // If the link is different, open a new window
  if (videoWindow && !videoWindow.closed && lastUrl !== link) {
    // Open a new window without closing the old one
    videoWindow = window.open(
      `https://sports.named.com/live?url=${encodeURI(link)}`,
      "",
      "scrollbars=no"
    );
    lastUrl = link; // Update the last opened URL
  } else if (videoWindow && !videoWindow.closed && lastUrl === link) {
    // If the URL is the same, just bring the existing window to focus
    videoWindow.focus();
    return;
  } else {
    // Open a new window for m3u8 streams if no window exists
    if (sourceCheck) {
      videoWindow = window.open(
        `https://sports.named.com/live?url=${encodeURI(link)}`,
        "",
        "scrollbars=no"
      );
      lastUrl = link; // Store the last opened URL
    } else {
      // If the link is not an m3u8 stream, open it in a normal tab
      window.open(
        link,
        "_blank",
        "width=730,height=605,resizable=yes,noopener, scrollbars=no"
      );
    }
  }

  // Ensure the window content has loaded, then resize the window based on the video size
  if (videoWindow !== undefined) {
    videoWindow.onload = () => {
      const videoElement = videoWindow.document.querySelector("video");

      let windowWidth = 640;
      let windowHeight = 440;

      if (videoElement) {
        videoElement.onloadedmetadata = () => {
          // Get the natural dimensions of the video
          windowWidth = videoElement.videoWidth - 25;
          windowHeight = videoElement.videoHeight + 40;
        };
      }

      videoWindow.resizeTo(windowWidth, windowHeight);
    };
  }
};

export { getStreamBrowser };
