import React from 'react';
import {Url} from "../../common/constants/environment";

const ScoreCastBaseballHtml = () => {
  return (
    <>

      {/* score_detail_wrap */ }

      <div className="score_detail_wrap"> {/* 클래스 상태 : small_view, ready, close */}

        <div className="summary">
          <button className="btn_prev">이전으로</button>
          <h2 className="tit">KBO</h2>
          <button className="btn_oc">크게보기/작게보기</button>
        </div>

        {/* score_detail_board */ }
        <div className="score_detail_board baseball"> {/* 클래스 종목 : soccer, basketball ---- 종목별 이름 */}

          {/* summary_score */ }
          <div className="summary_score">
            <div className="team left">
              <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                <span className="name">바로 셀로로</span>
                <span className="player">디에고 카스틸로</span>
            </div>
            <div className="info">
              <div className="score">
                <strong className="left on">
                  <em className="num">99</em>
                </strong>
                <strong className="right">
                  <em className="num">99</em>
                </strong>
              </div>
              <div className="state"><span>9회말</span></div>
              <div className="time">10.22 09:00</div>
              <div className="stadium">글로브 라이프 필드</div>
            </div>
            <div className="team right"><img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/14_1550480543.png"}/>
              <div className="name">바로 셀로나</div>
              <span className="player">디에고 카스틸로</span>
            </div>
          </div>
          {/* // summary_score */ }

          {/* detail_score */ }
          <div className="detail_score">
            <ul className="tab">
              <li>
                <button className="selected">스코어</button>
              </li>
              <li>
                <button>스페셜</button>
              </li>
              <li>
                <button>상황중계</button>
              </li>
            </ul>

            <div className="baseball_tracker" style={{ display: '' }}>
              <div className="ground">
                <div className="base">
                  <span className="b1 on"></span>
                  <span className="b2"></span>
                  <span className="b3 on"></span>
                </div>
                <div className="count">
                  <dl className="b">
                    <dt>B</dt>
                    <dd className="n1"><em></em><em></em><em></em></dd>
                  </dl>
                  <dl className="s">
                    <dt>S</dt>
                    <dd className="n2"><em></em><em></em><em></em></dd>
                  </dl>
                  <dl className="o">
                    <dt>O</dt>
                    <dd className="n3"><em></em><em></em><em></em></dd>
                  </dl>
                </div>
                <div className="base_on">
                  <span className="defence"><em>오승환</em></span>
                  <span className="offence"><em>이승엽</em></span>
                  <span className="base1"><em>이승엽1</em></span>
                  <span className="base2"><em>이승엽2</em></span>
                  <span className="base3"><em>이승엽3</em></span>
                </div>
                <div className="player">
                  <span>8 작 피더슨</span>
                  <span>9 오스틴 반스</span>
                  <span>1 무키 베츠</span>
                </div>
              </div>
            </div>

            <div className="detail_board" style={{ display: 'none' }}>
              <div className="team">
                <span>삼성</span>
                <span>엘지</span>
              </div>
              <div className="basic_board overtime" style={{ display: '' }}> {/* 클래스 : overtime */}
                <div className="inner">
                  <dl className="item">
                    <dt>1</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>2</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>3</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>4</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>5</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item on">
                    <dt>6</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>7</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>8</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>9</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>10</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>11</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>12</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                </div>
                <dl className="item r">
                  <dt>R</dt>
                  <dd>0</dd>
                  <dd>0</dd>
                </dl>
                <dl className="item">
                  <dt>H</dt>
                  <dd>0</dd>
                  <dd>0</dd>
                </dl>
                <dl className="item">
                  <dt>E</dt>
                  <dd>0</dd>
                  <dd>0</dd>
                </dl>
                <dl className="item">
                  <dt>B</dt>
                  <dd>0</dd>
                  <dd>0</dd>
                </dl>
              </div>

              <div className="special_board" style={{ display: 'none' }}>
                <div className="special_option">
                  <select>
                    <option value="0">전체</option>
                    <option value="1">1쿼터</option>
                    <option value="2">2쿼터</option>
                    <option value="3">3쿼터</option>
                    <option value="4">3쿼터</option>
                  </select>
                </div>
                <dl className="item">
                  <dt>첫득</dt>
                  <dd className="on"></dd>
                  <dd></dd>
                </dl>
                <dl className="item">
                  <dt>첫3</dt>
                  <dd></dd>
                  <dd></dd>
                </dl>
                <dl className="item">
                  <dt>첫자</dt>
                  <dd></dd>
                  <dd className="on"></dd>
                </dl>
                <dl className="item">
                  <dt>선5</dt>
                  <dd></dd>
                  <dd></dd>
                </dl>
                <dl className="item">
                  <dt>선7</dt>
                  <dd></dd>
                  <dd></dd>
                </dl>
                <dl className="item">
                  <dt>선10</dt>
                  <dd></dd>
                  <dd></dd>
                </dl>
              </div>

            </div>
          </div>
          {/* // detail_score */ }

        </div>
        {/* // score_detail_board */ }

        {/* snb_wrap */ }
        <div className="snb_wrap">
          <ul>
            <li><a className="item" href="javascript:;">응원</a></li>
            <li><a className="item" href="javascript:;">전력</a></li>
            <li><a className="item" href="javascript:;">배당</a></li>
            <li><a className="item" href="javascript:;">라인업</a></li>
            <li><a className="item selected" href="javascript:;">중계</a></li>
          </ul>
        </div>
        {/* // snb_wrap */ }

        {/* baseball_cast */ }
        <div className="baseball_cast">
          <dl className="item view">
            <dt>
              <span className="tit">6회</span>
              <button type="button" className="btn_oc">열기/닫기</button>
            </dt>
            <dd>
              <div className="box">
                <span className="player">7번 타자 윌 스미스</span>
                <span>1구 스트라이크</span>
                <span>2구 볼</span>
              </div>
              <div className="box">
                <span className="player">7번 타자 윌 스미스</span>
                <span>1구 스트라이크</span>
                <span>2구 볼</span>
              </div>
              <div className="box">
                <span className="notify">6회말 LA다저스 공격</span>
              </div>
            </dd>
          </dl>
          <dl className="item">
            <dt>
              <span className="tit">5회</span>
              <button type="button" className="btn_oc">열기/닫기</button>
            </dt>
            <dd>
              <div className="box">
                <span className="player">7번 타자 윌 스미스</span>
                <span>1구 스트라이크</span>
                <span>2구 볼</span>
              </div>
              <div className="box">
                <span className="player">7번 타자 윌 스미스</span>
                <span>1구 스트라이크</span>
                <span>2구 볼</span>
              </div>
            </dd>
          </dl>
        </div>
        {/* // baseball_cast */ }

      </div>
      {/* // score_detail_wrap */ }

    </>
  );
};

export default ScoreCastBaseballHtml;
