import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { MypageMyItem, MypageGiftList } from '../components';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { CategoryList } from '../../../style/myPageStyled';
import Seo from '../../../components/seo';

const Item = observer(({ history }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tab = query.get('tab') || 'myItem';
  const category = query.get('category') || (tab === 'myItem' ? 'all' : 'to');
  const page = parseInt(query.get('page')) || 1;

  const Component = useMemo(() => {
    switch (tab) {
      case 'giftList':
        return MypageGiftList;
      default:
        return MypageMyItem;
    }
  }, [tab]);

  const categoryList = useMemo(() => {
    switch (tab) {
      case 'giftList':
        return [
          { key: 'to', name: '보낸선물' },
          { key: 'from', name: '받은선물' }
        ];
      default:
        return [
          { key: 'all', name: '전체' },
          { key: 'package', name: '패키지' },
          { key: 'theme', name: '테마방' },
          { key: 'community', name: '커뮤니티' },
          { key: 'medal', name: '건빵' },
          { key: 'chat', name: '꾸미기' },
          { key: 'random', name: '랜덤' },
          { key: 'etc', name: '기타' },
          { key: 'using', name: '사용중' }
        ];
    }
  }, [tab]);

  return (
    <>
      <Seo title={'마이페이지 아이템'} />
      <div className="snb_wrap">
        <ul>
          <li>
            <Link
              to={`${location.pathname}?tab=myItem`}
              className={clsx('item', tab === 'myItem' && 'selected')}
            >
              내아이템
            </Link>
          </li>
          <li>
            <Link
              to={`${location.pathname}?tab=giftList`}
              className={clsx('item', tab === 'giftList' && 'selected')}
            >
              선물내역
            </Link>
          </li>
        </ul>
      </div>
      <CategoryList>
        {categoryList.map((item, key) => {
          return (
            <li key={key}>
              <Link
                to={`${location.pathname}?tab=${tab}&category=${item.key}`}
                className={clsx(category === item.key && 'selected')}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </CategoryList>
      <div style={{ clear: 'both' }}>
        <Component
          history={history}
          category={category}
          tab={tab}
          page={page}
        />
      </div>
    </>
  );
});

export default Item;
