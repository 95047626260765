import React from 'react';
import {Url} from "../../common/constants/environment";

const BoardReportHtml = () => {
  return (
    <>
      {/* board_wrap */}
      <div className="board_wrap">

        {/* board_view */}
        <div className="board_view">

          {/* view_area */}
          <div className="view_area">
            <h3 className="blind">게시글</h3>
            <div className="summary">
              <a className="category" href="javascript:;">유머</a>
              <p className="subject">소개팅에서 인생영화가 뭐냐는 질문을 받는다화가 뭐냐는 질문을 받는다면 당신은</p>
              <p className="article_info">
                <span className="date">2020.09.01 00:00</span>
                <span className="count">조회 386</span>
              </p>
              <div className="user_info">
                <a className="lnk_user" href="javascript:;">
                  <span className="thumb lv17">
                      <img src="/images/thumb_user_def.png"/>
                      <svg
                          className="border"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 36 36"
                          enable-background="new 0 0 36 36"
                          xmlSpace="preserve"
                      >
                      <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                      </svg>
                  </span>
                  <strong className="name">건대히어로</strong>
                  <span className="today">오늘의 한마디를한마디를한마디를한마디를</span>
                </a>
                <button type="button" className="btn_option">옵션</button>
                <div className="ly_option">
                  <ul>
                    <li><a className="lnk" href="javascript:;">신고하기</a></li>
                    <li><a className="lnk" href="javascript:;">URL 복사</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="article_cont">
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
              <p><img src={Url.thumb + "normal/resize/origin/file/photo/editor/2011/7ce923e44caf4d53e26853420c2d1d3a_cQZRMgW18Sp3uVRo7bFeQKIzr3uW.jpg"}/></p>
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
            </div>
          </div>
          {/* // view_area */}

        </div>
        {/* // board_view */}


        {/* ly_report */}
        <div className="ly_report">
          <div className="inner">
            <h5 className="tit">신고하기</h5>
            <ul className="list">
              <li>
                <input type="radio" id="chk_report1" name="chk_report" className="radio blind" autoComplete="off"/>
                <label htmlFor="chk_report1" className="radio_label">욕설/비방글</label>
              </li>
              <li>
                <input type="radio" id="chk_report2" name="chk_report" className="radio blind" autoComplete="off"/>
                <label htmlFor="chk_report2" className="radio_label">홍보/상업성 게시글</label>
              </li>
              <li>
                <input type="radio" id="chk_report3" name="chk_report" className="radio blind" autoComplete="off"/>
                <label htmlFor="chk_report3" className="radio_label">기타</label>
              </li>
            </ul>
            <div className="report_cont">
              <textarea placeholder="신고내용을 작성해주세요."></textarea>
            </div>
            <div className="btn_area">
              <button type="button" className="btn_cancel">취소</button>
              <button type="button" className="btn_submit">신고</button>
            </div>
            <button type="button" className="btn_close">닫기</button>
          </div>
        </div>
        {/* // ly_report */}

      </div>
      {/* // board_wrap */}
    </>
  );
};

export default BoardReportHtml;
