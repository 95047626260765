import { observable, computed, action, runInAction } from 'mobx';
import { asyncAction } from 'mobx-utils';
import autobind from 'autobind-decorator';
import LolModel from './model';
import LolSetModel from './model/set';
import LolPlayerModel from './model/player';
import { sortPlayers } from './util';
import LolBanModel from './model/ban';
import { setEsportsDefaultList } from '../../shared/utils';
import { timeUtils } from '../../../../common/utils/timeUtil';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import WebSocketUtil from '../../../../common/utils/socketUtil';
import { ScoreUtil } from '../../../../common/utils/scoreUtil';
import { ScoreService } from '../../../../common/services';
import AbstractScoreCommon from '../../store/common';

@autobind
class LolStore extends AbstractScoreCommon {
  localstorage = new useLocalStorage();
  socket = null;
  isSub = false;

  @observable
  list = [];

  @observable
  loading = false;

  constructor() {
    super();
  }

  connectSocket(topic) {
    this.socket = new WebSocketUtil(topic);

    this.socket.ws.onmessage = (event) => {
      if (event) {
        let data = event.data;

        try {
          if (data === 'OK') {
            this.isSub = true;
          } else {
            let messageData = JSON.parse(data);

            if (messageData) {
              const { TOPIC, MESSAGE } = messageData;

              if (TOPIC.includes('lol')) {
                const data = JSON.parse(MESSAGE);
                const copy = [...this.list.toJS()];
                let index = null;

                if (data.hasOwnProperty('gidx')) {
                  index = copy.findIndex((item) => item.id === data.gidx);
                }

                if (TOPIC === `esports.lol.games`) {
                  this.list[index].updateGame(data);
                }

                if (TOPIC === 'esports.lol.period') {
                  this.list[index].updatePeriod(data);
                }

                if (TOPIC === `sports.lol.cheers`) {
                  index = copy.findIndex((item) => item.id == data.game_id);
                  if (Boolean(~index)) {
                    this.list[index].cheerCount++;
                  }
                }
              }
            }
          }
        } catch (error) {
          console.log('=== socket on message error ===', error);
        }
      }
    };
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get lolList() {
    const list = this.list.toJS();

    let copy = [...list];

    const sortedGames = ScoreUtil.eSportsSortList(copy);

    return setEsportsDefaultList(sortedGames);
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  closeSocket() {
    this.socket.close(() => {
      runInAction(() => {
        this.isSub = false;
        this.socket = null;
      });
    });
  }

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  *getLol(date) {
    this.loading = true;

    try {
      // TODO: 웹소켓;
      if (!this.isSub) {
        yield this.connectSocket([
          'esports.lol.games',
          'esports.lol.period',
          'sports.lol.cheers'
        ]);
      }

      const result = yield ScoreService.getEsportsTypeGames(date, 'ALL', 'lol');

      if (result) {
        this.list = result.map((item) => {
          const deep = { ...item };

          if (deep.sets.length > 0) {
            deep.sets = item.sets.map((set) => {
              let setDeep = { ...set };

              // 블루 player 모델 적용
              if (set.away.players.length > 0) {
                setDeep.away.players = sortPlayers(set.away.players).map(
                  (item) => new LolPlayerModel(item, 'away')
                );
              }

              // 레드 player 모델 적용
              if (set.home.players.length > 0) {
                setDeep.home.players = sortPlayers(set.home.players).map(
                  (item) => new LolPlayerModel(item, 'home')
                );
              }

              setDeep.away.banPicks = set.away.banPicks.map((ban) => {
                if (!!ban) {
                  return new LolBanModel(ban, 'away');
                } else {
                  return null;
                }
              });

              setDeep.home.banPicks = set.home.banPicks.map((ban) => {
                if (!!ban) {
                  return new LolBanModel(ban, 'away');
                } else {
                  return null;
                }
              });

              return new LolSetModel(setDeep);
            });
          }

          deep['id'] = item.gidx;

          return new LolModel(deep);
        });

        this.loading = false;
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export default LolStore;
