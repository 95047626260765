import React from 'react';

const MypageLeaveHtml = () => {
  return (
    <>

      {/* leave_wrap */}
      <div className="leave_wrap">
        <div className="leave_info">
          <dl>
            <dt>사용하고 계신 계정(khjung1@bss.co.kr)은 탈퇴할 경우 재사용 및 복구가 불가능합니다.</dt>
            <dd>탈퇴한 계정은 본인과 타인 모두 재사용 및 복구가 불가하오니 신중하게 선택하시기 바랍니다.</dd>
            <dd><strong>추가 회원가입은 탈퇴일로부터 30일 후에 가능합니다.</strong></dd>
          </dl>
          <dl>
            <dt>회원정보 및 개인형 서비스 이용기록은 모두 삭제 됩니다.</dt>
            <dd>회원정보 및 포인트, 캐쉬, 친구, 즐겨찾기등 개인형 서비스 이용기록은 모두 삭제되며, 삭제된 데이터는 복구되지 않습니다.</dd>
            <dd>삭제되는 내용을 확인하시고 필요한 데이터는 미리 백업을 해주세요.</dd>
          </dl>
          <dl>
            <dt>탈퇴 후 게시판형 서비스에 등록한 게시물은 삭제되지 않고 유지됩니다.</dt>
            <dd>커뮤니티, 인포존 등 게시판에 등록한 댓글 및 게시물은 탈퇴 시에도 유지됩니다.</dd>
          </dl>
          <dl>
            <dt>탈퇴하기 전 이메일 인증이 필요합니다.</dt>
            <dd>이메일 인증을 통해 탈퇴가 진행되며 위 내용에 동의하신 경우 아래 버튼을 이용하여 진행하시면 됩니다.</dd>
          </dl>
        </div>
        <button type="submit" className="btn_submit">위 항목에 동의 및 탈퇴</button>
      </div>
      {/* // leave_wrap */}

    </>
  );
};

export default MypageLeaveHtml;
