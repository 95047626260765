import Api from '../api';
import { Url } from '../../constants/environment';
import Cookie from '../../utils/cookie';

class MemoService extends Api {
  constructor() {
    super();
  }

  /**
   * @description get list memo
   * @returns
   */
  async getMemo() {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, 'memo', {
      type: 'recv',
      mb_id: Cookie.getCookie('mb_id')
    });

    return res;
  }

  /**
   * @description Delete all memos
   * @param {*} memoIds
   * @returns
   */
  async deleteMemoByIds(memoIds) {
    const res = await this.commonAjax('delete', Url.named, `memo`, {
      type: 'recv',
      mb_id: Cookie.getCookie('mb_id'),
      me_id: memoIds && Array.isArray(memoIds) ? memoIds.join(',') : ''
    });

    return res;
  }
}

export default new MemoService();
