import React from 'react';
import {Url} from "../../common/constants/environment";

const ScoreCastLolHtml = () => {
  return (
    <>

      {/* score_detail_wrap */ }
      <div className="score_detail_wrap"> {/* 클래스 상태 : small_view, ready, close */}

        <div className="summary">
          <button className="btn_prev">이전으로</button>
          <h2 className="tit">2020 ASL S10</h2>
          <button className="btn_oc">크게보기/작게보기</button>
        </div>

        {/* score_detail_board */ }
        <div className="score_detail_board lol"> {/* 클래스 종목 : soccer, basketball ---- 종목별 이름 */}

          {/* summary_score */ }
          <div className="summary_score">
            <div className="team left">
              <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/starcraft/player/729_1603782633.png"}/>
                <span className="name">바로 셀로로</span>
                <span className="player">디에고 카스틸로</span>
            </div>
            <div className="info">
              <div className="score">
                <strong className="left on">
                  <em className="num">0</em>
                </strong>
                <strong className="right">
                  <em className="num">0</em>
                </strong>
              </div>
              <div className="state"><span>3세트 30:20</span></div>
              <div className="time">10.22 09:00</div>
              <div className="stadium">푸동 스타디움</div>
            </div>
            <div className="team right"><img className="logo" src={Url.thumb + "normal/resize/0x55/sports/lol/team/222_1586423078.png"}/>
              <div className="name">바로 셀로나</div>
              <span className="player">디에고 카스틸로</span>
            </div>
          </div>
          {/* // summary_score */ }

        </div>
        {/* // score_detail_board */ }

        {/* snb_wrap */ }
        <div className="snb_wrap">
          <ul>
            <li><a className="item" href="javascript:;">응원</a></li>
            <li><a className="item selected" href="javascript:;">중계</a></li>
          </ul>
        </div>
        {/* // snb_wrap */ }

        {/* lol_cast */ }
        <div className="lol_cast">
          <div className="tab">
            <ul>
              <li>
                <button type="button">1세트</button>
              </li>
              <li>
                <button type="button">2세트</button>
              </li>
              <li>
                <button type="button" className="selected">3세트</button>
              </li>
              <li>
                <button type="button" disabled>4세트</button>
              </li>
              <li>
                <button type="button" disabled>5세트</button>
              </li>
            </ul>
          </div>
          <div className="detail">
            <div className="info">
              <div className="score">
                <em className="on">20</em>
                <em className="vs">:</em>
                <em>8</em>
              </div>
              <span className="time">30:20</span>
            </div>
            <div className="team left">
              <div className="name">
                <div className="tx">EDward Gaming</div>
                <span className="win">승</span>
              </div>
              <div className="stats">
                <dl>
                  <dt>골드</dt>
                  <dd>83.4k</dd>
                </dl>
                <dl>
                  <dt>포탑</dt>
                  <dd>9</dd>
                </dl>
                <dl>
                  <dt>바론</dt>
                  <dd>5</dd>
                </dl>
                <dl>
                  <dt>용</dt>
                  <dd>8</dd>
                </dl>
              </div>
              <div className="special">
                <span className="on">킬</span>
                <span className="on">포탑</span>
                <span>용</span>
                <span>바론</span>
                <span>10킬</span>
              </div>
              <div className="pick">
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Leblanc.png"}/>
                  <span className="champ">아펠리오스</span>
                  <span className="player">Faker</span>
                  <span className="kda">1/3/6</span>
                </div>
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Ornn.png"}/>
                  <span className="champ">오리아나</span>
                  <span className="player">Xiaohu</span>
                  <span className="kda">1/3/6</span>
                </div>
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                  <span className="champ">그레이브즈</span>
                  <span className="player">GALA</span>
                  <span className="kda">1/3/6</span>
                </div>
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                  <span className="champ">그레이브즈</span>
                  <span className="player">GALA</span>
                  <span className="kda">1/3/6</span>
                </div>
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                  <span className="champ">그레이브즈</span>
                  <span className="player">GALA</span>
                  <span className="kda">1/3/6</span>
                </div>
              </div>
              <div className="ban">
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Leblanc.png"}/>
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
              </div>
            </div>
            <div className="team right">
              <div className="name">
                <div className="tx">Royal Never Give Up</div>
                <span className="lose">패</span>
              </div>
              <div className="stats">
                <dl>
                  <dt>골드</dt>
                  <dd>83.4k</dd>
                </dl>
                <dl>
                  <dt>포탑</dt>
                  <dd>9</dd>
                </dl>
                <dl>
                  <dt>바론</dt>
                  <dd>5</dd>
                </dl>
                <dl>
                  <dt>용</dt>
                  <dd>8</dd>
                </dl>
              </div>
              <div className="special">
                <span>킬</span>
                <span>포탑</span>
                <span className="on">용</span>
                <span className="on">바론</span>
                <span>10킬</span>
              </div>
              <div className="pick">
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Leblanc.png"}/>
                  <span className="champ">아펠리오스</span>
                  <span className="player">Faker</span>
                  <span className="kda">1/3/6</span>
                </div>
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Ornn.png"}/>
                  <span className="champ">오리아나</span>
                  <span className="player">Xiaohu</span>
                  <span className="kda">1/3/6</span>
                </div>
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                  <span className="champ">그레이브즈</span>
                  <span className="player">GALA</span>
                  <span className="kda">1/3/6</span>
                </div>
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                  <span className="champ">그레이브즈</span>
                  <span className="player">GALA</span>
                  <span className="kda">1/3/6</span>
                </div>
                <div className="item">
                  <img className="champ_img" src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                  <span className="champ">그레이브즈</span>
                  <span className="player">GALA</span>
                  <span className="kda">1/3/6</span>
                </div>
              </div>
              <div className="ban">
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Leblanc.png"}/>
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
                <img src={Url.thumb + "normal/resize/57x57/sports/lol/champ/Shen.png"}/>
              </div>
            </div>
          </div>
        </div>
        {/* // lol_cast */ }

      </div>
      {/* // score_detail_wrap */ }

    </>
  );
};

export default ScoreCastLolHtml;
