import React from 'react';

const MypageCashHtml = () => {
  return (
    <>

      <div className="snb_wrap">
        <ul>
          <li><a className="item selected" href="javascript:;">충전내역</a></li>
          <li><a className="item" href="javascript:;">사용내역</a></li>
        </ul>
      </div>

      {/* history_wrap */}
      <div className="history_wrap cash">
        <div className="summary">
          <dl className="amass">
            <dt>보유 캐시</dt>
            <dd>3,400,000</dd>
          </dl>
          <dl className="today">
            <dt>오늘 충전 캐시</dt>
            <dd>5,400</dd>
          </dl>
        </div>
        <div className="list_area">
          <div className="tip_area">
            <p className="tx">※ 최대 3개월 이내의 내역만 확인 가능합니다.</p>
            <button type="button" className="btn_charge">충전하기</button>
          </div>
          <div className="list charge">
            <div className="item">
              <span className="state">결제<br/>대기</span>
              <span className="info">
                <em className="tx">게임문화상품권</em>
                <em className="price">결제금액 : 10,000원</em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
              <span className="num">
                <em className="amount">1,500,000</em>
                <em className="bonus">+0</em>
              </span>
            </div>
            <div className="item">
              <span className="state">입금<br/>대기</span>
              <span className="info">
                <em className="tx">무통장입금<button type="button" className="btn_lnk">(계좌안내)</button></em>
                <em className="price">결제금액 : 10,000원</em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
              <span className="num">
                <em className="amount">2,500,000</em>
                <em className="bonus">+250,000</em>
              </span>
              <div className="layer_account_info" style={{display:'none'}}>
                <dl>
                  <dt>입금 계좌 안내</dt>
                  <dd>은행명 : 기업은행</dd>
                  <dd>예금주 : (주)비에스에스스포츠</dd>
                  <dd>계좌번호 : 001-596801-01-015</dd>
                </dl>
                <button type="button" className="btn_close">닫기</button>
              </div>
            </div>
            <div className="item">
              <span className="state complete">결제<br/>완료</span>
              <span className="info">
                <em className="tx">무통장입금<button type="button" className="btn_lnk">(현금영수증)</button></em>
                <em className="price">결제금액 : 10,000원</em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
              <span className="num">
                <em className="amount">2,500,000</em>
                <em className="bonus">+250,000</em>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* // history_wrap */}

    </>
  );
};

export default MypageCashHtml;
