import React, { useEffect, useState } from 'react';
import RoundTable from '../../ladder/analysis/roundTable/index';
import { LotteryService } from '../../../../../common/services';

const SpeedLadderResult = ({ type }) => {
  const [data, setData] = useState();

  useEffect(() => {
    LotteryService.getMiniRecentList('speed_ladder').then((res) => {
      setData(res.data);
    });

    return () => {};
  }, []);

  return <RoundTable list={data} title={'회차별 결과'} size={20} />;
};

export { SpeedLadderResult };
