import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import useGameStore from '../../../../../hooks/useGameStore';
import { observer } from 'mobx-react';
import { PickComment } from '../../pickComment';
import useStore from '../../../../../hooks/useStore';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import useModalStore from '../../../../../hooks/useModalStore';

let todayDate = timeUtils.dateFormatYmd(timeUtils.todayYmd());

const RunningballPick = observer(({ brandName, gameName }) => {
  const [tab, setTab] = useState('dist');
  const [option, setOption] = useState('all');
  const gameStore = useGameStore();
  const { clockStore } = useStore();
  const [currentRound, setCurrentRound] = useState(0);
  const [pickClass, setPickClass] = useState('runningball_odd');
  const [pickComment, setPickComment] = useState();
  const modalStore = useModalStore();
  const [pick, setPick] = useState('odd');
  const [pickType, setPickType] = useState("runningball_odd_even");
  const [commentIndex, setCommentIndex] = useState('0');

  const getGameTypeParameter = () => {
    switch(gameName){
      case "space8" :
        return "space8";
      case "runningball5_6" :
        return "running_ball6";
      case "runningball5_4" :
        return "running_ball4";
      case "runningball3" :
        return "running_ball2";

    }
  };

  const getPickType = (option)=>{
    if(option === 'ball3_odd_even') return 'sum_odd_even';
    else if(option === 'ball3_under_over') return 'sum_under_over';
    else if(option === 'odd_even') return 'runningball_odd_even';
    else if(option === 'under_over') return 'runningball_under_over';
    else return null;
  }

  useEffect(() => {
    let interval = null;
    if (currentRound !== 0) {
      interval = setInterval(() => {
        gameStore.getLotteryPickList({
          gameType: getGameTypeParameter(),
          pickType: option === 'all' ? null : option,
          limit: 10,
          offset: 0,
          date: timeUtils.dateFormatYmd(todayDate),
          round: currentRound
        });
      }, 1500);

      gameStore.getLotteryPickCommentList({
        gameType: getGameTypeParameter(),
        pickType: getPickType(option),
        limit: 10,
        offset: 0,
        concat: false,
        date: timeUtils.dateFormatYmd(todayDate),
        round: currentRound
      });
    }
    return () => {
      clearInterval(interval);
    };
  }, [tab, option, currentRound]);

  useEffect(() => {
    if(gameName==='runningball3'){
      clockStore.getRunningball2Round().then((res) => {
        setCurrentRound(res.nextRound);
      });
    }else{
      clockStore.getRunningball6Round().then((res) => {
        setCurrentRound(res.nextRound);
      });
    }
  }, []);

  const getRoundDate = () => {
    return timeUtils.dateFormatCalendarYmd(todayDate) + ' - ' + currentRound;
  };

  const onClickPreRound = () => {
    if (currentRound === 1) {
      todayDate = timeUtils.dateFormatCalendarYmd(
        timeUtils.datePrevOrNext(todayDate, 'prev')
      );
      setCurrentRound(264);
    } else {
      setCurrentRound(currentRound - 1);
    }
  };

  const onClickNextRound = () => {
    clockStore.getPowerRound().then((res) => {
      if (
        todayDate === timeUtils.dateFormatCalendarYmd(timeUtils.todayYmd()) &&
        currentRound === res.nextRound
      ) {
        return;
      } else {
        if (currentRound === 264) {
          todayDate = timeUtils.dateFormatCalendarYmd(
            timeUtils.datePrevOrNext(todayDate, 'next')
          );
          setCurrentRound(1);
        }  else {
          setCurrentRound(currentRound + 1);
        }
      }
    });
  };
  const runningballSectionDisplay = (index) => {
    if (index !== option && option !== 'all') {
      return 'none';
    }
  };

  const onSetPickComment = (e) => {
    setCommentIndex(e.value);
    setPickComment(e[e.selectedIndex].text);
    e.selectedIndex === 0 && setPickComment();
  };

  const insertPickComment = () => {
    if (pickComment === undefined) {
      modalStore.openAlertModal({
        message: '픽 메세지를 선택해 주세요'
      });
      return;
    }

    gameStore.insertLotteryPickComment({
      gameType: getGameTypeParameter(),
      pickType: pickType,
      pick: pick,
      pickComment: pickComment
    });
  };

  const onSetPick = (pickClass, pickType, pick) => {
    setPickClass(pickClass);
    setPick(pick);
    setPickType(pickType);
  };
  return (
    <div className="game_stats_area">
      {/* option_area */}
      <div className="option_tab">
        <ul>
          <li>
            <a
              onClick={() => setTab('dist')}
              className={clsx('item', tab === 'dist' && 'selected')}
            >
              픽분포도
            </a>
          </li>
          <li>
            <a
              onClick={() => setTab('reg')}
              className={clsx('item', tab === 'reg' && 'selected')}
            >
              픽등록
            </a>
          </li>
        </ul>
      </div>
      {tab === 'dist' && (
        <>
          <div className="option_area">
            <div className="date_area">
              <Link
                className="btn_prev"
                onClick={() => onClickPreRound()}
                to={`${location.pathname}?tab=pick`}
              >
                이전 날짜
              </Link>
              <strong className="date">{getRoundDate()}</strong>
              <Link
                className="btn_next"
                onClick={() => onClickNextRound()}
                to={`${location.pathname}?tab=pick`}
              >
                다음 날짜
              </Link>
            </div>
            <div className="option_type">
              <select
                value={option}
                onChange={(e) => setOption(e.target.value)}
              >
                <option value="all">전체</option>
                <option value="odd_even">1등 홀짝</option>
                {gameName !== 'runningball3' &&
                  <>
                  <option value="under_over">1등 언오버</option>
                  <option value="ball3_odd_even">123등 합 홀짝</option>
                  <option value="ball3_under_over">123등 합 언오버</option>
                  </>
                }
              </select>
            </div>
          </div>
          {/* // option_area */}

          {/* date_detail_wrap */}
          <div className="date_detail_wrap">
            {/* data_summary */}
            <div className="data_summary">
              <h3 className="tit">전체 픽 분포도</h3>
              <div
                className="chart"
                style={{
                  display: runningballSectionDisplay('odd_even')
                }}
              >
                <dl
                  className={clsx(
                    'bar ODD',
                    gameStore.data.dist.odd_percent > '50' && 'on'
                  )}
                >
                  <dt>1등 홀</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.odd_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.odd_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar EVEN',
                    gameStore.data.dist.even_percent > '50' && 'on'
                  )}
                >
                  <dt>1등 짝</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.even_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.even_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              {gameName !== 'runningball3' &&
                  <>
                  <div
                      className="chart"
                      style={{
                        display: runningballSectionDisplay('under_over')
                      }}
                  >
                    <dl
                        className={clsx(
                            'bar UNDER',
                            gameStore.data.dist.under_percent > '50' && 'on'
                        )}
                    >
                      <dt>1등 언더</dt>
                      <dd>
                    <span className="num">
                      {gameStore.data.dist.under_count}
                    </span>
                        <span className="per">
                      ({gameStore.data.dist.under_percent}%)
                    </span>
                      </dd>
                    </dl>
                    <dl
                        className={clsx(
                            'bar OVER',
                            gameStore.data.dist.over_percent > '50' && 'on'
                        )}
                    >
                      <dt>1등 오버</dt>
                      <dd>
                    <span className="num">
                      {gameStore.data.dist.over_count}
                    </span>
                        <span className="per">
                      ({gameStore.data.dist.over_percent}%)
                    </span>
                      </dd>
                    </dl>
                  </div>
                <div
                className="chart"
                style={{ display: runningballSectionDisplay('ball3_odd_even') }}
            >
              <dl
                  className={clsx(
                      'bar ODD',
                      gameStore.data.dist.ball3_odd_percent > '50' && 'on'
                  )}
              >
                <dt>123등 합 홀</dt>
                <dd>
                    <span className="num">
                      {gameStore.data.dist.ball3_odd_count}
                    </span>
                  <span className="per">
                      ({gameStore.data.dist.ball3_odd_percent}%)
                    </span>
                </dd>
              </dl>
              <dl
                  className={clsx(
                      'bar EVEN',
                      gameStore.data.dist.ball3_even_percent > '50' && 'on'
                  )}
              >
                <dt>123등 합 짝</dt>
                <dd>
                    <span className="num">
                      {gameStore.data.dist.ball3_even_count}
                    </span>
                  <span className="per">
                      ({gameStore.data.dist.ball3_even_percent}%)
                    </span>
                </dd>
              </dl>
            </div>
            <div
                className="chart"
                style={{ display: runningballSectionDisplay('ball3_under_over') }}
            >
              <dl
                  className={clsx(
                      'bar UNDER',
                      gameStore.data.dist.ball3_under_percent > '50' && 'on'
                  )}
              >
                <dt>123등 합 언더</dt>
                <dd>
                    <span className="num">
                      {gameStore.data.dist.ball3_under_count}
                    </span>
                  <span className="per">
                      ({gameStore.data.dist.ball3_under_percent}%)
                    </span>
                </dd>
              </dl>
              <dl
                  className={clsx(
                      'bar OVER',
                      gameStore.data.dist.ball3_over_percent > '50' && 'on'
                  )}
              >
                <dt>123등 합 오버</dt>
                <dd>
                    <span className="num">
                      {gameStore.data.dist.ball3_over_count}
                    </span>
                  <span className="per">
                      ({gameStore.data.dist.ball3_over_percent}%)
                    </span>
                </dd>
              </dl>
            </div>
            </>
            }
            </div>
            {/* // data_summary */}

            {/* pick_list_wrap */}
            <div className="pick_list_wrap">
              <h3 className="tit">유저의 픽</h3>
              <div className="pick_list">
                <PickComment
                  gameType={getGameTypeParameter()}
                  pickType={option}
                  items={gameStore.data.comment.list}
                  totalCnt={gameStore.data.comment.total_cnt}
                  date={timeUtils.dateFormatYmd(todayDate)}
                  round={currentRound}
                />
              </div>
            </div>
            {/* // pick_list_wrap */}
          </div>
          {/* // date_detail_wrap */}
        </>
      )}
      {tab === 'reg' && (
        <>
          {/* pick_register_wrap */}
          <div className="pick_register_wrap">
            {/* pick_select */}
            <div className="pick_select">
              <h3 className="tit">픽 선택</h3>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    'ODD',
                    pickClass === 'runningball_odd' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('runningball_odd', 'runningball_odd_even', 'odd')
                  }
                >
                  <span className="tx">1등 홀</span>
                  <span className="per">
                    {gameStore.data.dist.odd_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'EVEN',
                    pickClass === 'runningball_even' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('runningball_even', 'runningball_odd_even', 'even')
                  }
                >
                  <span className="tx">1등 짝</span>
                  <span className="per">
                    {gameStore.data.dist.even_percent}%
                  </span>
                </button>
              </div>
              {gameName !== 'runningball3' &&
                  <>
                    <div className="item">
                      <button
                          type="button"
                          className={clsx(
                              'UNDER',
                              pickClass === 'runningball_under' && 'selected'
                          )}
                          onClick={() =>
                              onSetPick(
                                  'runningball_under',
                                  'runningball_under_over',
                                  'under'
                              )
                          }
                      >
                        <span className="tx">1등 언더</span>
                        <span className="per">
                    {gameStore.data.dist.under_percent}%
                  </span>
                      </button>
                      <button
                          type="button"
                          className={clsx(
                              'OVER',
                              pickClass === 'runningball_over' && 'selected'
                          )}
                          onClick={() =>
                              onSetPick('runningball_over', 'runningball_under_over', 'over')
                          }
                      >
                        <span className="tx">1등 오버</span>
                        <span className="per">
                    {gameStore.data.dist.over_percent}%
                  </span>
                      </button>
                    </div>
                    <div className="item">
                      <button
                          type="button"
                          className={clsx('ODD', pickClass === 'sum_odd' && 'selected')}
                          onClick={() => onSetPick('sum_odd', 'sum_odd_even', 'odd')}
                      >
                        <span className="tx">123등 합 홀</span>
                        <span className="per">
                    {gameStore.data.dist.ball3_odd_percent}%
                  </span>
                      </button>
                      <button
                          type="button"
                          className={clsx(
                              'EVEN',
                              pickClass === 'sum_even' && 'selected'
                          )}
                          onClick={() => onSetPick('sum_even', 'sum_odd_even', 'even')}
                      >
                        <span className="tx">123등 합 짝</span>
                        <span className="per">
                    {gameStore.data.dist.ball3_even_percent}%
                  </span>
                      </button>
                    </div>
                    <div className="item">
                      <button
                          type="button"
                          className={clsx(
                              'UNDER',
                              pickClass === 'sum_under' && 'selected'
                          )}
                          onClick={() =>
                              onSetPick('sum_under', 'sum_under_over', 'under')
                          }
                      >
                        <span className="tx">123등 합 언더</span>
                        <span className="per">
                    {gameStore.data.dist.ball3_under_percent}%
                  </span>
                      </button>
                      <button
                          type="button"
                          className={clsx(
                              'OVER',
                              pickClass === 'sum_over' && 'selected'
                          )}
                          onClick={() =>
                              onSetPick('sum_over', 'sum_under_over', 'over')
                          }
                      >
                        <span className="tx">123등 합 오버</span>
                        <span className="per">
                          {gameStore.data.dist.ball3_over_percent}%
                        </span>
                      </button>
                    </div>
                  </>
              }
            </div>
            {/* // pick_select */}

            {/* pick_message */}
            <div className="pick_message">
              <h3 className="tit">픽 메시지</h3>
              <div className="message_type">
                <select
                  value={commentIndex}
                  onChange={(e) => {
                    onSetPickComment(e.target);
                  }}
                >
                  <option value="0">
                    픽을 선택하신후 간단한 문구를 선택하세요.
                  </option>
                  <option value="1">
                    지금까지 이런 흐름은 없었다. 이것은 신인가 컴퓨터인가?
                  </option>
                  <option value="2">
                    펭하~! 펭수도 이런 흐름에는 고민없이 갑니다.
                  </option>
                  <option value="3">
                    신사답게 행동해~! 묻고 흐름타서 떠블로 가!
                  </option>
                  <option value="4">
                    니 내가 누군지 아니? 내 런닝볼 장인이야!
                  </option>
                  <option value="5">
                    런닝볼 숫자 적중은 네임드! 언오버 적중도 네!임!드!
                  </option>
                  <option value="6">
                    런닝볼 숫자는 지리구요. 언오버는 오지구요.
                  </option>
                  <option value="7">
                    언오버를 지배하는 자가 런닝볼을 지배합니다.
                  </option>
                  <option value="8">제 픽은 정말 현명한 선택이에요.</option>
                  <option value="9">
                    어디로 갈지 망설이지 말고 저를 전적으로 믿으셔야합니다.
                  </option>
                  <option value="10">이안에 픽있다. 런닝볼아 가자~!</option>
                </select>
              </div>
              <button
                type="submit"
                className="btn_submit"
                onClick={() => insertPickComment()}
              >
                픽등록하기
              </button>
            </div>
            {/* // pick_message */}
          </div>
          {/* // pick_register_wrap */}
        </>
      )}
    </div>
  );
});

export { RunningballPick };
