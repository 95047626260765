import Api from '../api';
import autobind from 'autobind-decorator';
import { Url } from '../../constants/environment';

@autobind
class ScoreService extends Api {
  constructor() {
    super();
  }

  /**
   * @description 분석글 작성시 경기 리스트
   * @returns
   */
  getSportsTodayReadyGames(sportsId) {
    const sportsList = {
      1: { id: 1, url: '/sports/soccer/today-games' },
      2: { id: 2, url: '/sports/baseball/games' },
      3: { id: 3, url: '/sports/basketball/games' },
      4: { id: 4, url: '/sports/volleyball/games' },
      5: { id: 5, url: '/sports/hockey/games' },
      7: { id: 7, url: '/sports/football/games' },
      9: { id: 9, url: '/esports/lol/games' },
      6: { id: 6, url: '/esports/star/games' }
    };
    const sportsInfo = sportsList[sportsId];

    return this.ajax('get', Url.sports, `${sportsInfo.url}?status=READY`);
  }

  /**
   * @description 스코어 프로토 리스트 데이터 요청
   * @returns
   */
  getListOfProtoWin(roundId) {
    const param = !!roundId ? `?round-id=${roundId}` : '';
    return this.sportsAjax(
      'get',
      Url.legacySports,
      `/odds/proto/win${param}`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} date
   * @param {*} status
   * @param {*} type
   * @returns
   */
  getSportsTypeGames(date, status, type) {
    let statusParam = '';

    if (!!status) {
      statusParam = `&status=${status}`;
    }

    return this.ajax(
      'get',
      Url.sports,
      `/sports/${type}/games?date=${date}${statusParam}`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @returns
   */
  getSportsSoccerTodayGames() {
    return this.ajax('get', Url.sports, `/sports/soccer/today-games`).then(
      (res) => {
        if (res.status === 200) return res.data;
      }
    );
  }

  /**
   *
   * @param {*} sportsType
   * @param {*} month
   * @param {*} year
   * @returns
   */
  getGameCountCalendar(sportsType, month, year) {
    let url = `/sports/${sportsType}/calendar/game-counts?month=${month}&year=${year}`;

    if (sportsType === 'major') {
      url = `/popular-games/calendar/game-counts?month=${month}&year=${year}`;
    }

    return this.ajax('get', Url.sports, url).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 주요경기를 불러온다
   * @param {*} date 조회를 원하는 날짜 (yyyy-MM-dd)
   * @returns
   */
  getPopularGames(date) {
    return this.ajax('get', Url.sports, `/popular-games?date=${date}`).then(
      (res) => {
        if (res.status === 200) return res.data;
      }
    );
  }

  // /**
  //  * @description 오드사 데이터
  //  * @param {*} gameId
  //  * @returns
  //  */
  // getOddsList(gameId) {
  //   return this.ajax('get', URL.apiUrl, `/schedule/games/${gameId}/odds`).then(
  //     (res) => {
  //       if (res.status === 200) return res.data;
  //     }
  //   );
  // }

  /**
   * @description 오드 히스토리
   * @param {*} gameId
   * @returns
   */
  getOddsHistory(oddsId) {
    return this.ajax('get', Url.sports, `/sports/odds/${oddsId}/history`).then(
      (res) => {
        if (res.status === 200) return res.data;
      }
    );
  }

  /**
   * @description 오드 히스토리 타임라인
   * @param {*} oddsId
   * @returns
   */
  getOddsTimeLine(oddsId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/odds/${oddsId}/odds-timeline`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 축구 오늘날짜 종료 경기 카운트
   * @returns
   */
  getSoccerTodayFinalCount() {
    return this.ajax(
      'get',
      Url.sports,
      '/sports/soccer/today-games/count?status=FINAL'
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 즐겨찾기 축구 리스트
   * @param {*} gameIds
   * @returns
   */
  getSoccerInterestGames(gameIds) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/soccer/interest-games?game-ids=${gameIds}`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description e-sports (lol, star) 리스트
   * @param {*} type
   * @param {*} date
   * @returns
   */
  getEsportsTypeGames(date, status, type) {
    let statusParam = '';

    if (!!status) {
      statusParam = `&status=${status}`;
    }

    return this.ajax(
      'get',
      Url.sports,
      `/esports/${type}/games?date=${date}${statusParam}`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 즐겨찾기 축구 리스트
   * @param loginCheck 로그인 여부
   * @param {*} gameIds
   * @returns
   */
  getInterestGames(loginCheck, gameIds = []) {
    let url = '/interest-games?';
    // if (!loginCheck) {
      gameIds.map((value) => (url = url + `&game-ids=${value}`));
      if (gameIds.length === 0) {
        return null;
      }
    // }
    return this.ajax('get', Url.sports, url, null, loginCheck).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  setInterestGames(gameIds = []) {
    let url = '/interest-games?';
    gameIds.map((value) => (url = url + `&game-ids=${value}`));
    return this.commonAjax('post', Url.sports, url, null, {}, true).then(
      (res) => {
        if (res.status === 200) return res.data;
      }
    );
  }

  deleteInterestGames(gameIds = []) {
    let url = '/interest-games?';
    gameIds.map((value) => (url = url + `&game-ids=${value}`));
    return this.ajax('delete', Url.sports, url, null, true).then((res) => {
      if (res.status === 200) return res.data;
    });
  }
}

export default new ScoreService();
