import clsx from 'clsx';
import React from 'react';

const ProtoMyPickPaperMark = ({ item }) => {
  const { mark } = item;

  return (
    <div className="item">
      <div className="number">
        <span className={clsx(mark.num[0])}>{mark.num[0]}</span>
        <span className={clsx(mark.num[1])}>{mark.num[1]}</span>
        <span className={clsx(mark.num[2])}>{mark.num[2]}</span>
      </div>
      <div className="type">
        <span className={clsx(mark.type)}>{mark.type}</span>
        {/* <span className="cancel">취소</span> */}
      </div>
    </div>
  );
};

export default ProtoMyPickPaperMark;
