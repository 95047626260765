import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

const RoundTable = ({ list = [], title = '회차별 통계' }) => {
  const size = 10;
  const [page, setPage] = useState(1);
  const totalPage = useMemo(() => {
    if (list.length === 0) return 1;
    return Math.ceil(list.length / size);
  }, [list.length]);

  return (
    <div className="data_list_wrap">
      <h3 className="tit">{title}</h3>
      <div className="data_list">
        <div className="table_float">
          <table className="list" border="1">
            <caption>결과리스트</caption>
            <colgroup>
              <col width="74px" />
            </colgroup>
            <thead>
              <tr>
                <th>회차</th>
              </tr>
            </thead>
            <tbody>
              {list.slice(0, size * page).map((item, key) => {
                var className = '';
                if((key+1) % 2 == 0) {
                  className = 'col-even';
                } else {
                  className = 'col-odd';
                }
                return (
                  <tr key={key} className={className}>
                    <td>{`${item.date_round} (${item.round})`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="table_data powerball">
          <table className="list" border="1">
            <caption>결과리스트</caption>
            <colgroup>
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="3">1등</th>
                <th colSpan="4">123등</th>
              </tr>
              <tr>
                <th>결과</th>
                <th>홀짝</th>
                <th>언오버</th>
                <th>결과</th>
                <th>123등 합</th>
                <th>홀짝</th>
                <th>언오버</th>
              </tr>
            </thead>
            <tbody>
              {list.slice(0, size * page).map((item, key) => {
                var className = '';
                if((key+1) % 2 == 0) {
                  className = 'col-even';
                } else {
                  className = 'col-odd';
                }
                return (
                  <tr key={key} className={className}>
                    <td>{item.ball_1}</td>
                    <td>
                      {item.odd_even ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                        <span className={clsx('ic', item.odd_even)}>
                          {item.odd_even === 'ODD' ? '홀' : '짝'}
                        </span> :
                        <span className={clsx('ic', item.runningball_odd_even)}>
                          {item.runningball_odd_even === 'ODD' ? '홀' : '짝'}
                        </span>
                      }
                    </td>
                    <td>
                      {item.unover ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                          <span className={clsx('ic', item.unover)}>
                          {item.unover === 'OVER' ? '오버' : '언더'}
                        </span> :
                          <span className={clsx('ic', item.runningball_under_over)}>
                          {item.runningball_under_over === 'OVER' ? '오버' : '언더'}
                        </span>
                      }
                    </td>
                    <td>
                      <span>{item.ball_1}</span><span>,{item.ball_2}</span>
                      <span>,{item.ball_3}</span><span>,{item.ball_4}</span>
                      { item.ball_5 && (<><span>,{item.ball_5}</span><span>,{item.ball_6}</span></>)}
                    </td>
                    <td>{parseInt(item.ball_1) + parseInt(item.ball_2) + parseInt(item.ball_3)}</td>
                    <td>
                      {item.ball3_odd_even ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                          <span className={clsx('ic', item.ball3_odd_even)}>
                          {item.ball3_odd_even === 'ODD' ? '홀' : '짝'}
                        </span> :
                          <span className={clsx('ic', item.sum_odd_even)}>
                          {item.sum_odd_even === 'ODD' ? '홀' : '짝'}
                        </span>
                      }
                    </td>
                    <td>
                      {item.ball3_unover ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                          <span className={clsx('ic', item.ball3_unover)}>
                          {item.ball3_unover === 'OVER' ? '오버' : '언더'}
                        </span> :
                          <span className={clsx('ic', item.sum_under_over)}>
                          {item.sum_under_over === 'OVER' ? '오버' : '언더'}
                        </span>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <button
        className="btn_more"
        style={page === totalPage ? { display: 'none' } : {}}
        onClick={() => {
          if (page >= totalPage) return;
          setPage(page + 1);
        }}
      >
        더보기 ( {page} / {totalPage} )
      </button>
    </div>
  );
};

export default RoundTable;
