import { observable, computed, action, runInAction, toJS } from 'mobx';
import autobind from 'autobind-decorator';
import AbstractScoreCommon from '../../store/common';
import { ScoreService } from '../../../../common/services';
import SoccerModel from '../../soccer/store/model';
import BaseballModel from '../../baseball/store/model';
import BasketballModel from '../../basketball/store/model';
import VolleyballModel from '../../volleyball/store/model';
import HockeyModel from '../../hockey/store/model';
import FootballModel from '../../football/store/model';
import moment from 'moment';
import { _SPORTS_WS_TOPIC } from '../../../../common/constants/sportsConstants';
import WebSocketUtil from '../../../../common/utils/socketUtil';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import StarcraftSetModel from '../../starcraft/store/model/set';
import StarcraftModel from '../../starcraft/store/model';
import { sortPlayers } from '../../lol/store/util';
import LolPlayerModel from '../../lol/store/model/player';
import LolBanModel from '../../lol/store/model/ban';
import LolSetModel from '../../lol/store/model/set';
import LolModel from '../../lol/store/model';

@autobind
class InterestGameStore extends AbstractScoreCommon {
  localstorage = new useLocalStorage();
  socket = null;
  isSub = false;

  @observable
  list = [];

  @observable
  loading = false;

  @observable
  interestGames = {
    loading: false,
    data: null
  };

  @observable
  sportsView = {
    soccer: [],
    baseball: [],
    basketball: [],
    volleyball: [],
    hockey: [],
    football: [],
    starcraft: [],
    lol: []
  };

  constructor(stores) {
    super();
    this.isLogin = stores.commonStore.loginCheck;
  }

  connectSocket(topic, type = null) {
    this.socket = new WebSocketUtil(topic);

    this.socket.ws.onmessage = (event) => {
      if (event) {
        let data = event.data;

        try {
          if (data === 'OK') {
            this.isSub = true;
          } else {
            let messageData = JSON.parse(data);

            if (messageData) {
              const { TOPIC, MESSAGE } = messageData;

              if (TOPIC.includes('sports')) {
                const data = JSON.parse(MESSAGE);
                const { interestGames } = this;
                const sportsTypeKeyArr = Object.keys(interestGames.data);
                let index = -1;

                sportsTypeKeyArr.forEach((key) => {
                  if (data.hasOwnProperty('gameId')) {
                    index = interestGames.data[key].findIndex(
                      (item) => item.id === data.gameId
                    );
                  }

                  if (TOPIC.includes(key)) {
                    [
                      ..._SPORTS_WS_TOPIC['soccer'],
                      ..._SPORTS_WS_TOPIC['baseball'],
                      ..._SPORTS_WS_TOPIC['basketball'],
                      ..._SPORTS_WS_TOPIC['volleyball'],
                      ..._SPORTS_WS_TOPIC['hockey']
                    ].forEach((item) => {
                      if (TOPIC === `${item.topic}`) {
                        if (Boolean(~index)) {
                          if (TOPIC.includes('games')) {
                            if (key === 'basketball' || key === 'volleyball') {
                              interestGames.data[key][index][item.funcName](
                                data[item.key]
                              );
                            } else {
                              interestGames.data[key][index][item.funcName](
                                data[item.key]
                              );
                            }
                          } else if (TOPIC.includes('period-data')) {
                            if (key === 'basketball' || key === 'volleyball') {
                              interestGames.data[key][index][item.funcName](
                                data[item.key]
                              );
                            } else {
                              interestGames.data[key][index][item.funcName](
                                data[item.key]
                              );
                            }
                          } else {
                            interestGames.data[key][index][item.funcName](
                              data[item.key]
                            );
                          }
                        }
                      }
                    });
                  }
                });
              }
            }
          }
        } catch (error) {
          console.log('=== socket on message error ===', error);
        }
      }
    };
  }

  @action
  closeSocket() {
    if (!!this.socket) {
      this.socket.close(() => {
        runInAction(() => {
          this.isSub = false;
          this.socket = null;
        });
      });
    }
  }

  @computed
  get interestMatches() {
    const result = [];

    if (this.interestGames.data !== null) {
      Object.values(this.interestGames.data).map((typeMatches) => {
        if (typeMatches.length > 0) {
          let dateMatch = null;

          typeMatches.map((match) => {
            const { startDatetime } = match;

            const formattedTime = moment(startDatetime).format('YYYY-MM-DD');

            dateMatch =
              result.find((value) => value.date === formattedTime) || null;

            if (dateMatch !== null) {
              dateMatch.games.push(match);
            } else {
              dateMatch = {
                date: formattedTime,
                games: [match]
              };
              result.push(dateMatch);
            }
          });

          result.sort((a, b) => {
            const aDate = new Date(a.date);
            const bDate = new Date(b.date);
            if (aDate.getTime() > bDate.getTime()) return 1;
            if (aDate.getTime() < bDate.getTime()) return -1;
          });
        }
      });
    }
    return result;
  }

  @action
  async setUserInterestGameByLocalStorage() {
    const localStorageInterest =
      this.localstorage.getItem('selectedGames') || [];
    await ScoreService.setInterestGames([localStorageInterest]);
  }

  @action
  async getInterestList() {
    const selectedGames = this.localstorage.getItem('selectedGames') || [];
    this.interestGames.loading = true;
    const response = await ScoreService.getInterestGames(
      this.isLogin,
      selectedGames
    );

    const result = {};

    try {
      if (!this.isSub) {
        this.connectSocket(
          [
            ..._SPORTS_WS_TOPIC['soccer'],
            ..._SPORTS_WS_TOPIC['baseball'],
            ..._SPORTS_WS_TOPIC['basketball'],
            ..._SPORTS_WS_TOPIC['volleyball'],
            ..._SPORTS_WS_TOPIC['hockey'],
            ..._SPORTS_WS_TOPIC['starcraft'],
            ..._SPORTS_WS_TOPIC['lol']
          ].map((item) => item.topic)
        );
      }

      if (response) {
        Object.values(response).map((typeMatches) => {
          if (typeMatches.length > 0) {
            typeMatches.map((match) => {
              const { sportsType, gtype } = match;
              if (sportsType === undefined && gtype === undefined) {
                return;
              }
              let lowerSportType =
                (sportsType && sportsType.toLowerCase()) || '';

              let modelMappingData = null;
              if (lowerSportType === 'soccer') {
                modelMappingData = new SoccerModel(match);
              } else if (lowerSportType === 'baseball') {
                modelMappingData = new BaseballModel(match);
              } else if (lowerSportType === 'basketball') {
                modelMappingData = new BasketballModel(match);
              } else if (lowerSportType === 'volleyball') {
                modelMappingData = new VolleyballModel(match);
              } else if (lowerSportType === 'icehockey') {
                modelMappingData = new HockeyModel(match);
              } else if (lowerSportType === 'football') {
                modelMappingData = new FootballModel(match);
              } else if (gtype === '6') {
                const deep = { ...match };
                if (deep.set.length > 0) {
                  deep.set = match.set.map((set) => {
                    let setDeep = { ...set };

                    return new StarcraftSetModel(setDeep);
                  });
                }

                deep['id'] = match.gidx;
                lowerSportType = 'starcraft';
                deep['sportsType'] = lowerSportType;
                modelMappingData = new StarcraftModel(deep);
              } else if (gtype === 'LOL') {
                const deep = { ...match };

                if (deep.sets.length > 0) {
                  deep.sets = match.sets.map((set) => {
                    let setDeep = { ...set };

                    // 블루 player 모델 적용
                    if (set.away.players.length > 0) {
                      setDeep.away.players = sortPlayers(set.away.players).map(
                        (item) => new LolPlayerModel(item, 'away')
                      );
                    }

                    // 레드 player 모델 적용
                    if (set.home.players.length > 0) {
                      setDeep.home.players = sortPlayers(set.home.players).map(
                        (item) => new LolPlayerModel(item, 'home')
                      );
                    }

                    setDeep.away.banPicks = set.away.banPicks.map((ban) => {
                      if (!!ban) {
                        return new LolBanModel(ban, 'away');
                      } else {
                        return null;
                      }
                    });

                    setDeep.home.banPicks = set.home.banPicks.map((ban) => {
                      if (!!ban) {
                        return new LolBanModel(ban, 'away');
                      } else {
                        return null;
                      }
                    });

                    return new LolSetModel(setDeep);
                  });
                }

                deep['id'] = match.gidx;
                lowerSportType = 'lol';
                deep['sportsType'] = lowerSportType;

                modelMappingData = new LolModel(deep);
              }

              if (modelMappingData !== null) {
                if (result[lowerSportType] === undefined) {
                  result[lowerSportType] = [];
                  result[lowerSportType].push(modelMappingData);
                } else {
                  result[lowerSportType].push(modelMappingData);
                }
              }
            });
          }
        });
      }

      this.interestGames = {
        loading: false,
        data: result
      };
    } catch (e) {
      console.log(e);
    }
  }
}

export default InterestGameStore;
