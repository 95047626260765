import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import { MypageTrade } from '../components';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Write, CategoryList } from './styled';
import Pagination from '../../../components/pagination';
import TradeWrite from '../trade_write';
import { PopupModal } from '../../../components/modal/popup/popupModal';
//import useUsersStore from '../../../hooks/useUsersStore';
//import Pw2 from '../pw2';
//import useModalStore from '../../../hooks/useModalStore';
import NoneContent from '../../../components/noneContent';
import Seo from '../../../components/seo';

const DynamicComponents = {
  tradeWrite: TradeWrite
};

const StatusMap = {
  all: '거래',
  ready: '거래중인',
  complete: '거래가 완료된',
  me: '내가 거래한'
};

const Trade = observer(({ history }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tab = query.get('tab') || 'sell';
  const category = query.get('category') || 'all';
  const page = parseInt(query.get('page')) || 1;

  const { mypageStore } = useMypageStore(MypageContext);
//  const modalStore = useModalStore();
//  const usersStore = useUsersStore();
//  const { is_second_password, second_password_date } = usersStore.data;

  const [isOpenModal, setOpenModal] = useState(['', false]);

  const openPopup = () => {
    const Component = DynamicComponents[isOpenModal[0]];
    if (!!Component) {
      return (
        <Component
          tab={tab}
          onClose={() => setOpenModal(['', false])}
          onSubmitCallback={onSubmitCallback}
        />
      );
    } else {
      return null;
    }
  };

  const onSubmitCallback = (code) => {
    switch (isOpenModal[0]) {
      case 'pw2':
        if (code === 200) setOpenModal(['tradeWrite', true]);
        break;
      case 'tradeWrite':
        if (code === 1040) setOpenModal(['pw2', true]);
        else if (code === 200) setOpenModal(['', false]);
        break;
    }
  };

  const onClickWrite = () => {
//    if (second_password_date === null) {
//      modalStore.openAlertModal({
//        message: '2차 비밀번호 등록 후 이용 가능 합니다.',
//        callback: () => {
//          history.push('/mypage/info');
//        }
//      });
//    } else if (!is_second_password) {
//      setOpenModal(['pw2', true]);
//    } else {
//
//    }
    setOpenModal(['tradeWrite', true]);
  };

  useEffect(() => {
    if (!isOpenModal[1]) {
      mypageStore.actionTradeList({
        type: tab,
        status: category === 'all' ? '' : category,
        page
      });
    }
  }, [tab, category, page, isOpenModal[1]]);

  const categoryList = useMemo(() => {
    switch (tab) {
      case 'buy':
        return [
          { key: 'all', name: '전체' },
          { key: 'ready', name: '구매중' },
          { key: 'complete', name: '구매완료' },
          { key: 'me', name: '내 거래내역' }
        ];
      default:
        return [
          { key: 'all', name: '전체' },
          { key: 'ready', name: '판매중' },
          { key: 'complete', name: '판매완료' },
          { key: 'me', name: '내 거래내역' }
        ];
    }
  }, [tab]);

  return (
    <>
      <Seo title={'마이페이지 황금마차'} />
      <PopupModal
        isOpen={isOpenModal[1]}
        onClose={() => setOpenModal(['', false])}
      >
        {openPopup()}
      </PopupModal>
      <div className="snb_wrap">
        <ul>
          <li>
            <Link
              to={`${location.pathname}?tab=sell`}
              className={clsx('item', tab === 'sell' && 'selected')}
            >

              아이템 팝니다
            </Link>
          </li>
          <li>
            <Link
              to={`${location.pathname}?tab=buy`}
              className={clsx('item', tab === 'buy' && 'selected')}
            >
              아이텝 삽니다
            </Link>
          </li>
          <Write>
            <button onClick={onClickWrite} />
          </Write>
        </ul>
      </div>
      <CategoryList>
        {categoryList.map((item, key) => {
          return (
            <li key={key}>
              <Link
                to={`${location.pathname}?tab=${tab}&category=${item.key}`}
                className={clsx(category === item.key && 'selected')}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </CategoryList>
      {mypageStore.data.total_cnt < 1 ? (
        <NoneContent title={StatusMap[category]} />
      ) : (
        <>
          <MypageTrade
            history={history}
            items={mypageStore.data.list}
            tab={tab}
          />
          <Pagination
            page={page}
            totalCount={mypageStore.data.total_cnt}
            tab={tab}
            category={category}
            rowCount={10}
          />
        </>
      )}
    </>
  );
});

export default Trade;
