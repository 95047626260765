import { observable, computed, action, runInAction } from 'mobx';
import autobind from 'autobind-decorator';
import { asyncAction } from 'mobx-utils';
import BasketballModel from './model';
import AbstractScoreCommon from '../../store/common';
import { getDeletedGames, setDefaultList } from '../../shared/utils';
import WebSocketUtil from '../../../../common/utils/socketUtil';
import { ScoreUtil } from '../../../../common/utils/scoreUtil';
import { ScoreService } from '../../../../common/services';

@autobind
class BasketballStore extends AbstractScoreCommon {
  socket = null;
  isSub = false;

  @observable
  list = [];

  @observable
  loading = false;

  constructor() {
    super();
  }

  connectSocket(topic) {
    this.socket = new WebSocketUtil(topic);

    this.socket.ws.onmessage = (event) => {
      if (event) {
        let data = event.data;

        try {
          if (data === 'OK') {
            this.isSub = true;
          } else {
            let messageData = JSON.parse(data);

            if (messageData) {
              const { TOPIC, MESSAGE } = messageData;

              if (TOPIC.includes('sports')) {
                const data = JSON.parse(MESSAGE);
                const copy = [...this.list.toJS()];
                let index = -1;

                if (data.hasOwnProperty('gameId')) {
                  index = copy.findIndex((item) => item.id === data.gameId);
                }

                if (Boolean(~index)) {
                  if (TOPIC === `sports.basketball.games`) {
                    this.list[index].updateGame(data.game);
                  }

                  if (TOPIC === `sports.basketball.period-data`) {
                    this.list[index].updatePeriodData(data.periodData);
                  }
                }

                if (TOPIC === `sports.basketball.cheers`) {
                  index = copy.findIndex((item) => item.id == data.game_id);
                  if (Boolean(~index)) {
                    this.list[index].cheerCount++;
                  }
                }
              }
            }
          }
        } catch (error) {
          console.log('=== socket on message error ===', error);
        }
      }
    };
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get basketballList() {
    const { list, selectedGames } = this;
    const deleteSelectedGames = getDeletedGames(list, selectedGames);

    return setDefaultList(deleteSelectedGames);
  }

  @computed
  get isFavoriteActiveCheck() {
    const { selectedList } = this;

    return selectedList.length > 0;
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  closeSocket() {
    this.socket.close(() => {
      runInAction(() => {
        this.isSub = false;
        this.socket = null;
      });
    });
  }

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  *getBasketball(date) {
    this.loading = true;

    try {
      // TODO: 웹소켓;
      if (!this.isSub) {
        yield this.connectSocket([
          'sports.basketball.games',
          'sports.basketball.period-data',
          'sports.basketball.cheers'
        ]);
      }

      const result = yield ScoreService.getSportsTypeGames(
        date,
        'ALL',
        'basketball'
      );

      if (result) {
        this.list = result.map((item) => new BasketballModel(item));

        this.loading = false;
      }

      // console.log('result success = ', result);
      // console.log(type, ' response = ', this.sports[type]);
    } catch (error) {
      console.log(error);
    }
  }
}

export default BasketballStore;
