import React from 'react';
import {Url} from "../../common/constants/environment";

const GameStatsHtml = () => {
  return (
    <>
      <div className="minigame_wrap">

        <div className="game_view">
          <iframe scrolling="no" frameBorder="0" src={Url.namedWeb + "games/ladder/view.php?view_mode=m"}></iframe>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="snb_wrap">
          <ul>
            <li><a className="item" href="javascript:;">응원</a></li>
            <li><a className="item" href="javascript:;">방채팅</a></li>
            <li><a className="item" href="javascript:;">결과</a></li>
            <li><a className="item selected" href="javascript:;">분석</a></li>
            <li><a className="item" href="javascript:;">게임픽</a></li>
          </ul>
        </div>

        {/* game_stats_area */}
        <div className="game_stats_area">

          {/* option_tab */}
          <div className="option_tab">
            <ul>
              <li><a className="selected">일별분석</a></li>
              <li><a>회차분석</a></li>
              <li><a>패턴분석</a></li>
            </ul>
          </div>
          {/* // option_tab */}

          {/* option_area */}
          <div className="option_area">
            <div className="date_area">
              <a className="btn_prev" href="javascript:;">이전 날짜</a>
              <strong className="date">2021.01.07</strong>
              <a className="btn_next" href="javascript:;">다음 날짜</a>
            </div>
            <div className="option_type">
              <select>
                <option value="0" selected="selected">하루씩보기</option>
                <option value="1">기간으로보기</option>
              </select>
            </div>
          </div>
          {/* // option_area */}

          <br/>

          {/* option_tab */}
          <div className="option_tab">
            <ul>
              <li><a className="selected">일별분석</a></li>
              <li><a>회차분석</a></li>
              <li><a>패턴분석</a></li>
            </ul>
          </div>
          {/* // option_tab */}

          {/* option_area */}
          <div className="option_area">
            <div className="date_area">
              <strong className="date period">2021.01.07 ~ 2021.01.08</strong>
            </div>
            <div className="option_type">
              <select>
                <option value="0">하루씩보기</option>
                <option value="1" selected="selected">기간으로보기</option>
              </select>
            </div>
            <div className="option_list">
              <ul>
                <li><button type="button" className="selected">2일</button></li>
                <li><button type="button">4일</button></li>
                <li><button type="button">7일</button></li>
                <li><button type="button">15일</button></li>
                <li><button type="button">30일</button></li>
                <li><button type="button">전체</button></li>
              </ul>
            </div>
          </div>
          {/* // option_area */}


          {/* date_detail_wrap */}
          <div className="date_detail_wrap">

            {/* data_summary */}
            <div className="data_summary">
              <h3 className="tit">파워볼 통계</h3>
              <div className="chart">
                <dl className="bar ODD">
                  <dt>홀</dt>
                  <dd><span className="num">116</span><span className="per">(47.15%)</span></dd>
                </dl>
                <dl className="bar EVEN on">
                  <dt>짝</dt>
                  <dd><span className="num">130</span><span className="per">(52.85%)</span></dd>
                </dl>
              </div>
              <div className="chart">
                <dl className="bar UNDER">
                  <dt>언더</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar OVER">
                  <dt>오버</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
              </div>
              <div className="chart column4">
                <dl className="bar A">
                  <dt>구간 A</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar B on">
                  <dt>구간 B</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar C">
                  <dt>구간 C</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar D">
                  <dt>구간 D</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
              </div>
              <div className="chart column4">
                <dl className="bar ODD">
                  <dt>홀언</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar ODD">
                  <dt>홀오</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
                <dl className="bar EVEN on">
                  <dt>짝언</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar EVEN">
                  <dt>짝오</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
              </div>
            </div>
            {/* // data_summary */}

            {/* data_summary */}
            <div className="data_summary">
              <h3 className="tit">일반볼 통계</h3>
              <div className="chart">
                <dl className="bar ODD">
                  <dt>홀</dt>
                  <dd><span className="num">116</span><span className="per">(47.15%)</span></dd>
                </dl>
                <dl className="bar EVEN on">
                  <dt>짝</dt>
                  <dd><span className="num">130</span><span className="per">(52.85%)</span></dd>
                </dl>
              </div>
              <div className="chart">
                <dl className="bar UNDER on">
                  <dt>언더</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar OVER">
                  <dt>오버</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
              </div>
              <div className="chart">
                <dl className="bar BIG">
                  <dt>대</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
                <dl className="bar MIDDLE">
                  <dt>중</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar SMALL on">
                  <dt>소</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
              </div>
              <div className="chart column4">
                <dl className="bar ODD">
                  <dt>홀언</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar ODD">
                  <dt>홀오</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
                <dl className="bar EVEN on">
                  <dt>짝언</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar EVEN">
                  <dt>짝오</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
              </div>
              <div className="chart column6">
                <dl className="bar ODDBIG">
                  <dt>홀대</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
                <dl className="bar ODDMIDDLE">
                  <dt>홀중</dt>
                  <dd><span className="num">2</span><span className="per">(50%)</span></dd>
                </dl>
                <dl className="bar ODDSMALL on">
                  <dt>홀소</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar EVENBIG">
                  <dt>짝대</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar EVENMIDDLE">
                  <dt>짝중</dt>
                  <dd><span className="num">0</span><span className="per">(0%)</span></dd>
                </dl>
                <dl className="bar EVENSMALL">
                  <dt>짝소</dt>
                  <dd><span className="num">1</span><span className="per">(25%)</span></dd>
                </dl>
              </div>
            </div>
            {/* // data_summary */}

            {/* data_pattern_board_wrap */}
            <div className="data_pattern_board_wrap">
              <h3 className="tit">매 통계</h3>
              <div className="option_type">
                <select>
                  <option value="0">기본</option>
                  <option value="1">1매</option>
                  <option value="2">2매</option>
                  <option value="3">3매</option>
                  <option value="4">4매</option>
                  <option value="5">5매</option>
                  <option value="6">6매</option>
                </select>
              </div>
              <div className="option_type">
                <select>
                  <option value="0">파워볼 홀짝</option>
                  <option value="1">파워볼 언오버</option>
                  <option value="1">파워볼 구간</option>
                  <option value="1">파워볼 홀짝+언오버</option>
                  <option value="1">일반볼합 홀짝</option>
                  <option value="1">일반볼합 언오버</option>
                  <option value="1">일반볼합 대중소</option>
                  <option value="1">일반볼합 홀짝+언오버</option>
                  <option value="1">일반볼합 홀짝+대중소</option>
                </select>
              </div>
              <div className="pattern_data">
                <div className="inner" style={{width:'1000px'}}>
                  <dl className="default">
                    <dt className="ODD">홀</dt>
                    <dd><span className="ic ODD">1</span></dd>
                    <dd className="length">1</dd>
                    <dd className="times">1</dd>
                  </dl>
                  <dl className="default">
                    <dt className="EVEN">짝</dt>
                    <dd><span className="ic EVEN">2</span></dd>
                    <dd><span className="ic EVEN">3</span></dd>
                    <dd className="length">7</dd>
                    <dd className="times">2</dd>
                  </dl>
                  <dl className="default">
                    <dt className="ODD">홀</dt>
                    <dd><span className="ic ODD">9</span></dd>
                    <dd><span className="ic ODD">10</span></dd>
                    <dd className="length">2</dd>
                    <dd className="times">3</dd>
                  </dl>
                </div>
              </div>
              <div className="pattern_summary">
                <div className="bs">
                  <dl className="ic ODD">
                    <dt>홀</dt>
                    <dd>124번(47.51%) 7연속</dd>
                  </dl>
                  <dl className="ic EVEN">
                    <dt>짝</dt>
                    <dd>137번(52.49%) 7연속</dd>
                  </dl>
                  <dl className="ic A">
                    <dt>홀</dt>
                    <dd>124번(47.51%) 7연속</dd>
                  </dl>
                  <dl className="ic B">
                    <dt>짝</dt>
                    <dd>137번(52.49%) 7연속</dd>
                  </dl>
                </div>
                <dl className="etc">
                  <dt>퐁당 :</dt>
                  <dd>6번</dd>
                  <dt>꺽임 :</dt>
                  <dd>121번</dd>
                </dl>
              </div>
            </div>
            {/* // data_pattern_board_wrap */}

            {/* data_list_wrap */}
            <div className="data_list_wrap">
              <h3 className="tit">회차별 통계</h3>
              <div className="data_list speedkeno">
                <div className="table_float">
                  <table className="list" border="1">
                    <caption>결과리스트</caption>
                    <colgroup>
                      <col width="46px"/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th>회차</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>260</td>
                    </tr>
                    <tr>
                      <td>260</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table_data">
                  <table className="list" border="1">
                    <caption>결과리스트</caption>
                    <colgroup>
                      <col width=""/>
                      <col width="59px"/>
                      <col width="115px"/>
                      <col width="115px"/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th>결과</th>
                      <th>합</th>
                      <th>마지막자리 홀짝</th>
                      <th>마지막자리 언오버</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        69,23,32,11,66,65,39,10,70,17,01,64,51,31,62,21,16,22,09,02,25,37
                      </td>
                      <td>554</td>
                      <td><span className="ic EVEN">짝</span></td>
                      <td><span className="ic UNDER">언더</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <button className="btn_more">더보기 ( 1 / 26 )</button>
            </div>
            {/* // data_list_wrap */}


            {/* data_list_wrap */}
            <div className="data_list_wrap">
              <h3 className="tit">회차별 통계</h3>
              <div className="data_list racing">
                <table className="list" border="1">
                  <caption>결과리스트</caption>
                  <colgroup>
                    <col width="18%"/>
                    <col width="20%"/>
                    <col width="20%"/>
                    <col width="58%"/>
                  </colgroup>
                  <thead>
                  <tr>
                    <th>회차</th>
                    <th>언오버</th>
                    <th>홀짝</th>
                    <th>순위</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>100</td>
                    <td><span className="ic UNDER">언더</span></td>
                    <td><span className="ic ODD">홀</span></td>
                    <td>
                      <span className="ic SNAIL1">1번</span>
                      <span className="ic SNAIL2">2번</span>
                      <span className="ic SNAIL3">3번</span>
                      <span className="ic SNAIL4">4번</span>
                    </td>
                  </tr>
                  <tr>
                    <td>100</td>
                    <td><span className="ic UNDER">언더</span></td>
                    <td><span className="ic ODD">홀</span></td>
                    <td>
                      <span className="ic SNAIL1">1번</span>
                      <span className="ic SNAIL2">2번</span>
                      <span className="ic SNAIL3">3번</span>
                      <span className="ic SNAIL4">4번</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <button className="btn_more">더보기 ( 1 / 26 )</button>
            </div>
            {/* // data_list_wrap */}


            {/* data_list_wrap */}
            <div className="data_list_wrap">
              <h3 className="tit">회차별 통계</h3>
              <div className="data_list ladder">
                <table className="list" border="1">
                  <caption>결과리스트</caption>
                  <colgroup>
                    <col width="25%"/>
                    <col width="25%"/>
                    <col width="25%"/>
                    <col width="25%"/>
                  </colgroup>
                  <thead>
                  <tr>
                    <th>회차</th>
                    <th>출발</th>
                    <th>줄수</th>
                    <th>결과</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>100</td>
                    <td><span className="ic RIGHT">우</span></td>
                    <td><span className="ic LINE3">3</span></td>
                    <td><span className="ic ODD">홀</span></td>
                  </tr>
                  <tr>
                    <td>100</td>
                    <td><span className="ic LEFT">좌</span></td>
                    <td><span className="ic LINE4">4</span></td>
                    <td><span className="ic EVEN">짝</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <button className="btn_more">더보기 ( 1 / 26 )</button>
            </div>
            {/* // data_list_wrap */}

            {/* data_list_wrap */}
            <div className="data_list_wrap">
              <h3 className="tit">회차별 통계</h3>
              <div className="data_list">
                <div className="table_float">
                  <table className="list" border="1">
                    <caption>결과리스트</caption>
                    <colgroup>
                      <col width="46px"/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th>회차</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>260</td>
                    </tr>
                    <tr>
                      <td>260</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table_data">
                  <table className="list" border="1">
                    <caption>결과리스트</caption>
                    <colgroup>
                      <col width="49px"/>
                      <col width="49px"/>
                      <col width="49px"/>
                      <col width="49px"/>
                      <col width="99px"/>
                      <col width="49px"/>
                      <col width="49px"/>
                      <col width="49px"/>
                      <col width="49px"/>
                      <col width="49px"/>
                    </colgroup>
                    <thead>
                    <tr>
                      <th colSpan="4">파워볼</th>
                      <th colSpan="6">일반볼합</th>
                    </tr>
                    <tr>
                      <th>결과</th>
                      <th>홀짝</th>
                      <th>언오버</th>
                      <th>구간</th>
                      <th>결과</th>
                      <th>합</th>
                      <th>구간</th>
                      <th>홀짝</th>
                      <th>언오버</th>
                      <th>대중소</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>4</td>
                      <td><span className="ic EVEN">짝</span></td>
                      <td><span className="ic UNDER">언더</span></td>
                      <td><span className="ic B">B</span></td>
                      <td>
                        <span>21</span>
                        <span>28</span>
                        <span>14</span>
                        <span>7</span>
                        <span>15</span>
                      </td>
                      <td>85</td>
                      <td><span className="ic F">F</span></td>
                      <td><span className="ic ODD">홀</span></td>
                      <td><span className="ic OVER">오버</span></td>
                      <td><span className="ic L">대</span></td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td><span className="ic EVEN">짝</span></td>
                      <td><span className="ic UNDER">언더</span></td>
                      <td><span className="ic B">B</span></td>
                      <td>
                        <span>21</span>
                        <span>28</span>
                        <span>14</span>
                        <span>7</span>
                        <span>15</span>
                      </td>
                      <td>85</td>
                      <td><span className="ic F">F</span></td>
                      <td><span className="ic ODD">홀</span></td>
                      <td><span className="ic OVER">오버</span></td>
                      <td><span className="ic L">대</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <button className="btn_more">더보기 ( 1 / 26 )</button>
            </div>
            {/* // data_list_wrap */}

            {/* data_minmax_wrap */}
            <div className="data_minmax_wrap">
              <h3 className="tit">기간내 최소/최다 출현 통계</h3>
              <div className="minmax_list">
                <div className="reference">
                  <span className="tx_min">최소출현</span>
                  <span className="tx_max">최대출현</span>
                </div>
                <div className="tit_sub">파워볼</div>

                <div className="item">
                  <span className="tit">홀</span>
                  <div className="mm min">
                    <p style={{width:'60%'}}>
                      <span className="tx">
                        <em>2021-01-07</em>
                        <em><strong>122</strong> (46.74%)</em>
                      </span>
                    </p>
                  </div>
                  <div className="mm max">
                    <p style={{width:'46%'}}>
                      <span className="tx">
                        <em>2021-01-07</em>
                        <em><strong>122</strong> (46.74%)</em>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="item">
                  <span className="tit">짝</span>
                  <div className="mm min">
                    <p style={{width:'50%'}}>
                      <span className="tx">
                        <em>2021-01-07</em>
                        <em><strong>122</strong> (46.74%)</em>
                      </span>
                    </p>
                  </div>
                  <div className="mm max">
                    <p style={{width:'76%'}}>
                      <span className="tx">
                        <em>2021-01-07</em>
                        <em><strong>122</strong> (46.74%)</em>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="tit_sub">일반볼 합</div>
                <div className="item">
                  <span className="tit">홀</span>
                  <div className="mm min">
                    <p style={{width:'76%'}}>
                      <span className="tx">
                        <em>2021-01-07</em>
                        <em><strong>122</strong> (46.74%)</em>
                      </span>
                    </p>
                  </div>
                  <div className="mm max">
                    <p style={{width:'76%'}}>
                      <span className="tx">
                        <em>2021-01-07</em>
                        <em><strong>122</strong> (46.74%)</em>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* // data_minmax_wrap */}

            {/* data_pattern_search_wrap */}
            <div className="data_pattern_search_wrap">
              <h3 className="tit">파워볼 홀짝 기준 20패턴</h3>
              <div className="pattern_search">
                <dl className="item">
                  <dt className="ic ODD">홀</dt>
                  <dd className="times">245</dd>
                </dl>
                <dl className="item">
                  <dt className="ic EVEN">홀</dt>
                  <dd className="times">245</dd>
                </dl>
                <dl className="item">
                  <dt className="ic ODD">홀</dt>
                  <dd className="times">245</dd>
                </dl>
                <dl className="item">
                  <dt className="ic EVEN">홀</dt>
                  <dd className="times">245</dd>
                </dl>
                <dl className="item">
                  <dt className="ic ODD">홀</dt>
                  <dd className="times">245</dd>
                </dl>
                <dl className="item">
                  <dt className="ic EVEN">홀</dt>
                  <dd className="times">245</dd>
                </dl>
                <dl className="item">
                  <dt className="ic ODD">홀</dt>
                  <dd className="times">245</dd>
                </dl>
                <dl className="item">
                  <dt className="ic EVEN">홀</dt>
                  <dd className="times">245</dd>
                </dl>
              </div>
            </div>
            {/* // data_pattern_search_wrap */}

            {/* data_pattern_result_wrap */}
            <div className="data_pattern_result_wrap">
              <h3 className="tit">다음회차 통계</h3>
              <div className="pattern_result">
                <table className="list" border="1">
                  <caption>결과리스트</caption>
                  <colgroup>
                    <col width="80px"/>
                    <col width=""/>
                    <col width="60px"/>
                  </colgroup>
                  <thead>
                  <tr>
                    <th>날짜</th>
                    <th>결과</th>
                    <th>다음회차</th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr>
                    <td>2021.01.08</td>
                    <td className="result">
                      <dl className="item">
                        <dt className="ic EVEN">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic ODD">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic ODD">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic EVEN">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic ODD">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                    </td>
                    <td>
                      <dl className="item">
                        <dt className="ic EVEN">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                    </td>
                  </tr>
                  <tr>
                    <td>2021.01.08</td>
                    <td className="result">
                      <dl className="item">
                        <dt className="ic EVEN">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic ODD">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic ODD">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic EVEN">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic ODD">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                    </td>
                    <td>
                      <dl className="item">
                        <dt className="ic EVEN">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                    </td>
                  </tr>
                  <tr>
                    <td>2021.01.08</td>
                    <td className="result">
                      <dl className="item">
                        <dt className="ic EVEN">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic ODD">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic ODD">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic EVEN">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                      <dl className="item">
                        <dt className="ic ODD">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                    </td>
                    <td>
                      <dl className="item">
                        <dt className="ic EVEN">홀</dt>
                        <dd className="times">245</dd>
                      </dl>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <button className="btn_more">더보기 ( 1 / 26 )</button>
              </div>
            </div>
            {/* // data_pattern_result_wrap */}

          </div>
          {/* // date_detail_wrap */}

        </div>
        {/* // game_stats_area */}

      </div>

    </>
  );
};

export default GameStatsHtml;
