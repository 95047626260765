import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import useSportsStore from '../../../hooks/useSportsStore';
import useTypeStore from '../../../hooks/useTypeStore';
import {
  HeaderContainer,
  HeaderBar,
  LogoContainer,
  SearchContainer,
  SearchIcon,
  SearchInput,
  ClearButton,
  SearchButton,
  SuggestionsList,
  Overlay,
  SuggestionContent,
  CancelButton,
  HighlightedTextStyle,
} from './styles';

export const HomeHeader = observer(() => {
  const store = useSportsStore('major');
  const homeStore = useTypeStore('homeStore');

  const inputRef = useRef(null);

  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const bodyScroll = useRef();

  useEffect(() => {
    store.setSearchData({ keyword: '', id: undefined });
    homeStore.setShowSearchInput(false);
  }, []);

  // Close search input when my info pane is opened
  useEffect(() => {
    if (homeStore.showMyInfoPane && isSearchOpen) {
      handleCloseSearchClick();
    }
  }, [homeStore.showMyInfoPane]);

  const majorMatches = React.useMemo(() => {
    return (
      Array.from(store.majorList?.values() || [])?.reduce((acc, item) => {
        return acc.concat(item.list);
      }, []) || []
    );
  }, [store.majorList]);

  useEffect(() => {
    if (!showSuggestions) {
      enableBodyScroll(bodyScroll.current);
      clearAllBodyScrollLocks();
    } else {
      disableBodyScroll(bodyScroll.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [showSuggestions, bodyScroll.current]);

  const openSearchInput = () => {
    setIsSearchOpen(true);
    openKeyboard();
    homeStore.setShowSearchInput(true);
  };

  const handleCloseSearchClick = () => {
    setSearchQuery('');
    setIsSearchOpen(false);
    setShowSuggestions(false);
    hideKeyboard();
    store.setSearchData({ keyword: '', id: undefined });
    homeStore.setShowSearchInput(false);
  };

  const handleClearSearchClick = () => {
    setSearchQuery('');
    setShowSuggestions(false);
    store.setSearchData({ keyword: '', id: undefined });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query?.trim().length > 0) {
      const matchSuggestions = majorMatches.filter((item) => {
        const homeTeam = item.getTeamData.home.name;
        const awayTeam = item.getTeamData.away.name;
        const displayString = `${homeTeam} vs ${awayTeam}`;
        return displayString.toLowerCase().includes(query.trim().toLowerCase());
      });
      if (matchSuggestions.length > 0) {
        setSuggestions(matchSuggestions);
        setShowSuggestions(true);
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
      store.setSearchData({ keyword: '', id: undefined });
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const homeTeam = suggestion.getTeamData.home.name;
    const awayTeam = suggestion.getTeamData.away.name;
    const displayString = `${homeTeam} vs ${awayTeam}`;
    setSearchQuery(displayString);
    setShowSuggestions(false);

    store.setSearchData({ keyword: displayString, id: suggestion.id });
  };

  const handleSubmitSearch = () => {
    console.log('Search query:', searchQuery);
    setShowSuggestions(false);
    hideKeyboard();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitSearch();
    }
  };

  // Open keyboard (focus input)
  const openKeyboard = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Hide keyboard (blur input)
  const hideKeyboard = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const HighlightedText = ({ suggestion, query }) => {
    if (!query) return <span>{suggestion}</span>;

    // Case-insensitive search
    const regex = new RegExp(`(${query})`, 'gi');
    const parts = suggestion.split(regex);

    return (
      <HighlightedTextStyle>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <span className='highlight' key={index}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </HighlightedTextStyle>
    );
  };

  const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.getTeamData.home.name} vs ${suggestion.getTeamData.away.name}`;

    return (
      <SuggestionContent onClick={() => handleSuggestionClick(suggestion)}>
        <HighlightedText suggestion={suggestionText} query={query?.trim()} />
      </SuggestionContent>
    );
  };

  return (
    <HeaderContainer>
      <HeaderBar>
        {!isSearchOpen ? (
          <LogoContainer>
            <img src='/images/home_logo_name.svg' alt='Logo' />
          </LogoContainer>
        ) : null}

        {isSearchOpen ? (
          <SearchContainer>
            <SearchIcon src='/images/ic-score-on.svg' alt='Score' />
            <SearchInput
              ref={inputRef}
              type='text'
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder='오늘의 주요경기 검색'
              onKeyDown={handleKeyDown}
              enterKeyHint='search'
              inputMode='search'
            />
            <ClearButton onClick={handleClearSearchClick}>
              <img src='/images/ic-close.svg' alt='Clear' />
            </ClearButton>
            {showSuggestions && (
              <SuggestionsList ref={bodyScroll}>
                {suggestions.map((suggestion, index) => renderSuggestion(suggestion, { query: searchQuery }))}
              </SuggestionsList>
            )}
          </SearchContainer>
        ) : (
          <SearchButton onClick={openSearchInput}>
            <img src='/images/ic-search-home.svg' alt='Search' />
          </SearchButton>
        )}
        {isSearchOpen ? <CancelButton onClick={handleCloseSearchClick}>취소</CancelButton> : null}
      </HeaderBar>
      {showSuggestions && <Overlay />}
    </HeaderContainer>
  );
});
