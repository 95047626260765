import React, { useEffect, useState } from "react";
import { MypageContext, useMypageStore } from "../../../context";
import { RegexUtil } from "../../../../../common/utils/regexUtil";

const MedalSummary = ({ type }) => {
  const { mypageStore } = useMypageStore(MypageContext);
  const [data, setData] = useState({});
  useEffect(() => {
    setData({
      today_name: "오늘 구매 건빵",
      total_name: "보유 건빵",
      today_amount: mypageStore.data.today_payment_medal,
      total_amount: mypageStore.data.total_payment_medal,
    });
    //    if (type === "payment_medal_list") {
    //      setData({
    //        today_name: "오늘 구매 건빵",
    //        total_name: "건빵",
    //        today_amount: mypageStore.data.today_payment_medal,
    //        total_amount: mypageStore.data.total_payment_medal,
    //      });
    //    } else {
    //      setData({
    //        today_name: "오늘 획득 건빵",
    //        total_name: "받은 건빵",
    //        today_amount: mypageStore.data.today_profit_medal,
    //        total_amount: mypageStore.data.total_profit_medal,
    //      });
    //    }
  }, [type, mypageStore.data]);
  return (
    <div className="summary">
      <dl className="amass">
        <dt>{data.total_name}</dt>
        <dd>{RegexUtil.makeComma(data.total_amount)}</dd>
      </dl>
      <div className="today">
        <a href="/mypage/medal_exchange">환전하기</a>
        <a href="/mypage/medal_exchange_history">환전내역</a>
      </div>
    </div>
  );
};

export { MedalSummary };
