import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const DhPatternBarChart = ({ percent }) => {
  if (Object.keys(percent).length === 0) {
    return null;
  }

  const {
    powerball_under_over,
    sum_odd_even,
    sum_size,
  } = percent;

  return (
    <>
      <div className="data_summary">
        <h3 className="tit">
          파워볼 : <span>패턴 일치 다음회차 분석</span>
        </h3>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'UNDER',
              parseInt(powerball_under_over.over_cnt) >
                parseInt(powerball_under_over.under_cnt) && 'on'
            )}
          >
            <dt>언더</dt>
            <dd>
              <span className="num">{powerball_under_over.over_cnt}</span>
              <span className="per">({powerball_under_over.over_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'OVER',
              parseInt(powerball_under_over.over_cnt) <
                parseInt(powerball_under_over.under_cnt) && 'on'
            )}
          >
            <dt>오버</dt>
            <dd>
              <span className="num">{powerball_under_over.under_cnt}</span>
              <span className="per">({powerball_under_over.under_ratio}%)</span>
            </dd>
          </dl>
        </div>
      </div>

      <div className="data_summary">
        <h3 className="tit">
          일반볼합 : <span>패턴 일치 다음회차 분석</span>
        </h3>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'ODD',
              parseInt(sum_odd_even.odd_cnt) >
                parseInt(sum_odd_even.even_cnt) && 'on'
            )}
          >
            <dt>홀</dt>
            <dd>
              <span className="num">{sum_odd_even.odd_cnt}</span>
              <span className="per">({sum_odd_even.odd_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              parseInt(sum_odd_even.odd_cnt) <
                parseInt(sum_odd_even.even_cnt) && 'on'
            )}
          >
            <dt>짝</dt>
            <dd>
              <span className="num">{sum_odd_even.even_cnt}</span>
              <span className="per">({sum_odd_even.even_ratio}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'BIG',
              MathUtil.onlyMaxNumber([
                sum_size.l_cnt,
                sum_size.m_cnt,
                sum_size.s_cnt
              ]) === parseInt(sum_size.l_cnt) && 'on'
            )}
          >
            <dt>대</dt>
            <dd>
              <span className="num">{sum_size.l_cnt}</span>
              <span className="per">({sum_size.l_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'MIDDLE',
              MathUtil.onlyMaxNumber([
                sum_size.l_cnt,
                sum_size.m_cnt,
                sum_size.s_cnt
              ]) === parseInt(sum_size.m_cnt) && 'on'
            )}
          >
            <dt>중</dt>
            <dd>
              <span className="num">{sum_size.m_cnt}</span>
              <span className="per">({sum_size.m_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'SMALL',
              MathUtil.onlyMaxNumber([
                sum_size.l_cnt,
                sum_size.m_cnt,
                sum_size.s_cnt
              ]) === parseInt(sum_size.s_cnt) && 'on'
            )}
          >
            <dt>소</dt>
            <dd>
              <span className="num">{sum_size.s_cnt}</span>
              <span className="per">({sum_size.s_ratio}%)</span>
            </dd>
          </dl>
        </div>
      </div>
    </>
  );
};

export default DhPatternBarChart;
