import styled from 'styled-components';

export const Wrap = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  background-color: #fff;
  height: calc(100% - 55px);
  width: 100%;
  transform: translateX(${(props) => (props.isOpen ? 0 : `100%`)});
  transition: transform 0.3s ease-in;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 54px;
  min-height: 54px;
  border-bottom: 1px solid #e4e4e4;
  box-sizing: border-box;
  > h2 {
    font-size: 18px;
  }
  > button {
    position: absolute;
    width: 50px;
    height: 54px;
    top: 0;
    right: 0;
    :before,
    :after {
      content: '';
      display: block;
      position: absolute;
      top: 15px;
      left: calc(50% - 1px);
      width: 2px;
      height: 22px;
      background: #333;
    }
    :before {
      transform: rotate(-45deg);
    }
    :after {
      transform: rotate(45deg);
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  padding-bottom: 5px;
  color: #f2f2f2;
  font-size: 11px;
  background-color: #fff;
  a {
    font-size: 11px;
    padding: 0 12px;
    color: #555555;
  }
`;

export const Content = styled.div`
  overflow-y: auto;
  padding-bottom: 45px;
`;

export const Button = styled.button`
  font-size: 15px;
  display: flex;
  height: 55px;
  min-height: 55px;
  align-items: center;
  padding: 0 22px;
  :before {
    content: '';
    display: block;
    margin-right: 32px;
    width: 20px;
    height: 20px;
  }
  &.logout {
    :before {
      background: url('/images/ic-logout.svg') no-repeat center center;
      background-size: 16px 20px;
    }
  }
  &.alarm {
    position: relative;
    :before {
      background: url('/images/ic-alarm.svg') no-repeat center center;
      background-size: 17px 18px;
    }
    &.dot:after {
      content: '';
      position: absolute;
      top: 10px;
      left: 40px;
      width: 8px;
      height: 8px;
      background-color: red;
      border-radius: 50%;
    }
  }
`;

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e4e4e4;
  padding: 8px 0;

  &.login {
    padding: 0;
  }
  :first-child {
    border: none;
  }
  a {
    font-size: 15px;
    display: flex;
    height: 55px;
    min-height: 55px;
    align-items: center;
    padding: 0 22px;
    :before {
      content: '';
      display: block;
      margin-right: 32px;
      width: 20px;
      height: 20px;
    }
    &.login {
      height: 75px;
      min-height: 75px;
      :before {
        background: url('/images/sp_layout.png') no-repeat -18px 0;
        background-size: 350px 350px;
      }
    }
    &.notice {
      :before {
        background: url('/images/ic-notice.svg') no-repeat center center;
        background-size: 16px 20px;
      }
    }
    &.qna {
      :before {
        background: url('/images/ic-qna.svg') no-repeat center center;
        background-size: 18px 18px;
      }
    }
    &.faq {
      :before {
        background: url('/images/ic-faq.svg') no-repeat center center;
        background-size: 20px 16px;
      }
    }
    &.pc {
      :before {
        background: url('/images/ic-pc.svg') no-repeat center center;
        background-size: 20px 16px;
      }
    }
    &.market {
      :before {
        background: url('/images/ic-market.svg') no-repeat center center;
        background-size: 18px 18px;
      }
    }
    &.gifticon {
      :before {
        background: url('/images/ic-gifticon.svg') no-repeat center center;
        background-size: 18px 18px;
      }
    }
    &.develop {
      :before {
        background: url('/images/ic-dev-note.svg') no-repeat center center;
        background-size: 18px 18px;
      }
    }
    &.inbox {
      position: relative;
      :before {
        background: url('/images/inbox.svg') no-repeat center center;
        background-size: 17px 18px;
      }
      &.dot:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 40px;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
      }
    }
  }
`;

export const UserInfo = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  > a {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 70px;
    height: 55px;
    min-height: 55px;
    :before {
      content: '';
      position: absolute;
      top: 21px;
      right: 10px;
      width: 8px;
      height: 13px;
      background: url('/images/sp_layout.png') no-repeat -40px 0;
      background-size: 350px 350px;
    }
    .thumb {
      position: absolute;
      left: 0;
      width: 53px;
      height: 53px;
      :before {
        position: absolute;
        content: '';
        top: -1px;
        left: -1px;
        width: 55px;
        height: 55px;
        z-index: 1;
      }
      img {
        width: 53px;
        height: 53px;
        border-radius: 100%;
      }
      .border {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: 55px;
        height: 55px;
      }
    }
    .name {
      font-size: 18px;
      max-width: calc(100% - 30px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .today {
      font-size: 13px;
      color: #666;
      margin-top: 5px;
      max-width: calc(100% - 30px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

export const AccountInfo = styled.div`
  display: flex;
  justify-content: space-between;
  height: 38px;
  min-height: 38px;
  padding: 0 10px;
  border-radius: 19px;
  background-color: #f1f2f4;
  margin-top: 20px;
  > div {
    width: 30%;
    display: flex;
    align-items: center;
    :before {
      font-size: 9px;
      min-width: 15px;
      height: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      margin-right: 10px;
    }
    &.cash {
      :before {
        content: 'C';
        background-color: #e82d0c;
      }
    }
    &.point {
      :before {
        content: 'P';
        background-color: #1287eb;
      }
    }
    &.tp {
      :before {
        content: 'TP';
        background-color: #85a70f;
      }
    }
    &.medal {
      :before {
        content: '';
        background: url('/images/market/icon_medal.png') no-repeat;
        background-size: contain;
        border-radius: 0%;
      }
    }
  }
`;
