import React from 'react';
import {Url} from "../../common/constants/environment";

const ScoreListHtml = () => {
  return (
    <>
      {/*score_wrap*/}
      <div className="score_wrap">

        {/* score_summary */}
        <div className="score_summary">
          <button type="button" className="btn_calendar">캘린더</button>
          <div className="date_area">
            <a className="btn_prev" href="javascript:;">이전 날짜</a>
            <strong className="date">2020.08.06</strong>
            <a className="btn_next" href="javascript:;">다음 날짜</a>
          </div>
          <button type="button" className="btn_view_option">보기 옵션 변경</button> {/* vertical_view 클래스 추가 */}
        </div>
        {/* // score_summary */}


        {/* game_list */}
        <div className="game_list"> {/* vertical_view 클래스 추가 */}

          {/* section */}
          <div className="section">
            <div className="top">
              <strong className="tit">관심경기</strong>
              <button type="button" className="btn_oc">열기/닫기</button> {/* close 클래스 추가 */}
            </div>

            {/* list */}
            <div className="list">

              {/* item */}
              <div className="item">
                <a className="box" href="javascript:;">
                  <div className="team left">
                    <span className="name">St George Willawong FC</span>
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/18.png"}/>
                  </div>
                  <div className="info">
                    <div className="score">
                      <strong className="left on">
                        <em className="num">94</em>
                      </strong>
                      <strong className="right">
                        <em className="num">78</em>
                      </strong>
                    </div>
                    <div className="state">
                      <span>전반</span>
                      <span>25분</span>
                    </div>
                    <div className="time">00:30</div>
                  </div>
                  <div className="team right">
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                    <div className="name">FC 라다 톨리야티</div>
                  </div>
                </a>
                <button type="button" className="btn_favorites selected">관심경기 추가</button>
              </div>
              {/* // item */}

              {/* item */}
              <div className="item">
                <a className="box" href="javascript:;">
                  <div className="team left">
                    <span className="name">바로 셀로나</span>
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                  </div>
                  <div className="info">
                    <div className="score">
                      <strong className="left on">
                        <em className="num">99</em>
                      </strong>
                      <strong className="right">
                        <em className="num">99</em>
                      </strong>
                    </div>
                    <div className="state">9회말</div>
                    <div className="time">00:30</div>
                  </div>
                  <div className="team right">
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/14_1550480543.png"}/>
                    <div className="name">바로 셀로나</div>
                  </div>
                </a>
                <button type="button" className="btn_favorites selected">관심경기 추가</button>
              </div>
              {/*  // item */}

              {/* item */}
              <div className="item close">
                <a className="box" href="javascript:;">
                  <div className="team left">
                    <span className="name">바로 셀로나</span>
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                  </div>
                  <div className="info">
                    <div className="score">
                      <strong className="left">
                        <em className="num">0</em>
                        <em className="p on">4</em>
                      </strong>
                      <strong className="right">
                        <em className="num">0</em>
                        <em className="p">2</em>
                      </strong>
                    </div>
                    <div className="state">종료</div>
                    <div className="time">00:30</div>
                  </div>
                  <div className="team right">
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/14_1550480543.png"}/>
                    <div className="name">바로 셀로나</div>
                  </div>
                </a>
                <button type="button" className="btn_favorites selected">관심경기 추가</button>
              </div>
              {/*  // item */}

              {/*  item */}
              <div className="item ready">
                <a className="box" href="javascript:;">
                  <div className="team left">
                    <span className="name">바로 셀로나바로 셀로나바로 셀로나바로 셀로나바로 셀로나</span>
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                  </div>
                  <div className="info">
                    <div className="score">
                      <strong className="left on">99</strong>
                      <strong className="right">99</strong>
                    </div>
                    <div className="state">종료</div>
                    <div className="time">00:30</div>
                  </div>
                  <div className="team right">
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/14_1550480543.png"}/>
                    <div className="name">바로 셀로나</div>
                  </div>
                </a>
                <button type="button" className="btn_favorites selected">관심경기 추가</button>
              </div>
              {/*  // item */}

            </div>
            {/* // list */}

          </div>
          {/* // section */}


          {/* section */}
          <div className="section">
            <div className="top">
              <strong className="tit">관심경기</strong>
              <button type="button" className="btn_oc">열기/닫기</button> {/* close 클래스 추가 */}
            </div>

            {/* list */}
            <div className="list">

              {/* item */}
              <div className="item">
                <a className="box" href="javascript:;">
                  <div className="team left">
                    <span className="name">St George Willawong FC</span>
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/18.png"}/>
                  </div>
                  <div className="info">
                    <div className="score">
                      <strong className="left on">
                        <em className="num">94</em>
                      </strong>
                      <strong className="right">
                        <em className="num">55</em>
                      </strong>
                    </div>
                    <div className="state">
                      <span>전반</span>
                      <span>25분</span>
                    </div>
                    <div className="time">00:30</div>
                  </div>
                  <div className="team right">
                    <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                    <div className="name">FC 라다 톨리야티</div>
                  </div>
                </a>
                <button type="button" className="btn_favorites">관심경기 추가</button>
              </div>
              {/* // item */}

            </div>
            {/* // list */}

          </div>
          {/* // section */}

        </div>
        {/* // game_list */}

      </div>
      {/* // score_wrap*/}

    </>
  );
};

export default ScoreListHtml;
