import React from 'react';
import clsx from 'clsx';
import { toJS } from 'mobx';

const ProtoMyPickDetailItem = ({ item }) => {
  const {
    number,
    homeName,
    awayName,
    getStartDateTime,
    sportsTypeLower,
    leagueName,
    selectOddsId
  } = item;
  const { className, visible, optionValue } = item.getGroupType;
  const { statusText, statusClass } = item.getStatus;
  const { home, away, odds } = item.teamData;

  const isUnover = item.groupType === 'UNOVER';
  return (
    <div className="item">
      <div className="summary">
        <span className="no">{number}</span>
        <span className="date">{getStartDateTime}</span>
        <span className={clsx('league', sportsTypeLower)}>{leagueName}</span>
      </div>
      <div className="box">
        <div className="team left">
          <span className="name">{homeName}</span>
        </div>
        <div className="info">
          <div className="score">
            <strong className={clsx('left', home.isActive && 'on')}>
              <em className="num">{home.score}</em>
            </strong>
            <strong className={clsx('right', away.isActive && 'on')}>
              <em className="num">{away.score}</em>
            </strong>
          </div>
          <div className={clsx('state', statusClass)}>{statusText}</div>
        </div>
        <div className="team right">
          <div className="name">{awayName}</div>
        </div>
      </div>
      <div className="odds">
        {visible && (
          <span className={clsx('type', className)}>{optionValue}</span>
        )}
        <ul className="odds_selector">
          <li>
            <input
              type="checkbox"
              id={`win_odds_${odds.win.id}`}
              name="cheer_team"
              className="blind"
              value="win"
              checked={selectOddsId === odds.win.id}
            />
            <label
              htmlFor={`win_odds_${odds.win.id}`}
              className={clsx(odds.win.hit && 'selected')}
            >{`${isUnover ? 'U' : 'W'} ${odds.win.odds}`}</label>
          </li>
          <li>
            {odds.draw.id !== 0 ? (
              <>
                <input
                  type="checkbox"
                  id={`draw_odds_${odds.draw.id}`}
                  name="cheer_team"
                  className="blind"
                  value="draw"
                  checked={selectOddsId === odds.draw.id}
                />
                <label
                  htmlFor={`draw_odds_${odds.draw.id}`}
                  className={clsx(odds.draw.hit && 'selected')}
                >{`W ${odds.draw.odds}`}</label>
              </>
            ) : (
              '-'
            )}
          </li>
          <li>
            <input
              type="checkbox"
              id={`loss_odds_${odds.loss.id}`}
              name="cheer_team"
              className="blind"
              value="loss"
              checked={selectOddsId === odds.loss.id}
            />
            <label
              htmlFor={`loss_odds_${odds.loss.id}`}
              className={clsx(odds.loss.hit && 'selected')}
            >{`${isUnover ? 'O' : 'L'} ${odds.loss.odds}`}</label>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProtoMyPickDetailItem;
