import autobind from 'autobind-decorator';
import { computed, observable, runInAction } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { Url } from '../constants/environment';
import Cookie from '../utils/cookie';
import { LotteryService, ThemeService } from '../services';

@autobind
class GameStore {
  modalStore;

  constructor(stores) {
    this.modalStore = stores.modalStore;
  }

  @observable
  code = 200;

  @observable
  message = '';

  @observable
  data = {
    comment: {
      list: [],
      total_cnt: 0
    },
    dist: {
      powerball_a_section_count: 0,
      powerball_a_section_percent: 0,
      powerball_b_section_count: 0,
      powerball_b_section_percent: 0,
      powerball_c_section_count: 0,
      powerball_c_section_percent: 0,
      powerball_d_section_count: 0,
      powerball_d_section_percent: 0,
      powerball_even_count: 0,
      powerball_even_over_count: 0,
      powerball_even_over_percent: 0,
      powerball_even_percent: 0,
      powerball_even_under_count: 0,
      powerball_even_under_percent: 0,
      powerball_odd_count: 0,
      powerball_odd_over_count: 0,
      powerball_odd_over_percent: 0,
      powerball_odd_percent: 0,
      powerball_odd_under_count: 0,
      powerball_odd_under_percent: 0,
      powerball_over_count: 0,
      powerball_over_percent: 0,
      powerball_under_count: 0,
      powerball_under_percent: 0,

      last_even_count:0,
      last_even_percent:0,
      last_odd_count:0,
      last_odd_percent:0,
      last_over_count:0,
      last_over_percent:0,
      last_under_count:0,
      last_under_percent:0,

      sum_even_count: 0,
      sum_even_large_count: 0,
      sum_even_large_percent: 0,
      sum_even_medium_count: 0,
      sum_even_medium_percent: 0,
      sum_even_over_count: 0,
      sum_even_over_percent: 0,
      sum_even_percent: 0,
      sum_even_small_count: 0,
      sum_even_small_percent: 0,
      sum_even_under_count: 0,
      sum_even_under_percent: 0,
      sum_large_count: 0,
      sum_large_percent: 0,
      sum_medium_count: 0,
      sum_medium_percent: 0,
      sum_odd_count: 0,
      sum_odd_large_count: 0,
      sum_odd_large_percent: 0,
      sum_odd_medium_count: 0,
      sum_odd_medium_percent: 0,
      sum_odd_over_count: 0,
      sum_odd_over_percent: 0,
      sum_odd_percent: 0,
      sum_odd_small_count: 0,
      sum_odd_small_percent: 0,
      sum_odd_under_count: 0,
      sum_odd_under_percent: 0,
      sum_over_count: 0,
      sum_over_percent: 0,
      sum_small_count: 0,
      sum_small_percent: 0,
      sum_under_count: 0,
      sum_under_percent: 0,
      total_powerball_odd_even_count: 0,
      total_powerball_odd_even_under_over_count: 0,
      total_powerball_section_count: 0,
      total_powerball_under_over_count: 0,
      total_sum_odd_even_count: 0,
      total_sum_odd_even_size_count: 0,
      total_sum_odd_even_under_over_count: 0,
      total_sum_size_count: 0,
      total_sum_under_over_count: 0,
      even_count: 0,
      even_percent: 0,
      l3e_count: 0,
      l3e_percent: 0,
      l4o_count: 0,
      l4o_percent: 0,
      line_four_count: 0,
      line_four_percent: 0,
      line_three_count: 0,
      line_three_percent: 0,
      odd_count: 0,
      odd_percent: 0,
      r3o_count: 0,
      r3o_percent: 0,
      r4e_count: 0,
      r4e_percent: 0,
      start_left_count: 0,
      start_left_percent: 0,
      start_right_count: 0,
      start_right_percent: 0,
      total_combination_count: 0,
      total_line_count: 0,
      total_odd_even_count: 0,
      total_start_count: 0,
      over_count: 0,
      over_percent: 0,
      snail1_count: 0,
      snail1_percent: 0,
      snail2_count: 0,
      snail2_percent: 0,
      snail3_count: 0,
      snail3_percent: 0,
      snail4_count: 0,
      snail4_percent: 0,
      total_snail_count: 0,
      total_under_over_count: 0,
      under_count: 0,
      under_percent: 0,

      home_count_win_count: 0,
      away_count_win_count: 0,
      draw_count_win_count: 0,
      home_sum_win_count: 0,
      away_sum_win_count: 0,
      draw_sum_win_count: 0,
      sum_home_ball_odd_count: 0,
      sum_home_ball_even_count: 0,
      sum_home_ball_zero_count: 0,
      sum_away_ball_odd_count: 0,
      sum_away_ball_even_count: 0,
      sum_away_ball_zero_count: 0,
      home_ball1_odd_count: 0,
      home_ball1_even_count: 0,
      home_ball1_oe_zero_count: 0,
      away_ball1_odd_count: 0,
      away_ball1_even_count: 0,
      away_ball1_oe_zero_count: 0,
      home_ball1_under_count: 0,
      home_ball1_over_count: 0,
      home_ball1_uo_zero_count: 0,
      away_ball1_under_count: 0,
      away_ball1_over_count: 0,
      away_ball1_uo_zero_count: 0,

      home_count_win_percent: 0,
      away_count_win_percent: 0,
      draw_count_win_percent: 0,
      home_sum_win_percent: 0,
      away_sum_win_percent: 0,
      draw_sum_win_percent: 0,
      sum_home_ball_odd_percent: 0,
      sum_home_ball_even_percent: 0,
      sum_home_ball_zero_percent: 0,
      sum_away_ball_odd_percent: 0,
      sum_away_ball_even_percent: 0,
      sum_away_ball_zero_percent: 0,
      home_ball1_odd_percent: 0,
      home_ball1_even_percent: 0,
      home_ball1_oe_zero_percent: 0,
      away_ball1_odd_percent: 0,
      away_ball1_even_percent: 0,
      away_ball1_oe_zero_percent: 0,
      home_ball1_under_percent: 0,
      home_ball1_over_percent: 0,
      home_ball1_uo_zero_percent: 0,
      away_ball1_under_percent: 0,
      away_ball1_over_percent: 0,
      away_ball1_uo_zero_percent: 0,
    }
  };

  @asyncAction
  *getLotteryPickList(param = {}) {
    const { gameType, pickType, limit, offset, date, round } = param;
    const res = yield LotteryService.getLotteryPickList(
      gameType,
      pickType,
      limit,
      offset,
      date,
      round
    );

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data.dist = res.data.dist;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }
  }

  @asyncAction
  *getLotteryPickCommentList(param = {}) {
    const {
      gameType,
      pickType,
      limit,
      offset,
      date,
      round,
      concat = true
    } = param;
    const res = yield LotteryService.getLotteryPickList(
      gameType,
      pickType,
      limit,
      offset,
      date,
      round
    );

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        if (concat) {
          this.data.comment.list = this.data.comment.list.concat(
            res.data.comment.list
          );
        } else {
          this.data.comment.list = res.data.comment.list;
        }
        this.data.comment.total_cnt = res.data.comment.total_cnt;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }

    return res;
  }

  @asyncAction
  *insertLotteryPickComment(param = {}) {
    const { gameType, pickType, pick, pickComment } = param;
    const res = yield LotteryService.insertLotteryPickComment(
      gameType,
      pickType,
      pick,
      pickComment
    );

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: '등록되었습니다.'
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }
  }

  @asyncAction
  *getThemePickList(param = {}) {
    const { gameType, pickType, limit, offset, date, round } = param;
    const res = yield ThemeService.getThemePickList(
        gameType,
        pickType,
        limit,
        offset,
        date,
        round
    );

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data.dist = res.data.dist;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }
  }

  @asyncAction
  *getThemePickCommentList(param = {}) {
    const {
      gameType,
      pickType,
      limit,
      offset,
      date,
      round,
      concat = true
    } = param;
    const res = yield ThemeService.getThemePickList(
        gameType,
        pickType,
        limit,
        offset,
        date,
        round
    );

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        if (concat) {
          this.data.comment.list = this.data.comment.list.concat(
              res.data.comment.list
          );
        } else {
          this.data.comment.list = res.data.comment.list;
        }
        this.data.comment.total_cnt = res.data.comment.total_cnt;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }

    return res;
  }

  @asyncAction
  *insertThemePickComment(param = {}) {
    const { gameType, pickType, pick, pickComment } = param;
    const res = yield ThemeService.insertThemePickComment(
        gameType,
        pickType,
        pick,
        pickComment
    );

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: '등록되었습니다.'
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }
  }
}

export default GameStore;


