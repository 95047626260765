import React from 'react';
import { observer } from 'mobx-react';
import { CertEmailComponent } from '../../../components/cert';
import useUsersStore from '../../../hooks/useUsersStore';

const Pw2SetEmail = observer(({ history }) => {
  const usersStore = useUsersStore();
  const resultPath = 'mypage/pw2_set_result';

  const onEmail = (res) => {
    if (res.code === 200) {
      history.push(
        `/mypage/pw2_set_result?certify_token=${res.data['certify_token']}`
      );
    }
  };

  return (
    <CertEmailComponent
      email={usersStore.data.email}
      resultPath={resultPath}
      history={history}
    />
  );
});

export default Pw2SetEmail;
