import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import useUsersStore from '../../../hooks/useUsersStore';

const TodayWordChange = observer(({ history, onClose, category = '' }) => {
  const usersStore = useUsersStore();
  const { mypageStore } = useMypageStore(MypageContext);
  const [todayWord, setTodayWord] = useState('');

  const onClickTodayWordChange = () => {
    if (usersStore.data.certify.is_phone_certify) {
      updateTodayWord();
    } else {
      usersStore.modalStore.openConfirmModal({
        message: `오늘의 한마디 변경 아이템을 사용 하시겠습니까?`,
        okCallback: () => {
          updateTodayWord();
        }
      });
    }
  };

  const updateTodayWord = () => {
    const pathname = history.location.pathname.replace('/mypage/', '');
    mypageStore.actionTodayWordChange({ todayWord, pathname }, () => {
      if (pathname === 'info') {
        usersStore.getInfo();
      } else {
        mypageStore.actionMyItemList({
          category: category === 'all' ? '' : category
        });
      }
      onClose();
    });
  };

  const onClickCancel = () => {
    onClose();
  };

  const goMarket = () => {
    history.push('/mypage/market');
  };

  return (
    <>
      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">오늘의 한마디</h2>
          <button className={'btn_close'} onClick={onClickCancel} />
        </div>
        <div className="info_change_area">
          <div className="inner">
            {usersStore.data.certify.is_phone_certify ? (
              <p className="tx">
                오늘의 한마디 변경권(<strong>본인인증 완료</strong>)
              </p>
            ) : (
              <p className="tx">
                오늘의 한마디 변경권{' '}
                <strong>
                  {usersStore.data.item.change_today_word
                    ? usersStore.data.item.change_today_word
                    : 0}
                  개
                </strong>{' '}
                보유중
              </p>
            )}
            <div className="item">
              <input
                type="text"
                className="inputbox"
                autoComplete="off"
                tabIndex="1"
                title="오늘의 한마디를 입력해주세요"
                placeholder="오늘의 한마디를 입력해주세요."
                onChange={(e) => {
                  setTodayWord(e.target.value);
                }}
              />
            </div>
            <p className="error">
              오늘의 한마디는 한글,숫자,영문,띄어쓰기( ),마침표(.),쉼표(,)만
              가능합니다.
            </p>
          </div>
          <div className="btn_area">
            <button type="submit" className="btn" onClick={onClickCancel}>
              취소
            </button>
            <button
              type="submit"
              className="btn btn_submit"
              onClick={onClickTodayWordChange}
            >
              변경하기
            </button>
          </div>
        </div>
        <div className="tip">
          <p>
            변경권이 없으신가요?{' '}
            <a className="lnk" href="javascript:;" onClick={goMarket}>
              오늘의 한마디 구매하기
            </a>
          </p>
          <p className="c_999">
            사이트 규정에 어긋나는 용도로 사용시 제재의 대상이 됩니다.
          </p>
          <p className="c_999">
            한글 20글자, 영문 40글자 까지 입력가능합니다.(한글, 숫자, 영문,
            띄어쓰기, 마침표, 쉼표만 가능)
          </p>
        </div>
      </div>
    </>
  );
});

export default TodayWordChange;
