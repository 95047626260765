import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import useUsersStore from '../../../hooks/useUsersStore';
import useModalStore from '../../../hooks/useModalStore';
import { ImageUtil } from '../../../common/utils/imageUtil';
import { ItemInfo, Buttons, Form, TradeInfo } from './styled';
import clsx from 'clsx';
import { RegexUtil } from '../../../common/utils/regexUtil';
import Pw2 from '../pw2';
import { PopupModal } from '../../../components/modal/popup/popupModal';

const TradeDetail = observer(({ history, item, tab, onClose }) => {
  const usersStore = useUsersStore();
  const modalStore = useModalStore();
  const { mypageStore } = useMypageStore(MypageContext);

  const [message, setMessage] = useState('');
  const [myself, setMyself] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const { is_second_password, second_password_date } = usersStore.data;

  const {
    image,
    item_name,
    item_desc,
    title,
    point,
    seller_nick,
    buyer_nick
  } = item;

  const onSubmitCallback = (code) => {
    if (code === 200) {
      setOpen(false);
      usersStore.data.is_second_password = true;
    }
  };

  useEffect(() => {
    if (
      (tab === 'sell' && item.seller_nick === usersStore.data.nick) ||
      (tab === 'buy' && buyer_nick === usersStore.data.nick)
    ) {
      setMyself(true);
      setMessage('본인이 등록한 글입니다.');
    } else {
      if (!item.my_item_no) {
        setMessage('판매 가능한 아이템이 없습니다');
      } else {
        setMessage('판매가능 해당아이템을 보유중입니다.');
      }
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
//    if (second_password_date) {
//      if (is_second_password) {
//
//      } else {
//        setOpen(true);
//      }
//    } else {
//      modalStore.openAlertModal({
//        message: '2차 비밀번호 등록 후 이용 가능 합니다.',
//        callback: () => {
//          history.push('/mypage/info');
//        }
//      });
//    }
      if (myself) {
        modalStore.openConfirmModal({
          message: `등록된 ${item_name} 아이템을 취소하시겠습니까?`,
          okCallback: () => {
            mypageStore.actionTradeMarketCancel({
              type: tab,
              no: item.no,
              point: point
            });
          }
        });
      } else {
        if (tab === 'sell') {
          modalStore.openConfirmModal({
            message: `${item_name} 아이템을 포인트로 구매하시겠습니다.`,
            okCallback: () => {
              mypageStore.actionTradeMarketBuy({
                no: item.no,
                itemName: item_name,
                point: point
              });
            }
          });
        } else {
          modalStore.openConfirmModal({
            message: `아이템을 ${item.point}포인트에 판매 하시겠습니다.`,
            okCallback: () => {
              mypageStore.actionTradeMarketSell({
                no: item.no,
                myItemNo: item.my_item_no,
                itemName: item_name,
                point: point
              });
            }
          });
        }
      }
  };

  return (
    <>
      <PopupModal isOpen={isOpen} onClose={() => setOpen(false)}>
        <Pw2
          onClose={() => setOpen(false)}
          onSubmitCallback={onSubmitCallback}
        />
      </PopupModal>
      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">{tab === 'sell' ? '구매하기' : '판매하기'}</h2>
          <button className={'btn_close'} onClick={onClose} />
        </div>
        <ItemInfo>
          <img src={ImageUtil.imageUrl(image, 50, 50)} />
          <p className={'name'}>{item_name}</p>
          <p className={'desc'}>{item_desc}</p>
        </ItemInfo>
        <Form onSubmit={onSubmit}>
          <TradeInfo>
            <div>
              <span>거래방식</span>
              <div>{tab === 'sell' ? '팝니다' : '삽니다'}</div>
            </div>
            <div>
              <span>판매가격</span>
              <div className={'point'}>{RegexUtil.makeComma(point)} P</div>
            </div>
            <div>
              <span>등록자</span>
              <div>{tab === 'sell' ? seller_nick : buyer_nick}</div>
            </div>
            <div>
              <span>거래코멘트</span>
              <div>{title}</div>
            </div>
          </TradeInfo>
          {myself && <p>{message}</p>}
          <Buttons>
            <button type={'button'} onClick={onClose}>
              취소
            </button>
            <button
              type={'submit'}
              className={clsx(tab === 'sell' ? 'buy' : 'sell')}
            >
              {myself ? '거래취소' : tab === 'sell' ? '구매' : '판매'}
            </button>
          </Buttons>
        </Form>
        <div className="tip">
          <p className="c_999">
            거래 아이템에는 보너스 포인트가 포함되지 않습니다.
          </p>
          <p className="c_999">
            거래를 통해 획득한 아이템은 선물하거나 다시 거래 등록할 수 없습니다.
          </p>
        </div>
      </div>
    </>
  );
});

export default TradeDetail;
