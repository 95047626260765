import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  > p {
    font-size: 13px;
    color: #ed2026;
    font-weight: 600;
    margin: 10px 0;
    text-align: center;
  }
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  button {
    flex: 1;
    font-size: 14px;
    color: #fff;
    height: 44px;
    background-color: #8e8e8e;
    &.buy {
      background-color: #ed2026;
      margin-left: 5px;
    }
    &.sell {
      background-color: #3989be;
      margin-left: 5px;
    }
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 156px;
  > img {
    height: 50px;
    margin-bottom: 19px;
  }
  .name {
    font-size: 20px;
    line-height: 19px;
    font-weight: 600;
  }
  .desc {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
    text-align: center;
    padding: 0 20px;
  }
  border-bottom: 1px solid #e5e5e5;
`;

export const TradeInfo = styled.div`
  padding: 0 10px;
  margin-top: 15px;
  > div {
    display: flex;
    span {
      line-height: 40px;
      min-width: 70px;
      font-size: 12px;
      color: #999;
    }
    > div {
      font-size: 14px;
      color: #222;
      line-height: 16px;
      padding: 11px 0;
      &.point {
        color: #1287eb;
      }
    }
  }
`;
