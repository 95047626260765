import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageGifticonList } from '../components';
import { MypageContext, useMypageStore } from '../context';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { RegexUtil } from '../../../common/utils/regexUtil';
import { CategoryList } from '../../../style/myPageStyled';
import { Amass, Cash, ChargeButton, Asset_box, Empty } from './styled';
import Seo from '../../../components/seo';
import { Url } from "../../../common/constants/environment";

const CATEGORY_ALL = '0'
const CATEGORY_ALL_NAME = '전체'

const Gifticon = observer(({ history }) => {
  const location = useLocation();
  const category = location.pathname?.replace("/mypage/gifticon", "")?.replace("/", "") || CATEGORY_ALL;

  const { mypageStore } = useMypageStore(MypageContext);

  useEffect(() => {
    mypageStore.actionGifticonCategoryList();
  }, [])

  useEffect(() => {
    mypageStore.actionGifticonCategoryDetailList(category);

    // Scroll to the selected category
    if (category && !isNaN(category)) {
      const index = category
      setTimeout(() => {
        handleItemClick(null, index);
      }, 1000);
    }
  }, [category]);

  const onClickCharge = () => {
    window.location = '/mypage/cash/request-charge'
  };


  const ulRef = React.useRef(null);

  const handleItemClick = (e, index) => {
    if (e) {
      e.preventDefault();
    }

    const li = ulRef.current.children[index];
    if (li) {
      li.scrollIntoView({
        behavior: e ? "smooth" : 'auto',
        block: "nearest",
        inline: "center",
      });
    }
  };

  return (
    <>
      <Seo title={'마이페이지 마켓'} />
      <Cash>
        <Asset_box>
          <Amass>
            <dt>보유 캐시</dt>
            <dd className={'cash'}>{RegexUtil.makeComma(mypageStore.data?.total_cash ?? "0")}</dd>
          </Amass>
          <Amass>
            <dt>건빵</dt>
            <dd className={'medal'}>{RegexUtil.makeComma(mypageStore.data?.total_medal ?? "0")}</dd>
          </Amass>
          <Amass>
            <dt>포인트</dt>
            <dd className={'point'}>{RegexUtil.makeComma(mypageStore.data?.total_point ?? "0")}</dd>
          </Amass>
        </Asset_box>
        <ChargeButton onClick={onClickCharge}>충전하기</ChargeButton>
      </Cash>
      <CategoryList ref={ulRef}>
        <li key={CATEGORY_ALL} onClick={(e) => handleItemClick(e, 0)}>
          <Link
            to={`/mypage/gifticon`}
            className={clsx(category === CATEGORY_ALL && 'selected')}
          >
            {CATEGORY_ALL_NAME}
          </Link>
        </li>
        {mypageStore.data?.gifticon_categories?.map((item, key) => {
          return (
            <li key={key} onClick={(e) => handleItemClick(e, key + 1)}>
              <Link
                to={`/mypage/gifticon/${item.id}`}
                className={clsx(category === item.id && 'selected')}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </CategoryList>
      {
        mypageStore.data?.gifticon_list ? <MypageGifticonList items={mypageStore.data?.gifticon_list} history={history} /> : null
      }
      {
        !mypageStore.data.fetching_gifticon_list && mypageStore.data?.gifticon_list?.length === 0 ?
          <Empty>
            <div>
              <span>곧 판매 예정입니다.</span>
            </div>
          </Empty> : null
      }

    </>
  );
});

export default Gifticon;
