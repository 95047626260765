import styled, { css } from 'styled-components';

export const MatchBox = styled.div`
  width: 100%;
  background: #fff;
  margin-top: 8px;
`;

export const MatchTab = styled.div`
  cursor: pointer;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 12px;
  color: #222;

  &:before {
    content: '';
    display: block;
    width: 11px;
    height: 7px;
    margin: 0 15px;
    transform: translateY(-50%);
    right: 0;
    top: 50%;
    position: absolute;
    background: url('/images/sp_layout.png') no-repeat -225px -200px;
    background-size: 350px 350px;

    ${(props) =>
      props.isClose &&
      css`
        background-position: -225px -190px;
      `}
  }
`;

export const GamesWrapper = styled.div`
  width: 100%;
  display: none;
  ${(props) =>
    props.isActive &&
    css`
      display: block;
    `}
`;
