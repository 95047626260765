import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import CommunityDetailView from './view';
import useTypeStore from '../../../../hooks/useTypeStore';
import SportsLoader from '../../../../components/sportsLoader';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import Blind from './blind';
import useModalStore from '../../../../hooks/useModalStore';

const Detail = observer(({ match, isOpen }) => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page'));
  const gameId = query.get('gameId');
  const sportsType = query.get('sportsType');
  const sportsId = query.get('sportsId');
  const fromHome = query.get('fromHome') === 'true';
  const category = location.pathname.replace('/community/', '');

  const modalStore = useModalStore();
  const communityStore = useTypeStore('communityStore');
  const [isBlindView, setBlindView] = useState(false);
  const bodyScroll = useRef();

  useEffect(() => {
    communityStore
      .actionDetail({
        wr_id: match.params.id,
        board_type: match.params.type
      })
      .then((r) => {
        if (parseInt(communityStore.result.report_count) >= 5) {
          setBlindView(true);
        }
      });
  }, [communityStore, match.params.id, match.params.type]);

  const onClickRecommend = async () => {
    await communityStore.actionRecommend({
      wr_id: match.params.id,
      board_type: match.params.type
    });
  };

  const openReport = () => {
    modalStore.openConfirmModal({
      message: `신고를 접수하시겠습니까?`,
      okCallback: () => {
        const params = {
          category: 'board',
          relId: match.params.id,
          relTable: '@' + match.params.type,
          targetId: communityStore.result.mb_id
        };
        communityStore.actionReport(params);
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(bodyScroll.current);
    }
    return () => {
      setBlindView(false);
      enableBodyScroll(bodyScroll.current);
    };
  }, [isOpen]);

  const categoryMap = {
    free: '자유',
    humor: '유머',
    photo: '포토',
    // trade: '모의투자',
    analysis: '분석',
    park: '미니게임',
    crime: '피해공유',
    // betble: '벳블공유',
    sports_analysis: '경기 분석',
    notice: '공지'
  };

  const onClickClose = (event) => {
    event.preventDefault();

    if (fromHome) {
      history.goBack();
      return;
    }

    if (page) {
      history.replace(`/community/${category}?page=${page}`);
    } else {
      history.goBack();
    }
  };

  return (
    <Background view={isOpen}>
      {!communityStore.detailLoading && (
        <Header>
          {`${categoryMap[match.params.type]} 게시글 보기`}
          <a className="category" onClick={onClickClose} />
        </Header>
      )}
      <Wrap className="board_wrap" ref={bodyScroll}>
        {!communityStore.detailLoading ? (
          isBlindView ? (
            <Blind
              page={page || 1}
              category={category}
              setBlindView={setBlindView}
            />
          ) : (
            <CommunityDetailView
              item={communityStore.result}
              type={match.params.type}
              actionCommentAdd={communityStore.actionCommentAdd}
              actionDelete={communityStore.actionDelete}
              openReport={openReport}
              onClickRecommend={onClickRecommend}
              page={page || 1}
              category={category}
              gameId={gameId}
              sportsId={sportsId}
              sportsType={sportsType}
            />
          )
        ) : (
          isOpen && <SportsLoader />
        )}
      </Wrap>
    </Background>
  );
});

const Background = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 199;
  background-color: rgba(0, 0, 0, 0.5);
  ${(props) =>
    !props.view &&
    css`
      visibility: hidden;
    `}
`;

const Wrap = styled.div`
  padding-top: 50px;
  box-sizing: border-box;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  z-index: 10;
  background-color: #3498db;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  > a {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 16px;
      left: calc(50% - 1px);
      width: 2px;
      height: 18px;
      background: #fff;
    }
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
`;

export default Detail;
