import React, { useState, useEffect } from 'react';
import useUsersStore from '../../../../../hooks/useUsersStore';
import { MypageContext, useMypageStore } from '../../../context';

const Item = ({ item }) => {
  const usersStore = useUsersStore();
  const { mypageStore } = useMypageStore(MypageContext);
  const [isLoading, setIsLoading] = useState(false);

  const onClickDelete = () => {
    if (window.confirm(`${item.ip}를 삭제하시겠습니까?`)) {
      mypageStore.actionIpDelete({ ip: item.ip }, () => usersStore.getInfo());
    }
  };

  useEffect(() => {
    if (!item) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {!isLoading ? (
        <ul style={{ clear: 'both' }}>
          <li style={{ float: 'left', marginLeft: '10px' }}>{item.ip_name}</li>
          <li style={{ float: 'left', marginLeft: '10px' }}>{item.ip}</li>
          <li style={{ float: 'left', marginLeft: '10px' }}>{item.reg_dt}</li>
          <li style={{ float: 'left', marginLeft: '10px' }}>
            <button type="button" onClick={onClickDelete}>
              삭제하기
            </button>
          </li>
        </ul>
      ) : (
        <></>
      )}
    </>
  );
};

export { Item };
