import React, { useEffect, useMemo, useState } from "react";
import AnalysisOption from "../../analysisOption";
import BarChart from "./barChart";
import RoundTable from "./roundTable";
import PatternGraph from "./patternGraph";
import MinMaxWithinPeriod from "./minMaxWithinPeriod";
import CountPattern from "../../countPattern";
import PatternBarChart from "./patternBarChart";
import PatternTable from "../../patternTable";
import { useLocation } from "react-router-dom";
import { timeUtils } from "../../../../../common/utils/timeUtil";
import { LotteryService } from "../../../../../common/services";
import DhBarChart from "./barChart/DhBarChart";
import DhRoundTable from "./roundTable/DhRoundTable";
import DhPatternBarChart from "./patternBarChart/DhPatternBarChart";


const todayDate = timeUtils.todayYmd();

const PowerballAnalysis = ({ brandName, gameName }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get("type") || "daily";
  const option = query.get("option") || "oneDay";
  const startDate = query.get("start_dt") || todayDate;
  const endDate = query.get("end_dt") || todayDate;
  const round = parseInt(query.get("round")) || 100;
  const count = parseInt(query.get("count")) || 10;


  const [data, setDate] = useState({
    count: {},
    list: [],
    percent: {}
  });
  const [patternData, setPatternData] = useState({
    list: [],
    percent: {},
    search_pattern: "",
    search_pattern_round: ""
  });

  const getGameTypeParameter = () => {
    if (brandName === "dhlottery") {
      switch (gameName) {
        case "powerball":
          return "dh_powerball";
      }
    } else if (brandName === "redball") {
      return "redball_powerball";
    } else {
      switch (gameName) {
        case "powerball":
        case "powerball5":
          return "power_ball";
        case "powerball3":
          return "three_powerball";
      }
    }
  };
  useEffect(() => {
    let params = {};
    if (type === "daily") {
      if (option === "oneDay") {
        params = { startDate: startDate, endDate: startDate };
      } else {
        params = {
          startDate: startDate,
          endDate: endDate,
          searchType: "daily_range"
        };
      }
      params.gameType = getGameTypeParameter();
      LotteryService.getLotteryStatAnalysis({ ...params }).then((res) => {
        setDate(res.data);
      });
    } else if (type === "round") {
      if (option === "single") {
        params = { startDate: startDate, round: round };
      } else {
        params = { roundCount: round };
      }
      params.gameType = getGameTypeParameter();
      LotteryService.getLotteryStatAnalysis({ ...params }).then((res) => {
        setDate(res.data);
      });
    } else if (type === "pattern") {
      params = { gameType: getGameTypeParameter(), patternType: option, count: count };
      LotteryService.getLotteryStatAnalysisPattern({ ...params }).then(
        (res) => {
          setPatternData(res.data);
        }
      );
    }
  }, [type, option, round, startDate, count]);


  const isDhLottery = useMemo(() => {
    return brandName === "dhlottery";
  }, [brandName]);


  const optionsMap = useMemo(() => {
    return {
      daily: [
        { value: "oneDay", name: "하루씩보기" },
        { value: "period", name: "기간으로보기" }
      ],
      round: [
        { value: "single", name: "단일회차" },
        { value: "latest", name: "최근회차" }
      ],
      pattern:
        !isDhLottery ? [
            { value: "powerball_odd_even", name: "파워볼 홀짝" },
            { value: "powerball_under_over", name: "파워볼 언오버" },
            { value: "powerball_section", name: "파워볼 구간" },
            { value: "powerball_odd_even_under_over", name: "파워볼 홀짝+언오버" },
            { value: "sum_odd_even", name: "일반볼합 홀짝" },
            { value: "sum_under_over", name: "일반볼합 언오버" },
            { value: "sum_size", name: "일반볼합 대중소" },
            { value: "sum_odd_even_under_over", name: "일반볼합 홀짝+언오버" },
            { value: "sum_odd_even_size", name: "일반볼합 홀짝+대중소" }
          ] :
          [
            { value: "sum_odd_even", name: "일반볼합 홀짝" },
            { value: "sum_size", name: "일반볼합 대중소" },
            { value: "powerball_under_over", name: "파워볼 언오버" }
          ]
    };

  }, [isDhLottery]);


  const barComponent = useMemo(() => {
    if (isDhLottery) {
      return <DhBarChart count={data.count} />;
    } else {
      return <BarChart count={data.count} />;
    }
  }, [isDhLottery, data.count]);


  const roundTableComponent = useMemo(() => {
    if (isDhLottery) {
      return <DhRoundTable list={data.list} />;
    } else {
      return <RoundTable list={data.list} />;
    }
  }, [isDhLottery, data.list]);

  const patternBarComponent = useMemo(() => {
    if (isDhLottery) {
      return <DhPatternBarChart percent={patternData.percent} />;
    } else {
      return <PatternBarChart percent={patternData.percent} />;
    }
  }, [[isDhLottery, patternData.percent]]);


  return (
    <div className="game_stats_area">
      <AnalysisOption
        options={optionsMap[type]}
        patternTypeList={optionsMap.pattern}
      />
      <div className="date_detail_wrap">
        {(type !== "pattern") && barComponent}
        {(option === "oneDay" || option === "latest") && (
          <PatternGraph
            gameName={getGameTypeParameter()}
            patternTypeList={optionsMap.pattern}
          />
        )}
        {option === "period" && <MinMaxWithinPeriod data={data} />}
        {option !== "period" && type !== "pattern" && (
          roundTableComponent
        )}

        {type === "pattern" && (
          <>
            <CountPattern
              title={`${
                optionsMap.pattern.find((item) => item.value === option).name
              } 기준 ${count}패턴`}
              searchPattern={patternData.search_pattern}
              searchPatternRound={patternData.search_pattern_round}
            />
            {patternBarComponent}
            <PatternTable
              list={patternData.list}
              option={option}
              count={count}
            />
          </>
        )}
      </div>
    </div>
  );
};

export { PowerballAnalysis };
