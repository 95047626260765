// This function will return the hostname of the production server
// based on the current hostname of the window.
const getProductionHostname = () => {
  if (typeof window !== 'undefined' && window.location) {
    const hostname = window.location.hostname;

    switch (hostname) {
      case 'm.named.com':
      case 'm-stage.named.com':
        return 'named.com';
      case 'm.nmd.co':
      case 'm-stage.nmd.co':
        return 'nmd.co';
      default:
        return 'named.com';
    }
  }

  return 'named.com';
};

const getUrl = () => {
  const env = process.env.REACT_APP_API;

  // const env = "production";

  let sports = 'http://172.16.20.93:8090/v1.0';
  let named = 'http://beta.challenger-api.named.com/';
  let namedChallenger = 'https://challenger-api.named.com/';
  let namedApi = 'https://nmd.co/';
  let thumb = 'http://thumb.named.com/';
  let legacySports = 'http://172.16.20.93:8092/named/v1';
  let webSocket = 'ws://172.16.20.96:8880/sub';
  let sportsWebURL = 'http://dev.score.named.com/';
  let casterWebURL = 'http://dev.caster.named.com/';
  let legacyApiKey = 'vYXBNAxjFCre';
  let dataThumbUrl = thumb + 'normal/resize/origin';
  let jsonPath = '/data';
  let namedWeb = 'https://nmd.co/';
  let hostNamedWeb = 'named.com';
  let imageNamed = 'https://image.named.com/';

  let champ = 'https://arowana-api.champscore.com/';
  let champThumb = 'https://thumb.champscore.com';
  let champWebSocket = 'wss://push.champscore.com/sub';

  const prodHostName = getProductionHostname();

  switch (env) {
    case 'development':
      sports = 'http://52.78.195.200:8090/v1.0';
      webSocket = 'ws://52.78.195.200:8880/sub';
      named = 'http://dev.challenger-api.named.com/';
      namedChallenger = 'http://dev.challenger-api.named.com/';
      namedApi = 'http://dev.named.com/';
      thumb = 'http://52.78.195.200:22010/';
      legacySports = 'http://52.78.195.200:8092/named/v1';
      sportsWebURL = 'http://dev.score.named.com/';
      casterWebURL = 'http://dev.caster.named.com/';
      legacyApiKey = 'vYXBNAxjFCre';
      namedWeb = 'http://dev.named.com/';

      hostNamedWeb = 'dev.named.com';
      imageNamed = 'https://image.nmd.co/';

      //  TODO 챔프스코어 세팅 후 변경
      champ = 'http://mschoo.arowana-api.fishtank.com/';
      champThumb = 'http://dev-thumb.champscore.com';
      champWebSocket = 'ws://172.16.20.103:8880/sub';
      break;

    //      sports = 'http://52.78.195.200:8090/v1.0';
    //      webSocket = 'ws://52.78.195.200:8880/sub';
    //      named = 'http://dev.challenger-api.named.com/';
    //      namedApi = "http://dev.named.com/";
    //      thumb = 'http://52.78.195.200:22010/';
    //      legacySports = 'http://52.78.195.200:8092/named/v1';
    //      sportsWebURL = 'http://dev.score.named.com/';
    //      casterWebURL = 'http://dev.caster.named.com/';
    //      legacyApiKey = 'vYXBNAxjFCre';
    //      namedWeb = 'http://dev.named.com';
    //
    //      //  TODO 챔프스코어 세팅 후 변경
    //      champ = 'http://mschoo.arowana-api.fishtank.com/';
    //      champThumb = 'http://dev-thumb.champscore.com';
    //      champWebSocket = 'ws://172.16.20.103:8880/sub';
    //      break;

    case 'beta':
      sports = 'http://172.16.20.163:8080/v1.0';
      named = 'http://beta.challenger-api.named.com/';
      namedChallenger = 'https://challenger-api.named.com/';
      namedApi = 'https://named.com/';
      thumb = 'http://thumb.named.com/';
      legacySports = 'http://172.16.20.164:8092/named/v1';
      webSocket = 'ws://172.16.20.166:8880/sub';
      sportsWebURL = 'http://beta.score.named.com/';
      casterWebURL = 'http://beta.caster.named.com/';
      legacyApiKey = '8HNjAFFfmQoJ';
      champ = 'http://mschoo.arowana-api.fishtank.com/';
      champThumb = 'http://dev-thumb.champscore.com';
      champWebSocket = 'ws://172.16.20.103:8880/sub';
      break;

    case 'production':
      sports = `https://sports-api.${prodHostName}/v1.0`;
      webSocket = `wss://push.${prodHostName}/sub`;
      named = `https://challenger-api.${prodHostName}/`;
      namedChallenger = `https://challenger-api.named.com/`;
      namedApi = `https://${prodHostName}/`;
      thumb = `https://thumb.${prodHostName}/`;
      legacySports = `https://score-api.${prodHostName}/named/v1`;
      sportsWebURL = `https://score.${prodHostName}/`;
      casterWebURL = `https://sports-caster.${prodHostName}/`;
      legacyApiKey = '1rar2zCZvKjp';
      jsonPath = '/data';

      namedWeb = `https://${prodHostName}/`;
      hostNamedWeb = `${prodHostName}`;
      imageNamed = `https://image.${prodHostName}/`;
      break;

    default:
      break;
  }

  return {
    sports,
    named,
    namedChallenger,
    namedApi,
    legacySports,
    thumb,
    webSocket,
    sportsWebURL,
    casterWebURL,
    legacyApiKey,
    dataThumbUrl,
    dataJsonUrl: jsonPath,
    champ,
    champThumb,
    champWebSocket,
    namedWeb,
    hostNamedWeb,
    imageNamed,
    env,
  };
};

const getConstants = () => {
  const _GOOGLE_SITEKEY = '6LeWIwYTAAAAAM2ObtS4oKnokC_ZecnRZp2GChm0';

  return {
    _GOOGLE_SITEKEY,
  };
};

const Url = getUrl();
const Constants = getConstants();

module.exports = { Url, Constants };
