import React from 'react';
import {Url} from "../../common/constants/environment";

const BoardViewHtml = () => {
  return (
    <>
      {/* board_wrap */}
      <div className="board_wrap">

        {/* board_view */}
        <div className="board_view">

          {/* view_area */}
          <div className="view_area">
            <h3 className="blind">게시글</h3>
            <div className="summary">
              <a className="category" href="javascript:;">유머</a>
              <p className="subject">소개팅에서 인생영화가 뭐냐는 질문을 받는다화가 뭐냐는 질문을 받는다면 당신은</p>
              <p className="article_info">
                <span className="date">2020.09.01 00:00</span>
                <span className="count">조회 386</span>
              </p>
              <div className="user_info">
                <a className="lnk_user" href="javascript:;">
                  <span className="thumb lv17">
                      <img src="/images/thumb_user_def.png"/>
                      <svg
                          className="border"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 36 36"
                          enable-background="new 0 0 36 36"
                          xmlSpace="preserve"
                      >
                      <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                      </svg>
                  </span>
                  <strong className="name">건대히어로</strong>
                  <span className="today">오늘의 한마디를한마디를한마디를한마디를</span>
                </a>
                <button type="button" className="btn_option">옵션</button>
                <div className="ly_option">
                  <ul>
                    <li><a className="lnk" href="javascript:;">신고하기</a></li>
                    <li><a className="lnk" href="javascript:;">URL 복사</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="article_cont">
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
              <p><img src={Url.thumb + "normal/resize/origin/file/photo/editor/2011/7ce923e44caf4d53e26853420c2d1d3a_cQZRMgW18Sp3uVRo7bFeQKIzr3uW.jpg"}/></p>
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
              <p>ㄴ안ㅇㄴ어ㅏ민어ㅏㅁㄴ인ㅇ</p>
            </div>
          </div>
          {/* // view_area */}

          {/* comment_area */}
          <div className="comment_area">
            <h3 className="tit">댓글 40</h3>

            {/* comment_write */}
            <div className="comment_write">  {/* 클릭시 selected 추가 */}
              <form method="post">
                {/*
                <p className="lnk_user">
                  <span className="thumb lv17">
                      <img src="/images/thumb_user_def.png"/>
                      <svg
                          className="border"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 36 36"
                          enable-background="new 0 0 36 36"
                          xmlSpace="preserve"
                      >
                        <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                      </svg>
                  </span>
                  <strong className="name">건대히어로</strong>
                </p>
                */}
                <span className="ic_login">로그인 전</span>
                <div className="form_area">
                  <textarea placeholder="인터넷은 우리가 함께 만들어가는 소중한 공간입니다. 댓글 작성 시 타인에 대한 배려와 책임을 담아주세요."></textarea>
                  <div className="txt_byte">
                    <span className="num">0</span>
                    <span className="slash">/</span>
                    <span className="max">500</span>
                  </div>
                  <button type="submit" className="btn_submit">등록</button>
                </div>
                <button className="btn_open" type="button">댓글을 남겨 보세요.</button>
              </form>
            </div>
            {/* // comment_write */}

            {/* // comment_list */}
            <div className="comment_list">

              <div className="comment my">
                <div className="inner">
                  <div className="info">
                    <a className="lnk_user" href="javascript:;">
                      <span className="thumb lv14">
                        <img src="/images/thumb_user_def.png"/>
                        <svg
                            className="border"
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 36 36"
                            enable-background="new 0 0 36 36"
                            xmlSpace="preserve"
                        >
                        <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z"/>
                        </svg>
                      </span>
                      <strong className="name">첫번째댓글</strong>
                    </a>
                    <button type="button" className="btn_option">옵션</button>
                    <div className="ly_option">
                      <ul>
                        <li><a className="lnk" href="javascript:;">신고하기</a></li>
                        <li><a className="lnk" href="javascript:;">수정하기</a></li>
                        <li><a className="lnk" href="javascript:;">삭제하기</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="cont">첫번째댓글 첫번째댓글 첫번째댓글 첫번째댓글</div>
                  <div className="reply">
                    <span className="date">2020.00.00 00:00</span>
                    <a className="btn_reply" href="javascript:;">답글쓰기</a>
                  </div>
                </div>
              </div>

              <div className="comment depth">
                <div className="inner">
                  <div className="info">
                    <a className="lnk_user" href="javascript:;">
                      <span className="thumb lv17">
                        <img src="/images/thumb_user_def.png"/>
                        <svg
                            className="border"
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 36 36"
                            enable-background="new 0 0 36 36"
                            xmlSpace="preserve"
                        >
                        <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z"/>
                        </svg>
                      </span>
                      <strong className="name">건대히어로</strong>
                    </a>
                    <span className="writer">작성자</span>
                    <button type="button" className="btn_option">옵션</button>
                  </div>
                  <div className="cont">솔가 류뚱저새귀는 인성쓰레기랑 안좋아한다 그먼거리까지가서 응원한 애가 싸인해달라는데</div>
                  <div className="reply">
                    <span className="date">2020.00.00 00:00</span>
                    <a className="btn_reply" href="javascript:;">답글쓰기</a>
                  </div>
                  <div className="comment_write selected">
                    <form method="post">
                      <p className="lnk_user">
                        <span className="thumb lv17">
                          <img src="/images/thumb_user_def.png"/>
                          <svg
                              className="border"
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 36 36"
                              enable-background="new 0 0 36 36"
                              xmlSpace="preserve"
                          >
                          <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z"/>
                          </svg>
                        </span>
                        <strong className="name">건대히어로</strong>
                      </p>
                      <div className="form_area">
                        <textarea placeholder="인터넷은 우리가 함께 만들어가는 소중한 공간입니다. 댓글 작성 시 타인에 대한 배려와 책임을 담아주세요."></textarea>
                        <div className="txt_byte">
                          <span className="num">0</span>
                          <span className="slash">/</span>
                          <span className="max">500</span>
                        </div>
                        <button type="submit" className="btn_submit">등록</button>
                      </div>
                      <button className="btn_open" type="button">댓글을 남겨 보세요.</button>
                    </form>
                  </div>
                </div>
              </div>

              <div className="comment">
                <div className="inner">
                  <div className="info">
                    <a className="lnk_user" href="javascript:;">
                      <span className="thumb lv17">
                        <img src="/images/thumb_user_def.png"/>
                        <svg
                            className="border"
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 36 36"
                            enable-background="new 0 0 36 36"
                            xmlSpace="preserve"
                        >
                        <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z"/>
                        </svg>
                      </span>
                      <strong className="name">건대히어로</strong>
                    </a>
                    <button type="button" className="btn_option">옵션</button>
                  </div>
                  <div className="cont">솔가 류뚱저새귀싸인해달라는데</div>
                  <div className="reply">
                    <span className="date">2020.00.00 00:00</span>
                    <a className="btn_reply" href="javascript:;">답글쓰기</a>
                  </div>
                </div>
              </div>

            </div>
            {/* // comment_list */}

          </div>
          {/* // comment_area */}

        </div>
        {/* // board_view */}

        {/* other_article */}
        <div className="other_article">
          <h3 className="tit"><a href="javascript:;">베스트 글 더보기</a></h3>
          <div className="board_list">
            <div className="list_area">
              <div className="article new">
                <a className="comment" href="javascript:;">
                  <strong className="num">3</strong>
                  <span className="tx">댓글</span>
                </a>
                <a className="thumb" href="javascript:;">
                  <img src="https://search.pstatic.net/common/?src=http%3A%2F%2Fblogfiles.naver.net%2FMjAyMDEwMjNfMjIw%2FMDAxNjAzNDYyMzg3ODQ1.ETvh_WAPbbginwGduT3Y3YwUkXCkMkdI9TruT6WwrkYg.AWofo4mM7iTCG7wGGDOvwhSZgQn8E7nuOtib7jB0scIg.JPEG.wjswldbd126%2F8.JPG%23900x600&type=ff192_192"/>
                </a>
                <a className="item" href="javascript:;">
                  <p className="subject">소개팅에서 인생영화가 뭐냐는 질문생영화가 뭐냐는 질문생영화가 뭐냐는 질문생영화가 뭐냐는 질문생영화가 뭐냐는 질문을 받는 다면 당신은</p>
                  <p className="info">
                    <span className="category">자유</span>
                    <span className="name">플란체</span>
                    <span className="date">10:27</span>
                    <span className="count">조회 386</span>
                  </p>
                </a>
              </div>
              <div className="article new curr">
                <a className="comment" href="javascript:;">
                  <strong className="num">3</strong>
                  <span className="tx">댓글</span>
                </a>
                <a className="item" href="javascript:;">
                  <p className="subject">소개팅에서 인생영화을 받는 다면 당신은</p>
                  <p className="info">
                    <span className="category">자유</span>
                    <span className="name">플란체</span>
                    <span className="date">10:27</span>
                    <span className="count">조회 386</span>
                  </p>
                </a>
              </div>
              <div className="article">
                <a className="comment" href="javascript:;">
                  <strong className="num">3</strong>
                  <span className="tx">댓글</span>
                </a>
                <a className="item" href="javascript:;">
                  <p className="subject">소개팅에서 인생영화을 받는 다면 영화을 받는 다면 영화을 받는 다면 영화을 받는 다면 영화을 받는 다면 당신은</p>
                  <p className="info">
                    <span className="category">자유</span>
                    <span className="name">플란체</span>
                    <span className="date">10:27</span>
                    <span className="count">조회 386</span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* // other_article */}

      </div>
      {/* // board_wrap */}
    </>
  );
};

export default BoardViewHtml;
