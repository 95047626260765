import autobind from 'autobind-decorator';
import { action, computed, observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import {
  MajorStore,
  SoccerStore,
  BaseballStore,
  BasketballStore,
  VolleyballStore,
  HockeyStore,
  FootballStore,
  LolStore,
  StarcraftStore,
  ProtoStore,
  ProtoCalculatorStore,
  InterestGameStore
} from '../../routes/score/store';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ScoreService } from '../services';

@autobind
class ScoreStore {
  localstorage = new useLocalStorage();

  constructor(stores) {
    this.major = new MajorStore(this);
    this.soccer = new SoccerStore(this);
    this.baseball = new BaseballStore(this);
    this.basketball = new BasketballStore(this);
    this.volleyball = new VolleyballStore(this);
    this.hockey = new HockeyStore(this);
    this.football = new FootballStore(this);
    this.lol = new LolStore(this);
    this.starcraft = new StarcraftStore(this);
    this.proto = new ProtoStore(this);
    this.interest = new InterestGameStore(stores);
    this.protoCalculator = new ProtoCalculatorStore(stores);

    this.localstorage.getAsyncStringItem('scoreViewType').then((success) => {
      if (!!success) {
        this.viewType = success;
      }
    });
  }

  @observable
  calendarGameCount = [];

  @observable
  viewType = 'VERTICAL_VIEW';

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get gameCount() {
    return this.calendarGameCount.toJS();
  }

  /**********************************************************
   * action
   **********************************************************/
  @action
  changeViewType(viewType) {
    this.localstorage.setItem('scoreViewType', viewType);

    this.viewType = viewType;
  }

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  async *requestGetCalender(sportsType, month, year) {
    try {
      let sportsTypeParam = sportsType;

      if (sportsType === 'icehockey') {
        sportsTypeParam = 'hockey';
      }

      const result = yield ScoreService.getGameCountCalendar(
        sportsTypeParam,
        month,
        year
      );
      this.calendarGameCount = result;
    } catch (error) {
      console.log(error);
    }
  }
}

export default ScoreStore;
