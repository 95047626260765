import React from 'react';
import {Url} from "../../common/constants/environment";

const GamePickHtml = () => {
  return (
    <>
      <div className="minigame_wrap">

        <div className="game_view">
          중계화면 영역
        </div>
        <div className="snb_wrap">
          <ul>
            <li><a className="item" href="javascript:;">응원</a></li>
            <li><a className="item selected" href="javascript:;">방채팅</a></li>
            <li><a className="item" href="javascript:;">결과</a></li>
            <li><a className="item" href="javascript:;">분석</a></li>
            <li><a className="item" href="javascript:;">게임픽</a></li>
          </ul>
        </div>

        {/* game_roomchat_area */}
        <div className="game_roomchat_area">

          {/* room_list_wrap */}
          <div className="room_list_wrap">
            <div className="item">
              <a href="javascript:;">
                <img className="thumb" src={Url.thumb + "normal/croptop/63x63/member/i4/fb10a1d7a1e6fa7c0bb11c0407cf622f.jpg"}/>
                <p className="tit">❤️미겜❤️동행❤️수익&복구❤️무료상담❤️</p>
                <p className="msg">오늘 저랑 같이 스포츠 게임 함께해요</p>
              </a>
              <div className="info">
                <a className="user" href="javascript:;">닉네임임</a>
                <span className="date">13일전</span>
                <span className="count">1000/1200</span>
              </div>
            </div>
            <div className="item">
              <a href="javascript:;">
                <img className="thumb" src={Url.thumb + "normal/croptop/63x63/member/i4/fb10a1d7a1e6fa7c0bb11c0407cf622f.jpg"}/>
                <p className="tit">안녕하세요 안녕하세요 안녕하세요 안녕하세요 안녕하세요</p>
                <p className="msg">오늘 저랑 같이 스포츠 게임 함께해요</p>
              </a>
              <div className="info">
                <a className="user">닉네임임</a>
                <span className="date">13일전</span>
                <span className="count">1000/1200</span>
              </div>
            </div>
            <div className="item">
              <a href="javascript:;">
                <img className="thumb" src={Url.thumb + "normal/croptop/63x63/member/i4/fb10a1d7a1e6fa7c0bb11c0407cf622f.jpg"}/>
                <p className="tit">안녕하세요 안녕하세요 안녕하세요 안녕하세요 안녕하세요</p>
                <p className="msg">오늘 저랑 같이 스포츠 게임 함께해요</p>
              </a>
              <div className="info">
                <a className="user">닉네임임</a>
                <span className="date">13일전</span>
                <span className="count">1000/1200</span>
              </div>
            </div>
            <div className="item">
              <a href="javascript:;">
                <img className="thumb" src={Url.thumb + "normal/croptop/63x63/member/i4/fb10a1d7a1e6fa7c0bb11c0407cf622f.jpg"}/>
                <p className="tit">안녕하세요 안녕하세요 안녕하세요 안녕하세요 안녕하세요</p>
                <p className="msg">오늘 저랑 같이 스포츠 게임 함께해요</p>
              </a>
              <div className="info">
                <a className="user">닉네임임</a>
                <span className="date">13일전</span>
                <span className="count">1000/1200</span>
              </div>
            </div>
            <div className="item">
              <a href="javascript:;">
                <img className="thumb" src={Url.thumb + "normal/croptop/63x63/member/i4/fb10a1d7a1e6fa7c0bb11c0407cf622f.jpg"}/>
                <p className="tit">안녕하세요 안녕하세요 안녕하세요 안녕하세요 안녕하세요</p>
                <p className="msg">오늘 저랑 같이 스포츠 게임 함께해요</p>
              </a>
              <div className="info">
                <a className="user">닉네임임</a>
                <span className="date">13일전</span>
                <span className="count">1000/1200</span>
              </div>
            </div>
            <button className="btn_more">더보기 ( 1 / 26 )</button>
          </div>
          {/* // room_list_wrap */}

        </div>
        {/* // game_roomchat_area */}

      </div>

    </>
  );
};

export default GamePickHtml;
