import { extendObservable, observable, computed, action } from 'mobx';
import autobind from 'autobind-decorator';

@autobind
class LolBanModel {
  @observable
  location = 'home';

  constructor(data, location) {
    extendObservable(this, data);

    this.location = location;
  }

  @computed
  get champ() {
    const { img_path, name_full } = this;

    let defaultChampion = {
      img_path: '/sports/lol/champ/League-of-Legends-Game-Logo.jpg',
      name_full: '선택중'
    };

    if (!!img_path) {
      defaultChampion.img_path = img_path;
    }

    if (!!name_full) {
      defaultChampion.name_full = name_full;
    }

    return {
      name: defaultChampion.name_full.split(',')[0],
      imgPath: defaultChampion.img_path
    };
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  setBanPicks(data, location) {
    extendObservable(this, data);

    this.location = location;
  }

  @action
  updateBanPicks(data) {
    // console.log('updateBanPicks = ', data)
    if (!!data) {
      for (const [key, value] of Object.entries(data)) {
        this[key] = value;
      }
    }
  }
}

export default LolBanModel;
