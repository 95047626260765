import React from 'react';
import {Url} from "../../common/constants/environment";

const ScoreCastBasketballHtml = () => {
  return (
    <>

      {/* score_detail_wrap */ }
      <div className="score_detail_wrap"> {/* 클래스 상태 : small_view, ready, close */}

        <div className="summary">
          <button className="btn_prev">이전으로</button>
          <h2 className="tit">NBA</h2>
          <button className="btn_oc">크게보기/작게보기</button>
        </div>

        {/* score_detail_board */ }
        <div className="score_detail_board basketball"> {/* 클래스 종목 : soccer, basketball ---- 종목별 이름 */}

          {/* summary_score */ }
          <div className="summary_score">
            <div className="team left">
              <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                <span className="name">바로 셀로로</span>
                <span className="player">디에고 카스틸로</span>
            </div>
            <div className="info">
              <div className="score">
                <strong className="left on">
                  <em className="num">99</em>
                </strong>
                <strong className="right">
                  <em className="num">99</em>
                </strong>
              </div>
              <div className="state"><span>9회말</span></div>
              <div className="time">10.22 09:00</div>
              <div className="stadium">글로브 라이프 필드</div>
            </div>
            <div className="team right"><img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/14_1550480543.png"}/>
              <div className="name">바로 셀로나</div>
              <span className="player">디에고 카스틸로</span>
            </div>
          </div>
          {/* // summary_score */ }

          {/* detail_score */ }
          <div className="detail_score">
            <ul className="tab">
              <li>
                <button className="selected">스코어</button>
              </li>
              <li>
                <button>스페셜</button>
              </li>
              <li>
                <button>상황중계</button>
              </li>
            </ul>

            <div className="match_tracker" style={{ display: 'none' }}>
              <iframe scrolling="no" frameBorder="0" src="https://animations.betcoapps.com/#/widget/animation?region=122&amp;competition=1930&amp;game=17233239&amp;gameid=17233239&amp;amp;lang=kor"></iframe>
              <p className="tip">데이터 반영에 차이가 있을 수 있습니다.</p>
            </div>

            <div className="detail_board" style={{ display: '' }}>
              <div className="team">
                <span>삼성</span>
                <span>엘지</span>
              </div>
              <div className="basic_board overtime" style={{ display: '' }}>
                <dl className="item">
                  <dt>1Q</dt>
                  <dd>0</dd>
                  <dd>0</dd>
                </dl>
                <dl className="item">
                  <dt>2Q</dt>
                  <dd>0</dd>
                  <dd>0</dd>
                </dl>
                <dl className="item on">
                  <dt>3Q</dt>
                  <dd>1</dd>
                  <dd>1</dd>
                </dl>
                <dl className="item">
                  <dt>4Q</dt>
                  <dd>-</dd>
                  <dd>-</dd>
                </dl>
                <dl className="item">
                  <dt>연장</dt>
                  <dd>-</dd>
                  <dd>-</dd>
                </dl>
                <dl className="item r">
                  <dt>합계</dt>
                  <dd>1</dd>
                  <dd>1</dd>
                </dl>
              </div>

              <div className="special_board" style={{ display: 'none' }}>
                <div className="special_option">
                  <select>
                    <option value="0">전체</option>
                    <option value="1">1쿼터</option>
                    <option value="2">2쿼터</option>
                    <option value="3">3쿼터</option>
                    <option value="4">3쿼터</option>
                  </select>
                </div>
                <dl className="item">
                  <dt>첫득</dt>
                  <dd className="on"></dd>
                  <dd></dd>
                </dl>
                <dl className="item">
                  <dt>첫3</dt>
                  <dd></dd>
                  <dd></dd>
                </dl>
                <dl className="item">
                  <dt>첫자</dt>
                  <dd></dd>
                  <dd className="on"></dd>
                </dl>
                <dl className="item">
                  <dt>선5</dt>
                  <dd></dd>
                  <dd></dd>
                </dl>
                <dl className="item">
                  <dt>선7</dt>
                  <dd></dd>
                  <dd></dd>
                </dl>
                <dl className="item">
                  <dt>선10</dt>
                  <dd></dd>
                  <dd></dd>
                </dl>
              </div>

            </div>
          </div>
          {/* // detail_score */ }

        </div>
        {/* // score_detail_board */ }

        {/* snb_wrap */ }
        <div className="snb_wrap">
          <ul>
            <li><a className="item" href="javascript:;">응원</a></li>
            <li><a className="item" href="javascript:;">전력</a></li>
            <li><a className="item" href="javascript:;">배당</a></li>
            <li><a className="item" href="javascript:;">라인업</a></li>
            <li><a className="item selected" href="javascript:;">중계</a></li>
          </ul>
        </div>
        {/* // snb_wrap */ }

        {/* basketball_cast */ }
        <div className="basketball_cast">
          <dl className="item view">
            <dt>
              <span className="tit">2쿼터</span>
              <button type="button" className="btn_oc">열기/닫기</button>
            </dt>
            <dd>
              <span className="time">00:00</span>
              <span className="tx notify">2쿼터 게임시작</span>
            </dd>
            <dd>
              <span className="time">00:00</span>
              <span className="tx">울산 모비스 공격</span>
            </dd>
            <dd>
              <span className="time">00:00</span>
              <span className="tx">울산 모비스 중거리 슛</span>
            </dd>
          </dl>
          <dl className="item">
            <dt>
              <span className="tit">1쿼터</span>
              <button type="button" className="btn_oc">열기/닫기</button>
            </dt>
            <dd>
              <span className="time">00:00</span>
              <span className="tx">2쿼터 게임시 게임시 임시 게임시</span>
            </dd>
            <dd>
              <span className="time">00:00</span>
              <span className="tx">2쿼터 게임시 게임시 임시 게임시</span>
            </dd>
            <dd>
              <span className="time">00:00</span>
              <span className="tx">2쿼터 게임시 게임시 임시 게임시</span>
            </dd>
          </dl>
        </div>
        {/* // basketball_cast */ }

      </div>
      {/* // score_detail_wrap */ }

    </>
  );
};

export default ScoreCastBasketballHtml;
