import React, { useEffect } from 'react';
import ProtoMyPickStore from './store';
import clsx from 'clsx';
import GoBack from '../../../components/goBack';
import { Link, useHistory } from 'react-router-dom';
import PaginationExample from '../../../components/pagination_example/PaginationExample';
import Seo from '../../../components/seo';

const ProtoMyPick = () => {
  const history = useHistory();
  const { data, getOddsCalculatorList, onChangeTab } = ProtoMyPickStore();

  useEffect(() => {
    getOddsCalculatorList('', 1);
  }, []);

  return (
    <>
      <Seo title={'프로토 계산기 저장내역'} />
      <div className="proto_wrap">
        <div className="nav">
          <h2 className="tit">배당률 계산기 저장내역</h2>
          <GoBack />
        </div>
        <div className="snb_wrap">
          <ul>
            <li>
              <a
                className={clsx(
                  'item',
                  data.selectedTab === 'ALL' && 'selected'
                )}
                onClick={() => onChangeTab('ALL')}
              >
                전체
              </a>
            </li>
            <li>
              <a
                className={clsx(
                  'item',
                  data.selectedTab === 'READY' && 'selected'
                )}
                onClick={() => onChangeTab('READY')}
              >
                대기중
              </a>
            </li>
            <li>
              <a
                className={clsx(
                  'item',
                  data.selectedTab === 'HIT' && 'selected'
                )}
                onClick={() => onChangeTab('HIT')}
              >
                적중
              </a>
            </li>
            <li>
              <a
                className={clsx(
                  'item',
                  data.selectedTab === 'NO_HIT' && 'selected'
                )}
                onClick={() => onChangeTab('NO_HIT')}
              >
                미적중
              </a>
            </li>
          </ul>
        </div>

        <div className="proto_history">
          {data.list.length > 0 &&
            data.list.map((item) => {
              return (
                <div className="item" key={item.id}>
                  <Link
                    to={`/score/proto/calculator/my-pick/detail/${item.id}`}
                  >
                    <span className="tit">{item.name}</span>
                    {/* TODO: 저장 시간 추가 - createAt */}
                    <span className="info">
                      <em className="state">{item.status.text}</em>
                      <em className="amount">
                        예상 당첨금 : {item.estimateAmount}
                      </em>
                    </span>
                  </Link>
                  <button
                    type="button"
                    className="btn_proto_paper"
                    onClick={() =>
                      history.push(
                        `/score/proto/calculator/my-pick/paper/${item.id}`
                      )
                    }
                  >
                    용지보기
                  </button>
                </div>
              );
            })}
        </div>

        {/* <div className="pagination">
        <a className="btn btn_prev">다음 페이지</a>
        <div className="number">
          <span className="num on">
            <em>1</em>
          </span>
          <a className="num">
            <em>2</em>
          </a>
          <a className="num">
            <em>3</em>
          </a>
          <a className="num">
            <em>4</em>
          </a>
          <a className="num">
            <em>5</em>
          </a>
        </div>
        <a className="btn btn_next">다음 페이지</a>
      </div> */}

        <PaginationExample
          onChangePage={() => {}}
          page={1}
          totalCount={data.list.length}
        />
      </div>
    </>
  );
};

export default ProtoMyPick;
