import React from 'react';

const ProtoPaperHtml = () => {
  return (
    <>
      <div className="layer_proto_paper">
        <div className="option">
          <button type="button" className="btn_close">닫기</button>
          <button type="button" className="btn_share">공유</button>
          <button type="button" className="btn_download">다운받기</button>
        </div>
        <div className="paper">
          <div className="item">
            <div className="number">
              <span className="n1">1</span>
              <span className="n2">2</span>
              <span className="n3">3</span>
            </div>
            <div className="type">
              <span className="w">w</span>
              <span className="d">d</span>
              <span className="l">l</span>
              <span className="cancel">취소</span>
            </div>
          </div>
          <div className="item">
            <div className="number">
              <span className="n3">3</span>
              <span className="n8">8</span>
              <span className="n8">8</span>
            </div>
            <div className="type">
              <span className="w">w</span>
              <span className="d">d</span>
              <span className="l">l</span>
              <span className="cancel">취소</span>
            </div>
          </div>
          <div className="item">
            <div className="number">
              <span className="n1">1</span>
              <span className="n5">5</span>
              <span className="n5">5</span>
            </div>
            <div className="type">
              <span className="w">w</span>
              <span className="d">d</span>
              <span className="l">l</span>
              <span className="cancel">취소</span>
            </div>
          </div>
          <div className="item">
            <div className="number">
              <span className="n3">3</span>
              <span className="n8">8</span>
              <span className="n8">8</span>
            </div>
            <div className="type">
              <span className="w">w</span>
              <span className="d">d</span>
              <span className="l">l</span>
              <span className="cancel">취소</span>
            </div>
          </div>
          <div className="game_count">
            <span className="n5">1</span>
          </div>
        </div>
      </div>

    </>
  );
};

export default ProtoPaperHtml;
