import React, { useEffect, useRef } from 'react';
import { Background, Wrap, CloseButton } from './styled';
import { ImageUtil } from '../../../../../common/utils/imageUtil';
import { RegexUtil } from '../../../../../common/utils/regexUtil';
import { MypageContext, useMypageStore } from '../../../context';
import useModalStore from '../../../../../hooks/useModalStore';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { descriptionsHelper, payTypesHelper } from '../utils/utils';
import SelectPayType from './SelectPayType';

const ItemModal = ({ isOpen, onClose, item, onClickGift }) => {
  const bodyScroll = useRef();

  const { image, name, bonus_exp, pay_type, point, cash, desc, tp, medal } = item;
  const { mypageStore } = useMypageStore(MypageContext);
  const modalStore = useModalStore();

  const descriptions = descriptionsHelper(desc);
  const payTypeResult = payTypesHelper(pay_type);
  const arrayPayTypeResult = Object.entries(payTypeResult);

  useEffect(() => {
    if (!isOpen) {
      enableBodyScroll(bodyScroll.current);
    } else {
      disableBodyScroll(bodyScroll.current);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen, bodyScroll.current]);

  const onClickBuy = (count, payTypeSelected) => {
    modalStore.openConfirmModal({
      message: `${item.name} 아이템을 구매하시겠습니까?`,
      okCallback: () => {
        mypageStore.actionItemBuy({
          name: item.name,
          bonus: item.bonus_exp,
          itemId: item.id,
          count,
          payTypeSelected,
        });
        onClose();
      },
    });
  };
  return (
    <Background isOpen={isOpen} onClick={onClose}>
      <Wrap
        ref={bodyScroll}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <CloseButton onClick={onClose} />
        <img src={ImageUtil.imageUrl(image, 101, 101)} />
        <p className={'name'}>{name}</p>
        {bonus_exp > 0 && <p className={'exp'}>{`보너스 ${RegexUtil.makeComma(bonus_exp)} 경험치`}</p>}
        <p className={'desc'}>
          {descriptions &&
            descriptions.map((el, i) => (
              <React.Fragment key={i}>
                <span>{el}</span>
                <br />
              </React.Fragment>
            ))}
        </p>
        <SelectPayType
          arrayPayTypeResult={arrayPayTypeResult}
          payType={pay_type}
          cash={cash}
          medal={medal}
          point={point}
          onClickGift={onClickGift}
          onClickBuy={onClickBuy}
          giftable={item.giftable}
        />
      </Wrap>
    </Background>
  );
};

export default ItemModal;
