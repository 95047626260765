import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import { MypagePointList } from '../components';
import { timeUtils } from '../../../common/utils/timeUtil';
import { RegexUtil } from '../../../common/utils/regexUtil';
import Pagination from '../../../components/pagination';
import { useLocation } from 'react-router-dom';
import Seo from '../../../components/seo';

const Point = observer(() => {
  const { mypageStore } = useMypageStore(MypageContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;
  const totalCount = mypageStore.data.total_cnt;

  useEffect(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);

    const startDt = timeUtils.dateFormatYmd(date);
    const endDt = timeUtils.dateFormatYmd(new Date());

    mypageStore.actionPoint({ startDt, endDt, page });
  }, [page]);

  return (
    <>
      <Seo title={'마이페이지 포인트'} />
      <div className="history_wrap point">
        <div className="summary">
          <dl className="amass">
            <dt>보유 포인트</dt>
            <dd>{RegexUtil.makeComma(mypageStore.data.total_point)}</dd>
          </dl>
          <dl className="today">
            <dt>오늘 획득 포인트</dt>
            <dd>{RegexUtil.makeComma(mypageStore.data.today_point)}</dd>
          </dl>
        </div>
        <MypagePointList
          items={mypageStore.data.list}
          totalCount={mypageStore.data.total_cnt}
        />
        <Pagination page={page} totalCount={totalCount} rowCount={10} />
      </div>
    </>
  );
});

export default Point;
