import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useCommonStore from '../../../hooks/useCommonStore';
import useModalStore from '../../../hooks/useModalStore';

const BlackListAdd = observer(({ history }) => {
  const commonStore = useCommonStore();
  const modalStore = useModalStore();
  const [mbNick, setMbNick] = useState('');

  const onCancel = () => {
    history.goBack();
  };

  const onAdd = () => {
    modalStore.openConfirmModal({
      message: `블랙리스트에 추가하시겠습니까?`,
      okCallback: () => {
        commonStore.actionBlackAdd({ mbNick }, () => {
          history.replace('/mypage/friends');
        });
      }
    });
  };

  return (
    <>
      <h1>블랙리스트 추가하기</h1>
      <div>
        <input type="text" onChange={(e) => setMbNick(e.target.value)} />
        <button type="button" onClick={onAdd}>
          추가하기
        </button>
      </div>
      <div>
        <button type="button" onClick={onAdd}>
          확인
        </button>
        &nbsp;&nbsp;&nbsp;
        <button type="button" onClick={onCancel}>
          닫기
        </button>
      </div>
    </>
  );
});

export default BlackListAdd;
