import React from 'react';
import useStore from '../useStore';

const useScoreViewType = () => {
  const { scoreStore } = useStore();

  return {
    changeViewType: scoreStore.changeViewType,
    viewType: scoreStore.viewType
  };
};

export default useScoreViewType;
