import React, { useEffect, useMemo, useState, useRef } from "react";
import { observer } from "mobx-react";
import { NavMenu } from "../components/navMenu";
import { RunningballResult } from "../components/runningball/result";
import { RunningballAnalysis } from "../components/runningball/analysis";
import { RunningballPick } from "../components/runningball/pick";
import { RunningballPickRank } from "../components/runningball/pick_rank";
import { RoomChatList } from "../components/roomChat";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import {Space8Pick} from "../components/runningball/pick/Space8Pick";
//import NoticeBarLayer from "../../../components/nav/noticeBarLayer";
import {Url} from "../../../common/constants/environment";
import {timeUtils} from "../../../common/utils/timeUtil";
import useStore from "../../../hooks/useStore";
import BetSection from "../betSection";
//import BannerGA from "../../../components/banner/BannerGA";

const Runningball = observer(({ history }) => {
  const { pathname } = useLocation();
  const { clockStore } = useStore();

  const brandName = pathname?.split("/")[2];
  const gameName = pathname?.split("/")[3];

  const gameViewRef = useRef(null);
  const gameViewIframeRef = useRef(null);

  const query = new URLSearchParams(history.location.search);
  const tab = query.get("tab") || "result";
  const [isSmallView, toggleSmallView] = useState(false);
  const [currentRound, setCurrentRound] = useState(0);
  const [tabSelect, setTabSelect] = useState();

  useEffect(() => {
    toggleSmallView(false);
  }, [tab]);

  useEffect(() => {
    if (gameName === "space8") {
      clockStore.getSpace8Round().then((res) => {
        setCurrentRound(res.nextRound);
      });
    } else if (gameName === 'runningball3'){
      clockStore.getRunningball2Round().then((res) => {
        setCurrentRound(res.nextRound);
      });
    } else {
      clockStore.getRunningball6Round().then((res) => {
        setCurrentRound(res.nextRound);
      });
    }
  }, [gameName]);

  useEffect(() => {
    history.push(`${pathname}?tab=analysis${getTabQueryParams(tabSelect)}`)
  }, [pathname, tabSelect]);

  window.addEventListener('message', (e) => {
      if (e.data.changeTab) {
        setTabSelect(e.data.changeTab.analysisType)
        window.scrollTo({
          top: 500,
          behavior: 'smooth'
        });
      }
  })

  const optionsMap = useMemo(() => {
    return {
      daily: [
        { value: "oneDay", name: "하루씩보기" },
        { value: "period", name: "기간으로보기" }
      ],
      round: [
        { value: "single", name: "단일회차" },
        { value: "latest", name: "최근회차" }
      ],
      pattern:
          gameName === 'runningball3' ?
          [
            { value: "runningball_odd_even", name: "1등 홀짝" }
          ]
              :
              gameName === 'space8' ?
                  [
                    { value: "count_win", name: "볼 갯수 승무패" },
                    { value: "sum_win", name: "볼 번호 합산 승무패" },
                    { value: "sum_home_ball_odd_even", name: "좌측 번호 합 홀짝" },
                    { value: "sum_away_ball_odd_even", name: "우측 번호 합 홀짝" },
                    { value: "home_ball1_odd_even", name: "좌측 1등볼 홀짝" },
                    { value: "away_ball1_odd_even", name: "우측 1등볼 홀짝" },
                    { value: "home_ball1_under_over", name: "좌측 1등볼 언오버" },
                    { value: "away_ball1_under_over", name: "우측 1등볼 언오버" },
                  ] :
          [
            { value: "runningball_odd_even", name: "1등 홀짝" },
            { value: "runningball_under_over", name: "1등 언오버" },
            { value: "sum_odd_even", name: "123등 합 홀짝" },
            { value: "sum_under_over", name: "123등 합 언오버" },
          ]
    };

  }, [gameName]);

  const getTabQueryParams = (analysisType) => {
    switch (analysisType) {
      case 'daily':
        return '&type=daily&option=oneDay';
      case 'round':
        return `&type=round&option=single&start_dt=${timeUtils.addDay(new Date(), -6).value}&round=${currentRound}`;
      case 'pattern':
        return `&type=pattern&option=${optionsMap.pattern[0].value}`;
      default:
        return '';
    }
  }

  useEffect(() => {
    const centerFrameImgWidth = 320; //px
    const width = document.getElementsByTagName("body")[0].offsetWidth;

    const frameHeight = parseInt((width * 390) / 300);
    const frameScale = (width / centerFrameImgWidth).toFixed(3);


    gameViewRef.current.style.height = `${frameHeight}px`;
    gameViewIframeRef.current.style.transform = `scale(${frameScale})`;
  }, []);

  const component = useMemo(() => {
    switch (tab) {
      case "room_chat":
        return <RoomChatList roomType={"rball"} />;
      case "analysis":
        return (
          <RunningballAnalysis
            brandName={brandName}
            gameName={gameName}
          />
        );
      case "pick":
        return gameName === 'space8' ?
          <Space8Pick brandName={brandName} gameName={gameName} /> :
          <RunningballPick brandName={brandName} gameName={gameName} />;
//        return <RunningballPick brandName={brandName}
//                              gameName={gameName} />;
      case "pick_rank":
        return <RunningballPickRank brandName={brandName}
                                gameName={gameName} />;
      default:
        return (
          <RunningballResult
            brandName={brandName}
            gameName={
              gameName
            }
          />
        );
    }
  }, [tab, isSmallView, gameName]);

  return (
    <>
      <div className="minigame_wrap">
        <div
          ref={gameViewRef}
          className={clsx("game_view", "runningball", isSmallView && "small")}
        >
          <iframe
            ref={gameViewIframeRef}
            scrolling="no"
            frameBorder="0"
            src={Url.namedWeb+`/minigame/${brandName}/${gameName}/view.php?view_mode=m`}
          />
          {/*<Link to={'/game'} className="btn_prev">*/}
          {/*  이전으로*/}
          {/*</Link>*/}
        </div>
        {/*<NoticeBarLayer></NoticeBarLayer>*/}
        <BetSection gameType={gameName}/>
        <NavMenu />
        {component}
      </div>
    </>
  );
});

export default Runningball;
