import autobind from 'autobind-decorator';
import ClockService from '../services/game/clock';
import { asyncAction } from 'mobx-utils';
import format from 'date-fns/format';

@autobind
class ClockStore {
  time = null;
  useTimer = false;
  addSecondCount = 0;
  addSecondTimer = null;
  plusSecond = 0;
  autoStopTimer = null;

  constructor() {}

  /**
   * 타이머 정지
   */
  stop() {
    clearTimeout(this.addSecondTimer);
    this.time = null;
    this.useTimer = false;
    this.plusSecond = 0;
  }

  /**
   * 저장된 시간에 1초 더함
   */
  addSecond() {
    if (!this.time) {
      return false;
    }

    this.addSecondCount++;

    //현재시간에 1초 더함
    this.time.setSeconds(this.time.getSeconds() + 1);

    //10초에 한번
    if (this.addSecondCount >= 10) {
      this.addSecondCount = 0;
      this.setTime();
    } else {
      clearTimeout(this.addSecondTimer);
      this.addSecondTimer = setTimeout(this.addSecond, 1000);
    }
  }

  /**
   * 서버와 통신해 현재시작 조회
   * @returns {Generator<Promise<*>, void, ?>}
   */
  @asyncAction
  *setTime() {
    const res = yield ClockService.getTime();

    if (res.headers.date) {
      this.time = new Date(res.headers.date);
      this.time.setSeconds(this.time.getSeconds() + this.plusSecond);
      this.addSecondCount = 0;

      if (this.useTimer) {
        this.addSecond();
      }
    }
  }

  /**
   * 현재 초 조회
   * @returns {*}
   */
  getNowSecond() {
    return (
      this.time.getHours() * 3600 +
      this.time.getMinutes() * 60 +
      this.time.getSeconds()
    );
  }

  /**
   * 게임 회차 조회
   * @param addSecond
   * @param cycleSecond
   * @param maxRound
   * @returns {{currentRound: (*|number), nextRound: number}}
   */
  async getGameRound(addSecond, cycleSecond, maxRound) {
    if (!this.time) {
      await this.setTime();
    }

    const nowSecond = this.getNowSecond() + addSecond;

    let nextRound = Math.ceil(nowSecond / cycleSecond);
    nextRound = maxRound < nextRound ? 1 : nextRound;

    let currentRound = nextRound - 1;
    currentRound = currentRound === 0 ? maxRound : currentRound;


    let remainTime =
      cycleSecond * nextRound - (this.getNowSecond() + addSecond);
    let second =
      remainTime % 60 < 10 ? '0' + (remainTime % 60) : remainTime % 60;
    remainTime = Math.floor(remainTime / 60) + ':' + second;

    return {
      nextRound: nextRound,
      currentRound: currentRound,
      remainTime: remainTime
    };
  }

  /**
   * 런닝볼계열 회차 조회
   * @returns {Promise<*>}
   */

  async getRunningballRound(addSecond, cycleSecond, maxRound) {
    const now = new Date();
    let hour = (now.getUTCHours() +9);
    if(hour >= 24) hour = hour -24;

    let nowSecond = (hour * 3600) + (now.getMinutes() * 60) + now.getSeconds() + addSecond;

    if(nowSecond > 3600*7) nowSecond -= 7200;

    let nextRound = Math.ceil(nowSecond / cycleSecond);

    nextRound = maxRound < nextRound ? 1 : nextRound;

    let currentRound = nextRound - 1;
    currentRound = currentRound === 0 ? maxRound : currentRound;


    let remainTime =
        (cycleSecond * nextRound) - nowSecond;
    let second =
        remainTime % 60 < 10 ? '0' + (remainTime % 60) : remainTime % 60;
    remainTime = Math.floor(remainTime / 60) + ':' + second;


    return {
      nextRound: nextRound,
      currentRound: currentRound,
      remainTime: remainTime
    };
  }

  async getRunningball6Round() {
    return this.getRunningballRound(0, 300, 264);
  }
  async getSpace8Round() {
    return this.getRunningballRound(0, 180, 440);
  }

  async getRunningball2Round() {
    return this.getRunningballRound(320, 300, 264);
  }

  /**
   * 파워계열 회차 조회
   * @returns {Promise<*>}
   */
  async getPowerRound() {
    return this.getGameRound(125, 300, 288);
  }

  async getThreeMinRound() {
    return this.getGameRound(125, 180, 480);
  }

  getPower5MinRound() {
    const baseTime = new Date();

    const options = { timeZone: 'Asia/Seoul', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    const timeInKST = new Intl.DateTimeFormat('en-US', options).format(baseTime);

    const [hours, minutes, seconds] = timeInKST.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        console.error('Invalid time values:', { hours, minutes, seconds });
        return null;
    }

    const timeSeconds = (hours * 60 * 60) + (minutes * 60) + seconds;
    const round = Math.ceil(timeSeconds / (60 * 5)); // Tính round

    return round === 0 ? 288 : round;
  }

  getPower3MinRound() {
    const baseTime = new Date();

    const options = { timeZone: 'Asia/Seoul', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    const timeInKST = new Intl.DateTimeFormat('en-US', options).format(baseTime);

    const [hours, minutes, seconds] = timeInKST.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        console.error('Invalid time values:', { hours, minutes, seconds });
        return null;
    }

    const timeSeconds = (hours * 60 * 60) + (minutes * 60) + seconds;
    const round = Math.ceil(timeSeconds / (60 * 3)); // Tính round

    return round === 0 ? 480 : round;
  }

  getRedBallRound5min() {
    const baseTime = new Date();

    const options = { timeZone: 'Asia/Seoul', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    const timeInKST = new Intl.DateTimeFormat('en-US', options).format(baseTime);

    const [hours, minutes, seconds] = timeInKST.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        console.error('Invalid time values:', { hours, minutes, seconds });
        return null;
    }

    const timeSeconds = (hours * 60 * 60) + (minutes * 60) + seconds + 180;
    let round = Math.ceil(timeSeconds / (60 * 5)); // Tính round

    round = round > 288 ? (round % 288) : round;

    return round === 0 ? 288 : round;
}

  /**
   * 키노계열 회차 조회
   * @returns {*}
   */
  async getKenoRound() {
    return this.getGameRound(30, 300, 288);
  }

  /**
   * 파워키노계열 회차 조회
   * @returns {null|{currentRound: number, nextRound: number}}
   */
  async getPowerKenoRound() {
    const maxRound = 576;
    const powerRound = await this.getPowerRound();
    const kenoRound = await this.getKenoRound();

    let nextRound = powerRound.nextRound + kenoRound.nextRound - 1;
    nextRound = maxRound < nextRound ? 1 : nextRound;

    let currentRound = nextRound - 1;
    currentRound = currentRound === 0 ? maxRound : currentRound;

    return {
      nextRound: nextRound,
      currentRound: currentRound,
      remainTime:
        nextRound % 2 === 0 ? kenoRound.remainTime : powerRound.remainTime
    };
  }

  /**
   * 1분 게임 회차 조회
   * @returns {*}
   */
  async get1minRound() {
    return this.getGameRound(0, 60, 1440);
  }

  /**
   * 2분 게임 회차 조회
   * @returns {*}
   */
  async get2minRound() {
    return this.getGameRound(0, 120, 720);
  }

  /**
   * 3분 게임 회차 조회
   * @returns {*}
   */
  async get3minRound() {
    return this.getGameRound(0, 180, 480);
  }

  /**
   * 5분 게임 회차 조회
   * @returns {*}
   */
  async get5minRound() {
    return this.getGameRound(0, 300, 288);
  }

  /**
   * 레드볼 회차 조회
   */
  async getRedballRound() {
    return this.getGameRound(179, 300, 288);
  }

  /**
   * 모든 게임 회차 조회
   * @returns {Promise<{speedLadder: *, kenoLadder: *, speedkeno: *, powerball: *, ladder: *, daridari: *, powerLadder: *, snailRacing: *, powerKenoLadder: *}>}
   */
  async getAllGameRound() {
    clearTimeout(this.autoStopTimer);
    this.autoStopTimer = setTimeout(this.stop, 1000 * 10);

    this.useTimer = true;
    return {
      // speedladder: await this.get1minRound(),
      // daridari: await this.get3minRound(),
      // ladder: await this.get5minRound(),
      // snailracing: await this.get3minRound(),
      powerball: await this.getKenoRound(),
      three_powerball: await this.get3minRound(),
      powerladder: await this.getKenoRound(),
      three_powerladder: await this.get3minRound(),
      dh_powerball:await this.getKenoRound(),
      dh_speedkeno:await this.getKenoRound(),
      dh_kenoladder:await this.getKenoRound(),
      redball_powerball:await this.getRedballRound(),
      redball_powerladder:await this.getRedballRound()
      // speedkeno: await this.getKenoRound(),
      // kenoladder: await this.getKenoRound(),
      // powerkenoladder: await this.getPowerKenoRound()
    };

    this.plusSecond = -3;
  }



  async getHoldemRound() {
    clearTimeout(this.autoStopTimer);
    this.autoStopTimer = setTimeout(this.stop, 1000 * 10);

    this.useTimer = true;
    this.plusSecond = -3;

    return await this.get2minRound();
  }
}

export default ClockStore;
