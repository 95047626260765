import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import useGameStore from '../../../../../hooks/useGameStore';
import { observer } from 'mobx-react';
import { PickComment } from '../../pickComment';
import useStore from '../../../../../hooks/useStore';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import useModalStore from '../../../../../hooks/useModalStore';

let todayDate = timeUtils.dateFormatYmd(timeUtils.todayYmd());

const Space8Pick = observer(({ brandName, gameName }) => {
  const [tab, setTab] = useState('dist');
  const [option, setOption] = useState('all');
  const gameStore = useGameStore();
  const { clockStore } = useStore();
  const [currentRound, setCurrentRound] = useState(0);
  const [pickClass, setPickClass] = useState('runningball_odd');
  const [pickComment, setPickComment] = useState('픽을 선택하신후 간단한 문구를 선택하세요.');
  const modalStore = useModalStore();
  const [pick, setPick] = useState('odd');
  const [pickType, setPickType] = useState("runningball_odd_even");
  const [commentIndex, setCommentIndex] = useState('0');

  const getGameTypeParameter = () => {
    switch(gameName){
      case "space8" :
        return "space8";
      case "runningball5_6" :
        return "running_ball6";
      case "runningball5_4" :
        return "running_ball4";
      case "runningball3" :
        return "running_ball2";

    }
  };

  const getPickType = (option)=>{
    if(option === 'ball3_odd_even') return 'sum_odd_even';
    else if(option === 'ball3_under_over') return 'sum_under_over';
    else if(option === 'odd_even') return 'runningball_odd_even';
    else if(option === 'under_over') return 'runningball_under_over';
    else return null;
  }

  useEffect(() => {
    let interval = null;
    if (currentRound !== 0) {
      interval = setInterval(() => {
        gameStore.getLotteryPickList({
          gameType: getGameTypeParameter(),
          pickType: option === 'all' ? null : option,
          limit: 10,
          offset: 0,
          date: timeUtils.dateFormatYmd(todayDate),
          round: currentRound
        });
      }, 1500);

      gameStore.getLotteryPickCommentList({
        gameType: getGameTypeParameter(),
        pickType: getPickType(option),
        limit: 10,
        offset: 0,
        concat: false,
        date: timeUtils.dateFormatYmd(todayDate),
        round: currentRound
      });
    }
    return () => {
      clearInterval(interval);
    };
  }, [tab, option, currentRound]);

  useEffect(() => {
    if (gameName === "space8") {
      clockStore.getSpace8Round().then((res) => {
        setCurrentRound(res.nextRound);
      });
    } else if(gameName==='runningball3'){
      clockStore.getRunningball2Round().then((res) => {
        setCurrentRound(res.nextRound);
      });
    }else{
      clockStore.getRunningball6Round().then((res) => {
        setCurrentRound(res.nextRound);
      });
    }
  }, []);

  const getRoundDate = () => {
    return timeUtils.dateFormatCalendarYmd(todayDate) + ' - ' + currentRound;
  };

  const onClickPreRound = () => {
    if (currentRound === 1) {
      todayDate = timeUtils.dateFormatCalendarYmd(
        timeUtils.datePrevOrNext(todayDate, 'prev')
      );
      if(gameName==='runningball3') setCurrentRound(480);
      else setCurrentRound(288);
    } else {
      setCurrentRound(currentRound - 1);
    }
  };

  const onClickNextRound = () => {
    clockStore.getPowerRound().then((res) => {
      if (
        todayDate === timeUtils.dateFormatCalendarYmd(timeUtils.todayYmd()) &&
        currentRound === res.nextRound
      ) {
        return;
      } else {
        if (gameName!=='runningball3' && currentRound === 288) {
          todayDate = timeUtils.dateFormatCalendarYmd(
            timeUtils.datePrevOrNext(todayDate, 'next')
          );
          setCurrentRound(1);
        } else if(gameName==='runningball3' && currentRound === 480){
          todayDate = timeUtils.dateFormatCalendarYmd(
              timeUtils.datePrevOrNext(todayDate, 'next')
          );
          setCurrentRound(1);
        } else {
          setCurrentRound(currentRound + 1);
        }
      }
    });
  };
  const runningballSectionDisplay = (index) => {
    if (index !== option && option !== 'all') {
      return 'none';
    }
  };

  const onSetPickComment = (e) => {
    console.log(e[e.selectedIndex].text);
    setCommentIndex(e.value);
    setPickComment(e[e.selectedIndex].text);
    e.selectedIndex === 0 && setPickComment();
  };

  const insertPickComment = () => {
    if (pickComment === undefined) {
      modalStore.openAlertModal({
        message: '픽 메세지를 선택해 주세요'
      });
      return;
    }

    gameStore.insertLotteryPickComment({
      gameType: getGameTypeParameter(),
      pickType: pickType,
      pick: pick,
      pickComment: pickComment
    });
  };

  const onSetPick = (pickClass, pickType, pick) => {
    setPickClass(pickClass);
    setPick(pick);
    setPickType(pickType);
  };
  return (
    <div className="game_stats_area">
      {/* option_area */}
      <div className="option_tab">
        <ul>
          <li>
            <a
              onClick={() => setTab('dist')}
              className={clsx('item', tab === 'dist' && 'selected')}
            >
              픽분포도
            </a>
          </li>
          <li>
            <a
              onClick={() => setTab('reg')}
              className={clsx('item', tab === 'reg' && 'selected')}
            >
              픽등록
            </a>
          </li>
        </ul>
      </div>
      {tab === 'dist' && (
        <>
          <div className="option_area">
            <div className="date_area">
              <Link
                className="btn_prev"
                onClick={() => onClickPreRound()}
                to={`${location.pathname}?tab=pick`}
              >
                이전 날짜
              </Link>
              <strong className="date">{getRoundDate()}</strong>
              <Link
                className="btn_next"
                onClick={() => onClickNextRound()}
                to={`${location.pathname}?tab=pick`}
              >
                다음 날짜
              </Link>
            </div>
            <div className="option_type">
              <select
                value={option}
                onChange={(e) => setOption(e.target.value)}
              >
                <option value="all">전체</option>
                <option value="count_win">볼 갯수 승무패</option>
                <option value="sum_win">볼 번호 합산 승무패</option>
                <option value="sum_home_ball_odd_even">좌측 번호 합 홀짝</option>
                <option value="sum_away_ball_odd_even">우측 번호 합 홀짝</option>
                <option value="home_ball1_odd_even">좌측 1등볼 홀짝</option>
                <option value="away_ball1_odd_even">우측 1등볼 홀짝</option>
                <option value="home_ball1_under_over">좌측 1등볼 언오버</option>
                <option value="away_ball1_under_over">우측 1등볼 언오버</option>
              </select>
            </div>
          </div>
          {/* // option_area */}

          {/* date_detail_wrap */}
          <div className="date_detail_wrap">
            {/* data_summary */}
            <div className="data_summary space8">
              <h3 className="tit">전체 픽 분포도</h3>
              
              <p className={clsx('chart-title')} style={{
                   display: runningballSectionDisplay('count_win')
                 }}>볼 갯수 승무패</p>
              <div
                className="chart"
                style={{
                  display: runningballSectionDisplay('count_win')
                }}
              >
                <dl
                  className={clsx(
                    'bar HOME',
                    ( gameStore.data.dist.home_count_win_count > gameStore.data.dist.draw_count_win_count &&
                      gameStore.data.dist.home_count_win_count > gameStore.data.dist.away_count_win_count ) ?
                    'on' : null
                  )}
                >
                  <dt>좌</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.home_count_win_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.home_count_win_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar DRAW',
                    ( gameStore.data.dist.draw_count_win_count > gameStore.data.dist.home_count_win_count &&
                      gameStore.data.dist.draw_count_win_count > gameStore.data.dist.away_count_win_count ) ?
                    'on' : null
                  )}
                >
                  <dt>무</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.draw_count_win_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.draw_count_win_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar AWAY',
                    ( gameStore.data.dist.away_count_win_count > gameStore.data.dist.home_count_win_count &&
                      gameStore.data.dist.away_count_win_count > gameStore.data.dist.draw_count_win_count ) ?
                    'on' : null
                  )}
                >
                  <dt>우</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.away_count_win_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.away_count_win_percent}%
                    </span>
                  </dd>
                </dl>
              </div>
              <p className={clsx('chart-title')} style={{
                   display: runningballSectionDisplay('sum_win')
                 }}>볼 번호 합산 승무패</p>
              <div
                className="chart"
                style={{
                  display: runningballSectionDisplay('sum_win')
                }}
              >
                <dl
                  className={clsx(
                    'bar HOME',
                    ( gameStore.data.dist.home_sum_win_count > gameStore.data.dist.draw_sum_win_count &&
                      gameStore.data.dist.home_sum_win_count > gameStore.data.dist.away_sum_win_count ) ?
                    'on' : null
                  )}
                >
                  <dt>좌</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.home_sum_win_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.home_sum_win_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar DRAW',
                    ( gameStore.data.dist.draw_sum_win_count > gameStore.data.dist.home_sum_win_count &&
                      gameStore.data.dist.draw_sum_win_count > gameStore.data.dist.away_sum_win_count ) ?
                    'on' : null
                  )}
                >
                  <dt>무</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.draw_sum_win_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.draw_sum_win_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar AWAY',
                    ( gameStore.data.dist.away_sum_win_count > gameStore.data.dist.home_sum_win_count &&
                      gameStore.data.dist.away_sum_win_count > gameStore.data.dist.draw_sum_win_count ) ?
                    'on' : null
                  )}
                >
                  <dt>우</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.away_sum_win_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.away_sum_win_percent}%
                    </span>
                  </dd>
                </dl>
              </div>
              <p className={clsx('chart-title')} style={{
                   display: runningballSectionDisplay('sum_home_ball_odd_even')
                 }}>좌측 번호 합 홀짝</p>
              <div
                className="chart"
                style={{
                  display: runningballSectionDisplay('sum_home_ball_odd_even')
                }}
              >
                <dl
                  className={clsx(
                    'bar ODD',
                    ( gameStore.data.dist.sum_home_ball_odd_count > gameStore.data.dist.sum_home_ball_even_count &&
                      gameStore.data.dist.sum_home_ball_odd_count > gameStore.data.dist.sum_home_ball_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>홀</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_home_ball_odd_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.sum_home_ball_odd_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar ZERO',
                    ( gameStore.data.dist.sum_home_ball_zero_count > gameStore.data.dist.sum_home_ball_odd_count &&
                      gameStore.data.dist.sum_home_ball_zero_count > gameStore.data.dist.sum_home_ball_even_count ) ?
                    'on' : null
                  )}
                >
                  <dt>0</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_home_ball_zero_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.sum_home_ball_zero_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar EVEN',
                    ( gameStore.data.dist.sum_home_ball_even_count > gameStore.data.dist.sum_home_ball_odd_count &&
                      gameStore.data.dist.sum_home_ball_even_count > gameStore.data.dist.sum_home_ball_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>짝</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_home_ball_even_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.sum_home_ball_even_percent}%
                    </span>
                  </dd>
                </dl>
              </div>
              <p className={clsx('chart-title')} style={{
                   display: runningballSectionDisplay('sum_away_ball_odd_even')
                 }}>우측 번호 합 홀짝</p>
              <div
                className="chart"
                style={{
                  display: runningballSectionDisplay('sum_away_ball_odd_even')
                }}
              >
                <dl
                  className={clsx(
                    'bar ODD',
                    ( gameStore.data.dist.sum_away_ball_odd_count > gameStore.data.dist.sum_away_ball_even_count &&
                      gameStore.data.dist.sum_away_ball_odd_count > gameStore.data.dist.sum_away_ball_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>홀</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_away_ball_odd_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.sum_away_ball_odd_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar ZERO',
                    ( gameStore.data.dist.sum_away_ball_zero_count > gameStore.data.dist.sum_away_ball_odd_count &&
                      gameStore.data.dist.sum_away_ball_zero_count > gameStore.data.dist.sum_away_ball_even_count ) ?
                    'on' : null
                  )}
                >
                  <dt>0</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_away_ball_zero_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.sum_away_ball_zero_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar EVEN',
                    ( gameStore.data.dist.sum_away_ball_even_count > gameStore.data.dist.sum_away_ball_odd_count &&
                      gameStore.data.dist.sum_away_ball_even_count > gameStore.data.dist.sum_away_ball_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>짝</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_away_ball_even_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.sum_away_ball_even_percent}%
                    </span>
                  </dd>
                </dl>
              </div>
              <p className={clsx('chart-title')} style={{
                   display: runningballSectionDisplay('home_ball1_odd_even')
                 }}>좌측 1등볼 홀짝</p>
              <div
                className="chart"
                style={{
                  display: runningballSectionDisplay('home_ball1_odd_even')
                }}
              >
                <dl
                  className={clsx(
                    'bar ODD',
                    ( gameStore.data.dist.home_ball1_odd_count > gameStore.data.dist.home_ball1_even_count &&
                      gameStore.data.dist.home_ball1_odd_count > gameStore.data.dist.home_ball1_oe_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>홀</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.home_ball1_odd_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.home_ball1_odd_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar ZERO',
                    ( gameStore.data.dist.home_ball1_oe_zero_count > gameStore.data.dist.home_ball1_odd_count &&
                      gameStore.data.dist.home_ball1_oe_zero_count > gameStore.data.dist.home_ball1_even_count ) ?
                    'on' : null
                  )}
                >
                  <dt>0</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.home_ball1_oe_zero_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.home_ball1_oe_zero_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar EVEN',
                    ( gameStore.data.dist.home_ball1_even_count > gameStore.data.dist.home_ball1_odd_count &&
                      gameStore.data.dist.home_ball1_even_count > gameStore.data.dist.home_ball1_oe_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>짝</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.home_ball1_even_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.home_ball1_even_percent}%
                    </span>
                  </dd>
                </dl>
              </div>
              <p className={clsx('chart-title')} style={{
                   display: runningballSectionDisplay('away_ball1_odd_even')
                 }}>우측 1등볼 홀짝</p>
              <div
                className="chart"
                style={{
                  display: runningballSectionDisplay('away_ball1_odd_even')
                }}
              >
                <dl
                  className={clsx(
                    'bar ODD',
                    ( gameStore.data.dist.away_ball1_odd_count > gameStore.data.dist.away_ball1_even_count &&
                      gameStore.data.dist.away_ball1_odd_count > gameStore.data.dist.away_ball1_oe_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>홀</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.away_ball1_odd_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.away_ball1_odd_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar ZERO',
                    ( gameStore.data.dist.away_ball1_oe_zero_count > gameStore.data.dist.away_ball1_odd_count &&
                      gameStore.data.dist.away_ball1_oe_zero_count > gameStore.data.dist.away_ball1_even_count ) ?
                    'on' : null
                  )}
                >
                  <dt>0</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.away_ball1_oe_zero_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.away_ball1_oe_zero_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar EVEN',
                    ( gameStore.data.dist.away_ball1_even_count > gameStore.data.dist.away_ball1_odd_count &&
                      gameStore.data.dist.away_ball1_even_count > gameStore.data.dist.away_ball1_oe_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>짝</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.away_ball1_even_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.away_ball1_even_percent}%
                    </span>
                  </dd>
                </dl>
              </div>
              <p className={clsx('chart-title')} style={{
                   display: runningballSectionDisplay('home_ball1_under_over')
                 }}>좌측 1등볼 언오버</p>
              <div
                className="chart"
                style={{
                  display: runningballSectionDisplay('home_ball1_under_over')
                }}
              >
                <dl
                  className={clsx(
                    'bar UNDER',
                    ( gameStore.data.dist.home_ball1_under_count > gameStore.data.dist.home_ball1_over_count &&
                      gameStore.data.dist.home_ball1_under_count > gameStore.data.dist.home_ball1_oe_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>언더</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.home_ball1_under_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.home_ball1_under_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar ZERO',
                    ( gameStore.data.dist.home_ball1_uo_zero_count > gameStore.data.dist.home_ball1_under_count &&
                      gameStore.data.dist.home_ball1_uo_zero_count > gameStore.data.dist.home_ball1_over_count ) ?
                    'on' : null
                  )}
                >
                  <dt>0</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.home_ball1_uo_zero_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.home_ball1_uo_zero_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar OVER',
                    ( gameStore.data.dist.home_ball1_over_count > gameStore.data.dist.home_ball1_under_count &&
                      gameStore.data.dist.home_ball1_over_count > gameStore.data.dist.home_ball1_uo_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>오버</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.home_ball1_over_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.home_ball1_over_percent}%
                    </span>
                  </dd>
                </dl>
              </div>
              <p className={clsx('chart-title')} style={{
                   display: runningballSectionDisplay('away_ball1_under_over')
                 }}>우측 1등볼 언오버</p>
              <div
                className="chart"
                style={{
                  display: runningballSectionDisplay('away_ball1_under_over')
                }}
              >
                <dl
                  className={clsx(
                    'bar UNDER',
                    ( gameStore.data.dist.away_ball1_under_count > gameStore.data.dist.away_ball1_over_count &&
                      gameStore.data.dist.away_ball1_under_count > gameStore.data.dist.away_ball1_uo_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>언더</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.away_ball1_under_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.away_ball1_under_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar ZERO',
                    ( gameStore.data.dist.away_ball1_uo_zero_count > gameStore.data.dist.away_ball1_under_count &&
                      gameStore.data.dist.away_ball1_uo_zero_count > gameStore.data.dist.away_ball1_over_count ) ?
                    'on' : null
                  )}
                >
                  <dt>0</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.away_ball1_uo_zero_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.away_ball1_uo_zero_percent}%
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar OVER',
                    ( gameStore.data.dist.away_ball1_over_count > gameStore.data.dist.away_ball1_under_count &&
                      gameStore.data.dist.away_ball1_over_count > gameStore.data.dist.away_ball1_uo_zero_count ) ?
                    'on' : null
                  )}
                >
                  <dt>오버</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.away_ball1_over_count}
                    </span>
                    <span className="per">
                      {gameStore.data.dist.away_ball1_over_percent}%
                    </span>
                  </dd>
                </dl>
              </div>
              
            </div>
            {/* // data_summary */}

            {/* pick_list_wrap */}
            <div className="pick_list_wrap">
              <h3 className="tit">유저의 픽</h3>
              <div className="pick_list">
                <PickComment
                  gameType={getGameTypeParameter()}
                  pickType={option}
                  items={gameStore.data.comment.list}
                  totalCnt={gameStore.data.comment.total_cnt}
                  date={timeUtils.dateFormatYmd(todayDate)}
                  round={currentRound}
                />
              </div>
            </div>
            {/* // pick_list_wrap */}
          </div>
          {/* // date_detail_wrap */}
        </>
      )}
      {tab === 'reg' && (
        <>
          {/* pick_register_wrap */}
          <div className="pick_register_wrap">
            {/* pick_select */}
            <div className="pick_select">
              <h3 className="tit">픽 선택</h3>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    'HOME',
                    pickClass === 'home_count_win' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('home_count_win', 'count_win', 'home')
                  }
                >
                  <span className="tx">볼 갯수 승무패 좌</span>
                  <span className="per">
                    {gameStore.data.dist.home_count_win_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'DRAW',
                    pickClass === 'draw_count_win' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('draw_count_win', 'count_win', 'draw')
                  }
                >
                  <span className="tx">볼 갯수 승무패 무</span>
                  <span className="per">
                    {gameStore.data.dist.draw_count_win_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'AWAY',
                    pickClass === 'away_count_win' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('away_count_win', 'count_win', 'away')
                  }
                >
                  <span className="tx">볼 갯수 승무패 우</span>
                  <span className="per">
                    {gameStore.data.dist.away_count_win_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    'HOME',
                    pickClass === 'home_sum_win' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('home_sum_win', 'sum_win', 'home')
                  }
                >
                  <span className="tx">볼 번호 합산 승무패 좌</span>
                  <span className="per">
                    {gameStore.data.dist.home_sum_win_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'DRAW',
                    pickClass === 'draw_sum_win' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('draw_sum_win', 'sum_win', 'draw')
                  }
                >
                  <span className="tx">볼 번호 합산 승무패 무</span>
                  <span className="per">
                    {gameStore.data.dist.draw_sum_win_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'AWAY',
                    pickClass === 'away_sum_win' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('away_sum_win', 'sum_win', 'away')
                  }
                >
                  <span className="tx">볼 번호 합산 승무패 우</span>
                  <span className="per">
                    {gameStore.data.dist.away_sum_win_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    'ODD',
                    pickClass === 'sum_home_ball_odd' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('sum_home_ball_odd', 'sum_home_ball_odd_even', 'odd')
                  }
                >
                  <span className="tx">좌측 번호 합 홀짝 홀</span>
                  <span className="per">
                    {gameStore.data.dist.sum_home_ball_odd_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'ZERO',
                    pickClass === 'sum_home_ball_zero' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('sum_home_ball_zero', 'sum_home_ball_odd_even', '0')
                  }
                >
                  <span className="tx">좌측 번호 합 홀짝 0</span>
                  <span className="per">
                    {gameStore.data.dist.sum_home_ball_zero_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'EVEN',
                    pickClass === 'sum_home_ball_even' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('sum_home_ball_even', 'sum_home_ball_odd_even', 'even')
                  }
                >
                  <span className="tx">좌측 번호 합 홀짝 짝</span>
                  <span className="per">
                    {gameStore.data.dist.sum_home_ball_even_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    'ODD',
                    pickClass === 'sum_away_ball_odd' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('sum_away_ball_odd', 'sum_away_ball_odd_even', 'odd')
                  }
                >
                  <span className="tx">우측 번호 합 홀짝 홀</span>
                  <span className="per">
                    {gameStore.data.dist.sum_away_ball_odd_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'ZERO',
                    pickClass === 'sum_away_ball_zero' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('sum_away_ball_zero', 'sum_away_ball_odd_even', '0')
                  }
                >
                  <span className="tx">우측 번호 합 홀짝 0</span>
                  <span className="per">
                    {gameStore.data.dist.sum_away_ball_zero_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'EVEN',
                    pickClass === 'sum_away_ball_even' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('sum_away_ball_even', 'sum_away_ball_odd_even', 'even')
                  }
                >
                  <span className="tx">우측 번호 합 홀짝 짝</span>
                  <span className="per">
                    {gameStore.data.dist.sum_away_ball_even_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    'ODD',
                    pickClass === 'home_ball1_odd' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('home_ball1_odd', 'home_ball1_odd_even', 'odd')
                  }
                >
                  <span className="tx">좌측 1등볼 홀짝 홀</span>
                  <span className="per">
                    {gameStore.data.dist.home_ball1_odd_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'ZERO',
                    pickClass === 'home_ball1_zero' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('home_ball1_zero', 'home_ball1_odd_even', '0')
                  }
                >
                  <span className="tx">좌측 1등볼 홀짝 0</span>
                  <span className="per">
                    {gameStore.data.dist.home_ball1_oe_zero_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'EVEN',
                    pickClass === 'home_ball1_even' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('home_ball1_even', 'home_ball1_odd_even', 'even')
                  }
                >
                  <span className="tx">좌측 1등볼 홀짝 짝</span>
                  <span className="per">
                    {gameStore.data.dist.home_ball1_even_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    'ODD',
                    pickClass === 'away_ball1_odd' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('away_ball1_odd', 'away_ball1_odd_even', 'odd')
                  }
                >
                  <span className="tx">우측 1등볼 홀짝 홀</span>
                  <span className="per">
                    {gameStore.data.dist.away_ball1_odd_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'ZERO',
                    pickClass === 'away_ball1_zero' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('away_ball1_zero', 'away_ball1_odd_even', '0')
                  }
                >
                  <span className="tx">우측 1등볼 홀짝 0</span>
                  <span className="per">
                    {gameStore.data.dist.away_ball1_oe_zero_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'EVEN',
                    pickClass === 'away_ball1_even' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('away_ball1_even', 'away_ball1_odd_even', 'even')
                  }
                >
                  <span className="tx">우측 1등볼 홀짝 짝</span>
                  <span className="per">
                    {gameStore.data.dist.away_ball1_even_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    'ODD',
                    pickClass === 'home_ball1_under' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('home_ball1_under', 'home_ball1_under_over', 'under')
                  }
                >
                  <span className="tx">좌측 1등볼 언오버 언더</span>
                  <span className="per">
                    {gameStore.data.dist.home_ball1_under_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'ZERO',
                    pickClass === 'home_ball1_uo_zero' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('home_ball1_uo_zero', 'home_ball1_under_over', '0')
                  }
                >
                  <span className="tx">좌측 1등볼 언오버 0</span>
                  <span className="per">
                    {gameStore.data.dist.home_ball1_uo_zero_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'OVER',
                    pickClass === 'home_ball1_over' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('home_ball1_over', 'home_ball1_under_over', 'over')
                  }
                >
                  <span className="tx">좌측 1등볼 언오버 오버</span>
                  <span className="per">
                    {gameStore.data.dist.home_ball1_over_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    'ODD',
                    pickClass === 'away_ball1_under' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('away_ball1_under', 'away_ball1_under_over', 'under')
                  }
                >
                  <span className="tx">우측 1등볼 언오버 언더</span>
                  <span className="per">
                    {gameStore.data.dist.away_ball1_under_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'ZERO',
                    pickClass === 'away_ball1_uo_zero' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('away_ball1_uo_zero', 'away_ball1_under_over', '0')
                  }
                >
                  <span className="tx">우측 1등볼 언오버 0</span>
                  <span className="per">
                    {gameStore.data.dist.away_ball1_uo_zero_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    'OVER',
                    pickClass === 'away_ball1_over' && 'selected'
                  )}
                  onClick={() =>
                    onSetPick('away_ball1_over', 'away_ball1_under_over', 'over')
                  }
                >
                  <span className="tx">우측 1등볼 언오버 오버</span>
                  <span className="per">
                    {gameStore.data.dist.away_ball1_over_percent}%
                  </span>
                </button>
              </div>
            </div>
            {/* // pick_select */}

            {/* pick_message */}
            <div className="pick_message">
              <h3 className="tit">픽 메시지</h3>
              <div className="message_type">
                <select
                  value={commentIndex}
                  onChange={(e) => {
                    onSetPickComment(e.target);
                  }}
                >
                  <option value="0">
                    픽을 선택하신 후 간단한 문구를 선택하세요.
                  </option>
                  <option value="1">
                    지금까지 이런 흐름은 없었다. 이것은 신인가 컴퓨터인가?
                  </option>
                  <option value="2">
                    펭하~! 펭수도 이런 흐름에는 고민없이 갑니다.
                  </option>
                  <option value="3">
                    신사답게 행동해~! 묻고 흐름타서 떠블로 가!
                  </option>
                  <option value="4">
                    니 내가 누군지 아니? 내 런닝볼 장인이야!
                  </option>
                  <option value="5">
                    런닝볼 숫자 적중은 네임드! 언오버 적중도 네!임!드!
                  </option>
                  <option value="6">
                    런닝볼 숫자는 지리구요. 언오버는 오지구요.
                  </option>
                  <option value="7">
                    언오버를 지배하는 자가 런닝볼을 지배합니다.
                  </option>
                  <option value="8">제 픽은 정말 현명한 선택이에요.</option>
                  <option value="9">
                    어디로 갈지 망설이지 말고 저를 전적으로 믿으셔야합니다.
                  </option>
                  <option value="10">이안에 픽있다. 런닝볼아 가자~!</option>
                </select>
              </div>
              <button
                type="submit"
                className="btn_submit"
                onClick={() => insertPickComment()}
              >
                픽등록하기
              </button>
            </div>
            {/* // pick_message */}
          </div>
          {/* // pick_register_wrap */}
        </>
      )}
    </div>
  );
});

export { Space8Pick };
