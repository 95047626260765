import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import { PasswordInputWrapper, PasswordToggleButton } from './styles'

const PasswordChange = observer(({ history, onClose }) => {
  const { mypageStore } = useMypageStore(MypageContext);
  const [currentPw, setCurrentPw] = useState('');
  const [pw, setPw] = useState('');
  const [pwRe, setPwRe] = useState('');

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = () => {
    mypageStore.actionPasswordChange({ currentPw, pw, pwRe });
  };

  const onClickCancel = () => {
    onClose();
  };

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">비밀번호 변경</h2>
          <button className={'btn_close'} onClick={onClickCancel} />
        </div>
        <div className="password_reset_area">
          <div className="inner">

            <PasswordInputWrapper className="item">
              <input
                type={showCurrentPassword ? 'text' : 'password'}
                className="inputbox"
                autoComplete="off"
                tabIndex="1"
                title="비밀번호"
                placeholder="현재 비밀번호를 입력하세요."
                onChange={(e) => setCurrentPw(e.target.value)}
              />
              <PasswordToggleButton
                type="button"
                onClick={toggleCurrentPasswordVisibility}
              >
                <img
                  src={`/images/${showCurrentPassword ? 'visibility_off.svg' : 'visibility_on.svg'}`}
                  alt={showCurrentPassword ? "Hide password" : "Show password"}
                />
              </PasswordToggleButton>
            </PasswordInputWrapper>

            <div className="item">
              <PasswordInputWrapper>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="inputbox"
                  autoComplete="off"
                  tabIndex="2"
                  title="비밀번호"
                  placeholder="새 비밀번호를 입력하세요."
                  onChange={(e) => setPw(e.target.value)}
                />
                <PasswordToggleButton
                  type="button"
                  onClick={togglePasswordVisibility}
                >
                  <img
                    src={`/images/${showPassword ? 'visibility_off.svg' : 'visibility_on.svg'}`}
                    alt={showPassword ? "Hide password" : "Show password"}
                  />
                </PasswordToggleButton>
              </PasswordInputWrapper>
              <p className="error">
                비밀번호는 최소4자리, 최대20자리 까지 입력 가능합니다.
              </p>
            </div>
            <div className="item">
              <PasswordInputWrapper>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="inputbox"
                  autoComplete="off"
                  tabIndex="3"
                  title="비밀번호"
                  placeholder="새 비밀번호를 한번 더 입력하세요."
                  onChange={(e) => setPwRe(e.target.value)}
                />
                <PasswordToggleButton
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <img
                    src={`/images/${showConfirmPassword ? 'visibility_off.svg' : 'visibility_on.svg'}`}
                    alt={showConfirmPassword ? "Hide password" : "Show password"}
                  />
                </PasswordToggleButton>
              </PasswordInputWrapper>
              <p className="error">비밀번호가 일치하지 않습니다.</p>
            </div>
          </div>
          <div className="btn_area">
            <button type="submit" className="btn" onClick={onClickCancel}>
              취소
            </button>
            <button type="submit" className="btn btn_submit" onClick={onSubmit}>
              변경하기
            </button>
          </div>
          <div className="tip">
            <p>안전한 비밀번호로 내정보를 보호하세요.</p>
            <p>다른 아이디/사이트에서 사용한 적 없는 비밀번호</p>
            <p>이전에 사용한 적 없는 비밀번호가 안전합니다.</p>
          </div>
        </div>
      </div>
    </>
  );
});

export default PasswordChange;
