import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import ProtoCalcGroup from '../group';
import SportsLoader from '../../../../components/sportsLoader';

const ProtoCalcWrapper = ({
  loading,
  list,
  battingPicks,
  tempBattingClear
}) => {
  const [initView, setInitView] = useState(false);

  useEffect(() => {
    if (!loading) {
      setInitView(true);
    } else {
      setInitView(false);
    }
  }, [loading]);

  const renderViewCheck = useMemo(() => {
    return initView && list.length > 0;
  }, [initView, list]);

  return initView ? (
    renderViewCheck ? (
      <div className={clsx('proto_list', 'proto_calculator')}>
        {list.map((item) => {
          return (
            <ProtoCalcGroup
              data={item}
              key={item[0].id}
              battingPicks={battingPicks}
              tempBattingClear={tempBattingClear}
            />
          );
        })}
      </div>
    ) : (
      <div className={clsx("empty_score", "empty_proto")}>
        <dl>
          <dt>경기 일정이 없습니다.</dt>
          <dd>
            경기 결과나 예정 경기는 달력을 클릭하시면 편하게 찾아보실 수
            있습니다.
          </dd>
        </dl>
      </div>
    )
  ) : (
    <SportsLoader />
  );
};

export default ProtoCalcWrapper;
