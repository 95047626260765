import Api from '../../api';
import { Url } from '../../../constants/environment';
class LotteryService extends Api {
  constructor() {
    super();
    this.URL = 'lottery';
  }

  async getLotteryPickList(gameType, pickType, limit, offset, date, round) {
    const data = {
      game_type: gameType,
      limit: limit,
      offset: offset,
      date: date,
      round: round,
    };

    if (pickType !== null) {
      data['pick_type'] = pickType;
    }
    const res = await this.commonAjax('get', Url.named, 'lottery/pick', data);

    return res;
  }

  async getLotteryPickCommentList(gameType, pickType, limit, offset, date, round) {
    const data = {
      game_type: gameType,
      limit: limit,
      offset: offset,
      date: date,
      round: round,
    };

    if (pickType !== null) {
      data['pick_type'] = pickType;
    }
    const res = await this.commonAjax('get', Url.named, 'lottery/pick', data);

    return res;
  }

  async getLotteryStatPattern({ gameType, patternType, startDate, endDate, round }) {
    let url = this.URL + `/stat/pattern?game_type=${gameType}`;
    patternType && (url += `&pattern_type=${patternType}`);
    startDate && (url += `&start_dt=${startDate}`);
    endDate && (url += `&end_dt=${endDate}`);
    round && (url += `&round_count=${round}`);

    const res = await this.commonAjax('get', Url.named, url);

    return res;
  }

  async getLotteryStatAnalysis({ gameType, startDate, endDate, roundCount, round, searchType }) {
    let url = this.URL + `/stat/analysis?game_type=${gameType}`;
    startDate && (url += `&start_dt=${startDate}`);
    endDate && (url += `&end_dt=${endDate}`);
    roundCount && (url += `&round_count=${roundCount}`);
    round && (url += `&round=${round}`);
    searchType && (url += `&search_type=${searchType}`);

    const res = await this.commonAjax('get', Url.named, url);

    return res;
  }

  getEmptyObj() {
    return {
      data: {
        list: [],
        percent: {
          runningball_odd_even: {
            even_cnt: 0,
            even_ratio: 0,
            odd_cnt: 0,
            odd_ratio: 0,
          },
          runningball_under_over: {
            even_cnt: 0,
            even_ratio: 0,
            odd_cnt: 0,
            odd_ratio: 0,
          },
          sum_odd_even: {
            even_cnt: 0,
            even_ratio: 0,
            odd_cnt: 0,
            odd_ratio: 0,
          },
          sum_under_over: {
            even_cnt: 0,
            even_ratio: 0,
            odd_cnt: 0,
            odd_ratio: 0,
          },
          search_pattern: '',
          search_pattern_round: '',
        },
      },
    };
  }

  async getLotteryStatAnalysisPattern({ gameType, patternType, count }) {
    let url = this.URL + `/stat/analysis/pattern?game_type=${gameType}`;
    patternType && (url += `&pattern_type=${patternType}`);
    count && (url += `&count=${count}`);
    const res = await this.commonAjax('get', Url.named, url);
    // API 통신이 원할하지 않을경우 빈 객체 리턴
    if (Object.keys(res).length === 0) {
      return this.getEmptyObj();
    }

    return res;
  }

  async insertLotteryPickComment(gameType, pickType, pick, pickComment) {
    const form = new FormData();
    form.append('game_type', gameType);
    form.append('pick_type', pickType);
    form.append('pick', pick);
    form.append('comment', pickComment);

    const res = await this.commonAjax('post', Url.named, 'lottery/pick', form);

    return res;
  }

  /**
   * 미니게임 최근 결과 조회
   * @returns {Promise<*>}
   */
  async getLotteryRecentList(brandName, gameName) {
    let url;
    if (gameName.startsWith('runningball5')) {
      let gameType = gameName.split('_');

      url = `${Url.dataJsonUrl}/minigame/${brandName}/runningball5/ball${gameType[1]}/recent.json`;
    } else {
      url = `${Url.dataJsonUrl}/minigame/${brandName}/${gameName}/recent.json`;
    }

    const res = await this.commonAjax('get', '', url);
    return res;
  }

  /**
   * 미니게임 계열 최근 결과 조회
   * @returns {Promise<*>}
   */
  async getMiniRecentList(type) {
    let url = Url.dataJsonUrl + `/json/games/theme/${type}/recent.json`;
    if (window.location.hostname === 'localhost') {
      url = Url.namedWeb + url;
    }

    const res = await this.commonAjax('get', '', url);

    return res;
  }
}

export default new LotteryService();
