import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export const Tab = styled.ul`
  display: flex;
  height: 43px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
`;

export const TabItem = styled.li`
  height: 43px;
  width: 50%;
  font-size: 14px;
  a {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .selected {
    font-weight: bold;
    color: #ee1a21;
    border-bottom: 2px solid #ee1a21;
  }
`;

export const List = styled.ul`
  background-color: #fff;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 0 20px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InfoItem = styled.p`
  margin-top: 5px;
  span {
    float: left;
    margin-right: 8px;
    font-size: 12px;
    color: #999;
  }
  .count {
    margin-right: 0;
  }
  .answer {
    color: #ee1a21;
  }
`;

export const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-left: 20px;
  text-indent: -9999px;
  &:after {
    position: absolute;
    content: '';
    width: 11px;
    height: 7px;
    background: url(/images/sp_layout.png) no-repeat -225px -190px;
    background-size: 350px 350px;
  }
  &.open {
    &:after {
      background-position: -225px -200px;
    }
  }
`;

export const DetailContents = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: scroll;
  padding: 30px 20px;
  border-bottom: 1px solid #e5e5e5;
  & > div:last-child {
    margin-top: 30px;
  }
`;

export const DetailBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  div {
    margin-top: 10px;
  }
`;

export const NotAnswer = styled.span`
  margin-top: 10px;
  color: #999999;
`;

export const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 44px - 48px - 60px);
  width: 100%;
  & span:first-child {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;
