import React from 'react';
import {Url} from "../../common/constants/environment";

const ScoreCastSoccerHtml = () => {
  return (
      <>

        {/* score_detail_wrap */ }
        <div className="score_detail_wrap"> {/* 클래스 상태 : small_view, ready, close */}

          <div className="summary">
            <button className="btn_prev">이전으로</button>
            <h2 className="tit">UEFA 챔피언스리그</h2>
            <button className="btn_oc">크게보기/작게보기</button>
          </div>

          {/* score_detail_board */ }
          <div className="score_detail_board soccer"> {/* 클래스 종목 : soccer, basketball ---- 종목별 이름 */}

            {/* summary_score */ }
            <div className="summary_score">
              <div className="team left">
                <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                <span className="name">바로 셀로로</span>
                <span className="player">디에고 카스틸로</span>
              </div>
              <div className="info">
                <div className="score">
                  <strong className="left on">
                    <em className="num">0</em>
                    <em className="pk">2</em>
                  </strong>
                  <strong className="right">
                    <em className="num">0</em>
                    <em className="pk">3</em>
                  </strong>
                </div>
                <div className="state">9회말</div>
                <div className="time">10.22 09:00</div>
                <div className="stadium">글로브 라이프 필드</div>
              </div>
              <div className="team right"><img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/14_1550480543.png"}/>
                <div className="name">바로 셀로나</div>
                <span className="player">디에고 카스틸로</span>
              </div>
            </div>
            {/* // summary_score */ }

            {/* detail_score */ }
            <div className="detail_score">
              <ul className="tab">
                <li>
                  <button className="selected">스코어</button>
                </li>
                <li>
                  <button>상황중계</button>
                </li>
              </ul>

              <div className="match_tracker" style={{ display: 'none' }}>
                <iframe scrolling="no" frameBorder="0" src="https://animations.betcoapps.com/#/widget/animation?region=122&amp;competition=1930&amp;game=17233239&amp;gameid=17233239&amp;amp;lang=kor"></iframe>
                <p className="tip">데이터 반영에 차이가 있을 수 있습니다.</p>
              </div>

              <div className="detail_board" style={{ display: '' }}>
                <div className="team">
                  <span>AT 마드리드</span>
                  <span>FC 바로셀로나</span>
                </div>
                <div className="basic_board overtime">
                  <dl className="item">
                    <dt>전반</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>후반</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item on">
                    <dt>연장</dt>
                    <dd>1</dd>
                    <dd>1</dd>
                  </dl>
                  <dl className="item">
                    <dt>PK</dt>
                    <dd>-</dd>
                    <dd>-</dd>
                  </dl>
                  <dl className="item r">
                    <dt>합계</dt>
                    <dd>1</dd>
                    <dd>1</dd>
                  </dl>
                </div>
              </div>
            </div>
            {/* // detail_score */ }

          </div>
          {/* // score_detail_board */ }

          {/* snb_wrap */ }
          <div className="snb_wrap">
            <ul>
              <li><a className="item" href="javascript:;">응원</a></li>
              <li><a className="item" href="javascript:;">전력</a></li>
              <li><a className="item" href="javascript:;">배당</a></li>
              <li><a className="item" href="javascript:;">라인업</a></li>
              <li><a className="item selected" href="javascript:;">중계</a></li>
            </ul>
          </div>
          {/* // snb_wrap */ }

          {/* soccer_cast */ }
          <div className="soccer_cast">
            <div className="inner">
              <div className="item">
                <div className="event start">경기시작</div>
              </div>
              <div className="item">
                <div className="team home">
                  <span className="player">다비드 실바</span>
                  <span className="cont">득점 1:0</span>
                  <span className="time">5분</span>
                </div>
                <div className="event goal">골인</div>
              </div>
              <div className="item">
                <div className="team away">
                  <span className="player">막스 크루제</span>
                  <span className="cont">실패</span>
                  <span className="time">48분</span>
                </div>
                <div className="event no_gaol">실패</div>
              </div>
              <div className="item">
                <div className="team home">
                  <span className="player">다비드 실바</span>
                  <span className="cont">자책골</span>
                  <span className="time">23분</span>
                </div>
                <div className="event own_goal">골인</div>
              </div>
              <div className="item">
                <div className="team away">
                  <span className="player">다비드 실바</span>
                  <span className="cont">경고</span>
                  <span className="time">26분</span>
                </div>
                <div className="event yellow_card">경고</div>
              </div>
              <div className="item">
                <div className="team away">
                  <span className="player">다비드 실바</span>
                  <span className="cont">퇴장</span>
                  <span className="time">29분</span>
                </div>
                <div className="event red_card">퇴장</div>
              </div>
              <div className="item">
                <div className="team away">
                  <span className="player">다비드 실바</span>
                  <span className="cont">득점 3:0</span>
                  <span className="time">44분</span>
                </div>
                <div className="event goal">골인</div>
              </div>
              <div className="item">
                <div className="team home">
                  <span className="player">막스 크루제</span>
                  <span className="cont">PK 성공</span>
                  <span className="time">45분</span>
                </div>
                <div className="event pk_success">PK 성공</div>
              </div>
              <div className="item">
                <div className="event half">하프타임</div>
              </div>
              <div className="item">
                <div className="team home">
                  <span className="player">막스 크루제</span>
                  <span className="cont">코바치치 피트로아파</span>
                  <span className="time">47분</span>
                </div>
                <div className="event change">교체</div>
              </div>
              <div className="item">
                <div className="team away">
                  <span className="player">막스 크루제</span>
                  <span className="cont">PK 실패</span>
                  <span className="time">58분</span>
                </div>
                <div className="event pk_fail">PK 실패</div>
              </div>
            </div>
          </div>
          {/* // soccer_cast */ }

        </div>
        {/* // score_detail_wrap */ }

      </>
  );
};

export default ScoreCastSoccerHtml;
