import { action, observable, runInAction, toJS } from 'mobx';
import { asyncAction } from 'mobx-utils';
import autobind from 'autobind-decorator';
import { MypageService } from '../../../common/services';

@autobind
class MypageStore {
  modalStore;
  history;

  constructor(modalStore, history) {
    this.modalStore = modalStore;
    this.history = history;
  }

  @observable
  code = 200;

  @observable
  message = '';

  @observable
  friends = [];

  @observable
  data = {
    list: [],
    total_cnt: 0,
    max_black_count: '',
    next_point: 0,
    today_exp: 0,
    total_exp: '0',
    total_tp: 0,
    today_point: 0,
    total_point: '0',
    total_cash: 0,
    today_cash: 0,
    total_payment_medal: 0,
    today_payment_medal: 0,
    total_profit_medal: 0,
    total_medal: 0,
    today_profit_medal: 0,
    today_exchange_medal: 0,
    active_item: {},
    stats: {},
    total_payment_medal_list: {},
    gifticon_categories: [],
    gifticon_list: [],
    fetching_gifticon_list: false,
    medal_history_list: {},
  };

  @observable
  levelList = [];

  @observable
  isNickCheck = false;

  /**********************************************************
   * computed
   **********************************************************/
  // @computed
  // get getResponse() {
  //   return {
  //     code: 200,
  //   };
  // }

  @action
  clearNickCheck() {
    this.isNickCheck = false;
  }

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  *actionFriendList(param) {
    const { searchType, page, limit } = param;

    if (!param) {
      return;
    }

    const res = yield MypageService.list(searchType, page, limit);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.friends = res.data?.list ?? [];
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionList(param) {
    const { searchType, page } = param;

    if (!param) {
      return;
    }

    const res = yield MypageService.list(searchType, page);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionFriendDelete(param) {
    const { searchType, deleteMbId } = param;

    if (!param) {
      return;
    }
    if (!searchType || !deleteMbId) {
      return;
    }

    const res = yield MypageService.friendDelete(deleteMbId);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
      });

      yield this.actionList({ searchType, page: '1' });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionRecommend(param) {
    if (!param) {
      return;
    }
    const { page } = param;
    const res = yield MypageService.recommend(page);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionRecommendDelete(param) {
    if (!param) {
      return;
    }

    const { deleteMbId, page } = param;

    if (!deleteMbId) {
      return;
    }

    const res = yield MypageService.friendRecommendDelete(deleteMbId);

    if (res.code === 200) {
      yield this.actionRecommend({ page: page || 1 });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionBlackList(param) {
    const { page, limit } = param;

    if (!param) {
      return;
    }
    if (!page) {
      return;
    }

    const res = yield MypageService.blackList(page, limit);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...res.data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });
      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    return res.code === 200;
  }

  @asyncAction
  *actionLimitChange() {
    const res = yield MypageService.blackLimitChange();

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `블랙리스트 등록제한 10개를 추가하였습니다.`,
      });

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...res.data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionNickChange(param, callback) {
    const { nick } = param;

    if (!param) {
      return;
    }
    if (!nick) {
      this.modalStore.openAlertModal({
        message: `변경하실 닉네임을 입력하세요.`,
      });
      return;
    }

    const res = yield MypageService.nickChange(nick);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `회원님의 닉네임이 ${nick}으로 변경되었습니다.`,
        callback: () => {
          callback();
        },
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionTodayWordChange(param, callback) {
    const { todayWord } = param;

    if (!param) {
      return;
    }
    if (!todayWord) {
      this.modalStore.openAlertModal({
        message: `변경하실 오늘의 한마디를 입력하세요.`,
      });
      return;
    }

    const res = yield MypageService.todayWordChange(todayWord);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `오늘의 한마디가 변경되었습니다.`,
        callback: () => {
          callback();
        },
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionProfileTmpImage(param) {
    const { profileImage } = param;

    if (!param) {
      return;
    }

    if (!profileImage) {
      this.modalStore.openAlertModal({
        message: `변경하실 프로필 이미지를 선택하세요.`,
      });
      return;
    }

    const res = yield MypageService.profileTmpImageChange(profileImage);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...res.data };
      });
    } else {
      this.modalStore.openAlertModal({ message: res.message });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionProfileImageChange(param, callback) {
    let { filePath, size, pointX, pointY } = param;

    size = size || 0;
    pointX = pointX || 0;
    pointY = pointY || 0;

    if (!param) {
      return;
    }

    if (!filePath) {
      this.modalStore.openAlertModal({
        message: `변경할 프로필 이미지를 선택해주세요.`,
      });
      return;
    }

    const res = yield MypageService.profileImageChange(filePath, size, pointX, pointY);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `프로필 이미지가 변경되었습니다.`,
        callback: () => {
          callback();
        },
      });
    } else {
      this.modalStore.openAlertModal({ message: res.message });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionBackgroundTmpImage(param) {
    const { backgroundImage } = param;

    if (!param) {
      return;
    }
    if (!backgroundImage) {
      this.modalStore.openAlertModal({
        message: `변경하실 배경 이미지를 선택하세요.`,
      });
      return;
    }

    const res = yield MypageService.backgroundTmpImageChange(backgroundImage);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...res.data };
      });
    } else {
      this.modalStore.openAlertModal({ message: res.message });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionBackgroundImageChange(param, callback) {
    let { filePath, width, height, pointX, pointY } = param;

    pointX = pointX || 0;
    pointY = pointY || 0;

    if (!param) {
      return;
    }
    if (!filePath) {
      this.modalStore.openAlertModal({
        message: `변경할 프로필 배경 이미지를 선택해주세요.`,
      });
      return;
    }

    const res = yield MypageService.backgroundImageChange(filePath, width, height, pointX, pointY);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `프로필 배경 이미지가 변경되었습니다.`,
        callback: () => {
          callback();
        },
      });
    } else {
      this.modalStore.openAlertModal({ message: res.message });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionPasswordChange(param) {
    const { currentPw, pw, pwRe } = param;

    if (!param) {
      return;
    }
    if (!currentPw) {
      this.modalStore.openAlertModal({
        message: '현재 비밀번호를 입력하세요.',
      });
      return;
    }
    if (!pw) {
      this.modalStore.openAlertModal({ message: '새 비밀번호를 입력하세요.' });
      return;
    }
    if (!pwRe) {
      this.modalStore.openAlertModal({
        message: '새 비밀번호 확인을 입력하세요.',
      });
      return;
    }
    if (pw !== pwRe) {
      this.modalStore.openAlertModal({
        message: '새 비밀번호와 새 비밀번호확인이 동일하지 않습니다.',
      });
      return;
    }
    if (pw.length < 6) {
      this.modalStore.openAlertModal({
        message: '비밀번호는 6글자 이상 입력해주세요.',
      });
      return;
    }

    const res = yield MypageService.passwordChange(currentPw, pw);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: '새로운 비밀번호로 변경이 완료되었습니다.',
      });

      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionSecondPasswordChange(param) {
    const { certifyToken, password, passwordRe } = param;
    const regexp = /^[0-9]*$/;

    if (!param) {
      return;
    }
    if (!certifyToken) {
      this.modalStore.openAlertModal({ message: '인증 토큰이 없습니다.' });
      return;
    }
    if (!password) {
      this.modalStore.openAlertModal({ message: '비밀번호를 입력해 주세요.' });
      return;
    }
    if (!regexp.test(password)) {
      this.modalStore.openAlertModal({
        message: '2차 비밀번호는 4자리 숫자로 입력해 주세요.',
      });
      return;
    }
    if (!passwordRe) {
      this.modalStore.openAlertModal({
        message: '비밀번호를 한번 더 입력해 주세요.',
      });
      return;
    }
    if (password !== passwordRe) {
      this.modalStore.openAlertModal({
        message: '비밀번호가 일치하지 않습니다.',
      });
      return;
    }

    const res = yield MypageService.secondPasswordChange(certifyToken, password);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: '2차 비밀번호로 변경이 완료되었습니다.',
      });

      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
      });

      this.history.replace('/mypage/info');
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionAuthResult(param, callback) {
    const { type, token } = param;

    if (!param) {
      return;
    }
    if (!token) {
      this.modalStore.openAlertModal({ message: '인증 토큰이 없습니다.' });
      return;
    }

    let res;
    if (type === 'phone') {
      res = yield MypageService.authPhoenResult(token);
    } else {
      res = yield MypageService.authIpinResult(token);
    }

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: '인증이 완료되었습니다.',
        callback: () => {
          callback && callback(res);
        },
      });

      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionExp(param) {
    const { startDt, endDt, page } = param;

    if (!param) {
      return;
    }
    if (!startDt || !endDt) {
      this.modalStore.openAlertModal({ message: '날짜를 입력해주세요.' });
      return;
    }

    let res = yield MypageService.exp(startDt, endDt, page);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...res.data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionPoint(param) {
    const { startDt, endDt, page } = param;

    if (!param) {
      return;
    }
    if (!startDt || !endDt) {
      this.modalStore.openAlertModal({ message: '날짜를 입력해주세요.' });
      return;
    }

    let res = yield MypageService.point(startDt, endDt, page);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...res.data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionLoginLog(param) {
    const { startDt, endDt, page } = param;

    if (!param) {
      return;
    }
    if (!startDt || !endDt) {
      this.modalStore.openAlertModal({ message: '날짜를 입력해주세요.' });
      return;
    }

    let res = yield MypageService.loginlog(startDt, endDt, page);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...res.data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionIpAdd(param, callback) {
    const { name, ip } = param;

    if (!param) {
      return;
    }
    if (!name) {
      this.modalStore.openAlertModal({ message: 'IP명을 입력해주세요.' });
      return;
    }
    if (!ip) {
      this.modalStore.openAlertModal({ message: 'IP를 입력해주세요.' });
      return;
    }

    let res = yield MypageService.ipAdd(name, ip);

    if (res.code === 200) {
      this.modalStore.openAlertModal({ message: '등록 되었습니다.' });

      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
      });

      yield callback && callback();
      this.history.replace('/mypage/info');
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionIpDelete(param, callback) {
    const { ip } = param;

    if (!param) {
      return;
    }
    if (!ip) {
      this.modalStore.openAlertModal({ message: 'IP를 입력해주세요.' });
      return;
    }

    let res = yield MypageService.ipDelete(ip);

    if (res.code === 200) {
      this.modalStore.openAlertModal({ message: '삭제 되었습니다.' });

      runInAction(() => {
        this.code = res.code;
        this.data = res.data;
      });

      yield callback && callback();
      this.history.replace('/mypage/info');
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionMarketList(param) {
    const { category } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.marketList(category);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = { 
          ...this.data, 
          ...res.data, 
          total_payment_medal: this.data.total_payment_medal 
        };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionGifticonCategoryList() {
    let res = yield MypageService.gifticonCategoryList();

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...{ gifticon_categories: res.data } };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionGifticonCategoryDetailList(id) {
    if (!id) {
      return;
    }

    this.data = {
      ...this.data,
      fetching_gifticon_list: true,
    };

    let res = yield MypageService.gifticonCategotyDetailList(id);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = {
          ...this.data,
          ...{
            gifticon_list: res.data?.list ?? [],
            total_cash: res.data?.total_cash ?? this.data.total_cash,
            total_payment_medal: res.data?.total_payment_medal ?? this.data.total_payment_medal,
            total_profit_medal: res.data?.total_profit_medal ?? this.data.total_profit_medal,
            total_point: res.data?.total_point ?? this.data.total_point,
            total_medal: res.data?.total_medal ?? this.data.total_medal,
            fetching_gifticon_list: false,
          },
        };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
        this.data = {
          ...this.data,
          fetching_gifticon_list: false,
        };
      });
    }
  }

  @asyncAction
  *actionGifticonItemBuy(param) {
    let { product_name, productId, count, payTypeSelected } = param;

    count = parseInt(count);

    if (!param) {
      return;
    }

    if (!product_name || !productId || !payTypeSelected) {
      return;
    }

    if (!count || parseInt(count) === 0) {
      this.modalStore.openAlertModal({ message: '갯수를 입력하세요' });
      return;
    }

    let responseCanBuy = yield MypageService.isItemGifticonCanBuy(productId, count, payTypeSelected);

    if (responseCanBuy.code === 200 && responseCanBuy.data?.success) {
      let responseBuy = yield MypageService.itemGifticonBuy(productId, count, payTypeSelected);

      if (responseBuy.code === 200) {
        this.modalStore.openConfirmModal({
          message: `${product_name} 기프티콘 구매 신청이 완료되었습니다.\n신청 완료 후에는 문의하기를 통해 구매 사실과 휴대폰번호를 남겨주시면, 3영업일 내로 문자를 통해 기프티콘이 발송됩니다.\n문의하기로 이동하시겠습니까?`,
          okCallback: () => {
            this.history.push('/customercenter/qna/write');
          },
          callback: () => {
            window.location.reload();
          },
        });

        runInAction(() => {
          this.code = responseBuy.code;
        });
      } else {
        this.modalStore.openAlertModal({
          message: responseBuy.message,
        });

        runInAction(() => {
          this.code = responseBuy.code;
          this.message = responseBuy.message;
        });
      }
    } else {
      this.modalStore.openAlertModal({
        message: responseCanBuy.data?.message,
      });

      runInAction(() => {
        this.code = responseCanBuy.code;
        this.message = responseCanBuy.data?.message;
      });
    }
  }

  @asyncAction
  *actionItemUse(param) {
    const { item } = param;

    const res = yield MypageService.myItemUse(item.id);

    if (res.code === 200) {
      let message = '';

      if ((item.code.includes('RAND') || item.code.includes('JACKPOT')) && item.code.includes('BOX')) {
        message = `${res.data[0].item_name}획득`;
      } else {
        message = `${item.name} 아이템이 사용되었습니다.`;
      }
      this.modalStore.openAlertModal({
        message: message,
      });

      runInAction(() => {
        this.code = res.code;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    return res;
  }

  @asyncAction
  *actionItemBuy(param) {
    let { name, bonus, itemId, count, payTypeSelected } = param;

    count = parseInt(count);

    if (!param) {
      return;
    }
    if (!name || !bonus || !itemId || !payTypeSelected) {
      return;
    }
    if (!count || parseInt(count) === 0) {
      this.modalStore.openAlertModal({ message: '갯수를 입력하세요' });
      return;
    }

    let res = yield MypageService.itemBuy(itemId, count, payTypeSelected);

    if (res.code === 200) {
      this.modalStore.openConfirmModal({
        message: `${name} 아이템을 ${count}개 구매하였습니다.\n${
          parseInt(bonus) !== 0 ? `아이템 구매로 보너스 경험치가 [${bonus}]점 추가되었습니다.\n` : ''
        }아이템 사용을 위해 마이홈으로 이동하시겠습니까?`,
        okCallback: () => {
          this.history.push('/mypage/item');
        },
        callback: () => {
          const category = this.history.location.search.replace('?&category=', '');
          this.actionMarketList({
            category: category === 'all' ? '' : category,
          });
        },
      });

      runInAction(() => {
        this.code = res.code;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionSendGiftMarket(param) {
    let { itemId, giftNick, count, sendMessage, payType } = param;

    count = parseInt(count);

    if (!param) {
      return;
    }
    if (!itemId) {
      this.modalStore.openAlertModal({ message: '선물을 선택하세요.' });
      return;
    }
    if (!giftNick) {
      this.modalStore.openAlertModal({
        message: '선물 받는사람을 선택하세요.',
      });
      return;
    }
    if (!count || parseInt(count) === 0) {
      this.modalStore.openAlertModal({ message: '갯수를 입력하세요' });
      return;
    }
    if (!payType) {
      this.modalStore.openAlertModal({ message: '구매 수단을 선택하세요.' });
      return;
    }

    let res = yield MypageService.sendGiftMarket(itemId, giftNick, count, sendMessage, payType);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
      });

      this.modalStore.openAlertModal({
        message: `${giftNick}님께 아이템을 구매하여 선물하였습니다.`,
        callback: () => {
          window.location.reload();
        },
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionSendGift(param) {
    let { itemId, giftNick, count, sendMessage } = param;

    count = parseInt(count);

    if (!param) {
      return;
    }
    if (!itemId) {
      this.modalStore.openAlertModal({ message: '선물을 선택하세요.' });
      return;
    }
    if (!giftNick) {
      this.modalStore.openAlertModal({
        message: '선물 받는사람을 선택하세요.',
      });
      return;
    }
    if (!count || parseInt(count) === 0) {
      this.modalStore.openAlertModal({ message: '갯수를 입력하세요' });
      return;
    }

    let res = yield MypageService.sendGift(itemId, giftNick, count, sendMessage);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
      });

      this.modalStore.openAlertModal({
        message: `${giftNick}님께 아이템을 선물하였습니다.`,
        callback: () => window.location.reload(),
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionMyItemList(param) {
    let { category, allowTrade } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.myItemList(category, allowTrade);

    if (res.code === 200) {
      const data = {
        active_item: res.data.active_item,
        list: res.data.item.list,
        total_cnt: res.data.item.total_count,
      };

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionBuyItemList(param) {
    let { startDt, endDt, page } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.buyItemsList(startDt, endDt, page);

    if (res.code === 200) {
      const data = {
        active_item: res.data.active_item,
        list: res.data.buy_item.list,
        total_cnt: res.data.buy_item.total_cnt,
        total_cash: res.data.total_cash,
        today_cash: res.data.today_cash,
      };

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionSendItemList(param) {
    let { startDt, endDt, page } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.sendItemsList(startDt, endDt, page);

    if (res.code === 200) {
      const data = {
        active_item: res.data.active_item,
        list: res.data.send_item.list,
        total_cnt: res.data.send_item.total_cnt,
      };

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionReceiveItemsList(param) {
    let { startDt, endDt, page } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.receiveItemsList(startDt, endDt, page);

    if (res.code === 200) {
      const data = {
        active_item: res.data.active_item,
        list: res.data.receive_item.list,
        total_cnt: res.data.receive_item.total_cnt,
      };

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionPaymentMedalList(param) {
    let { startDt, endDt, page } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.paymentMedalList(startDt, endDt, page);

    if (res.code === 200) {
      const data = {
        payment_medal_list: res.data.total_payment_medal_list.list,
        total_cnt: res.data.total_payment_medal_list.total_cnt,
        total_payment_medal: res.data.total_payment_medal,
        today_payment_medal: res.data.today_payment_medal,
        today_exchange_medal: res.data.today_exchange_medal,
      };

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionMedalExchangeHistoryList(param) {
    let { page } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.medalExchangeHistoryList(page);

    if (res.code === 200) {
      const data = {
        medal_history_list: {
          count: res.data.count,
          list: res.data.list,
        },
      };

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionProfitMedalList(param) {
    let { startDt, endDt, page } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.profitMedalList(startDt, endDt, page);

    if (res.code === 200) {
      console.log(res.data);
      const data = {
        profit_medal_list: res.data.profit_medal.list,
        total_cnt: res.data.profit_medal.total_cnt,
        total_profit_medal: res.data.total_profit_medal,
        total_medal: res.data.total_medal,
        today_profit_medal: res.data.today_profit_medal,
      };

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionCashChargeList(param) {
    let { startDt, endDt, page } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.cashChargeList(startDt, endDt, page);

    if (res.code === 200) {
      const data = {
        active_item: res.data.active_item,
        list: res.data.payment.list,
        total_cnt: res.data.payment.total_cnt,
        total_cash: res.data.total_cash,
        today_cash: res.data.today_cash,
      };

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionTradeList(param) {
    let { type, status, itemId, page } = param;

    if (!param) {
      return;
    }
    if (!type) {
      return;
    }

    let me = status === 'me' ? 'y' : '';
    let res = yield MypageService.tradeList(type, status, itemId, me, page);

    if (res.code === 200) {
      const data = {
        stats: res.data.stats,
        list: res.data.list,
        total_cnt: res.data.total_cnt,
      };

      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionTradeMarketItems() {
    let res = yield MypageService.tradeMarketItems();

    if (res.code === 200) {
      const data = {
        list: res.data,
      };

      runInAction(() => {
        this.code = res.code;
        this.data = data;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionTradeAdd(param) {
    let { type, title, itemId, point } = param;

    if (!param) {
      return;
    }
    if (!type) {
      this.modalStore.openAlertModal({ message: '거래방식이 없습니다.' });
      return;
    }
    if (!title) {
      this.modalStore.openAlertModal({ message: '거래코멘트를 입력해주세요.' });
      return;
    }
    if (!point) {
      this.modalStore.openAlertModal({ message: '거래가격을 입력해주세요.' });
      return;
    }

    let res = yield MypageService.tradeAdd(
      type,
      title,
      type === 'sell' ? itemId : '',
      type === 'sell' ? '' : itemId,
      point
    );

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `${type === 'sell' ? '판매' : '구매'} 등록이 완료 되었습니다.`,
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    return res;
  }

  @asyncAction
  *actionPw2(param) {
    let { secondPassword } = param;

    if (!param) {
      return;
    }
    if (!secondPassword) {
      this.modalStore.openAlertModal({ message: '2차 비밀번호를 입력하세요.' });
      return;
    }

    let res = yield MypageService.pw2(secondPassword);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: '2차 비밀번호 확인이 완료되었습니다.\n다음 단계로 진행해주세요.',
      });

      runInAction(() => {
        this.code = res.code;
      });
    } else {
      this.modalStore.openAlertModal({ message: res.message });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    return res;
  }

  @asyncAction
  *actionTradeMarketBuy(param) {
    let { no, itemName, point } = param;

    if (!param) {
      return;
    }
    if (!no || !itemName || !point) {
      return;
    }

    let res = yield MypageService.tradeMarketBuy(no);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
      });

      this.modalStore.openAlertModal({
        message: `${itemName} 아이템을 ${point} 포인트로  구매하였습니다.`,
        callback: () => {
          window.location.reload();
        },
      });
    } else {
      this.modalStore.openAlertModal({ message: res.message });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionTradeMarketSell(param) {
    let { no, itemName, myItemNo, point } = param;

    if (!param) {
      return;
    }
    if (!myItemNo) {
      this.modalStore.openAlertModal({
        message: `판매 가능한 아이템이 없습니다.`,
      });
      return;
    }
    if (!no || !itemName || !point) {
      return;
    }

    let res = yield MypageService.tradeMarketSell(no, myItemNo);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
      });

      this.modalStore.openAlertModal({
        message: `거래성공!! ${itemName} 아이템을 ${point} 포인트에 판매하였습니다.`,
        callback: () => {
          window.location.reload();
        },
      });
    } else {
      this.modalStore.openAlertModal({ message: res.message });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionTradeMarketCancel(param) {
    let { type, no, point } = param;

    if (!param) {
      return;
    }
    if (!type || !no || !point) {
      return;
    }

    let res = yield MypageService.tradeMarketCancel(no);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
      });

      let message = '';
      if (type === 'sell') {
        message = `아이템을 [팝니다]에서 취소하였습니다.\n취소된 아이템은 내 아이템에 추가됩니다.`;
      } else {
        message = `아이템을 [삽니다]에서 취소하였습니다.\n등록시 차감된 ${point}포인트가 적립 되었습니다.`;
      }

      this.modalStore.openAlertModal({
        message,
        callback: () => {
          window.location.reload();
        },
      });
    } else {
      this.modalStore.openAlertModal({ message: res.message });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionNickCheck(param) {
    const { nick } = param;

    if (!param) {
      return;
    }
    if (!nick) {
      this.modalStore.openAlertModal({
        message: `중복체크 할 닉네임을 입력하세요.`,
      });
      return;
    }

    const res = yield MypageService.nickCheck(nick);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `사용 가능한 닉네임 입니다.`,
      });

      runInAction(() => {
        this.code = res.code;
        this.isNickCheck = true;
      });
    } else {
      this.modalStore.openAlertModal({
        message: `이미 사용중인 닉네임 입니다.`,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    return res.code;
  }

  @asyncAction
  *actionTradePointList(param) {
    const { page } = param;

    if (!param) {
      return;
    }

    let res = yield MypageService.tradePointList(page);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.data = { ...this.data, ...res.data };
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionTradePointRefill(callback) {
    let res = yield MypageService.tradePointRefill();

    if (res.code === 200) {
      runInAction(() => {
        this.modalStore.openAlertModal({
          message: '충전 되었습니다.',
          callback: () => {
            callback && callback(res);
          },
        });

        this.code = res.code;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionLevelList() {
    const res = yield MypageService.findLevel();

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.levelList = res.data;
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionRequestCashBill(params, callback) {
    const res = yield MypageService.actionRequestCashBill(params);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: '신청이 완료되었습니다.',
        callback: () => {
          callback && callback();
        },
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
        callback: () => {
          callback && callback();
        },
      });
    }
  }
}

export default MypageStore;
