import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import useSportsStore from '../../../../../hooks/useSportsStore';
import { timeUtils } from '../../../../../common/utils/timeUtil';

const SoccerFinalButton = observer(({ sportsType, date }) => {
  const store = useSportsStore('soccer');
  const today = timeUtils.todayYmd();

  const onClickFinal = () => {
    let result = 'FINAL';

    if (store.selectedTab === 'FINAL') {
      result = 'ALL';
    } else {
      store.getTodayFinalSoccer(today);
    }

    store.setSoccerSelectedTab(result);
  };

  useEffect(() => {
    if (sportsType === 'soccer' && date !== today) {
      store.setSoccerSelectedTab('ALL');
    }
  }, [date]);

  if (sportsType === 'soccer' && date === today) {
    return (
      <button
        type="button"
        className={clsx('btn_final', store.selectedTab === 'FINAL' && 'active')}
        onClick={onClickFinal}
      >
        종료
      </button>
    );
  } else {
    return null;
  }
});

export default SoccerFinalButton;
