import React from 'react';
import {Url} from "../../common/constants/environment";

const ScoreDetailHtml = () => {
  return (
      <>

        {/* score_detail_wrap */ }
        <div className="score_detail_wrap"> {/* 클래스 상태 : small_view, ready, close */}

          <div className="summary">
            <button className="btn_prev">이전으로</button>
            <h2 className="tit">UEFA 챔피언스리그</h2>
            <button className="btn_oc">크게보기/작게보기</button>
          </div>

          {/* score_detail_board */ }
          <div className="score_detail_board soccer"> {/* 클래스 종목 : soccer, basketball ---- 종목별 이름 */}

            {/* summary_score */ }
            <div className="summary_score">
              <div className="team left">
                <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                <span className="name">바로 셀로로</span>
                <span className="player">디에고 카스틸로</span>
              </div>
              <div className="info">
                <div className="score">
                  <strong className="left on">
                    <em className="num">0</em>
                    <em className="pk">2</em>
                  </strong>
                  <strong className="right">
                    <em className="num">0</em>
                    <em className="pk">3</em>
                  </strong>
                </div>
                <div className="state">9회말</div>
                <div className="time">10.22 09:00</div>
                <div className="stadium">글로브 라이프 필드</div>
              </div>
              <div className="team right"><img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/14_1550480543.png"}/>
                <div className="name">바로 셀로나</div>
                <span className="player">디에고 카스틸로</span>
              </div>
            </div>
            {/* // summary_score */ }

            {/* detail_score */ }
            <div className="detail_score">
              <ul className="tab">
                <li>
                  <button className="selected">스코어</button>
                </li>
                <li>
                  <button>상황중계</button>
                </li>
              </ul>

              <div className="match_tracker" style={{ display: 'none' }}>
                <iframe scrolling="no" frameBorder="0" src="https://animations.betcoapps.com/#/widget/animation?region=122&amp;competition=1930&amp;game=17233239&amp;gameid=17233239&amp;amp;lang=kor"></iframe>
                <p className="tip">데이터 반영에 차이가 있을 수 있습니다.</p>
              </div>

              <div className="detail_board" style={{ display: '' }}>
                <div className="team">
                  <span>AT 마드리드</span>
                  <span>FC 바로셀로나</span>
                </div>
                <div className="basic_board overtime">
                  <dl className="item">
                    <dt>전반</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item">
                    <dt>후반</dt>
                    <dd>0</dd>
                    <dd>0</dd>
                  </dl>
                  <dl className="item on">
                    <dt>연장</dt>
                    <dd>1</dd>
                    <dd>1</dd>
                  </dl>
                  <dl className="item">
                    <dt>PK</dt>
                    <dd>-</dd>
                    <dd>-</dd>
                  </dl>
                  <dl className="item r">
                    <dt>합계</dt>
                    <dd>1</dd>
                    <dd>1</dd>
                  </dl>
                </div>
              </div>
            </div>
            {/* // detail_score */ }

          </div>
          {/* // score_detail_board */ }

          {/* snb_wrap */ }
          <div className="snb_wrap">
            <ul>
              <li><a className="item" href="javascript:;">응원</a></li>
              <li><a className="item" href="javascript:;">전력</a></li>
              <li><a className="item selected" href="javascript:;">배당</a></li>
              <li><a className="item" href="javascript:;">라인업</a></li>
              <li><a className="item" href="javascript:;">중계</a></li>
            </ul>
          </div>
          {/* // snb_wrap */ }

          {/* odds_area */ }
          <div className="odds_area">

            {/* odds_column */ }
            <div className="odds_column">
              <h3 className="tit">국내, 해외 배당 비교</h3>
              <div className="tab">
                <ul>
                  <li>
                    <button className="selected">국내</button>
                  </li>
                  <li>
                    <button>해외</button>
                  </li>
                </ul>
              </div>
              <div className="odds_list">
                <div className="hd">
                  <span>승</span>
                  <span>무</span>
                  <span>패</span>
                </div>
                <div className="bd">
                  <div className="item">
                    <div className="inner">
                      <div className="type">일반</div>
                      <span className="up selected">1.78</span>
                      <span className="down">1.11</span>
                      <span>1.11</span>
                    </div>
                    <div className="change_list">
                      <div className="list">
                        <table>
                          <colgroup>
                            <col width=""/>
                            <col width="30%"/>
                            <col width="30%"/>
                          </colgroup>
                          <thead>
                          <tr>
                            <th>일시</th>
                            <th>배당</th>
                            <th>변화</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>2020.00.00 00:00</td>
                            <td className="up">1.78</td>
                            <td>+0.02</td>
                          </tr>
                          <tr>
                            <td>2020.00.00 00:00</td>
                            <td className="up">1.76</td>
                            <td>+0.1</td>
                          </tr>
                          <tr>
                            <td>2020.00.00 00:00</td>
                            <td className="up">1.66</td>
                            <td>+0.02</td>
                          </tr>
                          <tr>
                            <td>2020.00.00 00:00</td>
                            <td className="down">1.64</td>
                            <td>-0.02</td>
                          </tr>
                          <tr>
                            <td>초기</td>
                            <td>1.66</td>
                            <td>-</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="inner">
                      <div className="type">핸디캡<em>+1.5</em></div>
                      <span>1.11</span>
                      <span>1.11</span>
                      <span>1.11</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* // odds_column */ }

            {/* odds_column */ }
            <div className="odds_column">
              <h3 className="tit">해외 배당 현황</h3>
              <div className="tab">
                <ul>
                  <li>
                    <button className="selected">일반</button>
                  </li>
                  <li>
                    <button>핸디캡</button>
                  </li>
                  <li>
                    <button>언더/오버</button>
                  </li>
                </ul>
              </div>
              <div className="odds_list foreign_list">
                <div className="hd">
                  <span>기준</span>
                  <span>승</span>
                  <span>무</span>
                  <span>패</span>
                </div>
                <div className="bd">
                  <div className="item">
                    <div className="inner">
                      <div className="type">1xBet</div>
                      <span className="point">+1.5</span>
                      <span className="up">1.66</span>
                      <span className="down">1.11</span>
                      <span>1.11</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="inner">
                      <div className="type">bet365</div>
                      <span className="point">+1.5</span>
                      <span className="up">1.66</span>
                      <span className="down">1.11</span>
                      <span>1.11</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="inner">
                      <div className="type">SBOBET</div>
                      <span className="point">+1.5</span>
                      <span className="up">1.66</span>
                      <span className="down">1.11</span>
                      <span>1.11</span>
                    </div>
                    <div className="change_list">
                      <div className="list">
                        <table>
                          <colgroup>
                            <col width=""/>
                            <col width="30%"/>
                            <col width="30%"/>
                          </colgroup>
                          <thead>
                          <tr>
                            <th>일시</th>
                            <th>배당</th>
                            <th>변화</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>2020.00.00 00:00</td>
                            <td className="up">1.66</td>
                            <td>+0.02</td>
                          </tr>
                          <tr>
                            <td>2020.00.00 00:00</td>
                            <td className="down">1.64</td>
                            <td>-0.02</td>
                          </tr>
                          <tr>
                            <td>초기</td>
                            <td>1.66</td>
                            <td>-</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* // odds_column */ }

          </div>
          {/* // odds_area */ }

        </div>
        {/* // score_detail_wrap */ }

      </>
  );
};

export default ScoreDetailHtml;
