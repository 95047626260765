import React from 'react';

const MypageLoginLogHtml = () => {
  return (
    <>
      {/* history_wrap */}
      <div className="history_wrap login_log">
        <div className="list_area">
          <div className="tip_area">
            <p className="tx">※ 최대 3개월 이내의 내역만 확인 가능합니다.</p>
          </div>
          <div className="list">
            <div className="item">
              <span className="state">성공</span>
              <span className="info">
                <em className="tx">2020.85.141.179 : 데스크탑</em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
            </div>
            <div className="item">
              <span className="state fail">실패</span>
              <span className="info">
                <em className="tx">2020.85.141.179 : 모바일</em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* // history_wrap */}
    </>
  );
};

export default MypageLoginLogHtml;
