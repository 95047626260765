import Api from '../api';
import { Url } from '../../constants/environment';
import Cookie from '../../utils/cookie';

class MypageService extends Api {
  constructor(props) {
    super(props);
  }

  /**
   * @description 친구 등록
   * @returns
   */
  async addFriend(friendId) {
    const mdId = Cookie.getCookie('mb_id');
    if (!mdId) {
      return;
    }

    const form = new FormData();
    form.append('mb_id', mdId);
    form.append('friend_id', friendId);

    const res = await this.commonAjax('post', Url.named, `user/friend`, form);

    return res;
  }

  /**
   * @description 친구 목록
   * @returns
   */
  async list(searchType, page, limit = 10) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `user/friend`, {
      mb_id: Cookie.getCookie('mb_id'),
      search_type: searchType,
      page,
      limit
    });

    return res;
  }

  /**
   * @description 친구 삭제
   * @returns
   */
  async friendDelete(deleteMbId) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('delete', Url.named, `user/friend`, {
      mb_id: Cookie.getCookie('mb_id'),
      delete_mb_id: deleteMbId
    });

    return res;
  }

  /**
   * @description 친구추천 목록
   * @returns
   */
  async recommend(page, limit = 10) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax(
      'get',
      Url.named,
      `user/recommend-friend`,
      {
        mb_id: Cookie.getCookie('mb_id'),
        page,
        limit
      }
    );

    return res;
  }

  /**
   * @description 친구추천 삭제
   * @returns
   */
  async friendRecommendDelete(deleteMbId) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax(
      'delete',
      Url.named,
      `user/recommend-friend`,
      {
        mb_id: Cookie.getCookie('mb_id'),
        delete_mb_id: deleteMbId
      }
    );

    return res;
  }

  /**
   * @description 블랙리스트 목록
   * @returns
   */
  async blackList(page, limit = 10) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `user/black-list`, {
      mb_id: Cookie.getCookie('mb_id'),
      page,
      limit
    });

    return res;
  }

  /**
   * @description 블랙리스트 등록제한 10개 축가
   * @returns
   */
  async blackLimitChange() {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const form = new FormData();
    form.append('mb_id', Cookie.getCookie('mb_id'));

    const res = await this.commonAjax(
      'post',
      Url.named,
      `user/black-list/limit`,
      form
    );

    return res;
  }

  /**
   * @description 닉네임 변경
   * @returns
   */
  async nickChange(nick) {
    if (!nick) {
      return;
    }

    const form = new URLSearchParams();
    form.append('nick', nick);

    const res = await this.commonAjax('put', Url.named, `items/nick`, form);

    return res;
  }

  /**
   * @description 닉네임 조회
   * @returns
   */
  async nickCheck(nick) {
    if (!nick) {
      return;
    }

    const form = new URLSearchParams();
    form.append('nick', nick);

    const res = await this.commonAjax(
      'get',
      Url.named,
      `items/exist-nick`,
      form
    );

    return res;
  }

  /**
   * @description 오늘의 한마디 변경
   * @returns
   */
  async todayWordChange(today_word) {
    if (!today_word) {
      return;
    }

    const form = new URLSearchParams();
    form.append('today_word', today_word);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `items/today-word`,
      form
    );

    return res;
  }

  /**
   * @description 프로필 이미지 변경
   * @returns
   */
  async profileImageChange(
    file_path,
    size = '0',
    point_x = '0',
    point_y = '0'
  ) {
    if (!file_path) {
      return;
    }

    const form = new URLSearchParams();
    form.append('file_path', file_path);
    form.append('size', size);
    form.append('point_x', point_x);
    form.append('point_y', point_y);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `items/profile-image`,
      form
    );

    return res;
  }

  /**
   * @description 배경 이미지 변경
   * @returns
   */
  async backgroundImageChange(
    file_path,
    width = '0',
    height = '0',
    point_x = '0',
    point_y = '0'
  ) {
    if (!file_path) {
      return;
    }

    const form = new URLSearchParams();
    form.append('file_path', file_path);
    form.append('width', width);
    form.append('height', height);
    form.append('point_x', point_x);
    form.append('point_y', point_y);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `items/background-image`,
      form
    );

    return res;
  }

  /**
   * @description 프로필 이미지 변경
   * @returns
   */
  async profileTmpImageChange(file) {
    if (!file) {
      return;
    }

    const form = new FormData();
    form.append('file', file);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `items/profile-temp-image`,
      form
    );

    return res;
  }

  /**
   * @description 배경 이미지 변경
   * @returns
   */
  async backgroundTmpImageChange(file) {
    if (!file) {
      return;
    }

    const form = new FormData();
    form.append('file', file);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `items/background-temp-image`,
      form
    );

    return res;
  }

  /**
   * @description 비밀번호 변경
   * @returns
   */
  async passwordChange(current_password, new_password) {
    if (!current_password || !new_password) {
      return;
    }

    const form = new URLSearchParams();
    form.append('current_password', current_password);
    form.append('new_password', new_password);

    const res = await this.commonAjax('put', Url.named, `me/password`, form);

    return res;
  }

  /**
   * @description 비밀번호 변경
   * @returns
   */
  async secondPasswordChange(certifyToken, password) {
    if (!certifyToken || !password) {
      return;
    }

    const form = new URLSearchParams();
    form.append('certify_token', certifyToken);
    form.append('second_password', password);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `me/second-password`,
      form
    );

    return res;
  }

  /**
   * @description 마이페이지 휴대폰 인증
   * @returns
   */
  async authPhoenResult(certify_token) {
    if (!certify_token) {
      return;
    }

    const form = new URLSearchParams();
    form.append('certify_token', certify_token);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `me/certify-phone`,
      form
    );

    return res;
  }

  /**
   * @description 마이페이지 아이핀 인증
   * @returns
   */
  async authIpinResult(certify_token) {
    if (!certify_token) {
      return;
    }

    const form = new URLSearchParams();
    form.append('certify_token', certify_token);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `me/certify-ipin`,
      form
    );

    return res;
  }

  /**
   * @description 마이페이지 경험치 리스트
   * @returns
   */
  async exp(start_dt, end_dt, page = 1) {
    if (!start_dt || !end_dt) {
      return;
    }
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `me/exp`, {
      mb_id: Cookie.getCookie('mb_id'),
      start_dt,
      end_dt,
      page
    });

    return res;
  }

  /**
   * @description 마이페이지 포인트 리스트
   * @returns
   */
  async point(start_dt, end_dt, page = 1) {
    if (!start_dt || !end_dt) {
      return;
    }
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `me/point`, {
      mb_id: Cookie.getCookie('mb_id'),
      start_dt,
      end_dt,
      page
    });

    return res;
  }

  /**
   * @description 마이페이지 포인트 리스트
   * @returns
   */
  async loginlog(start_dt, end_dt, page = 1) {
    if (!start_dt || !end_dt) {
      return;
    }
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `me/login-history`, {
      mb_id: Cookie.getCookie('mb_id'),
      start_dt,
      end_dt,
      page
    });

    return res;
  }

  /**
   * @description 지정 IP 추가
   * @returns
   */
  async ipAdd(ip_name, ip) {
    if (!ip_name || !ip) {
      return;
    }

    const form = new FormData();
    form.append('ip_name', ip_name);
    form.append('ip', ip);

    const res = await this.commonAjax('post', Url.named, `me/login-ip`, form);

    return res;
  }

  /**
   * @description 지정 IP 삭제
   * @returns
   */
  async ipDelete(ip) {
    if (!ip) {
      return;
    }

    const res = await this.commonAjax('delete', Url.named, `me/login-ip`, {
      ip
    });

    return res;
  }

  /**
   * @description market 리스트
   * @returns
   */
  async marketList(category) {
    const res = await this.commonAjax('get', Url.named, `market-items`, {
      category
    });

    return res;
  }

  /**
   * @description market item 구매
   * @returns
   */
  async itemBuy(itemId, count, payType) {
    if (!itemId || !count || !payType) {
      return;
    }

    const form = new FormData();
    form.append('count', count);
    form.append('payType', payType);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `market-items/${itemId}`,
      form
    );

    return res;
  }

  /**
   * @description market item 선물
   * @returns
   */
  async sendGiftMarket(item_id, gift_nick, count, message, payType) {
    if (!item_id || !gift_nick || !count || !payType) {
      return;
    }

    const form = new FormData();
    form.append('gift_nick', gift_nick);
    form.append('count', count);
    form.append('message', message);
    form.append('payType', payType);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `gift-market-items/${item_id}`,
      form
    );

    return res;
  }

  /**
   * @description item 선물
   * @returns
   */
  async sendGift(item_id, gift_nick, item_count, message) {
    if (!item_id || !gift_nick || !item_count) {
      return;
    }

    const form = new FormData();
    form.append('gift_nick', gift_nick);
    form.append('item_count', item_count);
    form.append('message', message);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `gift-items/${item_id}`,
      form
    );

    return res;
  }

  /**
 * @description Gifticon category list
 * @returns
 */
  async gifticonCategoryList() {
    const res = await this.commonAjax('get', Url.named, `gift/categories`);

    return res;
  }

  /**
 * @description market 리스트
 * @returns
 */
  async gifticonCategotyDetailList(id) {
    const res = await this.commonAjax('get', Url.named, `gifts/category/${id}`);

    return res;
  }

  /**
* @description gifticon item can buy or not
* @returns
*/
  async isItemGifticonCanBuy(productId, count, payType) {
    if (!productId || !count || !payType) {
      return;
    }

    const form = new FormData();
    form.append('quantity', count);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `gifts/${productId}/can-buy`, form
    );

    return res;
  }

  /**
 * @description buy gifticon item
 * @returns
 */
  async itemGifticonBuy(productId, count, payType) {
    if (!productId || !count || !payType) {
      return;
    }

    const form = new FormData();
    form.append('quantity', count);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `/gifts/${productId}/buy`, form
    );

    return res;
  }

  /**
   * @description 내 아이템 리스트
   * @returns
   */
  async myItemList(category, allow_trade = '') {
    const res = await this.commonAjax('get', Url.named, `items`, {
      category,
      allow_trade
    });

    return res;
  }

  /**
   * @description 내 아이템 사용
   * @returns
   */
  async myItemUse(item_id, count = 1) {
    const form = new URLSearchParams();
    form.append('item_id', item_id);
    form.append('count', count);

    const res = await this.commonAjax('put', Url.named, `items`, form);

    return res;
  }

  /**
   * @description 구매 내역
   * @returns
   */
  async buyItemsList(start_dt, end_dt, page) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `buy-items`, {
      mb_id: Cookie.getCookie('mb_id'),
      start_dt,
      end_dt,
      page
    });

    return res;
  }

  /**
   * @description 선물한 내역
   * @returns
   */
  async sendItemsList(start_dt = '', end_dt = '', page = 1) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `send-items`, {
      mb_id: Cookie.getCookie('mb_id'),
      start_dt,
      end_dt,
      page
    });

    return res;
  }

  /**
   * @description 선물 받은 내역
   * @returns
   */
  async receiveItemsList(start_dt = '', end_dt = '', page = 1) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `receive-items`, {
      mb_id: Cookie.getCookie('mb_id'),
      start_dt,
      end_dt,
      page
    });

    return res;
  }

  /**
   * @description 충전 내역
   * @returns
   */
  async cashChargeList(start_dt, end_dt, page) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `me/payment`, {
      mb_id: Cookie.getCookie('mb_id'),
      start_dt,
      end_dt,
      page
    });

    return res;
  }

  /**
   * @description payment medal 내역
   * @returns
   */
  async paymentMedalList(start_dt, end_dt, page) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `me/total-payment-medal-list`, {
      mb_id: Cookie.getCookie('mb_id'),
      start_dt,
      end_dt,
      page
    });

    return res;
  }


  /**
   * @description payment medal 내역
   * @returns
   */
  async medalExchangeHistoryList(page) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `me/medal-exchange-history`, {
      page
    });

    return res;
  }

  /**
   * @description profit medal 내역
   * @returns
   */
  async profitMedalList(start_dt, end_dt, page) {
    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `me/profit-medal-list`, {
      mb_id: Cookie.getCookie('mb_id'),
      start_dt,
      end_dt,
      page
    });

    return res;
  }

  /**
   * @description 황금마차 리스트
   * @returns
   */
  async tradeList(type, status, item_id = '', me, page) {
    if (!type) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `trade-market-items`, {
      type,
      status,
      item_id,
      me,
      page
    });

    return res;
  }

  /**
   * @description 황금마차에 등록 가능한 아이템
   * @returns
   */
  async tradeMarketItems() {
    const res = await this.commonAjax(
      'get',
      Url.named,
      `trade-market-allow-items`
    );

    return res;
  }

  /**
   * @description 황금마차 등록
   * @returns
   */
  async tradeAdd(type, title, my_item_no = '', item_id = '', point) {
    if (!type || !title || !point) {
      return;
    }

    const form = new FormData();
    form.append('type', type);
    form.append('title', title);
    form.append('my_item_no', my_item_no);
    form.append('item_id', item_id);
    form.append('point', point);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `trade-market-items`,
      form
    );

    return res;
  }

  /**
   * @description 2차 비밀번호
   * @returns
   */
  async pw2(second_password) {
    if (!second_password) {
      return;
    }

    const form = new FormData();
    form.append('second_password', second_password);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `me/second-password`,
      form
    );

    return res;
  }

  /**
   * @description 황금마차 구매
   * @returns
   */
  async tradeMarketBuy(no) {
    if (!no) {
      return;
    }

    const res = await this.commonAjax(
      'put',
      Url.named,
      `trade-market-items/${no}/buy`
    );

    return res;
  }

  /**
   * @description 황금마차 판매
   * @returns
   */
  async tradeMarketSell(no, my_item_no) {
    if (!no || !my_item_no) {
      return;
    }

    const form = new URLSearchParams();
    form.append('my_item_no', my_item_no);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `trade-market-items/${no}/sell`,
      form
    );

    return res;
  }

  /**
   * @description 황금마차 거래 취소
   * @returns
   */
  async tradeMarketCancel(no) {
    if (!no) {
      return;
    }

    const res = await this.commonAjax(
      'put',
      Url.named,
      `trade-market-items/${no}/cancel`
    );

    return res;
  }

  /**
   * @description 마이페이지 tp 리스트
   * @returns
   */
  async tradePointList(page = 1) {
    const res = await this.commonAjax('get', Url.named, `game/tp-history`, {
      page
    });

    return res;
  }

  /**
   * @description 마이페이지 tp 리필
   * @returns
   */
  async tradePointRefill(page = 1) {
    const res = await this.commonAjax(
      'post',
      Url.named,
      `game/trade-point-refill`
    );

    return res;
  }

  /**
   * @description 마이페이지 계급정보 리스트
   * @returns
   */
  async findLevel() {
    const res = await this.commonAjax('get', Url.named, `level`);

    return res;
  }

  async actionRequestCashBill(params) {
    const form = new FormData();
    form.append('mb_id', params.mb_id);
    form.append('pay_id', params.pay_id);
    form.append('usage', params.type);
    form.append('id_number', params.id_number);

    const res = await this.commonAjax('post', Url.named, `me/cash-bill`, form);

    return res;
  }
}

export default new MypageService();