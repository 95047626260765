const _SPORTS_GAME_TYPE = {
  BASKETBALL: 'basketballGame',
  SOCCER: 'soccerGame',
  BASEBALL: 'baseballGame',
  VOLLEYBALL: 'volleyballGame'
};

const _SPORTS_STATUS = {
  IN_PROGRESS: 1,
  BREAK_TIME: 1,
  PAUSE: 1,
  READY: 2,
  FINAL: 3,
  CANCEL: 3,
  DELAY: 3,
  POSTPONED: 3,
  CUT: 3,
  PENDING: 3,
  SUSPENDED: 3
};

const _SOCCER_TIME_LINE = {
  GAME_START: '1st',
  HALFTIME: 'HT',
  FULLTIME: 'FT',
  SECOND_START: '2nd'
};

const _SPORTS_WS_TOPIC = {
  major: [
    {
      topic: 'sports.soccer.games',
      funcName: 'updateGame',
      key: 'game'
    },
    {
      topic: 'sports.soccer.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.baseball.games',
      funcName: 'updateGame',
      key: 'game'
    },
    {
      topic: 'sports.baseball.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.basketball.games',
      funcName: 'updateGame',
      key: 'game'
    },
    {
      topic: 'sports.basketball.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.volleyball.games',
      funcName: 'updateGame',
      key: 'game'
    },
    {
      topic: 'sports.volleyball.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.hockey.games',
      funcName: 'updateGame',
      key: 'game'
    },
    {
      topic: 'sports.hockey.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.football.games',
      funcName: 'updateGame',
      key: 'game'
    },
    {
      topic: 'sports.football.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    }
  ],
  soccer: [
    { topic: 'sports.soccer.games', funcName: 'updateGame', key: 'game' },
    {
      topic: 'sports.soccer.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.soccer.broadcasts',
      funcName: 'updateBroadcasts',
      key: 'broadcasts'
    },
    {
      topic: 'sports.soccer.cheers'
    }
  ],
  baseball: [
    { topic: 'sports.baseball.games', funcName: 'updateGame', key: 'game' },
    {
      topic: 'sports.baseball.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.baseball.last-broadcast',
      funcName: 'updateLastBroadcast',
      key: 'broadcast'
    },
    {
      topic: 'sports.baseball.bat-status',
      funcName: 'updateBatStatus',
      key: 'batStatus'
    },
    {
      topic: 'sports.baseball.field-status',
      funcName: 'updateFieldStatus',
      key: 'fieldStatus'
    },
    { topic: 'sports.baseball.heb', funcName: 'updateHeb', key: 'heb' },
    {
      topic: 'sports.baseball.special',
      funcName: 'updateSpecial',
      key: 'special'
    },
    {
      topic: 'sports.baseball.cheers'
    }
  ],
  basketball: [
    { topic: 'sports.basketball.games', funcName: 'updateGame', key: 'game' },
    {
      topic: 'sports.basketball.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.basketball.last-broadcast',
      funcName: 'updateLastBroadcast',
      key: 'broadcast'
    },
    {
      topic: 'sports.basketball.special',
      funcName: 'updateSpecial',
      key: 'special'
    },
    {
      topic: 'sports.basketball.period-special',
      funcName: 'updatePeriodSpecial',
      key: 'periodSpecial'
    },
    {
      topic: 'sports.basketball.cheers'
    }
  ],
  volleyball: [
    { topic: 'sports.volleyball.games', funcName: 'updateGame', key: 'game' },
    {
      topic: 'sports.volleyball.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.volleyball.last-broadcast',
      funcName: 'updateLastBroadcast',
      key: 'broadcast'
    },
    {
      topic: 'sports.volleyball.special',
      funcName: 'updateSpecial',
      key: 'special'
    },
    {
      topic: 'sports.volleyball.period-special',
      funcName: 'updatePeriodSpecial',
      key: 'periodSpecial'
    },
    {
      topic: 'sports.volleyball.cheers'
    }
  ],
  hockey: [
    { topic: 'sports.hockey.games', funcName: 'updateGame', key: 'game' },
    {
      topic: 'sports.hockey.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.hockey.last-broadcast',
      funcName: 'updateLastBroadcast',
      key: 'broadcast'
    },
    {
      topic: 'sports.hockey.special',
      funcName: 'updateSpecial',
      key: 'special'
    },
    {
      topic: 'sports.hockey.cheers'
    }
  ],
  football: [
    { topic: 'sports.football.games', funcName: 'updateGame', key: 'game' },
    {
      topic: 'sports.football.period-data',
      funcName: 'updatePeriodData',
      key: 'periodData'
    },
    {
      topic: 'sports.football.last-broadcast',
      funcName: 'updateLastBroadcast',
      key: 'broadcast'
    },
    {
      topic: 'sports.football.special',
      funcName: 'updateSpecial',
      key: 'special'
    },
    {
      topic: 'sports.football.cheers'
    }
  ],
  lol: [
    { topic: 'esports.lol.games', funcName: 'updateGame', key: 'game' },
    { topic: 'esports.lol.period', funcName: 'updatePeriod', key: 'game' },
    {
      topic: 'sports.lol.cheers'
    }
  ],
  starcraft: [
    { topic: 'esports.star.games', funcName: 'updateGame', key: 'game' },
    { topic: 'esports.star.period', funcName: 'updatePeriod', key: 'game' },
    {
      topic: 'sports.star.cheers'
    }
  ]
};

const _E_SPORTS_GAME_STATUS = {
  1: 'READY',
  2: 'IN_PROGRESS',
  3: 'FINAL',
  4: 'CANCEL'
};

const _BASEBALL_PERIODS = [
  { key: 1, value: '1회' },
  { key: 2, value: '2회' },
  { key: 3, value: '3회' },
  { key: 4, value: '4회' },
  { key: 5, value: '5회' },
  { key: 6, value: '6회' },
  { key: 7, value: '7회' },
  { key: 8, value: '8회' },
  { key: 9, value: '9회' },
  { key: 88, value: '연장' },
  { key: 99, value: '전체' }
];

const _INIT_ODDS = {
  handicapOdds: {
    win: { value: '-', isChange: null, id: null },
    draw: { value: '-', isChange: null, id: null },
    loss: { value: '-', isChange: null, id: null },
    option: null
  },
  underOverOdds: {
    win: { value: '-', isChange: null, id: null },
    draw: { value: '-', isChange: null, id: null },
    loss: { value: '-', isChange: null, id: null },
    option: null
  },
  winLoseOdds: {
    win: { value: '-', isChange: null, id: null },
    draw: { value: '-', isChange: null, id: null },
    loss: { value: '-', isChange: null, id: null }
  }
};

const _SCORE_SETTINGS = {
  major: { audio: false, prompt: false },
  soccer: { audio: false, prompt: false },
  baseball: { audio: false, prompt: false },
  basketball: { audio: false, prompt: false },
  volleyball: { audio: false, prompt: false },
  hockey: { audio: false, prompt: false },
  football: { audio: false, prompt: false }
};

const _SPORTS_TYPE = {
  MAJOR: "MAJOR",
  BASKETBALL: "BASKETBALL",
  SOCCER: "SOCCER",
  BASEBALL: "BASEBALL",
  VOLLEYBALL: "VOLLEYBALL",
  HOCKEY: "ICEHOCKEY",
  LOL: "LOL",
  STARCRAFT: "STARCRAFT",
  FOOTBALL: "FOOTBALL",
};

export {
  _SPORTS_GAME_TYPE,
  _SPORTS_STATUS,
  _SOCCER_TIME_LINE,
  _SPORTS_WS_TOPIC,
  _E_SPORTS_GAME_STATUS,
  _BASEBALL_PERIODS,
  _INIT_ODDS,
  _SCORE_SETTINGS,
  _SPORTS_TYPE,
};
