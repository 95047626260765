import styled from 'styled-components';

export const Cash = styled.div`
  padding: 0 20px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Asset_box = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
    width: 100%;`

export const Amass = styled.dl`
  dt {
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    color: #666;
  }
  dd {
    height: 32px;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
    :before{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      color: #fff;
    }
  }
    dd.cash{
        color: #e82d0c;
    }
    dd.point{
        color: #1287EB;
    }
    dd.medal{
        color: #FBA019;
    }
    dd.medal::before {
        content:"";
        background: url("/images/market/icon_medal.png") no-repeat;
        background-size: contain;
        border-radius: 0%;
    }
    dd.cash::before {
        content: 'C';
        background-color: #e82d0c;
    }
    dd.point::before {
        content: 'P';
        background-color: #1287EB;
    }
`;

export const ChargeButton = styled.button`
  width: 55px;
  height: 54px;
  border-radius: 4px;
  border: solid 1px #333333;
  background-color: #fff;
  box-sizing: border-box;
  font-size : 11px;
  opacity: 0.6;
  font-weight: bold;
  :before {
    content: '';
    width: 16px;
    height: 16px;
    display: block;
    background: url('/images/ic-charge.svg') no-repeat center center;
    background-size: 16px;
    margin: 0 auto 5px;
  }
`;

export const Empty = styled.li`
  border-bottom: none;
  > div {
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
    font-size: 14px;
    font-weight: 500;
    color: #666;
  }
  }
`;
