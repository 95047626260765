
export const is_pick_ranking= ()=>{
    let isRanking = false;
    const path = window.location.pathname.split("/");

    isRanking = path[2] === 'rball';

    // remove it once pick ranking developed for space8
//    if(path[3] ==='space8') isRanking = false;

    return isRanking;
}