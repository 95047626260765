import axios from 'axios';
import { Url } from '../constants/environment';
import Cookie from '../utils/cookie';
import { StringUtil } from '../utils/stringUtil';
import cookie from '../utils/cookie';

export const UNAUTHORIZED_MESSAGES = ['로그인 후 이용 할 수 있습니다.', '로그인 후 작성 할 수 있습니다.', '로그인 후 이용해 주세요.'];

class Api {
  ajax(method, apiUrl, url, data, isSession = false) {
    let headers = {};

    if (isSession) {
      headers = {
        // 'named-session': Cookie.getCookie('session_id')
        'named-session': Cookie.getCookie('session_id'),
        'Cookie': ''
      };
    }

    const axiosResult = !data
      ? axios[method](`${apiUrl}${url}`, { headers })
      : axios[method](`${apiUrl}${url}`, data, { headers });

    return axiosResult;
  }

  commonAjax(method, apiUrl, url, data = {}, headers = {}, isAuth = true) {
    method = method.toLowerCase();

    if (isAuth) {
      let sessionId = Cookie.getCookie('session_id');

      if (sessionId) {
        headers = Object.assign(headers, {
          'NAMED-SESSION': sessionId
        });
      }
    }

    if (method === 'get' || method === 'delete') {
      let queryString = StringUtil.querystringConvert(data);

      if (!!queryString) {
        url += '?';
        url += StringUtil.querystringConvert(data);
      }
    }

    const axiosResult =
      method === 'get' || method === 'delete'
        ? axios[method](`${apiUrl}${url}`, { headers })
        : axios[method](`${apiUrl}${url}`, data, { headers });

    const result = axiosResult
      .then((res) => {
        return res.status === 200
          ? {
            code: res.status,
            data: res.data
          }
          : {};
      })
      .catch((err) => {
        let data = {};

        if (!err || !err.response) {
          return {};
        }

        switch (err.response.status) {
          case 400:
            data = err.response.data;
            if (data?.message && typeof data.message === 'string' && UNAUTHORIZED_MESSAGES.includes(data.message)) {
              data = { code: 401, message: data.message };
            }
            break;
          case 401:
            Cookie.deleteCookie('session_id');
            Cookie.deleteCookie('mb_id');
            data = { code: 401, message: '로그인 후 이용해 주세요.' };
            break;
          default:
            data = { code: 500, message: '서버 오류입니다.' };
            break;
        }
        return data;
      });

    return result;
  }

  sportsAjax(method, apiUrl, url, data) {
    const header = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Methods':
        'HEAD, GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'oki-api-key': Url.legacyApiKey,
      'oki-api-name': 'named_score',
      'Cache-Control': 'no-cache'
    };

    const axiosResult = !data
      ? axios[method](`${apiUrl}${url}`, {
        headers: header
      })
      : axios[method](`${apiUrl}${url}`, data, {
        headers: header
      });

    return axiosResult;
  }

  jwtAjax(method, apiUrl, url, data) {
    const axiosResult = !data
      ? axios[method](`${apiUrl}${url}`, {
        headers: {
          Authorization: `Bearer ${cookie.getCookie('access_token')}`,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      })
      : axios[method](`${apiUrl}${url}`, data, {
        headers: {
          Authorization: `Bearer ${cookie.getCookie('access_token')}`,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      });

    return axiosResult;
  }
}

export default Api;
