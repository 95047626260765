import React from 'react';

const BoardWriteHtml = () => {
  return (
    <>
      <div className="board_wrap">

        <div class="board_write">
          <div class="nav">
            <h2 class="tit">글쓰기</h2>
            <a class="btn_prev" href="javascript:;">이전으로</a>
          </div>
          <div class="write_area">
            <div class="category">
              <select name="pets" id="pet-select">
                <option value="">게시판을 선택해주세요.</option>
                <option value="free">자유</option>
                <option value="humor">유머</option>
                <option value="photo">포토</option>
                <option value="analysis">분석</option>
                <option value="minigame">미니게임</option>
                <option value="crime">피해사례공유</option>
              </select>
            </div>
            <div class="subject">
              <input type="text" value="" placeholder="제목을 입력해주세요." />
            </div>
            <div class="editor_area">
              에디터 영역 - 에디터는 작동만 되게 해당 영역에 넣어주시면 상세 스타일은 제가 잡을께요
            </div>
            <div class="btn_area">
              <button class="btn_cancel" type="button">취소</button>
              <button class="btn_submit" type="submit">등록</button>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default BoardWriteHtml;
