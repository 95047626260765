import autobind from 'autobind-decorator';
import { computed, extendObservable } from 'mobx';
import { RegexUtil } from '../../../../../common/utils/regexUtil';

@autobind
class ProtoMyPickModel {
  constructor(data) {
    extendObservable(this, data);
  }

  @computed
  get name() {
    const { displayName, no } = this;

    return `승부식 ${displayName}`;
  }

  @computed
  get status() {
    const { gameStartFlag, totalPickStatus } = this;

    if (!gameStartFlag) {
      return { text: '대기중', class: 'wait' };
    } else {
      if (totalPickStatus === 'HIT') {
        return { text: '적중', class: 'hit' };
      } else {
        return { text: '미적중', class: 'no-hit' };
      }
    }
  }

  @computed
  get estimateAmount() {
    const { betPoint, totalOdds } = this;
    const amount = betPoint * totalOdds;

    return `${RegexUtil.makeComma(Math.round(amount))}원`;
  }
}

export default ProtoMyPickModel;
