import autobind from 'autobind-decorator';
import { observable, action, computed, when, runInAction } from 'mobx';
import PromptModel from './model';

@autobind
class PromptStore {
  constructor() {
    when(
      () => this.checkRemoveItem,
      () =>
        setTimeout(() => {
          this.removePrompts();
        }, 2000)
    );
  }

  @observable
  promptsList = [];

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get checkMaxPromptList() {
    return this.promptsList.length > 3;
  }

  @computed
  get prompts() {
    const { promptsList } = this;

    return promptsList.slice().filter((item) => item.isVisible);
  }

  @computed
  get checkRemoveItem() {
    const { promptsList } = this;

    return !promptsList.slice().every((item) => !item.isVisible);
  }

  /**********************************************************
   * action
   **********************************************************/
  @action
  removePrompts() {
    this.promptsList = this.promptsList.filter((item) => item.isVisible);
  }

  @action
  clear() {
    this.promptsList = [];
  }

  @action
  addNotification(prompt) {
    const { promptsList } = this;

    let deep = [...promptsList];

    this.promptsList = [new PromptModel(prompt), ...deep];
  }
}

export default new PromptStore();
