import React from 'react';

const MypageInfoHtml = () => {
  return (
    <>
      {/* mypage_wrap */}
      <div className="mypage_wrap">
        <div className="reward_area">
          <dl>
            <dt className="tit">내정보 입력 달성률은 <strong className="point">0점</strong> 입니다.</dt>
            <dd className="info">달성시마다 푸짐한 아이템을 드립니다.<a className="_lnk">보상안내</a></dd>
            <dd className="gauge">
              <span className="per" style={{width:'50%'}}>50P</span>
            </dd>
          </dl>
        </div>
        {/* info_area */}
        <div className="info_area">

          {/* column */}
          <div className="column">
            <div className="hd">계정 및 인증정보</div>
            <div className="bd">
              <dl className="item">
                <dt>이메일</dt>
                <dd className="tx">
                  <p className="tx">named@bss.co.kr</p>
                  <button type="button">인증하기</button>
                </dd>
              </dl>
              <dl className="item">
                <dt>비밀번호</dt>
                <dd>
                  <p className="tx">2015-01-19 15:59:23 변경됨</p>
                  <button type="button">변경하기</button>
                </dd>
              </dl>
              <dl className="item">
                <dt>휴대폰</dt>
                <dd>
                  <p className="tx">인증완료 : 정기현/남자/1985-03-05</p>
                  <button type="button">인증하기</button>
                </dd>
              </dl>
              <dl className="item">
                <dt>아이핀</dt>
                <dd>
                  <p className="tx">인증 내역이 없습니다.</p>
                  <button type="button">인증하기</button>
                </dd>
              </dl>
            </div>
          </div>
          {/* // column */}

          {/* column */}
          <div className="column">
            <div className="hd">커뮤니티 정보</div>
            <div className="bd">
              <dl className="item">
                <dt>닉네임</dt>
                <dd>
                  <p className="tx">럭셔리삐돌이</p>
                  <button type="button">변경하기</button>
                </dd>
              </dl>
              <dl className="item">
                <dt>오늘의 한마디</dt>
                <dd>
                  <p className="tx">미설정미설 미설정미설미설 정미설미설정미설</p>
                  <button type="button">변경하기</button>
                </dd>
              </dl>
              <dl className="item">
                <dt>프로필 이미지</dt>
                <dd>
                  <p className="profile_img">
                    <span className="thumb lv17">
                      <img src="/images/thumb_user_def.png"/>
                      <svg
                          className="border"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 36 36"
                          enable-background="new 0 0 36 36"
                          xmlSpace="preserve"
                      >
                      <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                      </svg>
                    </span>
                  </p>
                  <button type="button">재인증</button>
                </dd>
                <dd className="btn_area"></dd>
              </dl>
              <dl className="item">
                <dt>프로필 배경 이미지</dt>
                <dd>
                  <p className="profile_bg_img">
                    <img src="/images/thumb_bg_def.png"/>
                  </p>
                  <button type="button">인증하기</button>
                </dd>
              </dl>
            </div>
          </div>
          {/* // column */}

          {/* column */}
          {/*<div className="column">*/}
          {/*  <div className="hd">보안 정보</div>*/}
          {/*  <div className="bd">*/}
          {/*    <dl className="item">*/}
          {/*      <dt>2차 비밀번호</dt>*/}
          {/*      <dd>*/}
          {/*        <p className="tx">2020.00.00 00:00:00 변경됨</p>*/}
          {/*        <button type="button">설정하기</button>*/}
          {/*      </dd>*/}
          {/*    </dl>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/* // column */}

        </div>
        {/* // info_area */}
      </div>
      {/* // mypage_wrap */}
    </>
  );
};

export default MypageInfoHtml;
