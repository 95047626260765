import styled from 'styled-components';

export const CategoryList = styled.ul`
  height: 40px;
  background-color: #fff;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  white-space: nowrap;
  border-bottom: 1px solid #e4e4e4;
  li {
    display: flex;
    align-items: center;
    a {
      padding: 0 15px;
      height: 100%;
      display: flex;
      align-items: center;
      &.selected {
        color: #3498db;
      }
    }
    :not(:last-child) {
      :after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 11px;
        background-color: #f2f2f2;
      }
    }
  }
`;
