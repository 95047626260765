import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ScoreProtoContainer from '../proto/container';
import ProtoCalcWrapper from './wrapper';
import GoBack from '../../../components/goBack';
import ProtoCalculatorSlip from './slip';
import useSportsStore from '../../../hooks/useSportsStore';
import Seo from '../../../components/seo';

const ProtoCalculator = observer(() => {
  const store = useSportsStore('protoCalculator');

  useEffect(() => {
    store.getProtoCalc();
  }, []);

  const changeRound = (roundId) => {
    store.getProtoCalc(roundId);
  };

  return (
    <>
      <Seo title={'스포츠 프로토 배당률 계산기'} />
      <div className="proto_wrap">
        <div className="nav">
          <h2 className="tit">배당률 계산기</h2>
          <GoBack />
        </div>
        <ScoreProtoContainer
          currentRound={store.proto.currentRound}
          nextRound={store.proto.nextRound}
          previousRound={store.proto.previousRound}
          isCalculator={true}
          changeRound={changeRound}
        />
        <ProtoCalcWrapper
          list={store.protoList}
          loading={store.loading}
          battingPicks={store.battingPicks}
          tempBattingClear={store.tempBattingClear}
          slipOpen={store.slipOpen}
        />
        <ProtoCalculatorSlip
          amount={store.amount}
          changeAmount={store.changeAmount}
          count={store.battingCount}
          totalOdds={store.totalOdds}
          totalAmount={store.totalAmount}
          clear={store.clearBattingTemp}
          submit={store.submitCalculator}
          isOpen={store.slipOpen}
          toggleOpen={store.toggleOpen}
          battingNumber={store.battingNumber}
        />
      </div>
    </>
  );
});

export default ProtoCalculator;
