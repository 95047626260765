import React from 'react';
import { observer } from 'mobx-react';
import {RegexUtil} from "../../../../../common/utils/regexUtil";

const MypageProfitList = observer(({ items = [] }) => {
  return (
      <div className="list">
      {!!items &&
      items.length > 0 &&
      items.map((item, i) => {
        let msg = '';
        if(item.component === '@gift'){
          msg = item.content.replace(/\(.*?\)/g, '');
        }
        else msg = item.content;

        return (
          <div className="item" key={i}>
             <span className="info">
               <em className="tx">{msg}
                 {/*<span className="count">({item.amount}개)</span>*/}
                 {/*<span className="type">{item.type === 'GIFT' ? '선물' : '구매'}</span>*/}
               </em>
               <em className="date">{item.create_datetime}</em>
             </span>
            <span className='num'>{RegexUtil.makeComma(item.amount)}</span>
          </div>
        )}
      )}
    </div>
  );
});

export { MypageProfitList };
