export const objectPayTypeText = {
	is_point: "포인트",
	is_gold: "캐시",
	is_medal: "건빵",
}

export const getColorPayType = (payTypeSlected) => {
	switch (payTypeSlected) {
		case "is_point":
			return "POINT"
		case "is_gold":
			return "GOLD"
		case "is_medal":
			return "MEDAL"
		default:
			return "grey"
	}
}

