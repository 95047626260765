import React from 'react';

const ProtoHistoryHtml = () => {
  return (
    <>
      {/* proto_wrap */}
      <div className="proto_wrap">

        <div className="nav">
          <h2 className="tit">배당률 계산기 저장내역</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="snb_wrap">
          <ul>
            <li><a className="item selected" href="javascript:;">전체</a></li>
            <li><a className="item" href="javascript:;">대기중</a></li>
            <li><a className="item" href="javascript:;">적중</a></li>
            <li><a className="item" href="javascript:;">미적중</a></li>
          </ul>
        </div>

        <div className="proto_history">
          <div className="item">
            <a href="javascript:;">
              <span className="tit">승부식 72-2</span>
              <span className="info">
                    <em className="state win">적중</em> {/* class : win, lose */}
                    <em className="amount">예상 당첨금 : 30,000,000원</em>
                </span>
            </a>
            <button type="button" className="btn_proto_paper">용지보기</button>
          </div>
          <div className="item">
            <span className="tit">승부식 72-2</span>
            <span className="info">
                <em className="state lose">미적중</em>
                <em className="amount">예상 당첨금 : 30,000,000원</em>
            </span>
            <button type="button" className="btn_proto_paper">용지보기</button>
          </div>
          <div className="item">
            <span className="tit">승부식 72-2</span>
            <span className="info">
                <em className="state">대기중</em>
                <em className="amount">예상 당첨금 : 30,000,000원</em>
            </span>
            <button type="button" className="btn_proto_paper">용지보기</button>
          </div>
        </div>

        <div className="pagination">
          <a className="btn btn_prev" href="javascript:;">다음 페이지</a>
          <div className="number">
            <span className="num on"><em>1</em></span>
            <a className="num" href="javascript:;"><em>2</em></a>
            <a className="num" href="javascript:;"><em>3</em></a>
            <a className="num" href="javascript:;"><em>4</em></a>
            <a className="num" href="javascript:;"><em>5</em></a>
          </div>
          <a className="btn btn_next" href="javascript:;">다음 페이지</a>
        </div>

      </div>
      {/* // proto_wrap */}

    </>
  );
};

export default ProtoHistoryHtml;
