import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import useTypeStore from '../../../../hooks/useTypeStore';
import { Editor } from '../../../community/components';
import Modal from 'react-modal';
import Seo from '../../../../components/seo';

const CustomerCenterQnaWrite = observer(({ match, history }) => {
  const communityStore = useTypeStore('communityStore');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [isOpen, toggleOpenModal] = useState(true);

  const onSubmit = async (e) => {
    e.preventDefault();
    let data = {
      boardType: 'qna',
      subject,
      content
    };

    if (!subject) {
      alert('제목을 입력하세요.');
      return;
    }

    if (!content) {
      alert('내용을 입력하세요.');
      return;
    }

    await communityStore.actionSave(data);

    if (communityStore.code !== 200) {
      alert(communityStore.message);
    } else {
      history.replace(`/customercenter/qna/list`);
    }
  };

  const cancel = () => {
    if (!window.confirm('글쓰기를 취소 하시겠습니까?')) {
      return;
    }

    history.goBack();
  };

  return (
    <>
      <Seo title={'고객센터 문의하기'} />
      <div className="board_wrap">
        <div className="board_write">
          <div className="write_area">
            <div className="subject">
              <input
                type="text"
                placeholder="제목을 입력해주세요."
                value={subject}
                onChange={(node) => setSubject(node.target.value)}
              />
            </div>
            <div className="editor_area">
              <Editor
                content={content}
                setContent={setContent}
                mode={''}
                placeholder={`오류 문의 시 아래 사항도 함께 알려주시면 더욱 상세하게 
답변드릴 수 있습니다.
이용환경(안드로이드,아이폰), 통신사, 브라우저 및 버전, 
인터넷 환경 등`}
              />
            </div>
            <div className="btn_area">
              <button className="btn_cancel" type="button" onClick={cancel}>
                취소
              </button>
              <button className="btn_submit" type="submit" onClick={onSubmit}>
                문의하기
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => toggleOpenModal(false)}
        style={{
          content: {
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}
        overlayClassName="modal_overlay"
      >
        <div className="alert">
          <div style={{ marginBottom: 8 }}>
            <h2>문의하기 전에 꼭 읽어주세요.</h2>
          </div>
          <div>
            <ul className="list">
              <li>
                고객센터 운영 시간은 매일 <em>10:00 ~ 19:00</em> 입니다.
              </li>
              <li>
                사이트 이용방법은 공지사항, 자주묻는질문에 검색을 먼저 해보시기
                바랍니다.
              </li>
              <li>
                사이트 이용방법은 공지사항, 자주묻는질문에 검색을 먼저 해보시기
                바랍니다.
              </li>
              <li>
                게시글, 댓글, 쪽지 신고는 신고센터를 이용해주시기 바랍니다.
              </li>
              <li>
                오류문의시 아래 사항도 함께 알려주시면 더욱 상세하게 답변드릴 수
                있습니다.
              </li>
              <li>
                이용환경(안드로이드,아이폰,PC), 통신사, 브라우져 및 버전, 인터넷
                환경 등
              </li>
              <li>
                문의시 사이트 비방 또는 운영자에 대한 반말/욕설을 하는 경우
                문의내용과 관계없이 경고/전과 페널티가 부여될수 있습니다.
              </li>
              <li>
                문의시 기본 매너를 지켜주시길 바랍니다 (운영자에 지속적인 비매너
                행위시 아이피가 차단될수 있습니다.)
              </li>
            </ul>
          </div>
          <div>
            <button onClick={() => toggleOpenModal(false)}>확인</button>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default CustomerCenterQnaWrite;
