import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router';
import PrerenderedLoadable from '../../../components/prerenderLoadable';
// import AppLink from '../../../components/appLink';

const SoccerMatchComponent = PrerenderedLoadable(() =>
  import('../soccer/match')
);
const BaseballMatchComponent = PrerenderedLoadable(() =>
  import('../baseball/match')
);
const BasketballMatchComponent = PrerenderedLoadable(() =>
  import('../basketball/match')
);
const VolleyballMatchComponent = PrerenderedLoadable(() =>
  import('../volleyball/match')
);
const HockeyMatchComponent = PrerenderedLoadable(() =>
  import('../hockey/match')
);
const FootballMatchComponent = PrerenderedLoadable(() =>
  import('../football/match')
);
const LolMatchComponent = PrerenderedLoadable(() => import('../lol/match'));
const StarcraftMatchComponent = PrerenderedLoadable(() =>
  import('../starcraft/match')
);

const ScoreMatch = () => {
  // const [viewAppLink, setViewAppLink] = useState(false);

  useEffect(() => {
    // if (!window.sessionStorage.getItem('matchHideAppLink')) {
    //   setViewAppLink(true);
    // }

    return () => {
      if (!!window.sessionStorage.getItem('lastMessage')) {
        window.sessionStorage.removeItem('lastMessage');
      }
    };
  }, []);

  return (
    <>
      {/*{viewAppLink && (*/}
      {/*  <AppLink*/}
      {/*    onClickClose={() => {*/}
      {/*      setViewAppLink(false);*/}
      {/*      window.sessionStorage.setItem('matchHideAppLink', true);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      <Switch>
        <Route
          path="/match/soccer/:id/:type"
          component={SoccerMatchComponent}
        />
        <Route
          path="/match/baseball/:id/:type"
          component={BaseballMatchComponent}
        />
        <Route
          path="/match/basketball/:id/:type"
          component={BasketballMatchComponent}
        />
        <Route
          path="/match/volleyball/:id/:type"
          component={VolleyballMatchComponent}
        />
        <Route
          path="/match/hockey/:id/:type"
          component={HockeyMatchComponent}
        />
        <Route
          path="/match/football/:id/:type"
          component={FootballMatchComponent}
        />
        <Route path="/match/lol/:id/:type" component={LolMatchComponent} />
        <Route
          path="/match/starcraft/:id/:type"
          component={StarcraftMatchComponent}
        />
      </Switch>
    </>
  );
};

export default ScoreMatch;
