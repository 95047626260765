import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../../../context';
import {RegexUtil} from "../../../../../common/utils/regexUtil";

const MypageCashUseList = observer(({ items = [] }) => {
  return (
      <div className="list">
      {!!items &&
      items.length > 0 &&
      items.map((item, i) => (
          <div className="item" key={i}>
             <span className="info">
               <em className="tx">{item.name}
                 <span className="count">({item.count}개)</span>
                 <span className="type">{item.type === 'GIFT' ? '선물' : '구매'}</span>
               </em>
               <em className="date">{item.reg_dt}</em>
             </span>
            <span className='num'>{RegexUtil.makeComma(item.cash)}</span>
          </div>
        ))}
    </div>
  );
});

export { MypageCashUseList };
