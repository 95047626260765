import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import useCommonStore from '../../../hooks/useCommonStore';

const CertPhone = observer(({ resultUrl }) => {
  const commonStore = useCommonStore();

  const cert = async () => {
    commonStore.getCertPhoneEncData({ resultUrl }).then((res) => {
      switch (res.code) {
        case 200:
          commonStore.getCertPhonePage({ encData: res.data.enc_data });
          break;

        case 400:
          commonStore.modalStore.openAlertModal({
            message: res.message
          });
          break;

        default:
          commonStore.modalStore.openAlertModal({
            message: '인증 처리중 오류가 발생하였습니다.'
          });
      }
    });
  };

  useEffect(() => {

  }, []);

  return (
    <button className="btn btn_phone" onClick={cert}>
      휴대폰 인증
    </button>
  );
});

export default CertPhone;
