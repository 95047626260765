import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import useCheckLogin from '../../../../hooks/useCheckLogin';
import useModalStore from '../../../../hooks/useModalStore';

const ProtoCalculatorSlip = ({
  amount,
  changeAmount,
  count,
  totalOdds,
  totalAmount,
  clear,
  submit,
  isOpen,
  toggleOpen,
  battingNumber
}) => {
  const { isCheckLogin } = useCheckLogin();
  const modal = useModalStore();
  const history = useHistory();
  const [isMinSlip, setMiniSlip] = useState(false);

  const onClickToggle = () => {
    if (isOpen) {
      if (count > 0) {
        setMiniSlip(true);
      } else {
        setMiniSlip(false);
      }
    }
    toggleOpen();
  };

  const onClickSubmit = () => {
    if (isCheckLogin) {
      if (amount < 1000) {
        modal.openAlertModal({
          message: '베팅금액은 최소 1000원 부터 가능합니다.'
        });

        return;
      }

      if (count === 0) {
        modal.openAlertModal({
          message: '최소 3게임을 선택해야 베팅이 가능합니다.'
        });

        return;
      }

      submit();
    } else {
      modal.openConfirmModal({
        message: '로그인 후 사용이 가능 합니다. 로그인 하시겠습니까?',
        okCallback: () => history.push('/auth/login')
      });
    }
  };

  const onClickMyPick = () => {
    if (isCheckLogin) {
      history.push('/score/proto/calculator/my-pick');
    } else {
      modal.openConfirmModal({
        message: '로그인 후 사용이 가능 합니다. 로그인 하시겠습니까?',
        okCallback: () => history.push('/auth/login')
      });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      if (count > 0) {
        if (!isMinSlip) {
          toggleOpen();
        }
      }
    }
  }, [isOpen, count, isMinSlip]);

  return (
    <div className={clsx('proto_odds_slip', isOpen && 'view')}>
      <div className="summary">
        <span className="tit">{battingNumber}</span>
        <div className="option">
          <button type="button" className="btn_history" onClick={onClickMyPick}>
            저장내역
          </button>
          <button type="button" className="btn_oc" onClick={onClickToggle}>
            열기/닫기
          </button>
        </div>
      </div>
      <div className="list">
        <div className="info">
          <div className="column">
            <dl>
              <dt>선택경기</dt>
              <dd>{count}경기</dd>
            </dl>
            <dl>
              <dt>베팅금액</dt>
              <dd>
                <input type="number" value={amount} onChange={changeAmount} />
                <span className="won">원</span>
              </dd>
            </dl>
          </div>
          <div className="column">
            <dl>
              <dt>예상배당률</dt>
              <dd>{totalOdds}배</dd>
            </dl>
            <dl>
              <dt>예상당첨금</dt>
              <dd className="amount">{totalAmount}</dd>
            </dl>
          </div>
        </div>
        <div className="btn_area">
          <button type="button" className="btn_reset" onClick={clear}>
            초기화
          </button>
          <button type="submit" className="btn_save" onClick={onClickSubmit}>
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProtoCalculatorSlip;
