import React, { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import useUsersStore from '../../../hooks/useUsersStore';

const ProfileImageChange = observer(({ history, onClose, category = '' }) => {
  const usersStore = useUsersStore();
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const { mypageStore } = useMypageStore(MypageContext);
  const [profileImage, setProfileImage] = useState(null);

  const onClickProfileImageChange = ({ cropAllStatus }) => {
    if (typeof mypageStore.data.file_path !== 'string') {
      usersStore.modalStore.openAlertModal({
        message: `변경할 프로필 이미지를 선택해주세요.`
      });
      return;
    }

    if (usersStore.data.certify.is_phone_certify) {
      updateProfileImage({ cropAllStatus });
    } else {
      usersStore.modalStore.openConfirmModal({
        message: `프로필 이미지 변경 아이템을 사용 하시겠습니까?`,
        okCallback: () => {
          updateProfileImage({ cropAllStatus });
        }
      });
    }
  };

  const updateProfileImage = ({ cropAllStatus }) => {
    const pathname = history.location.pathname.replace('/mypage/', '');
    mypageStore.actionProfileImageChange(
      {
        filePath: mypageStore.data.file_path,
        size: cropAllStatus ? 0 : crop.width,
        pointX: cropAllStatus ? 0 : crop.x,
        pointY: cropAllStatus ? 0 : crop.y
      },
      () => {
        if (pathname === 'info') {
          usersStore.getInfo();
          onClose();
        } else {
          mypageStore.actionMyItemList({
            category: category === 'all' ? '' : category
          });
        }
      }
    );
  };

  const onClickCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (!!profileImage) {
      mypageStore.actionProfileTmpImage({ profileImage });
    }

    return () => {
      mypageStore.data.url = null;
    };
  }, [profileImage]);

  const goMarket = () => {
    history.push('/mypage/market');
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const hiddenFileInput = React.useRef(null);

  return (
    <>
      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">프로필 이미지 변경</h2>
          <button className={'btn_close'} onClick={onClickCancel} />
        </div>
        <div className="img_change_area">
          <div className="inner">
            <a className="profile_img" href="javascript:;">
              <span className="thumb">
                {mypageStore.data.url ? (
                  <img
                    src={`${mypageStore.data.url}`}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    alt="프로필 이미지"
                  />
                ) : usersStore.me.profile_img ? (
                  <img src={usersStore.me.profile_img} />
                ) : (
                  <img src="/images/thumb_user_def.png" />
                )}
                <svg
                  className="border"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 36 36"
                  enable-background="new 0 0 36 36"
                  xmlSpace="preserve"
                >
                  <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                </svg>
              </span>
            </a>
            <input
              type="file"
              accept="image/*"
              className="btn_upload profile"
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              onChange={(e) => {
                setProfileImage(e.target.files[0]);
              }}
            />
            <button
              type="button"
              className="btn_upload profile"
              onClick={handleClick}
            >
              이미지 올리기
            </button>
            {usersStore.data.certify.is_phone_certify ? (
              <p className="tx">
                프로필 이미지 변경권(<strong>본인인증 완료</strong>)
              </p>
            ) : (
              <p className="tx">
                프로필 이미지 변경권{' '}
                <strong>
                  {usersStore.data.item.change_profile_image
                    ? usersStore.data.item.change_profile_image
                    : 0}
                  개
                </strong>{' '}
                보유중
              </p>
            )}
          </div>
          <div className="btn_area">
            <button type="button" className="btn" onClick={onClickCancel}>
              취소
            </button>
            <button
              type="submit"
              className="btn btn_submit"
              onClick={() => onClickProfileImageChange({ cropAllStatus: true })}
            >
              변경하기
            </button>
          </div>
        </div>
        <div className="tip">
          <p>
            변경권이 없으신가요?{' '}
            <a className="lnk" href="javascript:;" onClick={goMarket}>
              프로필 이미지 변경권 구매하기
            </a>
          </p>
          <p className="c_999">
            선정적, 사회적으로 이슈가 되는 이미지 등록 시 별도의 통보없이 삭제될
            수 있습니다.
          </p>
        </div>
      </div>
    </>
  );
});

export default ProfileImageChange;
