import React, { useEffect, useState } from "react";
import AnalysisOption from "../../analysisOption";
import { useLocation } from "react-router-dom";
import { timeUtils } from "../../../../../common/utils/timeUtil";
import { LotteryService } from "../../../../../common/services";
import BarChart from "../../ladder/analysis/barChart";
import MinMaxWithinPeriod from "../../ladder/analysis/minMaxWithinPeriod";
import PatternGraph from "../../ladder/analysis/patternGraph";
import RoundTable from "../../ladder/analysis/roundTable";
import PatternBarChart from "../../ladder/analysis/patternBarChart";
import PatternTable from "../../patternTable";
import CountPattern from "../../countPattern";

const optionsMap = {
  daily: [
    { value: "oneDay", name: "하루씩보기" },
    { value: "period", name: "기간으로보기" }
  ],
  round: [
    { value: "single", name: "단일회차" },
    { value: "latest", name: "최근회차" }
  ],
  pattern: [
    { value: "odd_even", name: "결과" },
    { value: "start_point", name: "출발" },
    { value: "line_count", name: "줄수" },
    { value: "combination", name: "출발+줄수" }
  ]
};

const todayDate = timeUtils.todayYmd();

const PowerLadderAnalysis = ({ brandName, gameName }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get("type") || "daily";
  const option = query.get("option") || "oneDay";
  const startDate = query.get("start_dt") || todayDate;
  const endDate = query.get("end_dt") || todayDate;
  const round = parseInt(query.get("round")) || 100;
  const count = parseInt(query.get("count")) || 10;

  const [data, setDate] = useState({
    count: {},
    list: [],
    percent: {}
  });
  const [patternData, setPatternData] = useState({
    list: [],
    percent: {},
    search_pattern: "",
    search_pattern_round: ""
  });
  const getGameTypeParameter = () => {
    if (brandName === "redball") {
      return "redball_powerladder";
    } else {
      switch (gameName) {
        case "powerladder":
        case "powerladder5":
          return "power_ladder";
        case "powerladder3":
          return "three_power_ladder";
      }
    }
  };

  useEffect(() => {
    let params = {};

    if (type === "daily" || type === "round") {
      if (option === "oneDay") {
        params = { startDate: startDate, endDate: startDate };
      } else if (option === "period") {
        params = {
          startDate: startDate,
          endDate: endDate,
          searchType: "daily_range"
        };
      } else if (option === "single") {
        params = { startDate: startDate, round: round };
      } else {
        params = { roundCount: round };
      }
      params.gameType = getGameTypeParameter();
      LotteryService.getLotteryStatAnalysis({ ...params }).then((res) => {
        setDate(res.data);
      });
    } else if (type === "pattern") {
      params = { gameType: getGameTypeParameter(), patternType: option, count: count };
      LotteryService.getLotteryStatAnalysisPattern({ ...params }).then(
        (res) => {
          setPatternData(res.data);
        }
      );
    }
  }, [type, option, round, startDate, count]);

  return (
    <div className="game_stats_area">
      <AnalysisOption
        options={optionsMap[type]}
        patternTypeList={optionsMap.pattern}
      />
      <div className="date_detail_wrap">
        {type !== "pattern" && (
          <BarChart count={data.count} title={"파워사다리"} />
        )}
        {option === "period" && <MinMaxWithinPeriod data={data} />}
        {(option === "oneDay" || option === "latest") && (
          <PatternGraph
            gameType={getGameTypeParameter()}
            patternTypeList={optionsMap.pattern}
          />
        )}
        {option !== "period" && type !== "pattern" && (
          <RoundTable list={data.list} option={option} />
        )}
        {type === "pattern" && (
          <>
            <CountPattern
              title={`${
                optionsMap.pattern.find((item) => item.value === option).name
              } 기준 ${count}패턴`}
              searchPattern={patternData.search_pattern}
              searchPatternRound={patternData.search_pattern_round}
            />
            <PatternBarChart
              percent={patternData.percent}
              title={"파워사다리"}
            />
            <PatternTable
              list={patternData.list}
              option={option}
              count={count}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PowerLadderAnalysis;
