import { extendObservable, computed, createAtom, action } from 'mobx';
import autobind from 'autobind-decorator';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { _E_SPORTS_GAME_STATUS } from '../../../../../../common/constants/sportsConstants';
import { StringUtil } from '../../../../../../common/utils/stringUtil';
import { timeUtils } from '../../../../../../common/utils/timeUtil';

@autobind
class StarcraftSetModel {
  progressTime;
  intervalHandler = null;

  starcraftElapsedMin;

  constructor(data) {
    extendObservable(this, data);

    if (
      _E_SPORTS_GAME_STATUS[data.sstatus] === 'IN_PROGRESS' &&
      !!data.start_time
    ) {
      this.progressTime = createAtom(
        'ProgressTime',
        () => this.startTicking(),
        () => this.stopTicking()
      );
    }
  }

  startTicking() {
    this.tick();
    this.intervalHandler = setInterval(() => this.tick(), 1000);
  }

  stopTicking() {
    clearInterval(this.intervalHandler);
    this.intervalHandler = null;
  }

  tick() {
    const { start_time } = this;

    const timestamp = differenceInSeconds(
      Date.now(),
      new Date(`${timeUtils.todayYmd()}T${start_time}` + '+09:00')
    );

    let minutes = Math.floor(timestamp / 60);
    let seconds = timestamp % 60;

    const format = `${StringUtil.pad(minutes, 2, '0')}:${StringUtil.pad(
      seconds,
      2,
      '0'
    )}`;

    this.progressTime.reportChanged();

    this.starcraftElapsedMin = format;
  }

  getLolTime() {
    if (this.progressTime.reportObserved()) {
      return this.starcraftElapsedMin;
    } else {
      return '00:00';
    }
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get displayTime() {
    const { sstatus, hidden_time, total_time } = this;
    const splitTime = !!total_time ? total_time.split(':') : '00:00';

    if (_E_SPORTS_GAME_STATUS[sstatus] === 'READY') {
      return '대기';
    } else if (_E_SPORTS_GAME_STATUS[sstatus] === 'IN_PROGRESS') {
      if (Boolean(+hidden_time)) {
        return '';
      } else {
        return this.getLolTime();
      }
    } else if (_E_SPORTS_GAME_STATUS[sstatus] === 'FINAL') {
      if (Array.isArray(splitTime)) {
        splitTime.shift();

        return splitTime.join(':');
      } else {
        return splitTime;
      }
    } else if (_E_SPORTS_GAME_STATUS[sstatus] === 'CANCEL') {
      return '00:00';
    }
  }

  @computed
  get activTime() {
    const { sstatus } = this;

    return _E_SPORTS_GAME_STATUS[sstatus] === 'IN_PROGRESS';
  }

  @computed
  get win() {
    const { sresult, sstatus } = this;
    let win = '';

    if (_E_SPORTS_GAME_STATUS[sstatus] === 'FINAL') {
      if (sresult.length > 0) {
        win = sresult === '1' ? 'home' : 'away';
      }
    }

    return win;
  }

  @computed
  get playerRecord() {
    const { home_map_record, away_map_record } = this;

    let record = {
      home: `${home_map_record.wincnt}승${home_map_record.losecnt}패`,
      away: `${away_map_record.wincnt}승${away_map_record.losecnt}패`
    };

    return record;
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  updateSetDetail(data) {
    for (const [key, value] of Object.entries(data)) {
      if (key === 'gidx') {
        this.id = value;
      } else if (key === 'sstatus') {
        if (
          _E_SPORTS_GAME_STATUS[value] === 'IN_PROGRESS' &&
          !!data.start_time
        ) {
          if (typeof this.progressTime === 'undefined') {
            this.progressTime = createAtom(
              'ProgressTime',
              () => this.startTicking(),
              () => this.stopTicking()
            );
          }
        }

        this.sstatus = value;
      } else {
        this[key] = value;
      }
    }
  }
}

export default StarcraftSetModel;
