import React, { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import useUsersStore from '../../../hooks/useUsersStore';

const BackgroundImageChange = observer(
  ({ history, onClose, category = '' }) => {
    const usersStore = useUsersStore();
    const [crop, setCrop] = useState({ unit: '%', width: 50, aspect: 23 / 15 });
    const { mypageStore } = useMypageStore(MypageContext);
    const [backgroundImage, setBackgroundImage] = useState(null);

    const onClickBackgroundImageChange = () => {
      if (typeof mypageStore.data.file_path !== 'string') {
        usersStore.modalStore.openAlertModal({
          message: `변경할 프로필 배경 이미지를 선택해주세요.`
        });
        return;
      }

      if (usersStore.data.certify.is_phone_certify) {
        updateBackgroundImage();
      } else {
        usersStore.modalStore.openConfirmModal({
          message: `프로필 배경 이미지 변경 아이템을 사용 하시겠습니까?`,
          okCallback: () => {
            updateBackgroundImage();
          }
        });
      }
    };

    const updateBackgroundImage = () => {
      const pathname = history.location.pathname.replace('/mypage/', '');

      mypageStore.actionBackgroundImageChange(
        {
          filePath: mypageStore.data.file_path,
          width: 0,
          height: 0,
          pointX: 0,
          pointY: 0
        },
        () => {
          if (pathname === 'info') {
            usersStore.getInfo();
            onClose();
          } else {
            mypageStore.actionMyItemList({
              category: category === 'all' ? '' : category
            });
          }
        }
      );
    };

    const onClickCancel = () => {
      onClose();
    };

    useEffect(() => {
      if (!!backgroundImage) {
        mypageStore.actionBackgroundTmpImage({ backgroundImage });
      }

      return () => {
        mypageStore.data.url = null;
      };
    }, [backgroundImage]);

    const goMarket = () => {
      history.push('/mypage/market');
    };

    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };

    const hiddenFileInput = React.useRef(null);

    return (
      <>
        <div className="account_info_wrap">
          <div className="nav">
            <h2 className="tit">프로필 배경 이미지 변경</h2>
            <button className={'btn_close'} onClick={onClickCancel} />
          </div>
          <div className="img_change_area">
            <div className="inner">
              <a className="background_img" href="javascript:;">
                <span className="thumb">
                  {mypageStore.data.url ? (
                    <img
                      src={`${mypageStore.data.url}`}
                      crop={crop}
                      onChange={(c) => setCrop(c)}
                      alt="프로필 배경 이미지"
                    />
                  ) : usersStore.me.background_image_path ? (
                    <img src={usersStore.me.background_image_path} />
                  ) : (
                    <img src="/images/thumb_user_def.png" />
                  )}
                </span>
              </a>
              <input
                type="file"
                accept="image/*"
                className="btn_upload profile"
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                onChange={(e) => {
                  setBackgroundImage(e.target.files[0]);
                }}
              />
              <button
                type="button"
                className="btn_upload background"
                onClick={handleClick}
              >
                이미지 올리기
              </button>
              {usersStore.data.certify.is_phone_certify ? (
                <p className="tx">
                  프로필 배경 이미지 변경권(<strong>본인인증 완료</strong>)
                </p>
              ) : (
                <p className="tx">
                  프로필 배경 이미지 변경권{' '}
                  <strong>
                    {usersStore.data.item.change_background_image
                      ? usersStore.data.item.change_background_image
                      : 0}
                    개
                  </strong>{' '}
                  보유중
                </p>
              )}
            </div>
            <div className="btn_area">
              <button type="button" className="btn" onClick={onClickCancel}>
                취소
              </button>
              <button
                type="submit"
                className="btn btn_submit"
                onClick={() => onClickBackgroundImageChange()}
              >
                변경하기
              </button>
            </div>
          </div>
          <div className="tip">
            <p>
              변경권이 없으신가요?{' '}
              <a className="lnk" href="javascript:;" onClick={goMarket}>
                프로필 배경 이미지 변경권 구매하기
              </a>
            </p>
            <p className="c_999">
              선정적, 사회적으로 이슈가 되는 이미지 등록 시 별도의 통보없이
              삭제될 수 있습니다.
            </p>
            <p className="c_999">
              모바일 최적화 권장 이미지 사이즈는 720px x 440px 입니다.
            </p>
          </div>
        </div>
      </>
    );
  }
);

export default BackgroundImageChange;
