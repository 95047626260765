import React, { } from 'react';
import { observer } from 'mobx-react';
import '../../style/chat.scss';
import { getChatHostname } from '../../common/utils/hostnameUtil'

const ChatHtml = observer(() => {
  let { chatHostNameWithProtocol } = getChatHostname();

  return (
    <>
      <iframe className="box-chat-iframe" scrolling="no" frameBorder="0" id="livechat"
        src={chatHostNameWithProtocol + '/nlivechat/chat.php'}
      ></iframe>
    </>
  );
});

export default ChatHtml;
