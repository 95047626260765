import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { MypageCashChargeList, MypageCashUseList } from '../components';
import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';
import { MypageContext, useMypageStore } from '../context';
import { RegexUtil } from '../../../common/utils/regexUtil';
import Pagination from '../../../components/pagination';
import NoneContent from '../../../components/noneContent';
import Seo from '../../../components/seo';
import { Url } from "../../../common/constants/environment";

const Item = observer(({ history }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [tab, setTab] = useState(query.get('tab') || 'cash_charge_list');
  const page = parseInt(query.get('page')) || 1;
  const [startDt, setStartDt] = useState('');
  const [endDt, setEndDt] = useState('');
  const { mypageStore } = useMypageStore(MypageContext);
  const totalCount = mypageStore.data.total_cnt;

  const Component = useMemo(() => {
    switch (tab) {
      case 'cash_charge_list':
        return MypageCashChargeList;
      case 'cash_use_list':
        return MypageCashUseList;
      default:
        return MypageCashChargeList;
    }
  }, [tab, page]);

  const onClickSetTab = (tab) => {
    setTab(tab);
  };

  useEffect(() => {
    if (tab === 'cash_charge_list') {
      mypageStore.actionCashChargeList({ startDt, endDt, page });
    } else {
      mypageStore.actionBuyItemList({ startDt, endDt, page });
    }
  }, [page, tab]);

  return (
    <>
      <Seo title={'마이페이지 캐쉬'} />
      <div className="snb_wrap">
        <ul>
          <li>
            <Link
              to={`${location.pathname}?tab=cash_charge_list`}
              className={clsx('item', tab === 'cash_charge_list' && 'selected')}
              onClick={() => setTab('cash_charge_list')}
            >
              충전내역
            </Link>
          </li>
          <li>
            <Link
              to={`${location.pathname}?tab=cash_use_list`}
              className={clsx('item', tab === 'cash_use_list' && 'selected')}
              onClick={() => setTab('cash_use_list')}
            >
              사용내역
            </Link>
          </li>
        </ul>
      </div>
      <div className="history_wrap cash">
        <div className="summary">
          <dl className="amass">
            <dt>보유 캐시</dt>
            <dd>{RegexUtil.makeComma(mypageStore.data.total_cash)}</dd>
          </dl>
          <dl className="today">
            <dt>오늘 충전 캐시</dt>
            <dd>{RegexUtil.makeComma(mypageStore.data.today_cash)}</dd>
          </dl>
        </div>
        <div className="list_area">
          <div className="tip_area">
            <p className="tx">※ 최대 3개월 이내의 내역만 확인 가능합니다.</p>
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="btn_charge"*/}
            {/*  onClick={onClickCharge}*/}
            {/*>*/}
            {/*  충전하기*/}
            {/*</button>*/}
            <a href="/mypage/cash/request-charge" className="btn_charge">충전하기</a>
          </div>
          {totalCount < 1 ? (
            <NoneContent
              title={tab === 'cash_charge_list' ? '캐쉬 충전' : '캐쉬 사용'}
            />
          ) : (
            <Component history={history} items={mypageStore.data.list} />
          )}
        </div>
        <Pagination
          page={page}
          totalCount={totalCount}
          tab={tab}
          rowCount={10}
        />
      </div>
    </>
  );
});

export default Item;
