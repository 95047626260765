import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 54px;
  min-height: 54px;
  border-bottom: 1px solid #e4e4e4;
  margin-top: 48px;
  box-sizing: border-box;
  > h2 {
    font-size: 18px;
  }
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 8px;
  gap: 4px;
`;

export const BackButton = styled.button`
  height: 40px;
  width: 40px;
  background: url('/images/ic-back.svg') no-repeat center center;
  background-size: 18px;
`;

export const TotalDeleteButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 15px;
  font-size: 13px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  overflow-y: auto;
  height: calc(100% - 55px);
  li {
    height: 66px;
    min-height: 66px;
    border-bottom: 1px solid #f2f2f2;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
  }
`;

export const Dot = styled.label`
  position: absolute;
  top: 6px;
  left: -8px;
  height: 4px;
  width: 4px;
  background-color: #ed2026;
  border-radius: 2px;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  width: 37px;
  svg {
    height: 16px;
    width: 16px;
    margin-left: auto;
  }
`;

export const NoneContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f4;
  height: calc(100vh - 186px);
  img {
    width: 60px;
    margin-bottom: 15px;
  }
  span {
    font-size: 14px;
    color: #666;
  }
`;

// New code
export const MessageListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  height: calc(100vh - 55px - 53px - 48px - 30px);
`;

export const MessageHeader = styled.p`
    padding: 0 16px 0 0;
    display: block;
    height: 53px;
    line-height: 53px;
    font-size: 12px;
    background: #ebeef3;
    border-top: 1px solid #ced0d2;
    display: flex;
    justify-content: space-between;
`;

export const Title = styled.span`
    font-weight: 600;
    font-size: 15px;
    font-family: YoonGothicPro,  'Gulim', sans-serif;
    padding-top: 3px;
`;

export const ActionSpan = styled.span`
    cursor: pointer;
    margin-left: 15px;
    user-select: none; /* Prevents text selection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */

    /* Prevent highlight color */
    outline: none;
    background: transparent;

    /* Prevent focus outline */
    &:focus {
        outline: none;
    }

    /* Optional: Prevent tapping highlight on mobile */
    -webkit-tap-highlight-color: transparent;
`;

export const MessageListUlContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 55px;
`;

export const MessageListUl = styled.ul`
  background: #fff;
  overflow-y: auto;
  flex: 1;
  padding-bottom: 55px;
`;

export const BottomMenu = styled.div`
    height: 53px;
    background: #ebeef3;
    line-height: 53px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    position: fixed;
    bottom: 0;
    margin-bottom: 55px;
    left: 0;
    right: 0;
`;

export const BottomMenuItem = styled.span`
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  color: ${props => props.color || 'inherit'};
`;


export const MessageItemWrapper = styled.div`
  border-bottom: 1px solid #ebeef3;
  background: #fff;
  align-items: center;
  display: flex;
  padding-left: 16px;

  span {
    font-family: YoonGothicPro,  'Gulim', sans-serif;
    font-size: 12px;
    color: #a9a9a9;
  }
`;

export const DotWrapper = styled.div`
    display: block;
    width: 7px;
    height: 7px;
    background: ${props => props.unread ? '#ff1517' : '#dce2ea'};
    border-radius: 100%;
    margin-right: 10px;
`;

export const MessageContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const MessageMetadataContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;


export const TitleWrapper = styled.div`
  font-family: YoonGothicPro, 맑은고딕, Gulim, dotum, 돋음, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${props => props.unread ? '#272727' : '#a9a9a9'};
  margin-bottom: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
`;

export const DotSubWrapper = styled.span`
    margin: 0 10px;
`;

export const ButtonWrapper = styled.div`
  padding: 27px 16px;
  cursor: pointer;

  img {
    vertical-align: middle;
    width: 13px;
    height: 13px;
  }
`;