import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../../../context';
import {RegexUtil} from "../../../../../common/utils/regexUtil";
import useCommonStore from "../../../../../hooks/useCommonStore";

const MypageCashChargeList = observer(({ history, items = [] }) => {
  const commonStore = useCommonStore();

  const getPayType = (type) => {
    let payType = '';

    switch (type) {
      case 'NONACC':
        payType = '무통장입금';
        break;
      case 'VACC':
        payType = '가상계좌';
        break;
      case 'HPHONE':
        payType = '휴대폰결제';
        break;
      case 'CREDIT':
        payType = '신용카드';
        break;
      case 'HAPPY':
        payType = '해피문화상품권';
        break;
      case 'CULTURE':
        payType = '문화상품권';
        break;
      case 'REFUND':
        payType = '환불';
        break;
      case 'GAME':
        payType = '게임문화상품권';
        break;
      case 'BOOK':
        payType = '도서문화상품권';
        break;
      case 'TMONEY':
        payType = '모바일티머니';
        break;
      default:
        payType = '무통장입금';
        break;
    }

    return payType;
  };

  const getStatusType = (status, payType) => {
    let statusType = '';

    if (payType === "NONACC" || payType === "VACC" || payType === "BANK") {
      if (status === "READY") {
        statusType = "입금<br>대기";
      } else if (status === "COMPLETE") {
        statusType = "입금<br>완료";
      } else if (status === "CANCEL") {
        statusType = "입금<br>취소";
      } else if (status === "ERROR") {
        statusType = "결제<br>오류";
      }
      
      if (payType === "VACC") {
        if (status === "NONE") {
          statusType = "결제<br>시도";
        }
      }
    } else if (payType === "DIRECT") {
      if (status === "COMPLETE") {
        statusType = "지급<br>완료";
      } else if (status === "CANCEL") {
        statusType = "지급<br>취소";
      }
    } else if (payType === "CREDIT") {
      if (status === "COMPLETE") {
        statusType = "결제<br>완료";
      } else if (status === "CANCEL") {
        statusType = "결제<br>취소";
      } else if (status === "READY") {
        statusType = "결제<br>시도";
      }
    } else if (payType === "REFUND") {
      if (status === "COMPLETE") {
        statusType = "환불<br>완료";
      }
    } else {
      if (status === "COMPLETE") {
        statusType = "결제<br>완료";
      } else if (status === "CANCEL") {
        statusType = "결제<br>취소";
      } else if (status === "READY") {
        statusType = "결제<br>대기";
      }
    }

    if (status === "EXPIRE" || status === "NONE") {
      statusType = '기간<br>만료';
    }

    return statusType
  }

  const onClickNonaccInfo = (item) => {
    commonStore.modalStore.openAlertModal({
      message: item.bank.name + " " + item.bank.account + " " + item.bank.holder
    });
  }

  const getCashBillLink = (item) => {
    if (item.pay_type === "NONACC" && item.status === "READY") {
      return {link: () => onClickNonaccInfo(item), text: "(계좌안내)"};
    } else if (item.pay_type === "NONACC" && item.status === "COMPLETE" && item.cash_bill === null) {
      return {link: () => onClickCashBillRequest(item), text: "(현금영수증 신청)"};
    }  else if (item.pay_type === "NONACC" && item.status === "COMPLETE" && item.cash_bill !== null) {

      if (item.cash_bill.state === 'COMPLETE') {
        return {link: () => '', text: "(현금영수증 완료)"};
      } else if (item.cash_bill.state === 'PROGRESS') {
        return {link: () => '', text: "(현금영수증 처리중)"};
      } else if (item.cash_bill.state === 'CANCEL') {
        return {link: () => '', text: "(현금영수증 취소)"};
      } else {
        return {link: () => '', text: "(현금영수증 기타오류)"};
      }
    } else {
      return ''
    }
  }

  const onClickCashBillRequest = (item) => {
    history.push('/mypage/cash-receipt', {pay_id: item.pay_id})
  }

  return (
        <div className="list charge">
          {!!items &&
          items.map((item, i) => (
              <div className="item" key={i}>
                <span className={item.status ? "state " + item.status.toLowerCase() : "state "} dangerouslySetInnerHTML={{__html: getStatusType(item.status, item.pay_type)}}></span>
              <span className="info">
              <em className="tx">
                {getPayType(item.pay_type)}
                <button type="button" className="btn_lnk" onClick={getCashBillLink(item).link}>{getCashBillLink(item).text}</button>
              </em>
              <em className="price">결제금액 : {RegexUtil.makeComma(item.price)}</em>
              <em className="date">{item.reg_dt}</em>
            </span>
                <span className={item.cash <= 0 ? 'num minus' : 'num'}>
                  <em className="amount"> {item.cash !== undefined ? item.cash <= 0 ? RegexUtil.makeComma(item.cash) : '+' + RegexUtil.makeComma(item.cash) : 0} </em>
                  <em className="bonus"> +{item.cash >= 0 ? RegexUtil.makeComma(item.bonus) : 0} </em>
                </span>
              </div>
          ))}
        </div>
  )
});

export { MypageCashChargeList };
