import React, { useState } from 'react';
import "./wallet.css";

function WalletAddress() {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const walletAddress = "TKCZpH5i56b2GZA2q98TCHQ2w5SBXeMhPR";

  const copyToClipboard = () => {
    if (navigator.clipboard) {
    navigator.clipboard.writeText(walletAddress).then(() => {
        setTooltipVisible(true);
        setTimeout(() => {
            setTooltipVisible(false);
        }, 2000);
    }).catch(err => {
        console.error('Failed to copy: ', err);
    });
    } else {
        fallbackCopyTextToClipboard(walletAddress);
        setTooltipVisible(true);
        setTimeout(() => {
            setTooltipVisible(false);
        }, 2000);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
}

  return (
    <>
      <p>Address</p>
      <div className="wallet-address">
        <b><span id="w-address">{walletAddress}</span></b>
        <button id="btn-copy" className="tooltip" onClick={copyToClipboard}>
          <img src="/images/payment/copy-icon.png" alt="Copy" />
          {tooltipVisible && <span className="tooltiptext">복사됨!</span>}
        </button>
      </div>
    </>
  );
}

export default WalletAddress;