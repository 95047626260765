import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import { Form, Buttons, ItemInfo } from './styled';
import { ImageUtil } from '../../../common/utils/imageUtil';
import AutoSuggestInput from './AutoSuggestInput';
import QuantitySelect from './QuantitySelect';

const ItemGift = observer(({ history, onClose, count, maxCount, maxGiftCount, item, payType }) => {
  const { mypageStore } = useMypageStore(MypageContext);
  const [nick, setNick] = useState('');
  const [sendMessage, setSendMessage] = useState('');
  const [giftQuantity, setGiftQuantity] = useState(count);

  const onClickSend = (event) => {
    const is_market = history.location.pathname.replace('/mypage/', '') === 'market';

    if ((is_market && item.giftable !== 'Y') || (!is_market && item.gift_count < 1)) {
      console.log('선물이 불가능한 아이템입니다.');
      return;
    }

    event.preventDefault();
    if (is_market) {
      mypageStore.actionSendGiftMarket({
        itemId: item.id,
        giftNick: nick,
        count: giftQuantity,
        sendMessage,
        payType,
      });
    } else {
      mypageStore.actionSendGift({
        itemId: item.id,
        giftNick: nick,
        count: giftQuantity,
        sendMessage,
      });
    }
    onClose();
  };

  const onQuantityChange = (count) => {
    setGiftQuantity(count);
  };

  const { image, name, desc } = item;

  return (
    <div className='account_info_wrap'>
      <div className='nav'>
        <h2 className='tit'>선물하기</h2>
        <button className={'btn_close'} onClick={onClose} />
      </div>
      <ItemInfo>
        <img src={ImageUtil.imageUrl(image, 50, 50)} />
        <p className={'name'}>{name}</p>
        <p className={'desc'}>{desc}</p>
      </ItemInfo>
      <Form onSubmit={onClickSend}>
        <QuantitySelect
          count={count}
          maxCount={maxCount}
          maxGiftCount={maxGiftCount}
          onQuantityChange={onQuantityChange}
        />
        <AutoSuggestInput onChange={setNick} />
        <textarea
          name=''
          id=''
          cols='30'
          rows='10'
          placeholder={'선물과 함께 전달할 메세지'}
          value={sendMessage}
          onChange={(event) => setSendMessage(event.target.value)}
        />
        <Buttons>
          <button type={'button'} onClick={onClose}>
            취소
          </button>
          <button type={'submit'} className={'send'}>
            보내기
          </button>
        </Buttons>
      </Form>
      <div className='tip'>
        <p className='c_999'>선물시 포인트는 구매하신 분께 귀속됩니다.</p>
        <p className='c_999'>
          수신인을 잘못 지정하여 다른 사람이 수신하여 사용한 경우, 책임은 사용자에게 있으며, 환불되지 않습니다.
        </p>
      </div>
    </div>
  );
});

export default ItemGift;
