import React from 'react';
import styled from 'styled-components';
import { Card } from '../result/styled';

const HoldemGuide = ({}) => {
  return (
    <Wrap>
      <div className={'step'}>
        <p className={'title'}>게임진행안내</p>
        <p>
          첫번째 배당은 프리플랍 전 아무것도 없는 상태에서 배당이
          업데이트됩니다.
        </p>
        <br />
        <p>
          두번째 배당은 모두에게 2장씩 12장이 앞면이 보여지도록 오픈된 후
          업데이트 됩니다. (프리플랍)
        </p>
        <br />
        <p>
          세번째 배당은 커뮤니티 카드 3장이 오픈된후 배당이 업데이트 됩니다.
          (플랍)
        </p>
        <br />
        <p>
          네번째 배당은 커뮤니티 카드 4장이 오픈된 후 배당이 업데이트 됩니다.
          (턴)
        </p>
        <br />
        <p>마지막으로 커뮤니티 카드 5장이 오픈되면 게임이 종료됩니다. (리버)</p>
      </div>
      <div>
        <p className={'title'}>족보</p>
      </div>
      <div className={'combo'}>
        <p>
          하이카드 - 5장의 카드가 숫자, 무늬 모두 다른 경우.가장 높은 숫자부터
          비교하여 승자 판별.
        </p>
        <div className={'cards'}>
          <Card className={'cAd'} />
          <Card className={'cTh'} />
          <Card className={'c5s'} />
          <Card className={'c2h'} />
          <Card className={'c6d'} />
        </div>
      </div>
      <div className={'combo'}>
        <p>
          원페어 - 5장의 카드 중 같은 숫자가 2개인 경우.같은 원페어인 경우 높은
          숫자의 페어가 승자가 되고, 같을 시 나머지 숫자들을 비교해서 판별.
        </p>
        <div className={'cards'}>
          <Card className={'cAs'} />
          <Card className={'cAh'} />
          <Card className={'c3s'} />
          <Card className={'c7h'} />
          <Card className={'cQd'} />
        </div>
      </div>
      <div className={'combo'}>
        <p>투페어 - 5장의 카드 중 같은 숫자가 2가 페어인 경우.</p>
        <p>
          투페어의 경우 가장 높은 수자의 페어가 승자가 되고, 높은 페어 숫자가
          같으면 다음 숫자로 승자를 판별. 2가지 페어 숫자가 동일한 경우 마지막
          카드(킥커)가 높은 플레이어가 승자.
        </p>
        <div className={'cards'}>
          <Card className={'cKs'} />
          <Card className={'cKh'} />
          <Card className={'c5s'} />
          <Card className={'c5h'} />
          <Card className={'cJc'} />
        </div>
      </div>
      <div className={'combo'}>
        <p>트리플 - 5장의 카드 중 같은 숫자의 카드가 3장인 경우.</p>
        <div className={'cards'}>
          <Card className={'cAs'} />
          <Card className={'cAh'} />
          <Card className={'cAd'} />
          <Card className={'c8d'} />
          <Card className={'c5h'} />
        </div>
      </div>

      <div className={'combo'}>
        <p>스트레이트 - 5장의 카드 숫자가 연속된 경우.</p>
        <div className={'cards'}>
          <Card className={'c3h'} />
          <Card className={'c4s'} />
          <Card className={'c5c'} />
          <Card className={'c6d'} />
          <Card className={'c7c'} />
        </div>
      </div>

      <div className={'combo'}>
        <p>플러쉬 - 5장의 카드가 무늬가 같은 경우.</p>
        <p>
          같은 플러쉬인 경우 가장 높은 숫자가 승자가 되고 가장 높은 숫자가 같은
          경우 다음 카드 차례로 숫자를 비교하여 숫자가 높은 플레이어가 승자.
        </p>
        <div className={'cards'}>
          <Card className={'c5c'} />
          <Card className={'c8c'} />
          <Card className={'c9c'} />
          <Card className={'cQc'} />
          <Card className={'cKc'} />
        </div>
      </div>

      <div className={'combo'}>
        <p>
          풀 하우스 - 5장의 카드 중에 같은 숫자가 3장(Three of a kind) + 같은
          숫자 2장(Pair) 조합.
        </p>
        <p>
          같은 풀하우스의 경우 트리플 숫자가 높은 플레이어가 이기고 트리플이
          같은 경우, 페어 숫자가 높은 플레이어가 승자. 트리플, 페어가 모두 같은
          경우 찹찹이 된다.
        </p>
        <div className={'cards'}>
          <Card className={'c7c'} />
          <Card className={'c7h'} />
          <Card className={'c7s'} />
          <Card className={'cJs'} />
          <Card className={'cJh'} />
        </div>
      </div>

      <div className={'combo'}>
        <p>
          포카드 - 5장의 카드 중 같은 숫자가 4장인 경우. 동시에 포카드인 경우
          마지막 5번째 카드(킥커)로 승부를 정함.
        </p>
        <div className={'cards'}>
          <Card className={'cJs'} />
          <Card className={'cJh'} />
          <Card className={'cJd'} />
          <Card className={'cJc'} />
          <Card className={'c3h'} />
        </div>
      </div>

      <div className={'combo'}>
        <p>
          스트레이트 플러쉬 - 무늬가 같으면서 숫자가 연결된 것, 가장 높은
          A스트레이트 플러쉬를 로열 스트레이트 플래쉬라고 따로 부르고 그 외의
          경우를 말한다.
        </p>
        <div className={'cards'}>
          <Card className={'c4c'} />
          <Card className={'c5c'} />
          <Card className={'c6c'} />
          <Card className={'c7c'} />
          <Card className={'c8c'} />
        </div>
      </div>

      <div className={'combo'}>
        <p>
          로열 스트레이트 플러쉬 - 무늬가 같고 A, K, Q, J, 10, 플래쉬이면서
          동시에 가장 높은 스트레이트.
        </p>
        <div className={'cards'}>
          <Card className={'cTs'} />
          <Card className={'cJs'} />
          <Card className={'cQs'} />
          <Card className={'cKs'} />
          <Card className={'cAs'} />
        </div>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  > div {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    border-bottom: 1px solid #ededed;
  }
  .step {
    padding-bottom: 15px;
  }
  .combo {
    padding: 20px;
    .cards {
      display: flex;
      margin-top: 10px;
    }
  }
  .title {
    font-size: 14px;
    line-height: 55px;
  }
  p {
    font-size: 12px;
  }
`;

export default HoldemGuide;
