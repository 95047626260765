import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

const PatternTable = ({ list = [], option, count }) => {
  const size = 10;
  const [page, setPage] = useState(1);
  const totalPage = useMemo(() => {
    if (list.length === 0) return 1;
    return Math.ceil(list.length / size);
  }, [list.length]);

  useEffect(() => {
    setPage(1);
  }, [option, count]);

  return (
    <div className="data_pattern_result_wrap">
      <h3 className="tit">다음회차 통계</h3>
      <div className="pattern_result">
        <table className="list" border="1">
          <caption>결과리스트</caption>
          <colgroup>
            <col width="80px" />
            <col width="" />
            <col width="60px" />
          </colgroup>
          <thead>
            <tr>
              <th>날짜</th>
              <th>결과</th>
              <th>다음회차</th>
            </tr>
          </thead>
          <tbody>
            {list.slice(0, size * page).map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item.next.reg_date}</td>
                  <td className="result">
                    {item.pattern_list.map((item, key) => {
                      return (
                        <dl className="item" key={key}>
                          <dt
                            className={clsx(
                              'ic',
                              `${item[
                                option === 'combination'
                                  ? 'odd_even_class'
                                  : `${option}_class`
                              ].toUpperCase()}`
                            )}
                          >
                            {option === 'combination'
                              ? `${item.odd_even}${item.line_count}`
                              : item[option]}
                          </dt>
                          <dd className="times">{item.round}</dd>
                        </dl>
                      );
                    })}
                  </td>
                  <td>
                    <dl className="item">
                      <dt
                        className={clsx(
                          'ic',
                          `${item.next[
                            option === 'combination'
                              ? 'odd_even_class'
                              : `${option}_class`
                          ].toUpperCase()}`
                        )}
                      >
                        {/*{item.next[option]}*/}
                        {option === 'combination'
                          ? `${item.next.odd_even}${item.next.line_count}`
                          : item.next[option]}
                      </dt>
                      <dd className="times">{item.next.round}</dd>
                    </dl>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button
          className="btn_more"
          style={page === totalPage ? { display: 'none' } : {}}
          onClick={() => {
            if (page >= totalPage) return;
            setPage(page + 1);
          }}
        >
          더보기 ( {page} / {totalPage} )
        </button>
        {list.length === 0 && (
          <NoneContent>
            <p>검색된 결과가 없습니다.</p>
          </NoneContent>
        )}
      </div>
    </div>
  );
};

const NoneContent = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 12px;
  }
`;

export default PatternTable;
