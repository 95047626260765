import React from 'react';

const BoardPhotoHtml = () => {
  return (
    <>
      <div className="board_wrap">
        <div className="photo_list">
          <div className="list_area">
            <div className="article new">
              <a className="item" href="javascript:;">
                <div className="thumb">
                  <div className="inner">
                    <img width="100%" alt="" className="ct_m fade loaded change" src="https://ssl.pstatic.net/imgshopping/cnsv/iu/main/20/1027/e18sb3vxz3a.jpg"/>
                  </div>
                </div>
                <p className="subject">소개팅에서 인당신은인당신은인당신은인당신은인당신은인당신은인당신은인당신은인당신은</p>
                <p className="info">
                  <span className="name">플란체</span>
                  <span className="date">10:27</span>
                  <span className="comment">댓글 386</span>
                </p>
              </a>
            </div>
            <div className="article new">
              <a className="item" href="javascript:;">
                <div className="thumb">
                  <div className="inner">
                    <img width="100%" alt="" className="ct_m fade loaded change" src="https://ssl.pstatic.net/imgshopping/cnsv/iu/main/20/1027/e18sb3vxz3a.jpg"/>
                  </div>
                </div>
                <p className="subject">소개팅에서 인당신은인당신은인당신은인당신은인당신은인당신은인당신은인당신은인당신은</p>
                <p className="info">
                  <span className="name">플란체</span>
                  <span className="date">10:27</span>
                  <span className="comment">댓글 386</span>
                </p>
              </a>
            </div>
            <div className="article">
              <a className="item" href="javascript:;">
                <div className="thumb">
                  <div className="inner">
                    <img width="100%" alt="" className="ct_m fade loaded change" src="https://ssl.pstatic.net/imgshopping/cnsv/iu/main/20/1027/e18sb3vxz3a.jpg"/>
                  </div>
                </div>
                <p className="subject">소개팅에서 인당신은인당신은인당신은인당신은인당신은인당신은인당신은인당신은인당신은</p>
                <p className="info">
                  <span className="name">플란체</span>
                  <span className="date">10:27</span>
                  <span className="comment">댓글 386</span>
                </p>
              </a>
            </div>
          </div>
          <button type="button" className="btn_more">더보기</button>
        </div>
      </div>
    </>
  );
};

export default BoardPhotoHtml;
