import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { RegexUtil } from '../../../../common/utils/regexUtil';
import NoneContent from '../../../../components/noneContent';

const MypagePointList = ({ items = [], totalCount = 0 }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (items.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, []);

  return (
    <div className="list_area">
      <div className="tip_area">
        <p className="tx">※ 최대 3개월 이내의 내역만 확인 가능합니다.</p>
      </div>
      {totalCount < 1 ? (
        <NoneContent title={'포인트'} />
      ) : (
        <div className="list">
          {items.map((item, i) => (
            <div className="item" key={i}>
              <span className="info">
                <em className="tx">{item.po_content}</em>
                <em className="date">{item.last_time}</em>
              </span>
              <span className={item.get_point <= 0 ? 'num minus' : 'num'}>
                {item.get_point !== undefined
                  ? item.get_point > 0
                    ? '+' + RegexUtil.makeComma(item.get_point)
                    : RegexUtil.makeComma(item.use_point)
                  : 0}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { MypagePointList };
