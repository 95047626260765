import {
  extendObservable,
  computed,
  action,
  observable,
  runInAction
} from 'mobx';
import autobind from 'autobind-decorator';
import AbstractScoreAudioPrompt from '../../../store/audio';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import { ScoreUtil } from '../../../../../common/utils/scoreUtil';

@autobind
class VolleyballModel extends AbstractScoreAudioPrompt {
  @observable
  goalEvent = {
    location: '',
    isActive: false
  };

  constructor(data) {
    super();
    extendObservable(this, data);
  }

  @computed
  get quarterArr() {
    let quarters = [
      { key: '1set', value: 1 },
      { key: '2set', value: 2 },
      { key: '3set', value: 3 },
      { key: '4set', value: 4 },
      { key: '5set', value: 5 }
    ];

    return quarters;
  }

  @computed
  get getStartDateTime() {
    return timeUtils.timeFormatHourMinute(this.startDatetime);
  }

  @computed
  get getMatchGameTime() {
    return timeUtils.matchGameForamt(this.startDatetime);
  }

  @computed
  get getTeamData() {
    return ScoreUtil.getTeamScore(
      this,
      this.gameStatus,
      this.sportsType,
      this.period
    );
  }

  @computed
  get getTypeData() {
    return {};
  }

  @computed
  get getTracker() {
    const { gameStatus } = this;

    if (
      gameStatus === 'IN_PROGRESS' ||
      gameStatus === 'BREAK_TIME' ||
      gameStatus === 'PAUSE'
    ) {
      return { status: 'progress', class: '' };
    } else if (
      gameStatus === 'FINAL' ||
      gameStatus === 'CUT' ||
      gameStatus === 'POSTPONED' ||
      gameStatus === 'PENDING' ||
      gameStatus === 'CANCEL' ||
      gameStatus === 'DELAY' ||
      gameStatus === 'SUSPENDED'
    ) {
      return { status: 'final', class: 'close' };
    } else {
      // READY
      return { status: 'ready', class: 'wait' };
    }
  }

  @computed
  get availableBroadcast() {
    const { broadcast, teams } = this;
    let initBroadCast = {
      score: {},
      playText: '',
      locationType: null,
      eventType: '',
      displayTime: '00:00',
      teamName: ''
    };

    if (!!broadcast) {
      initBroadCast.score = !!broadcast.score
        ? broadcast.score
        : initBroadCast.score;
      initBroadCast.playText = !!broadcast.playText
        ? broadcast.playText
        : initBroadCast.playText;
      initBroadCast.locationType = !!broadcast.locationType
        ? broadcast.locationType
        : initBroadCast.locationType;
      initBroadCast.eventType = !!broadcast.eventType
        ? broadcast.eventType
        : initBroadCast.eventType;
      initBroadCast.displayTime = !!broadcast.displayTime
        ? broadcast.displayTime
        : initBroadCast.displayTime;
      if (!!initBroadCast.locationType) {
        if (initBroadCast.locationType === 'HOME') {
          initBroadCast.teamName = teams.home.name;
        } else if (initBroadCast.locationType === 'AWAY') {
          initBroadCast.teamName = teams.away.name;
        }
      }
    }

    return initBroadCast;
  }

  @computed
  get winnerTeam() {
    const { teams, period, getTracker } = this;
    let team = null;
    let teamData = null;
    let homeSet = 0;
    let awaySet = 0;

    if (teams.home.periodData.length > 0 && teams.away.periodData.length > 0) {
      let periodIdx = period - 1;

      if (getTracker.status === 'final') {
        periodIdx = period;
      }

      for (let i = 0; i < periodIdx; i++) {
        if (teams.home.periodData[i].score > teams.away.periodData[i].score) {
          homeSet++;
        } else {
          awaySet++;
        }
      }
    }

    if (homeSet > awaySet) {
      team = 'home';
      teamData = teams.home;
    } else if (homeSet < awaySet) {
      team = 'away';
      teamData = teams.away;
    } else if (homeSet === awaySet) {
      team = 'draw';
      teamData = {
        imgPath: '',
        name: ''
      };
    }

    return {
      activeScore: team,
      team: teamData,
      difference: Math.abs(homeSet - awaySet)
    };
  }

  @computed
  get homeTotalScore() {
    const { teams } = this;
    let total = 0;

    if (teams.home.periodData.length > 0) {
      teams.home.periodData.forEach((data) => {
        total += data.score;
      });
    }

    return total;
  }

  @computed
  get awayTotalScore() {
    const { teams } = this;
    let total = 0;

    if (teams.away.periodData.length > 0) {
      teams.away.periodData.forEach((data) => {
        total += data.score;
      });
    }

    return total;
  }

  @computed
  get summaryScore() {
    const { homeTotalScore, awayTotalScore } = this;

    return homeTotalScore + awayTotalScore;
  }

  @computed
  get oddsData() {
    const { odds } = this;
    let result = {
      domestic: {
        home: null,
        homeOption: null,
        away: null,
        awayOption: null
      },
      international: {
        home: null,
        homeOption: null,
        away: null,
        awayOption: null
      }
    };

    result.domestic = ScoreUtil.getScoreOdds({
      oddsArr: odds.domesticWinLoseOdds,
      handiArr: odds.domesticHandicapOdds,
      uoArr: odds.domesticUnderOverOdds
    });

    result.international = ScoreUtil.getScoreOdds({
      oddsArr: odds.internationalWinLoseOdds,
      handiArr: odds.internationalHandicapOdds,
      uoArr: odds.internationalUnderOverOdds
    });

    return result;
  }

  @computed
  get sortedHomeScores() {
    const { teams } = this;
    const { home } = teams;

    return home.periodData.length > 0
      ? home.periodData.sort((a, b) => a.period - b.period)
      : [];
  }

  @computed
  get sortedAwayScores() {
    const { teams } = this;
    const { away } = teams;

    return away.periodData.length > 0
      ? away.periodData.sort((a, b) => a.period - b.period)
      : [];
  }

  @computed
  get statusText() {
    const { gameStatus, period, teams } = this;
    let statusText = '';

    if (gameStatus === 'CANCEL') {
      statusText = '취소';
    } else if (gameStatus === 'DELAY' || gameStatus === 'POSTPONED') {
      statusText = '연기';
    } else if (gameStatus === 'BREAK_TIME') {
      statusText = 'HT';
    } else if (gameStatus === 'PAUSE') {
      statusText = '중지';
    } else if (gameStatus === 'PENDING' || gameStatus === 'CUT') {
      statusText = '중단';
    } else if (gameStatus === 'READY') {
      statusText = '대기중';
    } else if (gameStatus === 'FINAL') {
      statusText = '종료';
    } else if (gameStatus === 'IN_PROGRESS') {
      let setText = 0;

      const checkHomePeriodData =
        teams.home.periodData.filter((item) => item.period === period).length >
        0;
      const checkAwayPeriodData =
        teams.away.periodData.filter((item) => item.period === period).length >
        0;

      if (checkHomePeriodData && checkAwayPeriodData) {
        setText = period;
      } else {
        setText = period - 1;
      }

      statusText = `${setText}set`;
    }

    return statusText;
  }

  @computed
  get volleyBallPeriodScore() {
    const { teams, period, gameStatus } = this;
    const home = {
      score: 0
    };
    const away = {
      score: 0
    };
    const check =
      gameStatus === 'FINAL' ||
      gameStatus === 'IN_PROGRESS' ||
      gameStatus === 'BREAK_TIME';

    function getScoreSum(team, p) {
      if (!!team.periodData) {
        if (team.periodData.length > 0) {
          let filterdPeroid = team.periodData.filter(
            (item) => item.period === p
          );

          if (filterdPeroid.length > 0) {
            return filterdPeroid.reduce((a, b) => {
              return a + b.score;
            }, 0);
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }

    if (teams) {
      home.score = check ? getScoreSum(teams.home, period) : 0;
      away.score = check ? getScoreSum(teams.away, period) : 0;
    }

    return { home, away };
  }

  @computed
  get betbleButtonVisible() {
    const { betbleUri, getTracker } = this;

    return !!betbleUri && getTracker.status !== 'final';
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  updateGame(data, isFavoriteActivePrompt, isFavorite, isBetbleLive = false) {
    for (const [key, value] of Object.entries(data)) {
      if (key === 'gameStatus') {
        const { league, statusText, id, teams, period, gameStatus } = this;

        if (period >= 3) {
          if (
            gameStatus !== 'FINAL' &&
            value === 'FINAL' &&
            teams.home.periodData.some((item) => item.period === period)
          ) {
            let result = { home: 0, away: 0 };

            for (let i = 0; i < period; i++) {
              if (
                teams.home.periodData[i].score > teams.away.periodData[i].score
              ) {
                result.home++;
              } else {
                result.away++;
              }
            }

            let location = result.home > result.away ? 'home' : 'away';

            // if (!isBetbleLive) {
            //   if (isFavoriteActivePrompt) {
            //     if (isFavorite) {
            //       this.addPrompt(
            //         id,
            //         { name: teams.home.name, score: result.home },
            //         { name: teams.away.name, score: result.away },
            //         location,
            //         league,
            //         statusText
            //       );
            //     }
            //   } else {
            //     this.addPrompt(
            //       id,
            //       { name: teams.home.name, score: result.home },
            //       { name: teams.away.name, score: result.away },
            //       location,
            //       league,
            //       statusText
            //     );
            //   }
            // }
          }
        }

        this[key] = value;
      } else if (key === 'teams') {
        this.teams = {
          ...this.teams,
          ...value,
          home: {
            ...this.teams.home,
            ...value.home
          },
          away: {
            ...this.teams.away,
            ...value.away
          }
        };
      } else {
        if (key === 'period') {
          const { period } = this;

          if (period > 0 && value > period) {
            const { league, statusText, id, teams } = this;
            let result = { home: 0, away: 0 };

            for (let i = 0; i < period; i++) {
              if (
                teams.home.periodData[i].score > teams.away.periodData[i].score
              ) {
                result.home++;
              } else {
                result.away++;
              }
            }

            let location = result.home > result.away ? 'home' : 'away';

            // if (isFavoriteActivePrompt) {
            //   if (isFavorite) {
            //     this.addPrompt(
            //       id,
            //       { name: teams.home.name, score: result.home },
            //       { name: teams.away.name, score: result.away },
            //       location,
            //       league,
            //       statusText
            //     );
            //   }
            // } else {
            //   this.addPrompt(
            //     id,
            //     { name: teams.home.name, score: result.home },
            //     { name: teams.away.name, score: result.away },
            //     location,
            //     league,
            //     statusText
            //   );
            // }

            this.setGoalEvent(location);
          }
        }

        this[key] = value;
      }
      // console.log(`${key}: ${value}`);
    }
  }

  @action
  updatePeriodData(data) {
    // console.log('페리오드 업데이트 = ', data);
    // const { period } = this;
    // const { home, away } = this.getTeamData;
    // const total = home.score + away.score;

    // let nextHomeSet = 0;
    // let nextAwaySet = 0;

    // for (let i = 0; i < period - 1; i++) {
    //   if (data.home[i].score > data.away[i].score) {
    //     nextHomeSet++;
    //   } else {
    //     nextAwaySet++;
    //   }
    // }

    // const next = {
    //   home: nextHomeSet,
    //   away: nextAwaySet
    // };
    // const nextTotal = next.home + next.away;

    // console.log(`prev = total: ${total}, home: ${home.score}, away: ${away.score}`)
    // console.log(`next = total: ${nextTotal}, home: ${next.home}, away: ${next.away}`)
    // console.log(`==================================================================`)

    // if (nextTotal > total) {
    //   let location = 'home';

    //   if (next.home > home.score) {
    //     location = 'home';
    //   } else if (next.away > away.score) {
    //     location = 'away';
    //   }

    //   this.setGoalEvent(location);
    // }

    this.teams = {
      ...this.teams,
      home: {
        ...this.teams.home,
        periodData: data.home
      },
      away: {
        ...this.teams.away,
        periodData: data.away
      }
    };
  }

  @action
  setGoalEvent(location) {
    if (!this.goalEvent.isActive) {
      runInAction(() => {
        this.goalEvent.isActive = true;
        this.goalEvent.location = location;

        setTimeout(() => {
          runInAction(() => {
            this.goalEvent = {
              location: '',
              isActive: false
            };
          });
        }, 5000);
      });
    }
  }

  @action
  updateSpecial(data) {
    this.special = data;
  }

  @action
  updateLastBroadcast(data) {
    this.broadcast = data;
  }

  @action
  updatePeriodSpecial(data) {
    this.periodSpecial = data;
  }
}

export default VolleyballModel;
