import { extendObservable, computed, action, toJS } from 'mobx';
import autobind from 'autobind-decorator';
import StarcraftSetModel from './set';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import { _E_SPORTS_GAME_STATUS } from '../../../../../common/constants/sportsConstants';

@autobind
class StarcraftModel {
  constructor(data) {
    extendObservable(this, data);

    this.set = data.set.map((set) => {
      let setDeep = { ...set };
      return new StarcraftSetModel(setDeep);
    });
  }

  @computed
  get startDatetime() {
    const { gdate } = this;

    return new Date(gdate);
  }

  @computed
  get getStartDateTime() {
    const { gtime } = this;
    const date = gtime.split(':');

    return [date[0], date[1]].join(':');
  }

  @computed
  get getDate() {
    const { gdate, gtime } = this;

    return `${gdate}T${gtime}`;
  }

  @computed
  get getMatchGameTime() {
    return timeUtils.matchGameForamt(this.getDate);
  }

  @computed
  get getTeamData() {
    const { home_player, away_player, home_score, away_score } = this;
    let homeTribe = '';
    let awayTribe = '';

    if (home_player.tribe === 'T') {
      homeTribe = 'terran';
    } else if (home_player.tribe === 'P') {
      homeTribe = 'protoss';
    } else if (home_player.tribe === 'Z') {
      homeTribe = 'zerg';
    }

    if (away_player.tribe === 'T') {
      awayTribe = 'terran';
    } else if (away_player.tribe === 'P') {
      awayTribe = 'protoss';
    } else if (away_player.tribe === 'Z') {
      awayTribe = 'zerg';
    }

    return {
      home: {
        name: home_player.name,
        nameNick: `${home_player.name} (${home_player.nick})`,
        score: home_score,
        imgPath: home_player.img_path,
        tribe: homeTribe,
        orgTribe: home_player.tribe
      },
      away: {
        name: away_player.name,
        nameNick: `${away_player.name} (${away_player.nick})`,
        score: away_score,
        imgPath: away_player.img_path,
        tribe: awayTribe,
        orgTribe: away_player.tribe
      }
    };
  }

  @computed
  get statusText() {
    const { gstatus } = this;
    const eSportsGameStatus = _E_SPORTS_GAME_STATUS[gstatus];

    if (eSportsGameStatus === 'CANCEL') {
      return '취소';
    } else if (eSportsGameStatus === 'READY') {
      return '대기';
    } else if (eSportsGameStatus === 'FINAL') {
      return '종료';
    } else if (eSportsGameStatus === 'IN_PROGRESS') {
      return '경기중';
    }
  }

  @computed
  get getTracker() {
    const { gstatus } = this;
    const eSportsGameStatus = _E_SPORTS_GAME_STATUS[gstatus];

    if (
      eSportsGameStatus === 'IN_PROGRESS' ||
      eSportsGameStatus === 'BREAK_TIME' ||
      eSportsGameStatus === 'PAUSE'
    ) {
      return { status: 'progress', class: '' };
    } else if (
      eSportsGameStatus === 'FINAL' ||
      eSportsGameStatus === 'CUT' ||
      eSportsGameStatus === 'POSTPONED' ||
      eSportsGameStatus === 'PENDING' ||
      eSportsGameStatus === 'CANCEL' ||
      eSportsGameStatus === 'DELAY' ||
      eSportsGameStatus === 'SUSPENDED'
    ) {
      return { status: 'final', class: 'close' };
    } else {
      // READY
      return { status: 'ready', class: 'ready' };
    }
  }

  @computed
  get getTeamStat() {
    const { home_player, away_player } = this;

    let stat = {
      home: {
        draw: 0,
        gameCount: 0,
        lose: 0,
        win: 0,
        winPer: 0,
        vs: '',
        tribevs: '',
        recent: []
      },
      away: {
        draw: 0,
        gameCount: 0,
        lose: 0,
        win: 0,
        winPer: 0,
        vs: '',
        tribevs: '',
        recent: []
      }
    };

    if (+home_player.total_record.allcnt > 0) {
      stat.home.winPer = Math.round(
        (+home_player.total_record.wincnt / +home_player.total_record.allcnt) *
          100
      );
    }

    if (+away_player.total_record.allcnt > 0) {
      stat.away.winPer = Math.round(
        (+away_player.total_record.wincnt / +away_player.total_record.allcnt) *
          100
      );
    }

    if (home_player.recent_record.recentResults.length > 0) {
      stat.home.recent = home_player.recent_record.recentResults.map((item) =>
        setRecentItem(item)
      );
    }

    if (away_player.recent_record.recentResults.length > 0) {
      stat.away.recent = away_player.recent_record.recentResults.map((item) =>
        setRecentItem(item)
      );
    }

    function setRecentItem(item) {
      let value = '';
      let isWin = false;

      if (item === 'L') {
        value = '패';
      } else if (item === 'W') {
        value = '승';
        isWin = true;
      } else {
        value = item;
      }

      return { key: item, value, isWin };
    }

    stat = {
      ...stat,
      home: {
        ...stat.home,
        draw: home_player.total_record.drawcnt,
        win: home_player.total_record.wincnt,
        lose: home_player.total_record.losecnt,
        gameCount: home_player.total_record.allcnt,
        winPerText: `${stat.home.winPer}%`,
        vs: `${home_player.vs_record.wincnt}승${home_player.vs_record.losecnt}패`,
        tribevs: `${home_player.tribe_record.wincnt}승${home_player.tribe_record.losecnt}패`
      },
      away: {
        ...stat.away,
        draw: away_player.total_record.drawcnt,
        win: away_player.total_record.wincnt,
        lose: away_player.total_record.losecnt,
        gameCount: away_player.total_record.allcnt,
        winPerText: `${stat.away.winPer}%`,
        vs: `${away_player.vs_record.wincnt}승${away_player.vs_record.losecnt}패`,
        tribevs: `${away_player.tribe_record.wincnt}승${away_player.tribe_record.losecnt}패`
      }
    };

    return stat;
  }

  @computed
  get winnerTeam() {
    const { getTeamData } = this;
    const { home, away } = getTeamData;

    const homeScore = home.score;
    const awayScore = away.score;
    let team = 'draw';

    if (homeScore > awayScore) {
      team = 'home';
    } else if (homeScore < awayScore) {
      team = 'away';
    } else if (homeScore === awayScore) {
      team = 'draw';
    }

    return team;
  }

  @computed
  get betbleButtonVisible() {
    const { betbleUri, getTracker } = this;

    return !!betbleUri && getTracker.status !== 'final';
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  updateGame(data) {
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  }

  @computed
  get progressSet() {
    const set = this.set.find((value) => parseInt(value.sstatus) === 2) || null;

    return set ? set.snum : null;
  }

  @action
  updatePeriod(data) {
    const setIdx = this.set.findIndex((item) => item.snum === data.snum);

    if (setIdx < 0) {
      let deep = [...this.set];

      let setDeep = { ...data };

      deep.push(new StarcraftSetModel(setDeep));

      this.set = deep;
    } else {
      this.set[setIdx].updateSetDetail(data);
    }
  }
}

export default StarcraftModel;
