import React from "react";

const TermsUse = () => {
  return (
    <div className="wrap_main">
      <div className="doc-contents">
        <h1>General Terms of Use</h1>
        <h3 id="article1">1. WEBSITE CONTENT</h3>
        <p>
          <span className="head_sentence">
            1.1. Nature of the Service and our content.
          </span>{" "}
          The Website contains up-to-date information on sporting events, in
          particular sports results, fixtures, and other sports statistics and
          sports content ("our content"). Our content displayed on the Website
          is based on information provided by other independent sources (third
          parties) or collected by our efforts. Although we make every effort to
          regularly update and check our content displayed on the Website, we
          make no promises or guarantees about the Website and we encourage you
          to carefully compare the information collected on the Website also
          with original and other sources.
        </p>
        <p>
          <span className="head_sentence">
            1.2. Use of the site is at your own risk.
          </span>{" "}
          We operate the Website and provide our content with reasonable
          diligence and care that may be reasonably required from us and in the
          manner described in these Terms and Conditions. Your access to and use
          of the Website, and the use of any information that may be provided to
          you in connection with the Website are, however, at your sole choice,
          discretion, risk and for your personal use only. You may not use the
          Website or our content for commercial purposes.
        </p>
        <p>
          <span className="head_sentence">1.3. Registration.</span> We may
          require registration in order for you to access certain parts or
          features of the Website. Without registration, you may not be able to
          view and use all content and the features of the Website may be
          limited.
        </p>
        <p>
          <span className="head_sentence">1.4. Third Party Content.</span> We do
          not provide our content for external applications or resources that
          can be accessed from the Website. Any third party content available on
          the Website is not hosted on our servers, nor do we create or upload
          it to the server where it is hosted. Third party content is generally
          marked with an appropriate logo, icon or other third party identifier.
          We expressly exclude any liability associated with such content, its
          availability or the information contained therein.
        </p>
        <p>
          <span className="head_sentence">1.5. Display ads.</span> Unless agreed
          otherwise you agree that advertising, including third party
          advertising, may be displayed on the Service.
        </p>
        <p>
          <span className="head_sentence">
            1.6. Cookies and cookies consent management tool.
          </span>{" "}
          We inform you about details about cookies and collect your consent via
          a specific digital solution operated by a third party.
        </p>
        <p>
          <span className="head_sentence">1.7. Relationship to gambling.</span>{" "}
          The Website is not a gaming application or a gambling application. We
          do not operate games or gambling, therefore we do not accept or
          control your funds or other monies and do not participate in any
          gambling related transactions. The betting odds displayed on the
          Website are presented for reporting and comparison purposes. No
          communication or information posted on the Website constitutes a
          recommendation to participate in a game or to place a bet, nor does it
          constitute legal, tax or other similar advice in connection with
          gaming or gambling.
        </p>
        <p>
          <span className="head_sentence">1.8. Local Law.</span> We recommend
          that you comply with the applicable law of the country in which you
          are temporarily or permanently resident, a citizen and/or present.
        </p>
        <p>
          <span className="head_sentence">1.9. Content rights.</span> Texts,
          photographs, graphic works and other elements contained on the Website
          may be individually and/or as a whole (hereinafter collectively
          referred to as "Copyright Works") protected by copyright. Unless
          otherwise agreed in writing with us, the fair use of the Copyright
          Works may only occur to the extent and in the manner provided for by
          the applicable law. In particular, the use of copyright works in the
          form of reproduction (copying) for the purpose of direct or indirect
          economic gain, as well as the use in the form of distribution,
          lending, display or communication of the work to the public (including
          communication to the public via the Internet) is not permitted without
          our express consent.
        </p>
        <p>
          <span className="head_sentence">1.10. Protection Databases.</span>{" "}
          Content databases contained on the Website (hereinafter referred to as
          "Database Content") is protected by a special right of the database
          provider. Unless otherwise agreed with us in writing, the lawful use
          of the Database Content may only take place to the extent and in the
          manner provided for by the applicable law. In particular, no
          extraction (copying) or exploitation (making available to the public)
          of the Database Content or of a qualitatively or quantitatively
          substantial part thereof is permitted without our express consent.
        </p>
        <p>
          <span className="head_sentence">1.11. Illegal interventions.</span>{" "}
          You may not use any mechanism, tool, software or process that has or
          could adversely affect the operation of our facilities, the security
          of the Internet or other Internet users. You must not burden our
          server on which the Website is hosted with automated requests, nor
          assist any third party in such activity. You may not modify,
          disassemble, decompile or reverse engineer the Website in any way,
          unless otherwise required by applicable laws and regulations.
          Furthermore, you are not permitted to use our content available on the
          Website by embedding, aggregating, scraping or recreating it without
          our express consent, unless otherwise provided for by applicable laws
          and regulations.
        </p>
        <p>
          <span className="head_sentence">
            1.12. Consequences of violation of rights.
          </span>{" "}
          Your interference with copyright, trademark rights, or interference
          with the special rights of the database provider may result in civil,
          administrative, or criminal liability.
        </p>
        <h3 id="article2">
          2. CONCLUSION OF A CONTRACT FOR THE PROVISION OF SERVICES
        </h3>
        <p>
          <span className="head_sentence">2.1. Registration.</span> You will
          make a proposal to enter into a Service contract by completing the
          information in the registration form located on the website and by
          clicking on the "Create My Account" button or ("Registration
          Application"). You are responsible for ensuring that the information
          provided in the Registration Application is correct.
        </p>
        <p>
          <span className="head_sentence">2.2. Activation.</span> Following
          receipt of the Registration Application, we may accept your
          Registration Application and send to your e-mail address provided in
          the registration request ("User's address") the information necessary
          to make your user account active and operational. Upon activation of
          your account by you, the Service contract is concluded.
        </p>
        <p>
          <span className="head_sentence">
            2.3. Use of third-party registration.
          </span>{" "}
          If you use an existing registration with a third party (for example, a
          social network registration), you may send us a proposal to conclude a
          service contract by clicking on the relevant “SIGN IN” button with the
          logo, trademark and/or service name of the third party and by
          confirming registration and logging in through this third party.
          Following the delivery of the proposal for the conclusion of a service
          contract according to the previous sentence to us, you will be allowed
          to use the service. By allowing you to use the service, the service
          contract is concluded.
        </p>
        <p>
          <span className="head_sentence">
            2.4. Consent to the provision of the Service.
          </span>{" "}
          You agree that we may commence the provision of the Service under the
          Service contract immediately upon its conclusion, even before the
          expiry of the statutory withdrawal period.
        </p>
        <p>
          <span className="head_sentence">
            2.5. Cost of communication equipment.
          </span>{" "}
          You agree to the use of remote means of communication when entering
          into a Service contract. The costs you incur in using a means of
          remote communication in connection with the conclusion of a Service
          contract (e.g. the cost of connecting to the internet) will be borne
          by you separately and will not differ from the basic rate for the use
          of such means of communication.
        </p>

        <h3 id="article3">3. CONTENT OF THE SERVICE CONTRACT</h3>
        <p>
          <span className="head_sentence">3.1. Subject of the contract.</span>{" "}
          On the basis of the Service contract we will enable you to create a
          user account on our Website and use the content and features that are
          subject to registration and account activation (also referred to as
          "Service").
        </p>
        <p>
          <span className="head_sentence">3.2. Contents of the contract.</span>{" "}
          The Terms and Conditions form an integral part of the Service
          contract.
        </p>
        <p>
          <span className="head_sentence">3.3. Language of the contract.</span>{" "}
          The contract for the provision of Service shall be concluded in the
          English language.
        </p>

        <h3 id="article4">4. USER ACCOUNT</h3>
        <p>
          <span className="head_sentence">4.1. Account Protection.</span> Access
          to the user account is secured with a username and password or third
          party log in tool. You are required to maintain the confidentiality of
          the information necessary to access your user account and acknowledge
          that we are not liable for any breach of this obligation by you.
        </p>
        <p>
          <span className="head_sentence"> 4.2. User account functions.</span>{" "}
          With a user account, the user can use the Service in its entirety.
        </p>
        <p>
          <span className="head_sentence">
            4.3. Personalization of content.
          </span>{" "}
          You are entitled to adapt the content of the Service to your own
          needs, but always only within the scope of the setting options offered
          by the Service.
        </p>

        <h3 id="article5">5. OUR RESERVATIONS</h3>
        <p>
          <span className="head_sentence">
            5.1. Inability to provide the Service.
          </span>{" "}
          We may not provide the Service if the Service is prevented from being
          provided by difficulties on your part or on the part of others. In
          particular, we may not provide the Service if there are power outages,
          data network outages, other failures caused by third parties or acts
          of God.
        </p>
        <p>
          <span className="head_sentence">5.2. Service Outages.</span> Service
          outages, temporary limitations, interruptions, or reductions in
          Service quality may occur. Information stored by you under the Service
          may not be backed up by us, may be corrupted or otherwise degraded.
        </p>
        <p>
          <span className="head_sentence">
            5.3. Limitation of quality of Service.
          </span>{" "}
          To the extent permitted by law, we will not be liable for (i) any
          malfunction of any computer programs related to the Website, (ii)
          defects or viruses that result in loss of data, (iii) any other damage
          to your computer equipment, mobile phone or mobile device or software,
          (iv) errors (including errors in inputs, displayed data or results),
          and (v) any attempts by you to use the Website by methods, means or
          methods not intended by us. We reserve the right to suspend, modify,
          remove or supplement the Website in our sole discretion and to the
          extent permitted by law, and to suspend your use of the Website. We
          shall not be liable for any such action.
        </p>
        <p>
          <span className="head_sentence">5.4. Bugs.</span> You agree to notify
          us of any error within your user account on the Website or any error
          in any information displayed on the Website (including but not limited
          to miscalculations, misrepresentations, incorrect fees, commissions,
          bonuses or payouts, or currency conversions).
        </p>
        <p>
          <span className="head_sentence">5.5. Limitation of liability.</span>{" "}
          Neither we (including our officers, directors, agents and employees)
          nor any person associated with us shall be liable in contract, tort
          (including negligence) or otherwise for any direct, indirect,
          incidental, consequential, special, punitive, compensatory or
          exemplary damages, in particular, for loss of data, profits, revenue,
          business, opportunity, goodwill, reputation or business interruption
          or for any other loss which we could not have foreseen and which
          arises out of the Service contract or your use of the Website.
        </p>
        <p>
          <span className="head_sentence">5.6. Compensation.</span> The
          information on the Website is provided on an "as is" basis and you
          agree to release us from any and all liability in connection with the
          Website and the information contained on the Website. Notwithstanding
          the provisions of clause 5 of the Terms and Conditions, our liability
          is limited to the maximum extent permitted by applicable laws.
        </p>

        <h3 id="article6">6. PROHIBITED USE OF THE SERVICE</h3>
        <p>
          <span className="head_sentence">6.1. Unsolicited promotion.</span>{" "}
          With respect to privacy, information and cyber security requirements
          and to prevent fraud, it is expressly prohibited to post any
          information on the Website or contact our customers to offer or
          promote any offers, products or services.
        </p>
        <p>
          <span className="head_sentence">
            6.2. Anti-Fraud and Anti-Harassment Policy.
          </span>{" "}
          We have a zero-tolerance policy for inappropriate and fraudulent
          activities within the Website. If, in our sole discretion, we
          determine that you have attempted to fraud us and/or another user of
          the Website in any way, we reserve the right to suspend or terminate
          your user account or to prohibit you from accessing the Website for a
          specific or indefinite period of time. We shall not be liable for
          taking such action to the extent permitted by applicable law.
        </p>

        <h3 id="article7">7. DATA PROTECTION</h3>
        <p>
          <span className="head_sentence">
            7.1. Information on the processing of personal data.
          </span>{" "}
          We fulfill our information obligation within the meaning of Article 13
          of Regulation (EC) 2016/679 of the European Parliament and of the
          Council on the protection of natural persons with regard to the
          processing of personal data and on the free movement of such data, and
          repealing Directive 95/46/EC ("GDPR") by means of a special document
          designated as information on the processing of personal data ("Privacy
          Policy").
        </p>
        <p>
          <span className="head_sentence">7.2.</span> These Terms and Conditions
          should be read and interpreted in conjunction with our Privacy Policy,
          which is available on the Website.
        </p>

        <h3 id="article8">8. THE DURATION OF THE SERVICE CONTRACT</h3>
        <p>
          <span className="head_sentence">
            8.1. Effectiveness of the contract.
          </span>{" "}
          The Service contract shall become effective upon its conclusion. The
          Service contract is concluded for an indefinite period of time.
        </p>
        <p>
          <span className="head_sentence">
            8.2. Withdrawal from the contract.
          </span>{" "}
          From the moment of conclusion of the Contract, we shall immediately
          commence performance of the Service contract in connection with which
          the User's right to withdraw from the Service contract shall cease, to
          which the User agrees in accordance with these Terms and Conditions.
        </p>
        <p>
          <span className="head_sentence">8.3. Termination of contract.</span>{" "}
          You may terminate the Service contract at any time by effectively
          canceling your User Account. You may also terminate your Service
          contract by asking us to delete your personal data in accordance with
          the GDPR.
        </p>
        <p>
          <span className="head_sentence">
            8.4. Termination of contract by us.
          </span>{" "}
          We reserve the right to terminate the Service contract, if you breach
          any of your obligations under the Service contract (including the
          Terms and Conditions) or under applicable laws and regulations.
          Termination of the Service contract under this clause is effective
          when it is delivered to you, by electronic mail to your address.
          Unless otherwise agreed, the Service contract will terminate upon the
          effective date of such termination.
        </p>
      </div>
    </div>
  );
};

export default TermsUse;
