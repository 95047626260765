import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { MypageContext, useMypageStore } from '../../mypage/context';

const Container = styled.div`
  position: relative;
  width: 100%;
  font-family: sans-serif;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  gap: 8px;
  padding: 0px 16px;
  border: 1px solid #e4e4e4;
  background-color: white;
  min-height: 42px;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  min-width: 120px;
  background: transparent;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
`;

const SuggestionsList = styled.ul`
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 4px;
  padding: 0;
  list-style: none;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 10;
`;

const SuggestionItem = styled.li`
  padding: 10px 12px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #f1f5f9;
  }
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 8px;
  background-color: #f1f5f9;
  border-radius: 16px;
  font-size: 14px;
`;

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #64748b;

  &:hover {
    color: #ef4444;
  }
`;

const RemoveIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const ShowAllButton = styled.button`
  min-height: 42px;
  background: none;
  border: none;
  padding: 4px 8px;
  font-size: 14px;
  color: #3b82f6;
  cursor: pointer;
  border-radius: 4px;
  white-space: nowrap;
  background-color: #f1f5f9;
  font-weight: semi-bold;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const INITIAL_PAGE = 1;
const MAX_FRIENDS_NUMBER = 1000;

export default function AutoSuggestInput({ placeholder = '선물 받는 사람', onChange, onRemove }) {
  const { mypageStore } = useMypageStore(MypageContext);

  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const lastClickShowAllRef = useRef(0);

  useEffect(() => {
    mypageStore
      .actionFriendList({ searchType: 'all', page: INITIAL_PAGE, limit: MAX_FRIENDS_NUMBER })
      .then((res) => {});
  }, []);

  useEffect(() => {
    if (inputValue.trim() === '' && !showSuggestions) {
      setFilteredSuggestions([]);
      return;
    }

    const filtered = mypageStore.friends
      .map((item) => ({ id: item?.mb_id, label: item?.mb_nick, mb_nick: item?.mb_nick }))
      .filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    setFilteredSuggestions(filtered);
  }, [inputValue, mypageStore.friends]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Prevent closing suggestions when clicking on the show all button
      const timeDiff = Date.now() - lastClickShowAllRef.current;
      if (timeDiff < 300) {
        return;
      }

      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setShowSuggestions(true);
    if (onChange) {
      onChange(value);
    }
  };

  const handleSelectSuggestion = (item) => {
    setSelectedItem(item);
    setInputValue('');
    setShowSuggestions(false);
    if (onChange) {
      onChange(item?.mb_nick);
    }
  };

  const handleRemoveItem = () => {
    if (selectedItem) {
      if (onRemove) {
        onRemove(selectedItem);
      }
      if (onChange) {
        onChange('');
      }
      setSelectedItem(null);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setShowSuggestions(false);
    }
  };

  const handleClearInput = () => {
    setInputValue('');
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleShowAll = (e) => {
    lastClickShowAllRef.current = Date.now();
    setFilteredSuggestions(
      mypageStore.friends.map((item) => ({ id: item?.mb_id, label: item?.mb_nick, mb_nick: item?.mb_nick }))
    );
    setShowSuggestions(true);
  };

  return (
    <Container ref={containerRef}>
      <RowContainer>
        <InputWrapper>
          {selectedItem ? (
            <Chip>
              {selectedItem.label}
              <RemoveButton onClick={handleRemoveItem} aria-label='Remove item'>
                <RemoveIcon src='/images/ic-close.svg' alt='Clear' />
              </RemoveButton>
            </Chip>
          ) : (
            <InputContainer>
              <Input
                ref={inputRef}
                type='text'
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={selectedItem ? '' : placeholder}
                aria-label='Auto-suggest input'
              />
              {inputValue && (
                <RemoveButton onClick={handleClearInput} aria-label='Remove item'>
                  <RemoveIcon src='/images/ic-close.svg' alt='Clear' />
                </RemoveButton>
              )}
            </InputContainer>
          )}
        </InputWrapper>
        <ShowAllButton onClick={handleShowAll} type='button'>
          친구목록
        </ShowAllButton>
      </RowContainer>
      {showSuggestions && filteredSuggestions.length > 0 && (
        <SuggestionsList>
          {filteredSuggestions.map((item) => (
            <SuggestionItem key={item.id} onClick={() => handleSelectSuggestion(item)}>
              {item.label}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
    </Container>
  );
}
