import React from 'react';
import { Div, H2, Ol, Li, P } from './styled';

const YouthPolicy = () => {
  return (
    <div className="wrap_main">
      <div className="doc-contents">
        <Div>
          <P>
            (주)KJ Property (이하 “회사”라 함)는 청소년이
            건전한 인격체로 성장할 수 있도록 하기 위하여
            정보통신망이용촉진및정보보호등에관한법률 및 청소년보호법에 근거하여
            청소년 보호정책을 수립, 시행하고 있습니다.
          </P>
          <P>
            회사는 방송통신심의위원회의 정보통신에 관한 심의규정 및
            청소년보호법상의 청소년유해매체물 심의기준 등에 따라 19세 미만의
            청소년들이 유해정보에 접근할 수 없도록 방지하고 있는 바, 본 청소년
            보호정책을 통하여 회사가 청소년보호를 위해 어떠한 조치를 취하고
            있는지 알려드립니다.
          </P>

          <H2>1. 유해정보에 대한 청소년접근제한 및 관리조치</H2>
          <P>
            회사는 청소년이 아무런 제한장치 없이 청소년 유해정보에 노출되지
            않도록 청소년유해매체물에 대해서는 별도의 인증장치를 마련, 적용하며
            청소년 유해정보가 노출되지 않기 위한 예방차원의 조치를 강구합니다.
          </P>

          <H2>2. 유해정보로부터의 청소년보호를 위한 업무 담당자 교육 시행</H2>
          <P>
            회사는 정보통신업무 종사자를 대상으로 청소년보호 관련 법령 및
            제재기준, 유해정보 발견시 대처방법, 위반사항 처리에 대한 보고절차
            등을 교육하고 있습니다.
          </P>

          <H2>3. 유해정보로 인한 피해상담 및 고충처리</H2>
          <P>
            회사는 청소년 유해정보로 인한 피해상담 및 고충처리를 위한 전문인력을
            배치하여 그 피해가 확산되지 않도록 하고 있습니다.
          </P>

          <P>
            [시행일] 본 이용약관은 2012년 11월 21일부터 적용되며, 2010년 01월
            01일부터 적용되던 종전약관은 본 약관으로 대체됩니다.
          </P>
          <P>
            개정된 이용약관의 적용일자 이전 가입자 또한 개정된 이용약관의 적용을
            받습니다.
          </P>
        </Div>
      </div>
    </div>
  );
};

export default YouthPolicy;
