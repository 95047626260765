import { extendObservable, computed, createAtom, action } from 'mobx';
import autobind from 'autobind-decorator';
import LolPlayerModel from '../player';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { sortPlayers } from '../../util';
import LolBanModel from '../ban';
import { _E_SPORTS_GAME_STATUS } from '../../../../../../common/constants/sportsConstants';
import { timeUtils } from '../../../../../../common/utils/timeUtil';
import { StringUtil } from '../../../../../../common/utils/stringUtil';

@autobind
class LolSetModel {
  progressTime;
  intervalHandler = null;

  lolElapsedMin;

  constructor(data) {
    extendObservable(this, data);
    this.sortPlayerByTeamPosition();

    if (
      _E_SPORTS_GAME_STATUS[data.sstatus] === 'IN_PROGRESS' &&
      !!data.startTime
    ) {
      this.progressTime = createAtom(
        'ProgressTime',
        () => this.startTicking(),
        () => this.stopTicking()
      );
    }
  }

  startTicking() {
    this.tick();
    this.intervalHandler = setInterval(() => this.tick(), 1000);
  }

  stopTicking() {
    clearInterval(this.intervalHandler);
    this.intervalHandler = null;
  }

  tick() {
    const { startTime } = this;

    const timestamp = differenceInSeconds(
      Date.now(),
      new Date(`${timeUtils.todayYmd()}T${startTime}` + '+09:00')
    );

    let minutes = Math.floor(timestamp / 60);
    let seconds = timestamp % 60;

    const format = `${StringUtil.pad(minutes, 2, '0')}:${StringUtil.pad(
      seconds,
      2,
      '0'
    )}`;

    this.progressTime.reportChanged();

    this.lolElapsedMin = format;
  }

  getLolTime() {
    if (this.progressTime.reportObserved()) {
      return this.lolElapsedMin;
    } else {
      return '00:00';
    }
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get displayTime() {
    const { sstatus, totalTime, isPause, pauseTime, startTime } = this;
    const splitTime = !!totalTime ? totalTime.split(':') : '00:00';

    if (_E_SPORTS_GAME_STATUS[sstatus] === 'READY') {
      return '';
    } else if (_E_SPORTS_GAME_STATUS[sstatus] === 'IN_PROGRESS') {
      if (isPause) {
        const timestamp = differenceInSeconds(
          new Date(pauseTime + '+09:00'),
          new Date(`${timeUtils.todayYmd()}T${startTime}` + '+09:00')
        );

        let minutes = Math.floor(timestamp / 60);
        let seconds = timestamp % 60;

        const format = `${StringUtil.pad(minutes, 2, '0')}:${StringUtil.pad(
          seconds,
          2,
          '0'
        )}`;

        return format;
      } else {
        return this.getLolTime();
      }
    } else if (_E_SPORTS_GAME_STATUS[sstatus] === 'FINAL') {
      if (Array.isArray(splitTime)) {
        splitTime.shift();

        return splitTime.join(':');
      } else {
        return splitTime;
      }
    } else if (_E_SPORTS_GAME_STATUS[sstatus] === 'CANCEL') {
      return '00:00';
    }
  }

  @computed
  get win() {
    const { sstatus, winner } = this;

    let result = {
      home: false,
      away: false
    };

    if (_E_SPORTS_GAME_STATUS[sstatus] === 'FINAL') {
      if (winner === 'h') {
        result.home = true;
      } else {
        result.away = true;
      }
    }

    return result;
  }

  @computed
  get killScore() {
    const { away, home, sstatus } = this;

    if (_E_SPORTS_GAME_STATUS[sstatus] === 'READY') {
      return '-';
    } else {
      return `${home.kill} - ${away.kill}`;
    }
  }

  @computed
  get getSetTeamData() {
    const {
      away,
      home,
      first10Kill,
      firstBaron,
      firstBlood,
      firstDragon,
      firstTower
    } = this;

    return {
      home: {
        name: home.team.name_en,
        imgPath: home.team.img_path,
        players: home.players,
        banPicks: home.banPicks,
        baron: home.baron,
        dragon: home.dragon,
        gold: `${home.gold}K`,
        tower: home.tower,
        first10Kill: first10Kill === 'b',
        firstBaron: firstBaron === 'b',
        firstBlood: firstBlood === 'b',
        firstDragon: firstDragon === 'b',
        firstTower: firstTower === 'b'
      },
      away: {
        name: away.team.name_en,
        imgPath: away.team.img_path,
        players: away.players,
        banPicks: away.banPicks,
        baron: away.baron,
        dragon: away.dragon,
        gold: `${away.gold}K`,
        tower: away.tower,
        first10Kill: first10Kill === 'r',
        firstBaron: firstBaron === 'r',
        firstBlood: firstBlood === 'r',
        firstDragon: firstDragon === 'r',
        firstTower: firstTower === 'r'
      }
    };
  }

  @action
  sortPlayerByTeamPosition() {
    this.home.players = sortPlayers(this.home.players);
    this.away.players = sortPlayers(this.away.players);
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  updateSetDetail(data) {
    for (const [key, value] of Object.entries(data)) {
      if (key === 'gidx') {
        this.id = value;
      } else if (key === 'sstatus') {
        if (
          _E_SPORTS_GAME_STATUS[value] === 'IN_PROGRESS' &&
          !!data.startTime
        ) {
          if (typeof this.progressTime === 'undefined') {
            this.progressTime = createAtom(
              'ProgressTime',
              () => this.startTicking(),
              () => this.stopTicking()
            );
          }
        }
        this.sstatus = value;
      } else {
        this[key] = value;
      }
    }
    this.sortPlayerByTeamPosition();
  }
}

export default LolSetModel;
