import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import useCommonStore from '../../hooks/useCommonStore';

const RouteGuard = observer((props) => {
  const commonStore = useCommonStore();
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    commonStore.isLogin().then((res) => {
      if (res.code === 200) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!commonStore.loginCheck) {
      commonStore.isLogin().then((res) => {
        if (res.code === 200) {
          setIsLogin(true);
        } else {
          setIsLogin(false);
        }
      });
    }
  }, [commonStore.loginCheck]);

  if (isLogin) {
    return <Route {...props} />;
  } else {
    return <Redirect to={'/auth/login'} />;
  }
});

export default RouteGuard;
