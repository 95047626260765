import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageMarketList } from '../components';
import { MypageContext, useMypageStore } from '../context';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { RegexUtil } from '../../../common/utils/regexUtil';
import { CategoryList } from '../../../style/myPageStyled';
import { Amass, Cash, ChargeButton, Asset_box } from './styled';
import Seo from '../../../components/seo';
import { Url } from "../../../common/constants/environment";

const Market = observer(({ history }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const category = query.get('category') || 'all';

  const { mypageStore } = useMypageStore(MypageContext);

  useEffect(() => {
    mypageStore.actionMarketList({
      category: category === 'all' ? '' : category
    });
  }, [category]);

  const categoryList = [
    { key: 'all', name: '전체' },
    { key: 'points', name: '포인트' },
    { key: 'medal', name: '건빵' },
    { key: 'chat', name: '채팅' },
    { key: 'random', name: '랜덤' },
    { key: 'community', name: '커뮤니티' },
    { key: 'etc', name: '기타' },
    // { key: 'theme', name: '테마방' },
    // { key: 'package', name: '패키지' },
  ];

  const onClickCharge = () => {
    window.location = '/mypage/cash/request-charge'
  };

  return (
    <>
      <Seo title={'마이페이지 마켓'} />
      <Cash>
        <Asset_box>
          <Amass>
            <dt>보유 캐시</dt>
            <dd className={'cash'}>{RegexUtil.makeComma(mypageStore.data?.total_cash ?? "0")}</dd>
          </Amass>
          <Amass>
            <dt>건빵</dt>
            <dd className={'medal'}>{RegexUtil.makeComma(mypageStore.data?.total_medal ?? "0")}</dd>
          </Amass>
          <Amass>
            <dt>포인트</dt>
            <dd className={'point'}>{RegexUtil.makeComma(mypageStore.data?.total_point ?? "0")}</dd>
          </Amass>
        </Asset_box>
        <ChargeButton onClick={onClickCharge}>충전하기</ChargeButton>
      </Cash>
      <CategoryList>
        {categoryList.map((item, key) => {
          return (
            <li key={key}>
              <Link
                to={`${location.pathname}?&category=${item.key}`}
                className={clsx(category === item.key && 'selected')}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </CategoryList>
      <MypageMarketList items={mypageStore.data.list} history={history} />
    </>
  );
});

export default Market;
