import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { timeUtils } from '../../../../common/utils/timeUtil';
import { StringUtil } from '../../../../common/utils/stringUtil';
import useModalStore from '../../../../hooks/useModalStore';
import useUsersStore from '../../../../hooks/useUsersStore';
import clsx from 'clsx';
// import BannerGA from '../../../../components/banner/BannerGA';

const CommunityPhotoList = ({ items = [] }) => {
  const modalStore = useModalStore();
  const userStore = useUsersStore();
  const isAdmin = userStore.data.level === 'mp';
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;
  const category = location.pathname.replace('/community/', '');

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (items.length > 0) {
      setList(items);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [items]);

  return (
    <div className="list_area">
      {!isLoading &&
        list.map((item, i) => {
          const {
            wr_id,
            thumbnailCategoryPhoto,
            wr_subject,
            wr_name,
            time,
            wr_hit,
            wr_datetime,
            wr_good
          } = item;
          const isBlind = (parseInt(item.report_count) || 0) >= 10;
          const isWarning =
            (parseInt(item.report_count) || 0) >= 5 &&
            (parseInt(item.report_count) || 0) < 10;

          return (
            <>
              <div className="article" key={wr_id}>
                <Link
                  className="item"
                  to={`/community/${category}?page=${page}&id=${wr_id}&type=photo`}
                  onClick={(event) => {
                    window.reflowOnFocus();
                    if (!isAdmin && isBlind) {
                      event.preventDefault();
                      modalStore.openAlertModal({
                        message: '블라인드 처리된 게시물은 열람하실 수 없습니다.'
                      });
                    }
                  }}
                >
                  <div className="thumb">
                    <div className="inner">
                      {isBlind ? (
                        <img className="icon blind" src="/images/ic-blind.svg" />
                      ) : (
                        <img
                          width="100%"
                          className={clsx(
                            'ct_m',
                            'fade',
                            'loaded',
                            'change',
                            isWarning && 'warning'
                          )}
                          src={thumbnailCategoryPhoto}
                        />
                      )}
                      {isWarning && (
                        <img
                          className="icon warning"
                          src="/images/ic-warning.svg"
                        />
                      )}
                    </div>
                  </div>
                  <p className={clsx('subject', isBlind && 'blinded')}>
                    {isBlind ? '*블라인드 처리된 게시물*' : wr_subject}
                  </p>
                  <Info>
                    <em>{wr_name}</em>
                    <div>
                      <span>
                        {timeUtils.getDifferenceDay(wr_datetime.split(' ')[0]) ===
                          0
                          ? time
                          : StringUtil.replace(
                            '-',
                            '.',
                            wr_datetime.split(' ')[0]
                          )}
                      </span>
                      <span className={'like'}>{wr_good}</span>
                      <span className={'hit'}>{wr_hit}</span>
                    </div>
                  </Info>
                </Link>
              </div>

              {/* NEW ADS #4 */}
              {/* {i === 3 && (
                <div key={i}>
                  <BannerGA
                    containerStyle={{
                      display: 'block',
                      width: 450,
                      height: 50,
                      marginTop: 16,
                    }}
                    gaSlot={'1817385919'}
                    width={450}
                    height={50}
                  />
              </div>
              )} */}
              
            </>
          );
        })}
    </div>
  );
};

const Info = styled.div`
  display: flex;
  flex-direction: column;
  em {
    font-size: 12px;
    color: #222;
  }
  > div {
    display: flex;
  }
  span {
    font-size: 12px;
    color: #999;
    margin-right: 8px;
    display: flex;
    align-items: center;
    &.like {
      :before {
        content: '';
        width: 9px;
        height: 9px;
        display: inline-block;
        background: url('/images/ic-like.svg') no-repeat center center;
        background-size: 9px;
        margin-right: 5px;
      }
    }
    &.hit {
      :before {
        content: '';
        width: 14px;
        height: 14px;
        display: inline-block;
        background: url('/images/ic-hit.svg') no-repeat center center;
        background-size: 14px;
        margin-right: 5px;
      }
    }
  }
`;

export { CommunityPhotoList };
