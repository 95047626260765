import React from 'react';
import { observer } from 'mobx-react';
import { MypageFriendsBlack } from '../components';
import Seo from '../../../components/seo';

const BlackList = observer(({ history }) => {
  return (
    <>
      <Seo title={'마이페이지 차단'} />
      <div style={{ clear: 'both' }}>
        <MypageFriendsBlack history={history} />
      </div>
    </>
  );
});

export default BlackList;
