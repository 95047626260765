import React from 'react';

const BoardListHtml = () => {
  return (
    <>
      <div className="board_wrap">

        <div className="snb_wrap">
          <ul>
            <li><a className="item selected" href="javascript:;">전체</a></li>
            <li><a className="item" href="javascript:;">베스트</a></li>
            <li><a className="item" href="javascript:;">내글</a></li>
            <li><a className="item" href="javascript:;">내댓글</a></li>
          </ul>
        </div>

        <div className="board_list">
          <div className="list_area">
            <div className="article new">
              <a className="comment" href="javascript:;">
                <strong className="num">3</strong>
                <span className="tx">댓글</span>
              </a>
              <a className="thumb" href="javascript:;">
                <img src="https://search.pstatic.net/common/?src=http%3A%2F%2Fblogfiles.naver.net%2FMjAyMDEwMjNfMjIw%2FMDAxNjAzNDYyMzg3ODQ1.ETvh_WAPbbginwGduT3Y3YwUkXCkMkdI9TruT6WwrkYg.AWofo4mM7iTCG7wGGDOvwhSZgQn8E7nuOtib7jB0scIg.JPEG.wjswldbd126%2F8.JPG%23900x600&type=ff192_192"/>
              </a>
              <a className="item" href="javascript:;">
                <p className="subject">소개팅에서 인생영화가 뭐냐는 질문생영화가 뭐냐는 질문생영화가 뭐냐는 질문생영화가 뭐냐는 질문생영화가 뭐냐는 질문을 받는 다면 당신은</p>
                <p className="info">
                  <span className="category">자유</span>
                  <span className="name">플란체</span>
                  <span className="date">10:27</span>
                  <span className="count">조회 386</span>
                </p>
              </a>
            </div>
            <div className="article new">
              <a className="comment" href="javascript:;">
                <strong className="num">3</strong>
                <span className="tx">댓글</span>
              </a>
              <a className="item" href="javascript:;">
                <p className="subject">소개팅에서 인생영화을 받는 다면 당신은</p>
                <p className="info">
                  <span className="category">자유</span>
                  <span className="name">플란체</span>
                  <span className="date">10:27</span>
                  <span className="count">조회 386</span>
                </p>
              </a>
            </div>
            <div className="article">
              <a className="comment" href="javascript:;">
                <strong className="num">3</strong>
                <span className="tx">댓글</span>
              </a>
              <a className="item" href="javascript:;">
                <p className="subject">소개팅에서 인생영화을 받는 다면 영화을 받는 다면 영화을 받는 다면 영화을 받는 다면 영화을 받는 다면 당신은</p>
                <p className="info">
                  <span className="category">자유</span>
                  <span className="name">플란체</span>
                  <span className="date">10:27</span>
                  <span className="count">조회 386</span>
                </p>
              </a>
            </div>
          </div>
          <button type="button" className="btn_more">더보기</button>
        </div>
      </div>
    </>
  );
};

export default BoardListHtml;
