import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import { StringUtil } from '../../../../../common/utils/stringUtil';
import clsx from 'clsx';
import useModalStore from '../../../../../hooks/useModalStore';

const CommunityRow = ({ item, category, page, isAdmin }) => {
  const modalStore = useModalStore();
  const {
    isThumb,
    thumbnail,
    time,
    board_type,
    wr_id,
    wr_subject,
    wr_name,
    wr_hit,
    wr_comment,
    wr_datetime,
    wr_good,
    bo_name
  } = item;

  const isBlind = (parseInt(item.report_count) || 0) >= 10;

  return (
    <div className="article">
      <Link
        className={'item_link'}
        to={`/community/${category}?page=${page}&id=${wr_id}&type=${board_type}`}
        onClick={(event) => {
          window.reflowOnFocus();
          if (!isAdmin && isBlind) {
            event.preventDefault();
            modalStore.openAlertModal({
              message: '블라인드 처리된 게시물은 열람하실 수 없습니다.'
            });
          }
        }}
      >
        <div className="comment">
          <strong className="num">{wr_comment}</strong>
          <span className="tx">댓글</span>
        </div>
        {isThumb && (
          <div className="thumb">
            <img
              src={thumbnail}
              alt="thumbnail"
              onError={(event) => {
                event.currentTarget.parentElement.style.display = 'none';
              }}
            />
          </div>
        )}
        <div className="item">
          <p className={clsx('subject', isBlind && 'blinded')}>
            {isBlind
              ? '*블라인드 처리된 게시물*'
              : (category === 'recent' ? `[${bo_name}] ` : '') + wr_subject}
          </p>
          {board_type === 'sports_analysis' && (
            <p
              className={'subject sub'}
            >{`${item.home_team} vs ${item.away_team}`}</p>
          )}
          <Info>
            <em>{wr_name}</em>
            <div>
              <span>
                {!!wr_datetime &&
                  (timeUtils.getDifferenceDay(wr_datetime.split(' ')[0]) === 0
                    ? time
                    : StringUtil.replace('-', '.', wr_datetime.split(' ')[0]))}
              </span>
              <span className={'like'}>{wr_good}</span>
              <span className={'hit'}>{wr_hit}</span>
            </div>
          </Info>
        </div>
      </Link>
    </div>
  );
};

const Info = styled.div`
  width: calc(100% - 110px);
  > div {
    display: flex;
  }
  span {
    font-size: 12px;
    color: #999;
    margin-right: 8px;
    display: flex;
    align-items: center;
    &.like {
      :before {
        content: '';
        width: 9px;
        height: 9px;
        display: inline-block;
        background: url('/images/ic-like.svg') no-repeat center center;
        background-size: 9px;
        margin-right: 5px;
      }
    }
    &.hit {
      :before {
        content: '';
        width: 14px;
        height: 14px;
        display: inline-block;
        background: url('/images/ic-hit.svg') no-repeat center center;
        background-size: 14px;
        margin-right: 5px;
      }
    }
  }
  em {
    font-size: 12px;
    color: #222;
  }
`;

export { CommunityRow };
