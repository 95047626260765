import React, { useEffect } from 'react';
// import style from './style.module.scss';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import useUsersStore from '../../../hooks/useUsersStore';

const AuthResult = observer(({ match, location, history }) => {
  const usersStore = useUsersStore();
  const { mypageStore } = useMypageStore(MypageContext);
  const queryString = new URLSearchParams(location.search);

  useEffect(() => {
    mypageStore.actionAuthResult(
      {
        type: match.params && match.params.type,
        token: queryString.get('certify_token')
      },
      async () => {
        await usersStore.getInfo();
        history.replace('/mypage/info');
      }
    );
  }, []);

  return <></>;
});

export default AuthResult;
