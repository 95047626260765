import Api from "../../api";

class ClockService extends Api {
  constructor() {
    super();
  }

  /**
   * @description 시간 조회
   * @returns
   */
  async getTime() {
    const res = await this.ajax('head', '', `/sync.json`);

    return res;
  }
}

export default new ClockService();