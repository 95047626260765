import React, { useEffect, useState } from 'react';
import { ThemeService } from '../../../../common/services';
import { timeUtils } from '../../../../common/utils/timeUtil';
import { Link } from 'react-router-dom';
import { Url } from '../../../../common/constants/environment';

const RoomChatList = ({ roomType }) => {
  const [data, setData] = useState([]);

  const roomFileName = {
    lottery: `${Url.dataJsonUrl}/json/chat/chat_lottery_rank.json`,
    rball: `${Url.dataJsonUrl}/json/chat/chat_fxgame_rank.json`,
    // holdem: '/data/json/frame_v2/chat_left_rank.json'
  };

  const getRoomList = () => {
    ThemeService.getRoomChatList(roomFileName[roomType]).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getRoomList();

    let interval = null;
    interval = setInterval(() => {
      getRoomList();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getRoomChatThumb = (path) => {
    return path.replace('/data', Url.thumb + '/normal/crop/150x150/');
  };

  return (
    <>
      <div className='game_roomchat_area'>
        <div className='room_list_wrap'>
          {data &&
            data?.map((item, i) => {
              return (
                <div className='item' key={i}>
                  <a href={Url.namedWeb + '/nlivechat/chat_room.php?room_uid=' + item.room_uid} target={'_blank'}>
                    <img className='thumb' src={getRoomChatThumb(item.profile_img)} />
                    <p className='tit'>{item.title}️</p>
                    <p className='msg'>{item.room_topic}</p>
                  </a>
                  <div className='info'>
                    <a
                      className='user'
                      href={Url.namedWeb + '/nlivechat/chat_room.php?room_uid=' + item.room_uid}
                      target={'_blank'}
                    >
                      {item.opener_name}
                    </a>
                    <span className='date'>{item.disp_time}</span>
                    <span className='count'>{item.disp_cur_user}</span>
                  </div>
                </div>
              );
            })}
          {/* // room_list_wrap */}
        </div>
      </div>
    </>
  );
};

export { RoomChatList };
