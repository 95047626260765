import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react';
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock"

import useModalStore from '../../../hooks/useModalStore';

Modal.setAppElement('#root');

const ConfirmModal = observer(forwardRef(({ option }, ref) => {
  const modalRef = useRef()

  useImperativeHandle(ref, () => modalRef.current, [modalRef])

  const modalStore = useModalStore();

  const isOpen = option?.isOpen;

  useEffect(() => {
    if (!isOpen) {
      enableBodyScroll(modalRef.current)
    } else {
      disableBodyScroll(modalRef.current)
    }
  }, [isOpen])

  if (!option) {
    return null;
  }

  return (
    <Modal
      ref={modalRef}
      isOpen={option.isOpen}
      onRequestClose={() => {
        modalStore.closeModal();
      }}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)'
        }
      }}
      overlayClassName="modal_overlay"
    >
      <div className="confirm" ref={ref}>
        <div>
          <h2>{option.title}</h2>
        </div>
        <div style={{ textAlign: 'center' }}>
          <ul>
            {option.message.split('\n').map((value, key) => {
              return (
                <li key={key}>
                  <span>{value}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="btn_area">
          <button
            className="cancel"
            onClick={() => {
              modalStore.closeModal();
            }}
          >
            취소
          </button>
          <button
            className="ok"
            onClick={() => {
              modalStore.okModal();
            }}
          >
            확인
          </button>
        </div>
      </div>
    </Modal>
  );
}));

export { ConfirmModal };
