import React, { useState } from 'react';
import CommunityCommentUser from '../user';
import clsx from 'clsx';
import CommunityReport from '../../report';
import useCommonStore from '../../../../../hooks/useCommonStore';
import useUsersStore from '../../../../../hooks/useUsersStore';
import useTypeStore from '../../../../../hooks/useTypeStore';
import { useHistory } from 'react-router-dom';

const CommentRow = ({ id, boardType, item }) => {
  const history = useHistory();

  const {
    user,
    wr_content,
    date,
    comment,
    wr_id,
    isRecomment,
    isReReply,
    reReplyNick,
    is_me,
    reply_count
  } = item;
  const commonStore = useCommonStore();
  const usersStore = useUsersStore();
  const communityStore = useTypeStore('communityStore');
  const [openOption, toggleOption] = useState(false);
  const [activeTextarea, toggleTextArea] = useState(false);
  const [openReport, toggleReport] = useState(false);
  const [text, setText] = useState('');
  const [isModify, setIsModify] = useState(false);

  const onClickReply = () => {
    if (commonStore.loginCheck) {
      toggleTextArea(!activeTextarea);
    } else {
      commonStore.modalStore.openConfirmModal({
        message: '로그인 후 사용이 가능 합니다. 로그인 하시겠습니까?',
        okCallback: () => history.push('/auth/login')
      });
    }
  };

  const onChangeTextarea = (e) => {
    if (e.target.value.length > 500) {
      alert('댓글은 500자 까지 가능합니다.');
      return;
    }

    setText(e.target.value);
  };

  const onClickSubmitComment = () => {
    if (text.length === 0) {
      alert('댓글을 입력해주세요.');

      return;
    }

    if (isModify) {
      communityStore.putCommentModify({
        wr_id: id,
        board_type: boardType,
        comment: text,
        commentId: wr_id
      });

      setIsModify(false);
    } else {
      communityStore.postReCommentAdd({
        wr_id: id,
        board_type: boardType,
        comment: text,
        commentType: 'reply',
        commentId: wr_id
      });
    }

    setText('');
    toggleTextArea(false);
  };

  const onClickOption = () => {
    toggleOption(!openOption);
  };

  const onClickReport = () => {
    onClickOption();
    toggleReport(!openReport);
  };

  const onClickModify = () => {
    toggleOption(!openOption);
    toggleTextArea(!activeTextarea);
    setText(wr_content);
    setIsModify(true);
  };

  const onClickDelete = () => {
    if (!window.confirm('정말 삭제 하시겠습니까?')) {
      return;
    }

    communityStore.deleteComment({
      wr_id: id,
      board_type: boardType,
      commentId: wr_id
    });
  };

  return (
    <div
      className={clsx(
        'comment',
        usersStore.me.mb_id === user.id && 'my',
        isRecomment && 'depth'
      )}
    >
      <div className="inner">
        <div className="info">
          <CommunityCommentUser user={user} ca_name={item.ca_name} />
          <button type="button" className="btn_option" onClick={onClickOption}>
            옵션
          </button>
          {openOption && (
            <div className="ly_option">
              <ul>
                {!is_me && (
                  <li>
                    <button className="lnk" onClick={onClickReport}>
                      신고하기
                    </button>
                  </li>
                )}
                {is_me && (
                  <li>
                    <button className="lnk" onClick={onClickModify}>
                      수정하기
                    </button>
                  </li>
                )}
                {is_me && (
                  <li>
                    <button className="lnk" onClick={onClickDelete}>
                      삭제하기
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        <div className="cont">
          {isRecomment && isReReply && (
            <span className="re_reply">{reReplyNick}</span>
          )}
          {wr_content}
        </div>
        <div className="reply">
          <span className="date">{date}</span>
          {!isReReply && (
            <button className="btn_reply" onClick={onClickReply}>
              답글쓰기
            </button>
          )}
        </div>

        {/* TODO: 답글쓰기 클릭시 답글쓰기 영역 */}
        {activeTextarea && (
          <TextAreaForm
            user={usersStore.me}
            onChangeTextarea={onChangeTextarea}
            text={text}
            submit={onClickSubmitComment}
          />
        )}
        <CommunityReport
          isOpen={openReport}
          closeReport={() => toggleReport(false)}
          category={'comment'}
          relTable={'@' + boardType}
          mbId={item.mb_id}
          wrId={item.wr_id}
          items={useTypeStore('communityStore')}
        />
      </div>
    </div>
  );
};

const TextAreaForm = ({ user, onChangeTextarea, text, submit }) => {
  return (
    <div className={clsx('comment_write', 'selected')}>
      <form method="post" onSubmit={(e) => e.preventDefault()}>
        <p className="lnk_user">
          <span className={clsx('thumb', `lv${user.level}`)}>
            <img
              src={user.profile_img}
              onError={(event) => {
                event.target.src = '/images/thumb_user_def.png';
              }}
            />
            <svg
              className="border"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 36 36"
              enableBackground="new 0 0 36 36"
              xmlSpace="preserve"
            >
              <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
            </svg>
          </span>
          <strong className="name">{user.nick}</strong>
        </p>

        <div className="form_area">
          <textarea
            value={text}
            onChange={onChangeTextarea}
            placeholder="인터넷은 우리가 함께 만들어가는 소중한 공간입니다. 댓글 작성 시 타인에 대한 배려와 책임을 담아주세요."
          ></textarea>
          <div className="txt_byte">
            <span className="num">{text.length}</span>
            <span className="slash">/</span>
            <span className="max">500</span>
          </div>
          <button type="submit" className="btn_submit" onClick={submit}>
            등록
          </button>
        </div>
      </form>
    </div>
  );
};

export { CommentRow };
