import React, { useEffect } from 'react';

const SportsAdsense = () => {
  useEffect(() => {
    try {
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className={'adsense'}>
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          height: '100px'
        }}
        data-ad-format="fluid"
        data-ad-layout-key="-g1+3r+6u-9y-4m"
        data-ad-client="ca-pub-1874524243460406"
        data-ad-slot="9922954808"
      />
    </div>
  );
};

export default SportsAdsense;
