import React, { useEffect, useRef, useState } from 'react';
import { Wrap, Header, Footer, Content, UserInfo, Button, ButtonsWrap, AccountInfo } from './styled';
import IsLogin from '../../isLogin';
import { Link } from 'react-router-dom';
import useUsersStore from '../../../hooks/useUsersStore';
//import { MypageContext, useMypageStore } from "../../../routes/mypage/context";
import { observer } from 'mobx-react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { FormatUtil } from '../../../common/utils/formatUtil';
import clsx from 'clsx';
import NotifyLayer from './notifyLayer';
import { Url } from '../../../common/constants/environment';
import { enableGiftIcon } from '../../../routes/mypage/index';

const MyInfoLayer = observer(({ openMyInfo, setOpenMyInfo, showHomesSearch }) => {
  const userWrap = useRef();
  const usersStore = useUsersStore();
  //const { mypageStore } = useMypageStore(MypageContext);
  const [openAlarm, setOpenAlarm] = useState(false);

  useEffect(() => {
    localStorage.removeItem('is-bet-login');
    if (openMyInfo) {
      disableBodyScroll(userWrap.current);
    } else {
      setOpenAlarm(false);
    }

    usersStore.getInboxList().then((r) => {});
    usersStore.getInfo().then((r) => {});

    return () => {
      enableBodyScroll(userWrap.current);
    };
  }, [openMyInfo]);

  useEffect(() => {
    if (openAlarm && showHomesSearch) {
      setOpenAlarm(false);
    }
  }, [showHomesSearch]);

  const getDesktopVersionUrl = React.useCallback(() => {
    switch (window.location.hostname) {
      case `m.${Url.hostNamedWeb}`:
        return `https://${Url.hostNamedWeb}?mobile=1`;
      case `m-stage.${Url.hostNamedWeb}`:
        return `https://stage.${Url.hostNamedWeb}?mobile=1`;
      default:
        return Url.namedWeb + `?mobile=1`;
    }
  }, []);

  return (
    <>
      {openAlarm && <NotifyLayer setOpenAlarm={setOpenAlarm} setOpenMyInfo={setOpenMyInfo} />}
      <Wrap isOpen={openMyInfo}>
        <Header>
          <h2>계정</h2>
          <button onClick={() => setOpenMyInfo(false)} />
        </Header>
        <Content ref={userWrap}>
          <IsLogin
            on={
              <>
                <UserInfo>
                  <Link to={'/mypage/info'} onClick={() => setOpenMyInfo(false)}>
                    <span className={clsx('thumb', `lv${usersStore.me.level}`)}>
                      <img
                        src={usersStore.me.profile_img}
                        onError={(event) => {
                          event.target.src = '/images/thumb_user_def.png';
                        }}
                      />
                      <svg
                        className='border'
                        version='1.1'
                        id='Layer_1'
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        x='0px'
                        y='0px'
                        viewBox='0 0 36 36'
                        enableBackground='new 0 0 36 36'
                        xmlSpace='preserve'
                      >
                        <path d='M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z' />
                      </svg>
                    </span>
                    <strong className='name'>{usersStore.data.nick}</strong>
                    <span className='today'>{usersStore.data.today_word}</span>
                  </Link>
                  <AccountInfo>
                    <div className={'cash'}>
                      <a href='/mypage/cash'>{FormatUtil.numberUnitConvert(usersStore.data.cash)}</a>
                    </div>
                    <div className={'medal'}>
                      <a href='/mypage/medal'>{FormatUtil.numberUnitConvert(usersStore.data.total_medal)}</a>
                    </div>
                    <div className={'point'}>
                      <a href='/mypage/point'>{FormatUtil.numberUnitConvert(usersStore.data.point)}</a>
                    </div>
                  </AccountInfo>
                  {/* <AccountInfo>
                    <div className={"cash"}>
                      {FormatUtil.numberUnitConvert(usersStore.data.cash)}
                    </div>
                    <div className={"medal"}>
                      {FormatUtil.numberUnitConvert(
                        mypageStore.data.total_payment_medal
                      ) /
                        FormatUtil.numberUnitConvert(
                          mypageStore.data.total_profit_medal
                        )}
                      {/* {FormatUtil.numberUnitConvert(usersStore.data.payment_medal)}
                    </div>
                    <div className={"point"}>
                      {FormatUtil.numberUnitConvert(usersStore.data.point)}
                    </div>
                  </AccountInfo> */}
                </UserInfo>
                <ButtonsWrap>
                  <Button
                    className={clsx('alarm', usersStore.isNotifyDotActive && 'dot')}
                    onClick={() => setOpenAlarm(true)}
                  >
                    알림
                  </Button>
                  <Link
                    to={'/mypage/memo'}
                    className={clsx('inbox', usersStore.inboxList?.filter((item) => !item.is_read).length > 0 && 'dot')}
                    onClick={() => setOpenMyInfo(false)}
                  >
                    쪽지함
                  </Link>
                  <Link to={'/mypage/market'} className={'market'} onClick={() => setOpenMyInfo(false)}>
                    마켓
                  </Link>
                  {enableGiftIcon ? (
                    <Link to={'/mypage/gifticon'} className={'gifticon'} onClick={() => setOpenMyInfo(false)}>
                      기프티콘
                    </Link>
                  ) : null}
                  <Button className={'logout'} onClick={() => usersStore.logout()}>
                    로그아웃
                  </Button>
                </ButtonsWrap>
              </>
            }
            off={
              <ButtonsWrap className={'login'}>
                <Link to={'/auth/login'} className={'login'}>
                  로그인
                </Link>
              </ButtonsWrap>
            }
          />
          <ButtonsWrap>
            <Link to={'/customercenter/notice'} className={'notice'} onClick={() => setOpenMyInfo(false)}>
              공지사항
            </Link>
            <Link to={'/customercenter/qna/list'} className={'qna'} onClick={() => setOpenMyInfo(false)}>
              문의하기
            </Link>
            <Link to={'/customercenter/faq'} className={'faq'} onClick={() => setOpenMyInfo(false)}>
              도움말
            </Link>
            <Link to={'/customercenter/develop'} className={'develop'} onClick={() => setOpenMyInfo(false)}>
              개발자 노트
            </Link>
            <a href={getDesktopVersionUrl()} className={'pc'}>
              데스크탑 버전
            </a>
          </ButtonsWrap>
        </Content>
        <Footer>
          {/*<Link to={"/terms/privacy"}>개인정보취급방침</Link>|*/}
          {/*<Link to={"/terms/service"}>이용약관</Link>|*/}
          {/*<Link to={"/terms/youth"}>청소년보호정책</Link>*/}
        </Footer>
      </Wrap>
    </>
  );
});

export default MyInfoLayer;
