import React, { useEffect, useState } from 'react';
import { LotteryService } from '../../../../../common/services';
import RoundTable from '../analysis/roundTable/index';
import Ball2RoundTable from '../analysis/roundTable/ball2RoundTable';
import Space8RoundTable from "../analysis/roundTable/space8RoundTable";

const RunningballResult = ({ brandName,gameName }) => {
  const [data, setData] = useState();

  useEffect(() => {
    LotteryService.getLotteryRecentList(brandName,gameName).then((res) => {
      setData(res.data);
    });

    return () => {};
  }, [brandName, gameName]);
  if (gameName === 'space8'){
    return <Space8RoundTable list={data} title={'회차별 통계'} size={20} />;
  }
  if(gameName==='runningball3'){
    return <Ball2RoundTable list={data} title={'회차별 결과'} size={20} />;
  } else{
    return <RoundTable list={data} title={'회차별 결과'} size={20} />;
  }


};

export { RunningballResult };
