import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { MedalSummary } from '../components/medal/summary'
import { MypageProfitList } from '../components/medal/profitList'
import { MypagePaymentList } from '../components/medal/paymentList'
import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';
import { MypageContext, useMypageStore } from '../context';
import { RegexUtil } from '../../../common/utils/regexUtil';
import Pagination from '../../../components/pagination';
import NoneContent from '../../../components/noneContent';
import Seo from '../../../components/seo';

const Medal = observer(({ history }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [tab, setTab] = useState(query.get('tab') || 'payment_medal_list');
  const page = parseInt(query.get('page')) || 1;
  const [startDt, setStartDt] = useState('');
  const [endDt, setEndDt] = useState('');
  const { mypageStore } = useMypageStore(MypageContext);
  const totalCount = mypageStore.data.total_cnt;

  const Component = useMemo(() => {
    switch (tab) {
      case 'payment_medal_list':
        return MypagePaymentList;
      case 'profit_medal_list':
        return MypageProfitList;
      default:
        return MypagePaymentList;
    }
  }, [tab, page]);

  const onClickSetTab = (tab) => {
    setTab(tab);
  };

  useEffect(() => {
    if (tab === 'payment_medal_list') {
      mypageStore.actionPaymentMedalList({ startDt, endDt, page });
    } else {
      mypageStore.actionProfitMedalList({ startDt, endDt, page });
    }
  }, [page, tab]);


  return (
    <>
      <Seo title={'마이페이지 캐쉬'} />
      {/*<div className="snb_wrap">*/}
      {/*  <ul>*/}
      {/*    <li>*/}
      {/*      <Link*/}
      {/*        to={`${location.pathname}?tab=payment_medal_list`}*/}
      {/*        className={clsx('item', tab === 'payment_medal_list' && 'selected')}*/}
      {/*        onClick={() => setTab('payment_medal_list')}*/}
      {/*      >*/}
      {/*        선물가능 건빵*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <Link*/}
      {/*        to={`${location.pathname}?tab=profit_medal_list`}*/}
      {/*        className={clsx('item', tab === 'profit_medal_list' && 'selected')}*/}
      {/*        onClick={() => setTab('profit_medal_list')}*/}
      {/*      >*/}
      {/*        받은 건빵*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</div>*/}
      <div className="history_wrap medal">
        <MedalSummary type={tab}/>
        <div className="list_area">
          <div className="tip_area">
            <p className="tx">※ 최대 3개월 이내의 내역만 확인 가능합니다.</p>
            <Link
                to={`/mypage/market?&category=medal`}
            >
              <button
                  type="button"
                  className="btn_charge"
              >
                구매하기
              </button>
            </Link>

          </div>
          {totalCount < 1 ? (
              <NoneContent
                  title={tab === 'payment_medal_list' ? '건빵' : '충전가능건빵'}
              />
          ) : (
            <Component history={history} items={tab === 'payment_medal_list' ? mypageStore.data.payment_medal_list : mypageStore.data.profit_medal_list} />
          )}
        </div>
        <Pagination
          page={page}
          totalCount={totalCount}
          tab={tab}
          rowCount={10}
        />
      </div>
    </>
  );
});

export default Medal;