import { observable, computed, action, runInAction } from 'mobx';
import autobind from 'autobind-decorator';
import { asyncAction } from 'mobx-utils';
import ProtoModel from './model';
import { timeUtils } from '../../../../common/utils/timeUtil';
import WebSocketUtil from '../../../../common/utils/socketUtil';
import { _SPORTS_GAME_TYPE } from '../../../../common/constants/sportsConstants';
import { ScoreUtil } from '../../../../common/utils/scoreUtil';
import { ScoreService } from '../../../../common/services';
import useLocalStorage from '../../../../hooks/useLocalStorage';

@autobind
class ProtoStore {
  localstorage = new useLocalStorage();
  socket = null;
  isSub = false;

  @observable
  proto = {
    currentRound: {
      id: 0,
      type: '',
      year: '',
      status: '',
      displayName: '',
      outerRound: 0,
      startAt: null,
      endAt: null
    },
    previousRound: {
      id: 0,
      type: '',
      year: '',
      status: '',
      displayName: '',
      outerRound: 0,
      startAt: null,
      endAt: null
    },
    nextRound: {},
    roundGames: []
  };

  @observable
  loading = false;

  constructor() {}

  connectSocket(topic) {
    this.socket = new WebSocketUtil(topic);

    this.socket.ws.onmessage = (event) => {
      if (event) {
        let data = event.data;

        try {
          if (data === 'OK') {
            this.isSub = true;
          } else {
            let messageData = JSON.parse(data);

            if (messageData) {
              const { TOPIC, MESSAGE } = messageData;

              if (TOPIC.includes('proto')) {
                const data = JSON.parse(MESSAGE);

                if (TOPIC === `proto.games`) {
                  if (data.hasOwnProperty('id')) {
                    this.proto.roundGames.forEach((item) => {
                      if (
                        item[_SPORTS_GAME_TYPE[item.sportsType]].id === data.id
                      ) {
                        item.updateGame(data);
                      }
                    });
                  }
                }

                if (TOPIC === `proto.odds`) {
                  if (data.hasOwnProperty('id')) {
                    this.proto.roundGames.forEach((item) => {
                      if (item.id === data.id) {
                        item.updateOdds(data);
                      }
                    });
                  }
                }
              }
            }
          }
        } catch (error) {
          console.log('=== socket on message error ===', error);
        }
      }
    };
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get protoList() {
    const list = this.proto.roundGames.toJS();

    return ScoreUtil.sortProtoWinList(list);
  }

  @computed
  get currentRoundStartDateTime() {
    return timeUtils.scoreScheduleTimeFormat(this.proto.currentRound.startAt);
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  closeSocket() {
    if (!!this.socket) {
      this.socket.close(() => {
        runInAction(() => {
          this.socket = null;
        });
      });
    }
  }

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  async *getProto(roundId = '') {
    this.loading = true;

    try {
      if (!this.isSub) {
        yield this.connectSocket(['proto.games', 'proto.odds']);
      }

      let copyProto = { ...this.proto };

      const result = yield ScoreService.getListOfProtoWin(roundId);

      if (result.hasOwnProperty('currentRound')) {
        copyProto.currentRound = result.currentRound;
      } else {
        copyProto.currentRound = {};
      }
      if (result.hasOwnProperty('previousRound')) {
        copyProto.previousRound = result.previousRound;
      } else {
        copyProto.previousRound = {};
      }
      if (result.hasOwnProperty('nextRound')) {
        copyProto.nextRound = result.nextRound;
      } else {
        copyProto.nextRound = {};
      }
      if (result.hasOwnProperty('roundGames')) {
        copyProto.roundGames = result.roundGames.map(
          (item) => new ProtoModel({ ...item })
        );
      } else {
        copyProto.roundGames = [];
      }
      if (result.hasOwnProperty('roundStatistics')) {
        copyProto.roundStatistics = result.roundStatistics;
      } else {
        copyProto.roundStatistics = null;
      }

      runInAction(() => {
        this.proto = copyProto;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export default ProtoStore;
