import Api from '../api';
import { Url } from '../../constants/environment';

class UsersService extends Api {
  constructor() {
    super();
  }

  /**
   * @description 내정보 가져오기
   * @returns
   */
  async getInfo() {
    const res = await this.commonAjax('get', Url.named, `me`);

    return res;
  }

  /**
   * @description 닉네임 조회
   * @returns
   */
  async userFind(nick) {
    const res = await this.commonAjax('get', Url.named, `users`, { nick });

    return res;
  }

  /**
   * @description 유저 아이디 조회
   * @returns
   */
  async userFindById(id) {
    const res = await this.commonAjax('get', Url.named, `users`, { id });

    return res;
  }

  /**
   * @description 알람 리스트
   * @returns
   */
  async getNotify() {
    const res = await this.commonAjax('get', Url.named, 'me/notify');

    return res;
  }

  /**
   * @description 읽음 처리
   * @param {*} notifyId
   * @returns
   */
  async putNotify(notifyId) {
    const res = await this.commonAjax(
      'put',
      Url.named,
      `me/notify/${notifyId}`
    );

    return res;
  }

  /**
   * @description 알림 1개 삭제
   * @param {*} notifyId
   * @returns
   */
  async deleteNotifyById(notifyId) {
    const res = await this.commonAjax('delete', Url.named, `me/notify`, {
      notify_id: notifyId
    });

    return res;
  }

  /**
   * @description 알림 전체삭제
   * @returns
   */
  async deleteNotifyAll() {
    const res = await this.commonAjax('delete', Url.named, `me/notify`, {
      del_type: 'all'
    });

    return res;
  }
}

export default new UsersService();
