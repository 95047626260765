import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../../../context';

const MypageGiftToList = observer(() => {
  const { mypageStore } = useMypageStore(MypageContext);
  const [startDt, setStartDt] = useState('');
  const [endDt, setEndDt] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    mypageStore.actionSendItemList({ startDt, endDt, page });
  }, []);

  return (
    <div>
      {mypageStore.data.list.map((item, i) => (
        <ul style={{ clear: 'both' }} key={i}>
          <li style={{ float: 'left', marginLeft: '10px' }}>{item.reg_dt}</li>
          <li style={{ float: 'left', marginLeft: '10px' }}>{item.nick}</li>
          <li style={{ float: 'left', marginLeft: '10px' }}>{item.name}</li>
        </ul>
      ))}
    </div>
  );
});

export { MypageGiftToList };
