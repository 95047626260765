import autobind from 'autobind-decorator';
import { computed, extendObservable } from 'mobx';
import { Url } from '../../../../common/constants/environment';
import { timeUtils } from '../../../../common/utils/timeUtil';

@autobind
class CommunityModel {
  constructor(data) {
    extendObservable(this, data);
  }

  @computed
  get isThumb() {
    return Boolean(+this.is_photo);
  }

  @computed
  get time() {
    if (!this.wr_datetime) {
      return '';
    }

    let date = this.wr_datetime;
    if (!date.includes('T')) {
      date = date.replace(' ', 'T');
    }

    return timeUtils.timeFormatHourMinute(date);
  }

  @computed
  get thumbnail() {
    return `${Url.thumb}normal/croptop/112x112/file/${this.board_type}/${this.wr_id}`;
  }

  @computed
  get thumbnailCategoryPhoto() {
    return `${Url.thumb}normal/croptop/300x300/file/photo/${this.wr_id}`;
  }
}

export default CommunityModel;
