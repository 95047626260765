import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Score from './score';
import Design from './_html';
import Main from './main';
import ScoreMatch from './score/match';
import ProtoCalcRoutes from './score/protoCalculator/protoCalcRoutes';
import PrerenderedLoadable from '../components/prerenderLoadable';
import RouteGuard from '../components/routeGuard';
import useUsersStore from '../hooks/useUsersStore';
import CustomerCenter from './customercenter';
import TermsUse from '../components/terms/termsUse';
import PersonalInfo from '../components/terms/personalInfo';
import Terms from './terms';
import Game from '../routes/game';
import Home from './home'
import HomeBoardDetail from './home/home_board_detail'
import SubNav from '../components/subNav';
import Nav from '../components/nav';
import User from './user';
import InterestMatch from './score/interest';
import ChatHtml from "./_html/chat";

const AuthComponent = PrerenderedLoadable(() => import('../routes/auth'));
const CommunityComponent = PrerenderedLoadable(() =>
  import('../routes/community')
);
const MypageComponent = PrerenderedLoadable(() => import('../routes/mypage'));
const WriteComponent = PrerenderedLoadable(() =>
  import('../routes/community/write')
);

const Routes = () => {
  const usersStore = useUsersStore();
  const location = useLocation();
  const mainPath = location.pathname.split('/')[1];

  useEffect(() => {
    usersStore.getInfo();
  }, []);

  return (
    <Switch>
      <Route exact path="/auth/*" component={AuthComponent} />
      <RouteGuard exact path="/community/write" component={WriteComponent} />
      <Route exact path="/match/:type/:id/:category" component={ScoreMatch} />
      <Route exact path="/score/proto/*" component={ProtoCalcRoutes} />
      <Route exact path="/page/service" component={TermsUse} />
      <Route exact path="/page/privacy" component={PersonalInfo} />
      <Route exact path="/terms/:type" component={Terms} />
      <Route exact path="/user/:userId" component={User} />
      <div id="wrap" className={mainPath}>
        <div id="container">
          {mainPath && <SubNav type={mainPath} key={mainPath} />}
          <Route exact path="/chat" component={ChatHtml} />
          <Route exact path="/" component={Main} />
          <Route exact path={["/home"]} component={Home} />
          <Route exact path={["/home/*"]} component={HomeBoardDetail} />
          <Route exact path={["/minigame", "/minigame/*"]} component={Game} />
          <Route
            exact
            path="/score/:type(major|soccer|baseball|basketball|volleyball|hockey|football|lol|starcraft|proto)"
            component={Score}
          />
          <Route exact path="/score/interest" component={InterestMatch} />
          <Route exact path="/community/*" component={CommunityComponent} />
          <Route exact path="/mypage/*" component={MypageComponent} />
          <Route exact path="/customercenter/*" component={CustomerCenter} />
          <Route exact path="/_html/:type" component={Design} />
          {mainPath && <Nav path={mainPath} />}
          {/*<div className={'footerText'} style={{backgroundColor: 'white', padding: '10px', fontSize: '10px',borderTop:'1px solid #d6d6d6'}}>*/}
          {/*    <span>회사명: (주)케이제이프로퍼티 | 대표: 황지현 | 이메일: help@named.com</span><br/>*/}
          {/*    <span>전화번호: 070-4337-0808 | 주소: 서울특별시 서초구 사평대로68, 지1층</span><br/>*/}
          {/*    <span>사업자등록번호 709-87-02955 | 통신판매업신고번호 제 2024-서울서초-0918호</span><br/>*/}
          {/*    <span>Copyright &copy; KJ PROPERTY. All rights reserved</span>*/}
          {/*</div>*/}
        </div>
      </div>
    </Switch>
  );
};

export default Routes;
