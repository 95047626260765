import React, { useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { CommentRow } from '../row';
import useUsersStore from '../../../../../hooks/useUsersStore';
import useCommonStore from '../../../../../hooks/useCommonStore';
import IsLogin from '../../../../../components/isLogin';
import { useHistory } from 'react-router-dom';

const CommentList = observer(({ item, boardType, actionCommentAdd }) => {
  const history = useHistory();

  const usersStore = useUsersStore();
  const commonStore = useCommonStore();
  const { wr_comment, comment, wr_id } = item;
  const [activeTextarea, toggleTextArea] = useState(false);
  const [text, setText] = useState('');

  const onClickCommentArea = () => {
    if (commonStore.loginCheck) {
      toggleTextArea(!activeTextarea);
    } else {
      commonStore.modalStore.openConfirmModal({
        message: '로그인 후 사용이 가능 합니다. 로그인 하시겠습니까?',
        okCallback: () => history.push('/auth/login')
      });
    }
  };

  const onChangeTextarea = (e) => {
    if (e.target.value.length > 500) {
      alert('댓글은 500자 까지 가능합니다.');
      return;
    }

    setText(e.target.value);
  };

  const onClickSubmitComment = () => {
    if (text.length === 0) {
      alert('댓글을 입력해주세요.');

      return;
    }

    actionCommentAdd({
      wr_id,
      board_type: boardType,
      comment: text,
      commentType: 'new'
    });

    setText('');
  };

  return (
    <div id={'comment_area'} className="comment_area">
      <h3 className="tit">댓글 {wr_comment}</h3>

      {/* comment_write */}
      <div className={clsx('comment_write', activeTextarea && 'selected')}>
        {/* 클릭시 selected 추가 */}
        <form method="post" onSubmit={(e) => e.preventDefault()}>
          <IsLogin
            on={
              <p className="lnk_user">
                <span className={clsx('thumb', `lv${usersStore.me.level}`)}>
                  <img
                    src={usersStore.me.profile_img}
                    onError={(event) => {
                      event.target.src = '/images/thumb_user_def.png';
                    }}
                  />
                  <svg
                    className="border"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 36 36"
                    enableBackground="new 0 0 36 36"
                    xmlSpace="preserve"
                  >
                    <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                  </svg>
                </span>
                <strong className="name">{usersStore.me.nick}</strong>
              </p>
            }
            off={<p className="lnk_user"></p>}
          />

          <span className="ic_login">로그인 전</span>
          <div className="form_area">
            <textarea
              value={text}
              onChange={onChangeTextarea}
              placeholder="인터넷은 우리가 함께 만들어가는 소중한 공간입니다. 댓글 작성 시 타인에 대한 배려와 책임을 담아주세요."
            ></textarea>
            <div className="txt_byte">
              <span className="num">{text.length}</span>
              <span className="slash">/</span>
              <span className="max">500</span>
            </div>
            <button
              type="submit"
              className="btn_submit"
              onClick={onClickSubmitComment}
            >
              등록
            </button>
          </div>
          <button
            className="btn_open"
            type="button"
            onClick={onClickCommentArea}
          >
            댓글을 남겨 보세요.
          </button>
        </form>
      </div>
      {/* // comment_write */}
      <div className="comment_list">
        {comment.length > 0 &&
          comment.map((item, i) => {
            return (
              <CommentRow
                key={item.wr_id}
                id={wr_id}
                boardType={boardType}
                item={item}
              />
            );
          })}
      </div>
    </div>
  );
});

export { CommentList };
