import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { ScoreContainer } from './shared';
import PrerenderedLoadable from '../../components/prerenderLoadable';
import { useLocation } from 'react-router-dom';
import useStore from "../../hooks/useStore";

const MajorComponent = PrerenderedLoadable(() => import('../score/major'));
const SoccerComponent = PrerenderedLoadable(() => import('../score/soccer'));
const BaseballComponent = PrerenderedLoadable(() =>
  import('../score/baseball')
);
const BasketballComponent = PrerenderedLoadable(() =>
  import('../score/basketball')
);
const VolleyballComponent = PrerenderedLoadable(() =>
  import('../score/volleyball')
);
const HockeyComponent = PrerenderedLoadable(() => import('../score/hockey'));
const FootballComponent = PrerenderedLoadable(() =>
  import('../score/football')
);
const StarcraftComponent = PrerenderedLoadable(() =>
  import('../score/starcraft')
);
const LolComponent = PrerenderedLoadable(() => import('../score/lol'));
const ProtoComponent = PrerenderedLoadable(() => import('../score/proto'));

const Score = (props) => {
  const { match } = props;

  return (
    <div id="content">
      <div className="__wrap">
        <div className="score_wrap">
          <Switch>
            <Route exact path="/score/proto" component={ProtoComponent} />
            <>
              <ScoreContainer type={match.params.type} />

              <Route exact path="/score/major" component={MajorComponent} />
              <Route exact path="/score/soccer" component={SoccerComponent} />
              <Route
                exact
                path="/score/baseball"
                component={BaseballComponent}
              />
              <Route
                exact
                path="/score/basketball"
                component={BasketballComponent}
              />
              <Route
                exact
                path="/score/volleyball"
                component={VolleyballComponent}
              />
              <Route exact path="/score/hockey" component={HockeyComponent} />
              <Route
                exact
                path="/score/football"
                component={FootballComponent}
              />
              <Route exact path="/score/lol" component={LolComponent} />
              <Route
                exact
                path="/score/starcraft"
                component={StarcraftComponent}
              />
            </>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Score;
