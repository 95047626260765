import React from 'react';

const MypageExpHtml = () => {
  return (
    <>

      {/* history_wrap */}
      <div className="history_wrap exp">
        <div className="summary">
          <dl className="amass">
            <dt>누적 경험치</dt>
            <dd>3,400,000</dd>
          </dl>
          <dl className="today">
            <dt>오늘 획득 경험치</dt>
            <dd>5,400</dd>
          </dl>
        </div>
        <div className="list_area">
          <div className="tip_area">
            <p className="tx">※ 최대 3개월 이내의 내역만 확인 가능합니다.</p>
          </div>
          <div className="list">
            <div className="item">
              <span className="info">
                <em className="tx">포토 게시판 글 등록</em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
              <span className="num">+500</span>
            </div>
            <div className="item">
              <span className="info">
                <em className="tx">포토 게시판 글 등록</em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
              <span className="num minus">-500</span>
            </div>
            <div className="item">
              <span className="info">
                <em className="tx">포토 게시판 글 등록</em>
                <em className="date">2020-00-00 00:00:00</em>
              </span>
              <span className="num">+500</span>
            </div>
          </div>
        </div>
      </div>
      {/* // history_wrap */}

    </>
  );
};

export default MypageExpHtml;
