import React, { useState, useEffect } from 'react';
import { Button, List, ListItem, UserInfo, Summary } from '../styled';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../../../context';
import clsx from 'clsx';
import { Url } from '../../../../../common/constants/environment';
import { MypageService } from '../../../../../common/services';
import useCommonStore from '../../../../../hooks/useCommonStore';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../../../components/pagination';
import NoneContent from '../../../../../components/noneContent';

const MypageFriendsList = observer(({ tab }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;

  const commonStore = useCommonStore();
  const { mypageStore } = useMypageStore(MypageContext);

  const [loading, setLoading] = useState(true);
  const [processingMbIds, setProcessingMbIds] = useState([]);

  useEffect(() => {
    mypageStore.actionList({ searchType: 'all', page }).then((res) => {
      setLoading(false);
    });
  }, [page]);

  const onClickDelete = async (userId) => {
    if (processingMbIds && processingMbIds.includes(userId)) {
      return;
    }
    setProcessingMbIds([...processingMbIds, userId]);

    try {
      const deleteFriendResponse = await MypageService.friendDelete(userId);
      if (deleteFriendResponse.code === 200) {
        await mypageStore.actionList({ searchType: 'all', page });
      } else {
        commonStore.modalStore.openAlertModal({
          message: deleteFriendResponse.message,
        });
      }
    } finally {
      setProcessingMbIds(processingMbIds.filter((id) => id !== userId));
    }
  };

  const totalCount = mypageStore.data.total_cnt;

  return (
    <div>
      {totalCount < 1 && !loading ? (
        <NoneContent title={'내가 친구한'} />
      ) : (
        !loading && (
          <>
            <List>
              {mypageStore.data.list.map((item, key) => {
                return (
                  <Item
                    key={key}
                    item={item}
                    onClickDelete={onClickDelete}
                    deleting={processingMbIds?.includes(item?.mb_id)}
                  />
                );
              })}
            </List>
            <Pagination page={page} tab={tab} totalCount={totalCount} rowCount={10} />
          </>
        )
      )}
    </div>
  );
});

const Item = ({ item, onClickDelete, deleting }) => {
  const profileImg = item['profile_image_path']
    ? `${Url.thumb}normal/crop/112x112${item['profile_image_path'].replace('data/', '')}`
    : '/images/thumb_user_def.png';

  return (
    <ListItem>
      <UserInfo levelImgUrl={`/images/level/lv${item.friend_profile.level}.svg`}>
        <span className={clsx('thumb', `lv${item.friend_profile.level}`)}>
          <img
            src={profileImg}
            onError={(event) => {
              event.target.src = '/images/thumb_user_def.png';
            }}
          />
          <svg
            className='border'
            version='1.1'
            id='Layer_1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            x='0px'
            y='0px'
            viewBox='0 0 36 36'
            enableBackground='new 0 0 36 36'
            xmlSpace='preserve'
          >
            <path d='M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z' />
          </svg>
        </span>
        <Summary>
          <div>
            <em>{item.mb_nick}</em>
            {item.is_online === 1 ? <strong>접속중</strong> : <span>{item.mb_ahead_days}</span>}
          </div>
          {item.today_word && <p>{item.today_word}</p>}
        </Summary>
      </UserInfo>
      <Button disabled={deleting} onClick={() => onClickDelete(item.mb_id)}>
        취소
      </Button>
    </ListItem>
  );
};

export { MypageFriendsList };
