import React, { useMemo } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

const CalendarView = ({
  day,
  gameCount,
  selectedDate,
  today,
  changeDate
}) => {
  const ymd = format(day, 'yyyy-MM-dd');
  const dayFormat = format(day, 'd');
  const count = useMemo(() => {
    if (!!gameCount) {
      if (gameCount.length > 0) {
        const filter = gameCount.filter((item) => item.date === ymd);

        return filter.length > 0 ? filter[0].gameCount : 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [gameCount, ymd]);
  const isVisibleDay = useMemo(() => {
    const currentMonth = format(new Date(selectedDate), 'MM');
    const checkDay = format(day, 'MM');

    return currentMonth === checkDay;
  }, [day, selectedDate]);

  return (
    <div
      className={clsx(
        "date_square",
        count === 0 && "none_active",
        selectedDate === ymd && "selected_day"
      )}
    >
      {isVisibleDay && (
        <>
          <button
            disabled={count === 0}
            onClick={() => changeDate(ymd)}
          >
            <span className="day">
              {today === ymd ? <em>오늘</em> : dayFormat}
            </span>
            <span className="game">{count}</span>
          </button>
        </>
      )}
    </div>
  );
};

export default CalendarView;
