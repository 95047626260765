export class MathUtil {
  /**
   * @descripton 가장 큰 수를 가져온다 (가장 큰 수가 여러개면 return -1)
   * @static
   * @param {*} numbers - 모든 수는 양수
   * @returns number
   */

  static onlyMaxNumber(numbers = []) {
    const maxNumber = Math.max.apply(null, numbers);
    const duplicateMaxNumber = numbers.filter((n) => n == maxNumber);

    if (duplicateMaxNumber.length > 1) {
      return -1;
    } else {
      return maxNumber;
    }
  }
}
