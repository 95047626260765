import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  li {
    background-color: #fff;
    padding: 15px 11px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    :first-child {
      margin-top: 0;
    }

    > div {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      line-height: 40px;
      min-height: 40px;
      font-size: 13px;
      :first-child {
        margin-bottom: 15px;
      }

      .time {
        display: inline-block;
        width: 40px;
      }
      .result {
        margin-left: 10px;
      }
      .cards {
        display: flex;
      }
    }
  }
`;

export const MoreButton = styled.button`
  font-size: 13px;
  background-color: #fff;
  width: 100%;
  height: 43px;
`;

export const Card = styled.div`
  width: 28px;
  height: 40px;
  overflow: hidden;
  border-radius: 2px;
  background: #fff url('/images/sp_holdem.png') no-repeat 0 0;
  background-size: 400px 200px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;

  &.combo {
    border-color: red;
    z-index: 1;
  }
  :not(:last-child) {
    margin-right: -1px;
  }

  &.cAs {
    background-position: 0 0;
  }
  &.c2s {
    background-position: -30px 0;
  }
  &.c3s {
    background-position: -60px 0;
  }
  &.c4s {
    background-position: -90px 0;
  }
  &.c5s {
    background-position: -120px 0;
  }
  &.c6s {
    background-position: -150px 0;
  }
  &.c7s {
    background-position: -180px 0;
  }
  &.c8s {
    background-position: -210px 0;
  }
  &.c9s {
    background-position: -240px 0;
  }
  &.cTs {
    background-position: -270px 0;
  }
  &.cJs {
    background-position: -300px 0;
  }
  &.cQs {
    background-position: -330px 0;
  }
  &.cKs {
    background-position: -360px 0;
  }

  &.cAh {
    background-position: 0 -40px;
  }
  &.c2h {
    background-position: -30px -40px;
  }
  &.c3h {
    background-position: -60px -40px;
  }
  &.c4h {
    background-position: -90px -40px;
  }
  &.c5h {
    background-position: -120px -40px;
  }
  &.c6h {
    background-position: -150px -40px;
  }
  &.c7h {
    background-position: -180px -40px;
  }
  &.c8h {
    background-position: -210px -40px;
  }
  &.c9h {
    background-position: -240px -40px;
  }
  &.cTh {
    background-position: -270px -40px;
  }
  &.cJh {
    background-position: -300px -40px;
  }
  &.cQh {
    background-position: -330px -40px;
  }
  &.cKh {
    background-position: -360px -40px;
  }

  &.cAd {
    background-position: 0 -80px;
  }
  &.c2d {
    background-position: -30px -80px;
  }
  &.c3d {
    background-position: -60px -80px;
  }
  &.c4d {
    background-position: -90px -80px;
  }
  &.c5d {
    background-position: -120px -80px;
  }
  &.c6d {
    background-position: -150px -80px;
  }
  &.c7d {
    background-position: -180px -80px;
  }
  &.c8d {
    background-position: -210px -80px;
  }
  &.c9d {
    background-position: -240px -80px;
  }
  &.cTd {
    background-position: -270px -80px;
  }
  &.cJd {
    background-position: -300px -80px;
  }
  &.cQd {
    background-position: -330px -80px;
  }
  &.cKd {
    background-position: -360px -80px;
  }

  &.cAc {
    background-position: 0 -120px;
  }
  &.c2c {
    background-position: -30px -120px;
  }
  &.c3c {
    background-position: -60px -120px;
  }
  &.c4c {
    background-position: -90px -120px;
  }
  &.c5c {
    background-position: -120px -120px;
  }
  &.c6c {
    background-position: -150px -120px;
  }
  &.c7c {
    background-position: -180px -120px;
  }
  &.c8c {
    background-position: -210px -120px;
  }
  &.c9c {
    background-position: -240px -120px;
  }
  &.cTc {
    background-position: -270px -120px;
  }
  &.cJc {
    background-position: -300px -120px;
  }
  &.cQc {
    background-position: -330px -120px;
  }
  &.cKc {
    background-position: -360px -120px;
  }
`;
