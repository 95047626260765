import styled from 'styled-components';

export const Wrap = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #fff;
`;

export const MajorSportTitle = styled.div`
  border-top: 1px solid #dee3eb;
  border-left: 1px solid #dee3eb;
  border-right: 1px solid #dee3eb;
  background-color: #F1F1F1;
  font-family: YoonGothicPro, '맑은 고딕', Gulim, Helvetica, sans-serif;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  margin-top: 8px;
  padding: 12px;
  margin-bottom: -3px;
`;
