import styled, { css } from 'styled-components';

export const Wrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 20px);
  visibility: hidden;
  background-color: #fff;
  border: 1px solid #dfe4ec;
  z-index: 9;
  ${(props) =>
    props.open &&
    css`
      visibility: visible;
    `}
  .react-autosuggest__suggestions-container {
    display: none;
  }
  .react-autosuggest__suggestions-container--open {
    z-index: 2;
    display: block;
    position: absolute;
    top: 39px;
    right: -1px;
    max-height: ${(props) => props.maxHeight};
    width: 100%;
    overflow-y: auto;
    background-color: #ffffff;
    border-left: 1px solid #dfe4ec;
    border-right: 1px solid #dfe4ec;
    border-bottom: 1px solid #dfe4ec;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: #f2f2f2;
    color: red;
  }
  .react-autosuggest__section-title {
    height: 30px;
    padding: 0 20px;
    line-height: 30px;
    font-weight: 700;
    background-color: #f9fafc;
  }
  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    .name {
      .highlight {
        color: #ee0000;
        font-weight: bold;
      }
    }
  }
`;

export const Label = styled.label`
  display: block;
  > input {
    box-sizing: border-box;
    padding: 0 45px;
    width: 100%;
    height: 38px;
    border: none;
    font-size: 13px;
    ::placeholder {
      color: #ccc;
    }
  }
  .btn_reset {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 30px;
    height: 30px;
    line-height: 31px;
    outline-style: none;
    .ic {
      float: left;
      margin-left: 7px;
      content: '';
      width: 20px;
      height: 20px;
      background: url('/images/ic-delete-pic.svg') no-repeat center center;
      text-indent: -9999px;
      overflow: hidden;
    }
  }
`;
