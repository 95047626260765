import React from 'react';

const PeriodBarChart = ({ data, mapList }) => {
  return (
    <>
      {mapList.map((item, key) => {
        const minGroupKey = 'min_' + item.resultKey + '_count';
        const maxGroupKey = 'max_' + item.resultKey + '_count';
        const countKey = item.resultKey + '_count';
        const percentKey = item.resultKey + '_percent';
        const minWidth =
          parseInt(data[minGroupKey][percentKey]) < 40
            ? 40
            : data[minGroupKey][percentKey];
        const maxWidth =
          parseInt(data[maxGroupKey][percentKey]) < 40
            ? 40
            : data[maxGroupKey][percentKey];

        return (
          <div className="item" key={key}>
            <span className="tit">{item.name}</span>
            <div className="mm min">
              <p style={{ width: `${minWidth}%` }}>
                <span className="tx">
                  <em>{data[minGroupKey].reg_date}</em>
                  <em>
                    <strong>{data[minGroupKey][countKey]}</strong> (
                    {data[minGroupKey][percentKey]}%)
                  </em>
                </span>
              </p>
            </div>
            <div className="mm max">
              <p style={{ width: `${maxWidth}%` }}>
                <span className="tx">
                  <em>{data[maxGroupKey].reg_date}</em>
                  <em>
                    <strong>{data[maxGroupKey][countKey]}</strong> (
                    {data[maxGroupKey][percentKey]}%)
                  </em>
                </span>
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PeriodBarChart;
