import React, { useState } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import ProfileImg from '../profile_image_change';
import PasswordChange from '../password_change';
import NickChange from '../nick_change';
import TodayWordChange from '../today_word_change';
import { PopupModal } from '../../../components/modal/popup/popupModal';
import BackgroundImageChange from '../background_image_change';
import useUsersStore from '../../../hooks/useUsersStore';
import useCommonStore from '../../../hooks/useCommonStore';
import RewardGuidanceModal from '../components/rewardGuidanceModal';
import Seo from '../../../components/seo';

const DynamicComponents = {
  profileImageChange: ProfileImg,
  passwordChange: PasswordChange,
  nickChange: NickChange,
  todayWordChange: TodayWordChange,
  backgroundImageChange: BackgroundImageChange
};

const Info = observer(({ history }) => {
  const usersStore = useUsersStore();
  const commonStore = useCommonStore();
  const resultPhoneUrl = `${window.location.origin}/mypage/auth_result/phone`;
  const resultIpinUrl = `${window.location.origin}/mypage/auth_result/ipin`;
  const [isOpenModal, setOpenModal] = useState(['', false]);
  const [isOpenGuidanceModal, setOpenGuidanceModal] = useState(false);

  const openPopup = () => {
    const Component = DynamicComponents[isOpenModal[0]];

    if (!!Component) {
      return (
        <Component
          history={history}
          onClose={() => setOpenModal(['', false])}
        />
      );
    } else {
      return null;
    }
  };

  const onClickEmail = () => {
    history.push('/auth/email_auth');
  };

  const onClickNickChange = () => {
    setOpenModal(['nickChange', true]);
  };

  const onClickTodayWordChange = () => {
    setOpenModal(['todayWordChange', true]);
  };

  const onClickProfileImageChange = () => {
    setOpenModal(['profileImageChange', true]);
  };

  const onClickBackgroundImageChange = () => {
    setOpenModal(['backgroundImageChange', true]);
  };

//  const onClickPw2Set = () => {
//    const { is_email_certify, certify } = usersStore.data;
//    if (is_email_certify || certify.is_phone_certify) {
//      history.push('/auth/pw2_set');
//    } else {
//      commonStore.modalStore.openAlertModal({
//        message: '이메일 또는 휴대폰 인증 후 설정 가능합니다.'
//      });
//    }
//  };

  const goAddIp = () => {
    history.push('/mypage/ip_add');
  };

  const goDeleteIp = () => {
    history.push('/mypage/ip_delete');
  };

  const onClickPwChange = () => {
    setOpenModal(['passwordChange', true]);
  };

  // const onClickPhoneCert = () => {
  //   const resultUrl = `${window.location.origin}/mypage/auth_result/phone`;
  //   commonStore.getCertPhoneEncData({ resultUrl }).then((res) => {
  //     if (res.code === 200) {
  //       const encData = res.data.enc_data;
  //       commonStore.getCertPhonePage({ encData });
  //     } else {
  //       console.log(res.message);
  //     }
  //   });
  // };

  const onClickPhoneCert = () => {
    history.push('/auth/phone_auth');
  };

  const onClickIpinCert = () => {
    const resultUrl = `${window.location.origin}/mypage/auth_result/ipin`;
    commonStore.getCertIpinEncData({ resultUrl }).then((res) => {
      if (res.code === 200) {
        const encData = res.data.enc_data;
        commonStore.getCertIpinPage({ encData });
      } else {
        console.log(res.message);
      }
    });
  };

  return (
    <div className="mypage_wrap">
      <Seo title={'마이페이지 내정보'} />
      <div className="reward_area">
        <dl>
          <dt className="tit">
            내정보 입력 달성률은{' '}
            <strong className="point">
              {usersStore.data.info_point ? usersStore.data.info_point : 0} 점
            </strong>{' '}
            입니다.
          </dt>
          <dd className="info">
            달성시마다 푸짐한 아이템을 드립니다.
            <em
              className="guide"
              onClick={() => setOpenGuidanceModal(!isOpenGuidanceModal)}
            >
              보상안내
            </em>
          </dd>
          <dd className="gauge">
            <span
              className="per"
              style={{
                width: usersStore.data.info_point
                  ? usersStore.data.info_point + '%'
                  : 0 + '%'
              }}
            >
              {usersStore.data.info_point
                ? usersStore.data.info_point + 'P'
                : 0 + 'P'}
            </span>
          </dd>
        </dl>
      </div>
      {/* info_area */}
      <div className="info_area">
        {/* column */}
        <div className="column">
          <div className="hd">계정 및 인증정보</div>
          <div className="bd">
            <dl className="item">
              <dt>
                이메일
                {usersStore.data.is_email_certify ? (
                  <span>{`(인증완료)`}</span>
                ) : (
                  <span>{`(미인증)`}</span>
                )}
              </dt>
              <dd className="tx">
                <p className="tx">
                  {usersStore.data.email}
                  {!usersStore.data.is_email_certify && (
                    <button type="button" onClick={onClickEmail}>
                      인증하기
                    </button>
                  )}
                </p>
              </dd>
            </dl>
            <dl className="item">
              <dt>비밀번호</dt>
              <dd>
                <p className="tx">
                  {usersStore.data.password_change_date} 변경됨
                </p>
                <button type="button" onClick={onClickPwChange}>
                  변경하기
                </button>
              </dd>
            </dl>
            <dl className="item">
              <dt>휴대폰</dt>
              <dd>
                <p className="tx">
                  {usersStore.data.certify.is_phone_certify
                    ? '인증완료' +
                      ' : ' +
                      usersStore.data.certify.name +
                      '/' +
                      usersStore.data.certify.gender +
                      '/' +
                      usersStore.data.certify.birth
                    : '인증 내역이 없습니다.'}
                </p>
                <button type="button" onClick={onClickPhoneCert}>
                  인증하기
                </button>
              </dd>
            </dl>
          </div>
        </div>
        {/* // column */}

        {/* column */}
        <div className="column">
          <div className="hd">커뮤니티 정보</div>
          <div className="bd">
            <dl className="item">
              <dt>닉네임</dt>
              <dd>
                <p className="tx">{usersStore.me.nick}</p>
                <button type="button" onClick={onClickNickChange}>
                  변경하기
                </button>
              </dd>
            </dl>
            <dl className="item">
              <dt>오늘의 한마디</dt>
              <dd>
                <p className="tx">
                  {usersStore.me.today_word
                    ? usersStore.me.today_word
                    : '미설정'}
                </p>
                <button type="button" onClick={onClickTodayWordChange}>
                  변경하기
                </button>
              </dd>
            </dl>
            <dl className="item">
              <dt>프로필 이미지</dt>
              <dd>
                <p className="profile_img">
                  <span className={clsx('thumb', `lv${usersStore.me.level}`)}>
                    <img
                      src={usersStore.me.profile_img}
                      onError={(event) => {
                        event.target.src = '/images/thumb_user_def.png';
                      }}
                    />
                    <svg
                      className="border"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 36 36"
                      enable-background="new 0 0 36 36"
                      xmlSpace="preserve"
                    >
                      <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                    </svg>
                  </span>
                </p>
                <button type="button" onClick={onClickProfileImageChange}>
                  변경하기
                </button>
              </dd>
              <dd className="btn_area"></dd>
            </dl>
            <dl className="item">
              <dt>프로필 배경 이미지</dt>
              <dd>
                <p className="profile_bg_img">
                  <img
                    src={usersStore.me.background_image_path}
                    onError={(event) => {
                      event.target.src = '/images/thumb_bg_def.png';
                    }}
                  />
                </p>
                <button type="button" onClick={onClickBackgroundImageChange}>
                  변경하기
                </button>
              </dd>
            </dl>
          </div>
        </div>
        {/* // column */}

        {/* column */}
        {/*<div className="column">*/}
        {/*  <div className="hd">보안 정보</div>*/}
        {/*  <div className="bd">*/}
        {/*    <dl className="item">*/}
        {/*      <dt>2차 비밀번호</dt>*/}
        {/*      <dd>*/}
        {/*        <p className="tx">*/}
        {/*          {usersStore.data.second_password_date*/}
        {/*            ? usersStore.data.second_password_date + ' 변경됨'*/}
        {/*            : '설정 내역이 없습니다.'}*/}
        {/*        </p>*/}
        {/*        <button type="button" onClick={onClickPw2Set}>*/}
        {/*          설정하기*/}
        {/*        </button>*/}
        {/*      </dd>*/}
        {/*    </dl>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <PopupModal
          isOpen={isOpenModal[1]}
          onClose={() => setOpenModal(['', false])}
        >
          {openPopup()}
        </PopupModal>
        <RewardGuidanceModal
          isOpen={isOpenGuidanceModal}
          onClose={() => setOpenGuidanceModal(false)}
        />
        {/* // column */}
      </div>
      {/* // info_area */}
    </div>
  );
});

export default Info;
