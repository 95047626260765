import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 15px;
  > div {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
`;

export const Help = styled.span`
  width: 16px;
  height: 16px;
  margin-left: 10px;
  border: 1px solid #999;
  border-radius: 50%;
  color: #999;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  height: 28px;
  padding: 0 11px;
  border: 1px solid #b1b1b1;
  font-size: 11px;
  color: #666;
  background-color: #fff;
`;

export const List = styled.ul`
  background-color: #fff;
  padding: 0 15px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 0 5px;
`;

export const UserInfo = styled.div`
  display: flex;
  & > .thumb {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 17px;
    :before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: url(${(props) => props.levelImgUrl}) no-repeat 0 -2px !important;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
  > div {
    display: flex;
    line-height: 13px;
    em {
      font-size: 14px;
      font-weight: 600;
      :after {
        display: inline-block;
        content: '・';
        width: 18px;
        text-align: center;
        color: #c7c7c7;
      }
    }
    span {
      font-size: 12px;
      color: #999;
    }
    strong {
      font-size: 12px;
      color: #ed2026;
    }
  }
  > p {
    font-size: 12px;
    line-height: 12px;
    color: #666;
    margin-top: 8px;
  }
`;
