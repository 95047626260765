import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const PatternBarChart = ({ percent, gameName }) => {
  if (Object.keys(percent).length === 0) {
    return null;
  }

  const {
    runningball_odd_even,
    runningball_under_over,
    sum_odd_even,
    sum_under_over,
  } = percent;

  return (
    <>
      <div className="data_summary">
        <h3 className="tit">
          1등볼 : <span>패턴 일치 다음회차 분석</span>
        </h3>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'ODD',
              parseInt(runningball_odd_even.odd_cnt) >
                parseInt(runningball_odd_even.even_cnt) && 'on'
            )}
          >
            <dt>홀</dt>
            <dd>
              <span className="num">{runningball_odd_even.odd_cnt}</span>
              <span className="per">({runningball_odd_even.odd_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              parseInt(runningball_odd_even.odd_cnt) <
                parseInt(runningball_odd_even.even_cnt) && 'on'
            )}
          >
            <dt>짝</dt>
            <dd>
              <span className="num">{runningball_odd_even.even_cnt}</span>
              <span className="per">({runningball_odd_even.even_ratio}%)</span>
            </dd>
          </dl>
        </div>
        {gameName !== 'runningball3' &&
          <div className="chart">
            <dl
              className={clsx(
                'bar',
                'UNDER',
                parseInt(runningball_under_over.over_cnt) >
                  parseInt(runningball_under_over.under_cnt) && 'on'
              )}
            >
              <dt>언더</dt>
              <dd>
                <span className="num">{runningball_under_over.over_cnt}</span>
                <span className="per">({runningball_under_over.over_ratio}%)</span>
              </dd>
            </dl>
            <dl
              className={clsx(
                'bar',
                'OVER',
                parseInt(runningball_under_over.over_cnt) <
                  parseInt(runningball_under_over.under_cnt) && 'on'
              )}
            >
              <dt>오버</dt>
              <dd>
                <span className="num">{runningball_under_over.under_cnt}</span>
                <span className="per">({runningball_under_over.under_ratio}%)</span>
              </dd>
            </dl>
          </div>}
      </div>

      {gameName !== 'runningball3' &&
          <div className="data_summary">
            <h3 className="tit">
              123등 합 : <span>패턴 일치 다음회차 분석</span>
            </h3>
            <div className="chart">
              <dl
                  className={clsx(
                      'bar',
                      'ODD',
                      parseInt(sum_odd_even.odd_cnt) >
                      parseInt(sum_odd_even.even_cnt) && 'on'
                  )}
              >
                <dt>홀</dt>
                <dd>
                  <span className="num">{sum_odd_even.odd_cnt}</span>
                  <span className="per">({sum_odd_even.odd_ratio}%)</span>
                </dd>
              </dl>
              <dl
                  className={clsx(
                      'bar',
                      'EVEN',
                      parseInt(sum_odd_even.odd_cnt) <
                      parseInt(sum_odd_even.even_cnt) && 'on'
                  )}
              >
                <dt>짝</dt>
                <dd>
                  <span className="num">{sum_odd_even.even_cnt}</span>
                  <span className="per">({sum_odd_even.even_ratio}%)</span>
                </dd>
              </dl>
            </div>
            <div className="chart">
              <dl
                  className={clsx(
                      'bar',
                      'UNDER',
                      parseInt(sum_under_over.under_cnt) >
                      parseInt(sum_under_over.over_cnt) && 'on'
                  )}
              >
                <dt>언더</dt>
                <dd>
                  <span className="num">{sum_under_over.under_cnt}</span>
                  <span className="per">({sum_under_over.under_ratio}%)</span>
                </dd>
              </dl>
              <dl
                  className={clsx(
                      'bar',
                      'OVER',
                      parseInt(sum_under_over.under_cnt) <
                      parseInt(sum_under_over.over_cnt) && 'on'
                  )}
              >
                <dt>오버</dt>
                <dd>
                  <span className="num">{sum_under_over.over_cnt}</span>
                  <span className="per">({sum_under_over.over_ratio}%)</span>
                </dd>
              </dl>
            </div>
          </div>
      }
    </>
  );
};

export default PatternBarChart;
