import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import useSportsStore from '../../../../hooks/useSportsStore';

const ProtoCalcView = observer(({ item, isDisabled, onChangeDisabled }) => {
  const {
    number,
    homeName,
    awayName,
    getStartDateTime,
    sportsTypeLower,
    leagueName,
    oddss,
    game,
    id
  } = item;
  const { className, visible, optionValue } = item.getGroupType;
  const { statusText, statusClass } = item.getStatus;
  const { home, away, odds } = item.teamData;
  const [checkedId, setCheckId] = useState(null);
  const store = useSportsStore('protoCalculator');

  useEffect(() => {
    const { battingPicks } = store;

    battingPicks.some((item) => {
      if (item.gameId === game.id && item.roundGameId === id) {
        Object.keys(odds).some((type) => {
          if (type === 'win' || type === 'draw' || type === 'loss') {
            if (item.oddsId === odds[type].id) {
              setCheckId(odds[type].id);
              onChangeDisabled(id);
            }
          }
        });
      }
    });
  }, []);

  const activeBackground = useMemo(() => {
    return !isDisabled && !!checkedId;
  }, [checkedId, isDisabled]);

  const checkDisabled = useMemo(() => {
    if (isDisabled) {
      return true;
    } else {
      if (statusClass === 'wait') {
        if (oddss.length === 0 || game.id === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }, [statusClass, isDisabled, oddss]);

  const onChangeCheckbox = (e) => {
    const { value } = e.target;
    const toggle = checkedId === odds[value].id;

    if (game.id === 0) {
      alert('경기가 매핑되지 않음');
      return;
    }

    const checkAvailableFlag = item.oddss.filter(
      (item) => item.id === odds[value].id
    )[0].availableFlag;

    if (!checkAvailableFlag) {
      alert('발매차단되었습니다.');
      return;
    }

    store.onClickOdds({
      oddsId: odds[value].id,
      gameId: game.id,
      roundGameId: id,
      odds: odds[value].odds
    });
    setCheckId(toggle ? null : odds[value].id);
    onChangeDisabled(toggle ? null : id);
  };

  useEffect(() => {
    if (store.tempBattingClear) {
      setCheckId(null);
    }
  }, [store.tempBattingClear]);

  const booleanCheckedId = useMemo(() => {
    if (checkedId === null) {
      return null;
    } else {
      return +checkedId;
    }
  }, [checkedId]);

  return (
    <div className={clsx('item', activeBackground && 'active')}>
      <div className="summary">
        <span className="no">{number}</span>
        <span className="date">{getStartDateTime}</span>
        <span className={clsx('league', sportsTypeLower)}>{leagueName}</span>
      </div>
      <div className="box">
        <div className="team left">
          <span className="name">{homeName}</span>
        </div>
        <div className="info">
          <div className="score">
            <strong className={clsx('left', home.isActive && 'on')}>
              <em className="num">{home.score}</em>
            </strong>
            <strong className={clsx('right', away.isActive && 'on')}>
              <em className="num">{away.score}</em>
            </strong>
          </div>
          <div className={clsx('state', statusClass)}>{statusText}</div>{' '}
          {/* class : win, over, under */}
        </div>
        <div className="team right">
          <div className="name">{awayName}</div>
        </div>
      </div>
      <div className="odds">
        {visible && (
          <span className={clsx('type', className)}>{optionValue}</span>
        )}
        <ul className="odds_selector">
          <li>
            <input
              type="checkbox"
              id={`win_odds_${odds.win.id}`}
              name="cheer_team"
              className="blind"
              disabled={checkDisabled}
              value="win"
              checked={booleanCheckedId === odds.win.id}
              onChange={onChangeCheckbox}
            />
            <label
              htmlFor={`win_odds_${odds.win.id}`}
            >{`W ${odds.win.odds}`}</label>
          </li>
          <li>
            {odds.draw.id !== 0 ? (
              <>
                <input
                  type="checkbox"
                  id={`draw_odds_${odds.draw.id}`}
                  name="cheer_team"
                  className="blind"
                  disabled={checkDisabled}
                  value="draw"
                  checked={booleanCheckedId === odds.draw.id}
                  onChange={onChangeCheckbox}
                />
                <label
                  htmlFor={`draw_odds_${odds.draw.id}`}
                >{`W ${odds.draw.odds}`}</label>
              </>
            ) : (
              '-'
            )}
          </li>
          <li>
            <input
              type="checkbox"
              id={`loss_odds_${odds.loss.id}`}
              name="cheer_team"
              className="blind"
              disabled={checkDisabled}
              value="loss"
              checked={booleanCheckedId === odds.loss.id}
              onChange={onChangeCheckbox}
            />
            <label
              htmlFor={`loss_odds_${odds.loss.id}`}
            >{`L ${odds.loss.odds}`}</label>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default ProtoCalcView;
