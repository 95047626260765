import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GoBack from '../../../../components/goBack';
import ProtoMyPickStore from '../store';
import ProtoMyPickDetailItem from './item/protoMyPickDetailItem';
import clsx from 'clsx';
import Seo from '../../../../components/seo';

const ProtoMyPickDetail = () => {
  const { id } = useParams();
  const { data, getOddsCalculatorById } = ProtoMyPickStore();

  useEffect(() => {
    getOddsCalculatorById(id);
  }, []);

  return (
    <>
      <Seo title={'프로토 계산기 저장내역 상세'} />
      <div className="proto_wrap">
        <div className="nav">
          <h2 className="tit">승부식 {data.detail.displayName}</h2>
          <GoBack />
          <span className={clsx('state', data.detail.status.class)}>
            {data.detail.status.text}
          </span>
        </div>

        {/* proto_calculator */}
        <div className="proto_list proto_calculator">
          {data.detail.polls.length > 0 &&
            data.detail.polls.map((item) => {
              return <ProtoMyPickDetailItem item={item} key={item.id} />;
            })}
        </div>
        {/* // proto_calculator */}

        {/* proto_odds_slip */}
        <div className="proto_odds_slip view">
          {/* 클래스 view */}
          <div className="summary">
            <span className="tit">{data.detail.displayName}</span>
          </div>
          <div className="list">
            <div className="info">
              <div className="column">
                <dl>
                  <dt>선택경기</dt>
                  <dd>{data.detail.totalGameCount}경기</dd>
                </dl>
                <dl>
                  <dt>베팅금액</dt>
                  <dd>
                    <input type="text" />
                    <span className="won">{data.detail.betPoint}원</span>
                  </dd>
                </dl>
              </div>
              <div className="column">
                <dl>
                  <dt>예상배당률</dt>
                  <dd>{data.detail.totalOdds}배</dd>
                </dl>
                <dl>
                  <dt>예상당첨금</dt>
                  <dd className="amount">{data.detail.totalAmount}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        {/* // proto_odds_slip */}
      </div>
    </>
  );
};

export default ProtoMyPickDetail;
