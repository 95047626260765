import React from 'react';
import {Url} from "../../common/constants/environment";

const ScoreCastStarcraftHtml = () => {
  return (
    <>

      {/* score_detail_wrap */ }
      <div className="score_detail_wrap"> {/* 클래스 상태 : small_view, ready, close */}

        <div className="summary">
          <button className="btn_prev">이전으로</button>
          <h2 className="tit">2020 ASL S10</h2>
          <button className="btn_oc">크게보기/작게보기</button>
        </div>

        {/* score_detail_board */ }
        <div className="score_detail_board starcraft"> {/* 클래스 종목 : soccer, basketball ---- 종목별 이름 */}

          {/* summary_score */ }
          <div className="summary_score">
            <div className="team left">
              <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/starcraft/player/729_1603782633.png"}/>
                <span className="name">바로 셀로로</span>
                <span className="player">디에고 카스틸로</span>
            </div>
            <div className="info">
              <div className="score">
                <strong className="left on">
                  <em className="num">0</em>
                </strong>
                <strong className="right">
                  <em className="num">0</em>
                </strong>
              </div>
              <div className="state"><span>3세트 30:20</span></div>
              <div className="time">10.22 09:00</div>
              <div className="stadium">푸동 스타디움</div>
            </div>
            <div className="team right"><img className="logo" src={Url.thumb + "normal/resize/0x55/sports/lol/team/222_1586423078.png"}/>
              <div className="name">바로 셀로나</div>
              <span className="player">디에고 카스틸로</span>
            </div>
          </div>
          {/* // summary_score */ }

        </div>
        {/* // score_detail_board */ }

        {/* snb_wrap */ }
        <div className="snb_wrap">
          <ul>
            <li><a className="item" href="javascript:;">응원</a></li>
            <li><a className="item selected" href="javascript:;">중계</a></li>
          </ul>
        </div>
        {/* // snb_wrap */ }

        {/* starcraft_cast */ }
        <div className="starcraft_cast">
          <div className="list">
            <div className="hd">
              <span className="cell cell_set">세트</span>
              <span className="cell cell_map">맵</span>
              <span className="cell cell_player">정영재(T)</span>
              <span className="cell cell_player">유영진(Z)</span>
            </div>
            <div className="bd">
              <div className="item">
                <span className="cell cell_set">
                  <em className="set">1</em>
                  <em className="time">07:50</em>
                </span>
                <span className="cell cell_map">
                  <button type="button" className="btn_map">
                    <img src={Url.thumb + "normal/resize/0x35/sports/starcraft/map/271_1606882378.jpg"}/>
                    <em>Oxide Lxide Lxide Lxide LE</em>
                  </button>
                </span>
                <span className="cell cell_player">
                  <em className="tx">0승1패</em>
                  <em className="win">승</em>
                </span>
                <span className="cell cell_player">
                  <em className="tx">6승1패</em>
                </span>
              </div>
              <div className="item">
                <span className="cell cell_set">
                  <em className="set">2</em>
                  <em className="time">07:50</em>
                </span>
                <span className="cell cell_map">
                  <button type="button" className="btn_map">
                    <img src={Url.thumb + "normal/resize/0x35/sports/starcraft/map/271_1606882378.jpg"}/>
                    <em>Oxide Lxide Lxide Lxide LE</em>
                  </button>
                </span>
                <span className="cell cell_player">
                  <em className="tx">0승1패</em>
                </span>
                <span className="cell cell_player">
                  <em className="tx">6승1패</em>
                  <em className="win">승</em>
                </span>
              </div>
              <div className="item">
                <span className="cell cell_set">
                  <em className="set">3</em>
                </span>
                <span className="cell cell_map">
                  <button type="button" className="btn_map">
                    <img src={Url.thumb + "normal/resize/0x35/sports/starcraft/map/271_1606882378.jpg"}/>
                    <em>Oxide Lxide Lxide Lxide LE</em>
                  </button>
                </span>
                <span className="cell cell_player">
                  <em className="tx">0승1패</em>
                </span>
                <span className="cell cell_player">
                  <em className="tx">6승1패</em>
                </span>
              </div>
            </div>
          </div>

          <div className="layer_map_detail" style={{display:'none'}}> {/* 맵클릭시 나오는 레이어 */ }
            <div className="option">
              <button type="button" className="btn_close">닫기</button>
            </div>
            <div className="map">
              <img src={Url.thumb + "normal/resize/320x320/sports/starcraft/map/271_1606882378.jpg"} alt="map_image"/>
              <span className="name">Jagannatha LE</span>
            </div>
          </div>

        </div>
        {/* // starcraft_cast */ }

      </div>
      {/* // score_detail_wrap */ }

    </>
  );
};

export default ScoreCastStarcraftHtml;
