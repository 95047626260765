import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import styled from 'styled-components';

const Pw2 = observer(({ onClose, onSubmitCallback }) => {
  const { mypageStore } = useMypageStore(MypageContext);
  const [secondPassword, setSecondPassword] = useState('');

  const onSubmit = async (event) => {
    event.preventDefault();
    mypageStore.actionPw2({ secondPassword }).then(({ code }) => {
      onSubmitCallback(code);
    });
  };

  return (
    <div className="account_info_wrap">
      <div className="nav">
        <h2 className="tit">2차 비밀번호 입력</h2>
        <button className={'btn_close'} onClick={onClose} />
      </div>
      <Form onSubmit={onSubmit}>
        <input
          type="password"
          placeholder={'비밀번호 4자리를 입력'}
          value={secondPassword}
          onChange={(e) => setSecondPassword(e.target.value)}
        />
        <Buttons>
          <button type={'button'} onClick={onClose}>
            취소
          </button>
          <button type={'submit'}> 확인</button>
        </Buttons>
      </Form>
      <div className="tip">
        <p>
          2차 비밀번호는 회원님의 계정을 더욱 안전하게 보호하기 위한 수단입니다.
        </p>
        <p className="c_999">
          5회이상 입력이 틀릴경우 더이상 입력이 불가하며, 재설정을 통해 재입력이
          가능합니다.
        </p>
      </div>
    </div>
  );
});

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 26px 20px 0;
  > input {
    border: 1px solid #e4e4e4;
    height: 44px;
    font-size: 14px;
    padding: 0 16px;
    ::placeholder {
      color: #999;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  button {
    flex: 1;
    font-size: 14px;
    color: #fff;
    height: 44px;
    background-color: #8e8e8e;
  }
  button[type='submit'] {
    background-color: #ed2026;
    margin-left: 5px;
  }
`;

export default Pw2;
