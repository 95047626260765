import React, { useEffect, useRef, useState } from 'react';
import {
  Background,
  CloseButton,
  Header,
  Wrap,
  TypeButton,
  TypeWrap,
  Content,
  Explain,
  IconLevel,
  LevelInfo,
  Table
} from './styled';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const GuidanceModal = ({ isOpen, onClose, levelList }) => {
  const bodyScroll = useRef();

  const [selectedGP, setSelectedGP] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      enableBodyScroll(bodyScroll.current);
    } else {
      disableBodyScroll(bodyScroll.current);
    }
  }, [isOpen]);

  return (
    <Background isOpen={isOpen} onClick={onClose}>
      <Wrap
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Header>
          <h2>블랙리스트 확장안내</h2>
          <CloseButton onClick={onClose} />
        </Header>
        <div>
          <TypeWrap>
            <TypeButton
              onClick={() => setSelectedGP(false)}
              selected={!selectedGP}
            >
              계급별 확장
            </TypeButton>
            <TypeButton
              onClick={() => setSelectedGP(true)}
              selected={selectedGP}
            >
              GP 확장
            </TypeButton>
          </TypeWrap>
          <Content ref={bodyScroll}>
            <Explain>
              {selectedGP
                ? '블랙리스트는 GP를 이용하여 최대 0명\n' +
                  '계급이 상승함에 따라 보너스로 추가할 수 있는 개수가 늘어납니다.'
                : '블랙리스트는 기본 50명을 등록할 수 있습니다. 계급이 상승함에 따라\n' +
                  '추가할 수 있는 개수가 늘어납니다. 블랙리스트는 나에게 쪽지를\n' +
                  '보내거나 내가 개설한 방 채팅에 입장할 수 없으며, 내가 쓴 게시물에\n' +
                  '댓글을 달 수 없습니다.'}
            </Explain>
            {selectedGP ? <GPTable /> : <ClassesTable levelList={levelList} />}
          </Content>
        </div>
      </Wrap>
    </Background>
  );
};

const ClassesTable = ({ levelList }) => {
  return (
    <Table>
      <colgroup>
        <col width={129} />
      </colgroup>
      <thead>
        <tr>
          <th>계급</th>
          <th>추가되는 블랙리스트 개수</th>
        </tr>
      </thead>
      <tbody>
        {levelList.map((item, key) => {
          const { title, level } = item;
          let td = null;
          if (level === 0) {
            td = <td>없음</td>;
          } else if (level === 1) {
            td = <td>기본 50명</td>;
          } else if (level === 2) {
            td = <td rowSpan={4}>각 +10명 추가</td>;
          } else if (level === 6) {
            td = <td rowSpan={4}>각 +20명 추가</td>;
          } else if (level === 10) {
            td = <td rowSpan={3}>각 +30명 추가</td>;
          } else if (level === 13) {
            td = <td rowSpan={9}>각 +50명 추가</td>;
          }

          return (
            <tr key={key}>
              <td>
                <LevelInfo>
                  <IconLevel url={`/images/level/lv${level}.svg`} />
                  {title}
                  <em>({item.max_black_list})</em>
                </LevelInfo>
              </td>
              {td}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const GPTable = () => {
  const trList = [];
  for (let i = 10; i <= 110; i += 10) {
    let price = 0;
    let td = null;

    if (i <= 50) {
      price = '10,000';
    } else if (i <= 100) {
      price = '50,000';
    } else {
      price = '100,000';
    }

    if (i === 10) {
      td = <td rowSpan={5}>최대 50명</td>;
    } else if (i === 60) {
      td = <td rowSpan={5}>최대 100명</td>;
    } else if (i === 110) {
      td = <td>최대 100,000명</td>;
    }

    trList.push(
      <tr key={i}>
        <td>{i}명</td>
        <td>{price}</td>
        {td}
      </tr>
    );
  }

  return (
    <Table>
      <colgroup>
        <col width={`20%`} />
        <col width={`30%`} />
      </colgroup>
      <thead>
        <tr>
          <th>추가</th>
          <th>소모 GP</th>
          <th>비고</th>
        </tr>
      </thead>
      <tbody>{trList}</tbody>
    </Table>
  );
};

export default GuidanceModal;
