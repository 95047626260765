import React from 'react';

const MypageCashUseReceiptHtml = () => {
  return (
    <>
      <div className="pay_receipt_wrap">
        <div className="nav"><h2 className="tit">현금영수증 신청</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="summary">
          <p>번호 입력시 - 없이 숫자만 입력해 주세요.</p>
          <p>신청하신 현금 영수증은 국세청에 전산 처리 후 익일부터 출력이 가능합니다.</p>
        </div>
        <div className="form_area">
          <div className="type_option">
            <input type="radio" id="a" name="type" checked="checked"/>
            <label htmlFor="a">소비자 소득 공제용</label>
            <input type="radio" id="b" name="type"/>
            <label htmlFor="b">사업자 지출 증빙용</label>
          </div>
          <input type="text" className="inputbox" autoComplete="off" tabIndex="1" placeholder="휴대전화 번호 입력" />
            <div className="btn_area">
              <button type="button" className="btn">취소</button>
              <button type="submit" className="btn btn_submit">신청하기</button>
            </div>
        </div>
      </div>
    </>
  );
};

export default MypageCashUseReceiptHtml;
