import styled from 'styled-components';

export const Wrap = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 55px;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 200;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 54px;
  min-height: 54px;
  border-bottom: 1px solid #e4e4e4;
  margin-top: ${(props) => (props.isSubNavShown ? '48px' : '0')};
  box-sizing: border-box;
  > h2 {
    font-size: 18px;
  }
`;

export const BackButton = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50px;
  background: url('/images/ic-back.svg') no-repeat center center;
  background-size: 18px;
`;

export const TotalDeleteButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 15px;
  font-size: 13px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  overflow-y: auto;
  flex: 1;

  padding-bottom: 16px;

  li {
    height: 66px;
    min-height: 66px;
    border-bottom: 1px solid #f2f2f2;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
  }
`;

export const Dot = styled.label`
  position: absolute;
  top: 6px;
  left: -8px;
  height: 4px;
  width: 4px;
  background-color: #ed2026;
  border-radius: 2px;
`;

export const Sumarry = styled.div`
  position: relative;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subject = styled.span`
  font-size: 14px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Date = styled.span`
  margin-top: 7px;
  font-size: 11px;
  color: #aaaaaa;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  width: 37px;
  svg {
    height: 16px;
    width: 16px;
    margin-left: auto;
  }
`;

export const NoneContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f4;
  flex: 1;

  img {
    width: 60px;
    margin-bottom: 15px;
  }
  span {
    font-size: 14px;
    color: #666;
  }
`;
