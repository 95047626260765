import React from 'react';
import {Url} from "../../common/constants/environment";

const FooterHtml = () => {
  return (
    <>
      <div id="footer">
        <div className="inner">
          <a className="f_logo" href="/">named</a>
          <button type="button" className="btn_info">사업자 정보</button>
          <div className="company_info">
            <p>(주)KJ Property</p>
            <p>주소 : 서울특별시 강남구 봉은사로 328, 302호(역삼동)</p>
            <p>대표이사 : 한상연 , 사업자등록번호 : 825-81-00766</p>
            <p>통신판매업 신고번호 : 제2017-서울강남-03494</p>
            <p>긴급문의 : help@named.com , 전화번호 : 1566-6447</p>
          </div>
          <div className="corp_area">
            <a className="lnk" href="javascript:;">개인정보취급방침</a>
            <a className="lnk" href="javascript:;">이용약관</a>
            <a className="lnk" href="javascript:;">이메일주소무단수집거부</a>
            <a className="lnk" href="javascript:;">청소년보호정책</a>
            <a className="lnk lnk_pc" href="javascript:;">PC버전보기</a>
          </div>
          <div className="copyright">
            <p>네임드(NAMED)의 모든 컨텐츠 저작권은 (주)KJ Property 에 있으며, 무단 도용 시 법적 불이익을 받습니다.</p>
            <p>네임드(NAMED)는 불법적인 정보제공을 하거나 불법적인 사이트를 알선하지 않습니다. 네임드(NAMED)는 베팅 사이트가 아니며, 스포츠 정보제공 사이트 입니다.</p>
            <p>Copyright © BSS SPORTS. All rights reserved</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default FooterHtml;
