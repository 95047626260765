import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { timeUtils } from '../../../../../../common/utils/timeUtil';
import { useLocation } from 'react-router-dom';
import { LotteryService, ThemeService } from '../../../../../../common/services';

const valueNameMap = {
  ODD: '홀',
  EVEN: '짝',
  LEFT: '좌',
  RIGHT: '우',
  3: '3',
  4: '4',
  LEFT3EVEN: '좌3',
  LEFT4ODD: '좌4',
  RIGHT3ODD: '우3',
  RIGHT4EVEN: '우4',
};

const todayDate = timeUtils.todayYmd();

const PatternGraph = ({ gameType, patternTypeList, isTheme = false }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type') || 'daily';
  const option = query.get('option') || 'oneDay';
  const startDate = query.get('start_dt') || todayDate;
  const round = parseInt(query.get('round')) || 100;

  const [count, setCount] = useState(0);
  const [patternType, setPatternType] = useState('odd_even');
  const [data, setDate] = useState({
    count: {},
    pattern: [],
  });

  useEffect(() => {
    let params = {};

    if (option === 'oneDay') {
      params = { startDate: startDate, endDate: startDate };
    } else if (option === 'latest') {
      params = { round: round };
    }

    params.gameType = gameType;
    params.patternType = patternType;
    if (isTheme) {
      params.type = 'ladder';
      ThemeService.getStatPattern({ ...params }).then((res) => {
        setDate(res.data);
      });
    } else {
      LotteryService.getLotteryStatPattern({ ...params }).then((res) => {
        setDate(res.data);
      });
    }
  }, [option, patternType, startDate, round]);

  useEffect(() => {
    return () => {
      setPatternType(patternTypeList[0].value);
      setCount(0);
    };
  }, [type]);

  let pongdang = 0;
  let oneRepeatCount = 0;
  const sequence = {};
  const patternListComponent = [];
  (data.pattern ?? []).map((item, key) => {
    // 꺽임
    if (sequence[item.value]) {
      sequence[item.value] = Math.max(sequence[item.value], item.round.length);
    } else {
      sequence[item.value] = item.round.length;
    }

    // 최대 퐁당
    if (oneRepeatCount > 0 && item.round.length === 1) {
      oneRepeatCount++;
      if (oneRepeatCount > pongdang) {
        pongdang = oneRepeatCount;
      }
    } else {
      oneRepeatCount = 1;
    }

    if (count > 0 && item.round.length !== count) {
      return;
    }

    patternListComponent.push(
      <dl key={key} className='default'>
        <dt className={clsx(item.value === '3' ? 'ODD' : item.value === '4' ? 'EVEN' : item.value)}>
          {valueNameMap[item.value]}
        </dt>
        {item.round.map((round, key) => {
          return (
            <dd key={key}>
              <span className={clsx('ic', item.value === '3' ? 'ODD' : item.value === '4' ? 'EVEN' : item.value)}>
                {round}
              </span>
            </dd>
          );
        })}
        <dd className='length'>{item.count}</dd>
        <dd className='times'>{item.index}</dd>
      </dl>
    );
  });

  const patternSummaryComponent = useMemo(() => {
    switch (patternType) {
      case 'start_point':
        return (
          <div className='bs'>
            <dl className='ic LEFT'>
              <dt>좌</dt>
              <dd>
                {data.count.start_left_count}번 ({data.count.start_left_percent}
                %) {sequence.LEFT}연속
              </dd>
            </dl>
            <dl className='ic RIGHT'>
              <dt>우</dt>
              <dd>
                {data.count.start_right_count}번 ({data.count.start_right_percent}%) {sequence.RIGHT}연속
              </dd>
            </dl>
          </div>
        );
      case 'line_count':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>3</dt>
              <dd>
                {data.count.line_three_count}번 ({data.count.line_three_percent}
                %) {sequence[3]}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>4</dt>
              <dd>
                {data.count.line_four_count}번 ({data.count.line_four_percent}%) {sequence[4]}연속
              </dd>
            </dl>
          </div>
        );
      case 'combination':
        return (
          <div className='bs'>
            <dl className='ic LEFT4ODD'>
              <dt>좌4</dt>
              <dd>
                {data.count.l4o_count}번 ({data.count.l4o_percent}
                %) {sequence.LEFT4ODD}연속
              </dd>
            </dl>
            <dl className='ic LEFT3EVEN'>
              <dt>좌3</dt>
              <dd>
                {data.count.l3e_count}번 ({data.count.l3e_percent}%) {sequence.LEFT3EVEN}연속
              </dd>
            </dl>
            <dl className='ic RIGHT3ODD'>
              <dt>우3</dt>
              <dd>
                {data.count.r3o_count}번 ({data.count.r3o_percent}
                %) {sequence.LEFT4ODD}연속
              </dd>
            </dl>
            <dl className='ic RIGHT4EVEN'>
              <dt>우4</dt>
              <dd>
                {data.count.r4e_count}번 ({data.count.r4e_percent}%) {sequence.LEFT3EVEN}연속
              </dd>
            </dl>
          </div>
        );
      default:
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀</dt>
              <dd>
                {data.count.odd_count}번 ({data.count.odd_percent}%) {sequence.ODD}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝</dt>
              <dd>
                {data.count.even_count}번 ({data.count.even_percent}%) {sequence.EVEN}연속
              </dd>
            </dl>
          </div>
        );
    }
  }, [patternType, sequence]);

  return (
    <div className='data_pattern_board_wrap'>
      <h3 className='tit'>매 통계</h3>
      <div className='option_type'>
        <select value={count} onChange={(event) => setCount(parseInt(event.target.value))}>
          <option value={0}>기본</option>
          <option value={1}>1매</option>
          <option value={2}>2매</option>
          <option value={3}>3매</option>
          <option value={4}>4매</option>
          <option value={5}>5매</option>
          <option value={6}>6매</option>
        </select>
      </div>
      <div className='option_type'>
        <select value={patternType} onChange={(event) => setPatternType(event.target.value)}>
          {patternTypeList.map((item, key) => {
            return (
              <option key={key} value={item.value}>
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className='pattern_data'>
        <div className='inner'>{patternListComponent}</div>
      </div>
      <div className='pattern_summary'>
        {patternSummaryComponent}
        <dl className='etc'>
          <dt>퐁당 :</dt>
          <dd>{pongdang}번</dd>
          <dt>꺽임 :</dt>
          <dd>{data.pattern ? data.pattern.length : ''}번</dd>
        </dl>
      </div>
    </div>
  );
};

export default PatternGraph;
