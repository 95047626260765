import React, { useEffect, useState } from "react"
import {
	PayTypeWrapper,
	PayTypeItem,
	PayTypeImg,
	ItemCount,
	Price,
	ButtonQuantity,
	Count,
	Buttons,
} from "./styled"
import { RegexUtil } from "../../../../../common/utils/regexUtil"
import { getColorPayType } from "./helpers"

const SelectPayType = ({
	medal,
	onClickBuy,
}) => {
	const [count, setCount] = useState(1)

	const handleClickBuy = () => onClickBuy(count, getColorPayType('is_medal'));

	return (
		<PayTypeWrapper>
			{/* Below code is commented to make sure it is synced with PC web, however we will need
			it soon so we keep it here and un-comment them later. */}
			{/* <PayTypeItem
				key={'gold'}
				active={false}
			>
				<PayTypeImg
					checked={false}
					color={"GOLD"}
				/>
				<div>캐시로 구매불가</div>
			</PayTypeItem>

			<PayTypeItem
				key={'point'}
				active={false}
			>
				<PayTypeImg
					checked={false}
					color={"POINT"}
				/>
				<div>포인트로 구매불가</div>
			</PayTypeItem> */}

			<PayTypeItem
				key={'medal'}
				active
			>
				<PayTypeImg
					checked
					color={'MEDAL'}
				/>
				<Price color={'MEDAL'} checked>
					{RegexUtil.makeComma(medal)}
				</Price>
				<Price color={'MEDAL'} checked>건빵</Price>
				<ItemCount>
					<ButtonQuantity
						onClick={() => {
							if (count <= 1) return
							setCount(count - 1)
						}}
						className={getColorPayType('is_medal') + ' minus'}
						disabled={count === 1}
					>
					</ButtonQuantity>
					<Count>
						<span>{count}</span>
					</Count>
					<ButtonQuantity
						onClick={() => {
							setCount(count + 1)
						}}
						className={getColorPayType('is_medal') + ' plus'}
					>
					</ButtonQuantity>
				</ItemCount>
			</PayTypeItem>

			<Buttons buyColor={"#FBA019"}>
				<button className={"buy"} onClick={handleClickBuy}>
					구매신청
				</button>
			</Buttons>
		</PayTypeWrapper>
	)
}

export default SelectPayType
