import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import { Buttons, Form, Label } from './styled';

const TradeWrite = observer(({ tab, onClose, onSubmitCallback }) => {
  const { mypageStore } = useMypageStore(MypageContext);
  const [type, setType] = useState(tab);
  const [itemId, setItemId] = useState('');
  const [title, setTitle] = useState('');
  const [point, setPoint] = useState('');

  useEffect(() => {
    if (type === 'sell') {
      mypageStore.actionMyItemList({ category: '', allowTrade: 'y' });
    } else {
      mypageStore.actionTradeMarketItems();
    }
  }, [type]);

  const onSubmit = async (event) => {
    event.preventDefault();
    mypageStore
      .actionTradeAdd({
        type,
        title,
        itemId,
        point
      })
      .then(({ code }) => {
        onSubmitCallback(code);
      });
  };

  return (
    <div className="account_info_wrap">
      <div className="nav">
        <h2 className="tit">팝니다 등록하기</h2>
        <button className={'btn_close'} onClick={onClose} />
      </div>
      <Form onSubmit={onSubmit}>
        <Label className={'type'}>
          <span>거래방식</span>
          <select
            value={type}
            onChange={(event) => setType(event.target.value)}
          >
            <option value={'sell'}>팝니다</option>
            <option value={'buy'}>삽니다</option>
          </select>
        </Label>
        <Label>
          <span>판매 아이텝</span>
          <select
            value={itemId}
            onChange={(event) => setItemId(event.target.value)}
          >
            <option value={''}>판매할 아이템을 선택하세요.</option>
            {mypageStore.data.list.map((item, i) => {
              return (
                <option value={tab === 'sell' ? item.no : item.id} key={i}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </Label>
        <Label>
          <span>거래코멘트</span>
          <input
            type="text"
            placeholder={'입력하세요.'}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Label>
        <Label className={'price'}>
          <span>거래가격</span>
          <input
            type="text"
            placeholder={'0'}
            value={point}
            onChange={(event) => setPoint(event.target.value)}
          />
        </Label>
        <Buttons>
          <button type={'button'} onClick={onClose}>
            취소
          </button>
          <button type={'submit'}>등록</button>
        </Buttons>
      </Form>
      <div className="tip">
        <p>거래가능한 아이템은 선물이 가능한 아이템들 입니다.</p>
        <p className="c_999">
          거래 등록시 아이템은 사용되며, 거래가 진행되지 않은 아이템은 취소할수
          있습니다.
        </p>
        <p className="c_999">
          거래 아이템에는 보너스 포인트가 포함되지 않습니다.
        </p>
        <p className="c_999">
          포인트를 잘못 기입하여 발생하는 문제에 대해서는 책임지지 않으니,
          등록시 주의바랍니다.
        </p>
      </div>
    </div>
  );
});

export default TradeWrite;
