import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { timeUtils } from '../../../../../../common/utils/timeUtil';
import { useLocation } from 'react-router-dom';
import { LotteryService } from '../../../../../../common/services';

const valueNameMap = {
  ODD: '홀',
  EVEN: '짝',
  UNDER: '언더',
  OVER: '오버',
  HOME: '좌',
  DRAW: '무',
  AWAY: '우',
  UNDERSPACE8: '언',
  OVERSPACE8: '오',
  0: '0',
};

const todayDate = timeUtils.todayYmd();

const PatternGraph = ({ gameName, patternTypeList }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type') || 'daily';
  const option = query.get('option') || 'oneDay';
  const startDate = query.get('start_dt') || todayDate;
  const round = parseInt(query.get('round')) || 100;

  const [count, setCount] = useState(0);
  const [patternType, setPatternType] = useState('runningball_odd_even');
  const [patternTypeSpace8, setPatternTypeSpace8] = useState('count_win');
  const [data, setDate] = useState({
    count: {},
    pattern: [],
  });

  useEffect(() => {
    let params = {};

    if (option === 'oneDay') {
      params = { startDate: startDate, endDate: startDate };
    } else if (option === 'latest') {
      params = { round: round };
    }

    params.gameType = gameName;
    if (gameName === 'space8') {
      params.patternType = patternTypeSpace8;
    } else {
      params.patternType = patternType;
    }

    LotteryService.getLotteryStatPattern({ ...params }).then((res) => {
      setDate(res.data);
    });
  }, [option, patternType, patternTypeSpace8, startDate, round]);

  if (gameName === 'space8') {
    useEffect(() => {
      return () => {
        setPatternTypeSpace8(patternTypeList[0].value);
        setCount(0);
      };
    }, [type]);
  } else {
    useEffect(() => {
      return () => {
        setPatternType(patternTypeList[0].value);
        setCount(0);
      };
    }, [type]);
  }

  let pongdang = 0;
  let oneRepeatCount = 0;
  const sequence = {};
  const patternListComponent = [];
  data.pattern &&
    data.pattern.map((item, key) => {
      // 꺽임
      if (sequence[item.value]) {
        sequence[item.value] = Math.max(sequence[item.value], item.round.length);
      } else {
        sequence[item.value] = item.round.length;
      }

      // 최대 퐁당
      if (oneRepeatCount > 0 && item.round.length === 1) {
        oneRepeatCount++;
        if (oneRepeatCount > pongdang) {
          pongdang = oneRepeatCount;
        }
      } else {
        oneRepeatCount = 1;
      }

      if (count > 0 && item.round.length !== count) {
        return;
      }

      if (gameName !== 'space8') {
        patternListComponent.push(
          <dl key={key} className='default'>
            <dt className={clsx(item.value)}>{valueNameMap[item.value]}</dt>
            {item.round.map((round, key) => {
              return (
                <dd key={key}>
                  <span className={clsx('ic', item.value)}>{round}</span>
                </dd>
              );
            })}
            <dd className='length'>{item.count}</dd>
            <dd className='times'>{item.index}</dd>
          </dl>
        );
      } else {
        var value = item.value;
        if (item.value === 'UNDER') {
          value = 'UNDERSPACE8';
        }
        if (item.value === 'OVER') {
          value = 'OVERSPACE8';
        }
        patternListComponent.push(
          <dl key={key} className='default'>
            <dt className={clsx(item.value)}>{valueNameMap[value]}</dt>
            {item.round.map((round, key) => {
              return (
                <dd key={key}>
                  <span className={clsx('ic', item.value)}>{round}</span>
                </dd>
              );
            })}
            <dd className='length'>{item.count}</dd>
            <dd className='times'>{item.index}</dd>
          </dl>
        );
      }
    });

  const patternSummaryComponent = useMemo(() => {
    switch (patternType) {
      case 'runningball_under_over':
        return (
          <div className='bs'>
            <dl className='ic UNDER'>
              <dt>언더</dt>
              <dd>
                {data.count.powerball_under_count}번 ({data.count.powerball_under_percent}%) {sequence.UNDER}연속
              </dd>
            </dl>
            <dl className='ic OVER'>
              <dt>오버</dt>
              <dd>
                {data.count.powerball_over_count}번 ({data.count.powerball_over_percent}%) {sequence.OVER}연속
              </dd>
            </dl>
          </div>
        );
      case 'sum_odd_even':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀</dt>
              <dd>
                {data.count.sum_odd_count}번 ({data.count.sum_odd_percent}%) {sequence.ODD}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝</dt>
              <dd>
                {data.count.sum_even_count}번 ({data.count.sum_even_percent}%) {sequence.EVEN}연속
              </dd>
            </dl>
          </div>
        );
      case 'sum_under_over':
        return (
          <div className='bs'>
            <dl className='ic UNDER'>
              <dt>언더</dt>
              <dd>
                {data.count.sum_under_count}번 ({data.count.sum_under_percent}%) {sequence.UNDER}연속
              </dd>
            </dl>
            <dl className='ic OVER'>
              <dt>오버</dt>
              <dd>
                {data.count.sum_over_count}번 ({data.count.sum_over_percent}%) {sequence.OVER}연속
              </dd>
            </dl>
          </div>
        );
      default:
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀</dt>
              <dd>
                {data.count.powerball_odd_count}번 ({data.count.powerball_odd_percent}%) {sequence.ODD}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝</dt>
              <dd>
                {data.count.powerball_even_count}번 ({data.count.powerball_even_percent}%) {sequence.EVEN}연속
              </dd>
            </dl>
          </div>
        );
    }
  }, [patternType, sequence]);

  const patternSummaryComponentSpace8 = useMemo(() => {
    switch (patternTypeSpace8) {
      case 'sum_win':
        return (
          <div className='bs'>
            <dl className='ic HOME'>
              <dt>좌</dt>
              <dd>
                {data.count.home_sum_win_count}번 ({data.count.home_sum_win_percent}%) {sequence.HOME}연속
              </dd>
            </dl>
            <dl className='ic DRAW'>
              <dt>무</dt>
              <dd>
                {data.count.draw_sum_win_count}번 ({data.count.draw_sum_win_percent}%) {sequence.DRAW}연속
              </dd>
            </dl>
            <dl className='ic AWAY'>
              <dt>우</dt>
              <dd>
                {data.count.away_sum_win_count}번 ({data.count.away_sum_win_percent}%) {sequence.AWAY}연속
              </dd>
            </dl>
          </div>
        );
      case 'sum_home_ball_odd_even':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀</dt>
              <dd>
                {data.count.sum_home_ball_odd_count}번 ({data.count.sum_home_ball_odd_percent}%) {sequence.ODD}연속
              </dd>
            </dl>
            <dl className='ic DRAW'>
              <dt>0</dt>
              <dd>
                {data.count.sum_home_ball_zero_count}번 ({data.count.sum_home_ball_zero_percent}%) {sequence.DRAW}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝</dt>
              <dd>
                {data.count.sum_home_ball_even_count}번 ({data.count.sum_home_ball_even_count}%) {sequence.EVEN}연속
              </dd>
            </dl>
          </div>
        );
      case 'sum_away_ball_odd_even':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀</dt>
              <dd>
                {data.count.sum_away_ball_odd_count}번 ({data.count.sum_away_ball_odd_percent}%) {sequence.ODD}연속
              </dd>
            </dl>
            <dl className='ic DRAW'>
              <dt>0</dt>
              <dd>
                {data.count.sum_away_ball_zero_count}번 ({data.count.sum_away_ball_zero_percent}%) {sequence.DRAW}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝</dt>
              <dd>
                {data.count.sum_away_ball_even_count}번 ({data.count.sum_away_ball_even_count}%) {sequence.EVEN}연속
              </dd>
            </dl>
          </div>
        );
      case 'home_ball1_odd_even':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀</dt>
              <dd>
                {data.count.home_ball1_odd_count}번 ({data.count.home_ball1_odd_percent}%) {sequence.ODD}연속
              </dd>
            </dl>
            <dl className='ic DRAW'>
              <dt>0</dt>
              <dd>
                {data.count.home_ball1_zero_count}번 ({data.count.home_ball1_oe_zero_percent}%) {sequence.DRAW}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝</dt>
              <dd>
                {data.count.home_ball1_even_count}번 ({data.count.home_ball1_even_percent}%) {sequence.EVEN}연속
              </dd>
            </dl>
          </div>
        );
      case 'away_ball1_odd_even':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀</dt>
              <dd>
                {data.count.away_ball1_odd_count}번 ({data.count.away_ball1_odd_percent}%) {sequence.ODD}연속
              </dd>
            </dl>
            <dl className='ic DRAW'>
              <dt>0</dt>
              <dd>
                {data.count.away_ball1_zero_count}번 ({data.count.away_ball1_oe_zero_percent}%) {sequence.DRAW}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝</dt>
              <dd>
                {data.count.away_ball1_even_count}번 ({data.count.away_ball1_even_percent}%) {sequence.EVEN}연속
              </dd>
            </dl>
          </div>
        );
      case 'home_ball1_under_over':
        return (
          <div className='bs'>
            <dl className='ic UNDER'>
              <dt>언</dt>
              <dd>
                {data.count.home_ball1_under_count}번 ({data.count.home_ball1_under_percent}%) {sequence.UNDER}연속
              </dd>
            </dl>
            <dl className='ic DRAW'>
              <dt>0</dt>
              <dd>
                {data.count.home_ball1_uo_zero_count}번 ({data.count.home_ball1_uo_zero_percent}%) {sequence.DRAW}연속
              </dd>
            </dl>
            <dl className='ic OVER'>
              <dt>오</dt>
              <dd>
                {data.count.home_ball1_over_count}번 ({data.count.home_ball1_over_percent}%) {sequence.OVER}연속
              </dd>
            </dl>
          </div>
        );
      case 'away_ball1_under_over':
        return (
          <div className='bs'>
            <dl className='ic UNDER'>
              <dt>언</dt>
              <dd>
                {data.count.away_ball1_under_count}번 ({data.count.away_ball1_under_percent}%) {sequence.UNDER}연속
              </dd>
            </dl>
            <dl className='ic DRAW'>
              <dt>0</dt>
              <dd>
                {data.count.away_ball1_uo_zero_count}번 ({data.count.away_ball1_uo_zero_percent}%) {sequence.DRAW}연속
              </dd>
            </dl>
            <dl className='ic OVER'>
              <dt>오</dt>
              <dd>
                {data.count.away_ball1_over_count}번 ({data.count.away_ball1_over_percent}%) {sequence.OVER}연속
              </dd>
            </dl>
          </div>
        );
      default:
        return (
          <div className='bs'>
            <dl className='ic HOME'>
              <dt>좌</dt>
              <dd>
                {data.count.home_count_win_count}번 ({data.count.home_count_win_percent}%) {sequence.HOME}연속
              </dd>
            </dl>
            <dl className='ic DRAW'>
              <dt>무</dt>
              <dd>
                {data.count.draw_count_win_count}번 ({data.count.draw_count_win_percent}%) {sequence.DRAW}연속
              </dd>
            </dl>
            <dl className='ic AWAY'>
              <dt>우</dt>
              <dd>
                {data.count.away_count_win_count}번 ({data.count.away_count_win_percent}%) {sequence.AWAY}연속
              </dd>
            </dl>
          </div>
        );
    }
  }, [patternTypeSpace8, sequence]);

  if (gameName !== 'space8') {
    return (
      <div className='data_pattern_board_wrap'>
        <h3 className='tit'>매 통계</h3>
        <div className='option_type'>
          <select value={count} onChange={(event) => setCount(parseInt(event.target.value))}>
            <option value={0}>기본</option>
            <option value={1}>1매</option>
            <option value={2}>2매</option>
            <option value={3}>3매</option>
            <option value={4}>4매</option>
            <option value={5}>5매</option>
            <option value={6}>6매</option>
          </select>
        </div>
        <div className='option_type'>
          <select value={patternType} onChange={(event) => setPatternType(event.target.value)}>
            {patternTypeList.map((item, key) => {
              return (
                <option key={key} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className='pattern_data'>
          <div className='inner'>{patternListComponent}</div>
        </div>
        <div className='pattern_summary'>
          {patternSummaryComponent}
          <dl className='etc'>
            <dt>퐁당 :</dt>
            <dd>{pongdang}번</dd>
            <dt>꺽임 :</dt>
            <dd>{data.pattern ? data.pattern.length : ''}번</dd>
          </dl>
        </div>
      </div>
    );
  } else {
    return (
      <div className='data_pattern_board_wrap'>
        <h3 className='tit'>매 통계</h3>
        <div className='option_type'>
          <select value={count} onChange={(event) => setCount(parseInt(event.target.value))}>
            <option value={0}>기본</option>
            <option value={1}>1매</option>
            <option value={2}>2매</option>
            <option value={3}>3매</option>
            <option value={4}>4매</option>
            <option value={5}>5매</option>
            <option value={6}>6매</option>
          </select>
        </div>
        <div className='option_type'>
          <select value={patternTypeSpace8} onChange={(event) => setPatternTypeSpace8(event.target.value)}>
            {patternTypeList.map((item, key) => {
              return (
                <option key={key} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className='pattern_data'>
          <div className='inner'>{patternListComponent}</div>
        </div>
        <div className='pattern_summary rball_space'>
          {patternSummaryComponentSpace8}
          <dl className='etc'>
            <dt>퐁당 :</dt>
            <dd>{pongdang}번</dd>
            <dt>꺽임 :</dt>
            <dd>{data.pattern ? data.pattern.length : ''}번</dd>
          </dl>
        </div>
      </div>
    );
  }
};

export default PatternGraph;
