import React from 'react';

const ProtoHtml = () => {
  return (
    <>
      {/* proto_wrap */}
      <div className="proto_wrap">

        {/* proto_summary */}
        <div className="proto_summary">
          <button type="button" className="btn btn_history">저장내역</button>
          <div className="date_area">
            <a className="btn_prev" href="javascript:;">이전 회차</a>
            <strong className="date">승부식 78회차</strong>
            <a className="btn_next" href="javascript:;">다음 회차</a>
          </div>
          <button type="button" className="btn btn_calculator">배당 계산기</button>
        </div>
        {/* // proto_summary */}

        {/* proto_list */}
        <div className="proto_list">

          {/* item */}
          <div className="item">
            <div className="summary">
              <span className="no">008</span>
              <span className="date">01.09(월) 19:00</span>
              <span className="league basketball">NBA</span>
              <button type="button" className="btn_favorites selected">관심경기 추가</button>
            </div>
            <div className="box">
              <div className="team left">
                <span className="name">St George Willawong FC</span>
              </div>
              <div className="info">
                <div className="score">
                  <strong className="left on">
                    <em className="num">94</em>
                  </strong>
                  <strong className="right">
                    <em className="num">78</em>
                  </strong>
                </div>
                <div className="state win">핸승</div> {/* class : win, over, under */}
              </div>
              <div className="team right">
                <div className="name">FC 라다 톨리야티</div>
              </div>
            </div>
            <div className="odds">
              <span className="type handicap">+1.0</span>
              <span className="odd">
                <em className="on">2.5</em>
                <em>-</em>
                <em>1.5</em>
              </span>
            </div>
          </div>
          {/* // item */}

          {/* item */}
          <div className="item">
            <div className="summary">
              <span className="no">008</span>
              <span className="date">01.09(월) 19:00</span>
              <span className="league basketball">NBA</span>
              <button type="button" className="btn_favorites selected">관심경기 추가</button>
            </div>
            <div className="box">
              <div className="team left">
                <span className="name">St George Willawong FC</span>
              </div>
              <div className="info">
                <div className="score">
                  <strong className="left on">
                    <em className="num">94</em>
                  </strong>
                  <strong className="right">
                    <em className="num">78</em>
                  </strong>
                </div>
                <div className="state">전반</div> {/* class : win, over, under */}
              </div>
              <div className="team right">
                <div className="name">FC 라다 톨리야티</div>
              </div>
            </div>
            <div className="odds">
              <span className="type unover">+1.0</span>
              <span className="odd">
                <em className="up">2.5</em>
                <em>-</em>
                <em className="on">1.5</em>
              </span>
              <span className="total">5.0</span>
            </div>
          </div>
          {/* // item */}

          {/* item */}
          <div className="item ready">
            <div className="summary">
              <span className="no">008</span>
              <span className="date">01.09(월) 19:00</span>
              <span className="league basketball">NBA</span>
              <button type="button" className="btn_favorites selected">관심경기 추가</button>
            </div>
            <div className="box">
              <div className="team left">
                <span className="name">St George Willawong FC</span>
              </div>
              <div className="info">
                <div className="score">
                  <strong className="left">
                    <em className="num"></em>
                  </strong>
                  <strong className="right">
                    <em className="num"></em>
                  </strong>
                </div>
                <div className="state">대기</div> {/* class : win, over, under */}
              </div>
              <div className="team right">
                <div className="name">FC 라다 톨리야티</div>
              </div>
            </div>
            <div className="odds">
            <span className="odd">
              <em>2.5</em>
              <em>-</em>
              <em>1.5</em>
            </span>
            </div>
          </div>
          {/* // item */}

        </div>
        {/* // proto_list */}

      </div>
      {/* // proto_wrap */}

    </>
  );
};

export default ProtoHtml;
