import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

const RoundTable = ({ list = [], title = '회차별 통계' }) => {
  const size = 10;
  const [page, setPage] = useState(1);
  const totalPage = useMemo(() => {
    if (list.length === 0) return 1;
    return Math.ceil(list.length / size);
  }, [list.length]);

  return (
    <div className="data_list_wrap">
      <h3 className="tit">{title}</h3>
      <div className="data_list">
        <div className="table_float">
          <table className="list" border="1">
            <caption>결과리스트</caption>
            <colgroup>
              <col width="74px" />
            </colgroup>
            <thead>
              <tr>
                <th>회차</th>
              </tr>
            </thead>
            <tbody>
              {list.slice(0, size * page).map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{`${item.date_round} (${item.round})`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="table_data powerball">
          <table className="list" border="1">
            <caption>결과리스트</caption>
            <colgroup>
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
              <col width="99px" />
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
              <col width="49px" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="4">파워볼</th>
                <th colSpan="6">일반볼합</th>
              </tr>
              <tr>
                <th>결과</th>
                <th>홀짝</th>
                <th>언오버</th>
                <th>구간</th>
                <th>결과</th>
                <th>합</th>
                <th>구간</th>
                <th>홀짝</th>
                <th>언오버</th>
                <th>대중소</th>
              </tr>
            </thead>
            <tbody>
              {list.slice(0, size * page).map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{item.powerball}</td>
                    <td>
                      <span className={clsx('ic', item.powerball_odd_even)}>
                        {item.powerball_odd_even === 'ODD' ? '홀' : '짝'}
                      </span>
                    </td>
                    <td>
                      <span className={clsx('ic', item.powerball_unover)}>
                        {item.powerball_unover === 'OVER' ? '오버' : '언더'}
                      </span>
                    </td>
                    <td>
                      <span className={clsx('ic', item.powerball_section)}>
                        {item.powerball_section}
                      </span>
                    </td>
                    <td>
                      <span>{item.ball_1}</span>,<span>{item.ball_2}</span>,
                      <span>{item.ball_3}</span>,<span>{item.ball_4}</span>,
                      <span>{item.ball_5}</span>
                    </td>
                    <td>{item.sum}</td>
                    <td>
                      <span className={clsx('ic', item.sum_section)}>
                        {item.sum_section}
                      </span>
                    </td>
                    <td>
                      <span className={clsx('ic', item.sum_odd_even)}>
                        {item.sum_odd_even === 'ODD' ? '홀' : '짝'}
                      </span>
                    </td>
                    <td>
                      <span className={clsx('ic', item.sum_unover)}>
                        {item.sum_unover === 'OVER' ? '오버' : '언더'}
                      </span>
                    </td>
                    <td>
                      <span className={clsx('ic', item.sum_size)}>
                        {item.sum_size === 'L'
                          ? '대'
                          : item.sum_size === 'M'
                          ? '중'
                          : '소'}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <button
        className="btn_more"
        style={page === totalPage ? { display: 'none' } : {}}
        onClick={() => {
          if (page >= totalPage) return;
          setPage(page + 1);
        }}
      >
        더보기 ( {page} / {totalPage} )
      </button>
    </div>
  );
};

export default RoundTable;
