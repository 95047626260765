import React, { useEffect, useState } from 'react';
import { LotteryService } from '../../../../../common/services';
import RoundTable from '../analysis/roundTable/index';
import DhRoundTable from "../analysis/roundTable/DhRoundTable";

const PowerballResult = ({ brandName,gameName }) => {
  const [data, setData] = useState();

  useEffect(() => {
    LotteryService.getLotteryRecentList(brandName,gameName).then((res) => {
      setData(res.data);
    });

    return () => {};
  }, [brandName, gameName]);

  if(brandName === "dhlottery"){
    return <DhRoundTable list={data} title={'회차별 결과'} size={20} />;
  }else{
    return <RoundTable list={data} title={'회차별 결과'} size={20} />;
  }

};

export { PowerballResult };
