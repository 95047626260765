import React, { useEffect, useRef } from "react"
import { Background, Wrap, CloseButton } from "./styled"
import { ImageUtil } from "../../../../../common/utils/imageUtil"
import { RegexUtil } from "../../../../../common/utils/regexUtil"
import { MypageContext, useMypageStore } from "../../../context"
import useModalStore from "../../../../../hooks/useModalStore"
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock"
import SelectPayType from "./SelectPayType"

const ItemModal = ({ isOpen, onClose, item }) => {
	const bodyScroll = useRef()

	const { brand_name, product_name, price, product_image, id, category_id } = item
	const { mypageStore } = useMypageStore(MypageContext)
	const modalStore = useModalStore()

	useEffect(() => {
		if (!isOpen) {
			enableBodyScroll(bodyScroll.current)
		} else {
			disableBodyScroll(bodyScroll.current)
		}
	}, [isOpen, bodyScroll.current])

	const onClickBuy = (count, payTypeSelected) => {
		modalStore.openConfirmModal({
			message: `${item.product_name} 기프티콘 구매 신청을 하시겠습니까?`,
			okCallback: () => {
				mypageStore.actionGifticonItemBuy({
					product_name,
					productId: id,
					count: count,
					payTypeSelected: 'is_medal'
				});
				onClose();
			},
		})
	}

	return (
		<Background isOpen={isOpen} onClick={onClose}>
			<Wrap
				ref={bodyScroll}
				onClick={(event) => {
					event.stopPropagation()
				}}
			>
				<CloseButton onClick={onClose} />
				<img src={ImageUtil.imageUrl(product_image, 101, 101)} />
				<p className={"brand_name"}>{brand_name}</p>
				<p className={"product_name"}>{product_name}</p>
				<SelectPayType
					medal={parseInt(price, 10)}
					onClickBuy={onClickBuy}
				/>
			</Wrap>
		</Background>
	)
}

export default ItemModal
