import React from 'react';
import { observer } from 'mobx-react';
import { CertPhoneComponent } from '../../../components/cert';

const Pw2Set = observer(({ history }) => {
  const resultUrl = `${window.location.origin}/mypage/pw2_set_result`;

  const onClickEmail = () => {
    history.push('/mypage/pw2_set_email');
  };

  return (
    <>
      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">2차 비밀번호 설정</h2>
          <button className="btn_prev">이전으로</button>
        </div>
        <div className="sleeper_area">
          <div className="inner">
            <dl className="info">
              <dt>2차 비밀번호란?</dt>
              <dd>
                <p>
                  회원님의 계정을 더욱 안전하게 보호하기 위한 수단이며 포인트
                  선물하기시 사용 됩니다.
                </p>
                <p>
                  2차 비밀번호 설정을 위해서는 아래의 인증 방법중 한가지를 통해
                  본인 인증 절차가 필요합니다.
                </p>
              </dd>
            </dl>
            <div className="certification">
              <CertPhoneComponent resultUrl={resultUrl} />
              <button className="btn btn_email" onClick={onClickEmail}>
                이메일 인증
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Pw2Set;
