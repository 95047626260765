import autobind from 'autobind-decorator';
import { computed, extendObservable, observable } from 'mobx';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import { Url } from '../../../../../common/constants/environment';

@autobind
class CommunityCommentModel {
  @observable
  isRecomment = false;

  @observable
  isReReply = false;

  @observable
  reReplyNick = null;

  constructor(data, isRecomment, reReplyNick = null) {
    this.isRecomment = isRecomment;

    if (reReplyNick !== null) {
      this.isReReply = true;
      this.reReplyNick = reReplyNick;
    }

    extendObservable(this, data);
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get date() {
    let date = this.wr_datetime;

    if (!date.includes('T')) {
      date = date.replace(' ', 'T');
    }

    return timeUtils.yyyyMMddHHmm(date);
  }

  @computed
  get user() {
    const { member_info, mb_nick, mb_id } = this;

    if (!!member_info) {
      const copy = { ...member_info };
      let profileImg = '/images/thumb_user_def.png';

      if (!!member_info.profile_img) {
        profileImg = `${Url.thumb}normal/crop/112x112${member_info.profile_img}`;
      }

      copy.profile_img = profileImg;

      return { ...copy, nick: mb_nick, id: mb_id };
    } else {
      return {
        level: '0',
        profile_img: '/images/thumb_user_def.png',
        today_word: '',
        gender: null,
        level_title: '',
        nick_color: null,
        nick: '',
        id: 0
      };
    }
  }
}

export default CommunityCommentModel;
