import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const GoBack = ({ isRecord = false, fromHome = false }) => {
  const history = useHistory();
  const { location } = history;
  const [recentSports, setRecentSports] = useState('major');

  useEffect(() => {
    const recentScorePath = window.localStorage.getItem('recentSports');
    if (recentScorePath !== null) {
      setRecentSports(recentScorePath);
    }
  }, [location]);

  const onClickBack = () => {
    if (fromHome) {
      return history.replace(`/home`);
    }

    if (isRecord) {
      history.push(`/score/${recentSports}`);
    } else {
      history.goBack();
    }
  };

  return (
    <button className="btn_prev" onClick={onClickBack}>
      이전으로
    </button>
  );
};

export default GoBack;
