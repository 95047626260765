import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

const Item = observer(({ item, setNick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickAdd = () => {
    setNick(item['mb_nick']);
  }

  useEffect(() => {
    if (!item) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [])

  return (
    <>
      {
        !isLoading ? <ul style={{ clear: 'both' }}>
          <li style={{ float: 'left', marginLeft: '10px' }}>{item.mb_nick}</li>
          <li style={{ float: 'left', marginLeft: '10px' }}><button type="button" onClick={onClickAdd}>+</button></li>
        </ul> : <></>
      }
    </>
  );
});

export {
  Item
};
