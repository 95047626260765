import autobind from 'autobind-decorator';
import Api from '../../api';
import { Url } from '../../../constants/environment';

@autobind
class ProtoService extends Api {
  constructor() {
    super();
  }

  /**
   * @description
   * @param {*} pageNumber
   * @param {number} [pageSize=20]
   * @returns
   */
  getOddsCalculator(pickStatus, pageNumber, pageSize = 20, roundId = null) {
    let param = '';

    if (pickStatus.length > 0) {
      param = `pick-status=${pickStatus}&`;
    }

    if (!!roundId) {
      param += `round-id=${roundId}&`;
    }

    return this.ajax(
      'get',
      Url.sports,
      `/sports/odds/calculators?${param}page-number=${pageNumber}&page-size=${pageSize}`,
      null,
      true
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} calculatorId
   * @returns
   */
  getOddsCalculatorById(calculatorId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/odds/calculators/${calculatorId}`,
      null,
      true
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   *
   * @param {*} data
   *  {
        "no": 0,
        "oddsIds": [
          0
        ],
        "point": 0
      }
   * @returns
   */
  postOddsCalculator(data) {
    return this.ajax(
      'post',
      Url.sports,
      `/sports/odds/calculator`,
      data,
      true
    ).then((res) => {
      // console.log('res => ', res);
      return res
    }).catch(error => {
      return error.response;
    });
  }
}

export default new ProtoService();
