import React, { useEffect, useState } from 'react';
import { RegexUtil } from '../../../../common/utils/regexUtil';

const MypageExpList = ({ items = [] }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (items.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, []);

  return (
      <div className="list">
        {items.map((item, i) => (
          <div className="item" key={i}>
            <span className="info">
              <em className="tx">{item.po_content}</em>
              <em className="date">{item.po_datetime}</em>
            </span>
            <span className={item.po_exp <= 0 ? 'num minus' : 'num'}>
              {item.po_exp !== undefined
                ? item.po_exp > 0
                  ? '+' + RegexUtil.makeComma(item.po_exp)
                  : RegexUtil.makeComma(item.po_exp)
                : 0}
            </span>
          </div>
        ))}
      </div>
  );
};

export { MypageExpList };
