import Api from '../../api';
import { Url } from '../../../constants/environment';

class ThemeService extends Api {
  constructor() {
    super();
    this.URL = 'theme';
  }

  async getStatPattern({
    type,
    gameType,
    patternType,
    startDate,
    endDate,
    round
  }) {
    let url = `${this.URL}/${type}/stat/pattern?`;
    gameType && (url += `&game_type=${gameType}`);
    patternType && (url += `&pattern_type=${patternType}`);
    startDate && (url += `&start_dt=${startDate}`);
    endDate && (url += `&end_dt=${endDate}`);
    round && (url += `&round_count=${round}`);

    const res = await this.commonAjax('get', Url.named, url);

    return res;
  }

  async getStatAnalysis({
    type,
    gameType,
    startDate,
    endDate,
    roundCount,
    round,
    searchType
  }) {
    let url = `${this.URL}/${type}/stat/analysis?`;
    gameType && (url += `&game_type=${gameType}`);
    startDate && (url += `&start_dt=${startDate}`);
    endDate && (url += `&end_dt=${endDate}`);
    roundCount && (url += `&round_count=${roundCount}`);
    round && (url += `&round=${round}`);
    searchType && (url += `&search_type=${searchType}`);

    const res = await this.commonAjax('get', Url.named, url);

    return res;
  }

  async getStatAnalysisPattern({ type, gameType, patternType, count }) {
    let url = `${this.URL}/${type}/stat/analysis/pattern?`;
    gameType && (url += `&game_type=${gameType}`);
    patternType && (url += `&pattern_type=${patternType}`);
    count && (url += `&count=${count}`);

    const res = await this.commonAjax('get', Url.named, url);

    return res;
  }

  async getThemePickList(gameType, pickType, limit, offset, date, round) {
    const data = {
      game_type: gameType,
      limit: limit,
      offset: offset,
      date: date,
      round : round
    };

    if (pickType !== null) {
      data['pick_type'] = pickType;
    }
    const res = await this.commonAjax('get', Url.named, 'theme/pick', data);

    return res;
  }

  async getThemePickCommentList(gameType, pickType, limit, offset, date, round) {
    const data = {
      game_type: gameType,
      limit: limit,
      offset: offset,
      date: date,
      round : round
    };

    if (pickType !== null) {
      data['pick_type'] = pickType;
    }
    const res = await this.commonAjax('get', Url.named, 'theme/pick', data);

    return res;
  }

  async insertThemePickComment(gameType, pickType, pick, pickComment) {
    const form = new FormData();
    form.append('game_type', gameType);
    form.append('pick_type', pickType);
    form.append('pick', pick);
    form.append('comment', pickComment);

    const res = await this.commonAjax('post', Url.named, 'theme/pick', form);

    return res;
  }

  async getRoomChatList(filePath) {
    const res = await this.commonAjax('get', '', filePath);

    return res;
  }
}

export default new ThemeService();
