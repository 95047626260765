import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import {
  NoneContentWrap,
  MessageListContainer,
  MessageHeader,
  Title,
  ActionSpan,
  MessageListUl,
  BottomMenu,
  BottomMenuItem,
  MessageItemWrapper,
  DotWrapper,
  ButtonWrapper,
  DotSubWrapper,
  TitleWrapper,
  BackButtonContainer,
  MessageContentWrapper,
  MessageMetadataContainer,
  MessageListUlContainer
} from './styled';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import useUsersStore from '../../../../hooks/useUsersStore';
import useModalStore from '../../../../hooks/useModalStore';
import useTabFocus from '../../../../hooks/useTabFocus';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import SportsLoader from '../../../sportsLoader';
import { getChatHostname } from '../../../../common/utils/hostnameUtil'
import { copySessionToPcWeb } from '../../../../common/utils/sessionUtil';

// InboxLayer is placed here as a component to support
// in the future the InboxLayer component will be displayed like notification (a layer)
// But for now, it is displayed as a page via mypage/memo
const InboxLayer = observer(({ setOpenInbox }) => {
  const modal = useModalStore();
  let { chatHostNameWithProtocol } = getChatHostname();

  const history = useHistory();

  const notyWrap = useRef();
  const usersStore = useUsersStore();
  const [loading, setLoading] = useState(true);

  const fetchInboxList = useRef(
    debounce(() => {
      usersStore.getInboxList().then((r) => {
        setLoading(false);
      });
    }, 500)
  ).current;

  useTabFocus(() => {
    fetchInboxList();
  });

  useEffect(() => {
    fetchInboxList();
  }, []);

  useEffect(() => {
    if (setOpenInbox) {
      disableBodyScroll(notyWrap.current);
    }
    return () => {
      enableBodyScroll(notyWrap.current);
    };
  }, [setOpenInbox]);

  const openTargetPage = (url, message) => {
    modal.openConfirmModal({
      message: message,
      okCallback: () => {
        // Before opening the page, copy the session to the PC web.
        // This is to prevent the user from being logged out when moving to the PC web.
        copySessionToPcWeb();

        window.open(chatHostNameWithProtocol + url);
      }
    });
  };

  const onDeleteMemo = (memoId) => {
    usersStore.deleteInboxItems([memoId]).then(() => {
      usersStore.getInboxList();
    });
  };

  const onReadAllMemo = (event) => {
    event.preventDefault();

    usersStore.deleteAllReadMemo().then(() => {
      usersStore.getInboxList();
    });
  }

  const onDeleteAllMemo = (event) => {
    event.preventDefault();

    const allMemoIds = usersStore.inboxArr.map((item) => item.me_id);

    // If there is no memo, return false and don't do anything.
    if (!allMemoIds || allMemoIds.length === 0) {
      return false;
    }

    usersStore.deleteInboxItems(allMemoIds).then(() => {
      usersStore.getInboxList();
    });
  }

  const onBack = (event) => {
    event.preventDefault();

    if (setOpenInbox) {
      setOpenInbox(false);
    } else {
      history.goBack();
    }
  }

  return (
    <MessageListContainer>
      <MessageHeader>
        <BackButtonContainer onClick={onBack}>
          <img src="/images/chevron_left.svg" alt='Back' />
          <Title>쪽지</Title>
        </BackButtonContainer>
        <div>
          <ActionSpan onClick={onReadAllMemo}>읽음삭제</ActionSpan>
          <ActionSpan onClick={onDeleteAllMemo}>전체삭제</ActionSpan>
        </div>
      </MessageHeader>
      <MessageListUlContainer>
        <MessageListUl>
          {loading ? (
            <SportsLoader />
          ) : usersStore.inboxArr.length === 0 ? (
            <NoneContent />
          ) : (
            usersStore.inboxArr.map((item, key) => {
              return <MessageItem key={key} item={item} onDeleteMemo={onDeleteMemo} />;
            })
          )}
        </MessageListUl>
      </MessageListUlContainer>
      <BottomMenu>
        <BottomMenuItem onClick={() => openTargetPage('memo/memo_recv.php', '받은쪽지함을 새 창에서 열어야 이용하실 수 있습니다.')}>받은쪽지함</BottomMenuItem>
        <BottomMenuItem onClick={() => openTargetPage('memo/memo_send.php', '보낸쪽지함을 새 창에서 열어야 이용하실 수 있습니다.')}>보낸쪽지함</BottomMenuItem>
        <BottomMenuItem onClick={() => openTargetPage('memo/memo_form.php?kind=write', '쪽지보내기를 새 창에서 열어야 이용하실 수 있습니다.')} color='#ff1517'>쪽지보내기</BottomMenuItem>
      </BottomMenu>
    </MessageListContainer>
  );
});

const MessageItem = ({ item, onDeleteMemo }) => {
  const modal = useModalStore();
  let { chatHostNameWithProtocol } = getChatHostname();

  let category = '';
  let deletePossible = true;

  switch (item.memo_type) {
    case '1':
      category = '일반';
      if (parseInt(item.me_get_point) > 0 && item.me_get_yn === 'N') {
        deletePossible = false;
      }
      break;
    case '2':
      category = '아이템';
      if (parseInt(item.me_get_point) > 0 && item.me_get_yn === 'N') {
        deletePossible = false;
      }
      break;
    case '3':
      if (item.me_get_yn === 'N') {
        deletePossible = false;
      }
      category = '랜덤';
      break;
    case '4':
      category = '기타';
      if (parseInt(item.me_get_point) > 0 && item.me_get_yn === 'N') {
        deletePossible = false;
      }
      break;
    default:
      break;
  }

  let title = item.memosub === '' ? item.me_memo : item.memosub;

  const handleOpenMemo = (event) => {
    event.preventDefault();

    modal.openConfirmModal({
      message: '쪽지함 상세 페이지를 새 창에서 열어야 확인하실 수 있습니다. ',
      okCallback: () => {
        // Before opening the page, copy the session to the PC web.
        // This is to prevent the user from being logged out when moving to the PC web.
        copySessionToPcWeb();

        window.open(chatHostNameWithProtocol + `memo/memo_view.php?mkind=recv&me_id=${item.me_id}`);
      }
    });
  }

  const handleDeleteMemo = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onDeleteMemo(item.me_id);
  }

  return (
    <MessageItemWrapper as="li" onClick={handleOpenMemo}>
      <DotWrapper unread={!item.is_read}>
      </DotWrapper>

      <MessageContentWrapper>
        <TitleWrapper unread={!item.is_read}>{title}</TitleWrapper>
        <MessageMetadataContainer>
          <span className="user">{item.sender_nick}</span>
          <DotSubWrapper>•</DotSubWrapper>
          <span>{category}</span>
          <DotSubWrapper>•</DotSubWrapper>
          <span>{item.me_send_datetime.split(' ')[0]}</span>
        </MessageMetadataContainer>
      </MessageContentWrapper>

      {deletePossible && (
        <ButtonWrapper onClick={handleDeleteMemo}>
          <img src="/images/btn-close.svg" alt='Delete' />
        </ButtonWrapper>
      )}
    </MessageItemWrapper>
  );
};

const NoneContent = () => {
  return (
    <NoneContentWrap>
      <img src="/images/inbox-deactive.svg" />
      <span>쪽지내역이 없습니다.</span>
    </NoneContentWrap>
  );
};

export default InboxLayer;
