import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

const Space8RoundTable = ({ list = [], title = '회차별 통계' }) => {
    const size = 10;
    const [page, setPage] = useState(1);
    const totalPage = useMemo(() => {
        if (list.length === 0) return 1;
        return Math.ceil(list.length / size);
    }, [list.length]);

    return (
        <div className="data_list_wrap">
            <h3 className="tit">{title}</h3>
            <div className="data_list">
                <div className="table_float">
                    <table className="list full-width" border="1">
                        <caption>결과리스트</caption>
                        <colgroup>
                            <col width="74px"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th className="col-float">회차</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.slice(0, size * page).map((item, key) => {
                            var className = '';
                            if((key+1) % 2 == 0) {
                                className = 'col-even';
                            } else {
                                className = 'col-odd';
                            }
                            return (
                                <tr key={key} className={className} >
                                    <td>{`${item.round} `}<strong>({item.date_round})</strong></td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="table_data powerball">
                    <table className="list full-width" border="1">
                        <caption>결과리스트</caption>
                        <colgroup>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                            <col width="128px"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th rowSpan="2">좌측 결과</th>
                            <th rowSpan="2">우측 결과</th>
                            <th rowSpan="2">볼 갯수 <br/> 승무패</th>
                            <th rowSpan="2">볼 번호 합산 <br/> 승무패</th>
                            <th colSpan="2">좌측 번호 합 <br/> 홀짝</th>
                            <th colSpan="2">우측 번호 합 <br/> 홀짝</th>
                            <th rowSpan="2">좌측 1등볼 <br/> 홀짝</th>
                            <th rowSpan="2">우측 1등볼 <br/> 홀짝</th>
                            <th rowSpan="2">좌측 1등볼 <br/> 언오버</th>
                            <th rowSpan="2">우측 1등볼 <br/> 언오버</th>
                        </tr>
                        <tr>
                            <th>좌측 <br/> 합</th>
                            <th>홀짝</th>
                            <th>우측 <br/> 합</th>
                            <th>홀짝</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.slice(0, size * page).map((item, key) => {
                            var sumLeft = JSON.parse(item.ball_left).reduce(function(pv, cv) { return pv + cv; }, 0);
                            var sumRight = JSON.parse(item.ball_right).reduce(function(pv, cv) { return pv + cv; }, 0);
                            var className = '';
                            if((key+1) % 2 == 0) {
                                className = 'col-even';
                            } else {
                                className = 'col-odd';
                            }
                            return (
                                <tr key={key} className={className}>
                                    <td>{JSON.parse(item.ball_left).join(' ')}</td>
                                    <td>{JSON.parse(item.ball_right).join(' ')}</td>
                                    <td>
                                        {item.count_win ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                                            <span className={clsx('ic', item.count_win)}>
                          {item.count_win === 'HOME' ? '좌' : item.count_win === 'AWAY' ? '우' : '무'}
                        </span> :
                                            <span className={clsx('ic', 'DRAW')}>무</span>
                                        }
                                    </td>
                                    <td>
                                        {item.sum_win ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                                            <span className={clsx('ic', item.sum_win)}>
                          {item.count_win === 'HOME' ? '좌' : item.sum_win === 'AWAY' ? '우' : '무'}
                        </span> :
                                            <span className={clsx('ic', 'DRAW')}>무</span>
                                        }
                                    </td>
                                    <td>
                                        <span className={clsx('ic', 'border_round')}>{sumLeft}</span>
                                    </td>
                                    <td>
                                        {item.sum_home_ball_odd_even ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                                            <span className={clsx('ic', item.sum_home_ball_odd_even)}>
                          {item.sum_home_ball_odd_even === 'ODD' ? '홀' : item.sum_home_ball_odd_even === 'EVEN' ? '짝' : '0'}
                        </span> :
                                            <span className={clsx('ic', 'DRAW')}>0</span>
                                        }
                                    </td>
                                    <td>
                                        <span className={clsx('ic', 'border_round')}>{sumRight}</span>
                                    </td>
                                    <td>
                                        {item.sum_away_ball_odd_even ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                                            <span className={clsx('ic', item.sum_away_ball_odd_even)}>
                          {item.sum_away_ball_odd_even === 'ODD' ? '홀' : item.sum_away_ball_odd_even === 'EVEN' ? '짝' : '0'}
                        </span> :
                                            <span className={clsx('ic', 'DRAW')}>0</span>
                                        }
                                    </td>
                                    <td>
                                        {item.home_ball1_odd_even ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                                            <span className={clsx('ic', item.home_ball1_odd_even)}>
                          {item.home_ball1_odd_even === 'ODD' ? '홀' : item.home_ball1_odd_even === 'EVEN' ? '짝' : '0'}
                        </span> :
                                            <span className={clsx('ic', 'DRAW')}>0</span>
                                        }
                                    </td>
                                    <td>
                                        {item.away_ball1_odd_even ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                                            <span className={clsx('ic', item.away_ball1_odd_even)}>
                          {item.away_ball1_odd_even === 'ODD' ? '홀' : item.away_ball1_odd_even === 'EVEN' ? '짝' : '0'}
                        </span> :
                                            <span className={clsx('ic', 'DRAW')}>0</span>
                                        }
                                    </td>
                                    <td>
                                        {item.home_ball1_under_over ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                                            <span className={clsx('ic', 'random')}>
                          {item.home_ball1_under_over === 'OVER' ?
                              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28"
                                   viewBox="0 0 29 28" fill="none">
                                  <rect x="1" y="0.5" width="27" height="27" rx="13.5"
                                        stroke="#E74C3C"></rect>
                                  <path
                                      d="M14.5 6.62109L22.0281 15.9382H19.8494H9.15062H6.97186L14.5 6.62109Z"
                                      fill="#E74C3C"></path>
                                  <path d="M9.15062 15.6482V21.3789H19.8494V15.6482H9.15062Z"
                                        fill="#E74C3C"></path>
                              </svg> : item.home_ball1_under_over === 'UNDER' ?
                                  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28"
                                       viewBox="0 0 29 28" fill="none">
                                      <rect x="1" y="0.5" width="27" height="27" rx="13.5" fill="white"></rect>
                                      <rect x="1" y="0.5" width="27" height="27" rx="13.5"
                                            stroke="#3498DB"></rect>
                                      <path
                                          d="M14.5 21.3789L6.97186 12.3518L9.15064 12.3518L19.8494 12.3518L22.0281 12.3518L14.5 21.3789Z"
                                          fill="#3498DB"></path>
                                      <path
                                          d="M19.8494 12.3518L19.8494 6.62109L9.15064 6.62109L9.15064 15.1518L20.8494 12.3518Z"
                                          fill="#3498DB"></path>
                                  </svg> : <span className={clsx('ic', '0')}>0</span>
                          }
                        </span> :
                                            <span className={clsx('ic', '0')}>0</span>
                                        }
                                    </td>
                                    <td>
                                        {item.away_ball1_under_over ?  // API 요청으로 받은 응답의 키가 다르기에 분리 처리
                                            <span className={clsx('ic', 'random')}>
                          {item.away_ball1_under_over === 'OVER' ?
                              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28"
                                   viewBox="0 0 29 28" fill="none">
                                  <rect x="1" y="0.5" width="27" height="27" rx="13.5"
                                        stroke="#E74C3C"></rect>
                                  <path
                                      d="M14.5 6.62109L22.0281 15.9382H19.8494H9.15062H6.97186L14.5 6.62109Z"
                                      fill="#E74C3C"></path>
                                  <path d="M9.15062 15.6482V21.3789H19.8494V15.6482H9.15062Z"
                                        fill="#E74C3C"></path>
                              </svg> : item.away_ball1_under_over === 'UNDER' ?
                                  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28"
                                       viewBox="0 0 29 28" fill="none">
                                      <rect x="1" y="0.5" width="27" height="27" rx="13.5" fill="white"></rect>
                                      <rect x="1" y="0.5" width="27" height="27" rx="13.5"
                                            stroke="#3498DB"></rect>
                                      <path
                                          d="M14.5 21.3789L6.97186 12.3518L9.15064 12.3518L19.8494 12.3518L22.0281 12.3518L14.5 21.3789Z"
                                          fill="#3498DB"></path>
                                      <path
                                          d="M19.8494 12.3518L19.8494 6.62109L9.15064 6.62109L9.15064 15.1518L20.8494 12.3518Z"
                                          fill="#3498DB"></path>
                                  </svg> : <span className={clsx('ic', '0')}>0</span>
                          }
                        </span> :
                                            <span className={clsx('ic', '0')}>0</span>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <button
                className="btn_more"
                style={page === totalPage ? {display: 'none'} : {}}
                onClick={() => {
                    if (page >= totalPage) return;
                    setPage(page + 1);
                }}
            >
                더보기 ( {page} / {totalPage} )
            </button>
        </div>
    );
};

export default Space8RoundTable;
