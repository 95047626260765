import format from 'date-fns/format';
import { ScoreUtil } from '../../../../common/utils/scoreUtil';
import { toJS } from 'mobx';

/**
 * @description 선택된경기 삭제
 * @param {*} sportsTypeKeyArr
 * @param {*} filterList
 * @param {*} selectedGames
 * @returns
 */
function getDeletedGames(list, selectedGames) {
  let deleteSelectedGames = [];
  let copy = [...list];

  deleteSelectedGames = ScoreUtil.sortSportsList(copy);

  return deleteSelectedGames;
}

/**
 * @description 시간 + 리그로 묶음
 * @param {*} list
 * @returns
 */
function setDefaultList(list) {
  let result = new Map();

  const _STATUS = {
    IN_PROGRESS: 1,
    BREAK_TIME: 1,
    PAUSE: 1,
    READY: 2,
    FINAL: 3,
    CANCEL: 3,
    DELAY: 3,
    POSTPONED: 3,
    SUSPENDED: 3,
    CUT: 3,
    PENDING: 3
  };

  list.reduce(
    (acc, cur, idx) => {
      const hourMinute = format(
        new Date(cur.startDatetime + '+09:00'),
        'dd-HH-mm'
      );
      const arr = [cur];

      if (!!acc.time) {
        const prevData = acc.result.get(acc.lastIdx);

        if (!!prevData) {
          let mapKey = idx;
          let list = arr;

          if (
            acc.time === hourMinute &&
            prevData.league.id === cur.league.id &&
            _STATUS[cur.gameStatus] !== 3 &&
            prevData.hot === cur.hot
          ) {
            mapKey = acc.lastIdx;
            list = [...prevData.list, ...arr];
          }

          acc.time = hourMinute;
          acc.result.set(mapKey, {
            league: cur.league,
            sportsType: cur.sportsType,
            list,
            hot: cur.hot && _STATUS[cur.gameStatus] !== 3
          });
          acc.lastIdx = mapKey;

          return acc;
        } else {
          acc.time = hourMinute;
          acc.result.set(idx, {
            league: cur.league,
            sportsType: cur.sportsType,
            list: arr,
            hot: cur.hot && _STATUS[cur.gameStatus] !== 3
          });
          acc.lastIdx = idx;

          return acc;
        }
      } else {
        acc.time = hourMinute;
        acc.result.set(idx, {
          league: cur.league,
          sportsType: cur.sportsType,
          list: arr,
          hot: cur.hot && _STATUS[cur.gameStatus] !== 3
        });
        acc.lastIdx = idx;

        return acc;
      }
    },
    { time: null, lastIdx: 0, result }
  );

  let mergeLeague = new Map();
  let prevKey = null;

  for (let [key, value] of result.entries()) {
    let prev = mergeLeague.get(prevKey);

    if (!!prev) {
      if (prev.league.id === value.league.id && prev.hot === value.hot) {
        const copyValue = { ...prev };

        copyValue.list = [...copyValue.list, ...value.list];

        mergeLeague.set(prevKey, copyValue);
      } else {
        prevKey = key;
        mergeLeague.set(key, value);
      }
    } else {
      prevKey = key;
      mergeLeague.set(key, value);
    }
  }

  return mergeLeague;
}

/**
 * @description 시간 + 리그로 묶음
 * @param {*} list
 * @returns
 */
function setEsportsDefaultList(list) {
  let result = new Map();

  list.reduce(
    (acc, cur, idx) => {
      const hourMinute = format(new Date(cur.startDatetime), 'dd-HH-mm');
      const arr = [cur];

      if (!!acc.time) {
        const prevData = acc.result.get(acc.lastIdx);

        if (!!prevData) {
          let mapKey = idx;
          let list = arr;

          if (acc.time === hourMinute && prevData.league.id === cur.lid) {
            mapKey = acc.lastIdx;
            list = [...prevData.list, ...arr];
          }

          acc.time = hourMinute;
          acc.result.set(mapKey, {
            league: {
              id: cur.lid,
              shortName: cur.hasOwnProperty('league_name')
                ? cur.league_name
                : cur.leagueName
            },
            list
          });
          acc.lastIdx = mapKey;

          return acc;
        } else {
          acc.time = hourMinute;
          acc.result.set(idx, {
            league: {
              id: cur.lid,
              shortName: cur.hasOwnProperty('league_name')
                ? cur.league_name
                : cur.leagueName
            },
            list: arr
          });
          acc.lastIdx = idx;

          return acc;
        }
      } else {
        acc.time = hourMinute;
        acc.result.set(idx, {
          league: {
            id: cur.lid,
            shortName: cur.hasOwnProperty('league_name')
              ? cur.league_name
              : cur.leagueName
          },
          list: arr
        });
        acc.lastIdx = idx;

        return acc;
      }
    },
    { time: null, lastIdx: 0, result }
  );

  let mergeLeague = new Map();
  let prevKey = null;

  for (let [key, value] of result.entries()) {
    let prev = mergeLeague.get(prevKey);

    if (!!prev) {
      if (prev.league.id === value.league.id) {
        const copyValue = { ...prev };

        copyValue.list = [...copyValue.list, ...value.list];

        mergeLeague.set(prevKey, copyValue);
      } else {
        prevKey = key;
        mergeLeague.set(key, value);
      }
    } else {
      prevKey = key;
      mergeLeague.set(key, value);
    }
  }

  return mergeLeague;
}

export { getDeletedGames, setDefaultList, setEsportsDefaultList };
