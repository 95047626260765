import styled, { css } from 'styled-components';

export const Wrap = styled.div`
  background-color: #fff;
  height: 100%;
`;

export const Header = styled.header`
  z-index: 1;
  position: relative;
  background-color: #3498db;
  padding: 63px 20px 30px;
  ${(props) =>
    props.imagePath &&
    css`
      :after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(${props.imagePath}) no-repeat center center;
        background-size: cover;
        filter: brightness(0.5);
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    `}
`;

export const CloseButton = styled.button`
  position: absolute;
  display: block;
  width: 48px;
  height: 48px;
  top: 0;
  right: 0;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: calc(50% - 1px);
    width: 2px;
    height: 20px;
    background: #fff;
  }
  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column;
    em {
      font-weight: bold;
      font-size: 22px;
      line-height: 20px;
      margin-bottom: 14px;
      color: #fff;
    }
    p {
      font-size: 13px;
      line-height: 12px;
      color: #fff;
      margin-bottom: 16px;
    }
    > div {
      color: #fff;
      opacity: 0.6;
      line-height: 12px;
      display: flex;
      span {
        :first-child {
          display: flex;
          :after {
            content: '・';
            height: 12px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  > .thumb {
    width: 75px;
    height: 75px;
    position: relative;
    border-radius: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    :before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 24px;
  button {
    padding: 0 15px;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    height: 28px;
    border-radius: 5px;
    background-color: #00a2ff;
    :not(:last-child) {
      margin-right: 6px;
    }
  }
`;

export const ListWrap = styled.div`
  overflow-y: auto;
  height: calc(100% - ${(props) => `${props.headerHeight}px`});
`;

export const List = styled.ul`
  padding: 0 15px;
  margin-bottom: 30px;
  li {
    border-bottom: 1px solid #e5e5e5;
    padding: 15px 5px;
    a {
      display: flex;
      :visited .subject {
        color: #999;
      }
    }
  }
`;

export const Image = styled.div`
  background: url(${(props) => props.imgUrl}) no-repeat center center;
  min-width: 56px;
  width: 56px;
  height: 56px;
  border-radius: 6px;
`;

export const Comment = styled.div`
  background-color: #f5f6f8;
  min-width: 40px;
  width: 40px;
  height: 56px;
  border-radius: 6px;
  color: #999;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 19px;
  margin-left: 8px;
  em {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
    line-height: 17px;
  }
`;

export const Content = styled.div`
  flex: 1;
  > div {
    line-height: 19px;
    font-size: 15px;
    color: #222;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }
  span {
    font-size: 11px;
    color: #999;
    line-height: 11px;
  }
`;

export const TabWrap = styled.div`
  width: calc(100% - 60px);
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 44px;
  border-bottom: 1px solid #e4e4e4;
`;

export const Tab = styled.button`
  flex: 1;
  font-size: 16px;
  height: 100%;
  box-sizing: border-box;
  :not(:first-child) {
    margin-left: 10px;
  }
  ${(props) =>
    props.selected &&
    css`
      border-bottom: 2px solid #ed2026;
    `};
`;

export const NoneContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  > p {
    font-size: 18px;
  }
`;
