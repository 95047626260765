import autobind from 'autobind-decorator';
import { extendObservable, observable, runInAction, action } from 'mobx';

@autobind
class PromptModel {
  @observable
  isVisible = true;

  constructor(data) {
    extendObservable(this, data);

    setTimeout(() => {
      runInAction(() => {
        this.isVisible = false;
      });
    }, 30000);
  }

  @action
  remove() {
    this.isVisible = false;
  }
}

export default PromptModel;
