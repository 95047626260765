import React, {useEffect, useState} from 'react';
import './bet.css';
import {BetService} from "../../../common/services";
import useModalStore from "../../../hooks/useModalStore";
import useCheckLogin from "../../../hooks/useCheckLogin";
import {Redirect, useHistory, Navigate} from "react-router-dom";
const BetSection = ({ gameType, isLoggedIn, loadingLoginSession }) => {
    const [loading, setLoading] = useState(false);
    const [betLogin, setBetLogin] = useState(false);
    const modal = useModalStore();
    const history = useHistory();
    const { isCheckLogin } = useCheckLogin();

    const GAME_BET_INFO = {
        "space8": {
            is_available: true,
            description: "흥미진진한 볼 레이싱 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 짜릿한 게임의 세계에 도전해보세요!"
        },
        "runningball5_6": {
            is_available: true,
            description: "흥미진진한 볼 레이싱 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 짜릿한 게임의 세계에 도전해보세요!"
        },
        "runningball5_4": {
            is_available: true,
            description: "흥미진진한 볼 레이싱 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 짜릿한 게임의 세계에 도전해보세요!"
        },
        "runningball3": {
            is_available: true,
            description: "흥미진진한 볼 레이싱 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 짜릿한 게임의 세계에 도전해보세요!"
        },
        "powerball5": {
            is_available: true,
            description: "핫한 파워볼 게임을 <span class='bet-highlight'>포인트</span>로 간편하게 즐기실 수 있습니다. " +
                "<span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고, 신나는 게임을 시작해보세요!"
        },
        "powerball3": {
            is_available: true,
            description: "핫한 파워볼 게임을 <span class='bet-highlight'>포인트</span>로 간편하게 즐기실 수 있습니다. " +
                "<span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고, 신나는 게임을 시작해보세요!"
        },
        "powerball": {
            is_available: true,
            description: "핫한 파워볼 게임을 <span class='bet-highlight'>포인트</span>로 간편하게 즐기실 수 있습니다. " +
                "<span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고, 신나는 게임을 시작해보세요!"
        },
        "powerladder5": {
            is_available: true,
            description: "긴장감 넘치는 사다리 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 도전해보세요!"
        },
        "powerladder3": {
            is_available: true,
            description: "긴장감 넘치는 사다리 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 도전해보세요!"
        },
        "powerladder": {
            is_available: true,
            description: "긴장감 넘치는 사다리 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 도전해보세요!"
        },
        "ladder": {
            is_available: true,
            description: "긴장감 넘치는 사다리 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 도전해보세요!"
        },
        "daridari": {
            is_available: true,
            description: "긴장감 넘치는 사다리 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 도전해보세요!"
        },
        "speedladder": {
            is_available: true,
            description: "긴장감 넘치는 사다리 게임을 <span class='bet-highlight'>포인트</span>로 쉽게 즐기실 수 있습니다. " +
                "지금 바로 <span class='bet-highlight'>배팅하기</span> 버튼을 클릭하고 도전해보세요!"
        },

    }

    const waitingLogin = localStorage.getItem("is-bet-login");

    useEffect(() => {
        if (waitingLogin) {
            handleLoginBet();
        }
    }, []);

    if (betLogin) {
        window.location.href = betLogin;
    }

    const handleLoginBet = async () => {
        if (!isCheckLogin && !waitingLogin) {
            modal.openConfirmModal({
              message: '로그인 후 사용이 가능 합니다. 로그인 하시겠습니까?',
              okCallback: () => {
                  localStorage.setItem("is-bet-login", gameType);
                  history.push('/auth/login')
              }
            });
            return;
        }

        setLoading(true);
        const res = await BetService.loginBet(gameType);
        if (res.code !== 200) {
            modal.openAlertModal({
                message: res.message
            });
        }
        setLoading(false);
        if (res.data.url) {
            localStorage.removeItem("is-bet-login")
            window.location.href = res.data.url;
        }
    }

    const isAvailable = GAME_BET_INFO[gameType] ? GAME_BET_INFO[gameType]['is_available'] : false;

    return (
        <div className="bet-section">
            {
                !isAvailable ?
                <div className={"not-available"}>곧 제공됩니다!</div>
            : null
            }
            <p
                className="bet-description"
                dangerouslySetInnerHTML={{ __html: GAME_BET_INFO[gameType] ? GAME_BET_INFO[gameType]['description'] : '' }}>
            </p>
            <button
                className={`bet-button ${loadingLoginSession || loading ? 'loading' : ''}`}
                onClick={handleLoginBet}
                disabled={loadingLoginSession}
            >
                배팅하기
            </button>
        </div>
    );
};

export default BetSection;