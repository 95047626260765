/**
 * @description 포지션별로 솔팅
 * @param {*} players
 * @returns
 */
function sortPlayers(players) {
  const _POSITION = {
    TOP: 1,
    JUNGLE: 2,
    MID: 3,
    AD: 4,
    SUPPORT: 5
  };

  return players.sort((a, b) => _POSITION[a.position] - _POSITION[b.position]);
}

export { sortPlayers };
