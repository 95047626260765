import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import useCheckLogin from '../../../../hooks/useCheckLogin';
import useModalStore from '../../../../hooks/useModalStore';

const ScoreProtoContainer = observer(
  ({ currentRound, nextRound, previousRound, isCalculator, changeRound }) => {
    const history = useHistory();
    const { isCheckLogin } = useCheckLogin();
    const modal = useModalStore();

    const onClickPrev = () => {
      onChangeRound(previousRound.id);
    };

    const onClickNext = () => {
      if (!!nextRound.hasOwnProperty('id')) {
        onChangeRound(nextRound.id);
      } else {
        return;
      }
    };

    const onChangeRound = (roundId) => {
      if (isCalculator) {
        changeRound(roundId);
      } else {
        history.push({
          pathname: `/score/proto`,
          search: `?roundId=${roundId}`
        });
      }
    };

    const onClickCalc = () => {
      history.push('/score/proto/calculator');
    };

    const onClickCalcList = () => {
      if (!isCheckLogin) {
        modal.openConfirmModal({
          message: '로그인 후 사용이 가능 합니다. 로그인 하시겠습니까?',
          okCallback: () => history.push('/auth/login')
        });

        return;
      } else {
        history.push('/score/proto/calculator/my-pick');
      }
    };

    return (
      <div className="proto_summary">
        {!isCalculator && (
          <button
            type="button"
            className={clsx('btn', 'btn_history')}
            onClick={onClickCalcList}
          >
            저장내역
          </button>
        )}
        <div className="date_area">
          <a className="btn_prev" onClick={onClickPrev}>
            이전 날짜
          </a>
          <strong className="date">{currentRound.displayName}</strong>
          <a
            className={clsx(
              'btn_next',
              !!nextRound.hasOwnProperty('id') ? 'active' : 'disabled'
            )}
            onClick={onClickNext}
          >
            다음 날짜
          </a>
        </div>
        {!isCalculator && (
          <button
            type="button"
            className={clsx('btn', 'btn_calculator')}
            onClick={onClickCalc}
          >
            배당률 계산기
          </button>
        )}
      </div>
    );
  }
);

export default ScoreProtoContainer;
