import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const CommunityCommentUser = ({ user, ca_name }) => {
  return (
    <Link
      className="lnk_user"
      to={
        ca_name === '_REMOVE'
          ? `${window.location.pathname}${window.location.search}`
          : `/user/${user.id}`
      }
    >
      <span className={clsx('thumb', `lv${user.level}`)}>
        <img
          src={user.profile_img}
          onError={(event) => {
            event.target.src = '/images/thumb_user_def.png';
          }}
        />
        <svg
          className="border"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 36 36"
          enableBackground="new 0 0 36 36"
          xmlSpace="preserve"
        >
          <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
        </svg>
      </span>
      <strong className="name">{user.nick}</strong>
    </Link>
  );
};

export default CommunityCommentUser;
