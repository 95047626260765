import { useEffect } from "react";

/**
 * useTabFocus - Custom hook to detect when the tab/window regains focus
 * @param {Function} onFocus - Callback function to execute when tab gains focus
 */
const useTabFocus = (onFocus) => {
    useEffect(() => {
        const handleFocus = () => {
            if (typeof onFocus === 'function') {
                onFocus();
            }
        };

        document.addEventListener("visibilitychange", () => {
            if (!document.hidden) {
                handleFocus();
            }
        });

        return () => {
            document.removeEventListener("visibilitychange", handleFocus);
        };
    }, [onFocus]);
};

export default useTabFocus;