import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import useGameStore from '../../../../../hooks/useGameStore';
import { observer } from 'mobx-react';
import { PickComment } from '../../pickComment';
import useStore from '../../../../../hooks/useStore';
import { timeUtils } from '../../../../../common/utils/timeUtil';
import useModalStore from '../../../../../hooks/useModalStore';

let todayDate = timeUtils.dateFormatYmd(timeUtils.todayYmd());

const SpeedLadderPick = observer(({}) => {
  const [tab, setTab] = useState('dist');
  const [option, setOption] = useState('all');
  const gameStore = useGameStore();
  const { clockStore } = useStore();
  const [currentRound, setCurrentRound] = useState(0);
  const [pickClass, setPickClass] = useState('left');
  const [pickComment, setPickComment] = useState();
  const modalStore = useModalStore();
  const [pick, setPick] = useState('left');
  const [pickType, setPickType] = useState('start');
  const [commentIndex, setCommentIndex] = useState('0');

  useEffect(() => {
    let interval = null;
    if (currentRound !== 0) {
      interval = setInterval(() => {
        gameStore.getThemePickList({
          gameType: 'speed_ladder',
          pickType: option === 'all' ? null : option,
          limit: 10,
          offset: 0,
          date: timeUtils.dateFormatYmd(todayDate),
          round: currentRound
        });
      }, 1500);

      gameStore.getThemePickCommentList({
        gameType: 'speed_ladder',
        pickType: option === 'all' ? null : option,
        limit: 10,
        offset: 0,
        concat: false,
        date: timeUtils.dateFormatYmd(todayDate),
        round: currentRound
      });
    }
    return () => {
      clearInterval(interval);
    };
  }, [tab, option, currentRound]);

  useEffect(() => {
    clockStore.get1minRound().then((res) => {
      setCurrentRound(res.nextRound);
    });
  }, []);

  const getRoundDate = () => {
    return timeUtils.dateFormatCalendarYmd(todayDate) + ' - ' + currentRound;
  };

  const onClickPreRound = () => {
    if (currentRound === 1) {
      todayDate = timeUtils.dateFormatCalendarYmd(
        timeUtils.datePrevOrNext(todayDate, 'prev')
      );
      setCurrentRound(288);
    } else {
      setCurrentRound(currentRound - 1);
    }
  };

  const onClickNextRound = () => {
    clockStore.get1minRound().then((res) => {
      if (
        todayDate === timeUtils.dateFormatCalendarYmd(timeUtils.todayYmd()) &&
        currentRound === res.nextRound
      ) {
        return;
      } else {
        if (currentRound === 288) {
          todayDate = timeUtils.dateFormatCalendarYmd(
            timeUtils.datePrevOrNext(todayDate, 'next')
          );
          setCurrentRound(1);
        } else {
          setCurrentRound(currentRound + 1);
        }
      }
    });
  };

  const combinationMaxSection = (section) => {
    let num = [
      gameStore.data.dist.l4o_percent,
      gameStore.data.dist.l3e_percent,
      gameStore.data.dist.r3o_percent,
      gameStore.data.dist.r4e_percent
    ];

    let maxSection = Math.max.apply(null, num);

    if (maxSection === 0) {
      return section;
    }

    let a = {
      A: gameStore.data.dist.l4o_percent,
      B: gameStore.data.dist.l3e_percent,
      C: gameStore.data.dist.r3o_percent,
      D: gameStore.data.dist.r4e_percent
    };

    if (a[section] === maxSection) {
      section = section + ' on';
    }

    return section;
  };

  const sectionDisplay = (index) => {
    if (index !== option && option !== 'all') {
      return 'none';
    }
  };

  const onSetPickComment = (e) => {
    setCommentIndex(e.value);
    setPickComment(e[e.selectedIndex].text);
    e.selectedIndex === 0 && setPickComment();
  };

  const insertPickComment = () => {
    if (pickComment === undefined) {
      modalStore.openAlertModal({
        message: '픽 메세지를 선택해 주세요'
      });
      return;
    }

    gameStore.insertThemePickComment({
      gameType: 'speed_ladder',
      pickType: pickType,
      pick: pick,
      pickComment: pickComment
    });
  };

  const onSetPick = (pickClass, pickType, pick) => {
    setPickClass(pickClass);
    setPick(pick);
    setPickType(pickType);
  };

  return (
    <div className="game_stats_area">
      {/* option_area */}
      <div className="option_tab">
        <ul>
          <li>
            <a
              onClick={() => setTab('dist')}
              className={clsx('item', tab === 'dist' && 'selected')}
            >
              픽분포도
            </a>
          </li>
          <li>
            <a
              onClick={() => setTab('reg')}
              className={clsx('item', tab === 'reg' && 'selected')}
            >
              픽등록
            </a>
          </li>
        </ul>
      </div>
      {tab === 'dist' && (
        <>
          <div className="option_area">
            <div className="date_area">
              <Link
                className="btn_prev"
                onClick={() => onClickPreRound()}
                to={`${location.pathname}?tab=pick`}
              >
                이전 날짜
              </Link>
              <strong className="date">{getRoundDate()}</strong>
              <Link
                className="btn_next"
                onClick={() => onClickNextRound()}
                to={`${location.pathname}?tab=pick`}
              >
                다음 날짜
              </Link>
            </div>
            <div className="option_type">
              <select
                value={option}
                onChange={(e) => setOption(e.target.value)}
              >
                <option value="all">전체</option>
                <option value="start">출발</option>
                <option value="line">줄수</option>
                <option value="odd_even">결과</option>
                <option value="combination">출발+줄수</option>
              </select>
            </div>
          </div>
          {/* // option_area */}

          {/* date_detail_wrap */}
          <div className="date_detail_wrap">
            {/* data_summary */}
            <div className="data_summary">
              <h3 className="tit">전체 픽 분포도</h3>
              <div
                className="chart"
                style={{
                  display: sectionDisplay('start')
                }}
              >
                <dl
                  className={clsx(
                    'bar UNDER',
                    gameStore.data.dist.start_left_percent > '50' && 'on'
                  )}
                >
                  <dt>좌</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.start_left_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.start_left_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar OVER',
                    gameStore.data.dist.start_right_percent > '50' && 'on'
                  )}
                >
                  <dt>우</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.start_right_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.start_right_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart"
                style={{
                  display: sectionDisplay('line')
                }}
              >
                <dl
                  className={clsx(
                    'bar UNDER',
                    gameStore.data.dist.line_three_percent > '50' && 'on'
                  )}
                >
                  <dt>3</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.line_three_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.line_three_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar OVER',
                    gameStore.data.dist.line_four_percent > '50' && 'on'
                  )}
                >
                  <dt>4</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.line_four_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.line_four_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart"
                style={{
                  display: sectionDisplay('odd_even')
                }}
              >
                <dl
                  className={clsx(
                    'bar ODD',
                    gameStore.data.dist.odd_percent > '50' && 'on'
                  )}
                >
                  <dt>홀</dt>
                  <dd>
                    <span className="num">{gameStore.data.dist.odd_count}</span>
                    <span className="per">
                      ({gameStore.data.dist.odd_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    'bar EVEN',
                    gameStore.data.dist.even_percent > '50' && 'on'
                  )}
                >
                  <dt>짝</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.even_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.even_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart column4"
                style={{
                  display: sectionDisplay('combination')
                }}
              >
                <dl className={clsx('bar ', combinationMaxSection('A'))}>
                  <dt>좌4</dt>
                  <dd>
                    <span className="num">{gameStore.data.dist.l4o_count}</span>
                    <span className="per">
                      ({gameStore.data.dist.l4o_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx('bar ', combinationMaxSection('B'))}>
                  <dt>좌3</dt>
                  <dd>
                    <span className="num">{gameStore.data.dist.l3e_count}</span>
                    <span className="per">
                      ({gameStore.data.dist.l3e_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx('bar ', combinationMaxSection('C'))}>
                  <dt>우3</dt>
                  <dd>
                    <span className="num">{gameStore.data.dist.r3o_count}</span>
                    <span className="per">
                      ({gameStore.data.dist.r3o_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx('bar ', combinationMaxSection('D'))}>
                  <dt>우4</dt>
                  <dd>
                    <span className="num">{gameStore.data.dist.r4e_count}</span>
                    <span className="per">
                      ({gameStore.data.dist.r4e_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
            </div>
            {/* // data_summary */}

            {/* pick_list_wrap */}
            <div className="pick_list_wrap">
              <h3 className="tit">유저의 픽</h3>
              <div className="pick_list">
                <PickComment
                  gameType={'speed_ladder'}
                  pickType={option}
                  items={gameStore.data.comment.list}
                  totalCnt={gameStore.data.comment.total_cnt}
                  date={timeUtils.dateFormatYmd(todayDate)}
                  round={currentRound}
                />
              </div>
            </div>
            {/* // pick_list_wrap */}
          </div>
          {/* // date_detail_wrap */}
        </>
      )}
      {tab === 'reg' && (
        <>
          {/* pick_register_wrap */}
          <div className="pick_register_wrap">
            {/* pick_select */}
            <div className="pick_select">
              <h3 className="tit">픽 선택</h3>
              <div className="item">
                <button
                  type="button"
                  className={clsx('ODD', pickClass === 'left' && 'selected')}
                  onClick={() => onSetPick('left', 'start', 'left')}
                >
                  <span className="tx">좌</span>
                  <span className="per">
                    {gameStore.data.dist.start_left_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx('EVEN', pickClass === 'right' && 'selected')}
                  onClick={() => onSetPick('right', 'start', 'right')}
                >
                  <span className="tx">우</span>
                  <span className="per">
                    {gameStore.data.dist.start_right_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx('UNDER', pickClass === 'three' && 'selected')}
                  onClick={() => onSetPick('three', 'line', '3')}
                >
                  <span className="tx">3</span>
                  <span className="per">
                    {gameStore.data.dist.line_three_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx('OVER', pickClass === 'four' && 'selected')}
                  onClick={() => onSetPick('four', 'line', '4')}
                >
                  <span className="tx">4</span>
                  <span className="per">
                    {gameStore.data.dist.line_four_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx('ODD', pickClass === 'odd' && 'selected')}
                  onClick={() => onSetPick('odd', 'odd_even', 'odd')}
                >
                  <span className="tx">홀</span>
                  <span className="per">
                    {gameStore.data.dist.odd_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx('EVEN', pickClass === 'even' && 'selected')}
                  onClick={() => onSetPick('even', 'odd_even', 'even')}
                >
                  <span className="tx">짝</span>
                  <span className="per">
                    {gameStore.data.dist.even_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx('ODD', pickClass === 'l4o' && 'selected')}
                  onClick={() => onSetPick('l4o', 'combination', 'l4o')}
                >
                  <span className="tx">좌4</span>
                  <span className="per">
                    {gameStore.data.dist.l4o_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx('EVEN', pickClass === 'l3e' && 'selected')}
                  onClick={() => onSetPick('l3e', 'combination', 'l3e')}
                >
                  <span className="tx">좌3</span>
                  <span className="per">
                    {gameStore.data.dist.l3e_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx('ODD', pickClass === 'r3o' && 'selected')}
                  onClick={() => onSetPick('r3o', 'combination', 'r3o')}
                >
                  <span className="tx">우3</span>
                  <span className="per">
                    {gameStore.data.dist.r3o_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx('EVEN', pickClass === 'r4e' && 'selected')}
                  onClick={() => onSetPick('r4e', 'combination', 'r4e')}
                >
                  <span className="tx">우4</span>
                  <span className="per">
                    {gameStore.data.dist.r4e_percent}%
                  </span>
                </button>
              </div>
            </div>
            {/* // pick_select */}

            {/* pick_message */}
            <div className="pick_message">
              <h3 className="tit">픽 메시지</h3>
              <div className="message_type">
                <select
                  value={commentIndex}
                  onChange={(e) => {
                    onSetPickComment(e.target);
                  }}
                >
                  <option value="0">
                    픽을 선택하신후 간단한 문구를 선택하세요.
                  </option>
                  <option value="1">예림이 그 패 봐 봐. 혹시 짝이야?</option>
                  <option value="2">
                    홀짝만 30년 해온 장인입니다. 이번 픽은 저만 따라 오세요.
                  </option>
                  <option value="3">시스템 가동. 홀짝 준비 완료</option>
                  <option value="4">
                    홀짝은 흐름입니다. 이번 흐름 놓치시면 후회합니다.
                  </option>
                  <option value="5">홀이냐 짝이냐. 그것이 문제로다.</option>
                  <option value="6">Latte is Horse. 홀짝이 대세였다구.</option>
                  <option value="7">나는 홀짝왕이 될거야.</option>
                  <option value="8">
                    피할 수 없으면 즐겨야죠. 흐름을 즐기면 됩니다.
                  </option>
                  <option value="9">
                    펭하~! 펭수도 이런 흐름에는 고민없이 갑니다.
                  </option>
                  <option value="10">
                    흐름은 돌아오는거야~ 이번 흐름은 제것입니다.
                  </option>
                </select>
              </div>
              <button
                type="submit"
                className="btn_submit"
                onClick={() => insertPickComment()}
              >
                픽등록하기
              </button>
            </div>
            {/* // pick_message */}
          </div>
          {/* // pick_register_wrap */}
        </>
      )}
    </div>
  );
});

export { SpeedLadderPick };
