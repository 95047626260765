import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const BarChart = ({ count = {}, title = '사다리' }) => {
  return (
    <div className="data_summary">
      <h3 className="tit">{title} 통계</h3>
      <div className="chart">
        <dl
          className={clsx(
            'bar',
            'LEFT',
            parseInt(count.start_left_count) >
              parseInt(count.start_right_count) && 'on'
          )}
        >
          <dt>좌</dt>
          <dd>
            <span className="num">{count.start_left_count}</span>
            <span className="per">({count.start_left_percent}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'RIGHT',
            parseInt(count.start_left_count) <
              parseInt(count.start_right_count) && 'on'
          )}
        >
          <dt>우</dt>
          <dd>
            <span className="num">{count.start_right_count}</span>
            <span className="per">({count.start_right_percent}%)</span>
          </dd>
        </dl>
      </div>
      <div className="chart">
        <dl
          className={clsx(
            'bar',
            'LEFT',
            parseInt(count.line_three_count) >
              parseInt(count.line_four_count) && 'on'
          )}
        >
          <dt>3</dt>
          <dd>
            <span className="num">{count.line_three_count}</span>
            <span className="per">({count.line_three_percent}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'RIGHT',
            parseInt(count.line_three_count) <
              parseInt(count.line_four_count) && 'on'
          )}
        >
          <dt>4</dt>
          <dd>
            <span className="num">{count.line_four_count}</span>
            <span className="per">({count.line_four_percent}%)</span>
          </dd>
        </dl>
      </div>
      <div className="chart">
        <dl
          className={clsx(
            'bar',
            'ODD',
            parseInt(count.odd_count) > parseInt(count.even_count) && 'on'
          )}
        >
          <dt>홀</dt>
          <dd>
            <span className="num">{count.odd_count}</span>
            <span className="per">({count.odd_percent}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'EVEN',
            parseInt(count.odd_count) < parseInt(count.even_count) && 'on'
          )}
        >
          <dt>짝</dt>
          <dd>
            <span className="num">{count.even_count}</span>
            <span className="per">({count.even_percent}%)</span>
          </dd>
        </dl>
      </div>
      <div className="chart column4">
        <dl
          className={clsx(
            'bar',
            'LEFT4ODD',
            MathUtil.onlyMaxNumber([
              count.l4o_count,
              count.l3e_count,
              count.r3o_count,
              count.r4e_count
            ]) === parseInt(count.l4o_count) && 'on'
          )}
        >
          <dt>좌4홀</dt>
          <dd>
            <span className="num">{count.l4o_count}</span>
            <span className="per">({count.l4o_percent}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'LEFT3EVEN',
            MathUtil.onlyMaxNumber([
              count.l4o_count,
              count.l3e_count,
              count.r3o_count,
              count.r4e_count
            ]) === parseInt(count.l3e_count) && 'on'
          )}
        >
          <dt>좌3짝</dt>
          <dd>
            <span className="num">{count.l3e_count}</span>
            <span className="per">({count.l3e_percent}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'RIGHT3ODD',
            MathUtil.onlyMaxNumber([
              count.l4o_count,
              count.l3e_count,
              count.r3o_count,
              count.r4e_count
            ]) === parseInt(count.r3o_count) && 'on'
          )}
        >
          <dt>우3홀</dt>
          <dd>
            <span className="num">{count.r3o_count}</span>
            <span className="per">({count.r3o_percent}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'RIGHT4EVEN',
            MathUtil.onlyMaxNumber([
              count.l4o_count,
              count.l3e_count,
              count.r3o_count,
              count.r4e_count
            ]) === parseInt(count.r4e_count) && 'on'
          )}
        >
          <dt>우4짝</dt>
          <dd>
            <span className="num">{count.r4e_count}</span>
            <span className="per">({count.r4e_percent}%)</span>
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default BarChart;
