import React, { useState } from 'react';
import { GamesWrapper, MatchBox, MatchTab } from './styled';
import { toJS } from 'mobx';
import moment from 'moment';
import 'moment/locale/ko';
import ScoreTypeView from '../../shared/view';
import { observer } from 'mobx-react';

const DateMatches = observer(({ value, selectedGames, setSelectedGame }) => {
  const [viewToggle, setViewToggle] = useState(true);
  const handleToggle = () => {
    setViewToggle(!viewToggle);
  };

  const { date, games } = value;
  const formattedDateTime = moment(date)
    .locale('ko')
    .format('YYYY년 MM월 DD일 (ddd)');

  return (
    <MatchBox>
      <MatchTab onClick={handleToggle} isClose={!viewToggle}>
        {formattedDateTime}
      </MatchTab>
      <GamesWrapper isActive={viewToggle}>
        {games.map((value, key) => {
          const { sportsType } = value;
          return (
            <ScoreTypeView
              key={key}
              sportsType={sportsType}
              item={value}
              setSelectedGame={setSelectedGame}
              isSelected={selectedGames.some((id) => id === value.id)}
            />
          );
        })}
      </GamesWrapper>
    </MatchBox>
  );
});

export default DateMatches;
