import React from 'react';
import PeriodBarChart from '../../../periodBarChart';

const runningballMinMaxList = [
  { name: '홀', resultKey: 'runningball_odd' },
  { name: '짝', resultKey: 'runningball_even' },
  { name: '언더', resultKey: 'runningball_under' },
  { name: '오버', resultKey: 'runningball_over' },
];

const sumMinMaxList = [
  { name: '홀', resultKey: 'sum_odd' },
  { name: '짝', resultKey: 'sum_even' },
  { name: '언더', resultKey: 'sum_under' },
  { name: '오버', resultKey: 'sum_over' },
];

const MinMaxWithinPeriod = ({ data }) => {
  if (Object.keys(data).length <= 3) {
    return null;
  }

  return (
    <div className="data_minmax_wrap">
      <h3 className="tit">기간내 최소/최다 출현 통계</h3>
      <div className="minmax_list">
        <div className="reference">
          <span className="tx_min">최소출현</span>
          <span className="tx_max">최대출현</span>
        </div>
        <div className="tit_sub">1등 볼</div>
        <PeriodBarChart data={data} mapList={runningballMinMaxList} />
        { data.max_sum_odd_count && // 3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 로직 필요없음
            (<> <div className="tit_sub">123등 합</div>
          <PeriodBarChart data={data} mapList={sumMinMaxList} /> </>)
        }
      </div>
    </div>
  );
};

export default MinMaxWithinPeriod;
