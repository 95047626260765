import React from 'react';

const ProtoCalculatorHtml = () => {
  return (
    <>
      {/* proto_wrap */}
      <div className="proto_wrap">

        <div className="nav">
          <h2 className="tit">배당률 계산기</h2>
          <button className="btn_prev">이전으로</button>
        </div>

        {/* proto_summary */}
        <div className="proto_summary">
          <div className="date_area">
            <a className="btn_prev" href="javascript:;">이전 회차</a>
            <strong className="date">승부식 78회차</strong>
            <a className="btn_next" href="javascript:;">다음 회차</a>
          </div>
        </div>
        {/* // proto_summary */}

        {/* proto_calculator */}
        <div className="proto_list proto_calculator">

          {/* item */}
          <div className="item">
            <div className="summary">
              <span className="no">008</span>
              <span className="date">01.09(월) 19:00</span>
              <span className="league basketball">NBA</span>
            </div>
            <div className="box">
              <div className="team left">
                <span className="name">St George Willawong FC</span>
              </div>
              <div className="info">
                <div className="score">
                  <strong className="left on">
                    <em className="num">94</em>
                  </strong>
                  <strong className="right">
                    <em className="num">78</em>
                  </strong>
                </div>
                <div className="state">대기</div>
              </div>
              <div className="team right">
                <div className="name">FC 라다 톨리야티</div>
              </div>
            </div>
            <div className="odds">
              <span className="type handicap">+1.0</span>
              <ul className="odds_selector">
                <li>
                  <input type="radio" id="a1" name="cheer_team" className="blind" disabled />
                  <label htmlFor="a1">W 1.56</label>
                </li>
                <li>-</li>
                <li>
                  <input type="radio" id="a3" name="cheer_team" className="blind" disabled />
                  <label htmlFor="a3">L 1.56</label>
                </li>
              </ul>
            </div>
          </div>
          {/* // item */}

          {/* item */}
          <div className="item">
            <div className="summary">
              <span className="no">008</span>
              <span className="date">01.09(월) 19:00</span>
              <span className="league soccer">NBA</span>
            </div>
            <div className="box">
              <div className="team left">
                <span className="name">St George Willawong FC</span>
              </div>
              <div className="info">
                <div className="score">
                  <strong className="left on">
                    <em className="num">94</em>
                  </strong>
                  <strong className="right">
                    <em className="num">78</em>
                  </strong>
                </div>
                <div className="state">대기</div>
              </div>
              <div className="team right">
                <div className="name">FC 라다 톨리야티</div>
              </div>
            </div>
            <div className="odds">
              <span className="type unover">+1.0</span>
              <ul className="odds_selector">
                <li>
                  <input type="radio" id="b1" name="cheer_team" className="blind"/>
                  <label htmlFor="b1">W 1.56</label>
                </li>
                <li>
                  <input type="radio" id="b2" name="cheer_team" className="blind"/>
                  <label htmlFor="b2">D 1.56</label>
                </li>
                <li>
                  <input type="radio" id="b3" name="cheer_team" className="blind"/>
                  <label htmlFor="b3">L 1.56</label>
                </li>
              </ul>
            </div>
          </div>
          {/* // item */}

        </div>
        {/* // proto_calculator */}

        {/* proto_odds_slip */}
        <div className="proto_odds_slip view"> { /* 클래스 view */}
          <div className="summary">
            <span className="tit">78-3</span>
            <div className="option">
              <button type="button" className="btn_history">저장내역</button>
              <button type="button" className="btn_oc">열기/닫기</button>
            </div>
          </div>
          <div className="list">
            <div className="info">
              <div className="column">
                <dl>
                  <dt>선택경기</dt>
                  <dd>10경기</dd>
                </dl>
                <dl>
                  <dt>베팅금액</dt>
                  <dd>
                    <input type="text"/>
                    <span className="won">원</span>
                  </dd>
                </dl>
              </div>
              <div className="column">
                <dl>
                  <dt>예상배당률</dt>
                  <dd>613.6배</dd>
                </dl>
                <dl>
                  <dt>예상당첨금</dt>
                  <dd className="amount">99,680,000원</dd>
                </dl>
              </div>
            </div>
            <div className="btn_area">
              <button type="button" className="btn_reset">초기화</button>
              <button type="submit" className="btn_save">저장</button>
            </div>
          </div>
        </div>
        {/* // proto_odds_slip */}

      </div>
      {/* // proto_wrap */}

    </>
  );
};

export default ProtoCalculatorHtml;
