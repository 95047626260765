import React, { useEffect, useState } from 'react';
import RoundTable from '../../ladder/analysis/roundTable/index';
import { LotteryService } from '../../../../../common/services';

const PowerLadderResult = ({ brandName,gameName }) => {
  const [data, setData] = useState();

  useEffect(() => {
    LotteryService.getLotteryRecentList(brandName,gameName).then((res) => {
      setData(res.data);
    });

    return () => {};
  }, []);

  return <RoundTable list={data} title={'회차별 결과'} size={20} />;
};

export { PowerLadderResult };
