import { observable, computed, action, runInAction, toJS } from 'mobx';
import autobind from 'autobind-decorator';
import { asyncAction } from 'mobx-utils';
import SoccerModel from './model';
import AbstractScoreCommon from '../../store/common';
import { getDeletedGames, setDefaultList } from '../../shared/utils';
import { timeUtils } from '../../../../common/utils/timeUtil';
import WebSocketUtil from '../../../../common/utils/socketUtil';
import { ScoreService } from '../../../../common/services';

@autobind
class SoccerStore extends AbstractScoreCommon {
  socket = null;
  isSub = false;

  @observable
  list = [];

  @observable
  todayFinalList = [];

  @observable
  loading = false;

  @observable
  currentDate = timeUtils.todayYmd();

  @observable
  selectedTab = 'ALL';

  @observable
  suggestList = [
    {
      title: '리그',
      list: []
    },
    {
      title: '팀',
      list: []
    }
  ];

  @observable
  searchValue = {};

  constructor() {
    super();
  }

  connectSocket(topic) {
    this.socket = new WebSocketUtil(topic);

    this.socket.ws.onmessage = (event) => {
      if (event) {
        let data = event.data;

        try {
          if (data === 'OK') {
            this.isSub = true;
          } else {
            let messageData = JSON.parse(data);

            if (messageData) {
              const { TOPIC, MESSAGE } = messageData;
              const today = timeUtils.todayYmd();

              if (TOPIC.includes('sports')) {
                const data = JSON.parse(MESSAGE);
                const copy = [...this.list.toJS()];
                let index = -1;

                if (data.hasOwnProperty('gameId')) {
                  index = copy.findIndex((item) => item.id === data.gameId);
                }

                if (TOPIC === `sports.soccer.games`) {
                  if (Boolean(~index)) {
                    this.list[index].updateGame(data.game);
                  }
                }

                if (TOPIC === `sports.soccer.period-data`) {
                  if (Boolean(~index)) {
                    this.list[index].updatePeriodData(data.periodData);
                  }
                }

                if (TOPIC === `sports.soccer.cheers`) {
                  index = copy.findIndex((item) => item.id == data.game_id);
                  if (Boolean(~index)) {
                    this.list[index].cheerCount++;
                  }
                }
              }
            }
          }
        } catch (error) {
          console.log('=== socket on message error ===', error);
        }
      }
    };
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get soccerList() {
    const {
      list,
      selectedGames,
      todayFinalList,
      selectedTab,
      searchValue
    } = this;

    let tabList = list;

    if (selectedTab === 'FINAL') {
      tabList = todayFinalList;
    }

    if (searchValue.hasOwnProperty('id')) {
      tabList = tabList.filter((item) => {
        if (!!searchValue.isTeam) {
          return (
            item.teams.home.id === searchValue.id ||
            item.teams.away.id === searchValue.id
          );
        } else {
          return item.league.id === searchValue.id;
        }
      });
    }

    const deleteSelectedGames = getDeletedGames(tabList, selectedGames);

    return setDefaultList(deleteSelectedGames);
  }

  @computed
  get isFavoriteActiveCheck() {
    const { selectedList } = this;

    return selectedList.length > 0;
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  closeSocket() {
    this.socket.close(() => {
      runInAction(() => {
        this.isSub = false;
        this.socket = null;
      });
    });
  }

  @action
  setSoccerSelectedTab(tab) {
    this.selectedTab = tab;
  }

  @action
  setSearchValue(data) {
    this.searchValue = data;
  }

  @action
  clearSearchValue() {
    this.searchValue = {};
  }

  @action
  setSuggestList(date) {
    const today = timeUtils.todayYmd();
    let list = this.list.toJS();

    if (date === today) {
      if (this.selectedTab === 'ALL') {
        this.setSuggestData(list);
      } else if (this.selectedTab === 'FINAL') {
        this.setSuggestData(this.todayFinalList.toJS());
      }
    } else {
      this.setSuggestData(list);
    }
  }

  @action
  setSuggestData(list) {
    const leagues = [
      ...new Map(
        list.map((item) => [
          item.league.id,
          { name: item.league.shortName, id: item.league.id, isTeam: false }
        ])
      ).values()
    ];

    let teamsArr = [];
    let teams = [];

    list.forEach((item) => {
      if (!!item.teams.home) {
        teamsArr.push({
          name: item.teams.home.name,
          id: item.teams.home.id,
          isTeam: true
        });
      }
      if (!!item.teams.away) {
        teamsArr.push({
          name: item.teams.away.name,
          id: item.teams.away.id,
          isTeam: true
        });
      }
    });

    if (teamsArr.length > 0) {
      teams = [...new Map(teamsArr.map((item) => [item.id, item])).values()];
    }

    this.suggestList = [
      {
        title: '리그',
        list: leagues
      },
      {
        title: '팀',
        list: teams
      }
    ];
  }

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  *getSoccerToday() {
    this.loading = true;

    try {
      this.currentDate = timeUtils.todayYmd();

      if (!this.isSub) {
        yield this.connectSocket([
          'sports.soccer.games',
          'sports.soccer.period-data',
          'sports.soccer.cheers'
        ]);
      }

      const result = yield ScoreService.getSportsSoccerTodayGames();

      if (result) {
        this.list = result.map((item) => new SoccerModel(item));

        this.loading = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  *getTodayFinalCount() {
    try {
      const todayFinalCount = yield ScoreService.getSoccerTodayFinalCount();

      if (todayFinalCount) {
        runInAction(() => {
          this.todayFinalCount = todayFinalCount[0].gameCount;
        });
      }
    } catch (error) {}
  }

  @asyncAction
  *getSoccer(date) {
    this.loading = true;

    try {
      this.currentDate = date;

      if (!this.isSub) {
        yield this.connectSocket([
          'sports.soccer.games',
          'sports.soccer.period-data'
        ]);
      }

      const result = yield ScoreService.getSportsTypeGames(
        date,
        'ALL',
        'soccer'
      );

      if (result) {
        this.list = result.map((item) => new SoccerModel(item));

        this.loading = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  *getTodayFinalSoccer(date) {
    this.loading = true;

    try {
      this.currentDate = timeUtils.todayYmd();

      const result = yield ScoreService.getSportsTypeGames(
        date,
        'FINAL',
        'soccer'
      );

      if (result) {
        this.todayFinalList = result.map((item) => new SoccerModel(item));

        this.loading = false;
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export default SoccerStore;
