import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const BarChart = ({ count = {} }) => {
  return (
    <>
      <div className="data_summary">
        <h3 className="tit">1등 통계</h3>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'ODD',
              parseInt(count.runningball_odd_count) >
                parseInt(count.runningball_even_count) && 'on'
            )}
          >
            <dt>홀</dt>
            <dd>
              <span className="num">{count.runningball_odd_count}</span>
              <span className="per">({count.runningball_odd_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              parseInt(count.runningball_odd_count) <
                parseInt(count.runningball_even_count) && 'on'
            )}
          >
            <dt>짝</dt>
            <dd>
              <span className="num">{count.runningball_even_count}</span>
              <span className="per">({count.runningball_even_percent}%)</span>
            </dd>
          </dl>
        </div>
        {count.runningball_under_count && // 3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 언오버 로직 필요없음
            <div className="chart">
              <dl
                  className={clsx(
                      'bar',
                      'UNDER',
                      parseInt(count.runningball_under_count) >
                      parseInt(count.runningball_over_count) && 'on'
                  )}
              >
                <dt>언더</dt>
                <dd>
                  <span className="num">{count.runningball_under_count}</span>
                  <span className="per">({count.runningball_under_percent}%)</span>
                </dd>
              </dl>
              <dl
                  className={clsx(
                      'bar',
                      'OVER',
                      parseInt(count.runningball_under_count) <
                      parseInt(count.runningball_over_count) && 'on'
                  )}
              >
                <dt>오버</dt>
                <dd>
                  <span className="num">{count.runningball_over_count}</span>
                  <span className="per">({count.runningball_over_percent}%)</span>
                </dd>
              </dl>
            </div>
        }
      </div>
      {count.sum_odd_count && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
          <div className="data_summary">
            <h3 className="tit">123등 합 통계</h3>
            <div className="chart">
              <dl
                  className={clsx(
                      'bar',
                      'ODD',
                      parseInt(count.sum_odd_count) > parseInt(count.sum_even_count) &&
                      'on'
                  )}
              >
                <dt>홀</dt>
                <dd>
                  <span className="num">{count.sum_odd_count}</span>
                  <span className="per">({count.sum_odd_percent}%)</span>
                </dd>
              </dl>
              <dl
                  className={clsx(
                      'bar',
                      'EVEN',
                      parseInt(count.sum_odd_count) < parseInt(count.sum_even_count) &&
                      'on'
                  )}
              >
                <dt>짝</dt>
                <dd>
                  <span className="num">{count.sum_even_count}</span>
                  <span className="per">({count.sum_even_percent}%)</span>
                </dd>
              </dl>
            </div>
            <div className="chart">
              <dl
                  className={clsx(
                      'bar',
                      'UNDER',
                      parseInt(count.sum_under_count) >
                      parseInt(count.sum_over_count) && 'on'
                  )}
              >
                <dt>언더</dt>
                <dd>
                  <span className="num">{count.sum_under_count}</span>
                  <span className="per">({count.sum_under_percent}%)</span>
                </dd>
              </dl>
              <dl
                  className={clsx(
                      'bar',
                      'OVER',
                      parseInt(count.sum_under_count) <
                      parseInt(count.sum_over_count) && 'on'
                  )}
              >
                <dt>오버</dt>
                <dd>
                  <span className="num">{count.sum_over_count}</span>
                  <span className="per">({count.sum_over_percent}%)</span>
                </dd>
              </dl>
            </div>
          </div>
      }
    </>
  );
};

export default BarChart;
