import React, { useEffect, useState } from "react"
import {
	PayTypeWrapper,
	PayTypeItem,
	PayTypeImg,
	ItemCount,
	Price,
	ButtonQuantity,
	Count,
	Buttons,
} from "./styled"
import { RegexUtil } from "../../../../../common/utils/regexUtil"
import { objectPayTypeText, getColorPayType } from "./helpers"
import './item.css';

const SelectPayType = ({
	giftable,
	onClickGift,
	arrayPayTypeResult,
	payType,
	cash,
	medal,
	point,
	onClickBuy,
}) => {
	const [count, setCount] = useState(1)

	const [payTypeSelected, setPayTypeSelected] = useState(undefined)

	useEffect(() => {
		setPayTypeSelected(arrayPayTypeResult.find((item) => item[1])[0])
	}, [arrayPayTypeResult])

	useEffect(() => {
		setCount(1)
	}, [payTypeSelected])

	const handleClickBuy = () => onClickBuy(count, getColorPayType(payTypeSelected));

	return (
		<PayTypeWrapper>
			{arrayPayTypeResult.map((item, index) => {
				const checked = payTypeSelected === item[0];
				const pay_type = getColorPayType(item[0]);

				return (
							<PayTypeItem
								key={index}
								active={!!item[1]}
								onClick={() => item[1] && setPayTypeSelected(item[0])}
							>
								<PayTypeImg
									checked={checked}
									color={pay_type}
								/>
								{item[1] &&  (
									<Price color={pay_type} checked={checked}>
										{RegexUtil.makeComma(
											pay_type === "GOLD" ? cash : pay_type === "POINT" ? point : medal
										)}
									</Price>
								)}
								{!item[1] ?
									<div>{objectPayTypeText[item[0]]}{pay_type === "MEDAL" ? "으로" : "로"} 구매불가</div>
									:
									<Price color={pay_type} checked={checked}>{objectPayTypeText[item[0]]}</Price>
								}
								{checked && (
									<ItemCount>
										<ButtonQuantity
											onClick={() => {
												if (count <= 1) return
												setCount(count - 1)
											}}
											className={getColorPayType(payTypeSelected)+ ' minus'}
											disabled={count === 1}
										>
										</ButtonQuantity>
										<Count>
											<span>{count}</span>
										</Count>
										<ButtonQuantity
											onClick={() => {
												setCount(count + 1)
											}}
											className={getColorPayType(payTypeSelected)+ ' plus'}
										>
										</ButtonQuantity>
									</ItemCount>
								)}
							</PayTypeItem>
				)
			})}
			<Buttons>
				{giftable === "Y" ? (
					<button onClick={() => onClickGift(count, getColorPayType(payTypeSelected))}>선물</button>
				) : (
					""
				)}
				<button className={`buy ${getColorPayType(payTypeSelected)}`} onClick={handleClickBuy}>
					구매
				</button>
			</Buttons>
		</PayTypeWrapper>
	)
}

export default SelectPayType
