import styled from 'styled-components';

export const List = styled.ul`
  background-color: #fff;
  width: 100%;
  li {
    display: flex;
    align-items: center;
    height: 90px;
    box-sizing: border-box;
    padding: 0 20px;
    :not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }
  }
`;

export const Summary = styled.div`
  margin-left: 19px;
  width: calc(100% - 70px);

  > div {
    line-height: 13px;
    display: flex;
    .name {
      font-size: 14px;
      font-weight: 600;
      margin-right: 5px;
    }
    .price-box{
        display: flex;
        flex-direction: column;
        margin-top: auto;
    }
    .exp{
        font-size: 12px;
        color: #1AA718;
        font-weight: bold;
    }
    .point {
      font-size: 12px;
      color: #1287eb;
      &.price {
        font-size: 14px;
      }
    }
    .cash {
      font-size: 14px;
      color: #e82d0c;
    } 
    .medal {
      font-size: 14px;
      color: #FBA019;
    }
    .tp {
      font-size: 14px;
      color: #85a70f;
    }
    .price {
      margin-right: 14px;
      font-weight: bold;
    }
    .display-price {
      font-size: 12px;
      color: #c8c8c8;
      font-weight: bold;
      text-decoration: line-through;
    }

    > p {
      font-size: 11px;
      line-height: 11px;
      color: #999;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 10px 0;
    }
  }
`;
