import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CustomerCenterNotice from './notice';
import CustomerCenterQna from './qna';
import CustomerCenterFaq from './faq';
import CustomerCenterDevelop from './develop';
import CustomerCenterDetail from './components/detail';
import Seo from '../../components/seo';

const CustomerCenter = () => {
  return (
    <>
      <Seo title={'고객센터'} />
      <div id="content">
        <Switch>
          <Route
            exact
            path="/customercenter/notice"
            component={CustomerCenterNotice}
          />
          <Route
            exact
            path="/customercenter/qna/:type"
            component={CustomerCenterQna}
          />
          <Route
            exact
            path="/customercenter/faq"
            component={CustomerCenterFaq}
          />
          <Route
            exact
            path="/customercenter/develop"
            component={CustomerCenterDevelop}
          />
          <Route
            exact
            path="/customercenter/:type/:id"
            component={CustomerCenterDetail}
          />
        </Switch>
      </div>
    </>
  );
};

export default CustomerCenter;
