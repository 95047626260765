import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

const RoundTable = ({ list = [], title = '회차별 통계', size = 10 }) => {
  const [page, setPage] = useState(1);
  const totalPage = useMemo(() => {
    if (list.length === 0) return 1;
    return Math.ceil(list.length / size);
  }, [list.length]);

  return (
    <div className="data_list_wrap">
      <h3 className="tit">{title}</h3>
      <div className="data_list ladder">
        <table className="list" border="1">
          <caption>결과리스트</caption>
          <colgroup>
            <col width="10%" />
            <col width="30%" />
            <col width="30%" />
            <col width="30%" />
          </colgroup>
          <thead>
            <tr>
              <th>회차</th>
              <th>출발</th>
              <th>줄수</th>
              <th>결과</th>
            </tr>
          </thead>
          <tbody>
            {list.slice(0, size * page).map((item, key) => {
              let round = item.round;
              if (item.hasOwnProperty('date_round')) {
                round = item.date_round;
              }

              return (
                <tr key={key}>
                  <td>{round}</td>
                  <td>
                    <span className={clsx('ic', item.start_point)}>
                      {item.start_point === 'LEFT' ? '좌' : '우'}
                    </span>
                  </td>
                  <td>
                    <span
                      className={clsx(
                        'ic',
                        item.line_count === '3' ? 'ODD' : 'EVEN'
                      )}
                    >
                      {item.line_count}
                    </span>
                  </td>
                  <td>
                    <span className={clsx('ic', item.odd_even)}>
                      {item.odd_even === 'ODD' ? '홀' : '짝'}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <button
        className="btn_more"
        style={page === totalPage ? { display: 'none' } : {}}
        onClick={() => {
          if (page >= totalPage) return;
          setPage(page + 1);
        }}
      >
        더보기 ( {page} / {totalPage} )
      </button>
    </div>
  );
};

export default RoundTable;
