import React, { useEffect, useState } from 'react';
import useSportsStore from '../../../hooks/useSportsStore';
import { observer } from 'mobx-react';
import Seo from '../../../components/seo';
import clsx from 'clsx';
import DateMatches from './DateToggle';
import useCheckLogin from '../../../hooks/useCheckLogin';
import SportsLoader from '../../../components/sportsLoader';
import SportsAdsense from '../shared/wrapper/default/AdSense';

const InterestMatch = observer(() => {
  const store = useSportsStore('interest');
  const { isCheckLogin } = useCheckLogin();
  const [init, setInit] = useState(false);

  useEffect(() => {
    initializeInterestData();
  }, []);

  const initializeInterestData = async () => {
    if (isCheckLogin) {
      await store.setUserInterestGameByLocalStorage();
    }
    await store.getFavoriteGames();
    await store.getInterestList();
    setInit(true);
  };

  const { interestGames } = store;

  return (
    <>
      <Seo title={'스포츠 관심경기'} />
      <div className="score_wrap">
        {interestGames.loading && <SportsLoader />}
        {init && !interestGames.loading && store.interestMatches.length > 0 && (
          <div className={clsx('game_list')}>

            {store.interestMatches.map((value, key) => {
              return (
                <DateMatches
                  key={key}
                  value={value}
                  selectedGames={store.selectedGames}
                  setSelectedGame={store.setSelectedGame}
                />
              );
            })}
          </div>
        )}
        {init && !interestGames.loading && store.interestMatches.length === 0 && (
          <>
            <div className="empty_score interest">
              <dl>
                <dt>등록된 관심경기가 없습니다.</dt>
                <dd>
                  관심경기를 등록하시면 해당 페이지에서 모아보실 수 있습니다.
                </dd>
              </dl>
            </div>

          </>
        )}
      </div>
    </>
  );
});

export default InterestMatch;
