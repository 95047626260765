import { observable, action, runInAction } from 'mobx';
// import { asyncAction } from 'mobx-utils';

class ModalStore {
  @observable
  type = '';

  @observable
  option = {
    isOpen: false,
    title: '',
    message: '',
    cancel: null,
    save: null,
    callback: null,
    okCallback: null
  };

  @action
  openAlertModal(option) {
    runInAction(() => {
      this.type = 'AlertModal';

      if (!!option) {
        option.isOpen = option.isOpen ?? false;

        this.option = option;
      }
      this.option.isOpen = true;
    });
  }

  @action
  openConfirmModal(option) {
    runInAction(() => {
      this.type = 'ConfirmModal';

      if (!!option) {
        option.isOpen = option.isOpen ?? false;

        this.option = option;
      }
      this.option.isOpen = true;
    });
  }

  @action
  closeModal() {
    runInAction(() => {
      this.option.isOpen = false;
      this.option.callback && this.option.callback();
    });
  }

  @action
  okModal() {
    runInAction(() => {
      this.option.isOpen = false;
      this.option.okCallback && this.option.okCallback();
    });
  }
}

export default ModalStore;
