import React from 'react';
import { useLocalStore, useObserver } from 'mobx-react';
import protoService from '../../../../common/services/score/proto/protoService';
import ProtoMyPickModel from './model';
import ProtoMyPickDetailModel from '../detail/model';

const ProtoMyPickStore = () => {
  const store = useLocalStore(() => ({
    list: [],
    detail: new ProtoMyPickDetailModel(),
    loading: false,
    selectedTab: 'ALL',
    get myPickList() {
      const { selectedTab, list } = store;
      const deep = [...list];

      return deep.filter((item) => {
        if (selectedTab === 'ALL') {
          return item;
        } else if (selectedTab === 'READY') {
          return item.totalPickStatus === 'UNKNOWN';
        } else if (selectedTab === 'HIT') {
          return item.totalPickStatus === 'HIT';
        } else {
          return item.totalPickStatus === 'NO_HIT';
        }
      });
    },
    onChangeTab(tab) {
      store.selectedTab = tab;
    },
    async getOddsCalculatorList(pickStatus, pageNumber) {
      store.loading = true;

      const result = await protoService.getOddsCalculator(
        pickStatus,
        pageNumber
      );

      if (!!result) {
        store.list = result.map((item) => new ProtoMyPickModel(item));
      }
    },
    async getOddsCalculatorById(id) {
      store.loading = true;

      const result = await protoService.getOddsCalculatorById(id);

      if (!!result) {
        store.detail = new ProtoMyPickDetailModel(result);
      }
    }
  }));

  return {
    getOddsCalculatorList: store.getOddsCalculatorList,
    getOddsCalculatorById: store.getOddsCalculatorById,
    onChangeTab: store.onChangeTab,
    data: useObserver(() => {
      return {
        list: store.myPickList,
        loading: store.loading,
        selectedTab: store.selectedTab,
        detail: store.detail
      };
    })
  };
};

export default ProtoMyPickStore;
