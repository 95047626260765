import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useGameStore from '../../../../hooks/useGameStore';

const PickComment = observer(({ gameType, pickType, items, totalCnt, date, round }) => {
  const gameStore = useGameStore();
  const [page, setPage] = useState(1);
  const totalPage = Math.ceil(totalCnt / 10);

  useEffect(() => {}, []);

  const onClickNextPage = () => {
    let getPickCommentList = gameStore.getLotteryPickCommentList;
    if (gameType === 'ladder' || gameType === 'speed_ladder' || gameType === 'daridari') {
      getPickCommentList = gameStore.getThemePickCommentList;
    }

    getPickCommentList({
      gameType: gameType,
      pickType: pickType === 'all' ? null : pickType,
      limit: 10,
      offset: page * 10,
      date: date,
      round: round,
    }).then((res) => {
      totalCnt = res.data.comment.total_cnt;
    });

    setPage(page + 1);
  };

  const pickInfo = {
    powerball_odd_even: {
      odd: { pick: '홀', class: 'ODD' },
      even: { pick: '짝', class: 'EVEN' },
      pickType: '파워볼',
    },
    powerball_under_over: {
      under: { pick: '언더', class: 'UNDER' },
      over: { pick: '오버', class: 'OVER' },
      pickType: '파워볼',
    },
    powerball_section: {
      a: { pick: 'A', class: 'A' },
      b: { pick: 'B', class: 'A' },
      c: { pick: 'C', class: 'A' },
      d: { pick: 'D', class: 'A' },
      pickType: '파워볼',
    },
    powerball_odd_even_under_over: {
      odd_under: { pick: '홀언', class: 'ODD' },
      odd_over: { pick: '홀오', class: 'ODD' },
      even_under: { pick: '짝언', class: 'EVEN' },
      even_over: { pick: '짝오', class: 'EVEN' },
      pickType: '파워볼',
    },
    sum_odd_even: {
      odd: { pick: '홀', class: 'ODD' },
      even: { pick: '짝', class: 'EVEN' },
      pickType: '일반볼',
    },
    sum_under_over: {
      under: { pick: '언더', class: 'UNDER' },
      over: { pick: '오버', class: 'OVER' },
      pickType: '일반볼',
    },
    sum_size: {
      l: { pick: '대', class: 'EVEN' },
      m: { pick: '중', class: 'ODD' },
      s: { pick: '소', class: 'SMALL' },
      pickType: '일반볼',
    },
    sum_odd_even_under_over: {
      odd_under: { pick: '홀언', class: 'ODD' },
      odd_over: { pick: '홀오', class: 'ODD' },
      even_under: { pick: '짝언', class: 'EVEN' },
      even_over: { pick: '짝오', class: 'EVEN' },
      pickType: '일반볼',
    },
    sum_odd_even_size: {
      odd_large: { pick: '홀대', class: 'ODD' },
      odd_medium: { pick: '홀중', class: 'ODD' },
      odd_small: { pick: '홀소', class: 'ODD' },
      even_large: { pick: '짝대', class: 'EVEN' },
      even_medium: { pick: '짝중', class: 'EVEN' },
      even_small: { pick: '짝소', class: 'EVEN' },
      pickType: '일반볼',
    },
    start: {
      right: { pick: '우', class: 'ODD' },
      left: { pick: '좌', class: 'EVEN' },
      pickType: '',
    },
    line: {
      3: { pick: '3', class: 'ODD' },
      4: { pick: '4', class: 'EVEN' },
      pickType: '',
    },
    combination: {
      l3e: { pick: '좌3', class: 'EVEN' },
      l4o: { pick: '좌4', class: 'ODD' },
      r3o: { pick: '우3', class: 'ODD' },
      r4e: { pick: '우4', class: 'EVEN' },
      pickType: '',
    },
    odd_even: {
      odd: { pick: '홀', class: 'ODD' },
      even: { pick: '짝', class: 'EVEN' },
      pickType: '',
    },
    last_odd_even: {
      odd: { pick: '홀', class: 'ODD' },
      even: { pick: '짝', class: 'EVEN' },
      pickType: '',
    },
    under_over: {
      under: { pick: '언더', class: 'UNDER' },
      over: { pick: '오버', class: 'OVER' },
      pickType: '',
    },
    runningball_under_over: {
      under: { pick: '언더', class: 'UNDER' },
      over: { pick: '오버', class: 'OVER' },
      pickType: '1등볼',
    },
    runningball_odd_even: {
      odd: { pick: '홀', class: 'ODD' },
      even: { pick: '짝', class: 'EVEN' },
      pickType: '1등볼',
    },
    rank: {
      1: { pick: '1번', class: 'ODD' },
      2: { pick: '2번', class: 'EVEN' },
      3: { pick: '3번', class: 'ODD' },
      4: { pick: '4번', class: 'EVEN' },
      pickType: '일반볼',
    },
    last_under_over: {
      under: { pick: '언더', class: 'UNDER' },
      over: { pick: '오버', class: 'OVER' },
      pickType: '',
    },

    count_win: {
      home: { pick: '좌', class: 'HOME' },
      draw: { pick: '무', class: 'DRAW' },
      away: { pick: '우', class: 'AWAY' },
      pickType: '볼 갯수 승무패',
    },
    sum_win: {
      home: { pick: '좌', class: 'HOME' },
      draw: { pick: '무', class: 'DRAW' },
      away: { pick: '우', class: 'AWAY' },
      pickType: '볼 갯수 승무패',
    },
    sum_home_ball_odd_even: {
      odd: { pick: '홀', class: 'ODD' },
      0: { pick: '0', class: 'ZERO' },
      even: { pick: '짝', class: 'EVEN' },
      pickType: '좌측 번호 합 홀짝',
    },
    sum_away_ball_odd_even: {
      odd: { pick: '홀', class: 'ODD' },
      0: { pick: '0', class: 'ZERO' },
      even: { pick: '짝', class: 'EVEN' },
      pickType: '좌측 번호 합 홀짝',
    },
    home_ball1_odd_even: {
      odd: { pick: '홀', class: 'ODD' },
      0: { pick: '0', class: 'ZERO' },
      even: { pick: '짝', class: 'EVEN' },
      pickType: '좌측 1등볼 홀짝',
    },
    away_ball1_odd_even: {
      odd: { pick: '홀', class: 'ODD' },
      0: { pick: '0', class: 'ZERO' },
      even: { pick: '짝', class: 'EVEN' },
      pickType: '좌측 1등볼 홀짝',
    },
    home_ball1_under_over: {
      under: { pick: '홀', class: 'UNDER' },
      0: { pick: '0', class: 'ZERO' },
      over: { pick: '짝', class: 'OVER' },
      pickType: '좌측 1등볼 홀짝',
    },
    away_ball1_under_over: {
      under: { pick: '홀', class: 'UNDER' },
      0: { pick: '0', class: 'ZERO' },
      over: { pick: '짝', class: 'OVER' },
      pickType: '우측 1등볼 언오버',
    },
  };

  const getPickCommentList = (item) => {
    const info = pickInfo[item.pick_type];
    const pickClass = 'ic ' + info[item.pick]['class'];
    const pickText = info[item.pick]['pick'];
    let pickType = info['pickType'];
    console.log(pickType);
    console.log(info);
    console.log(info[item.pick][pickType]);

    if (gameType.startsWith('running') && pickType === '일반볼') {
      pickType = '123등 합';
    }
    let data = null;
    if (gameType === 'powerball' || gameType.startsWith('running')) {
      data = '<dt class="' + pickClass + '">' + pickText + '</dt>  <dd class="type"> ' + pickType + '</dd>';
    } else {
      data = `
            <dt class="${pickClass}">${pickText}</dt>
            <dd class="type">${pickType}</dd>
            `;
    }

    return data;
  };

  return (
    <>
      {items &&
        items.map((item, i) => {
          return (
            <div className='item' key={i}>
              <dl className='pick' dangerouslySetInnerHTML={{ __html: getPickCommentList(item) }}></dl>
              <div className='talk'>{item.comment}</div>
              <div className='nick'>{item.mb_nick}</div>
            </div>
          );
        })}
      {totalCnt > 10 && page !== totalPage && (
        <button className='btn_more' onClick={onClickNextPage}>
          더보기 ({page}/{totalPage})
        </button>
      )}
    </>
  );
});
export { PickComment };
