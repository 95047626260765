import Api from '../../api';
import autobind from 'autobind-decorator';
import { Url } from '../../../constants/environment';
import Cookie from '../../../utils/cookie';

@autobind
class ScoreDetailService extends Api {
  constructor() {
    super();
  }

  getChampCheerList(gameId, sportsId, page = 1) {
    return this.ajax(
      'get',
      Url.champ,
      `sport/comment-mobile?game_id=${gameId}&sports_id=${sportsId}&page=${page}`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   */
  getSportsTypeByGameId(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType === 'icehockey' ? 'hockey' : sportsType
      }/games/${gameId}`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description e-sports (lol, star) 리스트
   * @param {*} type
   * @param {*} date
   * @returns
   */
  getEsportsTypeByGameId(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/esports/${sportsType}/games/${gameId}`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   */
  getBroadcastsBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/broadcasts`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   */
  getManualBroadcastsBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/manual-broadcasts`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 라인업 데이터
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   */
  getLineupBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/lineup`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 출전불가선수
   * @param {*} gameId
   * @returns
   */
  getImpossiblePlayerByGameId(gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/games/${gameId}/impossible-player`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 선수 스탯
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   */
  getPlayerStatBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/player/stat`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   * @memberof ScoreDetailService
   */
  getPlayerGameStatBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/player/game-stat`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   * @memberof ScoreDetailService
   */
  getPlayerSeasonStatBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/player/season-stat`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 경기 전적
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   */
  getRecordBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/record`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 팀 스탯
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   */
  getTeamStatBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/team/stat`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   * @memberof ScoreDetailService
   */
  getTeamGameStatBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/team/game-stat`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   * @memberof ScoreDetailService
   */
  getTeamSeasonStatBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/team/season-stat`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 랭킹
   * @param {*} sportsType
   * @param {*} gameId
   * @returns
   */
  getRankBySportsType(sportsType, gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/${sportsType}/games/${gameId}/rank`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} gameId
   * @returns
   * @memberof ScoreDetailService
   */
  getOddsImportantOdds(gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/games/${gameId}/odds/important`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} gameId
   * @returns
   * @memberof ScoreDetailService
   */
  getOddsInternationalOdds(gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/games/${gameId}/odds/internationals`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} gameId
   * @returns
   * @memberof ScoreDetailService
   */
  getOddsSites(gameId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/games/${gameId}/odds/sites`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 응원하기 댓글 리스트
   * @param {*} gameId
   * @param {*} sportsId
   * @param {*} page
   * @returns
   */
  getCheerList(gameId, sportsId, page) {
    return this.ajax(
      'get',
      Url.named,
      `sport/comment-mobile?game_id=${gameId}&sports_id=${sportsId}&page=${page}`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   *
   * @param {*} gameId
   * @param {
        "content": string;
        "device": "WEB",
        "homeAway": null,
        "selectTeamId": null,
        "sportsId": 1
      } data
   * @returns
   */
  postCheer(gameId, data) {
    return this.ajax(
      'post',
      Url.sports,
      `/sports/games/${gameId}/${data.messageType === 'caster' ? 'caster' : 'cheer'
      }`,
      data,
      true
    )
      .then((res) => {
        if (res.status === 200) return res;
      })
      .catch((error) => error.response);
  }

  deleteCheer(chat) {
    return this.ajax(
      'delete',
      Url.named,
      `sport/comment/power-chat?cheer_id=${chat.id}&game_id=${chat.game_id}&sports_id=${chat.sports_id}`,
      null,
      true
    )
      .then((res) => {
        return res;
      })
      .catch((error) => error.response);
  }

  /**
   * @param {*} targetId
   * @param {*} gameId
   * @param {*} sportsId
   * @returns
   */
  getCommentReport(targetId, gameId, sportsId) {
    return this.ajax(
      'get',
      Url.named,
      `sport/comment/report?target_id=${targetId}&game_id=${gameId}&sports_id=${sportsId}`,
      null,
      true
    )
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .catch((e) => {
        return false;
      });
  }

  /**
   * @param {
        "targetId": string;
        "gameId": "string",
        "sportsId": 1,
        "reportType": "string",
        "device": "WEB",
        "ip": "string"
      } data
   * @returns
   */
  postCommentReport(data) {
    const form = new FormData();
    form.append('target_id', data.target_id);
    form.append('device', data.device);
    form.append('game_id', data.game_id);
    form.append('report_type', data.report_type);
    form.append('sports_id', data.sports_id);

    return this.ajax('post', Url.named, `sport/comment/report`, form, true)
      .then((res) => {
        if (res.status === 200) return res;
      })
      .catch((error) => error.response);
  }

  /**
   * @description 스타 세트전력
   * @param {*} gameId
   * @param {*} setNum
   * @returns
   */
  getStarcraftSetRecord(gameId, setNum) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/starcraft/games/${gameId}/sets/${setNum}/record`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  /**
   * @description 스타 세트전력
   * @param {*} mapId
   * @returns
   */
  getStarcraftMapRecord(mapId) {
    return this.ajax(
      'get',
      Url.sports,
      `/sports/starcraft/maps/${mapId}/record`
    ).then((res) => {
      if (res.status === 200) return res.data;
    });
  }

  async cheerImageChange(file) {
    if (!file) {
      return;
    }

    const form = new FormData();
    form.append('image', file);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `sport/comment/image`,
      form
    );

    return res;
  }

  /**
   * @param {*} targetId
   * @param {*} gameId
   * @param {*} sportsId
   * @returns
   */
  getHotGames() {
    return this.ajax('get', Url.sports, `/hot-games`, null)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .catch((e) => {
        return false;
      });
  }
}

export default new ScoreDetailService();
