import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Item } from './item';

const MypageItemGiftFriendList = observer(({ items = [], setNick }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (items.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [items])

  return (
    <div>
      {
        !isLoading && !!items && items.map((item, i) => <Item item={item} setNick={setNick} key={i} />)
      }
    </div>
  );
});

export {
  MypageItemGiftFriendList
};
