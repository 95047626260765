import autobind from 'autobind-decorator';
import { computed, extendObservable, observable } from 'mobx';
import format from 'date-fns/format';
import ko from 'date-fns/locale/ko';
import { StringUtil } from '../../../../../../common/utils/stringUtil';
import { ScoreUtil } from '../../../../../../common/utils/scoreUtil';
import { _SPORTS_GAME_TYPE } from '../../../../../../common/constants/sportsConstants';

@autobind
class ProtoMyPickDetailItemModel {
  @observable
  oddsList = [];

  @observable
  selectOddsId = null;

  constructor(data) {
    const deep = { ...data.roundGame };

    this.oddsList = data.oddsList;
    this.selectOddsId = data.selectOddsId;

    extendObservable(this, deep);
  }

  @computed
  get game() {
    return this[_SPORTS_GAME_TYPE[this.sportsType]];
  }

  @computed
  get sportsTypeLower() {
    return StringUtil.lower(this.game.sportsType);
  }

  @computed
  get getStartDateTime() {
    return format(
      new Date(this.game.startDatetime + '+09:00'),
      'MM.dd(eee) HH:mm',
      {
        locale: ko
      }
    );
  }

  @computed
  get mark() {
    const { number, oddsList, selectOddsId } = this;
    let num = new Array(3).fill('');
    let type = '';

    if (!!number) {
      num = number.split('').map((item) => `n${item}`);
    }

    if (oddsList.length > 0) {
      const filter = oddsList.filter((item) => item.id === selectOddsId)[0]
        .type;

      if (filter === 'HANDICAP_WIN' || filter === 'WIN' || filter === 'UNDER') {
        type = 'w';
      } else if (
        filter === 'HANDICAP_LOSS' ||
        filter === 'LOSS' ||
        filter === 'OVER'
      ) {
        type = 'l';
      } else {
        type = 'd';
      }
    }

    return {
      num,
      type
    };
  }

  @computed
  get getGroupType() {
    const { oddsList, groupType } = this;
    let type = '';
    let isChanged = false;
    let upDown = '';
    let optionArr = [];
    let className = '';
    let visible = false;
    let optionValue = '';

    if (this.groupType === 'HANDICAP') {
      type = `H`;
      className = 'handicap';
      visible = true;
    } else if (this.groupType === 'UNOVER') {
      type = `U/O`;
      className = 'unover';
      visible = true;
    }

    // debugger;
    if (oddsList.length > 0) {
      if (groupType === 'HANDICAP' || groupType === 'UNOVER') {
        optionArr = oddsList.filter(
          (item) => item.type === 'UNDER' || item.type === 'HANDICAP_WIN'
        )[0];

        if (!!optionArr.updateType) {
          if (
            optionArr.updateType === 'ALL' ||
            optionArr.updateType === 'OPTION'
          ) {
            if (!!optionArr.preOptionValue) {
              isChanged = true;

              if (optionArr.preOptionValue > optionArr.optionValue) {
                upDown = 'down';
              } else {
                upDown = 'up';
              }
            }
          }
        }
        const fixedValue = isNaN(optionArr.optionValue)
          ? optionArr.optionValue
          : optionArr.optionValue.toFixed(1);

        optionValue =
          optionArr.optionValue > 0 && this.groupType === 'HANDICAP'
            ? `+${fixedValue}`
            : fixedValue;

        type += ` ${optionValue}`;
      }
    }

    return { type, isChanged, upDown, className, visible, optionValue };
  }

  @computed
  get teamData() {
    const result = {
      home: {
        score: 0,
        imgPath: '',
        isActive: false
      },
      away: {
        score: 0,
        imgPath: '',
        isActive: false
      },
      odds: {
        win: {
          id: 0,
          odds: '-',
          oddsChange: '',
          hit: false
        },
        draw: {
          id: 0,
          odds: '-',
          oddsChange: '',
          hit: false
        },
        loss: {
          id: 0,
          odds: '-',
          oddsChange: '',
          hit: false
        },
        optionValue: '',
        preOptionValue: ''
      }
    };
    const { groupType } = this;
    const { teams, status, period, sportsType } = this.game;

    const scoreData = ScoreUtil.getTeamScore(
      this.game,
      status,
      sportsType,
      period
    );

    result.odds = ScoreUtil.getScoreOddssData(this.oddsList, result.odds);
    result.home.score = scoreData.home.score;
    result.away.score = scoreData.away.score;
    result.home.imgPath = teams.home.imgPath;
    result.away.imgPath = teams.away.imgPath;

    if (sportsType === 'VOLLEYBALL') {
      if (groupType === 'HANDICAP') {
        if (result.home.score + result.odds.optionValue > result.away.score) {
          result.home.isActive = true;
        } else if (
          result.home.score + result.odds.optionValue <
          result.away.score
        ) {
          result.away.isActive = true;
        }
      } else {
        if (result.home.score > result.away.score) {
          result.home.isActive = true;
        } else if (result.home.score < result.away.score) {
          result.away.isActive = true;
        }
      }
    } else {
      if (groupType === 'HANDICAP') {
        if (result.home.score + result.odds.optionValue > result.away.score) {
          result.home.isActive = true;
        } else if (
          result.home.score + result.odds.optionValue <
          result.away.score
        ) {
          result.away.isActive = true;
        }
      } else {
        if (result.home.score > result.away.score) {
          result.home.isActive = true;
        } else if (result.home.score < result.away.score) {
          result.away.isActive = true;
        }
      }
    }

    if (sportsType === 'SOCCER') {
      if (period === 5) {
        result.home.score = getScoreSum(teams.home, 3);
        result.away.score = getScoreSum(teams.away, 3);
      }
    }

    function getScoreSum(team, underPeriod) {
      if (!!team.periodData) {
        if (team.periodData.length > 0) {
          return team.periodData
            .filter((item) => item.period < underPeriod)
            .reduce((a, b) => {
              return a + b.score;
            }, 0);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }

    const checkOddssHit = Object.keys(result.odds).filter((item) => {
      if (result.odds[item].hit) {
        return item;
      }
    })[0];

    if (checkOddssHit === 'win') {
      result.home.isActive = true;
      result.away.isActive = false;
    } else if (checkOddssHit === 'loss') {
      result.home.isActive = false;
      result.away.isActive = true;
    } else if (checkOddssHit === 'draw') {
      result.home.isActive = false;
      result.away.isActive = false;
    }

    if (status === 'CANCEL' || status === 'POSTPONED') {
      result.home.isActive = false;
      result.away.isActive = false;
    }

    if (Math.abs(result.odds.optionValue) > 0 && groupType === 'HANDICAP') {
      result.home.score = result.home.score + result.odds.optionValue;
    }

    return result;
  }

  @computed
  get getStatus() {
    const { oddsList, groupType } = this;
    const {
      gameStatus,
      sportsType,
      period,
      result,
      inningDivision
    } = this.game;

    // debugger;

    /**
     * roundGame -> gameStatus
     * - READY
     * - IN_PROGRESS
     * - FINAL
     * - CANCEL
     */

    let periodText = '';
    let statusClass = '';
    let oddssHitCheck = !!oddsList && oddsList.length > 0 ? true : false;
    let hitType = oddssHitCheck
      ? oddsList.filter((data) => data.result === 'HIT')[0]
      : null;
    let specialHit = oddssHitCheck
      ? oddsList.some((data) => data.result === 'SPECIAL_HIT')
      : false;

    if (gameStatus === 'SUSPENDED') {
      periodText = '취소';
      statusClass = 'cancel';
    } else if (gameStatus === 'CANCEL') {
      periodText = '취소';
      statusClass = 'cancel';
    } else if (gameStatus === 'DELAY') {
      periodText = '연기';
      statusClass = 'cancel';
    } else if (gameStatus === 'IN_PROGRESS') {
      if (sportsType === 'SOCCER') {
        // 축구
        if (period === 1) {
          periodText = '전반';
        } else if (period === 2) {
          periodText = '후반';
        } else if (period === 3 || period === 4) {
          periodText = '연장';
        } else if (period === 5) {
          periodText = '승부차기';
        }
      } else if (sportsType === 'BASEBALL') {
        // 야구
        if (inningDivision === 'TOP') {
          periodText = `${period}회초`;
        } else {
          periodText = `${period}회말`;
        }
      } else if (sportsType === 'BASKETBALL') {
        // 농구
        if (period >= 5) {
          periodText = '연장';
        } else {
          periodText = `${period}Q`;
        }
      } else if (sportsType === 'VOLLEYBALL') {
        periodText = `${period}set`;
      } else {
        periodText = `${period}`;
      }
      statusClass = 'play';
    } else if (gameStatus === 'BREAK_TIME') {
      periodText = 'HT';
      statusClass = 'play';
    } else if (gameStatus === 'PAUSE') {
      periodText = '중지';
      statusClass = 'play';
    } else if (gameStatus === 'PENDING') {
      periodText = '중단';
      statusClass = 'play';
    } else if (gameStatus === 'POSTPONED') {
      if (this.gameStatus === 'CANCEL') {
        periodText = '취소';
        statusClass = 'cancel';
      } else {
        periodText = '연기';
        statusClass = 'play';
      }
    } else if (gameStatus === 'CUT') {
      periodText = '중단';
      statusClass = 'play';
    } else if (gameStatus === 'READY') {
      periodText = '대기중';
      statusClass = 'wait';
    } else if (gameStatus === 'FINAL') {
      if (groupType === 'UNOVER') {
        if (oddssHitCheck) {
          if (!!hitType) {
            periodText = hitType.type === 'UNDER' ? '언더' : '오버';
            statusClass = hitType.type === 'UNDER' ? 'win' : 'lose';
          }
        } else {
          periodText = '미확인';
          statusClass = 'cancel';
        }
      } else if (groupType === 'HANDICAP') {
        if (oddssHitCheck) {
          if (!!hitType) {
            if (hitType.type === 'HANDICAP_WIN') {
              periodText = '핸디승';
              statusClass = 'win';
            } else if (hitType.type === 'HANDICAP_LOSS') {
              periodText = '핸디패';
              statusClass = 'lose';
            } else {
              periodText = '핸디무';
              statusClass = 'draw';
            }
          }
        } else {
          periodText = '미확인';
          statusClass = 'cancel';
        }
      } else {
        if (oddssHitCheck) {
          if (!!hitType) {
            if (hitType.type === 'WIN') {
              periodText = '승';
              statusClass = 'win';
            } else if (hitType.type === 'DRAW') {
              periodText = '무';
              statusClass = 'draw';
            } else if (hitType.type === 'LOSS') {
              periodText = '패';
              statusClass = 'lose';
            } else {
              periodText = '미확인';
              statusClass = 'cancel';
            }
          } else {
            periodText = '미확인';
            statusClass = 'cancel';
          }
        } else {
          periodText = '미확인';
          statusClass = 'cancel';
        }
      }
    }

    if (specialHit) {
      periodText = '적특';
      statusClass = 'cancel_point_on';
    }

    return {
      statusText: periodText,
      statusClass: statusClass
    };
  }
}

export default ProtoMyPickDetailItemModel;
