import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Detail from '../../community/components/detail';

const HomeBoardDetail = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    const type = query.get('type');

    const [isDetailOpen, setOpen] = useState(false);

    useEffect(() => {
        if (id) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [id]);

    return (
        <Detail
            isOpen={isDetailOpen}
            onClose={() => setOpen(false)}
            match={{ params: { id, type } }}
        />
    );
};

export default HomeBoardDetail;
