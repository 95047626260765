import React, { useEffect, useMemo, useState } from "react";
import AnalysisOption from "../../analysisOption";
import BarChart from "./barChart";
import RoundTable from "./roundTable";
import PatternGraph from "./patternGraph";
import MinMaxWithinPeriod from "./minMaxWithinPeriod";
import CountPattern from "../../countPattern";
import PatternBarChart from "./patternBarChart";
import PatternTable from "../../patternTable";
import { useLocation } from "react-router-dom";
import { timeUtils } from "../../../../../common/utils/timeUtil";
import { LotteryService } from "../../../../../common/services";
import Ball2RoundTable from "./roundTable/ball2RoundTable";
import Space8RoundTable from "./roundTable/space8RoundTable";
import Space8BarChart from "./barChart/space8BarChart";
import Space8PatternBarChart from "./patternBarChart/Space8PatternBarChart";
import Space8PeriodBarChart from "./minMaxWithinPeriod/space8PeriodBarChart";



const todayDate = timeUtils.todayYmd();

const RunningballAnalysis = ({ brandName, gameName }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get("type") || "daily";
  const option = query.get("option") || "oneDay";
  const startDate = query.get("start_dt") || todayDate;
  const endDate = query.get("end_dt") || todayDate;
  const round = parseInt(query.get("round")) || 100;
  const count = parseInt(query.get("count")) || 10;

  const [data, setDate] = useState({
    count: {},
    list: [],
    percent: {}
  });
  const [patternData, setPatternData] = useState({
    list: [],
    percent: {},
    search_pattern: "",
    search_pattern_round: ""
  });

  const getGameTypeParameter = () => {
    switch(gameName){
      case "runningball5_6" :
        return "running_ball6";
      case "runningball5_4" :
        return "running_ball4";
      case "runningball3" :
        return "running_ball2";
      case "space8" :
        return "space8";
    }
  };
  useEffect(() => {
    let params = {};
    if (type === "daily") {
      if (option === "oneDay") {
        params = { startDate: startDate, endDate: startDate };
      } else {
        params = {
          startDate: startDate,
          endDate: endDate,
          searchType: "daily_range"
        };
      }
      params.gameType = getGameTypeParameter();
      LotteryService.getLotteryStatAnalysis({ ...params }).then((res) => {
        setDate(res.data);
      });
    } else if (type === "round") {
      if (option === "single") {
        params = { startDate: startDate, round: round };
      } else {
        params = { roundCount: round };
      }
      params.gameType = getGameTypeParameter();
      LotteryService.getLotteryStatAnalysis({ ...params }).then((res) => {
        setDate(res.data);
      });
    } else if (type === "pattern") {
      params = { gameType: getGameTypeParameter(), patternType: option, count: count };
      LotteryService.getLotteryStatAnalysisPattern({ ...params }).then(
        (res) => {
          setPatternData(res.data);
        }
      );
    }
  }, [type, option, round, startDate, count]);


  const optionsMap = useMemo(() => {
    return {
      daily: [
        { value: "oneDay", name: "하루씩보기" },
        { value: "period", name: "기간으로보기" }
      ],
      round: [
        { value: "single", name: "단일회차" },
        { value: "latest", name: "최근회차" }
      ],
      pattern:
          gameName === 'runningball3' ?
          [
            { value: "runningball_odd_even", name: "1등 홀짝" }
          ]
              :
              gameName === 'space8' ?
                  [
                    { value: "count_win", name: "볼 갯수 승무패" },
                    { value: "sum_win", name: "볼 번호 합산 승무패" },
                    { value: "sum_home_ball_odd_even", name: "좌측 번호 합 홀짝" },
                    { value: "sum_away_ball_odd_even", name: "우측 번호 합 홀짝" },
                    { value: "home_ball1_odd_even", name: "좌측 1등볼 홀짝" },
                    { value: "away_ball1_odd_even", name: "우측 1등볼 홀짝" },
                    { value: "home_ball1_under_over", name: "좌측 1등볼 언오버" },
                    { value: "away_ball1_under_over", name: "우측 1등볼 언오버" },
                  ] :
          [
            { value: "runningball_odd_even", name: "1등 홀짝" },
            { value: "runningball_under_over", name: "1등 언오버" },
            { value: "sum_odd_even", name: "123등 합 홀짝" },
            { value: "sum_under_over", name: "123등 합 언오버" },
          ]
    };

  }, []);

  const barComponent = useMemo(() => {
    if (gameName === 'space8') {
      return <Space8BarChart count={data.count} />;
    } else {
      return <BarChart count={data.count} />;
    }
  }, [ data.count]);

  const roundTableComponent = useMemo(() => {
    if (gameName === 'space8') {
      return <Space8RoundTable list={data.list} />;
    }
    if(gameName === 'runningball3'){
      return <Ball2RoundTable list={data.list} />;
    }else {
      return <RoundTable list={data.list} />;
    }
  }, [ data.list]);

  const patternBarComponent = useMemo(() => {
    if (gameName === 'space8') {;
      return <Space8PatternBarChart percent={patternData.percent} gameName={gameName} />;
    } else {
      return <PatternBarChart percent={patternData.percent} gameName={gameName} />;
    }
  }, [[ patternData.percent]]);

  if (gameName === 'space8') {
    return (
        <div className="game_stats_area">
          <AnalysisOption
              options={optionsMap[type]}
              patternTypeList={optionsMap.pattern}
          />
          <div className="date_detail_wrap">
            {(type !== "pattern") && barComponent}
            {(option === "oneDay" || option === "latest") && (
                <PatternGraph
                    gameName={getGameTypeParameter()}
                    patternTypeList={optionsMap.pattern}
                />
            )}
            {option === "period" && <Space8PeriodBarChart data={data} />}
            {option !== "period" && type !== "pattern" && (
                roundTableComponent
            )}

            {type === "pattern" && (
                <>
                  <CountPattern
                      title={`${
                          optionsMap.pattern.find((item) => item.value === option).name
                      } 기준 ${count}패턴`}
                      searchPattern={patternData.search_pattern}
                      searchPatternRound={patternData.search_pattern_round}
                  />
                  {patternBarComponent}
                  <PatternTable
                      list={patternData.list}
                      option={option}
                      count={count}
                  />
                </>
            )}
          </div>
        </div>
    );
  }

  return (
    <div className="game_stats_area">
      <AnalysisOption
        options={optionsMap[type]}
        patternTypeList={optionsMap.pattern}
      />
      <div className="date_detail_wrap">
        {(type !== "pattern") && barComponent}
        {(option === "oneDay" || option === "latest") && (
          <PatternGraph
            gameName={getGameTypeParameter()}
            patternTypeList={optionsMap.pattern}
          />
        )}
        {option === "period" && <MinMaxWithinPeriod data={data} />}
        {option !== "period" && type !== "pattern" && (
          roundTableComponent
        )}

        {type === "pattern" && (
          <>
            <CountPattern
              title={`${
                optionsMap.pattern.find((item) => item.value === option).name
              } 기준 ${count}패턴`}
              searchPattern={patternData.search_pattern}
              searchPatternRound={patternData.search_pattern_round}
            />
            {patternBarComponent}
            <PatternTable
              list={patternData.list}
              option={option}
              count={count}
            />
          </>
        )}
      </div>
    </div>
  );
};

export { RunningballAnalysis };
