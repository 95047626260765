import React from "react";

const PersonalInfo = () => {
  return (
    <div className="wrap_main">
      <div className="doc-contents">
        <h1>Privacy Policy</h1>
        <h3>INFORMATION ON THE PROCESSING OF PERSONAL DATA</h3>
        <h4>INTRODUCTORY INFORMATION</h4>
        <p>
          Vincoholdinglp ("we", "our" or "us") respects the privacy of its users
          ("you" or "your"). This privacy notice ("Notice") explains how we
          collect, use, disclose and protect your personal data in connection
          with our website named.com ("Website"). We process your personal data
          in connection with your use of the Website, registration on the
          Website, direct marketing, our protection, communication and in
          connection with the storage of HTTP cookies on your device.
        </p>
        <h4 id="article1">OUR IDENTITY AND CONTACT DETAILS</h4>
        <p>
          <strong>1.1.</strong> We, as the controller of your personal data, are
          Vincoholdinglp.
        </p>
        <p>
          <strong>1.2.</strong> We have appointed a data protection officer who
          you can contact for all matters related to the processing of your
          personal data and the exercise of your rights by e-mail at{" "}
          <strong>
            <a href="mailto:help@named.com">help@named.com</a>
          </strong>
          .
        </p>
        <h4 id="article2">
          PROCESSING IN CONNECTION WITH THE USE OF THE WEBSITE AND YOUR
          REGISTRATION
        </h4>
        <p>
          <strong>
            2.1. Processing specifications when using the Website.
          </strong>{" "}
          If you use the Website, we process for the following B and C purposes
          records related to your use of the Website (IP address, Geo IP,
          operating system data, your browser type and version, and Website user
          settings), which are collected automatically when you use the Website.{" "}
        </p>
        <p>
          <strong>
            2.2. Registration and login processing specifications.
          </strong>{" "}
          If you create an account then while using the Website and using the
          content and features of the Website subject to registration we process
          following data for purposes A, B and C, such as:
        </p>
        <ul>
          <li>
            <strong>2.2.1.</strong> Contact details, including username and the
            email address you provided to us when you created your account.
          </li>
          <li>
            <strong>2.2.2.</strong> Additional voluntary provided personal
            information, including age, photographs, country of origin and your
            date of registration, followed users, numbers of following users and
            followed users.
          </li>
          <li>
            <strong>2.2.3.</strong> A pseudonymised identifier for tracking
            Website performance and reporting technical faults, and other
            pseudonymised identifiers that we have put in place to protect you.
          </li>
          <li>
            <strong>2.2.4.</strong> Information about your use of the Service
            (e.g. personal settings, use and statistics of use of prediction
            function, coupons, overall prediction statistics, ROI, prediction
            likes).
          </li>
        </ul>
        <p>
          <strong>2.3. Purposes of processing.</strong> We process your personal
          data to the extent necessary for the following purposes:
        </p>
        <ul>
          <li>
            <strong>• Purpose A:</strong> The purpose of the processing of your
            personal data is to perform a contract between you and us or to
            enable us to take steps prior to entering into such a contract in
            connection with your registration or login to the Website.
          </li>
          <li>
            <strong>• Purpose B:</strong> The purpose of the processing of the
            above personal data is to ensure the technical operation of the
            Website, i.e. the proper and smooth functioning of the Website and
            the related troubleshooting of reported technical errors and the
            subsequent optimization (prevention of errors in the future) of the
            Website. We have carried out an assessment of whether it is possible
            to process your personal data for this purpose and we have also
            carried out the necessary proportionality test, which assesses the
            interests in protecting your rights and our interests. We have
            concluded that, in view of the fact that most of the personal data
            we process is available for other purposes, that we use
            pseudonymised identifiers, that the removal of defects is also in
            the interest of the Website user, that the subsequent optimisation
            of the Website is in the interest of all Website users, it does not
            appear to us that your interests or fundamental rights and freedoms
            to protect your personal data outweigh our legitimate interests as
            set out above.
          </li>
          <li>
            <strong>• Purpose C:</strong> The purpose of processing your
            personal data is to comply with our legal obligations, in particular
            in the areas of tax, accounting, and advertising regulation.
          </li>
        </ul>
        <p>
          <strong>2.4. Legal basis.</strong> The legal bases for the processing
          of personal data for individual purposes are as follows:
        </p>
        <ul>
          <li>
            <strong>2.4.1.</strong> For purpose A the legal basis is necessity
            of the processing for the performance of a contract between you and
            us or to enable us to take steps prior to entering into such a
            contract within the meaning of Article 6(1)(b) of Regulation (EU)
            2016/679 of the European Parliament and of the Council of 27 April
            2016 on the protection of natural persons with regard to the
            processing of personal data and on the free movement of such data
            and repealing Directive 95/46/EC (General Data Protection
            Regulation) (the "Regulation").
          </li>
          <li>
            <strong>2.4.2.</strong> For purpose B the legal basis is our
            legitimate interest under Article 6(1)(f) of the Regulation
            consisting in ensuring the technical operation of the Website.
          </li>
          <li>
            <strong>2.4.3.</strong> For purpose C, the legal basis is to comply
            with the legal obligations under Article 6(1)(c) of the Regulation.
          </li>
        </ul>
        <p>
          <strong>2.5. Retention time.</strong> Your personal data will be
          processed:
        </p>
        <ul>
          <li>
            • For purpose A for up to 3 years after the termination of the
            contract.
          </li>
          <li>
            • For purpose B for the duration of the contract if you have an
            account with us, or for 1 month after using the Website if you do
            not have an account.
          </li>
          <li>
            • For purpose C for the time necessary to comply with legal
            obligations in accordance with the relevant generally binding legal
            regulations.
          </li>
        </ul>
        <p>
          <strong>2.6. Other beneficiaries.</strong> Persons who provide
          technical activities for us, including persons providing hosting and
          software development services to us, and other companies in our group
          ("our group") may be additional recipients of your personal data. For
          certain personal information in your profile, additional recipients
          could be all internet users as your profile is public, you have the
          option to choose a private profile, hiding your predictions.
        </p>
        <p>
          <strong>2.7. Your rights.</strong> Under the conditions set out in the
          Regulation and depending on the purposes stated, you have the right to
          request that we provide you with access to your personal data, the
          right to rectification or erasure of your personal data or restriction
          of its processing, and the right to portability of your personal data.
          Please note that we may ask you to verify your identity before
          complying with such a request:
        </p>
        <ul>
          <li>
            <strong>2.7.1.</strong> If you suspect that the processing of your
            personal data has infringed or is infringing the Regulation, you
            have the right, among other things, to lodge a complaint with a
            supervisory authority, in particular with a supervisory authority in
            the EU Member State where you are habitually resident, have a place
            of work or where the alleged infringement of the Regulation is
            located.
          </li>
          <li>
            <strong>2.7.2.</strong> You have the right to object to the
            processing of your personal data for purpose B at any time. In the
            event that you object to the processing of your personal data for
            purpose B, we will not further process your personal data unless you
            demonstrate compelling legitimate grounds for the processing which
            override the interests or your rights and freedoms or if such data
            is necessary for the establishment, exercise or defence of our legal
            claims.
          </li>
          <li>
            <strong>2.7.3.</strong> You are not obliged to provide us with your
            personal data. However, the provision of your personal data is a
            necessary requirement for purpose A, i.e. for the conclusion and
            performance of a contract, and without the provision of your
            personal data, it is not possible to conclude or perform the
            contract and this may prevent you from using certain features and
            purchases within the Website that are only available to registered
            users.
          </li>
        </ul>
        <h4 id="article3">PROCESSING RELATED TO MARKETING </h4>
        <p>
          <strong>3.1. Processing specifications.</strong> With your consent, we
          process your:
        </p>
        <ul>
          <li>
            <strong>3.1.1.</strong> Personal information as explained in section
            2.2 above.
          </li>
          <li>
            <strong>3.1.2.</strong> Pseudonymised identifiers that we have put
            in place to protect you.
          </li>
          <li>
            <strong>3.1.3.</strong> Information about your use of the Website
            (e.g. IP address, Geo IP and analytical information obtained in
            accordance with Article 7 of the Information) and information about
            your device (e.g. operating system, browser and browser version)
            that is automatically collected by your use of the Website, for the
            purpose of sending commercial communications (marketing offers)
            promoting us, our group or third parties or for the purpose of
            carrying out other marketing activities towards you, including
            marketing profiling. This processing of personal data is based on
            your explicit consent, which you have the right to withdraw at any
            time.
          </li>
        </ul>
        <p>
          <strong>3.2. Purpose D.</strong> The purpose of processing your
          personal data is to send you commercial communications and to carry
          out other marketing activities towards you, including marketing
          profiling.
        </p>
        <p>
          <strong>3.3. Legal basis.</strong> The legal basis for the processing
          of personal data is your consent given to us within the meaning of
          Article 6(1)(a) of the Regulation.
        </p>
        <p>
          <strong>3.4. Retention time.</strong> We will process (store) your
          personal data for a period of four (4) years from the receipt of your
          consent to processing, and processing will cease if you withdraw your
          consent to the processing of personal data for this purpose.
        </p>
        <p>
          <strong>3.5. Other beneficiaries.</strong> Persons who provide
          marketing and advertising services for us, as well as persons
          operating our Website and other companies in our group (data
          processors), and the most widely used social networks, e.g. Facebook,
          Twitter, LinkedIn (as controller or joint controllers, as the case may
          be) may be other recipients of your personal data.
        </p>
        <p>
          <strong>3.6. Your rights.</strong> Under the terms of the Regulation,
          you have the right to request that we provide you with access to your
          personal data, the right to rectification or erasure of your personal
          data or restriction of its processing, and the right to the
          portability of your personal data:
        </p>
        <ul>
          <li>
            <strong>3.6.1</strong> You have the right to withdraw your consent
            to the processing of your personal data that you have provided to us
            at any time. This does not affect the lawfulness of the processing
            of your personal data prior to such withdrawal of consent. You may
            withdraw your consent to the processing of your personal data by
            clicking on the hyperlink provided in any commercial communication
            we send you or by sending an e-mail message to our contact e-mail
            address (see Article 1.2 of this Notice).
          </li>
          <li>
            <strong>3.6.2.</strong> If you suspect that the processing of your
            personal data has infringed or is infringing the Regulation, you
            have the right, among other things, to lodge a complaint with a
            supervisory authority, in particular with a supervisory authority in
            the EU Member State where you are habitually resident, have your
            place of work or where the alleged infringement of the Regulation is
            located.
          </li>
          <li>
            <strong>3.6.3.</strong> You are under no obligation to provide
            personal data. The provision of your personal data is not a legal or
            contractual requirement, nor is it a requirement that is necessary
            to enter into a contract.
          </li>
        </ul>
        <h4 id="article4">PROCESSING RELATED TO COMMUNICATION</h4>
        <p>
          <strong>4.1. Processing specifications.</strong> If you contact us via
          the contact form or communicate with us directly, we process your
          email, name and the content of the communication based on your
          consent.
        </p>
        <p>
          <strong>4.2. Purpose E.</strong> The purpose of processing your
          personal data is to carry out communication between you and us and our
          records of this communication.
        </p>
        <p>
          <strong>4.3. Legal basis.</strong> The legal basis for the processing
          of your personal data is your consent given to us within the meaning
          of Article 6(1)(a) of the Regulation.
        </p>
        <p>
          <strong>4.4. Retention time.</strong> Your personal data will be
          stored until the end of the second calendar year following the year in
          which you gave us your consent to process your personal data.
        </p>
        <p>
          <strong>4.5. Other beneficiaries.</strong> Other recipients of your
          personal data may be persons providing technical and organizational
          activities related to the operation of the Website, in accordance with
          the instructions of the controller (personal data processors),
          companies from our group, and public authorities in cases where the
          controller is required to do so by generally binding legal
          regulations.
        </p>
        <p>
          <strong>4.6. Your rights.</strong> Under the terms of the Regulation,
          you have the right to request that we provide you with access to your
          personal data, the right to rectification or erasure of your personal
          data or restriction of its processing, and the right to the
          portability of your personal data:
        </p>
        <ul>
          <li>
            <strong>4.6.1.</strong> You have the right to withdraw your consent
            to the processing of your personal data that you have provided to us
            at any time. This does not affect the lawfulness of the processing
            of your personal data prior to such withdrawal of consent. You may
            withdraw your consent to the processing of your personal data by
            sending an e-mail message to our contact e-mail address (see Article
            1.2 of this Information).
          </li>
          <li>
            <strong>4.6.2.</strong> If you suspect that the processing of your
            personal data has infringed or is infringing the Regulation, you
            have the right, among other things, to lodge a complaint with a
            supervisory authority, in particular with a supervisory authority in
            the EU Member State where you are habitually resident, have your
            place of work or where the alleged infringement of the Regulation is
            located.
          </li>
          <li>
            <strong>4.6.3.</strong> You are under no obligation to provide
            personal data. The provision of your personal data is not a legal or
            contractual requirement, nor is it a requirement that is necessary
            to enter into a contract.
          </li>
        </ul>
        <h4 id="article5">
          PROCESSING RELATED TO THE PLACEMENT OF HTTP COOKIES ON YOUR DEVICE
        </h4>
        <p>
          <strong>5.1. Processing specifications.</strong> You can give us your
          consent to the processing of your personal data in connection with the
          storage of http cookies on your device by means of the cookie bar
          located on the Website ("the Cookie bar").{" "}
        </p>
        <p>
          <strong>5.2. Purpose of processing.</strong> The purposes for which
          personal data is processed vary depending on the nature of the cookies
          stored, and more detailed information will be provided to you within
          the Cookie bar.
        </p>
        <p>
          <strong>5.3. Legal basis.</strong> The legal basis for the processing
          of your personal data within the meaning of Article 6 of the
          Regulation is provided in Cookie bar.
        </p>
        <p>
          <strong>5.4. Retention time.</strong> The information on the retention
          period of personal data in relation to the storage of individual
          cookies varies depending on the nature of the cookies stored, and more
          detailed information will be provided to you within the Cookie bar or
          in another document published by us.
        </p>
        <p>
          <strong>5.5. Other beneficiaries.</strong> The categories of
          recipients of personal data vary depending on the nature of the
          cookies stored, and more detailed information will be provided to you
          within the Cookie bar or in another document published by us.
        </p>
        <p>
          <strong>5.6. Your rights.</strong> Under the terms of the Regulation,
          you have the right to request that we provide you with access to your
          personal data, the right to rectification or erasure of your personal
          data or restriction of its processing, and the right to the
          portability of your personal data:
        </p>
        <ul>
          <li>
            <strong>5.6.1.</strong> You have the right to withdraw your consent
            to the processing of your personal data that you have provided to us
            at any time. This does not affect the lawfulness of the processing
            of your personal data prior to such withdrawal of consent. You can
            withdraw your consent via the Cookie bar on the Website.
          </li>
          <li>
            <strong>5.6.2.</strong> If you suspect that the processing of your
            personal data has infringed or is infringing the Regulation, you
            have the right, among other things, to lodge a complaint with a
            supervisory authority, in particular with a supervisory authority in
            the EU Member State where you are habitually resident, have a place
            of work or where the alleged infringement of the Regulation is
            located.
          </li>
        </ul>
        <h4 id="article6">SECURITY OF YOUR INFORMATION</h4>
        <p>
          <strong>6.1.</strong> We use administrative, technical and factual
          security measures to protect your personal data in accordance with
          generally binding legal regulations.
        </p>
        <h4 id="article7">MORE INFORMATION</h4>
        <p>
          <strong>7.1. Automated decision making.</strong> We do not carry out
          automated individual decision-making within the meaning of Article 22
          of the Regulation.
        </p>
        <p>
          <strong>7.2. Third party activities.</strong> We are not responsible
          for the actions of third parties to whom you disclose personal or
          sensitive information, and we have no authority to direct or control
          the sending of third party offers. If you no longer wish to receive
          correspondence, emails or other communications from third parties,
          please contact such third parties directly.
        </p>
        <h4 id="article8">TRANSFER OF PERSONAL DATA OUTSIDE THE EU</h4>
        <p>
          <strong>8.1.</strong> We do not intend to purposefully transfer your
          personal data to a third country (non-EU country) or international
          organization, however, some of our partners, such as the other
          recipients of personal data mentioned above, may be based in non-EU
          countries or store data on servers outside the EU. In such cases,
          there may be a "transfer of your data to third countries (to a country
          outside the EU)" within the meaning of the Regulation, including to
          countries for which the European Commission has not yet decided on
          adequate protection identical to EU law. In such cases, we will ensure
          that your personal data is processed and protected both in accordance
          with our standard privacy policy, but also through the appropriate
          safeguards set out in Article 46 of the Regulation and available.
        </p>
        <p>
          <strong>8.2.</strong> We reserve the right to modify or update this
          Notice from time to time. We always post the current version of the
          Notice on our Platforms. We therefore encourage you to check this
          Notice periodically to stay informed about the terms and conditions of
          the processing of personal data and your rights.
        </p>
        <p>
          <strong>8.3.</strong> We encourage you to check this Notice regularly
          so that you are aware of your rights.
        </p>

        <div className="doc-footer">
          <p>In the United Kingdom on 09.01.2025</p>
          <p>Vincoholdinglp</p>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
