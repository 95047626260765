import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const PatternBarChart = ({ percent }) => {
  if (Object.keys(percent).length === 0) {
    return null;
  }

  const {
    powerball_odd_even,
    powerball_under_over,
    powerball_section,
    powerball_odd_even_under_over,
    sum_odd_even,
    sum_under_over,
    sum_size,
    sum_odd_even_under_over,
    sum_odd_even_size
  } = percent;

  return (
    <>
      <div className="data_summary">
        <h3 className="tit">
          파워볼 : <span>패턴 일치 다음회차 분석</span>
        </h3>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'ODD',
              parseInt(powerball_odd_even.odd_cnt) >
                parseInt(powerball_odd_even.even_cnt) && 'on'
            )}
          >
            <dt>홀</dt>
            <dd>
              <span className="num">{powerball_odd_even.odd_cnt}</span>
              <span className="per">({powerball_odd_even.odd_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              parseInt(powerball_odd_even.odd_cnt) <
                parseInt(powerball_odd_even.even_cnt) && 'on'
            )}
          >
            <dt>짝</dt>
            <dd>
              <span className="num">{powerball_odd_even.even_cnt}</span>
              <span className="per">({powerball_odd_even.even_ratio}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'UNDER',
              parseInt(powerball_under_over.over_cnt) >
                parseInt(powerball_under_over.under_cnt) && 'on'
            )}
          >
            <dt>언더</dt>
            <dd>
              <span className="num">{powerball_under_over.over_cnt}</span>
              <span className="per">({powerball_under_over.over_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'OVER',
              parseInt(powerball_under_over.over_cnt) <
                parseInt(powerball_under_over.under_cnt) && 'on'
            )}
          >
            <dt>오버</dt>
            <dd>
              <span className="num">{powerball_under_over.under_cnt}</span>
              <span className="per">({powerball_under_over.under_ratio}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart column4">
          <dl
            className={clsx(
              'bar',
              'A',
              MathUtil.onlyMaxNumber([
                powerball_section.a_cnt,
                powerball_section.b_cnt,
                powerball_section.c_cnt,
                powerball_section.d_cnt
              ]) === parseInt(powerball_section.a_cnt) && 'on'
            )}
          >
            <dt>구간 A</dt>
            <dd>
              <span className="num">{powerball_section.a_cnt}</span>
              <span className="per">({powerball_section.a_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'B',
              MathUtil.onlyMaxNumber([
                powerball_section.a_cnt,
                powerball_section.b_cnt,
                powerball_section.c_cnt,
                powerball_section.d_cnt
              ]) === parseInt(powerball_section.b_cnt) && 'on'
            )}
          >
            <dt>구간 B</dt>
            <dd>
              <span className="num">{powerball_section.b_cnt}</span>
              <span className="per">({powerball_section.b_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'C',
              MathUtil.onlyMaxNumber([
                powerball_section.a_cnt,
                powerball_section.b_cnt,
                powerball_section.c_cnt,
                powerball_section.d_cnt
              ]) === parseInt(powerball_section.c_cnt) && 'on'
            )}
          >
            <dt>구간 C</dt>
            <dd>
              <span className="num">{powerball_section.c_cnt}</span>
              <span className="per">({powerball_section.c_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'D',
              MathUtil.onlyMaxNumber([
                powerball_section.a_cnt,
                powerball_section.b_cnt,
                powerball_section.c_cnt,
                powerball_section.d_cnt
              ]) === parseInt(powerball_section.d_cnt) && 'on'
            )}
          >
            <dt>구간 D</dt>
            <dd>
              <span className="num">{powerball_section.d_cnt}</span>
              <span className="per">({powerball_section.d_ratio}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart column4">
          <dl
            className={clsx(
              'bar',
              'ODD',
              MathUtil.onlyMaxNumber([
                powerball_odd_even_under_over.odd_under_cnt,
                powerball_odd_even_under_over.odd_over_cnt,
                powerball_odd_even_under_over.even_under_cnt,
                powerball_odd_even_under_over.even_over_cnt
              ]) === parseInt(powerball_odd_even_under_over.odd_under_cnt) &&
                'on'
            )}
          >
            <dt>홀언</dt>
            <dd>
              <span className="num">
                {powerball_odd_even_under_over.odd_under_cnt}
              </span>
              <span className="per">
                ({powerball_odd_even_under_over.odd_under_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'ODD',
              MathUtil.onlyMaxNumber([
                powerball_odd_even_under_over.odd_under_cnt,
                powerball_odd_even_under_over.odd_over_cnt,
                powerball_odd_even_under_over.even_under_cnt,
                powerball_odd_even_under_over.even_over_cnt
              ]) === parseInt(powerball_odd_even_under_over.odd_over_cnt) &&
                'on'
            )}
          >
            <dt>홀오</dt>
            <dd>
              <span className="num">
                {powerball_odd_even_under_over.odd_over_cnt}
              </span>
              <span className="per">
                ({powerball_odd_even_under_over.odd_over_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              MathUtil.onlyMaxNumber([
                powerball_odd_even_under_over.odd_under_cnt,
                powerball_odd_even_under_over.odd_over_cnt,
                powerball_odd_even_under_over.even_under_cnt,
                powerball_odd_even_under_over.even_over_cnt
              ]) === parseInt(powerball_odd_even_under_over.even_under_cnt) &&
                'on'
            )}
          >
            <dt>짝언</dt>
            <dd>
              <span className="num">
                {powerball_odd_even_under_over.even_under_cnt}
              </span>
              <span className="per">
                ({powerball_odd_even_under_over.even_under_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              MathUtil.onlyMaxNumber([
                powerball_odd_even_under_over.odd_under_cnt,
                powerball_odd_even_under_over.odd_over_cnt,
                powerball_odd_even_under_over.even_under_cnt,
                powerball_odd_even_under_over.even_over_cnt
              ]) === parseInt(powerball_odd_even_under_over.even_over_cnt) &&
                'on'
            )}
          >
            <dt>짝오</dt>
            <dd>
              <span className="num">
                {powerball_odd_even_under_over.even_over_cnt}
              </span>
              <span className="per">
                ({powerball_odd_even_under_over.even_over_ratio}%)
              </span>
            </dd>
          </dl>
        </div>
      </div>

      <div className="data_summary">
        <h3 className="tit">
          일반볼합 : <span>패턴 일치 다음회차 분석</span>
        </h3>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'ODD',
              parseInt(sum_odd_even.odd_cnt) >
                parseInt(sum_odd_even.even_cnt) && 'on'
            )}
          >
            <dt>홀</dt>
            <dd>
              <span className="num">{sum_odd_even.odd_cnt}</span>
              <span className="per">({sum_odd_even.odd_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              parseInt(sum_odd_even.odd_cnt) <
                parseInt(sum_odd_even.even_cnt) && 'on'
            )}
          >
            <dt>짝</dt>
            <dd>
              <span className="num">{sum_odd_even.even_cnt}</span>
              <span className="per">({sum_odd_even.even_ratio}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'UNDER',
              parseInt(sum_under_over.under_cnt) >
                parseInt(sum_under_over.over_cnt) && 'on'
            )}
          >
            <dt>언더</dt>
            <dd>
              <span className="num">{sum_under_over.under_cnt}</span>
              <span className="per">({sum_under_over.under_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'OVER',
              parseInt(sum_under_over.under_cnt) <
                parseInt(sum_under_over.over_cnt) && 'on'
            )}
          >
            <dt>오버</dt>
            <dd>
              <span className="num">{sum_under_over.over_cnt}</span>
              <span className="per">({sum_under_over.over_ratio}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'BIG',
              MathUtil.onlyMaxNumber([
                sum_size.l_cnt,
                sum_size.m_cnt,
                sum_size.s_cnt
              ]) === parseInt(sum_size.l_cnt) && 'on'
            )}
          >
            <dt>대</dt>
            <dd>
              <span className="num">{sum_size.l_cnt}</span>
              <span className="per">({sum_size.l_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'MIDDLE',
              MathUtil.onlyMaxNumber([
                sum_size.l_cnt,
                sum_size.m_cnt,
                sum_size.s_cnt
              ]) === parseInt(sum_size.m_cnt) && 'on'
            )}
          >
            <dt>중</dt>
            <dd>
              <span className="num">{sum_size.m_cnt}</span>
              <span className="per">({sum_size.m_ratio}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'SMALL',
              MathUtil.onlyMaxNumber([
                sum_size.l_cnt,
                sum_size.m_cnt,
                sum_size.s_cnt
              ]) === parseInt(sum_size.s_cnt) && 'on'
            )}
          >
            <dt>소</dt>
            <dd>
              <span className="num">{sum_size.s_cnt}</span>
              <span className="per">({sum_size.s_ratio}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart column4">
          <dl
            className={clsx(
              'bar',
              'ODD',
              MathUtil.onlyMaxNumber([
                sum_odd_even_under_over.odd_under_cnt,
                sum_odd_even_under_over.odd_over_cnt,
                sum_odd_even_under_over.even_under_cnt,
                sum_odd_even_under_over.even_over_cnt
              ]) === parseInt(sum_odd_even_under_over.odd_under_cnt) && 'on'
            )}
          >
            <dt>홀언</dt>
            <dd>
              <span className="num">
                {sum_odd_even_under_over.odd_under_cnt}
              </span>
              <span className="per">
                ({sum_odd_even_under_over.odd_under_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'ODD',
              MathUtil.onlyMaxNumber([
                sum_odd_even_under_over.odd_under_cnt,
                sum_odd_even_under_over.odd_over_cnt,
                sum_odd_even_under_over.even_under_cnt,
                sum_odd_even_under_over.even_over_cnt
              ]) === parseInt(sum_odd_even_under_over.odd_over_cnt) && 'on'
            )}
          >
            <dt>홀오</dt>
            <dd>
              <span className="num">
                {sum_odd_even_under_over.odd_over_cnt}
              </span>
              <span className="per">
                ({sum_odd_even_under_over.odd_over_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              MathUtil.onlyMaxNumber([
                sum_odd_even_under_over.odd_under_cnt,
                sum_odd_even_under_over.odd_over_cnt,
                sum_odd_even_under_over.even_under_cnt,
                sum_odd_even_under_over.even_over_cnt
              ]) === parseInt(sum_odd_even_under_over.even_under_cnt) && 'on'
            )}
          >
            <dt>짝언</dt>
            <dd>
              <span className="num">
                {sum_odd_even_under_over.even_under_cnt}
              </span>
              <span className="per">
                ({sum_odd_even_under_over.even_under_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              MathUtil.onlyMaxNumber([
                sum_odd_even_under_over.odd_under_cnt,
                sum_odd_even_under_over.odd_over_cnt,
                sum_odd_even_under_over.even_under_cnt,
                sum_odd_even_under_over.even_over_cnt
              ]) === parseInt(sum_odd_even_under_over.even_over_cnt) && 'on'
            )}
          >
            <dt>짝오</dt>
            <dd>
              <span className="num">
                {sum_odd_even_under_over.even_over_cnt}
              </span>
              <span className="per">
                ({sum_odd_even_under_over.even_over_ratio}%)
              </span>
            </dd>
          </dl>
        </div>
        <div className="chart column6">
          <dl
            className={clsx(
              'bar',
              'ODDBIG',
              MathUtil.onlyMaxNumber([
                sum_odd_even_size.odd_large_cnt,
                sum_odd_even_size.odd_medium_cnt,
                sum_odd_even_size.odd_small_cnt,
                sum_odd_even_size.even_large_cnt,
                sum_odd_even_size.even_medium_cnt,
                sum_odd_even_size.even_small_cnt
              ]) === parseInt(sum_odd_even_size.odd_large_cnt) && 'on'
            )}
          >
            <dt>홀대</dt>
            <dd>
              <span className="num">{sum_odd_even_size.odd_large_cnt}</span>
              <span className="per">
                ({sum_odd_even_size.odd_large_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'ODDMIDDLE',
              MathUtil.onlyMaxNumber([
                sum_odd_even_size.odd_large_cnt,
                sum_odd_even_size.odd_medium_cnt,
                sum_odd_even_size.odd_small_cnt,
                sum_odd_even_size.even_large_cnt,
                sum_odd_even_size.even_medium_cnt,
                sum_odd_even_size.even_small_cnt
              ]) === parseInt(sum_odd_even_size.odd_medium_cnt) && 'on'
            )}
          >
            <dt>홀중</dt>
            <dd>
              <span className="num">{sum_odd_even_size.odd_medium_cnt}</span>
              <span className="per">
                ({sum_odd_even_size.odd_medium_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'ODDSMALL',
              MathUtil.onlyMaxNumber([
                sum_odd_even_size.odd_large_cnt,
                sum_odd_even_size.odd_medium_cnt,
                sum_odd_even_size.odd_small_cnt,
                sum_odd_even_size.even_large_cnt,
                sum_odd_even_size.even_medium_cnt,
                sum_odd_even_size.even_small_cnt
              ]) === parseInt(sum_odd_even_size.odd_small_cnt) && 'on'
            )}
          >
            <dt>홀소</dt>
            <dd>
              <span className="num">{sum_odd_even_size.odd_small_cnt}</span>
              <span className="per">
                ({sum_odd_even_size.odd_small_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVENBIG',
              MathUtil.onlyMaxNumber([
                sum_odd_even_size.odd_large_cnt,
                sum_odd_even_size.odd_medium_cnt,
                sum_odd_even_size.odd_small_cnt,
                sum_odd_even_size.even_large_cnt,
                sum_odd_even_size.even_medium_cnt,
                sum_odd_even_size.even_small_cnt
              ]) === parseInt(sum_odd_even_size.even_large_cnt) && 'on'
            )}
          >
            <dt>짝대</dt>
            <dd>
              <span className="num">{sum_odd_even_size.even_large_cnt}</span>
              <span className="per">
                ({sum_odd_even_size.even_large_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVENMIDDLE',
              MathUtil.onlyMaxNumber([
                sum_odd_even_size.odd_large_cnt,
                sum_odd_even_size.odd_medium_cnt,
                sum_odd_even_size.odd_small_cnt,
                sum_odd_even_size.even_large_cnt,
                sum_odd_even_size.even_medium_cnt,
                sum_odd_even_size.even_small_cnt
              ]) === parseInt(sum_odd_even_size.even_medium_cnt) && 'on'
            )}
          >
            <dt>짝중</dt>
            <dd>
              <span className="num">{sum_odd_even_size.even_medium_cnt}</span>
              <span className="per">
                ({sum_odd_even_size.even_medium_ratio}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVENSMALL',
              MathUtil.onlyMaxNumber([
                sum_odd_even_size.odd_large_cnt,
                sum_odd_even_size.odd_medium_cnt,
                sum_odd_even_size.odd_small_cnt,
                sum_odd_even_size.even_large_cnt,
                sum_odd_even_size.even_medium_cnt,
                sum_odd_even_size.even_small_cnt
              ]) === parseInt(sum_odd_even_size.even_small_cnt) && 'on'
            )}
          >
            <dt>짝소</dt>
            <dd>
              <span className="num">{sum_odd_even_size.even_small_cnt}</span>
              <span className="per">
                ({sum_odd_even_size.even_small_ratio}%)
              </span>
            </dd>
          </dl>
        </div>
      </div>
    </>
  );
};

export default PatternBarChart;
