import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const Space8BarChart = ({ count = {} }) => {
    return (
        <>
            <div className="data_summary distance">
                <h3 className="tit new_tit">볼 갯수 승무패</h3>
                <div className="chart">
                    <dl
                        className={clsx(
                            'bar new_bar',
                            'HOME',
                            parseInt(count.home_count_win_count) > parseInt(count.away_count_win_count)
                            && parseInt(count.home_count_win_count) > parseInt(count.draw_count_win_count)
                            && 'on'
                        )}
                    >
                        <dt>좌</dt>
                        <dd>
                            <span className="num">{count.home_count_win_count}</span>
                            <span className="per">({count.home_count_win_percent}%)</span>
                        </dd>
                    </dl>
                    <dl
                        className={clsx(
                            'bar new_bar',
                            'DRAW',
                            parseInt(count.draw_count_win_count) > parseInt(count.home_count_win_count)
                            && parseInt(count.draw_count_win_count) > parseInt(count.away_count_win_count)
                            && 'on'
                        )}
                    >
                        <dt>무</dt>
                        <dd>
                            <span className="num">{count.draw_count_win_count}</span>
                            <span className="per">({count.draw_count_win_percent}%)</span>
                        </dd>
                    </dl>
                    <dl
                        className={clsx(
                            'bar new_bar',
                            'AWAY',
                            parseInt(count.away_count_win_count) > parseInt(count.home_count_win_count)
                            && parseInt(count.away_count_win_count) > parseInt(count.draw_count_win_count)
                            && 'on'
                        )}
                    >
                        <dt>우</dt>
                        <dd>
                            <span className="num">{count.away_count_win_count}</span>
                            <span className="per">({count.away_count_win_percent}%)</span>
                        </dd>
                    </dl>
                </div>
            </div>
            {count.total_sum_win_count && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">볼 번호 합산 승무패</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'HOME',
                                parseInt(count.home_sum_win_count) > parseInt(count.away_sum_win_count)
                                && parseInt(count.home_sum_win_count) > parseInt(count.draw_sum_win_count)
                                && 'on'
                            )}
                        >
                            <dt>좌</dt>
                            <dd>
                                <span className="num">{count.home_sum_win_count}</span>
                                <span className="per">({count.home_sum_win_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(count.draw_sum_win_count) > parseInt(count.home_sum_win_count)
                                && parseInt(count.draw_sum_win_count) > parseInt(count.away_sum_win_count)
                                && 'on'
                            )}
                        >
                            <dt>무</dt>
                            <dd>
                                <span className="num">{count.draw_sum_win_count}</span>
                                <span className="per">({count.draw_sum_win_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'AWAY',
                                parseInt(count.away_sum_win_count) > parseInt(count.home_sum_win_count)
                                && parseInt(count.away_sum_win_count) > parseInt(count.draw_sum_win_count)
                                && 'on'
                            )}
                        >
                            <dt>우</dt>
                            <dd>
                                <span className="num">{count.away_sum_win_count}</span>
                                <span className="per">({count.away_sum_win_percent}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {count.total_sum_home_ball_odd_even_count && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">좌측 번호 합 홀짝</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'ODD',
                                parseInt(count.sum_home_ball_odd_count) > parseInt(count.sum_home_ball_even_count)
                                && parseInt(count.sum_home_ball_odd_count) > parseInt(count.sum_home_ball_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>홀</dt>
                            <dd>
                                <span className="num">{count.sum_home_ball_odd_count}</span>
                                <span className="per">({count.sum_home_ball_odd_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(count.sum_home_ball_zero_count) > parseInt(count.sum_home_ball_odd_count)
                                && parseInt(count.sum_home_ball_zero_count) > parseInt(count.sum_home_ball_even_count)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{count.sum_home_ball_zero_count}</span>
                                <span className="per">({count.sum_home_ball_zero_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'EVEN',
                                parseInt(count.sum_home_ball_even_count) > parseInt(count.sum_home_ball_odd_count)
                                && parseInt(count.sum_home_ball_even_count) > parseInt(count.sum_home_ball_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>짝</dt>
                            <dd>
                                <span className="num">{count.sum_home_ball_even_count}</span>
                                <span className="per">({count.sum_home_ball_even_percent}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {count.total_sum_away_ball_odd_even_count && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">우측 번호 합 홀짝</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'ODD',
                                parseInt(count.sum_away_ball_odd_count) > parseInt(count.sum_away_ball_even_count)
                                && parseInt(count.sum_away_ball_odd_count) > parseInt(count.sum_away_ball_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>홀</dt>
                            <dd>
                                <span className="num">{count.sum_away_ball_odd_count}</span>
                                <span className="per">({count.sum_away_ball_odd_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(count.sum_away_ball_zero_count) > parseInt(count.sum_away_ball_odd_count)
                                && parseInt(count.sum_away_ball_zero_count) > parseInt(count.sum_away_ball_even_count)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{count.sum_away_ball_zero_count}</span>
                                <span className="per">({count.sum_away_ball_zero_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'EVEN',
                                parseInt(count.sum_away_ball_even_count) > parseInt(count.sum_away_ball_odd_count)
                                && parseInt(count.sum_away_ball_even_count) > parseInt(count.sum_away_ball_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>짝</dt>
                            <dd>
                                <span className="num">{count.sum_away_ball_even_count}</span>
                                <span className="per">({count.sum_away_ball_even_percent}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {count.total_home_ball1_odd_even_count && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">좌측 1등볼 홀짝</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'ODD',
                                parseInt(count.home_ball1_odd_count) > parseInt(count.home_ball1_even_count)
                                && parseInt(count.home_ball1_odd_count) > parseInt(count.home_ball1_oe_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>홀</dt>
                            <dd>
                                <span className="num">{count.home_ball1_odd_count}</span>
                                <span className="per">({count.home_ball1_odd_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(count.home_ball1_oe_zero_count) > parseInt(count.home_ball1_odd_count)
                                && parseInt(count.home_ball1_oe_zero_count) > parseInt(count.home_ball1_oe_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{count.home_ball1_oe_zero_count}</span>
                                <span className="per">({count.home_ball1_oe_zero_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'EVEN',
                                parseInt(count.home_ball1_even_count) > parseInt(count.home_ball1_odd_count)
                                && parseInt(count.home_ball1_even_count) > parseInt(count.home_ball1_oe_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>짝</dt>
                            <dd>
                                <span className="num">{count.home_ball1_even_count}</span>
                                <span className="per">({count.home_ball1_even_percent}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {count.total_away_ball1_odd_even_count && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">우측 1등볼 홀짝</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'ODD',
                                parseInt(count.away_ball1_odd_count) > parseInt(count.away_ball1_even_count)
                                && parseInt(count.away_ball1_odd_count) > parseInt(count.away_ball1_oe_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>홀</dt>
                            <dd>
                                <span className="num">{count.away_ball1_odd_count}</span>
                                <span className="per">({count.away_ball1_odd_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(count.away_ball1_oe_zero_count) > parseInt(count.away_ball1_odd_count)
                                && parseInt(count.away_ball1_oe_zero_count) > parseInt(count.away_ball1_even_count)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{count.away_ball1_oe_zero_count}</span>
                                <span className="per">({count.away_ball1_oe_zero_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'EVEN',
                                parseInt(count.away_ball1_even_count) > parseInt(count.away_ball1_odd_count)
                                && parseInt(count.away_ball1_even_count) > parseInt(count.away_ball1_oe_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>짝</dt>
                            <dd>
                                <span className="num">{count.away_ball1_even_count}</span>
                                <span className="per">({count.away_ball1_even_percent}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {count.total_home_ball1_under_over_count && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">좌측 1등볼 언오버</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'UNDER',
                                parseInt(count.home_ball1_under_count) > parseInt(count.home_ball1_over_count)
                                && parseInt(count.home_ball1_under_count) > parseInt(count.home_ball1_uo_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>언</dt>
                            <dd>
                                <span className="num">{count.home_ball1_under_count}</span>
                                <span className="per">({count.home_ball1_under_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(count.home_ball1_uo_zero_count) > parseInt(count.home_ball1_under_count)
                                && parseInt(count.home_ball1_uo_zero_count) > parseInt(count.home_ball1_over_count)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{count.home_ball1_uo_zero_count}</span>
                                <span className="per">({count.home_ball1_uo_zero_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'OVER',
                                parseInt(count.home_ball1_over_count) > parseInt(count.home_ball1_under_count)
                                && parseInt(count.home_ball1_over_count) > parseInt(count.home_ball1_uo_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>오</dt>
                            <dd>
                                <span className="num">{count.home_ball1_over_count}</span>
                                <span className="per">({count.home_ball1_over_percent}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
            {count.total_away_ball1_under_over_count && //3분 런닝볼은 총 볼 갯수가 2개이므로 밑의 통계 로직 필요없음
                <div className="data_summary second distance">
                    <h3 className="tit new_tit">우측 1등볼 언오버</h3>
                    <div className="chart">
                        <dl
                            className={clsx(
                                'bar new_bar testab',
                                'UNDER',
                                parseInt(count.away_ball1_under_count) > parseInt(count.away_ball1_over_count)
                                && parseInt(count.away_ball1_under_count) > parseInt(count.away_ball1_uo_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>언</dt>
                            <dd>
                                <span className="num">{count.away_ball1_under_count}</span>
                                <span className="per">({count.away_ball1_under_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'DRAW',
                                parseInt(count.away_ball1_uo_zero_count) > parseInt(count.away_ball1_under_count)
                                && parseInt(count.away_ball1_uo_zero_count) > parseInt(count.away_ball1_over_count)
                                && 'on'
                            )}
                        >
                            <dt>0</dt>
                            <dd>
                                <span className="num">{count.away_ball1_uo_zero_count}</span>
                                <span className="per">({count.away_ball1_uo_zero_percent}%)</span>
                            </dd>
                        </dl>
                        <dl
                            className={clsx(
                                'bar new_bar',
                                'OVER',
                                parseInt(count.away_ball1_over_count) > parseInt(count.away_ball1_under_count)
                                && parseInt(count.away_ball1_over_count) > parseInt(count.away_ball1_uo_zero_count)
                                && 'on'
                            )}
                        >
                            <dt>오</dt>
                            <dd>
                                <span className="num">{count.away_ball1_over_count}</span>
                                <span className="per">({count.away_ball1_over_percent}%)</span>
                            </dd>
                        </dl>
                    </div>
                </div>
            }
        </>
    );
};

export default Space8BarChart;
