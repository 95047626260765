import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import useGameStore from "../../../../../hooks/useGameStore";
import { observer } from "mobx-react";
import { PickComment } from "../../pickComment";
import useStore from "../../../../../hooks/useStore";
import { timeUtils } from "../../../../../common/utils/timeUtil";
import useModalStore from "../../../../../hooks/useModalStore";

let todayDate = timeUtils.dateFormatYmd(timeUtils.todayYmd());

const DhPowerballPick = observer(({ brandName, gameName }) => {
  const [tab, setTab] = useState("dist");
  const [option, setOption] = useState("sum_odd_even");
  const gameStore = useGameStore();
  const { clockStore } = useStore();
  const [currentRound, setCurrentRound] = useState(0);
  const [pickClass, setPickClass] = useState("powerball_odd");
  const [pickComment, setPickComment] = useState();
  const modalStore = useModalStore();
  const [pick, setPick] = useState("odd");
  const [pickType, setPickType] = useState("powerball_odd_even");
  const [commentIndex, setCommentIndex] = useState("0");

  const getGameTypeParameter = () => {
    return "dh_powerball";
  };

  useEffect(() => {
    let interval = null;
    if (currentRound !== 0) {
      interval = setInterval(() => {
        gameStore.getLotteryPickList({
          gameType: getGameTypeParameter(),
          pickType: option === "all" ? null : option,
          limit: 10,
          offset: 0,
          date: timeUtils.dateFormatYmd(todayDate),
          round: currentRound
        });
      }, 1500);

      gameStore.getLotteryPickCommentList({
        gameType: getGameTypeParameter(),
        pickType: option === "all" ? null : option,
        limit: 10,
        offset: 0,
        concat: false,
        date: timeUtils.dateFormatYmd(todayDate),
        round: currentRound
      });
    }
    return () => {
      clearInterval(interval);
    };
  }, [tab, option, currentRound]);

  useEffect(() => {
    clockStore.getPowerRound().then((res) => {
      setCurrentRound(res.nextRound);
    });
  }, []);

  const getRoundDate = () => {
    return timeUtils.dateFormatCalendarYmd(todayDate) + " - " + currentRound;
  };

  const onClickPreRound = () => {
    if (currentRound === 1) {
      todayDate = timeUtils.dateFormatCalendarYmd(
        timeUtils.datePrevOrNext(todayDate, "prev")
      );
      setCurrentRound(288);
    } else {
      setCurrentRound(currentRound - 1);
    }
  };

  const onClickNextRound = () => {
    clockStore.getPowerRound().then((res) => {
      if (
        todayDate === timeUtils.dateFormatCalendarYmd(timeUtils.todayYmd()) &&
        currentRound === res.nextRound
      ) {
        return;
      } else {
        if (currentRound === 288) {
          todayDate = timeUtils.dateFormatCalendarYmd(
            timeUtils.datePrevOrNext(todayDate, "next")
          );
          setCurrentRound(1);
        } else {
          setCurrentRound(currentRound + 1);
        }
      }
    });
  };

  const powerballMaxSection = (section) => {
    let num = [
      gameStore.data.dist.powerball_a_section_percent,
      gameStore.data.dist.powerball_b_section_percent,
      gameStore.data.dist.powerball_c_section_percent,
      gameStore.data.dist.powerball_d_section_percent
    ];

    let maxSection = Math.max.apply(null, num);

    if (maxSection === 0) {
      return section;
    }

    let a = {
      A: gameStore.data.dist.powerball_a_section_percent,
      B: gameStore.data.dist.powerball_b_section_percent,
      C: gameStore.data.dist.powerball_c_section_percent,
      D: gameStore.data.dist.powerball_d_section_percent
    };

    if (a[section] === maxSection) {
      section = section + " on";
    }

    return section;
  };

  const powerballMaxOddOver = (section) => {
    let num = [
      gameStore.data.dist.powerball_odd_over_percent,
      gameStore.data.dist.powerball_odd_under_percent,
      gameStore.data.dist.powerball_even_over_percent,
      gameStore.data.dist.powerball_even_under_percent
    ];

    let maxSection = Math.max.apply(null, num);

    if (maxSection === 0) {
      return section;
    }

    let a = {
      "ODD OVER": gameStore.data.dist.powerball_odd_over_percent,
      "ODD UNDER": gameStore.data.dist.powerball_odd_under_percent,
      "EVEN OVER": gameStore.data.dist.powerball_even_over_percent,
      "EVEN UNDER": gameStore.data.dist.powerball_even_under_percent
    };

    if (a[section] === maxSection) {
      section = section + " on";
    }

    return section;
  };

  const powerballMaxSize = (section) => {
    let num = [
      gameStore.data.dist.sum_large_percent,
      gameStore.data.dist.sum_medium_percent,
      gameStore.data.dist.sum_small_percent
    ];

    let maxSection = Math.max.apply(null, num);

    if (maxSection === 0) {
      return section;
    }

    let a = {
      BIG: gameStore.data.dist.sum_large_percent,
      MEDIUM: gameStore.data.dist.sum_medium_percent,
      SMALL: gameStore.data.dist.powerball_even_over_percent
    };

    if (a[section] === maxSection) {
      section = section + " on";
    }

    return section;
  };

  const powerballMaxSumOver = (section) => {
    let num = [
      gameStore.data.dist.sum_odd_under_percent,
      gameStore.data.dist.sum_odd_over_percent,
      gameStore.data.dist.sum_even_under_percent,
      gameStore.data.dist.sum_even_over_percent
    ];

    let maxSection = Math.max.apply(null, num);

    if (maxSection === 0) {
      return section;
    }

    let a = {
      "ODD OVER": gameStore.data.dist.sum_odd_over_percent,
      "ODD UNDER": gameStore.data.dist.sum_odd_under_percent,
      "EVEN OVER": gameStore.data.dist.sum_even_over_percent,
      "EVEN UNDER": gameStore.data.dist.sum_even_under_percent
    };

    if (a[section] === maxSection) {
      section = section + " on";
    }

    return section;
  };

  const powerballMaxSumOddSize = (section) => {
    let num = [
      gameStore.data.dist.sum_odd_large_percent,
      gameStore.data.dist.sum_odd_medium_percent,
      gameStore.data.dist.sum_odd_small_percent,
      gameStore.data.dist.sum_even_large_percent,
      gameStore.data.dist.sum_even_medium_percent,
      gameStore.data.dist.sum_even_small_percent
    ];

    let maxSection = Math.max.apply(null, num);

    if (maxSection === 0) {
      return section;
    }

    let a = {
      ODDBIG: gameStore.data.dist.sum_odd_large_percent,
      ODDMIDDLE: gameStore.data.dist.sum_odd_medium_percent,
      ODDSMALL: gameStore.data.dist.sum_odd_small_percent,
      EVENBIG: gameStore.data.dist.sum_even_large_percent,
      EVENMIDDLE: gameStore.data.dist.sum_even_medium_percent,
      EVENSMALL: gameStore.data.dist.sum_even_small_percent
    };

    if (a[section] === maxSection) {
      section = section + " on";
    }

    return section;
  };

  const powerballSectionDisplay = (index) => {
    if (index !== option && option !== "all") {
      return "none";
    }
  };

  const onSetPickComment = (e) => {
    setCommentIndex(e.value);
    setPickComment(e[e.selectedIndex].text);
    e.selectedIndex === 0 && setPickComment();
  };

  const insertPickComment = () => {
    if (pickComment === undefined) {
      modalStore.openAlertModal({
        message: "픽 메세지를 선택해 주세요"
      });
      return;
    }

    gameStore.insertLotteryPickComment({
      gameType: gameName,
      pickType: pickType,
      pick: pick,
      pickComment: pickComment
    });
  };

  const onSetPick = (pickClass, pickType, pick) => {
    setPickClass(pickClass);
    setPick(pick);
    setPickType(pickType);
  };

  return (
    <div className="game_stats_area">
      {/* option_area */}
      <div className="option_tab">
        <ul>
          <li>
            <a
              onClick={() => setTab("dist")}
              className={clsx("item", tab === "dist" && "selected")}
            >
              픽분포도
            </a>
          </li>
          <li>
            <a
              onClick={() => setTab("reg")}
              className={clsx("item", tab === "reg" && "selected")}
            >
              픽등록
            </a>
          </li>
        </ul>
      </div>
      {tab === "dist" && (
        <>
          <div className="option_area">
            <div className="date_area">
              <Link
                className="btn_prev"
                onClick={() => onClickPreRound()}
                to={`${location.pathname}?tab=pick`}
              >
                이전 날짜
              </Link>
              <strong className="date">{getRoundDate()}</strong>
              <Link
                className="btn_next"
                onClick={() => onClickNextRound()}
                to={`${location.pathname}?tab=pick`}
              >
                다음 날짜
              </Link>
            </div>
            <div className="option_type">
              <select
                value={option}
                onChange={(e) => setOption(e.target.value)}
              >
                <option value="sum_odd_even">일반볼합 홀짝</option>
                <option value="powerball_under_over">파워볼 언오버</option>
                <option value="sum_size">일반볼합 대중소</option>
              </select>
            </div>
          </div>
          {/* // option_area */}

          {/* date_detail_wrap */}
          <div className="date_detail_wrap">
            {/* data_summary */}
            <div className="data_summary">
              <h3 className="tit">전체 픽 분포도</h3>
              <div
                className="chart"
                style={{
                  display: powerballSectionDisplay("powerball_odd_even")
                }}
              >
                <dl
                  className={clsx(
                    "bar ODD",
                    gameStore.data.dist.powerball_odd_percent > "50" && "on"
                  )}
                >
                  <dt>파워 홀</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_odd_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_odd_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    "bar EVEN",
                    gameStore.data.dist.powerball_even_percent > "50" && "on"
                  )}
                >
                  <dt>파워 짝</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_even_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_even_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart"
                style={{
                  display: powerballSectionDisplay("powerball_under_over")
                }}
              >
                <dl
                  className={clsx(
                    "bar UNDER",
                    gameStore.data.dist.powerball_under_percent > "50" && "on"
                  )}
                >
                  <dt>파워 언더</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_under_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_under_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    "bar OVER",
                    gameStore.data.dist.powerball_over_percent > "50" && "on"
                  )}
                >
                  <dt>파워 오버</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_over_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_over_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart column4"
                style={{
                  display: powerballSectionDisplay("powerball_section")
                }}
              >
                <dl className={clsx("bar ", powerballMaxSection("A"))}>
                  <dt>파워 구간A</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_a_section_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_a_section_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxSection("B"))}>
                  <dt>파워 구간B</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_b_section_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_b_section_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxSection("C"))}>
                  <dt>파워 구간C</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_c_section_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_c_section_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxSection("D"))}>
                  <dt>파워 구간D</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_d_section_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_d_section_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart column4 division"
                style={{
                  display: powerballSectionDisplay(
                    "powerball_odd_even_under_over"
                  )
                }}
              >
                <dl className={clsx("bar ", powerballMaxOddOver("ODD UNDER"))}>
                  <dt>파워 홀언</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_odd_under_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_odd_under_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxOddOver("ODD OVER"))}>
                  <dt>파워 홀오</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_odd_over_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_odd_over_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxOddOver("EVEN UNDER"))}>
                  <dt>파워 짝언</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_even_under_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_even_under_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxOddOver("EVEN OVER"))}>
                  <dt>파워 짝오</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.powerball_even_over_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.powerball_even_over_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart"
                style={{ display: powerballSectionDisplay("sum_odd_even") }}
              >
                <dl
                  className={clsx(
                    "bar ODD",
                    gameStore.data.dist.sum_odd_percent > "50" && "on"
                  )}
                >
                  <dt>일반 홀</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_odd_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.sum_odd_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    "bar EVEN",
                    gameStore.data.dist.sum_even_percent > "50" && "on"
                  )}
                >
                  <dt>일반 짝</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_even_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.sum_even_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart"
                style={{ display: powerballSectionDisplay("sum_under_over") }}
              >
                <dl
                  className={clsx(
                    "bar UNDER",
                    gameStore.data.dist.sum_under_percent > "50" && "on"
                  )}
                >
                  <dt>일반 언더</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_under_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.sum_under_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx(
                    "bar OVER",
                    gameStore.data.dist.sum_over_percent > "50" && "on"
                  )}
                >
                  <dt>일반 오버</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_over_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.sum_over_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart"
                style={{ display: powerballSectionDisplay("sum_size") }}
              >
                <dl className={clsx("bar ", powerballMaxSize("BIG"))}>
                  <dt>일반 대</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_large_count}
                    </span>
                    <span className="per">
                      ({Math.floor(gameStore.data.dist.sum_large_percent)}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxSize("MEDIUM"))}>
                  <dt>일반 중</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_medium_count}
                    </span>
                    <span className="per">
                      ({Math.floor(gameStore.data.dist.sum_medium_percent)}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxSize("SMALL"))}>
                  <dt>일반 소</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_small_count}
                    </span>
                    <span className="per">
                      ({Math.floor(gameStore.data.dist.sum_small_percent)}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart column4"
                style={{
                  display: powerballSectionDisplay("sum_odd_even_under_over")
                }}
              >
                <dl className={clsx("bar ", powerballMaxSumOver("ODD UNDER"))}>
                  <dt>일반 홀언</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_odd_under_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.sum_odd_under_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxSumOver("ODD OVER"))}>
                  <dt>일반 홀오</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_odd_over_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.sum_odd_over_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxSumOver("EVEN UNDER"))}>
                  <dt>일반 짝언</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_even_under_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.sum_even_under_percent}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxSumOver("EVEN OVER"))}>
                  <dt>일반 짝오</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_even_over_count}
                    </span>
                    <span className="per">
                      ({gameStore.data.dist.sum_even_over_percent}%)
                    </span>
                  </dd>
                </dl>
              </div>
              <div
                className="chart column6"
                style={{
                  display: powerballSectionDisplay("sum_odd_even_size")
                }}
              >
                <dl className={clsx("bar ", powerballMaxSumOddSize("ODDBIG"))}>
                  <dt>홀+대</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_odd_large_count}
                    </span>
                    <span className="per">
                      ({Math.floor(gameStore.data.dist.sum_odd_large_percent)}%)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx("bar ", powerballMaxSumOddSize("ODDMIDDLE"))}
                >
                  <dt>홀+중</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_odd_medium_count}
                    </span>
                    <span className="per">
                      ({Math.floor(gameStore.data.dist.sum_odd_medium_percent)}
                      %)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx("bar ", powerballMaxSumOddSize("ODDSMALL"))}
                >
                  <dt>홀+소</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_odd_small_count}
                    </span>
                    <span className="per">
                      ({Math.floor(gameStore.data.dist.sum_odd_small_percent)}%)
                    </span>
                  </dd>
                </dl>
                <dl className={clsx("bar ", powerballMaxSumOddSize("EVENBIG"))}>
                  <dt>짝+대</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_even_large_count}
                    </span>
                    <span className="per">
                      ({Math.floor(gameStore.data.dist.sum_even_large_percent)}
                      %)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx("bar ", powerballMaxSumOddSize("EVENMIDDLE"))}
                >
                  <dt>짝+중</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_even_medium_count}
                    </span>
                    <span className="per">
                      ({Math.floor(gameStore.data.dist.sum_even_medium_percent)}
                      %)
                    </span>
                  </dd>
                </dl>
                <dl
                  className={clsx("bar ", powerballMaxSumOddSize("EVENSMALL"))}
                >
                  <dt>짝+소</dt>
                  <dd>
                    <span className="num">
                      {gameStore.data.dist.sum_even_small_count}
                    </span>
                    <span className="per">
                      ({Math.floor(gameStore.data.dist.sum_even_small_percent)}
                      %)
                    </span>
                  </dd>
                </dl>
              </div>
            </div>
            {/* // data_summary */}

            {/* pick_list_wrap */}
            <div className="pick_list_wrap">
              <h3 className="tit">유저의 픽</h3>
              <div className="pick_list">
                <PickComment
                  gameType={"powerball"}
                  pickType={option}
                  items={gameStore.data.comment.list}
                  totalCnt={gameStore.data.comment.total_cnt}
                  date={timeUtils.dateFormatYmd(todayDate)}
                  round={currentRound}
                />
              </div>
            </div>
            {/* // pick_list_wrap */}
          </div>
          {/* // date_detail_wrap */}
        </>
      )}
      {tab === "reg" && (
        <>
          {/* pick_register_wrap */}
          <div className="pick_register_wrap">
            {/* pick_select */}
            <div className="pick_select">
              <h3 className="tit">픽 선택</h3>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    "UNDER",
                    pickClass === "powerball_under" && "selected"
                  )}
                  onClick={() =>
                    onSetPick(
                      "powerball_under",
                      "powerball_under_over",
                      "under"
                    )
                  }
                >
                  <span className="tx">파워 언더</span>
                  <span className="per">
                    {gameStore.data.dist.powerball_under_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    "OVER",
                    pickClass === "powerball_over" && "selected"
                  )}
                  onClick={() =>
                    onSetPick("powerball_over", "powerball_under_over", "over")
                  }
                >
                  <span className="tx">파워 오버</span>
                  <span className="per">
                    {gameStore.data.dist.powerball_over_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx("ODD", pickClass === "sum_odd" && "selected")}
                  onClick={() => onSetPick("sum_odd", "sum_odd_even", "odd")}
                >
                  <span className="tx">일반 홀</span>
                  <span className="per">
                    {gameStore.data.dist.sum_odd_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    "EVEN",
                    pickClass === "sum_even" && "selected"
                  )}
                  onClick={() => onSetPick("sum_even", "sum_odd_even", "even")}
                >
                  <span className="tx">일반 짝</span>
                  <span className="per">
                    {gameStore.data.dist.sum_even_percent}%
                  </span>
                </button>
              </div>
              <div className="item">
                <button
                  type="button"
                  className={clsx(
                    "LARGE",
                    pickClass === "sum_large" && "selected"
                  )}
                  onClick={() => onSetPick("sum_large", "sum_size", "large")}
                >
                  <span className="tx">일반 대</span>
                  <span className="per">
                    {gameStore.data.dist.sum_large_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    "MEDIUM",
                    pickClass === "sum_medium" && "selected"
                  )}
                  onClick={() => onSetPick("sum_medium", "sum_size", "medium")}
                >
                  <span className="tx">일반 중</span>
                  <span className="per">
                    {gameStore.data.dist.sum_medium_percent}%
                  </span>
                </button>
                <button
                  type="button"
                  className={clsx(
                    "SMALL",
                    pickClass === "sum_small" && "selected"
                  )}
                  onClick={() => onSetPick("sum_small", "sum_size", "small")}
                >
                  <span className="tx">일반 소</span>
                  <span className="per">
                    {gameStore.data.dist.sum_small_percent}%
                  </span>
                </button>
              </div>
            </div>
            {/* // pick_select */}

            {/* pick_message */}
            <div className="pick_message">
              <h3 className="tit">픽 메시지</h3>
              <div className="message_type">
                <select
                  value={commentIndex}
                  onChange={(e) => {
                    onSetPickComment(e.target);
                  }}
                >
                  <option value="0">
                    픽을 선택하신후 간단한 문구를 선택하세요.
                  </option>
                  <option value="1">
                    지금까지 이런 흐름은 없었다. 이것은 신인가 컴퓨터인가?
                  </option>
                  <option value="2">
                    펭하~! 펭수도 이런 흐름에는 고민없이 갑니다.
                  </option>
                  <option value="3">
                    신사답게 행동해~! 묻고 흐름타서 떠블로 가!
                  </option>
                  <option value="4">
                    니 내가 누군지 아니? 내 파워볼 장인이야!
                  </option>
                  <option value="5">
                    파워볼 숫자 적중은 네임드! 언오버 적중도 네!임!드!
                  </option>
                  <option value="6">
                    파워볼 숫자는 지리구요. 언오버는 오지구요.
                  </option>
                  <option value="7">
                    언오버를 지배하는 자가 파워볼을 지배합니다.
                  </option>
                  <option value="8">제 픽은 정말 현명한 선택이에요.</option>
                  <option value="9">
                    어디로 갈지 망설이지 말고 저를 전적으로 믿으셔야합니다.
                  </option>
                  <option value="10">이안에 픽있다. 파워볼아 가자~!</option>
                </select>
              </div>
              <button
                type="submit"
                className="btn_submit"
                onClick={() => insertPickComment()}
              >
                픽등록하기
              </button>
            </div>
            {/* // pick_message */}
          </div>
          {/* // pick_register_wrap */}
        </>
      )}
    </div>
  );
});

export { DhPowerballPick };
