import Api from '../api';
import { Url } from '../../constants/environment';
import Cookie from '../../utils/cookie';

class CommonService extends Api {
  constructor() {
    super();
  }

  /**
   * @description 이메일 인증번호 발송
   * @returns
   */
  async certifyEmail(email, resultUrl) {
    if (!email || !resultUrl) {
      return;
    }

    const form = new FormData();
    form.append('email', email);
    form.append('result_url', resultUrl);

    const res = await this.commonAjax('post', Url.named, `certify-email`, form);

    return res;
  }

  /**
   * @description 이메일 인증번호 인증
   * @returns
   */
  async certifyEmailCode(email, code) {
    if (!email || !code) {
      return;
    }

    const form = new URLSearchParams();
    form.append('email', email);
    form.append('code', code);

    const res = await this.commonAjax('put', Url.named, `certify-email`, form);

    return res;
  }

  /**
   * @description 마이페이지 이메일 인증번호 인증
   * @returns
   */
  async myCertifyEmailToken(certify_token) {
    if (!certify_token) {
      return;
    }

    const form = new URLSearchParams();
    form.append('certify_token', certify_token);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `me/certify-email`,
      form
    );

    return res;
  }

  /**
   * @description 휴대폰 본인인증 하기 전 enc-data return
   * @returns
   */
  async getPhoneEncData(result_url) {
    if (!result_url) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `certify-phone`, {
      result_url
    });

    return res;
  }

  /**
   * @description ipin 본인인증 하기 전 enc-data return
   * @returns
   */
  async getIpinEncData(result_url) {
    if (!result_url) {
      return;
    }

    const res = await this.commonAjax('get', Url.named, `certify-ipin`, {
      result_url
    });

    return res;
  }

  /**
   * @description 친구 등록
   * @returns
   */
  async friendAdd(mb_id, friend_id) {
    if (!mb_id || !friend_id) {
      return;
    }

    const form = new FormData();
    form.append('mb_id', mb_id);
    form.append('friend_id', friend_id);

    const res = await this.commonAjax('post', Url.named, `user/friend`, form);

    return res;
  }

  /**
   * @description 블랙리스트 등록
   * @returns
   */
  async blackAdd(mb_nick) {
    if (!mb_nick) {
      return;
    }

    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const form = new FormData();
    form.append('mb_id', Cookie.getCookie('mb_id'));
    form.append('mb_nick', mb_nick);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `user/black-list`,
      form
    );

    return res;
  }

  /**
   * @description 블랙리스트 삭제
   * @returns
   */
  async blackDelete(delete_mb_id) {
    if (!delete_mb_id) {
      return;
    }

    if (!Cookie.getCookie('mb_id')) {
      return;
    }

    const res = await this.commonAjax('delete', Url.named, `user/black-list`, {
      mb_id: Cookie.getCookie('mb_id'),
      delete_mb_id
    });

    return res;
  }

  /**
   * @description 회원탈퇴
   * @param certify_token
   * @returns {Promise<void>}
   */
  async actionMemberLeave(certify_token) {
    if (!certify_token) {
      return;
    }

    const res = await this.commonAjax('delete', Url.named, `users`, {
      certify_token: certify_token
    });

    return res;
  }
}

export default new CommonService();
