import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { NavLink, Route, Switch, useHistory } from 'react-router-dom';
import { CommunityService } from '../../../common/services';
import useCheckLogin from '../../../hooks/useCheckLogin';
import useModalStore from '../../../hooks/useModalStore';
import CommunityModel from '../../community/store/model';
import {
  Wrapper,
  Tab,
  TabItem,
  List,
  ListItem,
  Button,
  DetailContents,
  Info,
  InfoItem,
  DetailBox,
  NotAnswer,
  EmptyList
} from './styled';
import clsx from 'clsx';
import { Url } from '../../../common/constants/environment';
import CommunityDetailModel from '../../community/store/model/detail';
import QImage from 'images/customercenter/q.png';
import AImage from 'images/customercenter/a.png';
import CustomerCenterQnaWrite from './write';
import IsLogin from '../../../components/isLogin';
import Seo from '../../../components/seo';

const CustomerCenterQna = () => {
  const history = useHistory();
  const { isCheckLogin } = useCheckLogin();
  const modal = useModalStore();

  const onClickWrite = () => {
    if (!isCheckLogin) {
      modal.openConfirmModal({
        message: '로그인 후 사용이 가능 합니다. 로그인 하시겠습니까?',
        okCallback: () => history.push('/auth/login')
      });

      return;
    } else {
      history.push('/customercenter/qna/write');
    }
  };

  return (
    <Wrapper>
      <Tab>
        <TabItem>
          <IsLogin
            on={
              <NavLink
                to={`/customercenter/qna/write`}
                activeClassName={'selected'}
              >
                문의하기
              </NavLink>
            }
            off={<a onClick={onClickWrite}>문의하기</a>}
          />
        </TabItem>
        <TabItem>
          <NavLink to={`/customercenter/qna/list`} activeClassName={'selected'}>
            문의내역
          </NavLink>
        </TabItem>
      </Tab>

      <Switch>
        <Route
          exact
          path="/customercenter/qna/write"
          component={CustomerCenterQnaWrite}
        />
        <Route exact path="/customercenter/qna/list" component={QnaList} />
      </Switch>
    </Wrapper>
  );
};

const QnaList = observer(() => {
  const { isCheckLogin } = useCheckLogin();
  const modalStore = useModalStore();
  const store = useLocalStore(() => ({
    list: [],
    loading: false,
    totalCount: 0,
    async getQna() {
      store.loading = true;

      const res = await CommunityService.list('qna', 1, '', '', '');

      if (res.code !== 200) {
        modalStore.openAlertModal({
          message: res.message
        });
      } else {
        store.code = res.code;
        store.totalCount = res.data.total_cnt;
        store.list = res.data.list.map((item) => new CommunityModel(item));
        window.scrollTo(0, 0);
      }
    }
  }));

  useEffect(() => {
    if (isCheckLogin) {
      store.getQna();
    }
  }, [isCheckLogin]);

  return (
    <>
      <Seo title={'고객센터 문의내역'} />
      <div className="board_wrap">
        <div className="board_list">
          <List>
            {!store.isLoading && store.list.length > 0 ? (
              store.list.map((item) => <Row item={item} key={item.wr_id} />)
            ) : (
              <EmptyList>
                <span>작성된 글이 없습니다.</span>
                <span>고객센터를 통해 궁금증을 해결하세요!</span>
              </EmptyList>
            )}
          </List>
        </div>
      </div>
    </>
  );
});

const Row = observer(({ item }) => {
  const modalStore = useModalStore();
  const store = useLocalStore(() => ({
    result: {},
    loading: false,
    async getQnaDetail(id) {
      store.loading = true;
      store.result = {};

      const res = await CommunityService.detail(id, 'qna');

      if (res.code !== 200) {
        modalStore.openAlertModal({
          message: res.message
        });
      } else {
        store.code = res.code;
        store.result = new CommunityDetailModel(res.data);
      }

      return res.code;
    }
  }));
  const {
    time,
    wr_id,
    wr_subject,
    wr_name,
    wr_hit,
    wr_comment,
    wr_content
  } = item;
  const [isOpenDetail, toggleOpenDetail] = useState(false);

  const thumbImgPathReplace = (content) => {
    if (!!content) {
      return content.replace('http://data.named.com/data', Url.dataThumbUrl);
    } else {
      return '';
    }
  };

  const onClickListItem = () => {
    if (!isOpenDetail) {
      store.getQnaDetail(wr_id).then((code) => {
        if (code === 200) {
          toggleOpenDetail(true);
        }
      });
    } else {
      toggleOpenDetail(false);
    }
  };

  return (
    <>
      <ListItem onClick={onClickListItem}>
        <Info>
          <p className="subject">{wr_subject}</p>
          <InfoItem>
            <span className={clsx(+wr_comment > 0 ? 'answer' : '')}>
              {+wr_comment > 0 ? '답변완료' : '문의접수'}
            </span>
            <span className="name">{wr_name}</span>
            <span className="date">{time}</span>
            <span className="count">조회 {wr_hit}</span>
          </InfoItem>
        </Info>
        <Button type="button" className={isOpenDetail ? 'open' : 'close'}>
          열기/닫기
        </Button>
      </ListItem>
      {isOpenDetail && (
        <DetailContents>
          <DetailBox>
            <img src={QImage} alt="Q" height="19" width="19" />
            <div
              dangerouslySetInnerHTML={{
                __html: thumbImgPathReplace(wr_content)
              }}
            />
          </DetailBox>
          <DetailBox>
            <img src={AImage} alt="A" height="19" width="19" />
            {store.result.comment.length > 0 ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: thumbImgPathReplace(
                    store.result.comment[0].wr_content
                  )
                }}
              />
            ) : (
              <NotAnswer>
                회원님들께 좋은 데이터를 제공해 드리기 위해 자나깨나 고민 하고
                많은 노력을 기울이고 있습니다. 지켜봐 주시고 많은 사랑 부탁
                드려요!
              </NotAnswer>
            )}
          </DetailBox>
        </DetailContents>
      )}
    </>
  );
});

export default CustomerCenterQna;
