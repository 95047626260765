import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageIpList } from '../components';
import useUsersStore from '../../../hooks/useUsersStore';

const IpDelete = observer(() => {
  const usersStore = useUsersStore();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!usersStore.data || !usersStore.data.login_ip) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [usersStore.data]);

  return (
    <>
      {!isLoading && !!usersStore.data.login_ip.list && (
        <MypageIpList items={usersStore.data.login_ip.list} />
      )}
    </>
  );
});

export default IpDelete;
