import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {Url} from "../../../../../common/constants/environment";

const RunningballPickRank = observer(({ brandName, gameName }) => {
    const [height, setHeight] = useState(); // 초기 height 설정

    let url ="/minigame/rball/" + gameName +"/stats/main.php?view_mode=m";

    switch (window.location.hostname) {
        case `m.${Url.hostNamedWeb}` :
            url = `https://${Url.hostNamedWeb}${url}`;
            break;
        case `m-stage.${Url.hostNamedWeb}` :
            url = `https://stage.${Url.hostNamedWeb}${url}`;
            break;
        default :
            url = Url.namedWeb + url;
            break;
    }
    console.log(url);

    window.addEventListener('message', function(event) {
        const message = event.data;
        if (message && message.namedStatsHeight) {
            const height = message.namedStatsHeight;
            setHeight(height+'px')
        }
    }, false);

  return (
      <div>
        <iframe
            src={url}
            width="100%"
            frameBorder="0"
            scrolling={'no'}
            style={{width: '100%', height: height, border: 'none', overflowY: 'hidden'}}
        />
      </div>
  );
});

export {RunningballPickRank};
