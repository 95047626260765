import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Seo from '../../../components/seo';
import './exchange.css';
import { MypageContext, useMypageStore } from '../context';
import { useLocation, withRouter } from 'react-router-dom';
import useUsersStore from '../../../hooks/useUsersStore';
import Cookie from '../../../common/utils/cookie';
import { Url } from '../../../common/constants/environment';

const MedalExchange = observer(({ history }) => {
  const location = useLocation();
  //    const history = useHistory();
  const query = new URLSearchParams(location.search);
  const { mypageStore } = useMypageStore(MypageContext);
  const usersStore = useUsersStore();
  const exchangePrice = 100;
  const minimumAmount = 100;
  const maxExchangeDaily = 1000;
  const exchangeFeePercent = 10; // 10% fee
  const startDt = '';
  const endDt = '';

  const [userTotalMedal, setUserTotalMedal] = useState(0);
  const page = parseInt(query.get('page')) || 1;
  const [mbId, setMbId] = useState('');
  const [amount, setAmount] = useState('');
  const [name, setName] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [agree, setAgree] = useState(false);
  const [useAll, setUseAll] = useState(false);
  const [actualReceipts, setActualReceipts] = useState(0);
  const [price, setPrice] = useState(0);
  const [fee, setFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const [amountError, setAmountError] = useState({ isError: false, message: '' });
  const [checkBankName, setCheckBankName] = useState(true);
  const [checkName, setCheckName] = useState(true);
  const [checkBankAccount, setCheckBankAccount] = useState(true);
  const [checkAgree, setCheckAgree] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [remainingExchangeable, setRemainingExchangeable] = useState(0);

  useEffect(() => {
    mypageStore.actionPaymentMedalList({ startDt, endDt, page });
  }, [page, refresh]);

  useEffect(() => {
    setUserTotalMedal(mypageStore.data.total_payment_medal);
    setMbId(usersStore.me.mb_id);
    setRemainingExchangeable(maxExchangeDaily - mypageStore.data.today_exchange_medal);
  }, [mypageStore.data, usersStore.me.mb_id, usersStore.me.certify.name, mypageStore.data.today_exchange_medal]);

  //    const remainingExchangeable = maxExchangeDaily - usersStore.me.today_exchange_medal;

  const checkAmount = (amount) => {
    if (!amount) {
      setAmountError({ isError: true, message: '환전 금액을 입력하세요' });
      return false;
    } else if (userTotalMedal < amount) {
      setAmountError({ isError: true, message: '보유 건빵보다 더 많은 수량을 입력할 수 없습니다' });
      return false;
    } else if (amount < minimumAmount) {
      setAmountError({ isError: true, message: `최소 ${minimumAmount}개` });
      return false;
    } else if (amount > remainingExchangeable) {
      setAmountError({ isError: true, message: `일일 환전 가능 건빵 갯수를 초과했습니다.` });
      return false;
    }
    setAmountError({ isError: false, message: `` });
    return true;
  };

  const handleAmountBlur = () => {
    if (amount < 100) {
      setAmount(0);
    }
  };

  const numberFormat = (number) => {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return 0;
  };

  const updateCalculations = (amount) => {
    if (!isNaN(amount)) {
      const calculatedPrice = amount * exchangePrice;
      const calculatedFee = amount * exchangeFeePercent;
      const actualPrice = calculatedPrice - calculatedFee;

      setPrice(calculatedPrice);
      setFee(calculatedFee);
      setActualReceipts(actualPrice);
    } else {
      setPrice(0);
      setFee(0);
      setActualReceipts(0);
    }
  };

  const handleAmountChange = (e) => {
    const value = Math.max(0, parseInt(e.target.value.replace(/[^0-9]/g, '')));
    const validAmount = checkAmount(value);

    setAmount(value);
    setUseAll(value === userTotalMedal);

    if (validAmount) {
      updateCalculations(value);
    } else {
      updateCalculations(0);
    }
  };

  const handleUseAll = () => {
    if (!useAll) {
      setAmount(userTotalMedal <= remainingExchangeable ? userTotalMedal : remainingExchangeable);
      updateCalculations(userTotalMedal);
    } else {
      setAmount(0);
      updateCalculations(0);
    }
    setUseAll(!useAll);
  };

  const resetForm = () => {
    setAmount('');
    setBankName('');
    setName('');
    setAccountNumber('');
    setAgree(false);
    setUseAll(false);
    setPrice(0);
    setFee(0);
    setActualReceipts(0);
    setCheckBankName(true);
    setCheckName(true);
    setCheckBankAccount(true);
    setCheckAgree(true);
    setAmountError({ isError: false, message: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checkAmount(amount)) return;
    if (!name) {
      setCheckName(false);
      return;
    }
    if (!bankName) {
      setCheckBankName(false);
      return;
    }
    if (!accountNumber) {
      setCheckBankAccount(false);
      return;
    }
    if (!agree) {
      setCheckAgree(false);
      return;
    }

    const payload = new FormData();
    payload.append('mb_id', mbId);
    payload.append('amount', amount);
    payload.append('bank_name', bankName);
    payload.append('bank_holder', name);
    payload.append('bank_account', accountNumber);
    payload.append('id_img_path', '');
    payload.append('bank_img_path', '');

    const hostName = window.location.hostname;
    const isProdDomain =
      hostName === 'named.com' || hostName === 'nmd.co' || hostName === 'm.named.com' || hostName === 'm.nmd.co';

    if (!isProdDomain) {
      payload.append('is_test', true);
    }

    setLoading(true);
    try {
      const response = await fetch(`${Url.named}me/request-medal-exchange`, {
        method: 'POST',
        headers: {
          'named-session': Cookie.getCookie('session_id'),
        },
        body: payload,
      });

      const result = await response.json();

      if (result.success) {
        alert('신청이 완료되었습니다!');
        resetForm();
        setRefresh((prev) => !prev);
      } else {
        alert('신청 중 오류가 발생했습니다. 다시 시도해 주세요');
      }
    } catch (error) {
      alert('신청 중 오류가 발생했습니다. 다시 시도해 주세요');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Seo title={'마이페이지 캐쉬'} />
      <div className='exchange-form'>
        <h1 className='title'>건빵 환전 신청</h1>

        <h2 className='section-title'>신청서 작성</h2>

        <form className='form' onSubmit={handleSubmit}>
          <div className='section'>
            <div className='info'>
              <div className={'main-infor-item'}>
                <span>환전 가능 건빵</span>
                <span className={'red-text'} id={'user-medal-amount'}>
                  {numberFormat(userTotalMedal)}개
                </span>
              </div>
              <div className={'info-item'}>
                <span>일일 환전가능건빵</span>
                <span className={'red-text'} id={'user-medal-amount'}>
                  {numberFormat(remainingExchangeable)}개{' '}
                </span>
                <span className={'gray-text'}>(하루 환전 {numberFormat(maxExchangeDaily)}개 가능)</span>
              </div>
            </div>
            <div className='form-group form-with-unit'>
              <div className={'input-label'}>
                <div className={'title-label'}>
                  <label>환전 신청 건빵</label> <span className={'red-text'}>*</span>
                </div>
                <label className={`error-label ${amountError.isError ? '' : 'hide'}`}>{amountError.message}</label>
              </div>
              <div className={'input-with-unit'}>
                <input
                  className={'box-input'}
                  type='text'
                  value={numberFormat(amount)}
                  onChange={handleAmountChange}
                  onBlur={handleAmountBlur}
                  required
                />
                <span className={'input-unit'}>개</span>
              </div>
              <p className={'input-note gray-text'}>(건빵 100개 이상 부터 현금 환전이 가능)</p>
            </div>
            {/*<div className="form-group">*/}
            {/*    <label>*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            checked={useAll}*/}
            {/*            onChange={handleUseAll}*/}
            {/*        />*/}
            {/*        모두 사용하기*/}
            {/*    </label>*/}
            {/*</div>*/}
            <div className='form-group form-with-unit'>
              <label>환전 신청 금액</label>
              <div className={'input-with-unit'}>
                <input className={'box-input'} type='string' value={numberFormat(price)} disabled />
                <span className={'input-unit'}>원</span>
              </div>
            </div>
            <div className='form-group form-with-unit'>
              <label>수수료</label> <span className={'red-text'}>*</span>
              <div className={'input-with-unit'}>
                <input className={'box-input'} type='string' value={numberFormat(fee)} disabled />
                <span className={'input-unit'}>원</span>
              </div>
              <p className={'input-note gray-text'}>수수료 {exchangeFeePercent}% 금액</p>
            </div>
            <div id={'receipts-result'}>
              <strong className='sum'>실수령 금액</strong>
              <em className='cnt' id='actual_receipts'>
                {numberFormat(actualReceipts)}
              </em>
              <span className='cnt_unit'>원</span>
            </div>
          </div>

          <h2 className='section-title'>본인확인</h2>
          <div className='section'>
            <div className='form-group'>
              <div className={'input-label'}>
                <div className={'title-label'}>
                  <label>
                    성명 <span className={'red-text'}>*</span>
                  </label>
                </div>
                <label className={`error-label ${!checkName ? '' : 'hide'}`}>성명을 입력하세요.</label>
              </div>
              <div className={'input-without-unit'}>
                <input
                  className={'box-input'}
                  type='text'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setCheckName(true);
                  }}
                />
              </div>
              <p className={'input-note gray-text'}>통장에 기입된 본인실명</p>
            </div>
            <div className='form-group'>
              <div className={'input-label'}>
                <div className={'title-label'}>
                  <label>
                    은행명 <span className={'red-text'}>*</span>
                  </label>
                </div>
                <label className={`error-label ${!checkBankName ? '' : 'hide'}`}>은행명을 입력하세요.</label>
              </div>
              <div className={'input-without-unit'}>
                <input
                  className={'box-input'}
                  type='text'
                  value={bankName}
                  onChange={(e) => {
                    setBankName(e.target.value);
                    setCheckBankName(true);
                  }}
                  required
                />
              </div>
              <p className={'input-note gray-text'}>받을실 본인 명의 거래 은행</p>
            </div>
            <div className='form-group'>
              <div className={'input-label'}>
                <div className={'title-label'}>
                  <label>
                    계좌번호 <span className={'red-text'}>*</span>
                  </label>
                </div>
                <label className={`error-label ${!checkBankAccount ? '' : 'hide'}`}>계좌번호를 입력하세요.</label>
              </div>
              <div className={'input-without-unit'}>
                <input
                  className={'box-input'}
                  type='text'
                  value={accountNumber}
                  onChange={(e) => {
                    setAccountNumber(e.target.value.replace(/[^0-9]/g, ''));
                    setCheckBankAccount(true);
                  }}
                  required
                />
              </div>
              <p className={'input-note gray-text'}>계좌번호 '-' 없이 입력</p>
            </div>
          </div>

          <div className='form-group'>
            <label>
              <input
                type='checkbox'
                checked={agree}
                onChange={() => {
                  setAgree(!agree);
                  setCheckAgree(true);
                }}
              />
              개인정보 수집 및 이용 안내에 동의 합니다.
            </label>
            <p className={`error-label ${!checkAgree ? '' : 'hide'}`}>
              개인정보 수집 및 이용 안내에 동의하여야 합니다.
            </p>
          </div>
        </form>

        <div className='readme-wrapper'>
          <div className='readme'>
            <p>환전 신청 전에 꼭 읽어주세요!</p>
            <ul>
              <li className='ico_indent'>
                환전 신청한 금액은 신청일로부터 영업일 5일 이내 입금 처리됩니다. (휴일인 경우 익일 영업일에 일괄 처리).
              </li>
              <li className='ico_indent'>건빵은 최소 100개부터 환전이 가능합니다.</li>
              <li className='ico_indent'>건빵은 00시 기준으로 하루에 1,000개까지만 환전 신청이 가능합니다.</li>
              <li className='ico_indent'>
                불법적인 용도나, 법적인 문제가 발생할 경우, 비정상적인 경우, 네임드 약관에 위배가되는 경우 환전 승인이
                거절될 수 있습니다.
              </li>
              <li className='ico_indent'>
                최근 90일간 환전 처리결과 내역을 확인하실 수 있으며, 90일 이후 내역은 모두 소멸됩니다.
              </li>
            </ul>
          </div>
        </div>

        <div className='button-group'>
          <button
            type='button'
            onClick={handleSubmit}
            className={`submit-button ${loading ? 'loading-btn' : ''}`}
            disabled={loading}
          >
            환전하기
          </button>
          <button
            type='button'
            className={`cancel-button ${loading ? 'loading-btn' : ''}`}
            onClick={() => history.goBack()}
            disabled={loading}
          >
            창닫기
          </button>
        </div>
      </div>
    </>
  );
});

export default withRouter(MedalExchange);
