import CommonStore from './common.store';
import ScoreStore from './score.store';
import UsersStore from './users.store';
import ModalStore from './modal.store';
import CommunityStore from '../../routes/community/store';
import ClockStore from './clock.store';
import GameStore from "./game.store";
import BannerStore from "./banners.store";
import HomeStore from './home.store';

class RootStore {
  modalStore;
  scoreStore;
  usersStore;
  commonStore;
  communityStore;
  clockStore;
  gameStore;
  homeStore;

  constructor() {
    this.modalStore = new ModalStore(this);
    this.usersStore = new UsersStore(this);
    this.commonStore = new CommonStore(this);
    this.scoreStore = new ScoreStore(this);
    this.communityStore = new CommunityStore(this);
    this.clockStore = new ClockStore();
    this.gameStore = new GameStore(this);
    this.bannerStore = new BannerStore();
    this.homeStore = new HomeStore(this);
  }
}

export default RootStore;
