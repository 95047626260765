import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  textarea {
    border: 1px solid #e4e4e4;
    margin-top: 20px;
    padding: 15px;
    ::placeholder {
      color: #999;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  button {
    flex: 1;
    font-size: 14px;
    color: #fff;
    height: 44px;
    background-color: #8e8e8e;
    &.send {
      background-color: #ed2026;
      margin-left: 5px;
    }
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 156px;
  > img {
    height: 50px;
    margin-bottom: 19px;
  }
  .name {
    font-size: 20px;
    line-height: 19px;
    font-weight: 600;
  }
  .desc {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
    text-align: center;
    padding: 0 10px;
  }
`;

export const QuantityContainer = styled.div`
  margin-bottom: 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const QuantityRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const QuantityLabel = styled.label`
  font-size: 0.875rem;
  white-space: nowrap;
`;

export const QuantityControlGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const QuantityButton = styled.button`
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1d5db;
  background-color: white;
  font-size: 1.125rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #f9fafb;
  }
`;

export const DecrementButton = styled(QuantityButton)`
  border-radius: 0.375rem 0 0 0.375rem;
  border-right: none;
`;

export const IncrementButton = styled(QuantityButton)`
  border-radius: 0 0.375rem 0.375rem 0;
  border-left: none;
`;

export const QuantityInput = styled.input`
  width: 4rem;
  height: 2.25rem;
  text-align: center;
  border: 1px solid #d1d5db;
  font-size: 1rem;
  font-weight: 700;

  /* Remove spinner buttons */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const MaxQuantity = styled.span`
  font-size: 1rem;
  color: #6b7280;
  font-weight: 500;
`;

export const GiftCountDesc = styled.span`
  font-size: 12px;
  color: #6b7280;
`;
