import React, { useEffect, useRef, useState } from 'react';
import {
  Background,
  Wrap,
  Header,
  CloseButton,
  TypeWrap,
  TypeButton,
  GuideList
} from './styled';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const scoreGuide = [
  { key: '이메일인증', value: '최초 1회 이메일인증시 10점 획득' },
  { key: '비밀번호변경', value: '1개월에 한번씩 변경시 2점씩 획득(총 10점)' },
  {
    key: '인증정보최초',
    value: '1회 아이핀. 휴대폰 중 하나라도 인증시 20점 획득'
  },
  { key: '닉네임변경최초', value: '1회 닉네임 변경시 10점 획득' },
  {
    key: '오늘의 한마디 변경',
    value: '최초 1회 오늘의 한마디 변경시 10점 획득'
  },
  {
    key: '프로필 이미지 변경',
    value: '최초 1회 프로필 이미지 변경시 10점 획득'
  },
//  { key: '2차 비밀번호 설정', value: '최초 1회 2차 비밀번호 설정시 10점 획득' },
//  { key: '지정IP서비스', value: '최초 1회 지정 IP지정시 10점 획득' }
];

const rewardGuide = [
  { key: '25점', value: '닉네임 변경권. 채팅1일권. 3,000포인트' },
  { key: '50점', value: '오늘의 한마디 변경권. 채팅1일권' },
  { key: '75점', value: '프로필 이미지 변경권. 채팅1일권' },
  { key: '100점', value: '무료쪽지(100개). 채팅1일권. 꿀엄지' }
];

const RewardGuidanceModal = ({ isOpen, onClose }) => {
  const bodyScroll = useRef();

  const [selectedReward, setSelectedReward] = useState(false);

  const guideList = selectedReward ? rewardGuide : scoreGuide;

  useEffect(() => {
    if (!isOpen) {
      enableBodyScroll(bodyScroll.current);
    } else {
      disableBodyScroll(bodyScroll.current);
    }
  }, [isOpen]);

  return (
    <Background isOpen={isOpen} onClick={onClose}>
      <Wrap
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Header>
          <h2>달성률과 보상안내</h2>
          <CloseButton onClick={onClose} />
        </Header>
        <div>
          <TypeWrap>
            <TypeButton
              className={'type-score'}
              onClick={() => setSelectedReward(false)}
              selected={!selectedReward}
            >
              점수안내
            </TypeButton>
            <TypeButton
              className={'type-reward'}
              onClick={() => setSelectedReward(true)}
              selected={selectedReward}
            >
              보상안내
            </TypeButton>
          </TypeWrap>
          <GuideList ref={bodyScroll}>
            {guideList.map((item, key) => {
              return (
                <li key={key}>
                  <em>{item.key}</em>
                  <p>{item.value}</p>
                </li>
              );
            })}
          </GuideList>
        </div>
      </Wrap>
    </Background>
  );
};

export default RewardGuidanceModal;
