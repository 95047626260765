import React, { useEffect, useState } from 'react';
import { Provider } from 'mobx-react';
import { Router, RouterStore } from 'react-router-mobx';
import { BrowserRouter } from 'react-router-dom';
import './style/index.scss';
import { ModalComponent } from './components/modal';
import Routes from './routes';
import RootStore from './common/stores';

const rootStore = new RootStore();
const routerStore = new RouterStore();

function App() {
  const [store, setStore] = useState(rootStore);

  useEffect(() => {
    const mobileArr = [
      'iPhone',
      'iPod',
      'BlackBerry',
      'Android',
      'Windows CE',
      'LG',
      'MOT',
      'SAMSUNG',
      'SonyEricsson'
    ];
    let mobileDevice = '';

    for (let txt in mobileArr) {
      if (navigator.userAgent.match(mobileArr[txt]) != null) {
        mobileDevice = mobileArr[txt];
        break;
      }
    }

    window.reflowOnFocus = () => {
      if (
        navigator.userAgent.indexOf('chrome') &&
        (mobileDevice === 'iPhone' || mobileDevice === 'iPod')
      ) {
        document.getElementById('for-reflow').focus();
      }
    };
  }, []);

  return (
    <Provider {...store}>
      <Router component={BrowserRouter} routerStore={routerStore}>
        <Routes />
      </Router>
      <ModalComponent />
      <input
        id={'for-reflow'}
        onFocus={(event) => {
          event.currentTarget.blur();
        }}
      />
    </Provider>
  );
}

export default App;
