import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';

const PaginationExample = ({ onChangePage, page, totalCount }) => {
  const [pagination, setPagination] = useState([1]);
  const [paginationRange, setPagenationRange] = useState(1);
  const [currentPage, setCurrentPage] = useState(page);
  const [init, setInit] = useState(false);

  useEffect(() => {
    let range = 1;
    let result = [];

    if (totalCount > 0 && !init) {
      if (totalCount > 6) {
        range = Math.round(totalCount / 6);
      }

      for (let i = 1; i <= range; i++) {
        if (i > 5) {
          break;
        } else {
          result.push(i);
        }
      }

      setPagenationRange(range);
      setPagination(result);
      setInit(true);
    }
  }, [totalCount]);

  const onClickPage = (page) => {
    let arr = [...pagination];

    if (page > 3) {
      const changeArr = [page - 2, page - 1, page, page + 1, page + 2];
      const check = changeArr.findIndex((item) => item > paginationRange);
      const result = [];

      if (Boolean(~check)) {
        const rangeCalc = paginationRange - 4 === 0 ? 1 : paginationRange - 4;

        for (let i = paginationRange; i >= rangeCalc; i--) {
          result.push(i);
        }

        arr = result.reverse();
      } else {
        arr = changeArr;
      }

      arr.slice();
    }

    setPagination(arr);
    setCurrentPage(page);
    onChangePage(page);
  };

  const onClickPrev = () => {
    let arr = pagination.slice();

    if (pagination[0] > 1) {
      arr = pagination.map((item) => item - 1);
    }

    setPagination(arr);

    let c = currentPage - 1;
    c = c < 1 ? 1 : c;

    setCurrentPage(c);
    onChangePage(c);
  };

  const onClickNext = () => {
    let arr = pagination.slice();
    let c = currentPage + 1;

    if (currentPage > 2) {
      if (paginationRange > pagination[pagination.length - 1]) {
        arr = pagination.map((item) => item + 1);
      }
    }

    if (c > paginationRange) {
      c = paginationRange;
    }

    setPagination(arr);
    setCurrentPage(c);
    onChangePage(c);
  };

  return (
    <div className="pagination">
      <a className="btn btn_prev" onClick={onClickPrev}>
        다음 페이지
      </a>

      <div className="number">
        {pagination.map((item) => {
          return (
            <a
              key={item}
              className={clsx('num', currentPage === item && 'on')}
              onClick={() => onClickPage(item)}
            >
              <em>{item}</em>
            </a>
          );
        })}
      </div>

      <a className="btn btn_next" onClick={onClickNext}>
        다음 페이지
      </a>
    </div>
  );
};

export default PaginationExample;
