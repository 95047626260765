import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const BarChart = ({ count = {} }) => {
  return (
    <>
      <div className="data_summary">
        <h3 className="tit">파워볼 통계</h3>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'ODD',
              parseInt(count.powerball_odd_count) >
                parseInt(count.powerball_even_count) && 'on'
            )}
          >
            <dt>홀</dt>
            <dd>
              <span className="num">{count.powerball_odd_count}</span>
              <span className="per">({count.powerball_odd_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              parseInt(count.powerball_odd_count) <
                parseInt(count.powerball_even_count) && 'on'
            )}
          >
            <dt>짝</dt>
            <dd>
              <span className="num">{count.powerball_even_count}</span>
              <span className="per">({count.powerball_even_percent}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'UNDER',
              parseInt(count.powerball_under_count) >
                parseInt(count.powerball_over_count) && 'on'
            )}
          >
            <dt>언더</dt>
            <dd>
              <span className="num">{count.powerball_under_count}</span>
              <span className="per">({count.powerball_under_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'OVER',
              parseInt(count.powerball_under_count) <
                parseInt(count.powerball_over_count) && 'on'
            )}
          >
            <dt>오버</dt>
            <dd>
              <span className="num">{count.powerball_over_count}</span>
              <span className="per">({count.powerball_over_percent}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart column4">
          <dl
            className={clsx(
              'bar',
              'A',
              MathUtil.onlyMaxNumber([
                count.powerball_a_section_count,
                count.powerball_b_section_count,
                count.powerball_c_section_count,
                count.powerball_d_section_count
              ]) === parseInt(count.powerball_a_section_count) && 'on'
            )}
          >
            <dt>구간 A</dt>
            <dd>
              <span className="num">{count.powerball_a_section_count}</span>
              <span className="per">
                ({count.powerball_a_section_percent}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'B',
              MathUtil.onlyMaxNumber([
                count.powerball_a_section_count,
                count.powerball_b_section_count,
                count.powerball_c_section_count,
                count.powerball_d_section_count
              ]) === parseInt(count.powerball_b_section_count) && 'on'
            )}
          >
            <dt>구간 B</dt>
            <dd>
              <span className="num">{count.powerball_b_section_count}</span>
              <span className="per">
                ({count.powerball_b_section_percent}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'C',
              MathUtil.onlyMaxNumber([
                count.powerball_a_section_count,
                count.powerball_b_section_count,
                count.powerball_c_section_count,
                count.powerball_d_section_count
              ]) === parseInt(count.powerball_c_section_count) && 'on'
            )}
          >
            <dt>구간 C</dt>
            <dd>
              <span className="num">{count.powerball_c_section_count}</span>
              <span className="per">
                ({count.powerball_c_section_percent}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'D',
              MathUtil.onlyMaxNumber([
                count.powerball_a_section_count,
                count.powerball_b_section_count,
                count.powerball_c_section_count,
                count.powerball_d_section_count
              ]) === parseInt(count.powerball_d_section_count) && 'on'
            )}
          >
            <dt>구간 D</dt>
            <dd>
              <span className="num">{count.powerball_d_section_count}</span>
              <span className="per">
                ({count.powerball_d_section_percent}%)
              </span>
            </dd>
          </dl>
        </div>
        <div className="chart column4">
          <dl
            className={clsx(
              'bar',
              'ODD',
              MathUtil.onlyMaxNumber([
                count.powerball_odd_under_count,
                count.powerball_odd_over_count,
                count.powerball_even_under_count,
                count.powerball_even_over_count
              ]) === parseInt(count.powerball_odd_under_count) && 'on'
            )}
          >
            <dt>홀언</dt>
            <dd>
              <span className="num">{count.powerball_odd_under_count}</span>
              <span className="per">
                ({count.powerball_odd_under_percent}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'ODD',
              MathUtil.onlyMaxNumber([
                count.powerball_odd_under_count,
                count.powerball_odd_over_count,
                count.powerball_even_under_count,
                count.powerball_even_over_count
              ]) === parseInt(count.powerball_odd_over_count) && 'on'
            )}
          >
            <dt>홀오</dt>
            <dd>
              <span className="num">{count.powerball_odd_over_count}</span>
              <span className="per">({count.powerball_odd_over_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              MathUtil.onlyMaxNumber([
                count.powerball_odd_under_count,
                count.powerball_odd_over_count,
                count.powerball_even_under_count,
                count.powerball_even_over_count
              ]) === parseInt(count.powerball_even_under_count) && 'on'
            )}
          >
            <dt>짝언</dt>
            <dd>
              <span className="num">{count.powerball_even_under_count}</span>
              <span className="per">
                ({count.powerball_even_under_percent}%)
              </span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              MathUtil.onlyMaxNumber([
                count.powerball_odd_under_count,
                count.powerball_odd_over_count,
                count.powerball_even_under_count,
                count.powerball_even_over_count
              ]) === parseInt(count.powerball_even_over_count) && 'on'
            )}
          >
            <dt>짝오</dt>
            <dd>
              <span className="num">{count.powerball_even_over_count}</span>
              <span className="per">
                ({count.powerball_even_over_percent}%)
              </span>
            </dd>
          </dl>
        </div>
      </div>

      <div className="data_summary">
        <h3 className="tit">일반볼 통계</h3>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'ODD',
              parseInt(count.sum_odd_count) > parseInt(count.sum_even_count) &&
                'on'
            )}
          >
            <dt>홀</dt>
            <dd>
              <span className="num">{count.sum_odd_count}</span>
              <span className="per">({count.sum_odd_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              parseInt(count.sum_odd_count) < parseInt(count.sum_even_count) &&
                'on'
            )}
          >
            <dt>짝</dt>
            <dd>
              <span className="num">{count.sum_even_count}</span>
              <span className="per">({count.sum_even_percent}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'UNDER',
              parseInt(count.sum_under_count) >
                parseInt(count.sum_over_count) && 'on'
            )}
          >
            <dt>언더</dt>
            <dd>
              <span className="num">{count.sum_under_count}</span>
              <span className="per">({count.sum_under_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'OVER',
              parseInt(count.sum_under_count) <
                parseInt(count.sum_over_count) && 'on'
            )}
          >
            <dt>오버</dt>
            <dd>
              <span className="num">{count.sum_over_count}</span>
              <span className="per">({count.sum_over_percent}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart">
          <dl
            className={clsx(
              'bar',
              'BIG',
              MathUtil.onlyMaxNumber([
                count.sum_large_count,
                count.sum_medium_count,
                count.sum_small_count
              ]) === parseInt(count.sum_large_count) && 'on'
            )}
          >
            <dt>대</dt>
            <dd>
              <span className="num">{count.sum_large_count}</span>
              <span className="per">({count.sum_large_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'MIDDLE',
              MathUtil.onlyMaxNumber([
                count.sum_large_count,
                count.sum_medium_count,
                count.sum_small_count
              ]) === parseInt(count.sum_medium_count) && 'on'
            )}
          >
            <dt>중</dt>
            <dd>
              <span className="num">{count.sum_medium_count}</span>
              <span className="per">({count.sum_medium_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'SMALL',
              MathUtil.onlyMaxNumber([
                count.sum_large_count,
                count.sum_medium_count,
                count.sum_small_count
              ]) === parseInt(count.sum_small_count) && 'on'
            )}
          >
            <dt>소</dt>
            <dd>
              <span className="num">{count.sum_small_count}</span>
              <span className="per">({count.sum_small_percent}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart column4">
          <dl
            className={clsx(
              'bar',
              'ODD',
              MathUtil.onlyMaxNumber([
                count.sum_odd_under_count,
                count.sum_odd_over_count,
                count.sum_even_under_count,
                count.sum_even_over_count
              ]) === parseInt(count.sum_odd_under_count) && 'on'
            )}
          >
            <dt>홀언</dt>
            <dd>
              <span className="num">{count.sum_odd_under_count}</span>
              <span className="per">({count.sum_odd_under_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'ODD',
              MathUtil.onlyMaxNumber([
                count.sum_odd_under_count,
                count.sum_odd_over_count,
                count.sum_even_under_count,
                count.sum_even_over_count
              ]) === parseInt(count.sum_odd_over_count) && 'on'
            )}
          >
            <dt>홀오</dt>
            <dd>
              <span className="num">{count.sum_odd_over_count}</span>
              <span className="per">({count.sum_odd_over_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              MathUtil.onlyMaxNumber([
                count.sum_odd_under_count,
                count.sum_odd_over_count,
                count.sum_even_under_count,
                count.sum_even_over_count
              ]) === parseInt(count.sum_even_under_count) && 'on'
            )}
          >
            <dt>짝언</dt>
            <dd>
              <span className="num">{count.sum_even_under_count}</span>
              <span className="per">({count.sum_even_under_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVEN',
              MathUtil.onlyMaxNumber([
                count.sum_odd_under_count,
                count.sum_odd_over_count,
                count.sum_even_under_count,
                count.sum_even_over_count
              ]) === parseInt(count.sum_even_over_count) && 'on'
            )}
          >
            <dt>짝오</dt>
            <dd>
              <span className="num">{count.sum_even_over_count}</span>
              <span className="per">({count.sum_even_over_percent}%)</span>
            </dd>
          </dl>
        </div>
        <div className="chart column6">
          <dl
            className={clsx(
              'bar',
              'ODDBIG',
              MathUtil.onlyMaxNumber([
                count.sum_odd_large_count,
                count.sum_odd_medium_count,
                count.sum_odd_small_count,
                count.sum_even_large_count,
                count.sum_even_medium_count,
                count.sum_even_small_count
              ]) === parseInt(count.sum_odd_large_count) && 'on'
            )}
          >
            <dt>홀대</dt>
            <dd>
              <span className="num">{count.sum_odd_large_count}</span>
              <span className="per">({count.sum_odd_large_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'ODDMIDDLE',
              MathUtil.onlyMaxNumber([
                count.sum_odd_large_count,
                count.sum_odd_medium_count,
                count.sum_odd_small_count,
                count.sum_even_large_count,
                count.sum_even_medium_count,
                count.sum_even_small_count
              ]) === parseInt(count.sum_odd_medium_count) && 'on'
            )}
          >
            <dt>홀중</dt>
            <dd>
              <span className="num">{count.sum_odd_medium_count}</span>
              <span className="per">({count.sum_odd_medium_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'ODDSMALL',
              MathUtil.onlyMaxNumber([
                count.sum_odd_large_count,
                count.sum_odd_medium_count,
                count.sum_odd_small_count,
                count.sum_even_large_count,
                count.sum_even_medium_count,
                count.sum_even_small_count
              ]) === parseInt(count.sum_odd_small_count) && 'on'
            )}
          >
            <dt>홀소</dt>
            <dd>
              <span className="num">{count.sum_odd_small_count}</span>
              <span className="per">({count.sum_odd_small_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVENBIG',
              MathUtil.onlyMaxNumber([
                count.sum_odd_large_count,
                count.sum_odd_medium_count,
                count.sum_odd_small_count,
                count.sum_even_large_count,
                count.sum_even_medium_count,
                count.sum_even_small_count
              ]) === parseInt(count.sum_even_large_count) && 'on'
            )}
          >
            <dt>짝대</dt>
            <dd>
              <span className="num">{count.sum_even_large_count}</span>
              <span className="per">({count.sum_even_large_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVENMIDDLE',
              MathUtil.onlyMaxNumber([
                count.sum_odd_large_count,
                count.sum_odd_medium_count,
                count.sum_odd_small_count,
                count.sum_even_large_count,
                count.sum_even_medium_count,
                count.sum_even_small_count
              ]) === parseInt(count.sum_even_medium_count) && 'on'
            )}
          >
            <dt>짝중</dt>
            <dd>
              <span className="num">{count.sum_even_medium_count}</span>
              <span className="per">({count.sum_even_medium_percent}%)</span>
            </dd>
          </dl>
          <dl
            className={clsx(
              'bar',
              'EVENSMALL',
              MathUtil.onlyMaxNumber([
                count.sum_odd_large_count,
                count.sum_odd_medium_count,
                count.sum_odd_small_count,
                count.sum_even_large_count,
                count.sum_even_medium_count,
                count.sum_even_small_count
              ]) === parseInt(count.sum_even_small_count) && 'on'
            )}
          >
            <dt>짝소</dt>
            <dd>
              <span className="num">{count.sum_even_small_count}</span>
              <span className="per">({count.sum_even_small_percent}%)</span>
            </dd>
          </dl>
        </div>
      </div>
    </>
  );
};

export default BarChart;
