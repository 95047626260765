import { observable, computed, action, runInAction } from 'mobx';
import autobind from 'autobind-decorator';
import { asyncAction } from 'mobx-utils';
import ProtoModel from '../../proto/store/model';
import { timeUtils } from '../../../../common/utils/timeUtil';
import { RegexUtil } from '../../../../common/utils/regexUtil';
import protoService from '../../../../common/services/score/proto/protoService';
import { ScoreUtil } from '../../../../common/utils/scoreUtil';
import { ScoreService } from '../../../../common/services';
import useLocalStorage from '../../../../hooks/useLocalStorage';

@autobind
class ProtoCalculatorStore {
  localstorage = new useLocalStorage();

  @observable
  proto = {
    currentRound: {
      id: 0,
      type: '',
      year: '',
      status: '',
      displayName: '',
      outerRound: 0,
      startAt: null,
      endAt: null
    },
    previousRound: {
      id: 0,
      type: '',
      year: '',
      status: '',
      displayName: '',
      outerRound: 0,
      startAt: null,
      endAt: null
    },
    nextRound: {},
    roundGames: []
  };

  @observable
  loading = false;

  @observable
  tempBattingPicks = [];

  @observable
  tempBattingAmount = 1000;

  @observable
  tempBattingClear = false;

  @observable
  tempPickNo = 1;

  @observable
  slipOpen = false;

  @observable
  isLogin;

  constructor(stores) {
    this.modalStore = stores.modalStore;
    this.isLogin = stores.commonStore.loginCheck;

    // if (!this.isLogin) {
    this.localstorage.getAsyncArrItem('protoCalcTemp').then((success) => {
      if (!!success && success.hasOwnProperty('amount')) {
        this.tempBattingPicks = success.battingPicks;
        this.tempBattingAmount = success.amount;
      }
    });
    // }
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get battingNumber() {
    const { tempPickNo, proto } = this;
    const { currentRound } = proto;

    return `${currentRound.outerRound}-${tempPickNo}`;
  }

  @computed
  get amount() {
    return this.tempBattingAmount;
  }

  @computed
  get battingPicks() {
    const { tempBattingPicks, proto } = this;
    const { currentRound } = proto;

    return tempBattingPicks.filter((item) => item.roundId === currentRound.id);
  }

  @computed
  get battingCount() {
    const { battingPicks } = this;

    return battingPicks.length;
  }

  @computed
  get protoList() {
    const isSales = (gameStatus) => {
      if (
        gameStatus === 'FINAL' ||
        gameStatus === 'PENDING' ||
        gameStatus === 'POSTPONED' ||
        gameStatus === 'CUT' ||
        gameStatus === 'CANCEL' ||
        gameStatus === 'SUSPENDED'
      ) {
        return false;
      } else if (
        gameStatus === 'READY' ||
        gameStatus === 'IN_PROGRESS' ||
        gameStatus === 'BREAK_TIME' ||
        gameStatus === 'PAUSE'
      ) {
        return true;
      }
    };
    const list = this.proto.roundGames.toJS();
    const filterList = list.filter((item) => {
      const { game } = item;
      const { gameStatus } = game;

      return isSales(gameStatus);
    });
    const sortArr = ScoreUtil.sortProtoWinList(filterList);
    const temp = [];

    sortArr.reduce((a, c) => {
      if (a.length === 0) {
        a.push(c);
      } else {
        if (a[a.length - 1].game.id === c.game.id) {
          a.push(c);
        } else {
          temp.push(a);
          a = [c];
        }
      }

      return a;
    }, []);

    return temp;
  }

  @computed
  get currentRoundStartDateTime() {
    return timeUtils.scoreScheduleTimeFormat(this.proto.currentRound.startAt);
  }

  @computed
  get totalOdds() {
    const { battingPicks } = this;

    if (battingPicks.length === 0) {
      return 0;
    } else {
      return battingPicks.reduce((a, c) => a * c.odds, 1).toFixed(2);
    }
  }

  @computed
  get totalAmount() {
    const { totalOdds, amount } = this;

    const result = totalOdds * amount;

    return `${RegexUtil.makeComma(Math.round(result))}원`;
  }

  /**
   * @description 배팅 데이터
   * @return 
   * {
       "no": 0,
       "oddsIds": [
         0
       ],
       "point": 0
     }
   */
  @computed
  get calculatorParam() {
    const { battingPicks, tempBattingAmount, tempPickNo } = this;

    const oddsIds = Array.from(battingPicks, (item) => item.oddsId);

    return {
      no: tempPickNo,
      oddsIds,
      point: tempBattingAmount
    };
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  toggleOpen() {
    this.slipOpen = !this.slipOpen;
  }

  @action
  changeAmount(e) {
    const { value } = e.target;

    if (value > 50000) {
      this.modalStore.openAlertModal({
        message: '최대 50,000원 까지 배팅 가능합니다.'
      });
    } else {
      this.tempBattingAmount = e.target.value;
    }

    this.persistData();
  }

  @action
  onClickOdds({ oddsId, gameId, roundGameId, odds }) {
    const { battingPicks, proto } = this;
    const { currentRound } = proto;
    const filterGame = proto.roundGames.filter(
      (item) => item.game.id === gameId
    );

    let copy = [...battingPicks];

    const selectedOdds = filterGame
      .filter((item) => item.id === roundGameId)[0]
      .oddss.filter((odds) => odds.id === oddsId)[0];

    if (!selectedOdds.availableFlag) {
      this.modalStore.openAlertModal({
        message: '발매차단되었습니다.'
      });
      return;
    }

    if (battingPicks.length === 0) {
      if (this.tempBattingClear) {
        this.tempBattingClear = false;
      }
      // 카트가 비어있을때
      copy.push({
        oddsId,
        gameId,
        roundGameId,
        odds,
        roundId: currentRound.id
      });
    } else {
      const checkDuplicate = this.checkDuplicateGame(
        oddsId,
        gameId,
        roundGameId
      );
      // // 카트에 하나 이상 담겨있을때

      if (checkDuplicate === 'next') {
        if (battingPicks.length > 9) {
          this.modalStore.openAlertModal({
            message:
              '선택 가능 개수를 초과하였습니다. 최대 10경기까지 선택 가능합니다.'
          });
        } else {
          copy.push({
            oddsId,
            gameId,
            roundGameId,
            odds,
            roundId: currentRound.id
          });
        }
      } else if (checkDuplicate === 'game') {
        const index = copy.findIndex((item) => item.gameId === gameId);

        copy[index].oddsId = oddsId;
        copy[index].odds = odds;
      } else if (checkDuplicate === 'odds') {
        copy = copy.filter((item) => item.oddsId !== oddsId);
      }
    }

    this.tempBattingPicks = copy;

    this.persistData();
  }

  /**
   * @description duplicate game check
   * @param {*} oddsId
   * @param {*} gameId
   * @param {*} roundGameId
   */
  checkDuplicateGame(oddsId, gameId, roundGameId) {
    const { battingPicks } = this;

    if (battingPicks.length > 0) {
      let result = 'next';

      battingPicks.forEach((item) => {
        if (item.gameId === gameId) {
          if (item.roundGameId === roundGameId) {
            result = 'game';

            if (item.oddsId === oddsId) {
              result = 'odds';
            }
          }
        }
      });

      return result;
    } else {
      return 'next';
    }
  }

  /**
   * @description 로컬스토리지에 저장
   * @param {*} key
   * @param {*} data
   */
  persistData() {
    const { battingPicks, amount, isLogin } = this;
    const { updateItem } = this.localstorage;

    // if (!isLogin) {
    updateItem('protoCalcTemp', JSON.stringify({ battingPicks, amount }));
    // }
  }

  @action
  clearBattingTemp() {
    const { isLogin } = this;
    const { removeItem } = this.localstorage;

    this.tempBattingClear = true;
    this.tempBattingAmount = 1000;
    this.tempBattingPicks = [];

    // if (!isLogin) {
    removeItem('protoCalcTemp');
    // }
  }

  /**********************************************************
   * async actions
   **********************************************************/

  @asyncAction
  async *getProtoCalc(roundId = '') {
    this.loading = true;

    try {
      let copyProto = { ...this.proto };

      const result = yield ScoreService.getListOfProtoWin(roundId);

      if (this.isLogin) {
        const lastPickNo = yield protoService.getOddsCalculator(
          '',
          1,
          1,
          roundId
        );

        if (lastPickNo.length > 0 && lastPickNo[0].hasOwnProperty('no')) {
          this.tempPickNo = lastPickNo[0].no + 1;
        } else {
          this.tempPickNo = 1;
        }
      }
      if (result.hasOwnProperty('currentRound')) {
        copyProto.currentRound = result.currentRound;
      } else {
        copyProto.currentRound = {};
      }
      if (result.hasOwnProperty('previousRound')) {
        copyProto.previousRound = result.previousRound;
      } else {
        copyProto.previousRound = {};
      }
      if (result.hasOwnProperty('nextRound')) {
        copyProto.nextRound = result.nextRound;
      } else {
        copyProto.nextRound = {};
      }
      if (result.hasOwnProperty('roundGames')) {
        copyProto.roundGames = result.roundGames.map(
          (item) => new ProtoModel({ ...item })
        );
      } else {
        copyProto.roundGames = [];
      }
      if (result.hasOwnProperty('roundStatistics')) {
        copyProto.roundStatistics = result.roundStatistics;
      } else {
        copyProto.roundStatistics = null;
      }

      runInAction(() => {
        this.proto = copyProto;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  async *submitCalculator() {
    try {
      const { calculatorParam } = this;

      const result = yield protoService.postOddsCalculator(calculatorParam);

      if (result.status === 200) {
        runInAction(() => {
          this.clearBattingTemp();
          this.tempPickNo = this.tempPickNo + 1;
          this.modalStore.openAlertModal({
            message: '저장이 완료되었습니다.'
          });
        });
      } else {
        this.modalStore.openAlertModal({
          message: result.data.message
        });
      }
    } catch (error) {
      this.modalStore.openAlertModal({
        message: error.message
      });
      console.log(error);
    }
  }
}

export default ProtoCalculatorStore;
