import React from 'react';
import {Url} from "../../common/constants/environment";

const ScoreDetailHtml = () => {
  return (
    <>

      {/* score_detail_wrap */ }
      <div className="score_detail_wrap"> {/* 클래스 상태 : small_view, ready, close */}

        <div className="summary">
          <button className="btn_prev">이전으로</button>
          <h2 className="tit">UEFA 챔피언스리그</h2>
          <button className="btn_oc">크게보기/작게보기</button>
        </div>

        {/* score_detail_board */ }
        <div className="score_detail_board soccer"> {/* 클래스 종목 : soccer, basketball ---- 종목별 이름 */}

          {/* summary_score */ }
          <div className="summary_score">
            <div className="team left">
              <img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/50_1565928530.png"}/>
                <span className="name">바로 셀로로</span>
                <span className="player">디에고 카스틸로</span>
            </div>
            <div className="info">
              <div className="score">
                <strong className="left on">
                  <em className="num">0</em>
                  <em className="pk">2</em>
                </strong>
                <strong className="right">
                  <em className="num">0</em>
                  <em className="pk">3</em>
                </strong>
              </div>
              <div className="state"><span>9회말</span></div>
              <div className="time">10.22 09:00</div>
              <div className="stadium">글로브 라이프 필드</div>
            </div>
            <div className="team right"><img className="logo" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/14_1550480543.png"}/>
              <div className="name">바로 셀로나</div>
              <span className="player">디에고 카스틸로</span>
            </div>
          </div>
          {/* // summary_score */ }

          {/* detail_score */ }
          <div className="detail_score">
            <ul className="tab">
              <li>
                <button className="selected">스코어</button>
              </li>
              <li>
                <button>상황중계</button>
              </li>
            </ul>

            <div className="match_tracker" style={{ display: 'none' }}>
              <iframe scrolling="no" frameBorder="0" src="https://animations.betcoapps.com/#/widget/animation?region=122&amp;competition=1930&amp;game=17233239&amp;gameid=17233239&amp;amp;lang=kor"></iframe>
              <p className="tip">데이터 반영에 차이가 있을 수 있습니다.</p>
            </div>

            <div className="detail_board" style={{ display: '' }}>
              <div className="team">
                <span>AT 마드리드</span>
                <span>FC 바로셀로나</span>
              </div>
              <div className="basic_board overtime">
                <dl className="item">
                  <dt>전반</dt>
                  <dd>0</dd>
                  <dd>0</dd>
                </dl>
                <dl className="item">
                  <dt>후반</dt>
                  <dd>0</dd>
                  <dd>0</dd>
                </dl>
                <dl className="item on">
                  <dt>연장</dt>
                  <dd>1</dd>
                  <dd>1</dd>
                </dl>
                <dl className="item">
                  <dt>PK</dt>
                  <dd>-</dd>
                  <dd>-</dd>
                </dl>
                <dl className="item r">
                  <dt>합계</dt>
                  <dd>1</dd>
                  <dd>1</dd>
                </dl>
              </div>
            </div>
          </div>
          {/* // detail_score */ }

        </div>
        {/* // score_detail_board */ }

        {/* snb_wrap */ }
        <div className="snb_wrap">
          <ul>
            <li><a className="item selected" href="javascript:;">응원</a></li>
            <li><a className="item" href="javascript:;">전력</a></li>
            <li><a className="item" href="javascript:;">배당</a></li>
            <li><a className="item" href="javascript:;">라인업</a></li>
            <li><a className="item" href="javascript:;">중계</a></li>
          </ul>
        </div>
        {/* // snb_wrap */ }

        {/* cheer_list */ }
        <div className="cheer_list">
          <div className="inner">
            <ul>
              <li className="item_message">
                <a className="lnk_user" href="javascript:;">
                  <span className="thumb lv14">
                    <img src="/images/thumb_user_def.png"/>
                    <svg
                        className="border"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 36 36"
                        enable-background="new 0 0 36 36"
                        xmlSpace="preserve"
                    >
                    <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                    </svg>
                  </span>
                </a>
                <div className="message_balloon">
                  <strong className="name"><img className="cheer_team" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/2.png"}/>건대히어로</strong>
                  <p className="message">동해물과 백두산이 동해물과 백두산이 동해물과 백두산이 동해물과 백두산이 동해물과 백두산이 동해물과 백두산이 동해물과 백두산이 동해물과 백두산이 동해물과 백두산이 동해물과 백두산이 </p>
                </div>
              </li>
              <li className="item_message me">
                <a className="lnk_user" href="javascript:;">
                  <span className="thumb lv14">
                    <img src="/images/thumb_user_def.png"/>
                    <svg
                        className="border"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 36 36"
                        enable-background="new 0 0 36 36"
                        xmlSpace="preserve"
                    >
                    <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                    </svg>
                  </span>
                </a>
                <div className="message_balloon">
                  <strong className="name"><img className="cheer_team" src={Url.thumb + "normal/resize/0x55/sports/baseball/team/2.png"}/>건대히어로2</strong>
                  <p className="message">동해물과 백산이 </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* // cheer_list */ }

        {/* cheer_input_area */ }
        <div className="cheer_input_area">
          <div className="inner">

            <div className="layer_team_option" style={{ display: 'none' }}>
              <input type="radio" id="home_team" name="cheer_team" checked="checked"/>
              <label htmlFor="home_team">
                <img src={Url.thumb + "normal/resize/0x55/sports/baseball/team/16.png"} alt="선택 팀"/>
                바로 셀로나바로 셀로나바로 셀로나바로 셀로나바로 셀로나바로 셀로나
              </label>
              <input type="radio" id="away_team" name="cheer_team"/>
              <label htmlFor="away_team">
                <img src={Url.thumb + "normal/resize/0x55/sports/baseball/team/16.png"} alt="선택 팀"/>
                원정팀
              </label>
            </div>

            <button type="submit" className="btn btn_team_select"> {/* 클래스 : selected */}
              <img src={Url.thumb + "normal/resize/0x55/sports/baseball/team/16.png"} alt="선택 팀"/>
            </button>
            <input type="text" className="cheer_input_box" placeholder="응원 메시지를 입력해주세요."/>
            <button type="submit" className="btn btn_enter">메시지 입력</button> {/* 클래스 : on */}
          </div>
        </div>
        {/* // cheer_input_area */ }

      </div>
      {/* // score_detail_wrap */ }

    </>
  );
};

export default ScoreDetailHtml;
