import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Url } from '../../../../common/constants/environment';
import Seo from '../../../../components/seo';
import { CommentList } from '../../../community/components/comment/list';
import useTypeStore from '../../../../hooks/useTypeStore';
import SportsLoader from '../../../../components/sportsLoader';

const CustomerCenterDetail = observer(({ match }) => {
  const communityStore = useTypeStore('communityStore');
  const { type } = match.params;

  useEffect(() => {
    communityStore.actionDetail({
      wr_id: match.params.id,
      board_type: type
    });
  }, [match.params.id]);

  return (
    <>
      <Seo title={`${type === 'notice' ? '공지사항' : '개발자노트'} 상세`} />
      {!communityStore.detailLoading ? (
        <div className="board_view">
          <div className="view_area">
            <h3 className="blind">게시글</h3>
            <div className="summary">
              <a className="category_cs">{communityStore.result.ca_name}</a>
              <p className="subject">{communityStore.result.wr_subject}</p>
              <p className="article_info">
                <span className="date">{communityStore.result.date}</span>
                <span className="count">
                  조회 {communityStore.result.wr_hit}
                </span>
              </p>
              <div className="user_info">
                <a className="lnk_user">
                  <span
                    className={clsx(
                      'thumb',
                      `lv${communityStore.result.user.level}`
                    )}
                  >
                    <img src={communityStore.result.user.profile_img} />
                    <svg
                      className="border"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 36 36"
                      enableBackground="new 0 0 36 36"
                      xmlSpace="preserve"
                    >
                      <path d="M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z" />
                    </svg>
                  </span>
                  <strong className="name">
                    {communityStore.result.wr_name}
                  </strong>
                  <span className="today">
                    {communityStore.result.user.today_word}
                  </span>
                </a>
              </div>
            </div>
            <div className="article_cont">
              <div
                dangerouslySetInnerHTML={{
                  __html: !!communityStore.result.wr_content
                    ? communityStore.result.wr_content.replace(
                        'http://data.named.com/data',
                        Url.dataThumbUrl
                      )
                    : ''
                }}
              />
            </div>
          </div>
          <CommentList
            item={communityStore.result}
            boardType={type}
            actionCommentAdd={communityStore.actionCommentAdd}
          />
        </div>
      ) : (
        <SportsLoader />
      )}
    </>
  );
});

export default CustomerCenterDetail;
