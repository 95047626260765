import autobind from 'autobind-decorator';
import { Url } from '../constants/environment';

@autobind
class WebSocketUtil {
  constructor(topic) {
    // topic 인자 => array 타입
    this.topic = topic || [];
    this.url = Url.webSocket;
    this.ws = null;

    // 웹푸시 디버깅을 위한 윈도우 객체 생성
    window.push_debug = false;

    this.is_ie = false;
    this.subscribe_callback_list = [];

    // socket connection 상태 값(true : 연결, false : 비연결)
    this.socket_open = false;

    // Check IE
    var trident = navigator.userAgent.match(/Trident\/(\d)/i);
    if (trident != null) {
      this.is_ie = true;
    } else {
      this.is_ie = false;
    }

    this.init();
  }

  /**
   * Initialize
   *
   * @param host 서버 호스트
   * @param port 서버 포트
   * @param callback 서버 연결시 Callback
   */
  init() {
    // 지원여부 체크
    if ('WebSocket' in window) {
      try {
        const reconnect = function () {
          setTimeout(
            function () {
              this.init();
            }.bind(this),
            2000
          );
        }.bind(this);

        this.ws = new WebSocket(this.url);

        this.ws.onopen = (event) => {
          this.socket_open = true;

          if (this.topic.length > 0) {
            this.topic.forEach((data) => {
              this.sub(data);
            });
          }

          if (this.is_ie === true) {
            if (this.ping_handler) {
              clearInterval(this.ping_handler);
            }
            this.ping_handler = setInterval(
              function () {
                this.ping();
              }.bind(this),
              58000
            );
          }
        };

        this.ws.onerror = function (error) {
          this.log('socket error : ' + error);
          if (this.socket_open === false) {
            reconnect();
          }
        }.bind(this);
      } catch (err) {
        console.warn('Failed to connect message queue server : ' + err);
      }
    } else {
      console.warn('Not supported WebSocket');
    }
  }

  /**
   * Sub
   * @param topic 구독명
   * @param callback 구독중인 메세지 전달시 Callback
   */
  sub(topic) {
    this.ws.send(`SUB ${topic}`);
  }

  /**
   * Unsub
   *
   * @param topic 구독명
   */
  unsub(topic) {
    if (this.socket_open) {
      this.ws.send('UNSUB ' + topic);
    }
  }

  /**
   * Unsuball
   */
  unsuball() {
    if (this.socket_open) {
      this.subscribe_callback_list = [];
      this.ws.send('UNSUBALL');
    }
  }

  /**
   * Ping
   */
  ping() {
    if (this.socket_open) {
      this.ws.send('PING');
    }
  }

  /**
   * Debug
   *
   * @param is_debug 디버그 모드 설정 여부
   */
  debug(is_debug) {
    this.debug_mode = is_debug;
  }

  /**
   * Log
   *
   * @param msg 로그 메세지
   */
  log(msg) {
    if (window.push_debug) {
      if (
        typeof window != 'undefined' &&
        window != null &&
        window.console != null
      ) {
        console.log(msg);
      }
    }
  }

  /**
   * socket Close
   */
  async asyncClose() {
    this.ws.close();
    this.ws.onmessage = null;
    this.ws = null;
    // this.subscribe_callback_list = [];
    return await true;
  }

  close(callback) {
    this.ws.close();
    this.ws.onclose = () => {
      this.log('socket closed');
      this.socket_open = false;
      this.ws = null;
      callback();
    };
    this.ws.onmessage = null;
    // this.subscribe_callback_list = [];
  }
}

export default WebSocketUtil;
