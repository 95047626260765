import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../../../context';
import { Url } from '../../../../../common/constants/environment';
import { Button, List, ListItem, Summary, UserInfo, CtaContainer, ButtonAccept } from '../styled';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import useCommonStore from '../../../../../hooks/useCommonStore';
import NoneContent from '../../../../../components/noneContent';
import Pagination from '../../../../../components/pagination';
import { MypageService } from '../../../../../common/services';

const MypageFriendsRecommend = observer(({ tab }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;

  const commonStore = useCommonStore();
  const { mypageStore } = useMypageStore(MypageContext);

  const [loading, setLoading] = useState(true);
  const [processingMbIds, setProcessingMbIds] = useState([]);

  useEffect(() => {
    mypageStore.actionRecommend({ page }).then((res) => {
      setLoading(false);
    });
  }, [page]);

  const onClickSubscribe = async (userId) => {
    if (processingMbIds && processingMbIds.includes(userId)) {
      return;
    }
    setProcessingMbIds([...processingMbIds, userId]);

    try {
      const addFriendResponse = await MypageService.addFriend(userId, page);
      if (addFriendResponse?.code !== 200) {
        commonStore.modalStore.openAlertModal({
          message: addFriendResponse.message,
        });
      } else {
        await mypageStore.actionRecommend({ page });
      }
    } finally {
      setProcessingMbIds(processingMbIds.filter((id) => id !== userId));
    }
  };

  const onClickDeleteRecommendation = async (userId) => {
    if (processingMbIds && processingMbIds.includes(userId)) {
      return;
    }
    setProcessingMbIds([...processingMbIds, userId]);

    try {
      const response = mypageStore.actionRecommendDelete({ page, deleteMbId: userId });
      if (response?.code !== 200) {
        commonStore.modalStore.openAlertModal({
          message: response.message,
        });
      }
    } finally {
      setProcessingMbIds(processingMbIds.filter((id) => id !== userId));
    }
  };

  const totalCount = mypageStore.data.total_cnt;

  return (
    <div>
      {(totalCount < 1 || (mypageStore.data?.list?.filter((item) => item.is_friend !== 'Y') ?? []).length === 0) &&
      !loading ? (
        <NoneContent title={'나를 친구한'} />
      ) : (
        !loading && (
          <>
            <List>
              {mypageStore.data?.list?.map((item, key) => {
                if (!item || item.is_friend === 'Y') return null;

                return (
                  <Item
                    key={key}
                    item={item}
                    onClickSubscribe={onClickSubscribe}
                    onClickDeleteRecommendation={onClickDeleteRecommendation}
                    subscribing={processingMbIds?.includes(item?.mb_id)}
                  />
                );
              })}
            </List>
            <Pagination page={page} tab={tab} totalCount={totalCount} rowCount={10} />
          </>
        )
      )}
    </div>
  );
});

const Item = ({ item, onClickSubscribe, onClickDeleteRecommendation, subscribing }) => {
  const profileImg = item['profile_image_path']
    ? `${Url.thumb}normal/crop/112x112${item['profile_image_path'].replace('data/', '')}`
    : '/images/thumb_user_def.png';

  return (
    <ListItem>
      <UserInfo levelImgUrl={`/images/level/lv${item.friend_profile.level}.svg`}>
        <span className={clsx('thumb', `lv${item.friend_profile.level}`)}>
          <img
            src={profileImg}
            onError={(event) => {
              event.target.src = '/images/thumb_user_def.png';
            }}
          />
          <svg
            className='border'
            version='1.1'
            id='Layer_1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            x='0px'
            y='0px'
            viewBox='0 0 36 36'
            enableBackground='new 0 0 36 36'
            xmlSpace='preserve'
          >
            <path d='M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z' />
          </svg>
        </span>
        <Summary>
          <div>
            <em>{item.mb_nick}</em>
            {item.is_online === 1 ? <strong>접속중</strong> : <span>{item.mb_ahead_days}</span>}
          </div>
          {item.today_word && <p>{item.today_word}</p>}
        </Summary>
      </UserInfo>
      <CtaContainer>
        <Button disabled={subscribing} onClick={() => onClickDeleteRecommendation(item.mb_id)}>
          삭제
        </Button>
        <ButtonAccept disabled={subscribing} onClick={() => onClickSubscribe(item.mb_id)}>
          수락
        </ButtonAccept>
      </CtaContainer>
    </ListItem>
  );
};

export { MypageFriendsRecommend };
