import { observable, runInAction, action } from 'mobx';
import { asyncAction } from 'mobx-utils';
import autobind from 'autobind-decorator';
import CommunityModel from '../../routes/community/store/model';
import { CommunityService } from '../services';

@autobind
class HomeStore {
  modalStore;

  constructor(store) {
    this.modalStore = store.modalStore;
  }

  @observable
  code = 200;

  @observable
  message = '';

  @observable
  totalCount = 0;

  @observable
  analysisItems = [];

  @observable
  sportAnalysisLoading = true;

  @observable
  communityBoardItems = {};

  @observable
  communityBoardLoading = true;

  @observable
  showSearchInput = false;

  @observable
  showMyInfoPane = false;

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  *actionAnalysisList(param) {
    this.sportAnalysisLoading = true;
    this.analysisItems = [];
    const { type, page, search_type, search_content, filter, gameId } = param;

    if (!param) {
      return;
    }
    if (!type) {
      return;
    }

    const res = yield CommunityService.list(type, page, search_type, search_content, filter, gameId);

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
        this.sportAnalysisLoading = false;
      });
      return;
    }

    runInAction(() => {
      this.code = res.code;
      this.totalCount = res.data.total_cnt;
      this.analysisItems = res.data.list.map((item) => new CommunityModel(item));
      this.sportAnalysisLoading = false;
    });
  }

  @asyncAction
  *actionCommunityList(param) {
    this.communityBoardLoading = true;
    const { type, page, search_type, search_content, filter, gameId } = param;

    if (!param) {
      return;
    }
    if (!type) {
      return;
    }

    const res = yield type !== 'best'
      ? CommunityService.list(type, page, search_type, search_content, filter, gameId)
      : CommunityService.best();

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message,
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
        this.communityBoardLoading = false;
      });
      return;
    }

    runInAction(() => {
      this.communityBoardItems[type] = res?.data?.list?.map((item) => new CommunityModel(item));
      this.communityBoardItems = { ...this.communityBoardItems };
      this.communityBoardLoading = false;
    });
  }

  @action
  setShowSearchInput(shown) {
    runInAction(() => {
      this.showSearchInput = shown;
    });
  }

  @action
  setShowMyInfoPane(shown) {
    runInAction(() => {
      this.showMyInfoPane = shown;
    });
  }
}

export default HomeStore;
