import React from 'react';
import styled from 'styled-components';

const NoneContent = ({ title }) => {
  return (
    <Wrap>
      <img src="/images/ic-none-list.svg" />
      <p>{title} 내역이 없습니다.</p>
    </Wrap>
  );
};

const Wrap = styled.div`
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f4;
  img {
    width: 50px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    color: #999;
    margin-top: 15px;
  }
`;

export default NoneContent;
