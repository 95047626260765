import Api from '../api';
import { Url } from '../../constants/environment';

class FxGameService extends Api {
  constructor() {
    super();
  }

  /**
   * @description 리스트
   * @returns
   */
  async list(
    game_type = '',
    pick_type = '',
    date = '',
    limit = '',
    offset = '',
    round = ''
  ) {
    const res = await this.conmonAjax('get', Url.named, `fxgame/pick`, {
      game_type,
      pick_type,
      date,
      limit,
      offset,
      round
    });

    return res;
  }
}

export default new FxGameService();
