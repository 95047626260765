import React, { useState, useEffect } from 'react';
import ProtoCalcView from '../view';

const ProtoCalcGroup = ({ data, battingPicks, tempBattingClear }) => {
  const duplicateDisabled = () => {
    const arr = [];

    data.forEach((item) => {
      arr.push({ id: item.id, isDisabled: false });
    });

    return arr;
  };

  const [checkGroup, setCheckGroup] = useState(duplicateDisabled());

  const onChangeDisabled = (id) => {
    const arr = [];

    data.forEach((item) => {
      if (id === null) {
        arr.push({ id: item.id, isDisabled: false });
      } else {
        arr.push({ id: item.id, isDisabled: item.id === id ? false : true });
      }
    });

    setCheckGroup(arr);
  };

  useEffect(() => {
    if (battingPicks.length === 10) {
      const copy = [...checkGroup];
      const count = copy.filter((item) => item.isDisabled).length;
      const arr = [];

      if (count === 0) {
        data.forEach((item) => {
          const checkId = battingPicks.filter(
            (pick) => item.game.id === pick.gameId
          )[0];

          if (!!checkId) {
            arr.push({
              id: item.id,
              isDisabled: checkId.roundGameId === item.id ? false : true
            });
          } else {
            arr.push({
              id: item.id,
              isDisabled: true
            });
          }
        });

        setCheckGroup(arr);
      }
    } else {
      const copy = [...checkGroup];
      const count = copy.filter((item) => item.isDisabled).length;

      if (copy.length === count) {
        setCheckGroup(duplicateDisabled());
      }
    }
  }, [battingPicks, data]);

  useEffect(() => {
    if (tempBattingClear) {
      setCheckGroup(duplicateDisabled());
    }
  }, [tempBattingClear]);

  if (data.length > 0) {
    return data.map((item) => {
      return (
        <ProtoCalcView
          item={item}
          key={item.id}
          isDisabled={checkGroup.filter((a) => a.id === item.id)[0].isDisabled}
          onChangeDisabled={onChangeDisabled}
        />
      );
    });
  } else {
    return null;
  }
};

export default ProtoCalcGroup;
