import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

export const H2 = styled.h2`
  margin: 20px 0px 10px 0px;
  font-size: 15px;
`;

export const P = styled.p`
  margin: 4px 0px;
  font-size: 13px;
`;

export const Ol = styled.ol`
  margin-block-start: 0px;
  padding-inline-start: 0px;
`;

export const Ol2 = styled.ol`
  margin-block-start: 0px;
  padding-inline-start: 10px;
`;

export const Li = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  font-size: 13px;
`;
