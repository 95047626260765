import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useLocalStore } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CommunityService } from '../../../common/services';
import Pagination from '../../../components/pagination';
import useModalStore from '../../../hooks/useModalStore';
import CommunityModel from '../../community/store/model';
import Seo from '../../../components/seo';

const CustomerCenterDevelop = observer(() => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;
  const modalStore = useModalStore();
  const store = useLocalStore(() => ({
    boardNotice: [],
    list: [],
    loading: false,
    totalCount: 0,
    async getNotice(page) {
      store.loading = true;

      const res = await CommunityService.list('develop', page, '', '', '');

      if (res.code !== 200) {
        modalStore.openAlertModal({
          message: res.message
        });
      } else {
        store.code = res.code;
        store.totalCount = res.data.total_cnt;
        store.boardNotice = res.data.board_notice.map(
          (item) => new CommunityModel(item)
        );
        store.list = res.data.list.map((item) => new CommunityModel(item));
        window.scrollTo(0, 0);
      }
    }
  }));

  useEffect(() => {
    store.getNotice(page);
  }, [page]);

  return (
    <div className="board_wrap">
      <Seo title={'고객센터 개발자노트'} />
      <div className="board_list">
        <div className="list_area">
          {!store.isLoading &&
            store.boardNotice.length > 0 &&
            store.boardNotice.map((item) => (
              <Row item={item} key={item.wr_id} isBoardNotice={true} />
            ))}
          {!store.isLoading &&
            store.list.length > 0 &&
            store.list.map((item) => (
              <Row item={item} key={item.wr_id} isBoardNotice={false} />
            ))}
        </div>
        <Pagination page={page} totalCount={store.totalCount} />
      </div>
    </div>
  );
});

const Row = ({ item, isBoardNotice }) => {
  const { time, wr_id, wr_subject, ca_name, wr_name, wr_hit } = item;

  return (
    <div className="article">
      <Link className="item" to={`/customercenter/develop/${wr_id}`}>
        <p className="subject">{wr_subject}</p>
        <p className="info">
          <span className={clsx('category', isBoardNotice && 'notice_top')}>
            {ca_name}
          </span>
          <span className="name">{wr_name}</span>
          <span className="date">{time}</span>
          <span className="count">조회 {wr_hit}</span>
        </p>
      </Link>
    </div>
  );
};

export default CustomerCenterDevelop;
