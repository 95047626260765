import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';

const Pw2SetResult = observer(({ history, location }) => {
  const { mypageStore } = useMypageStore(MypageContext);
  const queryString = new URLSearchParams(location.search);
  const [password, setPassword] = useState('');
  const [passwordRe, setPasswordRe] = useState('');

  const onSubmit = () => {
    mypageStore.actionSecondPasswordChange({
      certifyToken: queryString.get('certify_token'),
      password,
      passwordRe
    });
  };

  const goInfo = () => {
    history.replace('/mypage/info');
    //usersStore.getInfo();
  };

  return (
    <>
      <input
        type="password"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      비밀번호 입력
      <br />
      <input
        type="password"
        onChange={(e) => {
          setPasswordRe(e.target.value);
        }}
      />
      비밀번호 재입력
      <br />
      <button onClick={onSubmit}>비밀번호 재설정</button>
      <br />
      <button onClick={goInfo}>취소</button>
      <br />
    </>
  );
});

export default Pw2SetResult;
