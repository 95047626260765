import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../../../context';
import { List } from './styled';
import Pagination from '../../../../../components/pagination';
import NoneContent from '../../../../../components/noneContent';

const MypageGiftList = observer(({ tab, category, page }) => {
  const { mypageStore } = useMypageStore(MypageContext);

  useEffect(() => {
    if (category === 'to') {
      mypageStore.actionSendItemList({ page });
    } else {
      mypageStore.actionReceiveItemsList({ page });
    }
  }, [category, page]);

  return (
    <div>
      {mypageStore.data.total_cnt < 1 ? (
        <NoneContent title={category === 'to' ? '보낸선물' : '받은선물'} />
      ) : (
        <>
          <List>
            {mypageStore.data.list.map((item, key) => {
              return (
                <li key={key}>
                  <div>
                    <em>{item.nick}</em>
                    <span>{item.reg_dt}</span>
                  </div>
                  <span>{item.name}</span>
                </li>
              );
            })}
          </List>
          <Pagination
            page={page}
            totalCount={mypageStore.data.total_cnt}
            rowCount={10}
            tab={tab}
            category={category}
          />
        </>
      )}
    </div>
  );
});

export { MypageGiftList };
