import Api from '../../api';
import { Url } from '../../../constants/environment';

class HoldemService extends Api {
  constructor() {
    super();
    this.URL = 'game/holdem';
  }

  async getHoldemRecentResult() {
    const res = await this.commonAjax('get', Url.named, `${this.URL}/recent`);

    return res;
  }

  async getHoldemResult() {
    const res = await this.commonAjax(
      'get',
      Url.named,
      `${this.URL}/stat/daily`
    );

    return res;
  }
}

export default new HoldemService();
