import { observable, computed, action, runInAction, toJS } from 'mobx';
import autobind from 'autobind-decorator';
import { asyncAction } from 'mobx-utils';
import { getDeletedGames, getConcatList, setDefaultList } from './util';
import AbstractScoreCommon from '../../store/common';
import WebSocketUtil from '../../../../common/utils/socketUtil';
import { _SPORTS_WS_TOPIC } from '../../../../common/constants/sportsConstants';
import { ScoreService } from '../../../../common/services';
import SoccerModel from '../../soccer/store/model';
import BaseballModel from '../../baseball/store/model';
import BasketballModel from '../../basketball/store/model';
import VolleyballModel from '../../volleyball/store/model';
import HockeyModel from '../../hockey/store/model';
import FootballModel from '../../football/store/model';
@autobind
class MajorStore extends AbstractScoreCommon {
  socket = null;
  isSub = false;

  @observable
  sportsView = {
    soccer: [],
    baseball: [],
    basketball: [],
    volleyball: [],
    hockey: [],
    football: []
  };

  @observable
  loading = false;

  // keyword is what the user types in the search bar
  // id is the id of the game when users click on the game in suggestion list
  @observable
  searchData = {
    keyword: '',
    id: undefined,
    type: undefined
  }

  constructor() {
    super();
  }

  connectSocket(topic, type = null) {
    this.socket = new WebSocketUtil(topic);

    this.socket.ws.onmessage = (event) => {
      if (event) {
        let data = event.data;

        try {
          if (data === 'OK') {
            this.isSub = true;
          } else {
            let messageData = JSON.parse(data);

            if (messageData) {
              const { TOPIC, MESSAGE } = messageData;

              if (TOPIC.includes('sports')) {
                const data = JSON.parse(MESSAGE);
                const { sportsView } = this;
                const sportsTypeKeyArr = Object.keys(sportsView);
                let index = -1;

                if (TOPIC.includes('cheers')) {
                  sportsTypeKeyArr.forEach((key) => {
                    if (data.hasOwnProperty('game_id')) {
                      index = sportsView[key].findIndex(
                        (item) => item.id === parseInt(data.game_id)
                      );
                    }
                    if (Boolean(~index)) {
                      sportsView[key][index].cheerCount++;
                    }
                  });
                } else {
                  sportsTypeKeyArr.forEach((key) => {
                    if (data.hasOwnProperty('gameId')) {
                      index = sportsView[key].findIndex(
                        (item) => item.id === data.gameId
                      );
                    }

                    if (TOPIC.includes(key)) {
                      [
                        ..._SPORTS_WS_TOPIC['soccer'],
                        ..._SPORTS_WS_TOPIC['baseball'],
                        ..._SPORTS_WS_TOPIC['basketball'],
                        ..._SPORTS_WS_TOPIC['volleyball'],
                        ..._SPORTS_WS_TOPIC['hockey']
                      ].forEach((item) => {
                        if (TOPIC === `${item.topic}`) {
                          if (Boolean(~index)) {
                            if (TOPIC.includes('games')) {
                              if (
                                key === 'basketball' ||
                                key === 'volleyball'
                              ) {
                                sportsView[key][index][item.funcName](
                                  data[item.key]
                                );
                              } else {
                                sportsView[key][index][item.funcName](
                                  data[item.key]
                                );
                              }
                            } else if (TOPIC.includes('period-data')) {
                              if (
                                key === 'basketball' ||
                                key === 'volleyball'
                              ) {
                                sportsView[key][index][item.funcName](
                                  data[item.key]
                                );
                              } else {
                                sportsView[key][index][item.funcName](
                                  data[item.key]
                                );
                              }
                            } else {
                              sportsView[key][index][item.funcName](
                                data[item.key]
                              );
                            }
                          }
                        }
                      });
                    }
                  });
                }
              }
            }
          }
        } catch (error) {
          console.log('=== socket on message error ===', error);
        }
      }
    };
  }

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get majorList() {
    const { sportsView } = this;
    const sportsTypeKeyArr = Object.keys(sportsView);
    const deleteSelectedGames = getDeletedGames(sportsTypeKeyArr, sportsView);
    const list = getConcatList(deleteSelectedGames, 'major');

    return setDefaultList(list, 'major');
  }

  @computed
  get filteredMajorList() {
    if ((this.searchData.keyword === '' || !this.searchData.keyword) && this.searchData.id === undefined) {
      return this.majorList;
    } else if (this.searchData.id !== undefined) {
      return new Map(
        [...this.majorList.entries()]
          .map(([key, value]) => {
            const filteredList = value.list.filter(item => item.id === this.searchData.id);
            return filteredList.length > 0 ? [key, { ...value, list: filteredList }] : null;
          })
          .filter(Boolean)
      );
    } else {
      return Object.fromEntries(
        Object.entries(this.majorList)
          .map(([key, value]) => {
            const filteredList = value.list.filter((item) => {
              const homeTeam = item.getTeamData.home.name;
              const awayTeam = item.getTeamData.away.name;
              const displayString = `${homeTeam} vs ${awayTeam}`;
              return displayString.toLowerCase().includes(this.searchData.keyword.toLowerCase());
            });
            return filteredList.length > 0 ? [key, { list: filteredList }] : null;
          })
          .filter(Boolean)
      );
    }
  }

  /**********************************************************
   * actions
   **********************************************************/
  @action
  closeSocket() {
    if (!!this.socket) {
      this.socket.close(() => {
        runInAction(() => {
          this.isSub = false;
          this.socket = null;
        });
      });
    }
  }

  @action
  setSearchData({ keyword, id, type }) {
    this.searchData = { keyword, id, type };
  }

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  *getMajor(date) {
    this.loading = true;

    try {
      // TODO: 웹소켓;
      if (!this.isSub) {
        yield this.connectSocket(
          [
            ..._SPORTS_WS_TOPIC['soccer'],
            ..._SPORTS_WS_TOPIC['baseball'],
            ..._SPORTS_WS_TOPIC['basketball'],
            ..._SPORTS_WS_TOPIC['volleyball'],
            ..._SPORTS_WS_TOPIC['hockey']
          ].map((item) => item.topic)
          // _SPORTS_WS_TOPIC['major'].map((item) => item.topic)
        );
      }

      const result = yield ScoreService.getPopularGames(date);

      if (result) {
        let copySportsView = { ...this.sportsView };

        Object.keys(result).forEach((key) => {
          if (key !== 'tennis') {
            copySportsView[key] = result[key].map((item) => {
              if (key === 'soccer') {
                return new SoccerModel(item);
              } else if (key === 'baseball') {
                return new BaseballModel(item);
              } else if (key === 'basketball') {
                return new BasketballModel(item);
              } else if (key === 'volleyball') {
                return new VolleyballModel(item);
              } else if (key === 'hockey') {
                return new HockeyModel(item);
              } else if (key === 'football') {
                return new FootballModel(item);
              }
            });
          }
        });

        this.sportsView = copySportsView;
        this.loading = false;
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export default MajorStore;
