import React, { useState, useEffect } from 'react';
import { CommunityRow } from './row';
import { useLocation } from 'react-router-dom';
import useUsersStore from '../../../../hooks/useUsersStore';
import BannerGA from '../../../../components/banner/BannerGA';

const CommunityList = ({ items = [] }) => {
  const userStore = useUsersStore();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;
  const category = location.pathname.replace('/community/', '');

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (items.length > 0) {
      setList(items);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [items]);

  return (
    <div className="list_area">
      {!isLoading &&
        list.map((item, i) => {
          return (

<>
            <CommunityRow
              key={item.wr_id + i}
              item={item}
              page={page}
              category={category}
              isAdmin={userStore.data.level === 'mp'}
            />

            { category !== 'best' && category !== 'sports_analysis' && i === 4 && (
              <BannerGA
                containerStyle={{
                  display: 'block',
                  width: 450,
                  height: 50,
                  marginTop: 16,
                }}
                gaSlot={'5303472846'}
                width={450}
                height={50}
              />
            )}

</>       
          );
        })}
    </div>
  );
};

export { CommunityList };
