import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import { MypageTpList } from '../components';
import Pagination from '../../../components/pagination';
import { useLocation, Link } from 'react-router-dom';
import { RegexUtil } from '../../../common/utils/regexUtil';
import useModalStore from '../../../hooks/useModalStore';
import NoneContent from '../../../components/noneContent';
import Seo from '../../../components/seo';

const TradePoint = observer(() => {
  const { mypageStore } = useMypageStore(MypageContext);
  const location = useLocation();
  const modalStore = useModalStore();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;
  const totalCount = mypageStore.data.total_cnt;

  useEffect(() => {
    mypageStore.actionTradePointList({ page });
  }, [page]);

  const onClickRefillTp = () => {
    modalStore.openConfirmModal({
      message: `무료 TP 충전을 하시겠습니까?`,
      okCallback: () => {
        mypageStore.actionTradePointRefill(() => {
          mypageStore.actionTradePointList(1);
        });
      }
    });
  };

  return (
    <>
      <Seo title={'마이페이지 TP'} />
      <div className="history_wrap tp">
        <div className="summary">
          <dl className="amass">
            <dt>보유 트레이딩 포인트</dt>
            <dd>{RegexUtil.makeComma(mypageStore.data.total_tp)}</dd>
          </dl>
          <dl className="today">
            <dt>오늘 획득 포인트</dt>
            <dd>{RegexUtil.makeComma(mypageStore.data.today_point)}</dd>
          </dl>
        </div>
        <div className="list_area">
          <div className="tip_area">
            <p className="tx">※ 최대 3개월 이내의 내역만 확인 가능합니다.</p>
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="btn_charge"*/}
            {/*  onClick={onClickRefillTp}*/}
            {/*>*/}
            {/*  충전하기*/}
            {/*</button>*/}
          </div>
          {mypageStore.data.total_cnt < 1 ? (
            <NoneContent title={'TP'} />
          ) : (
            <MypageTpList items={mypageStore.data.list} />
          )}
        </div>
        <Pagination page={page} totalCount={totalCount} rowCount={10} />
      </div>
    </>
  );
});

export default TradePoint;
