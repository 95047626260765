import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageService, UsersService } from '../../common/services';
import { Link, useLocation } from 'react-router-dom';
import {
  Buttons,
  CloseButton,
  Comment,
  Content,
  Header,
  Image,
  ListWrap,
  List,
  UserInfo,
  Wrap,
  TabWrap,
  Tab,
  NoneContent,
} from './styled';
import { Url } from '../../common/constants/environment';
import { StringUtil } from '../../common/utils/stringUtil';
import clsx from 'clsx';
import useUsersStore from '../../hooks/useUsersStore';
import useCommonStore from '../../hooks/useCommonStore';

const initMemberData = {
  board_count: 0,
  board_list: [],
  sports_analysis_board_list: [],
  crime_count: 0,
  level: 0,
  profile_image_path: '',
  level_title: '',
  nick: '',
  reg_dt: '',
  today_word: '',
  background_image_path: '',
};

const User = observer(({ match, history }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const headerRef = useRef(null);
  const { userId } = match.params;
  const commonStore = useCommonStore();
  const usersStore = useUsersStore();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [memberData, setMemberData] = useState(initMemberData);
  const [headerHeight, setHeaderHeight] = useState(220);
  const [tab, setTab] = useState(query.get('tab') || 'analysis');

  useEffect(() => {
    if (userId) {
      UsersService.userFindById(userId).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          setMemberData(data);
          if (tab === 'analysis') {
            setList(data.sports_analysis_board_list);
          } else {
            setList(data.board_list);
          }
        } else if (code === 400) {
          commonStore.modalStore.openAlertModal({
            message: res.message,
            callback: () => {
              history.goBack();
            },
          });
        }
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight + 45);
  }, [usersStore.data.mb_id]);

  const onClickBlack = () => {
    if (is_black === 'Y') {
      commonStore.modalStore.openConfirmModal({
        message: `블랙리스트에서 해제하시겠습니다?`,
        okCallback: () => {
          commonStore.actionBlackDelete({ deleteMbId: userId }).then((res) => {
            if (res) {
              setMemberData({
                ...memberData,
                is_black: 'N',
              });
            }
          });
        },
      });
    } else {
      commonStore.modalStore.openConfirmModal({
        message: `블랙리스트에 추가하시겠습니까?`,
        okCallback: () => {
          commonStore.actionBlackAdd({ mbNick: nick }).then((res) => {
            if (res) {
              setMemberData({
                ...memberData,
                is_black: 'Y',
              });
            }
          });
        },
      });
    }
  };

  const onClickSubscribe = async () => {
    let res = {};
    if (is_friend === 'Y') {
      res = await MypageService.friendDelete(userId);
    } else {
      res = await MypageService.addFriend(userId);
    }
    if (res.code === 200) {
      setMemberData({
        ...memberData,
        is_friend: is_friend === 'Y' ? 'N' : 'Y',
      });
    } else {
      commonStore.modalStore.openAlertModal({
        message: res.message,
      });
    }
  };

  const {
    nick,
    level,
    profile_image_path,
    today_word,
    reg_dt,
    crime_count,
    board_list,
    sports_analysis_board_list,
    is_black,
    background_image_path,
    is_friend,
  } = memberData;

  return (
    <Wrap>
      <Header
        ref={headerRef}
        imagePath={background_image_path ? `${Url.thumb}normal/resize/origin${background_image_path}` : ''}
      >
        <CloseButton type={'button'} onClick={() => history.goBack()} />
        <UserInfo>
          <div>
            <em>{nick}</em>
            <p>{today_word}</p>
            <div>
              <span>{crime_count < 1 ? '전과없음' : `전과 ${crime_count}범`}</span>
              <span>{StringUtil.replace('-', '.', reg_dt.split(' ')[0])} 가입</span>
            </div>
          </div>
          <span className={clsx('thumb', `lv${level}`)}>
            <img
              src={
                profile_image_path
                  ? `${Url.thumb}normal/crop/112x112${profile_image_path.replace('data/', '')}`
                  : '/images/thumb_user_def.png'
              }
              onError={(event) => {
                event.target.src = '/images/thumb_user_def.png';
              }}
            />
            <svg
              className='border'
              version='1.1'
              id='Layer_1'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              x='0px'
              y='0px'
              viewBox='0 0 36 36'
              enableBackground='new 0 0 36 36'
              xmlSpace='preserve'
            >
              <path d='M18,2c10.9,0,16,5.1,16,16c0,10.9-5.1,16-16,16C7.1,34,2,28.9,2,18C2,7.1,7.1,2,18,2 M18,0C6,0,0,6,0,18c0,12,6,18,18,18c12,0,18-6,18-18C36,6,30,0,18,0L18,0z' />
            </svg>
          </span>
        </UserInfo>
        {userId !== usersStore.data.mb_id && (
          <Buttons>
            <button type={'button'} onClick={onClickSubscribe}>
              친구{is_friend === 'Y' ? '취소' : '하기'}
            </button>
            <button type={'button'} onClick={onClickBlack}>
              블랙리스트{is_black === 'Y' && ' 해제'}
            </button>
          </Buttons>
        )}
      </Header>
      <TabWrap>
        <Tab
          selected={tab === 'analysis'}
          onClick={() => {
            setTab('analysis');
            setList(sports_analysis_board_list);
            history.replace({
              search: 'tab=analysis',
            });
          }}
        >
          스포츠 분석
        </Tab>
        <Tab
          selected={tab === 'community'}
          onClick={() => {
            setTab('community');
            setList(board_list);
            history.replace({
              search: 'tab=community',
            });
          }}
        >
          커뮤니티
        </Tab>
      </TabWrap>
      <ListWrap headerHeight={headerHeight}>
        {list.length === 0 && !loading ? (
          <NoneContent>
            <p>작성된 게시글이 없습니다.</p>
          </NoneContent>
        ) : (
          <List>
            {list.map((item, key) => {
              return <Item key={key} item={item} tab={tab} />;
            })}
          </List>
        )}
      </ListWrap>
    </Wrap>
  );
});

const Item = ({ item, tab }) => {
  const isAnalysis = tab === 'analysis';

  const getCreatedAd = () => {
    return item.wr_datetime;
  };

  const sportsType = useMemo(() => {
    switch (item.sports_id) {
      case '1':
        return 'soccer';
      case '2':
        return 'baseball';
      case '3':
        return 'basketball';
      case '4':
        return 'volleyball';
      case '5':
        return 'hockey';
      case '6':
        return 'starcraft';
      case '7':
        return 'football';
      case '9':
        return 'lol';
      default:
        return '';
    }
  }, [tab]);

  return (
    <li>
      <Link
        to={
          isAnalysis
            ? `/community/sports_analysis?id=${item.wr_id}&type=sports_analysis&gameId=${item.game_id}&sportsType=${sportsType}&sportsId=${item.sports_id}`
            : `/community/${item.bo_table}?id=${item.wr_id}&type=${item.bo_table}`
        }
      >
        <Content>
          <div className={'subject'}>
            {isAnalysis && `${item.home_team} vs ${item.away_team} `}
            {isAnalysis && <br />}
            {item.wr_subject}
          </div>
          <span>{`${isAnalysis ? item.ca_name : item.table_name} ${getCreatedAd()} 조회 ${item.wr_hit}`}</span>
        </Content>
        {item.is_photo === '1' && (
          <Image imgUrl={`${Url.thumb}normal/croptop/112x112/file/${item.bo_table}/${item.wr_id}`} />
        )}
        <Comment>
          <em>{item.wr_comment}</em>댓글
        </Comment>
      </Link>
    </li>
  );
};

export default User;
