
import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

// const MobXProviderContext = createContext({})

// function Provider(props) {
//     const { children, ...stores } = props
//     const parentValue = useContext(MobXProviderContext)
//     const mutableProviderRef = useRef({ ...parentValue, ...stores })
//     const value = mutableProviderRef.current

//     // if (__DEV__) {
//     //     const newValue = { ...value, ...stores } // spread in previous state for the context based stores
//     //     // if (!shallowEqual(value, newValue)) {
//     //     //     throw new Error(
//     //     //         "MobX Provider: The set of provided stores has changed. See: https://github.com/mobxjs/mobx-react#the-set-of-provided-stores-has-changed-error."
//     //     //     )
//     //     // }
//     // }

//     return <MobXProviderContext.Provider value={value}>{children}</MobXProviderContext.Provider>
// }

const useStore = () => {
  return useContext(MobXProviderContext);
  // console.log(stores)
  // return {...stores}
};

export default useStore;
