import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MypageContext, useMypageStore } from '../context';
import { MypageExpList } from '../components';
import { timeUtils } from '../../../common/utils/timeUtil';
import { RegexUtil } from '../../../common/utils/regexUtil';
import Pagination from '../../../components/pagination';
import { useLocation, Link } from 'react-router-dom';
import NoneContent from '../../../components/noneContent';
import Seo from '../../../components/seo';

const Exp = observer(() => {
  const { mypageStore } = useMypageStore(MypageContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page')) || 1;
  const totalCount = mypageStore.data.total_cnt;

  useEffect(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);

    const startDt = timeUtils.dateFormatYmd(date);
    const endDt = timeUtils.dateFormatYmd(new Date());

    mypageStore.actionExp({ startDt, endDt, page });
  }, [page]);

  return (
    <>
      <Seo title={'마이페이지 경험치'} />
      <div className="history_wrap exp">
        <div className="summary">
          <dl className="amass">
            <dt>누적 경험치</dt>
            <dd>{RegexUtil.makeComma(mypageStore.data.total_exp)}</dd>
          </dl>
          <dl className="today">
            <dt>오늘 획득 경험치</dt>
            <dd>{RegexUtil.makeComma(mypageStore.data.today_exp)}</dd>
          </dl>
        </div>
        <div className="list_area">
          <div className="tip_area">
            <p className="tx">※ 최대 3개월 이내의 내역만 확인 가능합니다.</p>
          </div>
        </div>
        {mypageStore.data.total_cnt < 1 ? (
          <NoneContent title={'획득한 경험치'} />
        ) : (
          <>
            <MypageExpList items={mypageStore.data.list} />
            <Pagination page={page} totalCount={totalCount} rowCount={10} />
          </>
        )}
      </div>
    </>
  );
});

export default Exp;
