// This function will copy the session of mobile web (session_id) to pc web (PHPSESSID).
import cookie from "./cookie"
import { getChatHostname } from './hostnameUtil';

export const copySessionToPcWeb = () => {
    const session_id = cookie.getCookie('session_id');
    const PHPSESSID = cookie.getCookie('PHPSESSID');

    const SESSION_EXPIRE_SECOND = 60 * 60 * 24 * 14;

    if (session_id && !PHPSESSID) {

        const { chatHostName } = getChatHostname();

        const isSecure = window.location.protocol === 'https:';
        if (isSecure) {
            document.cookie = `PHPSESSID=${session_id}; expires=${new Date(Date.now() + SESSION_EXPIRE_SECOND * 1000).toUTCString()}; domain=${'.' + chatHostName}; path=/; SameSite=None; secure;`;
        } else {
            document.cookie = `PHPSESSID=${session_id}; expires=${new Date(Date.now() + SESSION_EXPIRE_SECOND * 1000).toUTCString()}; domain=${'.' + chatHostName}; path=/;`;
        }
    }
}