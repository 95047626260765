import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import publicIp from 'public-ip';
import { MypageContext, useMypageStore } from '../context';
import useUsersStore from '../../../hooks/useUsersStore';

const IpAdd = observer(({ history }) => {
  const usersStore = useUsersStore();
  const { mypageStore } = useMypageStore(MypageContext);
  const [autoIp, setAutoIp] = useState('');
  const [name, setName] = useState('');
  const [ip, setIp] = useState(['', '', '', '']);

  const onTabChange = (e) => {
    if (e.target.value === '2') {
      setIp(['', '', '', '']);
    } else {
      setIp(autoIp.split('.'));
    }
  };

  const onAdd = () => {
    let status = true;
    ip.forEach((item) => {
      if (!item) {
        status = false;
      }
    });

    mypageStore.actionIpAdd({ name, ip: status ? ip.join('.') : '' }, () =>
      usersStore.getInfo()
    );
  };

  const onCancel = () => {
    history.goBack();
  };

  const onChangeIp = (e, index) => {
    let newIp = ip.map((item, i) => {
      if (index === i) {
        return e.target.value;
      }

      return item;
    });

    setIp(newIp);
  };

  useEffect(() => {
    publicIp.v4().then((res) => {
      setAutoIp(res);
      setIp(res.split('.'));
    });
  }, []);

  return (
    <div>
      <ul>
        <li style={{ float: 'left', marginLeft: '10px' }}>
          <input
            type="radio"
            id="type1"
            name="type"
            value="1"
            onChange={onTabChange}
            defaultChecked={true}
          />
          <label htmlFor="type1">자동입력</label>
        </li>
        <li style={{ float: 'left', marginLeft: '10px' }}>
          <input
            type="radio"
            id="type2"
            name="type"
            value="2"
            onChange={onTabChange}
          />
          <label htmlFor="type2">수동입력</label>
        </li>
      </ul>
      <div style={{ clear: 'both' }}>
        <input
          type="text"
          value={!!ip[0] ? ip[0] : ''}
          onChange={(e) => onChangeIp(e, 0)}
        />
        &nbsp;
        <input
          type="text"
          value={!!ip[1] ? ip[1] : ''}
          onChange={(e) => onChangeIp(e, 1)}
        />
        &nbsp;
        <input
          type="text"
          value={!!ip[2] ? ip[2] : ''}
          onChange={(e) => onChangeIp(e, 2)}
        />
        &nbsp;
        <input
          type="text"
          value={!!ip[3] ? ip[3] : ''}
          onChange={(e) => onChangeIp(e, 3)}
        />
      </div>
      <div style={{ clear: 'both' }}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <button type="button" onClick={onAdd}>
          추가하기
        </button>
        &nbsp;&nbsp;&nbsp;
        <button type="button" onClick={onCancel}>
          취소
        </button>
      </div>
    </div>
  );
});

export default IpAdd;
