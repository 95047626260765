import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { timeUtils } from '../../../../../../common/utils/timeUtil';
import { useLocation } from 'react-router-dom';
import { LotteryService } from '../../../../../../common/services';

const valueNameMap = {
  ODD: '홀',
  EVEN: '짝',
  UNDER: '언더',
  OVER: '오버',
  L: '대',
  M: '중',
  S: '소',
  ODDUNDER: '홀언',
  ODDOVER: '홀오',
  EVENUNDER: '짝언',
  EVENOVER: '짝오',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
};

const todayDate = timeUtils.todayYmd();

const PatternGraph = ({ gameName, patternTypeList }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type = query.get('type') || 'daily';
  const option = query.get('option') || 'oneDay';
  const startDate = query.get('start_dt') || todayDate;
  const round = parseInt(query.get('round')) || 100;

  const [count, setCount] = useState(0);
  const [patternType, setPatternType] = useState(gameName === 'dh_powerball' ? 'sum_odd_even' : 'powerball_odd_even');
  const [data, setDate] = useState({
    count: {},
    pattern: [],
  });

  useEffect(() => {
    let params = {};

    if (option === 'oneDay') {
      params = { startDate: startDate, endDate: startDate };
    } else if (option === 'latest') {
      params = { round: round };
    }

    params.gameType = gameName;
    params.patternType = patternType;

    LotteryService.getLotteryStatPattern({ ...params }).then((res) => {
      setDate(res.data);
    });
  }, [option, patternType, startDate, round]);

  useEffect(() => {
    return () => {
      setPatternType(patternTypeList[0].value);
      setCount(0);
    };
  }, [type]);

  let pongdang = 0;
  let oneRepeatCount = 0;
  const sequence = {};
  const patternListComponent = [];
  (data.pattern ?? []).map((item, key) => {
    // 꺽임
    if (sequence[item.value]) {
      sequence[item.value] = Math.max(sequence[item.value], item.round.length);
    } else {
      sequence[item.value] = item.round.length;
    }

    // 최대 퐁당
    if (oneRepeatCount > 0 && item.round.length === 1) {
      oneRepeatCount++;
      if (oneRepeatCount > pongdang) {
        pongdang = oneRepeatCount;
      }
    } else {
      oneRepeatCount = 1;
    }

    if (count > 0 && item.round.length !== count) {
      return;
    }

    patternListComponent.push(
      <dl key={key} className='default'>
        <dt className={clsx(item.value)}>{valueNameMap[item.value]}</dt>
        {item.round.map((round, key) => {
          return (
            <dd key={key}>
              <span className={clsx('ic', item.value)}>{round}</span>
            </dd>
          );
        })}
        <dd className='length'>{item.count}</dd>
        <dd className='times'>{item.index}</dd>
      </dl>
    );
  });

  const patternSummaryComponent = useMemo(() => {
    switch (patternType) {
      case 'powerball_under_over':
        return (
          <div className='bs'>
            <dl className='ic UNDER'>
              <dt>언더</dt>
              <dd>
                {data.count.powerball_under_count}번 ({data.count.powerball_under_percent}%) {sequence.UNDER}연속
              </dd>
            </dl>
            <dl className='ic OVER'>
              <dt>오버</dt>
              <dd>
                {data.count.powerball_over_count}번 ({data.count.powerball_over_percent}%) {sequence.OVER}연속
              </dd>
            </dl>
          </div>
        );
      case 'powerball_section':
        return (
          <div className='bs'>
            <dl className='ic A'>
              <dt>A</dt>
              <dd>
                {data.count.powerball_a_section_count}번 ({data.count.powerball_a_section_percent}%) {sequence.A}연속
              </dd>
            </dl>
            <dl className='ic B'>
              <dt>B</dt>
              <dd>
                {data.count.powerball_b_section_count}번 ({data.count.powerball_b_section_percent}%) {sequence.B}연속
              </dd>
            </dl>
            <dl className='ic C'>
              <dt>C</dt>
              <dd>
                {data.count.powerball_c_section_count}번 ({data.count.powerball_c_section_percent}%) {sequence.C}연속
              </dd>
            </dl>
            <dl className='ic D'>
              <dt>D</dt>
              <dd>
                {data.count.powerball_d_section_count}번 ({data.count.powerball_d_section_percent}%) {sequence.D}연속
              </dd>
            </dl>
          </div>
        );
      case 'powerball_odd_even_under_over':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀언</dt>
              <dd>
                {data.count.powerball_odd_under_count}번 ({data.count.powerball_odd_under_percent}%) {sequence.ODDUNDER}
                연속
              </dd>
            </dl>
            <dl className='ic ODD'>
              <dt>홀오</dt>
              <dd>
                {data.count.powerball_odd_over_count}번 ({data.count.powerball_odd_over_percent}%) {sequence.ODDOVER}
                연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝언</dt>
              <dd>
                {data.count.powerball_even_under_count}번 ({data.count.powerball_even_under_percent}%){' '}
                {sequence.EVENUNDER}
                연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝오</dt>
              <dd>
                {data.count.powerball_even_over_count}번 ({data.count.powerball_even_over_percent}%) {sequence.EVENOVER}
                연속
              </dd>
            </dl>
          </div>
        );
      case 'sum_odd_even':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀</dt>
              <dd>
                {data.count.sum_odd_count}번 ({data.count.sum_odd_percent}%) {sequence.ODD}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝</dt>
              <dd>
                {data.count.sum_even_count}번 ({data.count.sum_even_percent}%) {sequence.EVEN}연속
              </dd>
            </dl>
          </div>
        );
      case 'sum_under_over':
        return (
          <div className='bs'>
            <dl className='ic UNDER'>
              <dt>언더</dt>
              <dd>
                {data.count.sum_under_count}번 ({data.count.sum_under_percent}%) {sequence.UNDER}연속
              </dd>
            </dl>
            <dl className='ic OVER'>
              <dt>오버</dt>
              <dd>
                {data.count.sum_over_count}번 ({data.count.sum_over_percent}%) {sequence.OVER}연속
              </dd>
            </dl>
          </div>
        );
      case 'sum_size':
        return (
          <div className='bs'>
            <dl className='ic L'>
              <dt>대</dt>
              <dd>
                {data.count.sum_large_count}번 ({data.count.sum_large_percent}%) {sequence.L}연속
              </dd>
            </dl>
            <dl className='ic M'>
              <dt>중</dt>
              <dd>
                {data.count.sum_medium_count}번 ({data.count.sum_medium_percent}
                %) {sequence.M}연속
              </dd>
            </dl>
            <dl className='ic S'>
              <dt>소</dt>
              <dd>
                {data.count.sum_small_count}번 ({data.count.sum_small_percent}%) {sequence.S}연속
              </dd>
            </dl>
          </div>
        );
      case 'sum_odd_even_under_over':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀언</dt>
              <dd>
                {data.count.sum_odd_under_count}번 ({data.count.sum_odd_under_percent}%) {sequence.ODDUNDER}
                연속
              </dd>
            </dl>
            <dl className='ic ODD'>
              <dt>홀오</dt>
              <dd>
                {data.count.sum_odd_over_count}번 ({data.count.sum_odd_over_percent}%) {sequence.ODDOVER}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝언</dt>
              <dd>
                {data.count.sum_even_under_count}번 ({data.count.sum_even_under_percent}%) {sequence.EVENUNDER}
                연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝오</dt>
              <dd>
                {data.count.sum_even_over_count}번 ({data.count.sum_even_over_percent}%) {sequence.EVENOVER}
                연속
              </dd>
            </dl>
          </div>
        );
      case 'sum_odd_even_size':
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀대</dt>
              <dd>
                {data.count.sum_odd_large_count}번 ({data.count.sum_odd_large_percent}%) {sequence.ODDL}연속
              </dd>
            </dl>
            <dl className='ic ODD'>
              <dt>홀중</dt>
              <dd>
                {data.count.sum_odd_medium_count}번 ({data.count.sum_odd_medium_percent}%) {sequence.ODDM}연속
              </dd>
            </dl>
            <dl className='ic ODD'>
              <dt>홀소</dt>
              <dd>
                {data.count.sum_odd_small_count}번 ({data.count.sum_odd_small_percent}%) {sequence.ODDS}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝대</dt>
              <dd>
                {data.count.sum_even_large_count}번 ({data.count.sum_even_large_percent}%) {sequence.EVENL}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝중</dt>
              <dd>
                {data.count.sum_even_medium_count}번 ({data.count.sum_even_medium_percent}%) {sequence.EVENM}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝소</dt>
              <dd>
                {data.count.sum_even_small_count}번 ({data.count.sum_even_small_percent}%) {sequence.EVENS}연속
              </dd>
            </dl>
          </div>
        );
      default:
        return (
          <div className='bs'>
            <dl className='ic ODD'>
              <dt>홀</dt>
              <dd>
                {data.count.powerball_odd_count}번 ({data.count.powerball_odd_percent}%) {sequence.ODD}연속
              </dd>
            </dl>
            <dl className='ic EVEN'>
              <dt>짝</dt>
              <dd>
                {data.count.powerball_even_count}번 ({data.count.powerball_even_percent}%) {sequence.EVEN}연속
              </dd>
            </dl>
          </div>
        );
    }
  }, [patternType, sequence]);

  return (
    <div className='data_pattern_board_wrap'>
      <h3 className='tit'>매 통계</h3>
      <div className='option_type'>
        <select value={count} onChange={(event) => setCount(parseInt(event.target.value))}>
          <option value={0}>기본</option>
          <option value={1}>1매</option>
          <option value={2}>2매</option>
          <option value={3}>3매</option>
          <option value={4}>4매</option>
          <option value={5}>5매</option>
          <option value={6}>6매</option>
        </select>
      </div>
      <div className='option_type'>
        <select value={patternType} onChange={(event) => setPatternType(event.target.value)}>
          {patternTypeList.map((item, key) => {
            return (
              <option key={key} value={item.value}>
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className='pattern_data'>
        <div className='inner'>{patternListComponent}</div>
      </div>
      <div className='pattern_summary'>
        {patternSummaryComponent}
        <dl className='etc'>
          <dt>퐁당 :</dt>
          <dd>{pongdang}번</dd>
          <dt>꺽임 :</dt>
          <dd>{data.pattern ? data.pattern.length : ''}번</dd>
        </dl>
      </div>
    </div>
  );
};

export default PatternGraph;
