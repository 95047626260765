import { action, observable, runInAction, toJS } from 'mobx';
import { asyncAction } from 'mobx-utils';
import autobind from 'autobind-decorator';
import CommunityModel from './model';
import CommunityDetailModel from './model/detail';
import { CommunityService } from '../../../common/services';

const _INIT_DETAIL = {
  ca_name: '',
  comment: [],
  is_best: '0',
  mb_id: '',
  member_info: {
    gender: null,
    level: '0',
    level_title: '',
    nick_color: null,
    profile_img: null,
    today_word: ''
  },
  wr_comment: '',
  wr_content: '',
  wr_datetime: '',
  wr_good: '',
  wr_hit: '',
  wr_id: '',
  wr_is_comment: '',
  wr_name: '',
  wr_subject: '',
  is_good: false
};

@autobind
class CommunityStore {
  modalStore;

  constructor(store) {
    this.modalStore = store.modalStore;
  }

  @observable
  code = 200;

  @observable
  message = '';

  @observable
  totalCount = 0;

  @observable
  boardNotice = [];

  @observable
  items = [];

  @observable
  result = _INIT_DETAIL;

  @observable
  loading = true;

  @observable
  detailLoading = true;

  /**********************************************************
   * computed
   **********************************************************/
  // @computed
  // get getResponse() {
  //   return {
  //     code: 200,
  //   };
  // }

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  *actionList(param) {
    this.loading = true;
    this.items = [];
    const { type, page, search_type, search_content, filter, gameId } = param;

    if (!param) {
      return;
    }
    if (!type) {
      return;
    }

    const res = yield CommunityService.list(
      type,
      page,
      search_type,
      search_content,
      filter,
      gameId
    );

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
      return;
    }

    runInAction(() => {
      this.code = res.code;
      this.totalCount = res.data.total_cnt;
      this.boardNotice = res.data.board_notice.map(
        (item) => new CommunityModel(item)
      );
      this.items = res.data.list.map((item) => new CommunityModel(item));
      this.loading = false;
    });
  }

  @asyncAction
  *actionMoreList(param) {
    const { type, page, search_type, search_content, filter } = param;

    if (!param) {
      return;
    }

    if (!type) {
      return;
    }

    const res = yield CommunityService.list(
      type,
      page,
      search_type,
      search_content,
      filter
    );

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
      return;
    }

    // console.log('res = ', res);

    runInAction(() => {
      const addList = res.data.list.map((item) => new CommunityModel(item));

      this.code = res.code;
      this.totalCount = res.data.total_cnt;
      this.items = [...this.items, ...addList];
    });
  }

  @asyncAction
  *actionBestList(param) {
    this.loading = true;
    this.items = [];
    const { page } = param;

    if (!param) {
      return;
    }

    const res = yield CommunityService.best(page);

    if (res.code !== 200) {
      alert(res.message);

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
      return;
    }

    runInAction(() => {
      this.code = res.code;
      this.totalCount = res.data.total_cnt;
      this.items = res.data.list.map((item) => new CommunityModel(item));
      this.loading = false;
    });
  }

  @asyncAction
  *actionRecentList(param) {
    this.loading = true;
    this.items = [];
    const { page } = param;

    if (!param) {
      return;
    }

    const res = yield CommunityService.recent(page);

    if (res.code !== 200) {
      alert(res.message);

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
      return;
    }

    runInAction(() => {
      this.code = res.code;
      this.totalCount = res.data.total_cnt;
      this.items = res.data.list.map((item) => new CommunityModel(item));
      this.loading = false;
    });
  }

  @asyncAction
  *actionBestMoreList(param) {
    const { page } = param;

    if (!param) {
      return;
    }

    const res = yield CommunityService.best(page);

    if (res.code !== 200) {
      alert(res.message);

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
      return;
    }

    runInAction(() => {
      const addList = res.data.list.map((item) => new CommunityModel(item));

      this.code = res.code;
      this.totalCount = res.data.total_cnt;
      this.items = [...this.items, ...addList];
    });
  }

  @asyncAction
  *actionSave(param) {
    const {
      boardType,
      subject,
      content,
      isNotice,
      commentAllowed,
      caName,
      id,
      gameId,
      sportsId
    } = param;

    if (!param) {
      return;
    }
    if (!boardType || !subject || !content) {
      return;
    }

    let res;
    if (!!id) {
      res = yield CommunityService.modify(
        id,
        boardType,
        subject,
        content,
        isNotice,
        commentAllowed,
        caName,
        gameId,
        sportsId
      );
    } else {
      res = yield CommunityService.save(
        boardType,
        subject,
        content,
        isNotice,
        commentAllowed,
        caName,
        gameId,
        sportsId
      );
    }

    if (res.code !== 200) {
      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
      return;
    }

    runInAction(() => {
      this.code = res.code;
    });
  }

  @asyncAction
  *actionDelete(param) {
    const { id, boardType } = param;

    if (!param) {
      return;
    }
    if (!boardType || !id) {
      return;
    }

    let res;
    res = yield CommunityService.delete(id, boardType);

    if (res.code !== 200) {
      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    } else {
      runInAction(() => {
        this.code = res.code;
      });
    }

    return res;
  }

  @asyncAction
  *actionDetail(param) {
    this.result = _INIT_DETAIL;
    this.detailLoading = true;

    const { wr_id, board_type } = param;

    if (!param) {
      return;
    }
    if (!wr_id || !board_type) {
      return;
    }

    const res = yield CommunityService.detail(wr_id, board_type);

    if (res.code !== 200) {
      alert(res.message);

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
        this.detailLoading = false;
      });
      window.location.href = '/community/best';
      return;
    }

    runInAction(() => {
      this.code = res.code;
      this.result = new CommunityDetailModel(res.data);
      this.detailLoading = false;
    });
  }

  @asyncAction
  *getCommentAsign(param) {
    const { wr_id, board_type } = param;

    if (!param) {
      return;
    }
    if (!wr_id || !board_type) {
      return;
    }

    const res = yield CommunityService.detail(wr_id, board_type);

    if (res.code !== 200) {
      alert(res.message);

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
        this.detailLoading = false;
      });
      return;
    }

    runInAction(() => {
      this.code = res.code;

      if (res.data.hasOwnProperty('comment')) {
        this.result.commentReGenerator(res.data.comment || []);
      }
    });
  }

  @asyncAction
  *actionRecommend(param) {
    const { wr_id, board_type } = param;

    if (!param) {
      return;
    }
    if (!wr_id || !board_type) {
      return;
    }

    const res = yield CommunityService.recommend(wr_id, board_type);

    if (res.code !== 200) {
      alert(res.message);

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    runInAction(() => {
      this.code = res.code;
      this.result.wr_good = parseInt(this.result.wr_good) + 1;
      this.result.is_good = true;
    });
  }

  @asyncAction
  *actionCommentAdd(param) {
    const { wr_id, board_type, comment, commentType } = param;

    if (!comment) {
      this.modalStore.openAlertModal({
        message: '내용을 입력하세요.'
      });
      return;
    }

    if (!param) {
      return;
    }
    if (!wr_id || !board_type || !comment || !commentType) {
      return;
    }

    const res = yield CommunityService.commentAdd(
      wr_id,
      board_type,
      comment,
      commentType
    );

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    runInAction(() => {
      this.code = res.code;
    });

    yield this.getCommentAsign({ wr_id, board_type });
  }

  @asyncAction
  *postReCommentAdd(param) {
    const { wr_id, board_type, comment, commentType, commentId } = param;

    const res = yield CommunityService.reCommentAdd(
      wr_id,
      board_type,
      comment,
      commentType,
      commentId
    );

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    runInAction(() => {
      this.code = res.code;
    });

    yield this.getCommentAsign({ wr_id, board_type });
  }

  @asyncAction
  *putCommentModify(param) {
    const { wr_id, board_type, comment, commentId } = param;

    if (!comment) {
      this.modalStore.openAlertModal({
        message: '내용을 입력하세요.'
      });
      return;
    }

    if (!param) {
      return;
    }
    if (!wr_id || !board_type || !comment) {
      return;
    }

    const res = yield CommunityService.commentModify(
      wr_id,
      board_type,
      comment,
      commentId
    );

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    runInAction(() => {
      this.code = res.code;
    });

    yield this.getCommentAsign({ wr_id, board_type });
  }

  @asyncAction
  *deleteComment(param) {
    const { wr_id, board_type, commentId } = param;

    if (!param) {
      return;
    }
    if (!wr_id || !board_type || !commentId) {
      return;
    }

    const res = yield CommunityService.commentDelete(
      wr_id,
      board_type,
      commentId
    );

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }

    runInAction(() => {
      this.code = res.code;
    });

    yield this.getCommentAsign({ wr_id, board_type });
  }

  @asyncAction
  *actionReport(param) {
    const { category, relId, relTable, targetId, type, content } = param;

    if (!param) {
      return;
    }

    const res = yield CommunityService.report({
      category,
      relId,
      relTable,
      targetId,
      type,
      content
    });

    if (res.code !== 200) {
      runInAction(() => {
        this.code = res.code;
        this.modalStore.openAlertModal({
          message: res.message
        });
      });
      return;
    } else {
      this.modalStore.openAlertModal({
        message: '신고가 접수되었습니다.'
      });
    }

    runInAction(() => {
      this.code = res.code;
    });
  }
}

export default CommunityStore;
