import React, { useEffect, useState } from 'react';
import {
  QuantityContainer,
  QuantityRow,
  QuantityLabel,
  QuantityControlGroup,
  DecrementButton,
  QuantityInput,
  IncrementButton,
  MaxQuantity,
  GiftCountDesc,
} from './styled';

export default function QuantitySelect({ count, maxGiftCount, maxCount, onQuantityChange }) {
  const [numberOfItems, setNumberOfItems] = useState(count);

  const handleQuantityChange = React.useCallback(
    (e) => {
      const value = Number.parseInt(e.target.value);
      if (isNaN(value)) {
        setNumberOfItems(1);
      } else {
        // Ensure quantity is at least 1 and optionally limited by maxGiftCount
        const newValue = Math.max(1, maxGiftCount ? Math.min(value, maxGiftCount) : value);
        setNumberOfItems(newValue);
      }
    },
    [maxGiftCount]
  );

  useEffect(() => {
    if (onQuantityChange) {
      onQuantityChange(numberOfItems);
    }
  }, [numberOfItems, onQuantityChange]);

  return (
    <QuantityContainer>
      <QuantityRow>
        <QuantityLabel htmlFor='quantity'>선물갯수:</QuantityLabel>
        <QuantityControlGroup>
          <DecrementButton
            type='button'
            onClick={() => setNumberOfItems(Math.max(1, numberOfItems - 1))}
            disabled={numberOfItems <= 1}
          >
            <span>-</span>
          </DecrementButton>
          <QuantityInput
            id='quantity'
            type='number'
            min='1'
            max={maxGiftCount || ''}
            value={numberOfItems}
            onChange={handleQuantityChange}
          />
          <IncrementButton
            type='button'
            onClick={() =>
              setNumberOfItems(maxGiftCount ? Math.min(maxGiftCount, numberOfItems + 1) : numberOfItems + 1)
            }
            disabled={maxGiftCount ? numberOfItems >= maxGiftCount : false}
          >
            <span>+</span>
          </IncrementButton>
        </QuantityControlGroup>
        {maxGiftCount && <MaxQuantity>/ {maxGiftCount}개</MaxQuantity>}
      </QuantityRow>
      {maxCount !== maxGiftCount ? (
        <GiftCountDesc>
          총 수량: {maxCount}개. &nbsp;&nbsp;선물 가능: {maxGiftCount}개.
        </GiftCountDesc>
      ) : null}
    </QuantityContainer>
  );
}
