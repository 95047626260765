import autobind from 'autobind-decorator';
import { computed, observable } from 'mobx';
import { RegexUtil } from '../../../../../common/utils/regexUtil';
import ProtoMyPickDetailItemModel from './item';

@autobind
class ProtoMyPickDetailModel {
  @observable
  displayName = '';

  @observable
  totalGameCount = 0;

  @observable
  betPoint = 0;

  @observable
  totalOdds = 0;
  
  @observable
  gameStartFlag = false;
  
  @observable
  totalPickStatus = "UNKNOWN";

  @observable
  polls = [];

  constructor(data) {
    if (data) {
      this.displayName = data.displayName;
      this.totalGameCount = data.totalGameCount;
      this.totalOdds = data.totalOdds;
      this.gameStartFlag = data.gameStartFlag;
      this.totalPickStatus = data.totalPickStatus;

      if (data.picks.length > 0) {
        this.betPoint = data.picks[0].betPoint;
        this.polls = data.picks[0].polls.map(
          (item) => new ProtoMyPickDetailItemModel(item)
        );
      }
    }
  }

  @computed
  get status() {
    const { gameStartFlag, totalPickStatus } = this;

    if (!gameStartFlag) {
      return { text: '대기중', class: 'wait' };
    } else {
      if (totalPickStatus === 'HIT') {
        return { text: '적중', class: 'win' };
      } else {
        return { text: '미적중', class: 'lose' };
      }
    }
  }

  @computed
  get totalAmount() {
    const { totalOdds, betPoint } = this;

    const result = totalOdds * betPoint;

    return `${RegexUtil.makeComma(Math.round(result))}원`;
  }
}

export default ProtoMyPickDetailModel;
