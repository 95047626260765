import React from 'react';
import {Url} from "../../common/constants/environment";

const AcountHtml = () => {
  return (
    <>
      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">이메일 인증</h2>
          <a className="btn_prev" href="javascript:;">
            이전으로
          </a>
        </div>
        <div className="sleeper_area">
          <div className="email_auth">
            <p>
              <strong>named@named.com</strong>(으)로 장기 미접속 상태 해제를
              위한 인증 메일이 발송되었습니다.
            </p>
            <p>
              메일을 10분 이내에 확인한 후 장기 미접속 상태 헤제 링크를 클릭하면
              상태를 변경하실 수 있습니다.
            </p>
          </div>
          <div className="btn_area">
            <button type="submit" className="btn btn_submit">
              메일 확인하러 가기
            </button>
          </div>
          <div className="tip">
            <p>
              메일을 받지 못하셨나요?{' '}
              <a className="lnk" href="javascript:;">
                인증메일 재전송
              </a>
            </p>
          </div>
        </div>
      </div>

      <hr style={{ margin: '20px 0' }} />

      {/* <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">장기 미접속 계정</h2>
          <a className="btn_prev" href="javascript:;">
            이전으로
          </a>
        </div>
        <div className="sleeper_area">
          <div className="inner">
            <dl className="info">
              <dt>장기 미접속 상태 해제를 위한 본인인증</dt>
              <dd>
                <p>
                  회원님은 6개월 이상 접속하지 않아 장기 미접속 계정으로
                  전환되었습니다.
                </p>
                <p>
                  장기 미접속 계정 상태에서는 서비스 이용이 불가하오니 아래의
                  인증을 진행해주세요. 본인인증 이후에는 장기 미접속 상태를 해제
                  하고 재사용하실 수 있습니다.
                </p>
              </dd>
            </dl>
            <div className="certification">
              <a className="btn btn_phone" href="javascript:;">
                휴대폰 인증
              </a>
              <a className="btn btn_ipin" href="javascript:;">
                아이핀 인증
              </a>
              <a className="btn btn_email" href="javascript:;">
                이메일 인증
              </a>
            </div>
          </div>
          <div className="tip">
            <p>
              회원님의 소중한 개인정보를 보호하기 위해 정보통신망 이용촉진 및
              정보보호 등에 관한 법률 제29조(시행령 제16조)에 의거하여 회원님의
              개인정보를 기존 회원님들의 개인정보와 분리하여 별도로 저장·관리 중
              입니다.
            </p>
          </div>
        </div>
      </div> */}

      <hr style={{ margin: '20px 0' }} />

      {/* <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">비밀번호 재설정</h2>
          <a className="btn_prev" href="javascript:;">
            이전으로
          </a>
        </div>
        <div className="reset_area">
          <div className="inner">
            <p className="tx">새로운 비밀번호를 설정해 주세요.</p>
            <div className="item">
              <input
                type="password"
                className="inputbox"
                autoComplete="off"
                tabIndex="2"
                title="비밀번호"
                placeholder="비밀번호를 입력하세요."
              />
              <p className="error">
                비밀번호는 최소4자리, 최대20자리 까지 입력 가능합니다.
              </p>
            </div>
            <div className="item">
              <input
                type="password"
                className="inputbox"
                autoComplete="off"
                tabIndex="3"
                title="비밀번호"
                placeholder="비밀번호를 한번 더 입력하세요."
              />
              <p className="error">비밀번호가 일치하지 않습니다.</p>
            </div>
          </div>
          <div className="btn_area">
            <button type="submit" className="btn_submit">
              비밀번호 재설정 완료
            </button>
          </div>
        </div>
      </div> */}

      <hr style={{ margin: '20px 0' }} />

      <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">비밀번호 재설정</h2>
          <a className="btn_prev" href="javascript:;">
            이전으로
          </a>
        </div>
        <div className="reset_area">
          <div className="email_auth">
            <p>
              <strong>named@named.com</strong>(으)로 비밀번호 재설정 메일이
              발송되었습니다.
            </p>
            <p>
              메일을 10분 이내에 확인한 후 비밀번호 재설정 링크를 클릭하면
              비밀번호를 변경하실 수 있습니다.
            </p>
          </div>
          <div className="btn_area">
            <button type="submit" className="btn_submit">
              메일 확인하러 가기
            </button>
          </div>
          <div className="tip">
            <p>
              메일을 받지 못하셨나요?{' '}
              <a className="lnk" href="javascript:;">
                인증메일 재전송
              </a>
            </p>
          </div>
        </div>
      </div>

      <hr style={{ margin: '20px 0' }} />

      {/* <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">비밀번호 재설정</h2>
          <a className="btn_prev" href="javascript:;">
            이전으로
          </a>
        </div>
        <div className="reset_area">
          <div className="certification">
            <a className="btn btn_phone" href="javascript:;">
              휴대폰 인증
            </a>
            <a className="btn btn_ipin" href="javascript:;">
              아이핀 인증
            </a>
            <a className="btn btn_email" href="javascript:;">
              이메일 인증
            </a>
          </div>
          <div className="tip">
            <p>본인 확인이 가능한 인증을 선택해주세요.</p>
          </div>
        </div>
      </div> */}

      <hr style={{ margin: '20px 0' }} />

      {/* <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">비밀번호 재설정</h2>
          <a className="btn_prev" href="javascript:;">
            이전으로
          </a>
        </div>
        <div className="reset_area">
          <div className="inner">
            <p className="tx">회원가입시 사용한 이메일 주소를 입력해주세요.</p>
            <div className="item">
              <input
                type="text"
                className="inputbox"
                autoComplete="off"
                tabIndex="1"
                title="이메일"
                placeholder="이메일을 입력하세요."
              />
              <p className="error">이메일이 잘못 입력되었습니다.</p>
            </div>
          </div>
          <div className="btn_area">
            <button type="submit" className="btn_submit">
              다음단계
            </button>
          </div>
        </div>
      </div> */}

      <hr style={{ margin: '20px 0' }} />

      {/* <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">아이디 찾기</h2>
          <a className="btn_prev" href="javascript:;">
            이전으로
          </a>
        </div>
        <div className="find_area">
          <div className="result">
            <p className="tx">인증정보와 일치하는 아이디(이메일) 입니다.</p>
            <p className="email">named@named.com</p>
  </div> */}
      {/* <!-- */}
      {/* <div className="result">
          <p className="tx">인증정보와 일치하는 일치하는 아이디(이메일)가 없습니다.</p>
      </div> */}
      {/* --> */}
      {/* <div className="btn_area">
            <a className="btn btn_login" href="javascript:;">
              로그인 하기
            </a>
            <a className="btn btn_reset" href="javascript:;">
              비밀번호 재설정
            </a>
          </div>
          <div className="tip">
            <p>
              찾으시는 정보를 일치하는 이아디가 없다면 다른 방법으로 아이디
              찾기를 해보세요.
            </p>
          </div>
        </div>
      </div> */}

      <hr style={{ margin: '20px 0' }} />

      {/* <div className="account_info_wrap">
        <div className="nav">
          <h2 className="tit">아이디 찾기</h2>
          <a className="btn_prev" href="javascript:;">
            이전으로
          </a>
        </div>
        <div className="find_area">
          <div className="certification">
            <a className="btn btn_phone" href="javascript:;">
              휴대폰 인증
            </a>
            <a className="btn btn_ipin" href="javascript:;">
              아이핀 인증
            </a>
          </div>
          <div className="tip">
            <p>
              본인인증을 거친 고객님의 경우 해당되는 방법으로,
              중복가입확인정보(DI) 값으로 검색합니다.
            </p>
          </div>
        </div>
      </div> */}

      <hr style={{ margin: '20px 0' }} />

      {/* <div className="lr_wrap">
        <h1 className="logo">
          <a href="javascript:;">네임드</a>
        </h1>
        <div className="register_form">
          <form>
            <fieldset>
              <legend>회원가입</legend>
              <div className="item">
                <input
                  type="text"
                  id="login_email"
                  className="inputbox"
                  autoComplete="off"
                  tabIndex="1"
                  title="이메일"
                  placeholder="이메일을 입력하세요."
                />
                <p className="error">이메일이 잘못 입력되었습니다.</p>
              </div>
              <div className="item">
                <input
                  type="password"
                  className="inputbox"
                  autoComplete="off"
                  tabIndex="2"
                  title="비밀번호"
                  placeholder="비밀번호를 입력하세요."
                />
                <p className="error">
                  비밀번호는 최소4자리, 최대20자리 까지 입력 가능합니다.
                </p>
              </div>
              <div className="item">
                <input
                  type="password"
                  className="inputbox"
                  autoComplete="off"
                  tabIndex="3"
                  title="비밀번호"
                  placeholder="비밀번호를 한번 더 입력하세요."
                />
                <p className="error">비밀번호가 일치하지 않습니다.</p>
              </div>
              <input
                type="checkbox"
                id="agree_terms"
                className="checkbox blind"
                tabIndex="4"
                autoComplete="off"
              />
              <label for="agree_terms" className="checkbox_label">
                <a className="lnk" href="javascript:;">
                  이용약관
                </a>{' '}
                및{' '}
                <a className="lnk" href="javascript:;">
                  개인정보 수집 및 이용안내
                </a>{' '}
                동의
              </label>

              <div className="terms_area">
                <div className="terms">
                  <div className="hd">
                    <h5 className="tit">이용약관</h5>
                    <a className="btn_close" href="javascript:;">
                      닫기
                    </a>
                  </div>
                  <div className="bd">
                    회사는 서비스의 가입 과정에서 회원에게 본 약관을 제시하고,
                    서비스 홈페이지에 본 약관을 게시합니다.
                  </div>
                </div>
                <div className="terms">
                  <div className="hd">
                    <h5 className="tit">개인정보 수집 및 이용안내</h5>
                    <a className="btn_close" href="javascript:;">
                      닫기
                    </a>
                  </div>
                  <div className="bd">
                    회사는 서비스의 가입 과정에서 회원에게 본 약관을 제시하고,
                    서비스 홈페이지에 본 약관을 게시합니다.
                  </div>
                </div>
              </div>

              <button
                type="submit"
                tabIndex="5"
                title="회원가입"
                className="btn btn_submit"
              >
                회원가입
              </button>
            </fieldset>
          </form>
          <div className="lr_lnk">
            이미 네임드 회원이시라면?{' '}
            <a className="lnk" href="javascript:;">
              로그인 하러가기
            </a>
          </div>
        </div>
      </div> */}

      <hr style={{ margin: '20px 0' }} />

      {/* <div className="lr_wrap">
        <h1 className="logo">
          <a href="javascript:;">네임드</a>
        </h1>
        <div className="login_form">
          <form>
            <fieldset>
              <legend>로그인</legend>
              <div className="item">
                <input
                  type="text"
                  className="inputbox"
                  autoComplete="off"
                  tabIndex="1"
                  title="이메일"
                  placeholder="이메일을 입력하세요."
                />
                <p className="error">이메일을 입력해주세요.</p>
              </div>
              <div className="item">
                <input
                  type="password"
                  className="inputbox"
                  autoComplete="off"
                  tabIndex="2"
                  title="비밀번호"
                  placeholder="비밀번호를 입력하세요."
                />
                <p className="error">비밀번호를 입력해주세요.</p>
              </div>
              <button
                type="submit"
                tabIndex="3"
                title="로그인"
                className="btn btn_submit"
              >
                로그인
              </button>
              <input
                type="checkbox"
                id="keep_login"
                className="checkbox blind"
                tabIndex="4"
                autoComplete="off"
              />
              <label for="keep_login" className="checkbox_label">
                로그인 상태 유지
              </label>
            </fieldset>
          </form>
          <div className="lr_lnk">
            <a className="lnk" href="javascript:;">
              아이디 찾기
            </a>
            <a className="lnk" href="javascript:;">
              비밀번호 재설정
            </a>
            <a className="lnk" href="javascript:;">
              간편 회원가입
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AcountHtml;
