import React from 'react';
import autobind from 'autobind-decorator';
import PromptStore from '../../../../../src/common/stores/prompt';
import {Url} from "../../../../common/constants/environment";

@autobind
class AbstractScoreAudioPrompt {
  sportsType = 'soccer';
  audio = null;
  audioId = null;

  constructor() {}

  checkPrompt() {
    const { scoreSettings, location } = window;
    const { sportsType } = this;
    let type = sportsType;
    let sportsTypeLower = type.toLowerCase();

    if (sportsTypeLower === 'icehockey') {
      sportsTypeLower = 'hockey';
    }

    if (!!scoreSettings) {
      if (location.pathname.includes('major')) {
        return scoreSettings['major']['prompt'];
      } else {
        return scoreSettings[sportsTypeLower]['prompt'];
      }
    } else {
      return false;
    }
  }

  addPrompt(id, home, away, location, league, statusText) {
    if (this.checkPrompt()) {
      PromptStore.addNotification({
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 50000000,
          onScreen: true
        },
        sportsType: this.sportsType,
        leagueName: league.name,
        location,
        eventTime: statusText,
        home,
        away,
        id: id + performance.now()
      });
    }
  }

  checkAudio() {
    const { scoreSettings, location } = window;
    const { sportsType } = this;
    let type = sportsType;

    if (location.pathname.includes('major')) {
      return scoreSettings['major']['audio'];
    } else {
      // return scoreSettings[type.toLowerCase()]['audio'];
      return false;
    }
  }

  setAudio(id) {
    const el = document.getElementById('root');
    const audio = document.createElement('audio');
    const source = document.createElement('source');

    audio.classList.add(`score-audio-${id}`);
    audio.setAttribute('volume', 1);
    audio.setAttribute('autoplay', true);
    audio.setAttribute('controls', false);
    audio.setAttribute('preload', 'metadata');
    audio.setAttribute('hidden', true);
    audio.setAttribute('width', 1);
    audio.setAttribute('height', 1);

    source.setAttribute(
      'src',
      `${Url.namedWeb}public/sound/sports_sound.mp3`
    );
    source.setAttribute('type', 'audio/mp3');

    audio.append(source);
    el.append(audio);

    this.audio = audio;
  }

  playAudio(id) {
    if (this.checkAudio()) {
      const audioId = id + performance.now();
      this.audioId = audioId;
      this.setAudio(audioId);

      setTimeout(() => {
        this.clearAudio();
      }, 5000);
    }
  }

  clearAudio() {
    const { audio, audioId } = this;
    const el = document.getElementById('root');
    const audioEl = document.getElementsByClassName(
      `score-audio-${audioId}`
    )[0];

    if (!!audio && audioId && audioEl) {
      el.removeChild(audioEl);
    }
  }
}

export default AbstractScoreAudioPrompt;
