import React from 'react';
import clsx from 'clsx';
import { MathUtil } from '../../../../../../common/utils/mathUtil';

const PatternBarChart = ({ percent = {}, title = '사다리' }) => {
  if (Object.keys(percent).length === 0) {
    return null;
  }
  const { start_point, line_count, odd_even, combination } = percent;

  return (
    <div className="data_summary">
      <h3 className="tit">{title} 통계</h3>
      <div className="chart">
        <dl
          className={clsx(
            'bar',
            'LEFT',
            parseInt(start_point.left_cnt) > parseInt(start_point.right_cnt) &&
              'on'
          )}
        >
          <dt>좌</dt>
          <dd>
            <span className="num">{start_point.left_cnt}</span>
            <span className="per">({start_point.left_ratio}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'RIGHT',
            parseInt(start_point.left_cnt) < parseInt(start_point.right_cnt) &&
              'on'
          )}
        >
          <dt>우</dt>
          <dd>
            <span className="num">{start_point.right_cnt}</span>
            <span className="per">({start_point.right_ratio}%)</span>
          </dd>
        </dl>
      </div>
      <div className="chart">
        <dl
          className={clsx(
            'bar',
            'LEFT',
            parseInt(line_count.three_cnt) > parseInt(line_count.four_cnt) &&
              'on'
          )}
        >
          <dt>3</dt>
          <dd>
            <span className="num">{line_count.three_cnt}</span>
            <span className="per">({line_count.three_ratio}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'RIGHT',
            parseInt(line_count.three_cnt) < parseInt(line_count.four_cnt) &&
              'on'
          )}
        >
          <dt>4</dt>
          <dd>
            <span className="num">{line_count.four_cnt}</span>
            <span className="per">({line_count.four_ratio}%)</span>
          </dd>
        </dl>
      </div>
      <div className="chart">
        <dl
          className={clsx(
            'bar',
            'ODD',
            parseInt(odd_even.odd_cnt) > parseInt(odd_even.even_cnt) && 'on'
          )}
        >
          <dt>홀</dt>
          <dd>
            <span className="num">{odd_even.odd_cnt}</span>
            <span className="per">({odd_even.odd_ratio}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'EVEN',
            parseInt(odd_even.odd_cnt) < parseInt(odd_even.even_cnt) && 'on'
          )}
        >
          <dt>짝</dt>
          <dd>
            <span className="num">{odd_even.even_cnt}</span>
            <span className="per">({odd_even.even_ratio}%)</span>
          </dd>
        </dl>
      </div>
      <div className="chart column4">
        <dl
          className={clsx(
            'bar',
            'LEFT4ODD',
            MathUtil.onlyMaxNumber([
              combination.l4o_cnt,
              combination.l3e_cnt,
              combination.r3o_cnt,
              combination.r4e_cnt
            ]) === parseInt(combination.l4o_cnt) && 'on'
          )}
        >
          <dt>좌4홀</dt>
          <dd>
            <span className="num">{combination.l4o_cnt}</span>
            <span className="per">({combination.l4o_ratio}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'LEFT3EVEN',
            MathUtil.onlyMaxNumber([
              combination.l4o_cnt,
              combination.l3e_cnt,
              combination.r3o_cnt,
              combination.r4e_cnt
            ]) === parseInt(combination.l3e_cnt) && 'on'
          )}
        >
          <dt>좌3짝</dt>
          <dd>
            <span className="num">{combination.l3e_cnt}</span>
            <span className="per">({combination.l3e_ratio}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'RIGHT3ODD',
            MathUtil.onlyMaxNumber([
              combination.l4o_cnt,
              combination.l3e_cnt,
              combination.r3o_cnt,
              combination.r4e_cnt
            ]) === parseInt(combination.r3o_cnt) && 'on'
          )}
        >
          <dt>우3홀</dt>
          <dd>
            <span className="num">{combination.r3o_cnt}</span>
            <span className="per">({combination.r3o_ratio}%)</span>
          </dd>
        </dl>
        <dl
          className={clsx(
            'bar',
            'RIGHT4EVEN',
            MathUtil.onlyMaxNumber([
              combination.l4o_cnt,
              combination.l3e_cnt,
              combination.r3o_cnt,
              combination.r4e_cnt
            ]) === parseInt(combination.r4e_cnt) && 'on'
          )}
        >
          <dt>우4짝</dt>
          <dd>
            <span className="num">{combination.r4e_cnt}</span>
            <span className="per">({combination.r4e_ratio}%)</span>
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default PatternBarChart;
