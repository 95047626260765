import {Url} from "../../../constants/environment";
import Api from "../../api";

class BannersService extends Api {
  constructor() {
    super();
  }

  /**
   * @description 내정보 가져오기
   * @returns
   */
  async getList() {
    return this.commonAjax(
      "get",
      Url.namedApi,
      `portal_api/get_banner.php`,
      {},
      {},
      false
    );
  }
}

export default new BannersService();
