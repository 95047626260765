import React, { useState } from 'react';
import { PopupModal } from '../../components/modal/popup/popupModal';
import ProfileImg from '../mypage/profile_image_change'

const ModalTest = () => {
  const [isOpenModal, setOpenModal] = useState(false);

  return (
    <div>
      <button onClick={() => setOpenModal(true)}>팝업열기</button>
      <PopupModal isOpen={isOpenModal} onClose={() => setOpenModal(false)}>
        {/*<ModalContent />*/}
        <ProfileImg />
      </PopupModal>
    </div>
  );
};

const ModalContent = () => {
  // 모달에 사용될 화면 내용
  const [count, setCount] = useState(0);

  return (
    <div>
      <h2>모달 컨텐츠</h2>
      <p>{count}</p>
      <button
        style={{ border: '1px solid red', color: 'red', padding: 9, margin: 4 }}
        onClick={() => setCount(count + 1)}
      >
        업
      </button>
      <button
        style={{ border: '1px solid red', color: 'red', padding: 9, margin: 4 }}
        onClick={() => setCount(count - 1)}
      >
        다운
      </button>
    </div>
  );
};

export default ModalTest;
