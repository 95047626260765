import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import useTypeStore from '../../../hooks/useTypeStore';
import styled from 'styled-components';
import Seo from '../../../components/seo';
import './notice.css';
import { useLocalStore } from "mobx-react-lite";
import { CommunityService } from "../../../common/services";
import CommunityModel from "../../../routes/community/store/model";
import { Url } from "../../../common/constants/environment";
import useModalStore from "../../../hooks/useModalStore";


const NoticeBarLayer = observer(() => {
    const modalStore = useModalStore();
    const [viewNoticeIndex, setViewNoticeIndex] = useState(0);
    const [timeInterval, setTimeInterval] = useState(undefined);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page')) || 1;
    const type = 'notice';

    // const communityStore = useTypeStore('communityStore');
    const store = useLocalStore(() => ({
        boardNotice: [],
        list: [],
        loading: false,
        totalCount: 0,
        async getNotice(page) {
            store.loading = true;

            const res = await CommunityService.list('free', page, '', '', '');

            if (res.code !== 200) {
                modalStore.openAlertModal({
                    message: res.message
                });
            } else {
                store.code = res.code;
                store.totalCount = res.data.total_cnt;
                store.boardNotice = res.data.board_notice.map(
                    (item) => new CommunityModel(item)
                );
                store.list = res.data.list.map((item) => new CommunityModel(item));
                window.scrollTo(0, 0);
            }
        }
    }));

    useEffect(() => {
        store.getNotice(page);
    }, [page]);

    const noticeCount = store.boardNotice.slice(0, 5).length;

    useEffect(() => {
        if (noticeCount > 1) {
            const timer = setInterval(() => {
                setViewNoticeIndex((current) => (current + 1) % noticeCount);
            }, 5000);
            return () => clearInterval(timer);
        }
    }, [noticeCount]);
    return (
        <div className="footerNotice">
            <div className="wrapper">
                <div>
                    <div className="symbolImg"></div>
                    <a href={`/customercenter/notice`}><span>공지사항</span></a>
                    <div className="arrowImg"></div>
                    <div className="noticeList">
                        <ul>
                            {store.boardNotice.length > 0 ? store.boardNotice.slice(0, 5).map((obj, index) => {
                                let inOutClassName = ''
                                if (noticeCount === 1) {
                                    inOutClassName = 'in'
                                } else {
                                    if (viewNoticeIndex === index) inOutClassName = 'in'
                                    if (viewNoticeIndex - 1 === index) inOutClassName = 'out'
                                    if (viewNoticeIndex + noticeCount - 1 === index) inOutClassName = 'out'
                                }
                                return (
                                    <li key={index} className={inOutClassName}>
                                        <a
                                            className={'noticeSubject'}
                                            href={`/customercenter/${obj.board_type}/${obj.wr_id}`}
                                        >
                                            {/*<em>[공지]</em>*/}
                                            {obj.wr_subject}
                                        </a>
                                    </li>
                                )
                            }) : <li className="in">
                                등록된 공지가 없습니다.
                            </li>}
                        </ul>
                    </div>
                </div>
                <div>
                    <ul>
                        <li></li>
                    </ul>
                </div>
            </div>
        </div>
    );
});

const NoticeList = styled.ul`
    padding: 0 15px;
    background-color: #fff;

    li {
        border-bottom: 1px solid #f2f2f2;

        a {
            padding: 16px 6px;
            font-size: 15px;
            color: #222;
            line-height: 19px;
            display: block;

            em {
                color: #ed2026;
                margin-right: 5px;
            }
        }
    }
`;

export default NoticeBarLayer;
