import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import GoBack from '../../components/goBack';
import EmailTerm from '../../components/terms/emailTerm';
import PersonalInfo from '../../components/terms/personalInfo';
import TermsUse from '../../components/terms/termsUse';
import YouthPolicy from '../../components/terms/youthPolicy';
import Seo from '../../components/seo';

const Terms = () => {
  const { type } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tit = useMemo(() => {
    if (type === 'privacy') {
      return '개인정보취급방침';
    } else if (type === 'service') {
      return '이용약관';
    } else if (type === 'email') {
      return '이메일주소무단수집거부';
    } else {
      return '청소년보호정책';
    }
  }, [type]);

  return (
    <div className="account_info_wrap">
      <Seo title={tit} />

      <div className="nav">
        <h2 className="tit">{tit}</h2>
        <GoBack />
      </div>

      <Switch>
        <Route exact path="/terms/service" component={TermsUse} />
        <Route exact path="/terms/privacy" component={PersonalInfo} />
        <Route exact path="/terms/email" component={EmailTerm} />
        <Route exact path="/terms/youth" component={YouthPolicy} />
      </Switch>
    </div>
  );
};

export default Terms;
